import React from 'react';
import {branch} from 'baobab-react/higher-order';

import PortalHome from './app-home';

import browserHistory from '../../history';

// Creating our top-level component
class RegistrationFlow extends React.Component {
	componentDidMount() {
		if (this.props.user.loggedIn) {
			let targetRoute = '/home';
			if (this.props.user.entryPoint !== '') {
				targetRoute = this.props.user.entryPoint;
			}
			browserHistory.push(targetRoute);	
		}
	}

	render() {
		return (
			<div>
				<PortalHome />
			</div>
		);
	}
}

export default branch({
	user: ['user']
}, RegistrationFlow);

