import React from 'react';

import Glyphicon from '../components/app-icon';

import PropTypes from 'baobab-react/prop-types';
import * as actions from '../actions/actions';

import EditorItem from '../components/app-editor-item.js';

import * as utils from '../utils/utils';

import { pathToArray } from "../utils/utils";

class CardLeaf extends React.PureComponent{
	// shouldComponentUpdate(nextProps, nextState) {
	// 	//console.log("Leaf shouldComponentUpdate");
	// 	return this.props.node !== nextProps.node;
	// }

	handleSelectNodeAtPath(e) {
		actions.handleSelectNodeAtPath(this.context.tree,pathToArray(this.props.path));
	}
	//<Glyphicon style={{color: "white"}} glyph="file" />
	//<Glyphicon style={this.props.node.entity.hasOwnProperty("value") ? {color: "#B6B6B4"} : {color: "#6D6968"}} glyph="file" />
	handleChangeLabel(e) {
		actions.handleChangeProperty(this.context.tree,"label",e.target.value);
	}


	render() {
		var self=this;
		var leaf, item, icon
		var color = "#118871";

		var icon = utils.getIconForType(this.props.node.entity.type);


		if (this.props.node.selected) {
			leaf =
			<div style={{overflowX: "hidden", width: "100%", display: "flex", alignItems: "center", padding: "2px", backgroundColor: "#357EC7", cursor: "pointer"}}>
				{/* <div>
					<Glyphicon style={{ color: "transparent", marginLeft: "5px", marginRight: "10px" }} glyph="plus" />
				</div>	 */}
				<div style={{overflowX: "hidden", width: "100%"/*, marginLeft: "5px"*/}}>
					<EditorItem editorState={this.props.editorState} node={this.props.node} provided={this.props.provided} path={this.props.path}/>
				</div>	
			</div>
		} else {
			leaf =
			<div style={{overflowX: "hidden", width: "100%", display: "flex", alignItems: "center", padding: "2px", color: "#bcbcbc", cursor: "pointer"}} onClick={this.handleSelectNodeAtPath.bind(this)}>
				{/* <div>
					<Glyphicon style={{ color: "transparent", marginLeft: "5px", marginRight: "10px" }} glyph="plus" />
				</div>	 */}
				<div style={{overflowX: "hidden", width: "100%"/*, marginLeft: "5px"*/}}>
					<EditorItem editorState={this.props.editorState} node={this.props.node} provided={this.props.provided} path={this.props.path}/>
				</div>	
			</div>
		}

		return (
			<div>
				{leaf}
			</div>
		);
	}
}

CardLeaf.contextTypes = {
	tree: PropTypes.baobab
};

export default CardLeaf;
