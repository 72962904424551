import React from 'react';
import {FormControl} from 'react-bootstrap';

class PickerViewType extends React.PureComponent {
    render() {
        return (
            <FormControl componentClass="select" value={this.props.value} onChange={this.props.onChange}>
                <option value="picker">Picker</option>
                <option value="buttons">Buttons</option>
            </FormControl>
        )    
    }
}

export default PickerViewType;