import React, { PureComponent } from "react";
import RichTextEditor from "./index.js";

import { Button } from "react-bootstrap";

class RichTextEditorWithToggle extends PureComponent {
	renderComponent() {
		return (
			<div>
				<RichTextEditor placeholder={this.props.placeholder} autoFocus={this.props.autoFocus} advanced={this.props.advanced} value={this.props.value} onChange={this.props.onChange} />
				<div style={{ display: "flex", flexDirection: "column", marginTop: "5px", width: "100%" }}>
					<Button style={{ alignSelf: "flex-end" }} onClick={this.props.onClickToggle}>{this.props.advanced ? "Show Basic Editor" : "Show Markdown Editor"}</Button>
				</div>
			</div>
		);
	}

	render() {
		let component = this.renderComponent();

		return component;
	}
}

export default RichTextEditorWithToggle;