import React from 'react';

import Glyphicon from '../components/app-icon';

//import Button from 'react-bootstrap/lib/Button';

import PropTypes from 'baobab-react/prop-types';
import * as actions from '../actions/actions';

import * as utils from '../utils/utils';

import ClampText from "react-clamp-text";

import { getAdditionalInfoComponent} from "../utils/utils";

import { pathToArray } from "../utils/utils";

class Leaf extends React.PureComponent{
	// shouldComponentUpdate(nextProps, nextState) {
	// 	//console.log("Leaf shouldComponentUpdate");
	// 	return this.props.node !== nextProps.node;
	// }

	handleSelectNodeAtPath(e) {
		actions.handleSelectNodeAtPath(this.context.tree,pathToArray(this.props.path));
	}
	//<Glyphicon style={{color: "white"}} glyph="file" />
	//<Glyphicon style={this.props.node.entity.hasOwnProperty("value") ? {color: "#B6B6B4"} : {color: "#6D6968"}} glyph="file" />
	handleChangeLabel(e) {
		actions.handleChangeProperty(this.context.tree,"label",e.target.value);
	}


	render() {
		var self=this;

		const entity = self.props.node.entity;
		
		var leaf, item, icon
		var color = "#118871";

		var icon = utils.getIconForType(this.props.node.entity.type);

		let multiSelectMode = this.context.tree.select(["appState","editor", "multiSelectMode"]).get();
		const selectComponent = (multiSelectMode) ? <input type="checkbox"/> : "";

		var itemSizeLabel;

		const itemSize = (self.props.node.entity.label.length + self.props.node.entity.label2.length + self.props.node.entity.label3.length + self.props.node.entity.comments.length);
		if (itemSize >= 100000) {
			itemSizeLabel = <span style={{color: "darkred"}}><b>{" (" + itemSize.toLocaleString() + " bytes)"}</b></span>
		} else if (itemSize >= 50000) {
			itemSizeLabel = <span style={{color: "goldenrod"}}><b>{" (" + itemSize.toLocaleString() + " bytes)"}</b></span>
		} else if (itemSize >= 1000) {
			itemSizeLabel = <span style={{color: "darkgreen"}}><b>{" (" + itemSize.toLocaleString() + " bytes)"}</b></span>
		}

		const labelComponent = 
			<div style={{width: "100%"}}>
				<div style={{textOverflow: "clip", overflow: "hidden", whiteSpace: "no-wrap", width: "100%"}}>{selectComponent} {self.props.node.entity.label === "" ? "(Label 1)" : <ClampText
						line={3}
						showMore={true}
						showMoreText="Expand"
						showLessText="Collapse"
					>
						<div dangerouslySetInnerHTML={utils.createMarkupForLabel(entity.label)} />
					</ClampText>}{itemSizeLabel}</div>
				{getAdditionalInfoComponent(entity)}				
			</div>;

		if (this.props.node.selected) {
			leaf =
			<div style={{width: "100%", display: "flex", alignItems: "center", padding: "10px", backgroundColor: "#cccccc33", color: "#ffffff", cursor: "pointer"}}>
				<div style={{flex: 0}}>
					<Glyphicon style={{ color: "transparent", marginLeft: "5px" }} glyph="plus" />
				</div>	
				<Glyphicon style={{ flex: 0, fontSize: "32px", color: "#118871"}} glyph={icon} />
				<div style={{flex: 1, width: "100%", marginLeft: "5px"}}>
					{labelComponent}
				</div>
				{this.props.provided && <div {...this.props.provided.dragHandleProps}>
					<Glyphicon style={{fontSize: "32px", color: color + "88"}} glyph="sort" />
				</div>}
			</div>
		} else {
			leaf =
			<div style={{width: "100%", display: "flex", alignItems: "center", padding: "10px", color: "#bcbcbc", cursor: "pointer"}} onClick={this.handleSelectNodeAtPath.bind(this)}>
				<div style={{flex: 0}}>
					<Glyphicon style={{ color: "transparent", marginLeft: "5px" }} glyph="plus" />
				</div>	
				<Glyphicon style={{flex: 0 , fontSize: "32px", color: color}} glyph={icon} />
				<div style={{flex: 1, width: "100%", marginLeft: "5px"}}>
					{labelComponent}
				</div>
				{this.props.provided && <div {...this.props.provided.dragHandleProps}>
					<Glyphicon style={{ fontSize: "32px", color: color + "88" }} glyph="sort" />
				</div>}
			</div>
		}

		return (
			<div>
				{leaf}
			</div>
		);
	}
}

Leaf.contextTypes = {
	tree: PropTypes.baobab
};

export default Leaf;
