import React, { Component } from 'react';

import { branch } from 'baobab-react/higher-order';
import PropTypes from 'baobab-react/prop-types';
import * as actions from '../actions/actions';
import * as actionsPortal from '../actions/portalActions';
import * as awsS3 from '../persistence/s3';
import * as utils from '../utils/utils';
import { contentBucket } from '../constants/globals.js';


import Confirm from 'react-confirm-bootstrap';

import Toolbar from '../components/app-toolbar.js';
import EditorWrapper from '../components/app-editor-wrapper.js';
import ChecklistEditor from '../components/app-checklist-editor.js';

import Alerts from '../components/app-alerts.js';

import Flexbox from 'flexbox-react';

import { HotKeys } from 'react-hotkeys';

import { Button, Nav, Navbar, NavItem, DropdownButton, MenuItem } from 'react-bootstrap';
import { ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import Glyphicon from '../components/app-icon';

import { Link } from 'react-router-dom';
import browserHistory from '../history';

import MediaQuery from 'react-responsive';

import Loader from 'react-loader-advanced';
import FontAwesome from 'react-fontawesome';


const keyMap = {
	'moveUp': ['alt+up'],
	'moveDown': ['alt+down'],
	'moveParent': ['alt+left'],
	'moveFirstChild': ['alt+right'],
	'expand': ['alt+.'],
	'collapse': ['alt+,'],
	'cut': ['ctrl+x', 'command+x'],
	'copy': ['ctrl+c', 'command+c'],
	'paste': ['ctrl+v', 'command+v'],
	'undo': ['ctrl+z', 'command+z'],
	'redo': ['ctrl+y', 'command+shift+z', 'command+y'],
	'removeNode': ['fn+del'],
	//'addList': ['fn+1'],
	//'addSection': ['fn+2'],
	//'addItem': ['fn+3'],
	//'addCategory': 'alt+1',
	//'addItemValue': 'alt+2',
	//'addItemValues': 'alt+3',
	'moveNodeUp': ['shift+alt+up'],
	'moveNodeDown': ['shift+alt+down'],
	//'expandNode': 'ctrl+right',
	//'collapseNode': 'ctrl+left',
	'toggleExpandCollapse': ['alt+t']
}

// Flexbox seems to slow performance
class Main extends Component {
	constructor(props, context) {
		super(props, context);

		if (!props.user.loggedIn) {
			actions.forceLogin(browserHistory, this.props.location.pathname);
		}
	}

	home(e) {
		e.preventDefault();
		const self = this;
		window.confirm("Make sure you saved before exiting. Are you sure you want to exit?", () => {
			self.props.dispatch(
				actions.setNavPanelSelected,
				"home"
			);
		});
	}

	search(e) {
		e.preventDefault();
		const self = this;
		window.confirm("Make sure you saved before exiting. Are you sure you want to exit?", () => {
			self.props.dispatch(
				actions.setNavPanelSelected,
				"search"
			);
		});
	}

	myChecklists(e) {
		e.preventDefault();
		const self = this;
		window.confirm("Make sure you saved before exiting. Are you sure you want to exit?", () => {
			self.props.dispatch(
				actions.setNavPanelSelected,
				"myChecklists"
			);
		});


	}

	timers(e) {
		e.preventDefault();
		const self = this;
		window.confirm("Make sure you saved before exiting. Are you sure you want to exit?", () => {
			self.props.dispatch(
				actions.setNavPanelSelected,
				"timers"
			);
		});
	}

	history(e) {
		e.preventDefault();
		const self = this;
		window.confirm("Make sure you saved before exiting. Are you sure you want to exit?", () => {
			self.props.dispatch(
				actions.setNavPanelSelected,
				"history"
			);
		});

	}

	handleSelect(e) {
		e.preventDefault();
	}

	logout(e) {
		e.preventDefault();
		const self = this;
		window.confirm("Make sure you saved before exiting. Are you sure you want to exit?", () => {
			actionsPortal.logout(self.props.user.username);
		});
	}

	componentWillMount() {
		if (this.props.user.loggedIn && this.props.match.params.checklistId && this.props.match.params.checklistId !== this.props.selectedChecklistId) {
			awsS3.loadJsonFromS3(contentBucket, this.props.match.params.checklistId);
		}
	}

	save() {
		// Need to transform back to checklist specific format
		actions.saveChecklist();
	}

	cut() {
		actions.handleCut(this.context.tree);
	}

	copy() {
		actions.handleCopy(this.context.tree);
	}

	pasteAsLastChild() {
		actions.handlePaste(this.context.tree, "lastChild");
	}

	pasteAsFirstChild() {
		actions.handlePaste(this.context.tree, "firstChild");
	}

	pasteBefore() {
		actions.handlePaste(this.context.tree, "before");
	}

	pasteAfter() {
		actions.handlePaste(this.context.tree, "after");
	}

	expandAll(e) {
		actions.handleExpandAll(this.context.tree);
	}

	collapseAll(e) {
		actions.handleCollapseAll(this.context.tree);
	}

	addCategoryAsFirstChild() {
		actions.handleAddCategory(this.context.tree, this.context.tree.select(this.context.tree.select('selectedNodePath').get()), "firstChild");
	}

	addCategoryAsLastChild() {
		actions.handleAddCategory(this.context.tree, this.context.tree.select(this.context.tree.select('selectedNodePath').get()), "lastChild");
	}

	addCategoryBefore() {
		actions.handleAddCategory(this.context.tree, this.context.tree.select(this.context.tree.select('selectedNodePath').get()), "before");
	}

	addCategoryAfter() {
		actions.handleAddCategory(this.context.tree, this.context.tree.select(this.context.tree.select('selectedNodePath').get()), "after");
	}

	addListAsFirstChild() {
		actions.handleAddList(this.context.tree, this.context.tree.select(this.context.tree.select('selectedNodePath').get()), "firstChild");
	}

	addListAsLastChild() {
		actions.handleAddList(this.context.tree, this.context.tree.select(this.context.tree.select('selectedNodePath').get()), "lastChild");
	}

	addListBefore() {
		actions.handleAddList(this.context.tree, this.context.tree.select(this.context.tree.select('selectedNodePath').get()), "before");
	}

	addListAfter() {
		actions.handleAddList(this.context.tree, this.context.tree.select(this.context.tree.select('selectedNodePath').get()), "after");
	}

	addSectionAsFirstChild() {
		actions.handleAddSection(this.context.tree, this.context.tree.select(this.context.tree.select('selectedNodePath').get()), "firstChild");
	}

	addSectionAsLastChild() {
		actions.handleAddSection(this.context.tree, this.context.tree.select(this.context.tree.select('selectedNodePath').get()), "lastChild");
	}

	addSectionBefore() {
		actions.handleAddSection(this.context.tree, this.context.tree.select(this.context.tree.select('selectedNodePath').get()), "before");
	}

	addSectionAfter() {
		actions.handleAddSection(this.context.tree, this.context.tree.select(this.context.tree.select('selectedNodePath').get()), "after");
	}

	addItemAsFirstChild() {
		actions.handleAddItem(this.context.tree, this.context.tree.select(this.context.tree.select('selectedNodePath').get()), "firstChild");
	}

	addItemAsLastChild() {
		actions.handleAddItem(this.context.tree, this.context.tree.select(this.context.tree.select('selectedNodePath').get()), "lastChild");
	}

	addItemBefore() {
		actions.handleAddItem(this.context.tree, this.context.tree.select(this.context.tree.select('selectedNodePath').get()), "before");
	}

	addItemAfter() {
		actions.handleAddItem(this.context.tree, this.context.tree.select(this.context.tree.select('selectedNodePath').get()), "after");
	}

	undo() {
		actions.handleUndo(this.context.tree);
	}

	redo() {
		actions.handleRedo(this.context.tree);
	}

	removeNode() {
		actions.handleRemoveNode(this.context.tree, this.context.tree.select(this.context.tree.select('selectedNodePath').get()));
		//actions.handleUndo(this.context.tree);
	}

	moveNodeUp() {
		actions.handleMoveNodeUp(this.context.tree, this.context.tree.select(this.context.tree.select('selectedNodePath').get()));
	}

	moveNodeDown() {
		actions.handleMoveNodeDown(this.context.tree, this.context.tree.select(this.context.tree.select('selectedNodePath').get()));
	}

	toggleMultiSelect() {
		actions.toggleMultiSelect(this.context.tree);
	}

	render() {
		var self = this;

		var multiSelectMode = this.context.tree.select(['appState', 'editor', 'multiSelectMode']).get();

		// alert(multiSelectMode);

		const handlers = {
			'moveUp': (event) => { actions.handleSelectNodeUp(this.context.tree) },
			'moveDown': (event) => { actions.handleSelectNodeDown(this.context.tree) },
			'moveParent': (event) => { actions.handleSelectNodeParent(this.context.tree) },
			'moveFirstChild': (event) => { actions.handleSelectNodeFirstChild(this.context.tree) },
			'cut': (event) => { actions.handleCut(this.context.tree) },
			'copy': (event) => { actions.handleCopy(this.context.tree) },
			'paste': (event) => { actions.handlePaste(this.context.tree); },
			'undo': (event) => { actions.handleUndo(this.context.tree) },
			'redo': (event) => { actions.handleRedo(this.context.tree) },
			'removeNode': (event) => { actions.handleRemoveNode(this.context.tree, this.context.tree.select(this.context.tree.select('selectedNodePath').get())) },
			'expand': (event) => { actions.handleExpandAll(this.context.tree) },
			'collapse': (event) => { actions.handleCollapseAll(this.context.tree) },
			'addList': (event) => { actions.handleAddList(this.context.tree, this.context.tree.select(this.context.tree.select('selectedNodePath').get()), "lastChild") },
			'addSection': (event) => { actions.handleAddSection(this.context.tree, this.context.tree.select(this.context.tree.select('selectedNodePath').get()), "lastChild") },
			'addItem': (event) => { actions.handleAddItem(this.context.tree, this.context.tree.select(this.context.tree.select('selectedNodePath').get()), "lastChild") },
			//'addCategory': (event) => {actions.handleAddCategory(this.context.tree,this.context.tree.select(this.context.tree.select('selectedNodePath').get()),"lastChild")},
			//'addItemValue': (event) => {actions.handleAddItemValue(this.context.tree,this.context.tree.select(this.context.tree.select('selectedNodePath').get()),"lastChild")},
			//'addItemValues': (event) => {actions.handleAddItemValues(this.context.tree,this.context.tree.select(this.context.tree.select('selectedNodePath').get()),"lastChild")},
			'moveNodeUp': (event) => { actions.handleMoveNodeUp(this.context.tree, this.context.tree.select(this.context.tree.select('selectedNodePath').get())) },
			'moveNodeDown': (event) => { actions.handleMoveNodeDown(this.context.tree, this.context.tree.select(this.context.tree.select('selectedNodePath').get())) },
			'expandNode': (event) => { actions.handleExpandNode(this.context.tree.select(this.context.tree.select('selectedNodePath').get())) },
			'collapseNode': (event) => { actions.handleCollapseNode(this.context.tree.select(this.context.tree.select('selectedNodePath').get())) },
			'toggleExpandCollapse': (event) => { actions.handleToggleNode(this.context.tree.select(this.context.tree.select('selectedNodePath').get())) }
		};

		var toolbarBottom =
			<ButtonToolbar style={{ padding: "5px" }}>
				{/*<ButtonGroup>
					<Button bsSize="small" onClick={this.toggleMultiSelect.bind(this)} title="Enable Multiselect"><Glyphicon glyph="check-square-o"/></Button>
				</ButtonGroup>*/}
				<ButtonGroup>
					<Button onClick={this.addListAsLastChild.bind(this)} title="Add List"><Glyphicon style={{ color: "#0084c1" }} glyph="list-alt" /></Button>
					<Button onClick={this.addSectionAsLastChild.bind(this)} title="Add Section"><Glyphicon style={{ color: "#ffc153" }} glyph="table" /></Button>
					<Button onClick={this.addItemAsLastChild.bind(this)} title="Add Item"><Glyphicon style={{ color: "#118871" }} glyph="check" /></Button>
				</ButtonGroup>
				{/* <ButtonGroup>
					<Button onClick={this.moveNodeUp.bind(this)} title="Move Up"><Glyphicon glyph="arrow-up" /></Button>
					<Button onClick={this.moveNodeDown.bind(this)} title="Move Down"><Glyphicon glyph="arrow-down" /></Button>
				</ButtonGroup> */}
				<ButtonGroup>
					<Button onClick={this.expandAll.bind(this)} title="Expand"><Glyphicon glyph="plus-square" /></Button>
					<Button onClick={this.collapseAll.bind(this)} title="Collapse"><Glyphicon glyph="minus-square" /></Button>
				</ButtonGroup>
			</ButtonToolbar>;

		var title = <span><i className="fa fa-user"></i> {this.props.user.username}</span>;

		var profileButton;
		if (utils.isMobile()) {
			profileButton =
				<DropdownButton style={{ marginTop: "8px", marginLeft: "10px" }} title={title} key="dropdown-user" id="dropdown-user">
					<MenuItem eventKey="1" onClick={this.logout.bind(this)}>Logout</MenuItem>
				</DropdownButton>;
		} else if (!this.props.igapp) {
			profileButton =
				<DropdownButton style={{ marginTop: "8px", marginLeft: "10px" }} title={title} key="dropdown-user" id="dropdown-user">
					<MenuItem eventKey="1" onClick={this.logout.bind(this)}>Logout</MenuItem>
					<MenuItem divider />
					<MenuItem eventKey="2" onClick={(e) => { self.props.dispatch(actions.setLastLocation, window.location); browserHistory.push("/subscribe"); }}>Subscription</MenuItem>
					<MenuItem divider />
					<MenuItem eventKey="3" onClick={(e) => { self.props.dispatch(actions.setLastLocation, window.location); browserHistory.push("/updateCustomerCard"); }}>Update Card Info</MenuItem>
				</DropdownButton>;
		} else if (this.props.igapp) {
			profileButton =
				<DropdownButton style={{ marginTop: "8px", marginLeft: "10px" }} title={title} key="dropdown-user" id="dropdown-user">
					<MenuItem eventKey="1" onClick={this.logout.bind(this)}>Logout</MenuItem>
				</DropdownButton>;
		}

		const spinner =
			<div onClick={() => { utils.hideLoader(this.context.tree, "loadingSpinner") }}>
				<FontAwesome
					name='spinner'
					size='4x'
					spin />
			</div>;

		return (
			<Loader show={this.props.loadingSpinner} message={spinner}>

				<HotKeys keyMap={keyMap} handlers={handlers}>
					<Flexbox flexDirection="column" height="100vh">

						<Flexbox flex="1" flexDirection="column" style={{ height: "100%" }}>
							<Alerts />
							<div style={{ marginBottom: "-20px" }}>
								<Navbar fluid>
									<Navbar.Header>
										<Navbar.Brand>
											<Flexbox alignItems="center">
												<img width="32" height="32" src="/assets/goose-g-logo-300x300.png" />
												<div style={{ marginLeft: "10px", fontWeight: "normal", fontSize: "24px", color: "#fdfdfd" }}>Goose Cloud</div>
												{/* <a style={{marginLeft: "10px", fontWeight: "normal", fontSize: "24px"}} href="#">Goose Cloud (formerly MiraCheck)</a> */}
											</Flexbox>
											{/* <Flexbox alignItems="center">
												<img width="32" height="32" src="/assets/goose-g-logo-300x300.png"/>
												<a style={{marginLeft: "5px"}} href="#">Goose Cloud (formerly MiraCheck)</a>
											</Flexbox>									 */}
										</Navbar.Brand>
										<Navbar.Toggle />
									</Navbar.Header>
									<Navbar.Collapse>
										<Nav pullRight onSelect={(e) => { }}>

											<NavItem onClick={this.home.bind(this)}>Home</NavItem>
											<NavItem onClick={this.search.bind(this)}>Search</NavItem>
											<NavItem onClick={this.myChecklists.bind(this)}>My Hangar</NavItem>
											<NavItem onClick={this.history.bind(this)}>History</NavItem>
											{/* {this.props.capabilitiesTimers && this.props.hasOwnProperty("user") && this.props.user.hasOwnProperty("subscriptionPlan") && this.props.user.subscriptionPlan.startsWith("pro-plan") && <NavItem onClick={this.timers.bind(this)}>Timers</NavItem>} */}
											<NavItem onClick={this.timers.bind(this)}>Timers</NavItem>
											<li>
												{profileButton}
											</li>
										</Nav>
									</Navbar.Collapse>
								</Navbar>
							</div>

							<MediaQuery query='(max-width: 379px)'>
								<Flexbox style={{ width: "100%" }} flexDirection="row">
									<Flexbox style={{ paddingLeft: "8px", paddingRight: "8px", width: "100%" }} flexDirection="column">
										<Button  onClick={() => this.save()} style={{ margin: "2px", backgroundColor: "rgb(42,140,104)" }}><Glyphicon glyph="save" style={{ color: "white", fontSize: "16px" }} /><div style={{ fontSize: "12px", fontWeight: "bold", color: "white" }}>Save</div></Button>
										<Flexbox justifyContent="center" style={{ overflowY: "visible", backgroundColor: "transparent" }}>
											<Toolbar />
										</Flexbox>
										<Flexbox flexDirection="row" style={{ backgroundColor: "transparent" }}>
											{toolbarBottom}
										</Flexbox>
									</Flexbox>
								</Flexbox>
							</MediaQuery>
							<MediaQuery query='(min-width: 380px)'>
								<Flexbox style={{  paddingLeft: "8px", width: "100%" }} flexDirection="row">
									<Button onClick={() => this.save()} style={{ margin: "2px", backgroundColor: "rgb(42,140,104)" }}><Glyphicon glyph="save" style={{ color: "white", fontSize: "16px" }} /><div style={{ fontSize: "12px", fontWeight: "bold", color: "white" }}>Save</div></Button>
									<Flexbox style={{ width: "100%" }} flexDirection="column">
										<Flexbox justifyContent="center" style={{ overflowY: "visible", backgroundColor: "transparent" }}>
											<Toolbar />
										</Flexbox>
										<Flexbox flexDirection="row" style={{ backgroundColor: "transparent" }}>
											{toolbarBottom}
										</Flexbox>
									</Flexbox>
								</Flexbox>
							</MediaQuery>

							<MediaQuery query='(max-width: 767px)'>
								<Flexbox style={{ overflow: "hidden" }} flex="1" flexDirection="row" width="100vw">
									<Flexbox flex="4" style={{ overflowY: "auto", overflowX: "hidden", border: "1px solid transprent" }}>
										<ChecklistEditor className="smooth-scrolling" />
									</Flexbox>
								</Flexbox>
							</MediaQuery>
							<MediaQuery query='(min-width: 768px)'>
								<Flexbox style={{ overflow: "hidden" }} flex="1" flexDirection="row" width="100vw">
									<Flexbox flex="4" style={{ margin: "10px", backgroundColor: "#1F272B", maxWidth: "40%", overflowY: "auto", overflowX: "hidden", borderRadius: "15px", border: "1px solid transprent" }}>
										<ChecklistEditor />
									</Flexbox>
									<Flexbox flex="6" style={{ overflowY: "auto", overflowX: "hidden", border: "1px solid transprent" }}>
										<EditorWrapper className="smooth-scrolling" style={{ width: "100%" }} />
									</Flexbox>
								</Flexbox>
							</MediaQuery>
						</Flexbox>

					</Flexbox>
				</HotKeys>
			</Loader>
		);

	}
}

Main.contextTypes = {
	tree: PropTypes.baobab
};

export default branch({
	version: ['version'],
	user: ['user'],
	selectedChecklistId: ['selectedChecklistId'],
	capabilitiesTimers: ['appCapabilities', 'timers'],
	loadingSpinner: ['appState', 'loadingSpinner'],
	igapp: ['appCapabilities', 'igapp']
}, Main);
