import React, { useState } from 'react';
import { branch } from 'baobab-react/higher-order';
import PropTypes from 'baobab-react/prop-types';
import * as actions from '../actions/actions';
import * as actionsPortal from '../actions/portalActions';
import * as utils from '../utils/utils';

import * as awsS3 from '../persistence/s3';
import { Panel, Button, Dropdown, MenuItem, Glyphicon } from 'react-bootstrap';
import Alerts from '../components/app-alerts.js';

import Flexbox from 'flexbox-react';
import browserHistory from '../history';

import NavLinks from './navPanel/app-nav-links.js';
import NavPanel from './navPanel/app-nav-panel.js';

import MediaQuery from 'react-responsive';

import ChecklistReadOnly from '../components/app-checklist-read-only.js';

//import shallowCompare from 'react-addons-shallow-compare';

import _ from 'lodash';

import Loader from 'react-loader-advanced';
import FontAwesome from 'react-fontawesome';

class CheckMateImport extends React.PureComponent {
	constructor(props, context) {
		super(props, context);

		if (!props.user.loggedIn) {
			actions.forceLogin(browserHistory, this.props.location.pathname);
		}

		this.state = {
			selectedIdentityId: this.props.user.identityId
		}


		this.setCheckMateUsername = this.setCheckMateUsername.bind(this);
		this.setCheckMatePassword = this.setCheckMatePassword.bind(this);
	}
	/*
		shouldComponentUpdate(nextProps, nextState) {
			return shallowCompare(this, nextProps, nextState);
		}
	*/
	componentDidMount() {
		this.context.tree.select(['appState', 'navPanel']).set('selected', 'checkMateImport');
	}

	handleChangeUser(e) {
		this.setState({ selectedIdentityId: e.target.value });
	}

	componentDidUpdate(prevProps, prevState) {

	}

	setCheckMateUsername(e) {
		actions.setCheckMateUsername(e.target.value);
	}

	setCheckMatePassword(e) {
		actions.setCheckMatePassword(e.target.value);
	}

	fetchCheckLists() {
		actions.fetchCheckLists();
	}

	selectChecklist(checklistId) {

	}

	deselectChecklist(checklistId) {

	}

	importChecklists() {
		actions.doImportChecklists();
	}

	async importJson(event) {
		if (!this.props.user.subscriptionPlan.startsWith("pro-plan")) {
			alert("Sorry. You need to be a Pro Plan subscriber to import from JSON format.");
			return;
		}

		console.log("Selected file:", event.target.files[0]);
		try {
			// this.context.tree.set(["checkMateImport", "showSpinner"], true);
			await awsS3.importChecklistJson(event, { filedUploadField: null, openEditorAfterSave: false });
			// this.context.tree.set(["checkMateImport", "showSpinner"], false);
		} catch (e) {
			this.context.tree.set(["checkMateImport", "showSpinner"], false);
			alert(e.message);

			console.log(e);
		}
	}

	importCsv(event) {
		if (this.props.user.subscriptionPlan.startsWith("no-plan") || this.props.user.subscriptionPlan.startsWith("basic-plan")) {
			alert("Sorry. You need to be a Standard or Pro Plan subscriber to import from CSV format.");
			return;
		}

		console.log('Selected file:', event.target.files[0]);
		actionsPortal.uploadFiles(event.target.files);
	}







importRandomSpoonacularRecipe(event) {
	actionsPortal.uploadRandomSpoonacularRecipe();
}

render() {
	if (!this.props.user.loggedIn) {
		return (
			<div />
		);
	} else {
		var self = this;

		const spinner =
			<span>
				<FontAwesome
					name='spinner'
					size='4x'
					spin
				/>
			</span>;

		var importJson =
			<Panel bsStyle="primary">
				<Panel.Heading>
					<Panel.Title>
						JSON Import
					</Panel.Title>
				</Panel.Heading>
				<Panel.Body>
					<div className="row">
						<div style={{ maxWidth: "800px", textAlign: "left", backgroundColor: "#666666", border: "1px solid #666666", borderRadius: "10px", padding: "10px", margin: "0 15px 15px 15px" }}>
							You can export your checklist as a JSON file. You do this from the menu on a Checklist Card in the My Hangar panel.<br /><br /><b>NOTE: 	</b><i>You must be a Pro Plan subscriber to be able to import/export JSON files.</i>
						</div>
					</div>
					<div className="row">
						<div style={{ marginTop: "20px" }} className="col-xs-12">
							<label>Select a JSON file to import</label>
						</div>
						<div className="col-xs-12">
							<input type="file" accept=".json" onChange={this.importJson.bind(this)} />
						</div>
					</div>
				</Panel.Body>
			</Panel>;

		var importCsv =
			<Panel bsStyle="primary">
				<Panel.Heading>
					<Panel.Title>
						CSV Import
					</Panel.Title>
				</Panel.Heading>
				<Panel.Body>
					<div className="row">
						<div style={{ maxWidth: "800px", textAlign: "left", backgroundColor: "#313131", border: "1px solid #f0bd42", borderRadius: "10px", padding: "10px", margin: "0 15px 15px 15px" }}>
							You can import your custom procedures to Goose from a CSV file. Download our template for the structure Goose expects when importing.<br>
							</br>
							<b>NOTE:</b>
							You must have a Performance or Enterprise subscription for the import functionality. <a href="/subscribe"><i>Upgrade your subscription</i></a>.
						</div>
					</div>
					<div className="row">
						<div className="col-xs-12">
							<label>Download Goose CSV template files</label>
						</div>
						<div className="col-xs-12">
							<a href="/assets/miracheck-template.csv">Download template</a><br />
							<a href="/assets/cessna152.csv">Download sample Cessna 152</a>
						</div>
						<div style={{ marginTop: "20px" }} className="col-xs-12">
							<label>Select a CSV file to import</label>
						</div>
						<div className="col-xs-12">
							<input type="file" accept=".csv" onChange={this.importCsv.bind(this)} />
						</div>
					</div>
				</Panel.Body>
			</Panel>;

		var importSpoonacularRecipe =
			<Panel bsStyle="primary">
				<Panel.Heading>
					<Panel.Title>
						Spoonacular Import
					</Panel.Title>
				</Panel.Heading>
				<Panel.Body>
					<Button bsStyle="primary" onClick={this.importRandomSpoonacularRecipe.bind(this)}>Import Random Spoonacular Recipe</Button>
				</Panel.Body>
			</Panel>;

		let pageComponent = (
			<Panel bsStyle="primary">
				<Panel.Heading>
					<Panel.Title>
						CheckMate Import
					</Panel.Title>
				</Panel.Heading>
				<Panel.Body>
					<div className="row">
						<div style={{ maxWidth: "800px", textAlign: "left", backgroundColor: "#666666", border: "1px solid #666666", borderRadius: "10px", padding: "10px", margin: "0 15px 15px 15px" }}>
							<p>Existing CheckMate customers! You can migrate all of your content from CheckMate. Please provide your CheckMate user name and password to retrieve the checklists to be migrated.</p>
						</div>
					</div>
					<div className="row">
						<div className="col-xs-12">
							<label>Username</label>
						</div>
						<div className="col-xs-12">
							<input autoFocus={utils.isMobile()} id="checkMateUserName" style={{ marginBottom: "10px" }} className="form-control" type="text" autoComplete="off" autoCorrect="off" autoCapitalize="none" onChange={this.setCheckMateUsername} />
						</div>
						<div className="col-xs-12">
							<label>Password</label>
						</div>
						<div className="col-xs-12">
							<input ref="password" id="checkMatePassword" style={{ marginBottom: "5px" }} className="form-control" type="password" autoComplete="new-password" onChange={this.setCheckMatePassword} />
						</div>
					</div>
					<Button bsStyle="primary" onClick={this.fetchCheckLists.bind(this)}>Login to CheckMate</Button>
				</Panel.Body>
			</Panel>
		)
		if (!_.isEmpty(this.props.checkMateImport.checklists)) {

			const checkMateChecklists = [];
			this.props.checkMateImport.checklists.forEach((checklist) => {
				checkMateChecklists.push(
					<div key={checklist.id} style={{ borderBottom: "1px dashed gray", marginTop: "5px" }}>
						<input type="checkbox" id={"checklist-" + checklist.id} />&nbsp;&nbsp;<label style={{ cursor: "pointer" }} htmlFor={"checklist-" + checklist.id}>{checklist.name}</label>
					</div>
				)
			});

			let checkMateChecklistComponent = null;
			if (!_.isEmpty(checkMateChecklists)) {
				checkMateChecklistComponent = (
					<div>
						<div>

						</div>
						{checkMateChecklists}
					</div>
				)
			}

			const checklists = [];
			this.props.checkMateImport.checklists.forEach((checklist) => {
				checklists.push(
					<div key={checklist.id} style={{ borderBottom: "1px dashed gray", marginTop: "5px" }}>
						-&nbsp;&nbsp;<label style={{ cursor: "pointer" }} htmlFor={"checklist-" + checklist.id}>{checklist.name}</label>
					</div>
				)
			});

			pageComponent = (
				<Panel bsStyle="primary">
					<Panel.Heading>
						<Panel.Title>
							CheckMate Checklist Import
						</Panel.Title>
					</Panel.Heading>
					<Panel.Body>
						<div className="row">
							<div style={{ maxWidth: "800px", textAlign: "left", backgroundColor: "#666666", border: "1px solid #666666", borderRadius: "10px", padding: "10px", margin: "0 15px 15px 15px" }}>
								Here are your available checklists.
							</div>
						</div>
						{checklists}
						<Button style={{ marginTop: "15px" }} bsStyle="primary" onClick={this.importChecklists.bind(this)}>Import Checklists</Button>
					</Panel.Body>
				</Panel>
			)
		}

		return (
			<Flexbox flexDirection="column" height="100vh" style={{ minHeight: 0 }}>
				<Alerts />
				<NavLinks />
				<MediaQuery query='(max-width: 599px)'>
					<Flexbox flex="1" flexDirection="row" width="100vw" style={{ minHeight: 0 }}>
						<Loader style={{ padding: "10px", overflowY: "auto", display: "flex", width: "100%", flexDirection: "column", flexGrow: 1, justifyContent: "flex-start" }} show={this.props.checkMateImport.showSpinner === true} message={spinner}>
							{/* {importJson} */}
							{importCsv}
						</Loader>
					</Flexbox>
				</MediaQuery>
				<MediaQuery query='(min-width: 600px)'>
					<Flexbox flex="1" flexDirection="row" width="100vw" style={{ minHeight: 0 }}>


						<MediaQuery query='(min-width: 768px)'>
							<NavPanel />
						</MediaQuery>

						<Loader style={{ paddingLeft: "20px", paddingRight: "20px", overflowY: "auto", display: "flex", width: "100%", flexDirection: "column", flexGrow: 1, justifyContent: "flex-start" }} show={this.props.checkMateImport.showSpinner === true} message={spinner}>
							{/* {importJson} */}

							<Flexbox flexDirection="column" alignItems="center" paddingBottom="20px">
								<Flexbox flexDirection='row' width="100%" >
									<Flexbox flexGrow={1} style={{ borderBottom: "1px solid #ccc" }} justifyContent="space-between">
										<h1>CHECKLIST IMPORT</h1>


									</Flexbox>
								</Flexbox>
								<Flexbox flexDirection='row' width="100%" paddingTop='10px'>

								</Flexbox>

							</Flexbox>
							{importCsv}
						</Loader>
					</Flexbox>
				</MediaQuery>
			</Flexbox>
		);
	}
}
}

CheckMateImport.contextTypes = {
	tree: PropTypes.baobab
};

export default branch({
	user: ['user'],
	checkMateImport: ['checkMateImport']
}, CheckMateImport);