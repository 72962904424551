import * as actions from "../actions/actions";
import * as utils from "../utils/utils";

import AWS from "aws-sdk";

import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
} from "amazon-cognito-identity-js";

import _ from "lodash";

import browserHistory from "../history";

import jsonTemplate from "../data/template.js";

import state from "../state/state";

import jsreport from "jsreport-browser-client-dist";

import globals from "../constants/globals.js";
import { cognito } from "../constants/globals.js";
import { contentBucket, miracheckContentBucket } from "../constants/globals.js";

import { isMobile } from "../utils/utils";

import FileSaver from "file-saver";

var timerTemplates = require("../data/timerTemplates.json");

AWS.config.update({ region: "us-east-1" });

export function promoteChecklist(keyPrefix, srcChecklistId) {
  /*
	Copy src json file to dstIdentityId folder
	Update dstIdentityId checklists.json with new checklist
	*/
  /*
		if (hasMaxChecklists()) {
			return;
		}
	*/
  // Can I cache all this user stuff?
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        alert(err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId,
        Logins: logins,
        region: cognito.region,
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            var s3 = new AWS.S3({ useDualStack: true });
            var bucket = new AWS.S3({
              useDualStack: true,
              params: {
                Bucket: contentBucket,
                ResponseContentType: "application/json",
                ResponseCacheControl: "max-age=0",
              },
            });
            bucket.getObject(
              { Key: keyPrefix + "/" + srcChecklistId + ".json" },
              function (err, data) {
                if (err) {
                  alert(err, err.stack);
                } else {
                  var checklist = JSON.parse(data.Body.toString());
                  addChecklistForPromote(checklist);
                }
              }
            );
          } else {
            showError("Add failed", err);
          }
        });
      });
    });
  }
}

function addChecklistForPromote(checklist) {
  var baseId = checklist.id;
  checklist.id = utils.generateUUID();
  if (checklist.hasOwnProperty("baseIds")) {
    checklist.baseIds.push(baseId);
  } else {
    var baseIds = [];
    baseIds.push(baseId);
    checklist.baseIds = baseIds;
  }
  // Need to think through all scenarios...for now allow people to delete anything
  checklist.cloned = false;

  var newDocument = {
    id: checklist.id,
    name: checklist.name,
    description: checklist.description,
    cloned: false,
    genre: checklist.genre,
    tags: checklist.tags,
    contributors: checklist.hasOwnProperty("contributors")
      ? checklist.contributors
      : [
          {
            name: state.get(["user", "name"]),
            username: state.get(["user", "username"]),
            nickname: state.get("user", "nickname"),
          },
        ],
    publisher: checklist.publisher,
    store: "",
    productPlaneId: "",
    image: "",
    shareCode: "",
    shareCodeReceived: "",
    version: "1.0",
    visible: true,
    speedType: "KIAS",
  };

  updateChecklistsAndPushToS3(checklist, newDocument);

  actions.showSuccess(
    "Added",
    "This checklist has been successfuly added to My Checklists."
  );
}
/*
export function updateChecklistsAndPushToS3ForPromote(checklist, newDocument=null,message="",ignoreContributors=false) {
	// Need to get documents from dst
	//var documents = state.get(["documents"]);

	var newDocuments = [];
	var saved = false;
	var template;
	for (var document of documents) {
		if (!document.hasOwnProperty("newDoc") || !document.newDoc) {
			if (checklist.id === document.id) {
				document.name = checklist.name;
				document.description = checklist.description;
				document.cloned = checklist.cloned;
				document.publisher = checklist.publisher;
				document.genre = checklist.genre;
				document.tags = checklist.tags;
				document.contributors = checklist.contributors;
			}
			newDocuments.push(document);
		} else if (document.hasOwnProperty("newDoc") && document.newDoc && checklist.id === document.id) {
			saved = true;
			document.newDoc = false;
			document.name = checklist.name;
			document.description = checklist.description;
			document.cloned = checklist.cloned;
			document.publisher = checklist.publisher;
			document.genre = checklist.genre;
			document.tags = checklist.tags;
			document.contributors = checklist.contributors;
			newDocuments.push(document);
		} else {
			template = document;
		}
	}

	if (newDocument != null) {
		newDocuments.push(newDocument);
	}

	utils.setDocuments(state,newDocuments);

	var files = [
		{
			fileName: "checklists.json",
			fileContent: newDocuments
		},
		{
			fileName: checklist.id + ".json",
			fileContent: checklist
		}
	]

	saveJsonFilesToS3(contentBucket,files,message);

	if (saved) {
		
		// Only push if no other new docs in array
		var newDocument = {
			"id": utils.generateUUID(),
			"newDoc": true,
			"name": "",
			"description": "",
			"genre": "",
			"tags": [],
			"contributors": [{name:state.get(["user","name"]), username:state.get(["user","username"]), nickname: state.get("user","nickname")}],
			"publisher": "self",
			"store": "",
			"productPlaneId": "",
			"shareCode": "",
			"shareCodeReceived": "",
			"version": "1.0",
			"cloned": false,
			"visible": true,
			"speedType": "KIAS"
		};
		state.get(["documents"]).push(newDocument);
	} else {
		state.get(["documents"]).push(template);
	}
}
*/
export function getHistory(tree, identityId) {
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        actions.showError("Failed getting session", err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(async function (err) {
          if (!err) {
            var checklistHistory = [];

            var keyPrefix = utils.getKeyPrefix(identityId);

            var s3 = new AWS.S3({ useDualStack: true });

            var params = {
              Bucket: contentBucket,
              Delimiter: "/",
              Prefix: keyPrefix + "/",
            };

            var listFoldersData = await s3.listObjects(params).promise();

            var folders = listFoldersData.CommonPrefixes;
            for (var i = 0; i < folders.length; i++) {
              var folder = folders[i];
              // Ignore assets folders
              if (
                !folder.Prefix.includes("/assets/") &&
                !folder.Prefix.includes("/userAssets/")
              ) {
                params.Prefix = folder.Prefix;

                var checklistId = folder.Prefix.split("/")[1];
                //console.log("Checklist ID: " + checklistId);

                var checklistHistoryItem = {
                  type: "checklistHistory",
                  id: checklistId,
                  name: "",
                  description: "",
                  instances: [],
                };

                checklistHistory.push(checklistHistoryItem);

                var filesData = await s3.listObjects(params).promise();

                //console.log(filesData);
                var files = filesData.Contents;
                for (var j = 0; j < files.length; j++) {
                  var file = files[j].Key;

                  // Need to get checklist instance using async await
                  //console.log("File: " + file);
                  var data = await s3
                    .getObject({ Bucket: contentBucket, Key: file })
                    .promise();

                  var checklistInstance = JSON.parse(data.Body.toString());
                  checklistHistoryItem.name = checklistInstance.name;
                  checklistHistoryItem.description =
                    checklistInstance.description;
                  checklistHistoryItem.instances.push(checklistInstance);
                }
              }
            }

            tree.set(["checklistHistory"], checklistHistory);
            //console.log(checklistHistory);
          } else {
            showError("Get history failed", err);
          }
        });
      });
    });
  }
}

export function touchHistoryFiles(identityId) {
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        actions.showError("Failed getting session", err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(async function (err) {
          if (!err) {
            var keyPrefix = utils.getKeyPrefix(identityId);

            var s3 = new AWS.S3({ useDualStack: true });

            var params = {
              Bucket: contentBucket,
              Delimiter: "/",
              Prefix: keyPrefix + "/",
            };

            var listFoldersData = await s3.listObjects(params).promise();

            var folders = listFoldersData.CommonPrefixes;
            for (var i = 0; i < folders.length; i++) {
              var folder = folders[i];
              // Ignore assets folders
              if (
                !folder.Prefix.includes("/assets/") &&
                !folder.Prefix.includes("/userAssets/")
              ) {
                params.Prefix = folder.Prefix;

                var filesData = await s3.listObjects(params).promise();

                var files = filesData.Contents;
                for (var j = 0; j < files.length; j++) {
                  var file = files[j].Key;

                  // Need to get checklist instance using async await
                  //console.log("File: " + file);
                  var data = await s3
                    .copyObject({
                      MetadataDirective: "REPLACE",
                      CopySource: "content.checklist.miralouaero.com/" + file,
                      Bucket: contentBucket,
                      Key: file,
                    })
                    .promise();
                }
              }
            }

            updateAttribute(cognitoUser, "custom:historyUpdated2", "true");
          } else {
            showError("Touch history files failed", err);
          }
        });
      });
    });
  }
}

function getNamedTimers(checklist) {
  let namedTimers = {};

  recurseGetNamedTimers(checklist.children, namedTimers);

  return namedTimers;
}

function recurseGetNamedTimers(arr, namedTimers) {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i].type == "list") {
    } else if (arr[i].type == "section") {
    } else if (arr[i].type == "item") {
      if (
        arr[i].hasOwnProperty("startNamedTimers") &&
        arr[i].startNamedTimers.length > 0
      ) {
        for (let timer of arr[i].startNamedTimers) {
          namedTimers[timer.value] = timer;
        }
      }
      if (
        arr[i].hasOwnProperty("stopNamedTimers") &&
        arr[i].stopNamedTimers.length > 0
      ) {
        for (let timer of arr[i].stopNamedTimers) {
          namedTimers[timer.value] = timer;
        }
      }
    }

    if (arr[i].hasOwnProperty("children")) {
      recurseGetNamedTimers(arr[i].children, namedTimers);
    }
  }
}

async function transferTimerTemplates(checklist, srcKeyPrefix) {
  // Look for any named timers being referenced and if there are any get the timer from userAssets/timerTemplates and add to this users timerTemplates
  // Recurse through checklist and look for named timers on an item
  const namedTimers = getNamedTimers(checklist);

  // Get src timerTemplates.json...await
  var srcTimerTemplates = await getJsonFileFromS3(
    contentBucket,
    "userAssets/timerTemplates.json",
    srcKeyPrefix
  );
  // Get dst timerTemplates.json...await
  var dstTimerTemplates = await getJsonFileFromS3(
    contentBucket,
    "userAssets/timerTemplates.json"
  );

  dstTimerTemplates = utils.cleanTimerTemplates(dstTimerTemplates);

  // Loop through namedTimers and copy data
  for (let guid in namedTimers) {
    for (let timer of srcTimerTemplates) {
      if (timer.guid === guid) {
        var foundTemplate = _.find(dstTimerTemplates, function (o) {
          return o.guid === timer.guid;
        });

        if (!foundTemplate) {
          dstTimerTemplates.push(timer);
        }
      }
    }
  }

  // Save dst timerTemplates.json
  try {
    await putJsonFileToS3(
      contentBucket,
      "userAssets/timerTemplates.json",
      dstTimerTemplates
    );
    state.set(["timerTemplates"], dstTimerTemplates);
  } catch (err) {
    alert("Error: " + err.message);
  }
}

export function receiveSharedChecklist(identityId, shareId) {
  console.log("In receiveSharedChecklist");
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        actions.showError("Failed getting session", err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            var s3 = new AWS.S3({ useDualStack: true });
            var keyPrefix = utils.getKeyPrefix(identityId);

            s3.getObject(
              { Bucket: contentBucket, Key: keyPrefix + "/checklists.json" },
              function (err, data) {
                if (!err) {
                  var checklists = JSON.parse(data.Body.toString());

                  var srcIdentityId = shareId.split(";")[0];
                  var srcChecklistId = shareId.split(";")[1];

                  var srcKeyPrefix = srcIdentityId;
                  s3.getObject(
                    {
                      Bucket: contentBucket,
                      Key: srcKeyPrefix + "/" + srcChecklistId + ".json",
                    },
                    async function (err, data) {
                      if (!err) {
                        var checklist = JSON.parse(data.Body.toString());

                        if (checklist.publisher === "checkmate") {
                          state.set(["appState", "checklistReceived"], true);
                          if (srcIdentityId === "public") {
                            state.set(
                              ["appState", "checklistReceivedMessage"],
                              "You can't receive a CheckMate checklist. You need to purchase one from Goose Cloud."
                            );
                          } else {
                            state.set(
                              ["appState", "checklistReceivedMessage"],
                              "You can't receive a CheckMate checklist. Use the Create Share Code action to share a CheckMate checklist."
                            );
                          }
                          return;
                        }

                        //console.log("WHAT!: " + JSON.stringify(checklist));

                        try {
                          await transferTimerTemplates(checklist, srcKeyPrefix);
                        } catch (err) {
                          alert("Error: " + err.message);
                        }

                        if (srcIdentityId === "public") {
                          clonePublicDocument({ id: srcChecklistId }, true);
                        } else {
                          var newChecklist = {};
                          newChecklist.identityId = srcIdentityId;
                          newChecklist.id = checklist.id;
                          newChecklist.name = checklist.name;
                          newChecklist.description = checklist.description;
                          newChecklist.genre = checklist.genre;
                          newChecklist.tags = checklist.tags;
                          newChecklist.publisher = checklist.publisher;
                          newChecklist.store =
                            checklist.store != null ? checklist.store : "";
                          newChecklist.productPlaneId =
                            checklist.productPlaneId != null
                              ? checklist.productPlaneId
                              : "";
                          newChecklist.shareCode =
                            checklist.shareCode != null
                              ? checklist.shareCode
                              : "";
                          newChecklist.shareCodeReceived =
                            checklist.shareCodeReceived != null
                              ? checklist.shareCodeReceived
                              : "";
                          newChecklist.version =
                            checklist.version != null
                              ? checklist.version
                              : "1.0";
                          newChecklist.cloned = checklist.cloned;
                          newChecklist.visible =
                            checklist.visible != null
                              ? checklist.visible
                              : true;
                          newChecklist.speedType = checklist.speedType;
                          newChecklist.contributors = checklist.contributors;
                          newChecklist.image = checklist.hasOwnProperty("image") ? checklist.image : "";

                          // Only add if it doesn't already exist
                          if (!_.find(checklists, newChecklist)) {
                            //console.log(newChecklist);
                            checklists.push(newChecklist);
                            // Write out new checklists.json to S3
                            saveJsonToS3(
                              checklists,
                              contentBucket,
                              "checklists.json",
                              "",
                              false
                            );

                            /*
												setTimeout(function() {
													const path = "/checklists";
													browserHistory.push(path);								
												}, 3000);
			*/
                          }

                          state.set(["appState", "checklistReceived"], true);
                        }
                      } else {
                        state.set(
                          ["appState", "checklistReceivedMessage"],
                          "There was an error receiving the checklist. " +
                            err.message
                        );
                        state.set(["appState", "checklistReceived"], true);
                      }
                    }
                  );
                } else {
                  state.set(
                    ["appState", "checklistReceivedMessage"],
                    "There was an error receiving the checklist. " + err.message
                  );
                  state.set(["appState", "checklistReceived"], true);
                }
                //actions.receiveDocuments(checklists);
              }
            );
          } else {
            state.set(
              ["appState", "checklistReceivedMessage"],
              "There was an error receiving the checklist. " + err.message
            );
            state.set(["appState", "checklistReceived"], true);
            //showError("Receive Checklist Failed",err);
          }
        });
      });
    });
  }
}

// With multiple devices a user may overwrite the checklists.json from one device. This will restore any checklists that are lost.
function reconstructChecklistsCollection(tree, identityId, checklists) {
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        actions.showError("Failed getting session", err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(async function (err) {
          if (!err) {
            var keyPrefix = utils.getKeyPrefix(identityId);

            var s3 = new AWS.S3({ useDualStack: true });

            var params = {
              Bucket: contentBucket,
              Delimiter: "/",
              Prefix: keyPrefix + "/",
            };

            var filesData = await s3.listObjects(params).promise();

            var files = filesData.Contents;
            for (var i = 0; i < files.length; i++) {
              var file = files[i].Key.replace(keyPrefix + "/", "").replace(
                ".json",
                ""
              );

              if (
                file !== "user" &&
                file !== "checklists" &&
                file !== "timerTemplates"
              ) {
                // Check if in checklists array, if not create new object and append
                var found = false;
                for (var j = 0; j < checklists.length; j++) {
                  var currentChecklist = checklists[j];
                  if (file === currentChecklist.id) {
                    found = true;
                  }
                }

                if (!found) {
                  var data = await s3
                    .getObject({
                      Bucket: contentBucket,
                      Key: keyPrefix + "/" + file + ".json",
                    })
                    .promise();

                  // Reconstruct items need for checklists.json
                  var checklist = JSON.parse(data.Body.toString());

                  var newChecklist = {};
                  newChecklist.id = checklist.id;
                  newChecklist.name = checklist.name;
                  newChecklist.description = checklist.description;
                  newChecklist.genre = checklist.genre;
                  newChecklist.tags = checklist.tags;
                  newChecklist.publisher = checklist.publisher;
                  newChecklist.store =
                    checklist.store != null ? checklist.store : "";
                  newChecklist.productPlaneId =
                    checklist.productPlaneId != null
                      ? checklist.productPlaneId
                      : "";
                  newChecklist.shareCode =
                    checklist.shareCode != null ? checklist.shareCode : "";
                  newChecklist.shareCodeReceived =
                    checklist.shareCodeReceived != null
                      ? checklist.shareCodeReceived
                      : "";
                  newChecklist.version =
                    checklist.version != null ? checklist.version : "1.0";
                  newChecklist.cloned = checklist.cloned;
                  newChecklist.visible = true;
                  newChecklist.speedType = checklist.speedType;
                  newChecklist.contributors = checklist.contributors;
                  newChecklist.image = checklist.hasOwnProperty("image") ? checklist.image : "";

                  checklists.push(newChecklist);
                }
              }
            }

            // Write out new checklists.json to S3
            saveJsonToS3(
              checklists,
              contentBucket,
              "checklists.json",
              "",
              false
            );

            //actions.receiveDocuments(checklists);
          } else {
            showError("Reconstruct Checklists Collection Failed", err);
          }
        });
      });
    });
  }
}

/*
export function putChecklistsPublic(tree,username,bucket,keyPrefix,jsonDocuments) {
	// Loop through all checklists and create checklists in memory
	var poolData = {
		UserPoolId : cognito.userPoolId, // Your user pool id here
		ClientId : cognito.clientId // Your client id here
	};
	var userPool = new CognitoUserPool(poolData);
	var cognitoUser = userPool.getCurrentUser();

	if (cognitoUser != null) {
		cognitoUser.getSession(function(err, session) {
			if (err) {
				alert(err);
				return;
			}
			console.log('session validity: ' + session.isValid());

			var logins = {};
			logins[cognito.logins] = session.getIdToken().getJwtToken();

			AWS.config.credentials = new AWS.CognitoIdentityCredentials({
				IdentityPoolId :  cognito.identityPoolId, // your identity pool id here
				Logins : logins,
				region: "us-east-1"
			});

			AWS.config.credentials.refresh(function(){
				AWS.config.credentials.get(async function(err) {
					if (!err) {
						var checklists = [];

						var s3 = new AWS.S3({useDualStack: true});

						var params = { 
							Bucket: contentBucket,
							Delimiter: "/",
							Prefix: keyPrefix + "/"
						}

						var listFoldersData = await s3.listObjects(params).promise();

						var files= listFoldersData.Contents;
						for (var j=0; j<files.length; j++) {
							var file = files[j].Key;
							console.log("File: " + file);
							if (file !== "public/") {
								// Need to get checklist instance using async await
								//console.log("File: " + file);
								var data = await s3.getObject({Bucket: contentBucket, Key: file}).promise();
								var checklist = JSON.parse(data.Body.toString());

								var checklistItem = {
									id: checklist.id,
									name: checklist.name,
									description: checklist.description,
									cloned: checklist.cloned,
									genre: checklist.genre,
									tags: checklist.tags,
									publisher: checklist.publisher,
									store: "",
									productPlaneId: "",
									shareCode: "",
									shareCodeReceived: "",
									version: "1.0",
									visible: true,
									speedType: "kias",
									privacy: "public"
								}

								checklists.push(checklistItem);
							}
						}

						actions.receiveDocuments(checklists);
						
						utils.hideLoader(tree);

						state.set(["user","loggedIn"],true);
						state.set(["user","username"],username);
						state.set(["appState","navPanel","selected"],"home");
						const path = "/home";
						browserHistory.push(path);	
					}
				});
			});
		});
	}
}
*/
export async function getChecklistsCollection(
  tree,
  username,
  bucket,
  keyPrefix,
  jsonDocuments
) {
  // Get print templates...probably a better place to put
  var templates = await getPrintTemplates();

  var filteredTemplates = [];
  for (var i = 0; i < templates.length; i++) {
    var template = templates[i];
    if (template.name.startsWith("miracheck-")) {
      filteredTemplates.push(template);
    }
  }

  state.set(["printTemplates"], filteredTemplates);

  bucket.getObject(
    { Key: keyPrefix + "/checklists.json" },
    function (err, data) {
      if (err) {
        var putBatch = function putBatch(files) {
          return Promise.all(
            files.map(function (file) {
              var body;
              if (file == "checklists.json") {
                body = JSON.stringify(jsonDocuments);
              }
              return bucket
                .putObject({
                  Key: keyPrefix + "/" + file,
                  ContentType: "application/json",
                  Body: body,
                })
                .promise();
            })
          );
        };

        var files = ["checklists.json"];

        putBatch(files)
          .then(function () {
            reconstructChecklistsCollection(tree, keyPrefix, jsonDocuments);
            //actions.receiveDocuments(jsonDocuments);

            if (utils.isMobile()) {
              if (!getChecklists(username, keyPrefix, bucket)) {
                utils.hideLoader(tree);
              }
            } else {
              navigate(true);
            }
          })
          .catch(function () {
            utils.hideLoader(tree);
            console.log("Error putiing batch uploads!");
          });
      } else {
        var checklists = JSON.parse(data.Body.toString());
        utils.clearFilters(tree, checklists);
        reconstructChecklistsCollection(tree, keyPrefix, checklists);
        //actions.receiveDocuments(checklists);

        if (utils.isMobile()) {
          if (!getChecklists(username, keyPrefix, bucket)) {
            //utils.hideLoader(tree);
          }
        } else {
          navigate(true);
        }
      }
    }
  );
}

export async function getChecklists(username, keyPrefix, bucket, nav = true) {
  var originalKeyPrefix = keyPrefix;
  var getBatch = function getBatch(checklists) {
    return Promise.all(
      checklists.map(function (checklist) {
        if (checklist.hasOwnProperty("identityId")) {
          keyPrefix = checklist.identityId;
        } else {
          keyPrefix = originalKeyPrefix;
        }
        return bucket
          .getObject({ Key: keyPrefix + "/" + checklist.id + ".json" })
          .promise()
          .then(function (data) {
            var jsonChecklist = JSON.parse(data.Body.toString());

            // Should convert to make sure has at least one list and one section and set proper type
            jsonChecklist = utils.normalizeChecklist(jsonChecklist);

            jsonChecklist.visible = checklist.visible;

            // Get speed for checklist from document
            if (checklist.hasOwnProperty("speedType")) {
              jsonChecklist.speedType = checklist.speedType;
              jsonChecklist.preferences.speedType = checklist.speedType;
            }

            // Find checklist with same key and replace - only if visible
            for (var i = 0; i < newChecklists.checklists.length; i++) {
              if (
                newChecklists.checklists[i].id === jsonChecklist.id &&
                jsonChecklist.visible
              ) {
                newChecklists.checklists[i] = jsonChecklist;
              }
            }
          })
          .catch(function (err) {
            console.log("Error getting checklist " + checklist.id + "; " + err);
          });
      })
    );
  };
  var user = state.get(["user"]);

  // newChecklists is what pass to mobile app. Aggregate users, content and checklists
  var newChecklists = {
    user: {
      username: username,
      createDate: user.hasOwnProperty("createDate") ? user.createDate : null,
      scr: utils.scr,
      savePassword: utils.saveScr,
      identityId: keyPrefix,
      jwt: user.jwt,
      subscriptionPlan: user.subscriptionPlan,
      subscriptionExpiration: user.hasOwnProperty("subscriptionExpiration")
        ? user.subscriptionExpiration
        : 0,
    },
    checklists: [],
    timerTemplates: timerTemplates,
  };

  try {
    var timersData = await bucket
      .getObject({ Key: keyPrefix + "/userAssets/timerTemplates.json" })
      .promise();

    var newTimerTemplates = JSON.parse(timersData.Body.toString());

    newChecklists.timerTemplates = newTimerTemplates;
  } catch (err) {
    console.log("Error getting timer templates: " + err);
  }

  bucket.getObject(
    { Key: keyPrefix + "/checklists.json" },
    function (err, data) {
      if (err) {
        console.log(err, err.stack);
      } else {
        var checklists = JSON.parse(data.Body.toString());

        newChecklists.checklists = checklists;

        // If Version 3
        var clientVersion = state.get("clientVersion");
        if (
          clientVersion &&
          (clientVersion.startsWith("3") ||
            clientVersion.startsWith("4") ||
            clientVersion.startsWith("5") ||
            clientVersion.startsWith("6"))
        ) {
          state.set(["user", "loggedIn"], true);
          state.set(["user", "username"], username);
          state.set(["user", "createdDate"], user);

          // Need to get content for the app
          bucket.getObject(
            {
              Key: "apps/miraCheckCoPilot/content/en-US/miraCheckCoPilot-en-US.json",
            },
            function (err, data) {
              if (err) {
                console.log(err, err.stack);
              } else {
                var content = JSON.parse(data.Body.toString());
                newChecklists.content = content;
                //var mobile = false;

                // Only want to do if calling from mobile app
                // WebView
                try {
                  window.ReactNativeWebView.postMessage(
                    JSON.stringify(newChecklists)
                  );
                  //mobile = true;
                  //const path = "/loggedIn";
                  //browserHistory.push(path);
                } catch (e) {}
                // WKWebView
                try {
                  window.webkit.messageHandlers.reactNative.postMessage(
                    JSON.stringify(newChecklists)
                  );
                  //mobile = true;
                  //const path = "/loggedIn";
                  //browserHistory.push(path);
                } catch (e) {}
                // UIWebView
                try {
                  window.postMessage(JSON.stringify(newChecklists));
                  //mobile = true;
                  //const path = "/loggedIn";
                  //browserHistory.push(path);
                } catch (e) {}

                navigate(nav);

                console.log("Get success");
                return true;
              }
            }
          );
          //Else
        } else {
          getBatch(checklists)
            .then(function () {
              state.set(["user", "loggedIn"], true);
              state.set(["user", "username"], username);

              // Need to get content for the app
              bucket.getObject(
                {
                  Key: "apps/miraCheckCoPilot/content/en-US/miraCheckCoPilot-en-US.json",
                },
                function (err, data) {
                  if (err) {
                    console.log(err, err.stack);
                  } else {
                    var content = JSON.parse(data.Body.toString());
                    newChecklists.content = content;
                    //var mobile = false;

                    // Only want to do if calling from mobile app
                    // WebView
                    try {
                      window.ReactNativeWebView.postMessage(
                        JSON.stringify(newChecklists)
                      );
                      //mobile = true;
                      //const path = "/loggedIn";
                      //browserHistory.push(path);
                    } catch (e) {}
                    // WKWebView
                    try {
                      window.webkit.messageHandlers.reactNative.postMessage(
                        JSON.stringify(newChecklists)
                      );
                      //mobile = true;
                      //const path = "/loggedIn";
                      //browserHistory.push(path);
                    } catch (e) {}
                    // UIWebView
                    try {
                      window.postMessage(JSON.stringify(newChecklists));
                      //mobile = true;
                      //const path = "/loggedIn";
                      //browserHistory.push(path);
                    } catch (e) {}

                    navigate(nav);

                    console.log("Get success");
                    return true;
                  }
                }
              );
            })
            .catch(function () {
              console.error.bind(console);
              console.log("Error getting batch checklists!");
              return false;
            });
        }
      }
    }
  );
}

function navigate(nav) {
  // debugger;
  var user = state.get(["user"]);

  // Have to deal with iTunes subscription and if expired
  var expired = false;
  var current = new Date().getTime();
  var subscriptionPlan;
  if (user.hasOwnProperty("subscriptionPlan")) {
    subscriptionPlan = state.get(["user", "subscriptionPlan"]);
  } else {
    subscriptionPlan = "no-plan";
  }
  if (user.hasOwnProperty("subscriptionExpiration")) {
    var subscriptionExpiration = state.get(["user", "subscriptionExpiration"]);
  } else {
    var subscriptionExpiration = 0;
  }

  if (
    (user.subscriptionPlan === "pro-plan-yearly-itunes" ||
      user.subscriptionPlan === "pro-plan-monthly-itunes") &&
    current > user.subscriptionExpiration
  ) {
    expired = true;
  } else {
    expired = false;
  }

  if (nav /* && !mobile*/) {
    var urlParams = state.get(["appState", "urlParams"]);
    if (
      urlParams.subscriptionPlan === "no-plan" ||
      urlParams.subscriptionPlan === "basic-plan" ||
      urlParams.subscriptionPlan === "standard-plan" ||
      urlParams.subscriptionPlan === "pro-plan"
    ) {
      if (subscriptionPlan === "pro-plan-unlimited") {
        alert(
          "You are already in the Pro Plan (Unlimited) plan. This plan has all features and never expires. There is no need to change plans."
        );
      } else if (subscriptionPlan.includes("itunes")) {
        alert(
          "You are already in a plan purchased through the app. You will need to let that expire before you can purchase a plan from the website. Email support@miralouaero.com if you have questions."
        );
      }
    }

    if (urlParams.updateCard === "true") {
      const path = "/updateCustomerCard";
      browserHistory.push(path);
    } else if (urlParams.purchaseCustom === "true") {
      const path = "/purchaseCustom";
      browserHistory.push(path);
    } else if (
      urlParams.hasOwnProperty("voucher") &&
      urlParams.voucher !== ""
    ) {
      const path = "/redeemVoucher";
      browserHistory.push(path);
    } else if (
      expired ||
      ((urlParams.subscriptionPlan === "no-plan" ||
        urlParams.subscriptionPlan === "basic-plan" ||
        urlParams.subscriptionPlan === "standard-plan" ||
        urlParams.subscriptionPlan === "pro-plan") &&
        (subscriptionPlan === "no-plan" ||
          subscriptionPlan === "basic-plan" ||
          subscriptionPlan === "basic-plan-checkmate" ||
          subscriptionPlan === "standard-plan-unlimited" ||
          subscriptionPlan === "pro-plan-monthly" ||
          subscriptionPlan === "pro-plan-yearly" ||
          subscriptionPlan === "pro-plan-per-year" ||
          subscriptionPlan === "stabdard-plan-per-year"))
    ) {
      const path = "/subscribe";
      browserHistory.push(path);
    } else if (
      urlParams.subscriptionPlan === "pro-plan-performance-as-intro-499"
    ) {
      const path = "/perfTrialCheckout";
      browserHistory.push(path);
    } else {
      let path = "/home";
      let userInfo = state.select("user").get();
      /**
       * entryPoint['appState','navPanel']
       */
      //entryPoint
      let targetNavPanel = "home";
      if (userInfo && userInfo.entryPoint && userInfo.entryPoint !== "") {
        if (userInfo.entryPoint.indexOf("/search") !== -1) {
          targetNavPanel = "search";
        } else if (userInfo.entryPoint.indexOf("/myHangar") !== -1) {
          targetNavPanel = "myHangar";
        } else if (userInfo.entryPoint.indexOf("/history") !== -1) {
          targetNavPanel = "history";
        } else if (userInfo.entryPoint.indexOf("/timers") !== -1) {
          targetNavPanel = "timers";
        } else if (userInfo.entryPoint.indexOf("/import") !== -1) {
          targetNavPanel = "home";
        } else if (userInfo.entryPoint.indexOf("/afmpoh") !== -1) {
          targetNavPanel = "afmpoh";
        } else if (userInfo.entryPoint.indexOf("/flightschools") !== -1) {
          targetNavPanel = "flightschools";
        }
        path = userInfo.entryPoint;
      }
      state.set(["appState", "navPanel", "selected"], targetNavPanel);
      browserHistory.push(path);
    }
  }
}

export function uploadFileToS3(bucket, keyPrefix, file) {
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        actions.showError("Failed getting session", err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        // Instantiate aws sdk service objects now that the credentials have been updated.
        var myBucket = new AWS.S3({
          useDualStack: true,
          params: { Bucket: bucket },
        });

        var params = {
          Key: keyPrefix + "/" + file.name,
          ContentType: file.type,
          Body: file,
        };
        myBucket.upload(params, function (err, data) {
          var result = err ? "ERROR!" : "SAVED.";
          console.log(result);
        });
      });
    });
  }
}

export function uploadFilesToS3(bucketName, files) {
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        actions.showError("Failed getting session", err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });
      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            var keyPrefix =
              utils.getKeyPrefix(AWS.config.credentials.identityId) + "/assets";

            // Instantiate aws sdk service objects now that the credentials have been updated.
            var bucket = new AWS.S3({
              useDualStack: true,
              params: { Bucket: bucketName },
            });

            for (var i = 0; i < files.length; i++) {
              var file = files[i];
              if (file) {
                // get file contents in memory
                var reader = new FileReader();
                reader.readAsText(file, "UTF-8");
                reader.onload = function (evt) {
                  var csvContent = evt.target.result;

                  var event = {
                    csvContent: csvContent,
                    name: file.name.split(".")[0],
                    checklistId: utils.generateUUID(),
                  };

                  console.log(csvContent);
                  // invoke lambda to do import
                  var lambda = new AWS.Lambda({
                    region: "us-east-1",
                    apiVersion: "2015-03-31",
                  });

                  var params = {
                    FunctionName: globals.lambdaFunctionNames.importCsv,
                    InvocationType: "RequestResponse",
                    LogType: "None",
                    Payload: JSON.stringify(event),
                  };

                  lambda.invoke(params, function (error, data) {
                    if (error) {
                      alert(error);
                    } else {
                      console.log(data.Payload);
                      var checklist = JSON.parse(data.Payload);

                      checklist.contributors = [
                        {
                          name: state.get("user", "name"),
                          username: state.get("user", "username"),
                          nickname: state.get("user", "nickname"),
                        },
                      ];

                      // Upload new file
                      importChecklistFromCsv(checklist);
                    }
                  });
                };
                reader.onerror = function (evt) {
                  alert("Error reading file");
                };

                var ts = new Date().getTime();

                // Upload to S3 and S3 triggers conversion
                var params = {
                  Key: keyPrefix + "/" + ts + "-" + file.name,
                  ContentType: file.type,
                  Body: file,
                };
                bucket.upload(params, function (err, data) {
                  // Successfully have file on S3
                  var result = err ? "ERROR!" : "SAVED.";
                  console.log(result);
                });
              }
            }
          } else {
            showError("Import failed", err);
          }
        });
      });
    });
  }
}

export function uploadMediaFileToS3(bucketName, file, fileName, type) {
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        actions.showError("Failed getting session", err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });
      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            var keyPrefix = type;

            // Instantiate aws sdk service objects now that the credentials have been updated.
            var bucket = new AWS.S3({
              useDualStack: true,
              params: { Bucket: bucketName },
            });

            //var ts = new Date().getTime();

            // Upload to S3 and S3 triggers conversion
            var params = {
              Key: keyPrefix + "/" + fileName,
              ContentType: file.type,
              Body: file,
            };
            bucket.upload(params, function (err, data) {
              // Successfully have file on S3
              var result = err ? "ERROR!" : "SAVED.";
              console.log(result);
            });
          } else {
            showError("Import failed", err);
          }
        });
      });
    });
  }
}

export function logToCloudWatch(logData) {
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        actions.showError("Failed getting session", err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });
      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            var event = {
              logData: logData,
            };

            // invoke lambda to do import
            var lambda = new AWS.Lambda({
              region: "us-east-1",
              apiVersion: "2015-03-31",
            });

            var params = {
              FunctionName: globals.lambdaFunctionNames.logToCloudWatch,
              InvocationType: "RequestResponse",
              LogType: "None",
              Payload: JSON.stringify(event),
            };

            lambda.invoke(params, function (error, data) {
              // if (error) {
              // 	alert(error);
              // } else {
              // 	alert(data.Payload);
              // }
            });
          } else {
            showError("logToCloudWatch", err);
          }
        });
      });
    });
  }
}

export function logToCloudWatch2(logData) {
  var event = {
    logData: logData,
  };

  // invoke lambda to do import
  var lambda = new AWS.Lambda({
    region: "us-east-1",
    apiVersion: "2015-03-31",
  });

  var params = {
    FunctionName: globals.lambdaFunctionNames.logToCloudWatch,
    InvocationType: "RequestResponse",
    LogType: "None",
    Payload: JSON.stringify(event),
  };

  lambda.invoke(params);
}

export function uploadRandomSpoonacularRecipeToS3(bucketName) {
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        actions.showError("Failed getting session", err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });
      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            // Instantiate aws sdk service objects now that the credentials have been updated.
            var bucket = new AWS.S3({
              useDualStack: true,
              params: { Bucket: bucketName },
            });

            var event = {
              checklistId: utils.generateUUID(),
            };

            // invoke lambda to do import
            var lambda = new AWS.Lambda({
              region: "us-east-1",
              apiVersion: "2015-03-31",
            });

            var params = {
              FunctionName: globals.lambdaFunctionNames.getRecipeInfo,
              InvocationType: "RequestResponse",
              LogType: "None",
              Payload: JSON.stringify(event),
            };

            lambda.invoke(params, function (error, data) {
              if (error) {
                alert(error);
              } else {
                console.log(data.Payload);
                var checklist = JSON.parse(data.Payload);

                checklist.contributors.push({
                  name: state.get("user", "name"),
                  username: state.get("user", "username"),
                  nickname: state.get("user", "nickname"),
                });

                // Upload new file
                importChecklist(checklist);
              }
            });
          } else {
            showError("Upload failes", err);
          }
        });
      });
    });
  }
}

// Getting image data directly from S3
// Our new approach uses Cloudfront to get the image
export function showImage() {
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        actions.showError("Failed getting session", err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            var keyPrefix = utils.getKeyPrefix(
              AWS.config.credentials.identityId
            );

            var s3 = new AWS.S3({ useDualStack: true });
            var bucket = new AWS.S3({
              useDualStack: true,
              params: { Bucket: "assets.pricer.miralouaero.com" },
            });

            bucket.getObject(
              { Key: keyPrefix + "/" + "DSCN0008.JPG" },
              function (err, data) {
                if (err) {
                  console.log(err, err.stack);
                } else {
                  var imgData = data.Body;
                }
              }
            );
          } else {
            showError("Error", err);
          }
        });
      });
    });
  }
}

export function loadJsonFromS3(bucketName, checklistId, doc) {
  const fileName = checklistId + ".json";
  // Can I cache all this user stuff?
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        actions.showError("Failed getting session", err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            utils.showLoader(state, "loadingSpinner");

            var keyPrefix = utils.getKeyPrefix(
              AWS.config.credentials.identityId
            );

            var s3 = new AWS.S3({ useDualStack: true });
            var bucket = new AWS.S3({
              useDualStack: true,
              params: {
                Bucket: bucketName,
                ResponseContentType: "application/json",
                ResponseCacheControl: "max-age=0",
              },
            });
            bucket.getObject(
              { Key: keyPrefix + "/" + fileName },
              function (err, data) {
                if (err) {
                  utils.hideLoader(state, "loadingSpinner");

                  if (!hasMaxChecklists()) {
                    var id = fileName.split(".")[0];
                    var checklist = jsonTemplate;
                    checklist.id = id;
                    checklist.cloned = false;
                    checklist.publisher = "self";

                    checklist.contributors = [
                      {
                        name: state.get("user", "name"),
                        username: state.get("user", "username"),
                        nickname: state.get("user", "nickname"),
                      },
                    ];
                    actions.receiveData(checklist, checklistId);
                    //console.log(err, err.stack);
                  }
                } else {
                  console.log(JSON.stringify(doc));
                  var checklist = JSON.parse(data.Body.toString());

                  // TODO: hack until fux source data in migrate properly
                  if (doc != null) {
                    checklist.cloned = doc.cloned;
                    if (doc.contributors == null) {
                      checklist.contributors = [
                        {
                          name: state.get("user", "name"),
                          username: state.get("user", "username"),
                          nickname: state.get("user", "nickname"),
                        },
                      ];
                    } else {
                      checklist.contributors = doc.contributors;
                    }
                  }

                  actions.receiveData(checklist, checklistId);

                  utils.hideLoader(state, "loadingSpinner");
                }
              }
            );
          } else {
            showError("Getting checklists failed", err);
          }
        });
      });
    });
  }
}

// TODO: Complete one-off...can reuse function above if make async/await
export function previewJsonFromS3(tree, bucketName, fileName) {
  // Can I cache all this user stuff?
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        actions.showError("Failed getting session", err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            utils.showLoader(state, "loadingSpinner");

            var keyPrefix = "public";

            var s3 = new AWS.S3({ useDualStack: true });
            var bucket = new AWS.S3({
              useDualStack: true,
              params: {
                Bucket: bucketName,
                ResponseContentType: "application/json",
                ResponseCacheControl: "max-age=0",
              },
            });
            bucket.getObject(
              { Key: keyPrefix + "/" + fileName },
              function (err, data) {
                if (err) {
                  utils.hideLoader(state, "loadingSpinner");

                  actions.showError("Unable to get checklist", err);
                } else {
                  // Set previewChecklist
                  var jsonChecklist = JSON.parse(data.Body.toString());
                  // Should convert to make sure has at least one list and one section and set proper type
                  jsonChecklist = utils.normalizeChecklist(jsonChecklist);

                  tree.set(
                    ["appState", "previewChecklist", "checklist"],
                    jsonChecklist
                  );
                  tree.set(["appState", "previewChecklist", "showModal"], true);
                  utils.hideLoader(state, "loadingSpinner");
                }
              }
            );
          } else {
            showError("Preview failed", err);
          }
        });
      });
    });
  }
}

export function showNotes(tree, bucketName, fileName) {
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        actions.showError("Failed getting session", err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          utils.showLoader(state, "loadingSpinner");

          fileName = fileName.replace(
            "https://s3.amazonaws.com/content.checklist.miralouaero.com/",
            ""
          );
          const bucket = new AWS.S3({
            useDualStack: true,
            params: {
              Bucket: bucketName,
              ResponseContentType: "application/json",
              ResponseCacheControl: "max-age=0",
            },
          });
          bucket.getObject({ Key: `${fileName}` }, (err, data) => {
            if (err) {
              utils.hideLoader(state, "loadingSpinner");

              showError("Unable to get checklist", err);
            } else {
              // Set previewChecklist
              let jsonChecklist = JSON.parse(data.Body.toString());
              // Should convert to make sure has at least one list and one section and set proper type
              jsonChecklist = utils.normalizeChecklist(jsonChecklist);

              tree.set(["appState", "showNotes", "checklist"], jsonChecklist);
              tree.set(["appState", "showNotes", "showModal"], true);

              utils.hideLoader(state, "loadingSpinner");
            }
          });
        });
      });
    });
  }
}

export function showDetailedStatus(tree, bucketName, fileName) {
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        actions.showError("Failed getting session", err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          utils.showLoader(state, "loadingSpinner");

          fileName = fileName.replace(
            "https://s3.amazonaws.com/content.checklist.miralouaero.com/",
            ""
          );

          const bucket = new AWS.S3({
            useDualStack: true,
            params: {
              Bucket: bucketName,
              ResponseContentType: "application/json",
              ResponseCacheControl: "max-age=0",
            },
          });
          bucket.getObject({ Key: `${fileName}` }, (err, data) => {
            if (err) {
              utils.hideLoader(state, "loadingSpinner");

              showError("Unable to get checklist", err);
            } else {
              // Set previewChecklist
              let jsonChecklist = JSON.parse(data.Body.toString());
              // Should convert to make sure has at least one list and one section and set proper type
              jsonChecklist = utils.normalizeChecklist(jsonChecklist);

              tree.set(
                ["appState", "showDetailedStatus", "checklist"],
                jsonChecklist
              );
              tree.set(["appState", "showDetailedStatus", "showModal"], true);
              utils.hideLoader(state, "loadingSpinner");
            }
          });
        });
      });
    });
  }
}

export function showDetailedHistory(tree, bucketName, fileName) {
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        actions.showError("Failed getting session", err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          utils.showLoader(state, "loadingSpinner");

          fileName = fileName.replace(
            "https://s3.amazonaws.com/content.checklist.miralouaero.com/",
            ""
          );
          const bucket = new AWS.S3({
            useDualStack: true,
            params: {
              Bucket: bucketName,
              ResponseContentType: "application/json",
              ResponseCacheControl: "max-age=0",
            },
          });
          bucket.getObject({ Key: `${fileName}` }, (err, data) => {
            if (err) {
              utils.hideLoader(state, "loadingSpinner");

              showError("Unable to get checklist", err);
            } else {
              // Set previewChecklist
              let jsonChecklist = JSON.parse(data.Body.toString());
              // Should convert to make sure has at least one list and one section and set proper type
              jsonChecklist = utils.normalizeChecklist(jsonChecklist);

              tree.set(
                ["appState", "showDetailedHistory", "checklist"],
                jsonChecklist
              );
              tree.set(["appState", "showDetailedHistory", "showModal"], true);
              utils.hideLoader(state, "loadingSpinner");
            }
          });
        });
      });
    });
  }
}

export function printInstance(
  tree,
  bucketName,
  fileName,
  template = "miracheck-lists-form"
) {
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        actions.showError("Failed getting session", err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          utils.showLoader(state, "loadingSpinner");

          fileName = fileName.replace(
            "https://s3.amazonaws.com/content.checklist.miralouaero.com/",
            ""
          );

          const bucket = new AWS.S3({
            useDualStack: true,
            params: {
              Bucket: bucketName,
              ResponseContentType: "application/json",
              ResponseCacheControl: "no-cache",
            },
          });
          bucket.getObject({ Key: `${fileName}` }, (err, data) => {
            if (err) {
              utils.hideLoader(state, "loadingSpinner");

              showError("Unable to get checklist", err);
            } else {
              // Set previewChecklist
              let jsonChecklist = JSON.parse(data.Body.toString());
              // Should convert to make sure has at least one list and one section and set proper type
              jsonChecklist = utils.normalizeChecklist(jsonChecklist);

              utils.recurseReplaceSpeeds(jsonChecklist.children);
              utils.recurseConvertChecklistToMarkdown(
                jsonChecklist.children,
                false
              );

              let printFilename = "";
              let lastUpdated = "";
              if (jsonChecklist.history.length > 0) {
                lastUpdated =
                  jsonChecklist.history[jsonChecklist.history.length - 1]
                    .timestamp;
              }

              if (lastUpdated !== "") {
                printFilename = jsonChecklist.name + " - " + lastUpdated;
              } else {
                printFilename = jsonChecklist.name;
              }

              printTemplate(printFilename, jsonChecklist, template);
            }
          });
        });
      });
    });
  }
}

export function getAllChecklists(tree) {
  // Can I cache all this user stuff?
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        actions.showError("Failed getting session", err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            var keyPrefix = utils.getKeyPrefix(
              AWS.config.credentials.identityId
            );

            var s3 = new AWS.S3({ useDualStack: true });
            var bucket = new AWS.S3({
              useDualStack: true,
              params: {
                Bucket: contentBucket,
                ResponseContentType: "application/json",
                ResponseCacheControl: "max-age=0",
              },
            });
            if (
              !getChecklists(
                cognitoUser.getUsername(),
                keyPrefix,
                bucket,
                false
              )
            ) {
              utils.hideLoader(tree);
            }
          } else {
            showError("Get checklists failed", err);
          }
        });
      });
    });
  }
}

export function saveJsonToS3WithKeyPrefix(
  object,
  bucketName,
  keyPrefix,
  fileName,
  message = ""
) {
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        actions.showError("Failed getting session", err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            // Instantiate aws sdk service objects now that the credentials have been updated.
            var bucket = new AWS.S3({
              useDualStack: true,
              params: { Bucket: bucketName },
            });
            var params = {
              Key: keyPrefix + "/" + fileName,
              ContentType: "application/json",
              Body: JSON.stringify(object),
            };
            bucket.upload(params, function (err, data) {
              var result = err ? "ERROR!" : "SAVED.";
              console.log(result);
              // Replace with notification
              if (!err) {
                if (message != "") {
                  actions.showSuccess("Successfully saved", message);
                }
              } else {
                actions.showError("Failed to save", err.message);
              }
            });
          } else {
            showError("Save failed", err);
          }
        });
      });
    });
  }
}

export function saveHistory2(bucketName, files) {
  var keyPrefix = utils.getKeyPrefix(AWS.config.credentials.identityId);

  putBatch(bucketName, keyPrefix, files)
    .then(() => {
      // Send message to client that history has been saved so can set sync to true on all instances
      // WebView
      try {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ method: "saveHistory", result: "success" })
        );
      } catch (e) {}
      // WKWebView
      try {
        window.webkit.messageHandlers.reactNative.postMessage(
          JSON.stringify({ method: "saveHistory", result: "success" })
        );
      } catch (e) {}
      // UIWebView
      try {
        window.postMessage(
          JSON.stringify({ method: "saveHistory", result: "success" })
        );
      } catch (e) {}
    })
    .catch((err) => {
      alert(err);
      try {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ method: "saveHistory", result: "fail" })
        );
      } catch (e) {}
      try {
        window.webkit.messageHandlers.reactNative.postMessage(
          JSON.stringify({ method: "saveHistory", result: "fail" })
        );
      } catch (e) {}
      // UIWebView
      try {
        window.postMessage(
          JSON.stringify({ method: "saveHistory", result: "fail" })
        );
      } catch (e) {}
    });
}

export function saveHistory(bucketName, files, refreshChecklists = false) {
  // logToCloudWatch("saveHistory");
  // logToCloudWatch(files);
  return new Promise((resolve, reject) => {
    try {
      var keyPrefix = utils.getKeyPrefix(AWS.config.credentials.identityId);
      putBatch(bucketName, keyPrefix, files)
        .then(() => {
          // logToCloudWatch("putBatch success");
          // Send message to client that history has been saved so can set sync to true on all instances
          // WebView
          try {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                method: "saveHistory",
                result: "success",
                refreshChecklists: refreshChecklists,
              })
            );
          } catch (e) {}
          // WKWebView
          try {
            window.webkit.messageHandlers.reactNative.postMessage(
              JSON.stringify({
                method: "saveHistory",
                result: "success",
                refreshChecklists: refreshChecklists,
              })
            );
          } catch (e) {}
          // UIWebView
          try {
            window.postMessage(
              JSON.stringify({
                method: "saveHistory",
                result: "success",
                refreshChecklists: refreshChecklists,
              })
            );
          } catch (e) {}

          resolve();
        })
        .catch((err) => {
          alert(err);
          // logToCloudWatch("Save History Error");
          try {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                method: "saveHistory",
                result: "fail",
                refreshChecklists: refreshChecklists,
              })
            );
          } catch (e) {}
          try {
            window.webkit.messageHandlers.reactNative.postMessage(
              JSON.stringify({
                method: "saveHistory",
                result: "fail",
                refreshChecklists: refreshChecklists,
              })
            );
          } catch (e) {}
          // UIWebView
          try {
            window.postMessage(
              JSON.stringify({
                method: "saveHistory",
                result: "fail",
                refreshChecklists: refreshChecklists,
              })
            );
          } catch (e) {}

          reject(err);
        });
    } catch (e) {
      // logToCloudWatch("Catch error in saveHistory");
      reject(e);
    }
  });
}

// Need to send in batch instead of loop
export function saveJsonToS3ForHistory(object, bucketName, fileName) {
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        actions.showError("Failed getting session", err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            var keyPrefix = utils.getKeyPrefix(
              AWS.config.credentials.identityId
            );

            // Instantiate aws sdk service objects now that the credentials have been updated.
            var bucket = new AWS.S3({
              useDualStack: true,
              params: { Bucket: bucketName },
            });
            var params = {
              Key: keyPrefix + "/" + fileName,
              ContentType: "application/json",
              Body: JSON.stringify(object),
            };
            console.log("SAVE HISTORY: " + keyPrefix + "/" + fileName);
            bucket.upload(params, function (err, data) {
              var result = err ? "ERROR!" : "SAVED.";
              console.log(result);
              // Replace with notification
              if (!err) {
              } else {
                actions.showError("History save failed", err.message);
              }
            });
          } else {
            showErrorNoDetail("Save history failed", err);
          }
        });
      });
    });
  }
}

export function saveJsonToS3(
  object,
  bucketName,
  fileName,
  message = "",
  getChecklists = true
) {
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        actions.showError("Failed getting session", err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            var keyPrefix = utils.getKeyPrefix(
              AWS.config.credentials.identityId
            );

            // Instantiate aws sdk service objects now that the credentials have been updated.
            var bucket = new AWS.S3({
              useDualStack: true,
              params: { Bucket: bucketName },
            });
            var params = {
              Key: keyPrefix + "/" + fileName,
              ContentType: "application/json",
              Body: JSON.stringify(object),
            };
            bucket.upload(params, function (err, data) {
              var result = err ? "ERROR!" : "SAVED.";
              console.log(result);
              // Replace with notification
              if (!err) {
                // If getChecklists then refresh content on mobile device
                if (getChecklists) {
                  getAllChecklists(state);
                  if (message != "") {
                    actions.showSuccess("Save successful", message);
                  }
                  // receiveDocuments sets the state in the app
                } else {
                  actions.receiveDocuments(object);
                }
              } else {
                actions.showError("Failed to save", err.message);
              }
            });
          } else {
            showError("Save failed", err);
          }
        });
      });
    });
  }
}

export function deleteObject(bucketName, key) {
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        alert(err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            var bucket = new AWS.S3({
              useDualStack: true,
              params: { Bucket: bucketName },
            });

            bucket.deleteObject({ Key: key }, function (err, data) {
              if (err) console.log(err, err.stack); // an error occurred
              else console.log(data); // successful response
            });
          } else {
            showError("Delete failed", err);
          }
        });
      });
    });
  }
}

export function deleteJsonFileFromS3WithKeyPrefix(
  bucketName,
  keyPrefix,
  fileName
) {
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        alert(err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            var bucket = new AWS.S3({
              useDualStack: true,
              params: { Bucket: bucketName },
            });

            bucket.deleteObject(
              { Key: keyPrefix + "/" + fileName },
              function (err, data) {
                if (err) console.log(err, err.stack); // an error occurred
                else console.log(data); // successful response
              }
            );
          } else {
            showError("Delete failed", err);
          }
        });
      });
    });
  }
}

export function deleteJsonFileFromS3(bucketName, fileName) {
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        alert(err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            var bucket = new AWS.S3({
              useDualStack: true,
              params: { Bucket: bucketName },
            });
            var keyPrefix = utils.getKeyPrefix(
              AWS.config.credentials.identityId
            );

            bucket.deleteObject(
              { Key: keyPrefix + "/" + fileName },
              function (err, data) {
                if (err) console.log(err, err.stack); // an error occurred
                else console.log(data); // successful response
              }
            );
          } else {
            showError("Delete failed", err);
          }
        });
      });
    });
  }
}

export function saveJsonFilesToS3WithKeyPrefix(
  bucketName,
  keyPrefix,
  files,
  message = ""
) {
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        alert(err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            putBatchWithKeyPrefix(bucketName, files)
              .then(function () {
                if (message != "") {
                  actions.showSuccess("Save successful", message);
                }
              })
              .catch(function () {
                console.log(err.message);
              });
          } else {
            showError("Save failed", err);
          }
        });
      });
    });
  }
}

export function saveJsonFilesToS3ForCheckMateImport(bucketName, files) {
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        alert(err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            var keyPrefix = utils.getKeyPrefix(
              AWS.config.credentials.identityId
            );
            putBatch(bucketName, keyPrefix, files)
              .then(async function () {
                var templateDoc = {
                  id: utils.generateUUID(),
                  newDoc: true,
                  name: "",
                  description: "",
                  genre: "",
                  tags: [],
                  publisher: "self",
                  store: "",
                  productPlaneId: "",
                  shareCode: "",
                  shareCodeReceived: "",
                  version: "1.0",
                  cloned: false,
                  visible: true,
                  speedType: "KIAS",
                  image: "",
                };

                // Get checklists.json from S3
                var s3 = new AWS.S3({ useDualStack: true });
                var data = await s3
                  .getObject({
                    Bucket: contentBucket,
                    Key: keyPrefix + "/checklists.json",
                  })
                  .promise();
                var documents = JSON.parse(data.Body.toString());
                documents.push(templateDoc);
                utils.setDocuments(state, documents);
              })
              .catch(function (err) {
                showError("Save failed", err);
                console.log(err.message);
              });
          } else {
            showError("Save failed", err);
          }
        });
      });
    });
  }
}

// This also getAllChecklists
export function saveJsonFilesToS3(
  bucketName,
  files,
  message = "",
  getChecklists = true
) {
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        alert(err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            utils.showLoader(state, "loadingSpinner");

            var keyPrefix = utils.getKeyPrefix(
              AWS.config.credentials.identityId
            );
            putBatch(bucketName, keyPrefix, files)
              .then(function () {
                if (getChecklists) {
                  getAllChecklists(state);
                }

                if (message != "") {
                  actions.showSuccess("Save successful", message);
                }

                utils.hideLoader(state, "loadingSpinner");
              })
              .catch(function (err) {
                utils.hideLoader(state, "loadingSpinner");

                showError("Save failed", err);
                console.log(err.message);
              });
          } else {
            showError("Save failed", err);
          }
        });
      });
    });
  }
}

function putBatch2(bucketName, keyPrefix, files) {
  return new Promise((resolve, reject) => {
    files.map(async function (file) {
      //var bucket = new AWS.S3({useDualStack: true, params: {Bucket: bucketName}});
      var body = JSON.stringify(file.fileContent);

      try {
        await putJsonFileToS3(contentBucket, file.fileName, body);
      } catch (err) {
        alert(err.message);
        reject(err);
      }
    });
    resolve();
  });
}

var putBatch = function putBatch(bucketName, keyPrefix, files) {
  return Promise.all(
    files.map(function (file) {
      // logToCloudWatch("Put " + file.fileName);

      var bucket = new AWS.S3({
        useDualStack: true,
        params: { Bucket: bucketName },
      });
      var body = JSON.stringify(file.fileContent);
      //logToCloudWatch("Upload " + body);

      // return bucket.upload({Key: keyPrefix + "/" + file.fileName, ContentType: "application/json", Body: body}).promise();

      return bucket
        .putObject({
          Key: keyPrefix + "/" + file.fileName,
          ContentType: "application/json",
          Body: body,
        })
        .promise();
    })
  );
};

var putBatchWithKeyPrefix = function putBatchWithKeyPrefix(bucketName, files) {
  return Promise.all(
    files.map(function (file) {
      var bucket = new AWS.S3({
        useDualStack: true,
        params: { Bucket: bucketName },
      });
      var body = JSON.stringify(file.fileContent);
      return bucket
        .putObject({
          Key: file.keyPrefix + "/" + file.fileName,
          ContentType: "application/json",
          Body: body,
        })
        .promise();
    })
  );
};

export function deleteDocument(document) {
  if (!document.cloned && document.publisher == "checkmate") {
    alert(
      "This is a CheckMate licensed checklist that you purchased. You can't delete this checklist."
    );
    return;
  }

  // TODO: probably better to just splice one want to delete
  var documents = state.get(["documents"]);

  var newDocuments = [];
  var template;
  for (var doc of documents) {
    if (!doc.hasOwnProperty("newDoc") || !doc.newDoc) {
      if (doc.id !== document.id) {
        newDocuments.push(doc);
      }
    } else {
      template = doc;
    }
  }

  utils.setDocuments(state, newDocuments);

  // TODO: Can get out of sync without promise
  saveJsonToS3(newDocuments, contentBucket, "checklists.json");
  deleteJsonFileFromS3(contentBucket, document.id + ".json");

  state.get(["documents"]).unshift(template);
}

export function cloneDocument(document) {
  if (hasMaxChecklists()) {
    return;
  }

  var deepDocument = document;
  // Can I cache all this user stuff?
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        alert(err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            utils.showLoader(state, "loadingSpinner");

            var keyPrefix;
            if (document.hasOwnProperty("identityId")) {
              keyPrefix = document.identityId;
            } else {
              keyPrefix = utils.getKeyPrefix(AWS.config.credentials.identityId);
            }

            var s3 = new AWS.S3({ useDualStack: true });
            var bucket = new AWS.S3({
              useDualStack: true,
              params: {
                Bucket: contentBucket,
                ResponseContentType: "application/json",
                ResponseCacheControl: "max-age=0",
              },
            });
            bucket.getObject(
              { Key: keyPrefix + "/" + document.id + ".json" },
              function (err, data) {
                if (err) {
                  utils.hideLoader(state, "loadingSpinner");

                  alert(err, err.stack);
                } else {
                  var checklist = JSON.parse(data.Body.toString());
                  var baseId = checklist.id;
                  checklist.id = utils.generateUUID();
                  if (checklist.hasOwnProperty("baseIds")) {
                    checklist.baseIds.push(baseId);
                    baseIds = checklist.baseIds;
                  } else {
                    var baseIds = [];
                    baseIds.push(baseId);
                    checklist.baseIds = baseIds;
                  }

                  checklist.name = "Clone of " + checklist.name;
                  checklist.cloned = true;
                  checklist.publisher = deepDocument.publisher;
                  checklist.genre = deepDocument.genre;
                  checklist.tags = deepDocument.tags;

                  var newDocument = _.cloneDeep(deepDocument);
                  newDocument.id = checklist.id;
                  newDocument.name = checklist.name;
                  newDocument.cloned = checklist.cloned;
                  newDocument.publisher = checklist.publisher;
                  newDocument.genre = checklist.genre;
                  newDocument.tags = checklist.tags;
                  newDocument.shareCode = "";
                  newDocument.shareCodeReceived = "";
                  newDocument.privacy = "private";
                  if (document.hasOwnProperty("identityId")) {
                    delete newDocument.identityId;
                  }

                  updateChecklistsAndPushToS3(checklist, newDocument);
                }
              }
            );
          } else {
            showError("Clone failed", err);
          }
        });
      });
    });
  }
}

export function replaceDocument(document, identityId, checklistId) {
  if (hasMaxChecklists()) {
    return;
  }

  var deepDocument = document;
  // Can I cache all this user stuff?
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        alert(err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            var s3 = new AWS.S3({ useDualStack: true });
            var bucket = new AWS.S3({
              useDualStack: true,
              params: {
                Bucket: contentBucket,
                ResponseContentType: "application/json",
                ResponseCacheControl: "max-age=0",
              },
            });
            bucket.getObject(
              { Key: identityId + "/" + checklistId + ".json" },
              async function (err, data) {
                if (err) {
                  alert(err, err.stack);
                } else {
                  var checklist = JSON.parse(data.Body.toString());

                  try {
                    await transferTimerTemplates(checklist, identityId);
                  } catch (err) {
                    alert("Error: " + err.message);
                  }

                  checklist.id = document.id;

                  var newDocument = _.cloneDeep(deepDocument);
                  newDocument.name = checklist.name;
                  newDocument.description = checklist.description;
                  newDocument.cloned = checklist.cloned;
                  newDocument.publisher = checklist.publisher;
                  newDocument.genre = checklist.genre;
                  newDocument.tags = checklist.tags;
                  newDocument.speedType = checklist.speedType;
                  newDocument.contributors = checklist.contributors;
                  newDocument.image = checklist.hasOwnProperty("image") ? checklist.image : "";
                  newDocument.version = checklist.version;
                  newDocument.privacy = "private";

                  updateDocument(newDocument);

                  updateChecklistsAndPushToS3(checklist, null, "", true);
                }
              }
            );
          } else {
            showError("Replace failed", err);
          }
        });
      });
    });
  }
}

export function hasMaxChecklists() {
  var documents = state.get(["documents"]);

  var subscriptionPlan = state.get(["user", "subscriptionPlan"]);

  if (subscriptionPlan === "no-plan" && documents.length === 3) {
    alert(
      "You can only have a maximum of two checklists in the Free Plan. You will need to upgrade to the Pro or Performance Plan if you would like to manage more checklists."
    );

    return true;
  }

  if (subscriptionPlan === "basic-plan" && documents.length === 3) {
    alert(
      "You can only have a maximum of two checklists in the Basic Plan. You will need to upgrade to the Pro or Performance Plan if you would like to manage more checklists."
    );

    return true;
  }

  // PRICING FIXME
  if (subscriptionPlan.startsWith("pro-plan-as") && documents.length === 6) {
    alert(
      "You can only have a maximum of five checklists in the Pro Plan. You will need to upgrade to the Performance Plan if you would like to manage unlimited checklists."
    );

    return true;
  }

  return false;
}

export function updateChecklistsAndPushToS3(
  checklist,
  newDocument = null,
  message = "",
  ignoreContributors = false
) {
  var documents = state.get(["documents"]);

  var name = state.get(["user", "name"]);
  var username = state.get(["user", "username"]);
  var nickname = state.get(["user", "nickname"]);

  if (!ignoreContributors) {
    if (newDocument === null) {
      if (!checklist.hasOwnProperty("contributors")) {
        var contributors = [];
        contributors.push({
          name: name,
          username: username,
          nickname: nickname,
        });
        checklist.contributors = contributors;
      } else {
        if (checklist.contributors.length === 0) {
          checklist.contributors.push({
            name: name,
            username: username,
            nickname: nickname,
          });
        } else {
          if (
            checklist.contributors[checklist.contributors.length - 1]
              .username !== username
          ) {
            checklist.contributors.push({
              name: name,
              username: username,
              nickname: nickname,
            });
          }
        }
      }
    }
  }

  if (!checklist.hasOwnProperty("revision") || newDocument !== null) {
    checklist.revision = 1;
  }

  var newDocuments = [];
  var saved = false;
  var template;
  for (var document of documents) {
    if (!document.hasOwnProperty("newDoc") || !document.newDoc) {
      if (checklist.id === document.id) {
        document.name = checklist.name;
        document.description = checklist.description;
        document.cloned = checklist.cloned;
        document.publisher = checklist.publisher;
        document.genre = checklist.genre;
        document.tags = checklist.tags;
        document.contributors = checklist.contributors;
        document.image = checklist.hasOwnProperty("image") ? checklist.image : "";
        document.revision = checklist.hasOwnProperty("revision")
          ? checklist.revision
          : 1;
      }
      newDocuments.push(document);
    } else if (
      document.hasOwnProperty("newDoc") &&
      document.newDoc &&
      checklist.id === document.id
    ) {
      saved = true;
      document.newDoc = false;
      document.name = checklist.name;
      document.description = checklist.description;
      document.cloned = checklist.cloned;
      document.publisher = checklist.publisher;
      document.genre = checklist.genre;
      document.tags = checklist.tags;
      document.contributors = checklist.contributors;
      document.revision = checklist.hasOwnProperty("revision")
        ? checklist.revision
        : 1;
      newDocuments.unshift(document);
    } else {
      template = document;
    }
  }

  if (newDocument != null) {
    newDocument.revision = 1;
    newDocuments.push(newDocument);
  }

  utils.setDocuments(state, newDocuments);

  var files = [
    {
      fileName: "checklists.json",
      fileContent: newDocuments,
    },
    {
      fileName: checklist.id + ".json",
      fileContent: checklist,
    },
  ];

  saveJsonFilesToS3(contentBucket, files, message);

  if (saved) {
    // Only push if no other new docs in array
    var _newDocument = {
      id: utils.generateUUID(),
      newDoc: true,
      name: "",
      description: "",
      genre: "",
      tags: [],
      contributors: [
        {
          name: state.get(["user", "name"]),
          username: state.get(["user", "username"]),
          nickname: state.get("user", "nickname"),
        },
      ],
      publisher: "self",
      store: "",
      productPlaneId: "",
      shareCode: "",
      shareCodeReceived: "",
      version: "1.0",
      cloned: false,
      visible: true,
      speedType: "KIAS",
      image: "",
    };
    state.get(["documents"]).unshift(_newDocument);
  } else {
    state.get(["documents"]).unshift(template);
  }
}

function existsInChecklists(name, documents) {
  var exists = false;
  for (var i = 0; i < documents.length; i++) {
    if (name == documents[i].name) {
      console.log(documents[i].name);
      exists = true;
    }
  }

  return exists;
}

export function clonePublicDocument(hit, fromShare = false, purchase = false) {
  if (hasMaxChecklists() && !purchase) {
    return;
  }

  // Can I cache all this user stuff?
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        alert(err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            var keyPrefix = "public";

            var s3 = new AWS.S3({ useDualStack: true });

            var hitUrl = hit.url;
            hitUrl = hitUrl.replace("https://", "");
            var hitParts = hitUrl.split("/");

            var bucket = new AWS.S3({
              useDualStack: true,
              params: {
                Bucket: hitParts[1],
                ResponseContentType: "application/json",
                ResponseCacheControl: "max-age=0",
              },
            });
            bucket.getObject(
              { Key: keyPrefix + "/" + hit.id + ".json" },
              async function (err, data) {
                if (err) {
                  alert(err, err.stack);
                } else {
                  if (fromShare) {
                    var checklist = JSON.parse(data.Body.toString());

                    addChecklist(checklist, hit);

                    setTimeout(function () {
                      state.set(["appState", "checklistReceived"], true);
                    }, 3000);
                  } else {
                    var checklist = JSON.parse(data.Body.toString());

                    try {
                      // transfer if no where source of timer templates is coming from
                      if (checklist.hasOwnProperty("timerTemplatesSrcKey")) {
                        await transferTimerTemplates(
                          checklist,
                          checklist.timerTemplatesSrcKey
                        );
                      }
                    } catch (err) {
                      alert("Error: " + err.message);
                    }

                    // Check if already exists in checklists
                    if (
                      !existsInChecklists(
                        checklist.name,
                        state.get(["documents"])
                      )
                    ) {
                      addChecklist(checklist, hit);
                    } else {
                      window.confirm(
                        "A checklist of the same name already exists in your My Checklists. Do you still want to add it?",
                        () => {
                          addChecklist(checklist, hit);
                        }
                      );
                    }
                  }
                }
              }
            );
          } else {
            showError("Add failed", err);
          }
        });
      });
    });
  }
}

export function importChecklistsFromCheckMate(checklists) {
  let result = 0;
  var files = [];

  var documents = state.get(["documents"]);
  // Remove template
  var templateDoc = documents.shift();

  checklists.forEach((checklist) => {
    if (!checklist.error) {
      console.log("Saving to S3 " + checklist.id);

      checklist.cloned = true;
      checklist.contributors = [
        {
          name: "Rich Lang",
          nickname: "CheckMate Aviation, Inc.",
          username: "rich.lang.admin",
        },
      ];

      var newDocument = {
        id: checklist.id,
        name: checklist.name,
        description: checklist.description,
        cloned: checklist.cloned,
        genre: checklist.genre,
        tags: checklist.tags,
        contributors: checklist.contributors,
        publisher: checklist.publisher,
        store: "",
        productPlaneId: "",
        shareCode: "",
        shareCodeReceived: "",
        version: "1.0",
        visible: true,
        speedType: "KIAS",
        image: "",
      };

      documents.push(newDocument);

      files.push({ fileName: checklist.id + ".json", fileContent: checklist });
    }
  });

  // Push content for checklists.json
  files.push({ fileName: "checklists.json", fileContent: documents });

  // Also add to checklists.json
  saveJsonFilesToS3ForCheckMateImport(contentBucket, files);

  // Sync the document collection
  //syncChecklistManifestWithDocumentsCollection()
  return result;
}

export function importChecklistFromCsv(checklist) {
  var newDocument = {
    id: checklist.id,
    name: checklist.name,
    description: checklist.description,
    cloned: checklist.publisher === "self" ? true : true,
    genre: checklist.genre,
    tags: checklist.tags,
    contributors: checklist.hasOwnProperty("contributors")
      ? checklist.contributors
      : [
          {
            name: state.get(["user", "name"]),
            username: state.get(["user", "username"]),
            nickname: state.get("user", "nickname"),
          },
        ],
    publisher: checklist.publisher,
    store: "",
    productPlaneId: "",
    shareCode: "",
    shareCodeReceived: "",
    version: "1.0",
    visible: true,
    speedType: "KIAS",
    image: "",
  };

  updateChecklistsAndPushToS3(
    checklist,
    newDocument,
    "The checklist " + checklist.name + " was successfully imported."
  );
}

export function importChecklist(checklist) {
  var newDocument = {
    id: checklist.id,
    name: checklist.name,
    description: checklist.description,
    cloned: checklist.publisher === "self" ? true : true,
    genre: checklist.genre,
    tags: checklist.tags,
    contributors: checklist.hasOwnProperty("contributors")
      ? checklist.contributors
      : [
          {
            name: state.get(["user", "name"]),
            username: state.get(["user", "username"]),
            nickname: state.get("user", "nickname"),
          },
        ],
    publisher: checklist.publisher,
    store: "",
    productPlaneId: "",
    shareCode: "",
    shareCodeReceived: "",
    version: "1.0",
    visible: true,
    speedType: "KIAS",
    image: "",
  };

  updateChecklistsAndPushToS3(
    checklist,
    newDocument,
    "The checklist " + checklist.name + " was successfully imported."
  );
}

function addChecklist(checklist, hit) {
  var baseId = checklist.id;
  checklist.id = utils.generateUUID();
  if (checklist.hasOwnProperty("baseIds")) {
    checklist.baseIds.push(baseId);
  } else {
    var baseIds = [];
    baseIds.push(baseId);
    checklist.baseIds = baseIds;
  }
  // Need to think through all scenarios...for now allow people to delete anything
  checklist.cloned = false;
  //checklist.publisher = hit.publisher;
  //checklist.genre = hit.genre;
  //checklist.tags = hit.tags;

  var newDocument = {
    id: checklist.id,
    name: checklist.name,
    description: checklist.description,
    cloned: false,
    genre: checklist.genre,
    tags: checklist.tags,
    contributors: checklist.hasOwnProperty("contributors")
      ? checklist.contributors
      : [
          {
            name: state.get(["user", "name"]),
            username: state.get(["user", "username"]),
            nickname: state.get("user", "nickname"),
          },
        ],
    publisher: checklist.publisher,
    store: "",
    productPlaneId: "",
    shareCode: "",
    shareCodeReceived: "",
    version: "1.0",
    visible: true,
    speedType: "KIAS",
    image: "",
  };

  updateChecklistsAndPushToS3(checklist, newDocument);

  actions.showSuccess(
    "Added",
    "This checklist has been successfuly added to My Checklists."
  );
}

export function setDocumentPrivacy(document, privacy) {
  // Can I cache all this user stuff?
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        alert(err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            var keyPrefix = utils.getKeyPrefix(
              AWS.config.credentials.identityId
            );

            var s3 = new AWS.S3({ useDualStack: true });
            var bucket = new AWS.S3({
              useDualStack: true,
              params: {
                Bucket: contentBucket,
                ResponseContentType: "application/json",
                ResponseCacheControl: "max-age=0",
              },
            });
            bucket.getObject(
              { Key: keyPrefix + "/" + document.id + ".json" },
              function (err, data) {
                if (err) {
                  alert(err, err.stack);
                } else {
                  var checklist = JSON.parse(data.Body.toString());

                  // If privacy = public then copy
                  // If privacy = private then delete
                  checklist.privacy = privacy;
                  checklist.timerTemplatesSrcKey = keyPrefix;

                  // Need to update documents collection
                  document.privacy = privacy;
                  updateDocument(document);

                  var documentsToSend = _.cloneDeep(state.get(["documents"]));
                  documentsToSend.shift();

                  if (privacy === "public") {
                    var files = [
                      {
                        keyPrefix: keyPrefix,
                        fileName: "checklists.json",
                        fileContent: documentsToSend,
                      },
                      {
                        keyPrefix: "public",
                        fileName: checklist.id + ".json",
                        fileContent: checklist,
                      },
                    ];

                    saveJsonFilesToS3WithKeyPrefix(
                      contentBucket,
                      "public",
                      files,
                      ""
                    );
                  } else if (privacy === "private") {
                    // Not consistent...above we do this in a batch
                    deleteJsonFileFromS3WithKeyPrefix(
                      contentBucket,
                      "public",
                      document.id + ".json"
                    );
                    saveJsonToS3WithKeyPrefix(
                      documentsToSend,
                      contentBucket,
                      keyPrefix,
                      "checklists.json",
                      ""
                    );
                  }
                }
              }
            );
          } else {
            showError("Set privacy failed", err);
          }
        });
      });
    });
  }
}

export function updateDocument(document) {
  var documents = state.get(["documents"]);

  var newDocuments = [];
  for (var doc of documents) {
    if (doc.id === document.id) {
      newDocuments.push(document);
    } else {
      newDocuments.push(doc);
    }
  }

  utils.setDocuments(state, newDocuments);
}

export function setDocuments(documents) {
  utils.setDocuments(state, documents);

  // Needs to save up to s3
  saveJsonToS3(documents, contentBucket, "checklists.json");
}

export function toggleVisible(document) {
  var documents = state.get(["documents"]);

  var newDocuments = [];
  var template;
  for (var doc of documents) {
    if (!doc.hasOwnProperty("newDoc") || !doc.newDoc) {
      if (doc.id === document.id) {
        doc.visible = !doc.visible;
      }
      newDocuments.push(doc);
    } else {
      template = doc;
    }
  }

  utils.setDocuments(state, newDocuments);

  // TODO: Can get out of sync without promise
  saveJsonToS3(newDocuments, contentBucket, "checklists.json");

  state.get(["documents"]).unshift(template);
}

export function toggleSpeedType(document) {
  var documents = state.get(["documents"]);

  var newDocuments = [];
  var template;
  for (var doc of documents) {
    if (!doc.hasOwnProperty("newDoc") || !doc.newDoc) {
      if (doc.id === document.id) {
        if (doc.speedType.toLowerCase() === "mph") {
          doc.speedType = "KIAS";
        } else {
          doc.speedType = "MPH";
        }
      }
      newDocuments.push(doc);
    } else {
      template = doc;
    }
  }

  utils.setDocuments(state, newDocuments);

  // TODO: Can get out of sync without promise
  saveJsonToS3(newDocuments, contentBucket, "checklists.json");

  state.get(["documents"]).unshift(template);
}

export async function getPrintTemplates() {
  let headers = new Headers();

  headers.append(
    "Authorization",
    "Basic amVmZi5ib25hc3NvQG1pcmFsb3VhZXJvLmNvbTpTaXhGb3VyNzJHb2xm"
  );

  const responseTemplates = await fetch(
    "https://miralouaero.jsreportonline.net/odata/templates?$select=name",
    {
      method: "GET",
      headers: headers,
    }
  );
  const jsonTemplates = await responseTemplates.json();
  const templates = jsonTemplates.value;

  return templates;
}

export async function getResolvedTemplate(templateId) {
  let headers = new Headers();

  headers.append(
    "Authorization",
    "Basic amVmZi5ib25hc3NvQG1pcmFsb3VhZXJvLmNvbTpTaXhGb3VyNzJHb2xm"
  );

  const responseTemplate = await fetch(
    "https://miralouaero.jsreportonline.net/odata/templates(" +
      templateId +
      ")",
    {
      method: "GET",
      headers: headers,
    }
  );
  const jsonTemplate = await responseTemplate.json();
  const template = jsonTemplate.value[0].content;

  const templateObject = {
    content: template,
    engine: "none",
    recipe: "html",
  };

  const myData = { template: templateObject, data: JSON.stringify({}) };

  jsreport.serverUrl = "https://miralouaero.jsreportonline.net";

  //add custom headers to ajax calls
  jsreport.headers["Authorization"] =
    "Basic amVmZi5ib25hc3NvQG1pcmFsb3VhZXJvLmNvbTpTaXhGb3VyNzJHb2xm";

  //render through AJAX request and return promise with array buffer response
  try {
    var resBuffer = await jsreport.renderAsync(myData);

    utils.hideLoader(state, "loadingSpinner");

    return resBuffer.toString();
  } catch (err) {
    utils.hideLoader(state, "loadingSpinner");

    alert("Error: " + err.toString());
  }
}

export function printTemplateWithContent(
  name,
  data,
  content,
  orientation = "portrait"
) {
  var template = {
    content: content,
    engine: "jsrender",
    recipe: "electron-pdf",
    electron: {
      landscape: orientation === "landscape" ? true : false,
    },
  };

  data.username = state.get(["user", "username"]);

  var myData = { template: template, data: JSON.stringify(data) };

  jsreport.serverUrl = "https://miralouaero.jsreportonline.net";

  //add custom headers to ajax calls
  jsreport.headers["Authorization"] =
    "Basic amVmZi5ib25hc3NvQG1pcmFsb3VhZXJvLmNvbTpTaXhGb3VyNzJHb2xm";

  //render through AJAX request and return promise with array buffer response
  jsreport.renderAsync(myData).then(
    function (res) {
      console.log(res);

      //open download dialog

      var mobile = true;
      var printedAlready = false;
      try {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ method: "base64Blob", base64: res.toDataURI() })
        );
        // window.ReactNativeWebView.postMessage(JSON.stringify({method: "showGoBack"}));

        //open in new window
        // window.open(res.toDataURI())

        // var html = '<html>' +
        // '<style>html,body {padding:0;margin:0;} iframe {width:100%;height:100%;border:0}</style>' +
        // '<body>' +
        // '<iframe type="application/pdf" src="' +  res.toDataURI() + '"></iframe>' +
        // '</body></html>';
        // var a = window.open("about:blank", "Report")
        // a.document.write(html)
        // a.document.close()

        // res.download(name + '.pdf');
        printedAlready = true;
      } catch (e) {
        utils.hideLoader(state, "loadingSpinner");
        mobile = false;
      }

      try {
        window.webkit.messageHandlers.reactNative.postMessage(
          JSON.stringify({ method: "showGoBack" })
        );
        res.download(name + ".pdf");
        printedAlready = true;
      } catch (e) {
        utils.hideLoader(state, "loadingSpinner");
        mobile = false;
      }

      if (!printedAlready) {
        // UIWebView
        try {
          window.postMessage(JSON.stringify({ method: "showGoBack" }));
          res.download(name + ".pdf");
          printedAlready = true;
        } catch (e) {
          utils.hideLoader(state, "loadingSpinner");
          mobile = false;
        }
      }

      if (!mobile && !printedAlready) {
        res.download(name + ".pdf");
        //window.open(res.toDataURI())
      }

      utils.hideLoader(state, "loadingSpinner");
    },
    function (reason) {
      utils.hideLoader(state, "loadingSpinner");

      alert("Error: " + reason.status + "\n" + reason.statusText);
      // rejection
    }
  );
}

export function printTemplate(name, data, templateId) {
  data.username = state.get(["user", "username"]);

  var myData = { template: { name: templateId }, data: JSON.stringify(data) };

  jsreport.serverUrl = "https://miralouaero.jsreportonline.net";

  //add custom headers to ajax calls
  jsreport.headers["Authorization"] =
    "Basic amVmZi5ib25hc3NvQG1pcmFsb3VhZXJvLmNvbTpTaXhGb3VyNzJHb2xm";
  //jsreport.headers['Content-Type'] = "application/json";

  //render through AJAX request and return promise with array buffer response
  jsreport.renderAsync(myData).then(
    function (res) {
      console.log(res);

      //open download dialog

      var mobile = true;
      var printedAlready = false;
      try {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ method: "base64Blob", base64: res.toDataURI() })
        );
        // window.ReactNativeWebView.postMessage(JSON.stringify({method: "showGoBack"}));

        //open in new window
        // window.open(res.toDataURI())

        // var html = '<html>' +
        // '<style>html,body {padding:0;margin:0;} iframe {width:100%;height:100%;border:0}</style>' +
        // '<body>' +
        // '<iframe type="application/pdf" src="' +  res.toDataURI() + '"></iframe>' +
        // '</body></html>';
        // var a = window.open("about:blank", "Report")
        // a.document.write(html)
        // a.document.close()

        // res.download(name + '.pdf');
        printedAlready = true;
      } catch (e) {
        utils.hideLoader(state, "loadingSpinner");
        mobile = false;
      }

      try {
        window.webkit.messageHandlers.reactNative.postMessage(
          JSON.stringify({ method: "showGoBack" })
        );
        res.download(name + ".pdf");
        printedAlready = true;
      } catch (e) {
        utils.hideLoader(state, "loadingSpinner");
        mobile = false;
      }

      if (!printedAlready) {
        // UIWebView
        try {
          window.postMessage(JSON.stringify({ method: "showGoBack" }));
          res.download(name + ".pdf");
          printedAlready = true;
        } catch (e) {
          utils.hideLoader(state, "loadingSpinner");
          mobile = false;
        }
      }

      if (!mobile && !printedAlready) {
        res.download(name + ".pdf");
        //window.open(res.toDataURI())
      }

      utils.hideLoader(state, "loadingSpinner");
    },
    function (reason) {
      utils.hideLoader(state, "loadingSpinner");

      alert("Error: " + reason.status + "\n" + reason.statusText);
      // rejection
    }
  );
}

export async function printFromMemory(
  document,
  columns = false,
  numColumns = 5,
  orientation = "portrait",
  custom = false
) {
  utils.showLoader(state, "loadingSpinner");

  var checklist = document;
  utils.recurseReplaceSpeeds(checklist.children);
  utils.recurseConvertChecklistToMarkdown(checklist.children, true);

  var templateId;
  if (columns) {
    if (utils.isChecklistItems(checklist)) {
      templateId =
        "miracheck-items-columns" + (numColumns !== 5 ? "-" + numColumns : "");
    } else if (utils.isChecklistSections(checklist)) {
      templateId =
        "miracheck-sections-columns" +
        (numColumns !== 5 ? "-" + numColumns : "") +
        (orientation !== "portrait" ? "-" + orientation : "");
    } else if (utils.isChecklistLists(checklist)) {
      templateId =
        "miracheck-lists-columns" +
        (numColumns !== 5 ? "-" + numColumns : "") +
        (orientation !== "portrait" ? "-" + orientation : "");
    }
  } else {
    if (utils.isChecklistItems(checklist)) {
      if (checklist.publisher.toLowerCase() === "checkmate") {
        templateId = "miracheck-items-checkmate";
      } else {
        templateId = "miracheck-items";
      }
    } else if (utils.isChecklistSections(checklist)) {
      if (checklist.publisher.toLowerCase() === "checkmate") {
        templateId = "miracheck-sections-checkmate";
      } else {
        templateId = "miracheck-sections";
      }
    } else if (utils.isChecklistLists(checklist)) {
      if (checklist.publisher.toLowerCase() === "checkmate") {
        templateId = "miracheck-lists-checkmate";
      } else {
        templateId = "miracheck-lists";
      }
    }
  }

  var template = checklist.printTemplate;

  if (custom && template && template.trim().length > 0) {
    // var templates = await getPrintTemplates();
    // var template = await getResolvedTemplate("58bfd021d4d441000194d118");
    removeAlternatives(checklist);
    printTemplateWithContent(document.name, checklist, template, orientation);
  } else if (
    custom &&
    (_.isUndefined(template) || (template && template.trim.length === 0))
  ) {
    utils.hideLoader(state, "loadingSpinner");
    alert(
      "You do not have a Print Template. You need to add a Print Template in the Checklist properties."
    );
  } else {
    printTemplate(document.name, checklist, templateId);
  }
}

export function print(
  document,
  columns = false,
  numColumns = 5,
  orientation = "portrait",
  custom = false
) {
  // Can I cache all this user stuff?
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        alert(err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            utils.showLoader(state, "loadingSpinner");

            var keyPrefix = utils.getKeyPrefix(
              AWS.config.credentials.identityId
            );

            var s3 = new AWS.S3({ useDualStack: true });
            var bucket = new AWS.S3({
              useDualStack: true,
              params: {
                Bucket: contentBucket,
                ResponseContentType: "application/json",
                ResponseCacheControl: "max-age=0",
              },
            });
            bucket.getObject(
              { Key: keyPrefix + "/" + document.id + ".json" },
              function (err, data) {
                if (err) {
                  utils.hideLoader(state, "loadingSpinner");

                  console.log(err, err.stack);
                  alert(err + "; " + err.stack);
                } else {
                  var checklist = JSON.parse(data.Body.toString());
                  checklist.username = state.get(["user", "username"]);

                  utils.recurseReplaceSpeeds(checklist.children);

                  var templateId;
                  if (columns) {
                    utils.recurseConvertChecklistToMarkdown(
                      checklist.children,
                      true
                    );
                    if (utils.isChecklistItems(checklist)) {
                      templateId =
                        "miracheck-items-columns" +
                        (numColumns !== 5 ? "-" + numColumns : "");
                    } else if (utils.isChecklistSections(checklist)) {
                      templateId =
                        "miracheck-sections-columns" +
                        (numColumns !== 5 ? "-" + numColumns : "") +
                        (orientation !== "portrait" ? "-" + orientation : "");
                    } else if (utils.isChecklistLists(checklist)) {
                      templateId =
                        "miracheck-lists-columns" +
                        (numColumns !== 5 ? "-" + numColumns : "") +
                        (orientation !== "portrait" ? "-" + orientation : "");
                    }
                  } else {
                    utils.recurseConvertChecklistToMarkdown(
                      checklist.children,
                      false
                    );
                    if (utils.isChecklistItems(checklist)) {
                      if (checklist.publisher.toLowerCase() === "checkmate") {
                        templateId = "miracheck-items-checkmate";
                      } else {
                        templateId = "miracheck-items";
                      }
                    } else if (utils.isChecklistSections(checklist)) {
                      if (checklist.publisher.toLowerCase() === "checkmate") {
                        templateId = "miracheck-sections-checkmate";
                      } else {
                        templateId = "miracheck-sections";
                      }
                    } else if (utils.isChecklistLists(checklist)) {
                      if (checklist.publisher.toLowerCase() === "checkmate") {
                        templateId = "miracheck-lists-checkmate";
                      } else {
                        templateId = "miracheck-lists";
                      }
                    }
                  }

                  var template = checklist.printTemplate;

                  if (custom && template && template.trim().length > 0) {
                    // getResolvedTemplate("");
                    removeAlternatives(checklist);
                    printTemplateWithContent(
                      document.name,
                      checklist,
                      template,
                      orientation
                    );
                  } else if (
                    custom &&
                    (_.isUndefined(template) ||
                      (template && template.trim.length === 0))
                  ) {
                    utils.hideLoader(state, "loadingSpinner");
                    alert(
                      "You do not have a Print Template. You need to add a Print Template in the Checklist properties."
                    );
                  } else {
                    printTemplate(document.name, checklist, templateId);
                  }
                }
              }
            );
          } else {
            showError("Print failed", err);
          }
        });
      });
    });
  }
  // JQuery Way - not working - probably array buffer issue
  /*
		$.ajax({
			type: "POST",
			beforeSend: function (xhr) {
				xhr.setRequestHeader ("Authorization", "Basic amVmZi5ib25hc3NvQG1pcmFsb3VhZXJvLmNvbTo2NFNldmVuVHdvR29sZiE=");
			},
			url: "https://miralouaero.jsreportonline.net/api/report",
			contentType: "application/json; charset=utf-8",
			data: JSON.stringify(myData),
			success: function(data, textStatus, jqXHR) {
				var pdfWin= window.open("data:application/pdf, " + escape(data), '', 'height=650,width=840');
				// some actions with this win, example print...
			},
			error: function(jqXHR) {
				alert("Error printing!");
			}
		});	
	*/
}

function removeAlternatives(checklist) {
  var lists = checklist.children;
  removeAlternativesRecurse(lists, checklist, 0, 0);
}

function removeAlternativesRecurse(arr, listIndex, sectionIndex) {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i].type == "list") {
      listIndex = i;
      var nameList = arr[i].name;
      nameList = nameList.replace(" | ", "|");
      var nameListParts = nameList.split("|");
      if (nameListParts.length > 0) {
        arr[i].name = nameListParts[0];
      }
    } else if (arr[i].type == "section") {
      sectionIndex = i;
      var nameSection = arr[i].name;
      nameSection = nameSection.replace(" | ", "|");
      var nameSectionParts = nameSection.split("|");
      if (nameSectionParts.length > 0) {
        arr[i].name = nameSectionParts[0];
      }
    } else if (arr[i].type.startsWith("item")) {
    }

    if (arr[i].hasOwnProperty("children")) {
      removeAlternativesRecurse(arr[i].children, listIndex, sectionIndex);
    }
  }
}

function pascalize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

export async function exportJson(document) {
  // Can I cache all this user stuff?
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        alert(err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            var keyPrefix = utils.getKeyPrefix(
              AWS.config.credentials.identityId
            );

            var s3 = new AWS.S3({ useDualStack: true });
            var bucket = new AWS.S3({
              useDualStack: true,
              params: {
                Bucket: contentBucket,
                ResponseContentType: "application/json",
                ResponseCacheControl: "max-age=0",
              },
            });
            bucket.getObject(
              { Key: keyPrefix + "/" + document.id + ".json" },
              function (err, data) {
                if (err) {
                  console.log(err, err.stack);
                  alert(err + "; " + err.stack);
                } else {
                  var checklist = JSON.parse(data.Body.toString());

                  const blob = new Blob([JSON.stringify(checklist, null, 3)], {
                    type: "application/json;charset=utf-8",
                  });
                  FileSaver.saveAs(
                    blob,
                    `${pascalize(
                      checklist.name
                    )}_${utils.getUtcTimestampForFilename()}.json`
                  );
                }
              }
            );
          } else {
            showError("Export failed", err);
          }
        });
      });
    });
  }
}

export async function importChecklistJson(
  event,
  opts = { fileUploadField: null, openEditorAfterSave: false }
) {
  let { fileUploadField, openEditorAfterSave } = opts;

  const file = event.target.files[0];
  console.log("File", file);

  return new Promise((resolve, reject) => {
    try {
      console.log("Handle upload", event);
      if (file && file.name) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const textContent = e.target.result;
          console.log("About to parse", textContent);
          const jsonContent = JSON.parse(textContent);

          jsonContent.id = utils.generateUUID();

          const checklist = jsonContent;

          checklist.name =
            checklist.name +
            " (Imported " +
            utils.getUtcTimestampForChecklistName() +
            ")";

          var newDocument = {
            id: checklist.id,
            name: checklist.name,
            description: checklist.description,
            cloned: checklist.publisher === "self" ? true : true,
            genre: checklist.genre,
            tags: checklist.tags,
            contributors: checklist.hasOwnProperty("contributors")
              ? checklist.contributors
              : [],
            publisher: checklist.publisher,
            store: "",
            productPlaneId: "",
            shareCode: "",
            shareCodeReceived: "",
            version: "1.0",
            visible: true,
            speedType: "KIAS",
            image: "",
            revision: checklist.revision ? checklist.revision + 1 : 1,
          };

          try {
            updateChecklistsAndPushToS3(
              checklist,
              newDocument,
              "The checklist was imported successfuly!"
            );
          } catch (e) {
            reject(e);
          } finally {
            if (fileUploadField && fileUploadField.current) {
              fileUploadField.current.value = "";
            }
          }
        };

        reader.readAsText(file);
      }
    } catch (e) {
      console.error(e);
      if (fileUploadField && fileUploadField.current) {
        fileUploadField.current.value = "";
      }
      showError("Import JSON", "Import of activity JSON failed.", () => {
        reject(e);
      });
    }
  });
}

export function exportCsv(document) {
  // Can I cache all this user stuff?
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        alert(err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            var keyPrefix = utils.getKeyPrefix(
              AWS.config.credentials.identityId
            );

            var s3 = new AWS.S3({ useDualStack: true });
            var bucket = new AWS.S3({
              useDualStack: true,
              params: {
                Bucket: contentBucket,
                ResponseContentType: "application/json",
                ResponseCacheControl: "max-age=0",
              },
            });
            bucket.getObject(
              { Key: keyPrefix + "/" + document.id + ".json" },
              function (err, data) {
                if (err) {
                  console.log(err, err.stack);
                  alert(err + "; " + err.stack);
                } else {
                  var checklist = JSON.parse(data.Body.toString());
                  var resArr = [
                    [
                      "list",
                      "section",
                      "label1",
                      "label2",
                      "labelOnly",
                      "labelOnlyBackgroundColor",
                      "mandatory",
                    ],
                  ];
                  var lastList = "";
                  var lastSection = "";

                  utils.recurseToFlatArray(
                    checklist.children,
                    lastList,
                    lastSection,
                    resArr
                  );

                  var lineArray = [];
                  resArr.forEach(function (infoArray, index) {
                    var line = infoArray.join(",");
                    lineArray.push(line);
                  });
                  var csvContent = lineArray.join("\n");

                  var encodedUri = encodeURI(csvContent);

                  utils.createBlobCsv2(csvContent, checklist.name + ".csv");
                }
              }
            );
          } else {
            showError("Export failed", err);
          }
        });
      });
    });
  }
}

export function exportCsvAdvanced(document) {
  // Can I cache all this user stuff?
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        alert(err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            var keyPrefix = utils.getKeyPrefix(
              AWS.config.credentials.identityId
            );

            var s3 = new AWS.S3({ useDualStack: true });
            var bucket = new AWS.S3({
              useDualStack: true,
              params: {
                Bucket: contentBucket,
                ResponseContentType: "application/json",
                ResponseCacheControl: "max-age=0",
              },
            });
            bucket.getObject(
              { Key: keyPrefix + "/" + document.id + ".json" },
              function (err, data) {
                if (err) {
                  console.log(err, err.stack);
                  alert(err + "; " + err.stack);
                } else {
                  var checklist = JSON.parse(data.Body.toString());
                  var resArr = [
                    [
                      "checklistId",
                      "checklistName",
                      "checklistAudio",
                      "checklistPrint",
                      "checklistDescription",
                      "checklistSingleLineCheckItems",
                      "checklistDontSpeakAudio",
                      "checklistTrackLocation",
                      "checklistGenre",
                      "checklistTags",
                      "listId",
                      "listName",
                      "listAudio",
                      "listPrint",
                      "listDefaultView",
                      "listColor",
                      "listIgnoreCompletion",
                      "listDontPrint",
                      "sectionId",
                      "sectionName",
                      "sectionAudio",
                      "sectionPrint",
                      "sectionColor",
                      "sectionIconColor",
                      "sectionBackgroundColor",
                      "sectionBorderColor",
                      "sectionIgnoreCompletion",
                      "sectionDontPrint",
                      "itemId",
                      "itemType",
                      "itemLabel1",
                      "itemLabel2",
                      "itemLabel3",
                      "itemComments",
                      "itemCommentsInline",
                      "itemCommentsDontPrint",
                      "itemMandatory",
                      "itemIgnoreCompletion",
                      "itemLabelOnly",
                      "itemLabelOnlyBackgroundColor",
                      "itemLabel1Color",
                      "itemLabel1Audio",
                      "itemLabel1Print",
                      "itemLabel2Color",
                      "itemLabel2Audio",
                      "itemLabel2Print",
                      "itemLabel3Color",
                      "itemLabel3Audio",
                      "itemLabel3Print",

                      "textInputPlaceholder",
                      "textInputDefaultValue",
                      "textInputMaxLength",
                      "textInputKeyboardType",
                      "textInputKeyboardAutoCapitalize",
                      "textInputKeyboardAutoCorrect",
                      "textInputKeyboardReturnKeyType",
                      "textInputMaskType",
                      "textInputCurrencySymbol",
                      "textInputCurrencySeparator",
                      "textInputNumberOfLines",

                      "pickerItemViewType",
                      "pickerItemPlaceholder",
                      "pickerItemDefaultValue",
                      "pickerItems",
                      "pickerAdvanceOnSelect",
                      "pickerLinkOnSelect",
                      "pickerLinkActionType",

                      "yesNoLinkOnSelect",
                      "yesNoLinkActionType",
                      "yesNoYesLinkId",
                      "yesNoNoLinkId",

                      "dateTimeType",
                      "dateTimeInitialDate",
                      "dateTimeMinuteInterval",

                      "imagePickerAddMediaButton",
                      "imagePickerUploadTitle",
                      "imagePickerCaptureMediaTitle",

                      "sketchPadBackgroundColor",
                      "sketchPadPenColor",
                      "sketchPadPenWidth",

                      "itemDontPrint",
                      "itemStartTimer",
                      "itemStopTimer",

                      "itemLinkOnCheck",
                      "itemLinkId",
                      "itemLinkActionType",
                    ],
                  ];
                  var lastList = "";
                  var lastSection = "";
                  utils.recurseToFlatArrayAdvanced(
                    checklist,
                    checklist.children,
                    lastList,
                    lastSection,
                    resArr
                  );

                  var lineArray = [];
                  resArr.forEach(function (infoArray, index) {
                    var line = infoArray.join(",");
                    lineArray.push(line);
                  });
                  var csvContent = lineArray.join("\n");

                  var encodedUri = encodeURI(csvContent);
                  utils.createBlobCsv2(csvContent, checklist.name + ".csv");
                }
              }
            );
          } else {
            showError("Export failed", err);
          }
        });
      });
    });
  }
}

export function convertImageUrlToBase64(imageUrl) {
  // Can I cache all this user stuff?
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        alert(err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            var keyPrefix = utils.getKeyPrefix(
              AWS.config.credentials.identityId
            );

            var lambda = new AWS.Lambda({
              region: "us-east-1",
              apiVersion: "2015-03-31",
            });

            var params = {
              FunctionName: globals.lambdaFunctionNames.convertImageUrlToBase64,
              InvocationType: "RequestResponse",
              LogType: "None",
              Payload: '{"imageUrl":"' + imageUrl + '"}',
            };

            lambda.invoke(params, function (error, data) {
              if (error) {
                alert(error);
              } else {
                console.log(data.Payload);
              }
            });
          } else {
            showError("Convert failed", err);
          }
        });
      });
    });
  }
}

export function saveStripeToken(token, hit) {
  // Can I cache all this user stuff?
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        alert(err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            token.checklistName = hit.name;
            token.stage = globals.stage;

            var lambda = new AWS.Lambda({
              region: "us-east-1",
              apiVersion: "2015-03-31",
            });
            var params = {
              FunctionName: globals.lambdaFunctionNames.chargeCard,
              InvocationType: "RequestResponse",
              LogType: "None",
              Payload: JSON.stringify(token),
            };

            lambda.invoke(params, function (error, data) {
              if (error) {
                alert(error);
              } else {
                if (
                  data.hasOwnProperty("FunctionError") &&
                  data.FunctionError === "Handled"
                ) {
                  var objError = JSON.parse(data.Payload);
                  alert(objError.errorMessage);
                } else {
                  var results = JSON.parse(data.Payload);
                  clonePublicDocument(hit, false, true);
                }
              }
            });
          } else {
            showError("Purchase failed", err);
          }
        });
      });
    });
  }
}

function updateAttribute(cognitoUser, name, value) {
  try {
    if (cognitoUser && cognitoUser.updateAttributes) {
      const attributeList = [];
      const params = {
        Name: name,
        Value: _.isString(value) ? value : JSON.stringify(value),
      };
      const attribute = new CognitoUserAttribute(params);
      attributeList.push(attribute);

      cognitoUser.updateAttributes(attributeList, (err, result) => {
        if (err) {
          alert("Failed to update user attributes\n\n" + err);
          alert(
            "Failed to update user attributes\n\n" +
              err +
              " for " +
              name +
              "; " +
              value
          );
          return;
        }
        console.log(`call result: ${result}`);
      });
    }
  } catch (e) {
    alert("Failed to set user attribute " + e + " for " + name + "; " + value);
  }
}

export function updateCustomerCard(token) {
  // Can I cache all this user stuff?
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        alert(err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            var user = state.get(["user"]);
            token.stripeCustomerId = user.stripeCustomerId;
            token.stage = globals.stage;

            var lambda = new AWS.Lambda({
              region: "us-east-1",
              apiVersion: "2015-03-31",
            });
            var params = {
              FunctionName: globals.lambdaFunctionNames.updateCustomerCard,
              InvocationType: "RequestResponse",
              LogType: "None",
              Payload: JSON.stringify(token),
            };

            lambda.invoke(params, function (error, data) {
              if (error) {
                alert(error);
              } else {
                if (
                  data.hasOwnProperty("FunctionError") &&
                  data.FunctionError === "Handled"
                ) {
                  var objError = JSON.parse(data.Payload);
                  alert(objError.errorMessage);
                } else {
                  var results = JSON.parse(data.Payload);
                  if (results.hasOwnProperty("errorMessage")) {
                    alert(results.errorMessage);
                  } else {
                    // TODO: Validate that everything looks good
                    alert("You have successfully updated your card info.");

                    actions.goHome(state);
                  }
                }
              }
            });
          } else {
            showError("Update failed", err);
          }
        });
      });
    });
  }
}

export function redeemVoucher(voucherCode) {
  alert(
    "Voucher codes are no longer supported. Please contact support@miralouaero.com if you have questions."
  );
  return;

  // Can I cache all this user stuff?
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        alert(err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            if (voucherCode.length !== 5) {
              alert("That is an invalid Voucher Code. Please try again.");
              return;
            }

            var abcIndex1 = voucherCode.toUpperCase().charCodeAt(0) - 64;
            var abcIndex2 = voucherCode.toUpperCase().charCodeAt(1) - 64;
            var abcIndex3 = voucherCode.toUpperCase().charCodeAt(2) - 64;
            var abcIndex4 = voucherCode.toUpperCase().charCodeAt(3) - 64;
            var abcIndex5 = voucherCode.toUpperCase().charCodeAt(4) - 64;

            var voucherCodeSum =
              abcIndex1 + abcIndex2 + abcIndex3 + abcIndex4 + abcIndex5;

            if (voucherCodeSum === 50) {
              console.log(
                "Standard Voucher Redemed for user " +
                  state.get(["user"]).username
              );
              updateAttribute(
                cognitoUser,
                "custom:subscriptionPlan",
                "standard-plan-unlimited"
              );
              state.set(
                ["user", "subscriptionPlan"],
                "standard-plan-unlimited"
              );
              alert(
                "You have successfully created your Standard Plan (Unlimited) subscription."
              );
              actions.goHome(state);
            } else if (voucherCodeSum === 60) {
              console.log(
                "Pro Voucher Redemed for user " + state.get(["user"]).username
              );
              updateAttribute(
                cognitoUser,
                "custom:subscriptionPlan",
                "pro-plan-unlimited"
              );
              state.set(["user", "subscriptionPlan"], "pro-plan-unlimited");
              alert(
                "You have successfully created your Pro Plan (Unlimited) subscription."
              );
              actions.goHome(state);
            } else if (voucherCode === "BASIC") {
              updateAttribute(
                cognitoUser,
                "custom:subscriptionPlan",
                "basic-plan"
              );
              state.set(["user", "subscriptionPlan"], "basic-plan");
              alert(
                "You have successfully created your Basic Plan subscription."
              );
              actions.goHome(state);
            } else {
              alert("That is an invalid Voucher Code. Please try again.");
            }
          } else {
            showError("Redeem Voucher failed", err);
          }
        });
      });
    });
  }
}

export function updateSubscriptionV2(
  customerId,
  subscriptionId,
  subscriptionPlan
) {
  if (customerId === "" || subscriptionId === "") {
    return;
  }

  // Can I cache all this user stuff?
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        alert(err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            updateAttribute(
              cognitoUser,
              "custom:subscriptionId",
              subscriptionId
            );
            state.set(["user", "subscriptionId"], subscriptionId);
            updateAttribute(
              cognitoUser,
              "custom:subscriptionPlan",
              subscriptionPlan
            );
            state.set(["user", "subscriptionPlan"], subscriptionPlan);
            // This should have given us a unique Stripe customer_id. We need to associate this with user and use for future requests.
            updateAttribute(cognitoUser, "custom:stripeCustomerId", customerId);
            state.set(["user", "stripeCustomerId"], customerId);
            // alert("You have successfully created your Performance Plan Yearly subscription with the first 3 months to try it out.");
            // actions.goHome(state);
          } else {
            showError("Update failed", err);
          }
        });
      });
    });
  }
}

export function updateSubscription(token, plan, coupon) {
  // Can I cache all this user stuff?
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        alert(err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            token.stage = globals.stage;
            if (plan === "standard-plan-unlimited") {
              var user = state.get(["user"]);
              token.subscriptionId = user.subscriptionId;
              token.amount = 2999;
              token.planDescription =
                "MiraCheck Standard Plan (Unlimited) purchase";

              // Call chargeCardProPlanUnlimited and if successful update user
              var lambda = new AWS.Lambda({
                region: "us-east-1",
                apiVersion: "2015-03-31",
              });
              var params = {
                FunctionName: globals.lambdaFunctionNames.chargeCardPlans,
                InvocationType: "RequestResponse",
                LogType: "None",
                Payload: JSON.stringify(token),
              };

              lambda.invoke(params, function (error, data) {
                if (error) {
                  alert(error);
                } else {
                  if (
                    data.hasOwnProperty("FunctionError") &&
                    data.FunctionError === "Handled"
                  ) {
                    var objError = JSON.parse(data.Payload);
                    alert(objError.errorMessage);
                  } else {
                    var results = JSON.parse(data.Payload);
                    if (results.hasOwnProperty("errorMessage")) {
                      alert(results.errorMessage);
                    } else {
                      updateAttribute(
                        cognitoUser,
                        "custom:subscriptionPlan",
                        "standard-plan-unlimited"
                      );
                      state.set(
                        ["user", "subscriptionPlan"],
                        "standard-plan-unlimited"
                      );
                    }
                  }
                }
              });
            } else if (
              plan === "pro-plan-unlimited" ||
              plan === "pro-plan-unlimited-v4" ||
              plan === "pro-plan-unlimited-discount"
            ) {
              var user = state.get(["user"]);
              token.subscriptionId = user.subscriptionId;

              token.amount = 9999;
              token.planDescription = "MiraCheck Pro Plan (Unlimited) purchase";

              if (plan === "pro-plan-unlimited-discount") {
                token.amount = 6999;
                token.planDescription =
                  "MiraCheck Pro Plan (Unlimited) upgrade";
              } else if (plan === "pro-plan-unlimited-v4") {
                token.amount = 29999;
                token.planDescription =
                  "MiraCheck Pro Plan (Unlimited) purchase";
              }

              // Call chargeCardProPlanUnlimited and if successful update user
              var lambda = new AWS.Lambda({
                region: "us-east-1",
                apiVersion: "2015-03-31",
              });
              var params = {
                FunctionName: globals.lambdaFunctionNames.chargeCardPlans,
                InvocationType: "RequestResponse",
                LogType: "None",
                Payload: JSON.stringify(token),
              };

              lambda.invoke(params, function (error, data) {
                if (error) {
                  alert(error);
                } else {
                  if (
                    data.hasOwnProperty("FunctionError") &&
                    data.FunctionError === "Handled"
                  ) {
                    var objError = JSON.parse(data.Payload);
                    alert(objError.errorMessage);
                  } else {
                    var results = JSON.parse(data.Payload);
                    if (results.hasOwnProperty("errorMessage")) {
                      alert(results.errorMessage);
                    } else {
                      updateAttribute(
                        cognitoUser,
                        "custom:subscriptionPlan",
                        "pro-plan-unlimited"
                      );
                      state.set(
                        ["user", "subscriptionPlan"],
                        "pro-plan-unlimited"
                      );

                      // TODO: Validate that everything looks good
                      alert(
                        "You have successfully created your Pro Plan (Unlimited) subscription."
                      );

                      actions.goHome(state);
                    }
                  }
                }
              });
            } else {
              token.subscriptionPlan = plan;
              var user = state.get(["user"]);
              token.subscriptionId = user.subscriptionId;
              token.stripeCustomerId = user.stripeCustomerId;
              if (coupon) {
                token.couponId = coupon;
              }

              var lambda = new AWS.Lambda({
                region: "us-east-1",
                apiVersion: "2015-03-31",
              });
              var params = {
                FunctionName: globals.lambdaFunctionNames.updateSubscription,
                InvocationType: "RequestResponse",
                LogType: "None",
                Payload: JSON.stringify(token),
              };

              lambda.invoke(params, function (error, data) {
                if (error) {
                  alert(error);
                } else {
                  if (
                    data.hasOwnProperty("FunctionError") &&
                    data.FunctionError === "Handled"
                  ) {
                    var objError = JSON.parse(data.Payload);
                    alert(objError.errorMessage);
                  } else {
                    var results = JSON.parse(data.Payload);
                    if (results.hasOwnProperty("errorMessage")) {
                      alert(results.errorMessage);
                    } else {
                      updateAttribute(
                        cognitoUser,
                        "custom:subscriptionId",
                        results.id
                      );
                      updateAttribute(
                        cognitoUser,
                        "custom:subscriptionPlan",
                        results.plan.id
                      );
                      state.set(["user", "subscriptionPlan"], results.plan.id);

                      // TODO: Validate that everything looks good
                      alert(
                        "You have successfully created your " +
                          results.plan.name +
                          " subscription."
                      );

                      actions.goHome(state);
                    }
                  }
                }
              });
            }
          } else {
            showError("Update failed", err);
          }
        });
      });
    });
  }
}

export function purchaseCustom(token) {
  // Can I cache all this user stuff?
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        alert(err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            var user = state.get(["user"]);
            token.subscriptionId = user.subscriptionId;
            token.amount = 4999;
            token.planDescription = "MiraCheck Custom Aircraft Model purchase";
            token.stage = globals.stage;
            token.purchaseCustom = true;

            // Call chargeCardProPlanUnlimited and if successful update user
            var lambda = new AWS.Lambda({
              region: "us-east-1",
              apiVersion: "2015-03-31",
            });
            var params = {
              FunctionName: globals.lambdaFunctionNames.chargeCardPlans,
              InvocationType: "RequestResponse",
              LogType: "None",
              Payload: JSON.stringify(token),
            };

            lambda.invoke(params, function (error, data) {
              if (error) {
                alert(error);
              } else {
                if (
                  data.hasOwnProperty("FunctionError") &&
                  data.FunctionError === "Handled"
                ) {
                  var objError = JSON.parse(data.Payload);
                  alert(objError.errorMessage);
                } else {
                  var results = JSON.parse(data.Payload);
                  if (results.hasOwnProperty("errorMessage")) {
                    alert(results.errorMessage);
                  } else {
                    // TODO: Validate that everything looks good
                    alert(
                      "You have successfully purchased a Custom Aircraft Model. Email your photos or scans of the content to aircraft@miralouaero.com and in 2-4 weeks it will appear in your My Checklists."
                    );

                    actions.goHome(state);
                  }
                }
              }
            });
          } else {
            showError("Purchase custom failed", err);
          }
        });
      });
    });
  }
}

export function updateSubscriptionCancel() {
  // If CheckMate subscriber
  var subscriptionPlan = state.get(["user", "subscriptionPlan"]);

  if (subscriptionPlan.startsWith("basic-plan")) {
    actions.goHome(state);
    return;
  }

  // Can I cache all this user stuff?
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        alert(err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            var user = state.get(["user"]);
            var event = {
              subscriptionId: user.subscriptionId,
              customerId: user.stripeCustomerId,
              stage: globals.stage,
              subscriptionPlan: "no-plan",
            };

            var lambda = new AWS.Lambda({
              region: "us-east-1",
              apiVersion: "2015-03-31",
            });
            var params = {
              FunctionName: globals.lambdaFunctionNames.updateSubscription,
              InvocationType: "RequestResponse",
              LogType: "None",
              Payload: JSON.stringify(event),
            };

            lambda.invoke(params, function (error, data) {
              if (error) {
                alert(error);
              } else {
                if (
                  data.hasOwnProperty("FunctionError") &&
                  data.FunctionError === "Handled"
                ) {
                  var objError = JSON.parse(data.Payload);
                  alert(objError.errorMessage);
                } else {
                  var results = JSON.parse(data.Payload);
                  if (results.hasOwnProperty("errorMessage")) {
                    alert(results.errorMessage);
                  } else {
                    updateAttribute(
                      cognitoUser,
                      "custom:subscriptionId",
                      results.id
                    );
                    updateAttribute(
                      cognitoUser,
                      "custom:subscriptionPlan",
                      results.plan.id
                    );
                    state.set(["user", "subscriptionPlan"], results.plan.id);

                    // TODO: Validate that everything looks good
                    // alert("You have successfully created your " + results.plan.name + " subscription.");
                    alert(
                      "You have successfully cancelled your subscription and have been downgraded to the free plan."
                    );

                    actions.goHome(state);
                  }
                }
              }
            });
          } else {
            showError("Update failed", err);
          }
        });
      });
    });
  }
}

export function updateSubscriptionBasic() {
  // If CheckMate subscriber
  var subscriptionPlan = state.get(["user", "subscriptionPlan"]);

  if (subscriptionPlan.startsWith("basic-plan")) {
    actions.goHome(state);
    return;
  }

  // Can I cache all this user stuff?
  var poolData = {
    UserPoolId: cognito.userPoolId, // Your user pool id here
    ClientId: cognito.clientId, // Your client id here
  };
  var userPool = new CognitoUserPool(poolData);
  var cognitoUser = userPool.getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        alert(err);
        return;
      }
      console.log("session validity: " + session.isValid());

      var logins = {};
      logins[cognito.logins] = session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognito.identityPoolId, // your identity pool id here
        Logins: logins,
        region: "us-east-1",
      });

      AWS.config.credentials.refresh(function () {
        AWS.config.credentials.get(function (err) {
          if (!err) {
            var user = state.get(["user"]);
            var event = {
              subscriptionId: user.subscriptionId,
              customerId: user.stripeCustomerId,
              stage: globals.stage,
            };

            var lambda = new AWS.Lambda({
              region: "us-east-1",
              apiVersion: "2015-03-31",
            });
            var params = {
              FunctionName: globals.lambdaFunctionNames.updateSubscriptionBasic,
              InvocationType: "RequestResponse",
              LogType: "None",
              Payload: JSON.stringify(event),
            };

            lambda.invoke(params, function (error, data) {
              if (error) {
                alert(error);
              } else {
                if (
                  data.hasOwnProperty("FunctionError") &&
                  data.FunctionError === "Handled"
                ) {
                  var objError = JSON.parse(data.Payload);
                  alert(objError.errorMessage);
                } else {
                  var results = JSON.parse(data.Payload);
                  if (results.hasOwnProperty("errorMessage")) {
                    alert(results.errorMessage);
                  } else {
                    updateAttribute(
                      cognitoUser,
                      "custom:subscriptionId",
                      results.id
                    );
                    updateAttribute(
                      cognitoUser,
                      "custom:subscriptionPlan",
                      results.plan.id
                    );
                    state.set(["user", "subscriptionPlan"], results.plan.id);

                    // TODO: Validate that everything looks good
                    alert(
                      "You have successfully created your " +
                        results.plan.name +
                        " subscription."
                    );

                    actions.goHome(state);
                  }
                }
              }
            });
          } else {
            showError("Update failed", err);
          }
        });
      });
    });
  }
}

export function updateSubscriptionBasicCheckMate() {
  return new Promise((resolve, reject) => {
    try {
      // Can I cache all this user stuff?
      var poolData = {
        UserPoolId: cognito.userPoolId, // Your user pool id here
        ClientId: cognito.clientId, // Your client id here
      };
      var userPool = new CognitoUserPool(poolData);
      var cognitoUser = userPool.getCurrentUser();

      if (cognitoUser != null) {
        cognitoUser.getSession(function (err, session) {
          if (err) {
            reject(err);
            return;
          }
          console.log("session validity: " + session.isValid());

          var logins = {};
          logins[cognito.logins] = session.getIdToken().getJwtToken();

          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: cognito.identityPoolId, // your identity pool id here
            Logins: logins,
            region: "us-east-1",
          });

          AWS.config.credentials.refresh(function () {
            AWS.config.credentials.get(function (err) {
              if (!err) {
                var user = state.get(["user"]);

                if (user.subscriptionPlan === "basic-plan") {
                  updateAttribute(
                    cognitoUser,
                    "custom:subscriptionPlan",
                    "basic-plan-checkmate"
                  );
                  state.set(
                    ["user", "subscriptionPlan"],
                    "basic-plan-checkmate"
                  );
                }
                resolve("");
              } else {
                reject(err);
              }
            });
          });
        });
      }
    } catch (e) {
      reject(e);
    }
  });
}

export function getJsonFileFromS3(
  bucketName,
  fileName,
  overrideKeyPrefix = ""
) {
  return new Promise((resolve, reject) => {
    try {
      // Can I cache all this user stuff?
      var poolData = {
        UserPoolId: cognito.userPoolId, // Your user pool id here
        ClientId: cognito.clientId, // Your client id here
      };
      var userPool = new CognitoUserPool(poolData);
      var cognitoUser = userPool.getCurrentUser();

      if (cognitoUser != null) {
        cognitoUser.getSession(function (err, session) {
          if (err) {
            reject(err);
            return;
          }
          console.log("session validity: " + session.isValid());

          var logins = {};
          logins[cognito.logins] = session.getIdToken().getJwtToken();

          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: cognito.identityPoolId, // your identity pool id here
            Logins: logins,
            region: "us-east-1",
          });

          AWS.config.credentials.refresh(function () {
            AWS.config.credentials.get(async function (err) {
              if (!err) {
                var keyPrefix =
                  overrideKeyPrefix !== ""
                    ? overrideKeyPrefix
                    : utils.getKeyPrefix(AWS.config.credentials.identityId);

                var s3 = new AWS.S3({ useDualStack: true });
                var data = await s3
                  .getObject({
                    Bucket: contentBucket,
                    Key: keyPrefix + "/" + fileName,
                  })
                  .promise();

                console.log("GET!: " + data.Body.toString());

                resolve(JSON.parse(data.Body.toString()));
              } else {
                reject(err);
              }
            });
          });
        });
      }
    } catch (e) {
      reject(e);
    }
  });
}

export function putJsonFileToS3(bucketName, fileName, data) {
  return new Promise((resolve, reject) => {
    try {
      // Can I cache all this user stuff?
      var poolData = {
        UserPoolId: cognito.userPoolId, // Your user pool id here
        ClientId: cognito.clientId, // Your client id here
      };
      var userPool = new CognitoUserPool(poolData);
      var cognitoUser = userPool.getCurrentUser();

      if (cognitoUser != null) {
        cognitoUser.getSession(function (err, session) {
          if (err) {
            reject(err);
            return;
          }
          console.log("session validity: " + session.isValid());

          var logins = {};
          logins[cognito.logins] = session.getIdToken().getJwtToken();

          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: cognito.identityPoolId, // your identity pool id here
            Logins: logins,
            region: "us-east-1",
          });

          AWS.config.credentials.refresh(function () {
            AWS.config.credentials.get(async function (err) {
              if (!err) {
                var body = JSON.stringify(data);

                var keyPrefix = utils.getKeyPrefix(
                  AWS.config.credentials.identityId
                );
                var s3 = new AWS.S3({ useDualStack: true });
                await s3
                  .putObject({
                    Bucket: bucketName,
                    Key: keyPrefix + "/" + fileName,
                    Body: body,
                  })
                  .promise();

                //console.log("PUT!: " + data);
                resolve(data);
              } else {
                alert(err.message);
                reject(err);
              }
            });
          });
        });
      }
    } catch (e) {
      reject(e);
    }
  });
}

function showError(title, err) {
  if (err == null) {
    actions.showError(title, "Unknown error.");
  } else if (err.code === "NetworkingError") {
    actions.showError(
      title,
      "Network error. Please check connectivity and try again."
    );
  } else {
    actions.showError(title, err.message + "\n\nDetail: " + err.stack);
  }
}

function showErrorNoDetail(title, err) {
  if (err == null) {
    actions.showError(title, "Unknown error.");
  } else if (err.code === "NetworkingError") {
    actions.showError(
      title,
      "Network error. Please check connectivity and try again."
    );
  } else {
    actions.showError(title, err.message);
  }
}
