const globals = {
    v4Time: 1563441390000, // 1563778800000 July 22, 3am EST time
    stage: "prod",
    // hostUrl: "https://localhost:3000",
    // hostUrl: "https://dev-checklist.miralouaero.com",
    hostUrl: "https://checklist.miracheck.com",
    // hostUrl: "https://dev-igapp.miralouaero.com", // IGAPP change this
    // hostUrl: "https://igappmiralouaero.com",
    contentBucket: "content.checklist.miralouaero.com",
    // contentBucket: "content.igapp.miralouaero.com", // IGAPP change this
    miracheckContentBucket: "content.checklist.miralouaero.com",
    apiGatewayUrl: "https://6qlld3f4yl.execute-api.us-east-1.amazonaws.com/prod", // IGAPP change this
    // apiGatewayUrl: "https://0awcejulzk.execute-api.us-east-1.amazonaws.com/prod", // IGAPP change this
    stripeKeyDev: "pk_test_OLIBXG28fbBQ1BBpxPWdVRKe",
    stripeKeyProd: "pk_live_sa2GpJNU1qtWv5tw6NMcovb9",
    defaultColors: [
        {title: 'black', color: '#000000'},
        {title: 'gray', color: '#808080'},
        {title: 'silver', color: '#C0C0C0'},
        {title: 'white', color: '#FFFFFF'},
        {title: 'maroon', color: '#800000'},
        {title: 'red', color: '#FF0000'},
        {title: 'olive', color: '#808000'},
        {title: 'yellow', color: '#FFFF00'},
        {title: 'green', color: '#008000'},
        {title: 'lime', color: '#00FF00'},
        {title: 'teal', color: '#008080'},
        {title: 'aqua', color: '#00FFFF'},
        {title: 'navy', color: '#000080'},
        {title: 'blue', color: '#0000FF'},
        {title: 'purple', color: '#800080'},
        {title: 'fuchshia', color: '#FF00FF'}
    ],
    headingColors: [
        {title: 'noteyellow', color: '#FFF8C6'},
        {title: 'lightblue', color: '#ADD8E6'},
        {title: 'lightpink', color: '#FFB6C1'},
        {title: 'lightcyan', color: '#E0FFFF'},
        {title: 'lightgreen', color: '#90EE90'},
        {title: 'lightsalmon', color: '#FFA07A'},
        {title: 'lightskyblue', color: '#87CEFA'},
        {title: 'lightsteelblue', color: '#B0C4DE'},
        {title: 'linen', color: '#FAF0E6'},
        {title: 'gold', color: '#FFD700'},
        {title: 'thistle', color: '#D8BFD8'},
        {title: 'powderblue', color: '#B0E0E6'},
        {title: 'khaki', color: '#F0E68C'},
        {title: 'lightgray', color: '#D3D3D3'},
        {title: 'whitesmoke', color: '#F5F5F5'},
        {title: 'white', color: '#FFFFFF'}
    ],
    // IGAPP
    // cognito: { // IGAPP change all properties here
    //     region: "us-east-1",
    //     userPoolId: "us-east-1_hs2rj2seK",
    //     clientId: "q4cqvifgp079o96sbhq2bbt5v",
    //     identityPoolId: "us-east-1:86585d99-1999-447c-b19c-a98bdb8a60be",
    //     logins: "cognito-idp.us-east-1.amazonaws.com/us-east-1_hs2rj2seK"
    // },
    // MiraCheck
    cognito: {
        region: "us-east-1",
        userPoolId: "us-east-1_bPRWn35rR",
        clientId: "6n5vpcb900nqohd0q5bl4bue1j",
        identityPoolId: "us-east-1:5249265b-becb-4665-b1db-136e81fb4f45",
        logins: "cognito-idp.us-east-1.amazonaws.com/us-east-1_bPRWn35rR"
    },
    lambdaFunctionNames: {
        fetchCheckmateChecklists: 'fetch-checkmate-checklists',
        getCognitoUser: 'getCognitoUser', // IGAPP Change
        // getCognitoUser: 'getCognitoUserIgapp', // IGAPP Change
        algoliaSetSettings: 'algoliaSetSettings',
        subscribe: 'subscribe',
        forgetDevice: 'forgetDevice',
        importCsv: 'importCsv',
        logToCloudWatch: 'logToCloudWatch',
        getRecipeInfo: 'getRecipeInfo',
        convertImageUrlToBase64: 'convertImageUrlToBase64',
        chargeCard: 'chargeCard',
        updateCustomerCard: 'updateCustomerCard',
        chargeCardPlans: 'chargeCardPlans',
        updateSubscription: 'updateSubscription',
        updateSubscriptionBasic: 'updateSubscriptionBasic'
    }
}

export const cognito = globals.cognito; 
export const contentBucket = globals.contentBucket;
export const miracheckContentBucket = globals.miracheckContentBucket;

export default globals;