import React, {Component} from 'react';
import {render} from 'react-dom';
import {SortableContainer, SortableElement, SortableHandle, arrayMove} from 'react-sortable-hoc';
import * as awsS3 from '../persistence/s3';
import FontAwesome from 'react-fontawesome';

import _ from 'lodash';

/*
const SortableItem = SortableElement(({value}) => <li>{value}</li>);

const SortableList = SortableContainer(({items}) => {
    return (
        <ul>
            {items.map((value, index) =>
                <SortableItem key={`item-${index}`} index={index} value={value} />
            )}
        </ul>
    );
});
*/
const DragHandle = SortableHandle(() => <div style={{cursor: 'ns-resize'}}><FontAwesome fixedWidth={true} name="unsorted" /></div>);

const SortableItem = SortableElement(({item}) => (
    <div style={{
        position: 'relative',
        width: '100%',
        display: 'block',
        padding: 20,
        borderBottom: '1px solid #EFEFEF',
        boxSizing: 'border-box',
        UserSelect: 'none',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        zIndex: 1000,
        cursor: 'default',
        backgroundColor: "#1F272B"
    }}>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
            <div style={{marginLeft: 10}}>{item}</div><DragHandle />
        </div>
    </div>
));

const SortableList = SortableContainer(({items}) => (
    <div style={{
        width: '100%',
        height: '70vh',
        maxWidth: '700px',
        margin: '0 auto',
        overflow: 'auto',
        border: '2px solid #EFEFEF',
        borderRadius: 3,
        backgroundColor: "#1F272B"
    }}>
        {items.map((item, index) => <SortableItem key={`item-${index}`} index={index} item={item.name}/>)}
    </div>
));

class SortDocuments extends Component {
	constructor(props, context) {
		super(props, context);

		var newDocuments = _.cloneDeep(this.props.documents);
		this.saveItem = newDocuments.shift();
		this.state = {
			items: newDocuments
		}
	}

    onSortEnd = ({oldIndex, newIndex}) => {
        this.setState({
            items: arrayMove(this.state.items, oldIndex, newIndex)
        });

		this.state.items.unshift(this.saveItem);
		awsS3.setDocuments(this.state.items);
        this.state.items.shift();
    };
    render() {
        return (
			<SortableList items={this.state.items} onSortEnd={this.onSortEnd} lockAxis="y" useDragHandle={true}/>	
        )
    }
}

export default SortDocuments;