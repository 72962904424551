var templateMinimal = {
    "refresh": 0,
    "comments": {
        "show": false,
        "content": ""
    },
    "flightTimer": {
        "clock": "",
        "clockUTC": "",
        "elapsed": "0.0 Hours"
    },
    "logbook": {
        "timeOut": "------------",
        "timeIn": "------------"
    },
    "preferences": {
        "defaultViewMode": "horz",
        "speedType": "KIAS"
    },
    selected: {
        listIndex: 0,
        sectionIndex: 0,
        itemIndex: 0
    },
    "selectedListIndex": 0,
    "type": "checklist",
    "checklistView": "horz",
    "speakMode": "check",
    "id": "",
    "name": "",
    "description": "",
    "genre": "aviation",
    "publisher": "self",
    "tags": [],
    "speedType": "KIAS",
    "children": []
};

export default templateMinimal;