import React, { useState, useEffect } from "react";

import { updateSubscriptionV2 } from "../../persistence/s3";
import * as actions from '../../actions/actions';

import browserHistory from '../../history';

import { Button, Panel } from "react-bootstrap";

import state from "../../state/state";

const Return = (props) => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [subscriptionId, setSubscriptionId] = useState('');

  useEffect(() => {
    async function fetchData() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const sessionId = urlParams.get('session_id');

      const response = await fetch(`https://6qlld3f4yl.execute-api.us-east-1.amazonaws.com/prod/stripe-session-status?session_id=${sessionId}`, {
        method: "GET"
      });
      const jsonResponse = await response.json();
      //const newJsonResponse = JSON.parse(jsonResponse.body);

      // urlParams.delete("session_id");
      // window.history.replaceState({}, document.title, "/");

      setStatus(jsonResponse.status);
      setCustomerEmail(jsonResponse.customer_email);
      setCustomerId(jsonResponse.customer);
      setSubscriptionId(jsonResponse.subscription);
    }
    fetchData();
  }, []);

  const handleDownload = () => {
    window.open("https://apps.apple.com/app/id1183839722", "_blank");
  };

  if (status === 'open') {
    alert("There was a problem with the purchase. Try again.");
    browserHistory.push("/perfTrialCheckout");
    // return (
    //   <Navigate to="/checkout" />
    // )
  }

  if (status === 'complete') {
    updateSubscriptionV2(customerId, subscriptionId, props.plan ? props.plan : "pro-plan-performance-as-yearly-v4");

    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%" }}>
        <Panel style={{ maxWidth: "800px", marginTop: "100px", marginLeft: "10px", marginRight: "10px" }} defaultExpanded bsStyle="primary">
          <Panel.Heading style={{ backgroundColor: "royalblue", color: "white" }}>
            <Panel.Title>
              {props.title ? props.title : "3 Months Trial - Pro Performance Plan"}
            </Panel.Title>
          </Panel.Heading>
          <Panel.Collapse>
            <Panel.Body>
              Thank you for supporting Goose! <br></br>
              {/* You are now subscribed to the 3 months trial on our yearly Performance Plan. Your $4.99 was a one-time payment for the next 90 days, after which we will automatically onboard you to our Performance plan which provides the best value for money, charging only $36/year. */}
              <br></br>
              {/* <br></br> */}
              You can cancel anytime and are still able to  use all features until the current plan expires.
              <br /><br />
              If you have any questions in the mean time, please do not hesitate to send an email to <a href="mailto:support@aerosys.io">support@aerosys.io</a> or reach out to us on Instagram @Goose.TalkToMe or YouTube @Goose.TalkToMe
              <br /><br />
              <br></br>
              Happy Landings
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
        <Button bsSize="large" style={{ width: "15%", minWidth: "200px", borderRadius: "10px", margin: "20px", marginTop: "50px", backgroundColor: "#1F272A", color: "white", fontWeight: "bold" }} onClick={handleDownload}>DOWNLOAD APP</Button>
        <Button bsSize="large" style={{ width: "15%", minWidth: "200px", borderRadius: "10px", margin: "20px", backgroundColor: "rgb(255,181,63)", color: "black", fontWeight: "bold" }} onClick={(e) => {
          actions.goHome();
        }}>LET'S GO</Button>
      </div>

    )
  }

  return null;
}

export default Return;