import React from "react";
import { branch } from "baobab-react/higher-order";
import PropTypes from "baobab-react/prop-types";

import { Checkbox, Button, FormControl } from "react-bootstrap";

import Flexbox from "flexbox-react";

import moment from "moment";

import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

import { Controlled as CodeMirror } from "react-codemirror2";

require("codemirror/lib/codemirror.css"); // eslint-disable-line
require("codemirror/theme/material.css"); // eslint-disable-line
require("codemirror/mode/javascript/javascript"); // eslint-disable-line
require("codemirror/mode/htmlmixed/htmlmixed"); // eslint-disable-line
require("codemirror/addon/fold/foldcode"); // eslint-disable-line
require("codemirror/addon/fold/foldgutter"); // eslint-disable-line
require('codemirror/addon/fold/brace-fold.js'); // eslint-disable-line
require("codemirror/addon/fold/foldgutter.css"); // eslint-disable-line

const showSecond = true;
class TimerCardEdit extends React.PureComponent {
	constructor(props, context) {
		super(props, context);

		const m = moment().utcOffset(0);
		m.set({ hour: this.props.timerTemplate.hours, minute: this.props.timerTemplate.minutes, second: this.props.timerTemplate.seconds, millisecond: 0 });

		const customTimerTemplate = JSON.stringify(this.props.customTimerTemplate, null, 3);

		let custom;
		if (this.props.timerTemplate.hasOwnProperty("custom") && typeof this.props.timerTemplate.custom === "string" && this.props.timerTemplate.custom !== "") {
			try {
				custom = JSON.parse(this.props.timerTemplate.custom);
			} catch (e) {
				console.log(e);
			}

			if (custom.hasOwnProperty("name")) {
				custom = JSON.stringify(custom, null, 3);
			} else {
				custom = customTimerTemplate;
			}
		}

		this.state = {
			guid: this.props.timerTemplate.guid,
			name: this.props.timerTemplate.name,
			type: this.props.timerTemplate.type,
			custom: custom,
			mode: this.props.timerTemplate.mode,
			viewType: this.props.timerTemplate.viewType,
			hours: m.format("HH"),
			minutes: m.format("mm"),
			seconds: m.format("ss"),
			time: m,
			repeat: this.props.timerTemplate.repeat,
			repeatCount: this.props.timerTemplate.repeatCount,
			label1: this.props.timerTemplate.label1,
			label2: this.props.timerTemplate.label2,
			hideDismiss: this.props.timerTemplate.hideDismiss,
			suppressAudio: this.props.timerTemplate.suppressAudio,
			message: this.props.timerTemplate.action.message,
			actionType: this.props.timerTemplate.action.type,
			action: this.props.timerTemplate.action.hasOwnProperty("action") ? this.props.timerTemplate.action.action : "",
			checklistId: this.props.timerTemplate.action.hasOwnProperty("checklistId") ? this.props.timerTemplate.action.checklistId : "",
			linkItemId: this.props.timerTemplate.action.hasOwnProperty("linkItemId") ? this.props.timerTemplate.action.linkItemId : ""
		};
	}

	render() {
		let actions = [];

		actions.push(<option key={"action"} value={""}>None</option>);
		actions.push(<option key={"action0"} value={"goto"}>Goto</option>);
		actions.push(<option key={"action1"} value={"gotoAndReturn"}>Goto and Return</option>);

		let checklistIds = [];

		checklistIds.push(<option key={"checklistId"} value={""}>None</option>);

		for (let i = 0; i < this.props.documents.length; i++) {
			const document = this.props.documents[i];
			if (document.hasOwnProperty("checklistId") && document.visible && document.checklistId !== "") {
				checklistIds.push(<option key={"checklistId" + i} value={document.checklistId}>{document.checklistId}</option>);
			}
		}

		let types = [];

		types.push(<option key={"countdown"} value={"countdown"}>Count Down</option>);
		types.push(<option key={"countup"} value={"countup"}>Count Up</option>);
		// types.push(<option key={"custom"} value={"custom"}>Custom</option>);

		let modes = [];

		modes.push(<option key={"normal"} value={"normal"}>Normal (Floating)</option>);
		modes.push(<option key={"modal"} value={"modal"}>Modal</option>);
		// modes.push(<option key={"appBar"} value={"appBar"}>App Bar</option>);

		let viewTypes = [];

		viewTypes.push(<option key={"top-left"} value={"top-left"}>Top Left</option>);
		viewTypes.push(<option key={"top-right"} value={"top-right"}>Top Right</option>);

		const actionComponent =
			<div>
				<div className="editTimerHeader" style={{  fontWeight: "bold", marginTop: "5px" }}>Message</div>
				<div><textarea ref="inputMessage" className="form-control" type="text" placeholder="(Optional: Message)" value={this.state.message} onChange={(e) => { this.setState({ message: e.target.value }); }} /></div>
				<div className="editTimerHeader"  style={{ fontWeight: "bold", marginTop: "5px" }}>Action</div>
				<FormControl componentClass="select" style={{ marginBottom: "5px" }} value={this.state.action} onChange={(e) => {
					this.setState({ action: e.target.value });
				}}>
					{actions}
				</FormControl>
				<div className="editTimerHeader" style={{ fontWeight: "bold", marginTop: "5px" }}>Checklist ID</div>
				{/* <FormControl componentClass="select" value={this.state.action} onChange={(e)=>{
					this.setState({checklistId:e.target.value})
				}}>
					{checklistIds}
				</FormControl> */}
				<input style={{ width: "150px" }} ref="inputName" className="form-control" type="text" value={this.state.checklistId} placeholder="" onChange={(e) => { this.setState({ checklistId: e.target.value }); }} />
				<div className="editTimerHeader" style={{ fontWeight: "bold", marginTop: "5px" }}>Link Item ID</div>
				<input style={{ width: "150px" }} ref="inputName" className="form-control" type="text" value={this.state.linkItemId} placeholder="" onChange={(e) => { this.setState({ linkItemId: e.target.value }); }} />
			</div>;

		let typeComponent;
		if (this.state.type === "custom") {
			typeComponent =
				<div style={{ width: "100%" }}>
					<CodeMirror
						value={this.state.custom}
						options={{
							mode: "application/json",
							lineNumbers: true
						}}
						onBeforeChange={(editor, data, value) => {
							this.setState({ custom: value });
						}}
						onBlur={() => {
						}}
					/>
				</div>;

		} else {
			typeComponent =
				<div>
					<div className="editTimerHeader" style={{ fontWeight: "bold", marginTop: "5px" }}>Mode</div>
					<FormControl componentClass="select" style={{ marginBottom: "5px" }} value={this.state.mode} onChange={(e) => {
						this.setState({ mode: e.target.value });
					}}>
						{modes}
					</FormControl>
					{this.state.mode === "appBar" &&
						<div>
							<div className="editTimerHeader" style={{ fontWeight: "bold", marginTop: "5px" }}>View Type</div>
							<FormControl componentClass="select" style={{ marginBottom: "5px" }} value={this.state.viewType} onChange={(e) => {
								this.setState({ viewType: e.target.value });
							}}>
								{viewTypes}
							</FormControl>
						</div>
					}
					<div style={{ color: "#000000", fontWeight: "bold", marginTop: "5px" }}>Time</div>
					<TimePicker 
						style={{ width: 100 }}
						showSecond={showSecond}
						value={this.state.time}
						onChange={(value) => {
							//console.log(value && value.format(str));
							this.setState({
								time: value,
								hours: value.format("HH"),
								minutes: value.format("mm"),
								seconds: value.format("ss")
							});
						}}
					/>
					<div style={{ marginTop: "5px" }}>
						<Checkbox checked={this.state.repeat ? "checked" : ""} onChange={(e) => { this.setState({ repeat: e.target.checked }); }}>
							<span className="editTimerHeader">Repeat</span>
						</Checkbox>
						{this.state.repeat &&
							<div>
								<div style={{ color: "#000000", marginTop: "5px" }}>Repeat Count</div>
								<input style={{ width: "50px" }} ref="inputRepeatCount" className="form-control" type="text" value={this.state.repeatCount} onChange={(e) => { this.setState({ repeatCount: e.target.value }); }} />
							</div>
						}
					</div>
					<div className="editTimerHeader" style={{ fontWeight: "bold", marginTop: "5px" }}>Label 1</div>
					<div><textarea ref="inputMessage" className="form-control" type="text" placeholder="(Optional: Label 1)" value={this.state.label1} onChange={(e) => { this.setState({ label1: e.target.value }); }} /></div>
					<div className="editTimerHeader" style={{ fontWeight: "bold", marginTop: "5px" }}>Label 2</div>
					<div><textarea ref="inputMessage" className="form-control" type="text" placeholder="(Optional: Label 2)" value={this.state.label2} onChange={(e) => { this.setState({ label2: e.target.value }); }} /></div>
					<Checkbox className="editTimerHeader" checked={this.state.hideDismiss ? "checked" : ""} onChange={(e) => { this.setState({ hideDismiss: e.target.checked }); }}>
						<span style={{ fontWeight: "bold" }}>Hide Dismiss Button</span>
					</Checkbox>
					<Checkbox className="editTimerHeader"checked={this.state.suppressAudio ? "checked" : ""} onChange={(e) => { this.setState({ suppressAudio: e.target.checked }); }}>
						<span style={{ fontWeight: "bold" }}>Don't Speak Audio</span>
					</Checkbox>
					{actionComponent}

				</div>;
		}

		let component = (
			<div style={{ padding: 10, width: "100%" }}>
				<div className="editTimerHeader" style={{ fontWeight: "bold", marginTop: "5px" }}>Name</div>
				<input style={{ width: "260px" }} ref="inputName" className="form-control" type="text" value={this.state.name} placeholder="(Required: Timer Name)" onChange={(e) => { this.setState({ name: e.target.value }); }} />
				<div className="editTimerHeader" style={{ fontWeight: "bold", marginTop: "5px" }}>Type</div>
				<FormControl componentClass="select" style={{ marginBottom: "5px" }} value={this.state.type} onChange={(e) => {
					this.setState({ type: e.target.value });
				}}>
					{types}
				</FormControl>
				{typeComponent}
				<Flexbox style={{ marginTop: "10px" }} flexDirection="row" alignItems="center" justifyContent="space-between">
					<Button onClick={() => {
						this.props.onSave(this.state);
					}}>
						Save
					</Button>
					<Button onClick={() => {
						this.props.onCancel(this.state);
					}}>
						Cancel
					</Button>
				</Flexbox>
			</div>
		);

		return (
			<Flexbox>
				{component}
			</Flexbox>
		);
	}
}

TimerCardEdit.contextTypes = {
	tree: PropTypes.baobab
};

export default branch({
	user: ["user"],
	timerTemplates: ["timerTemplates"],
	documents: ["documents"],
	customTimerTemplate: ["templates", "customTimer"],
	timerContent: ["content", "timer"]
}, TimerCardEdit);