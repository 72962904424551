import React from "react";
import FontAwesome from "react-fontawesome";

import { OverlayTrigger, Popover } from "react-bootstrap";

class Card extends React.Component {
  render() {
    var icon = "question";
    for (var i = 0; i < this.props.genres.length; i++) {
      var genre = this.props.genres[i];
      if (this.props.genre == genre.value) {
        icon = genre.icon;
      }
    }

    var iconPrivate = (
      <FontAwesome
        fixedWidth={true}
        title="This checklist is private."
        style={{ color: "#888888", fontSize: "14px" }}
        name="lock"
      />
    );
    var iconPublic = (
      <FontAwesome
        fixedWidth={true}
        title="This checklist is public."
        style={{ color: "#88aa88", fontSize: "14px" }}
        name="users"
      />
    );

    var contributorsTooltip = "";
    var creator = "Creator:\n";
    var contributors = "\n\nContributors:\n";
    if (
      !this.props.hasOwnProperty("contributors") ||
      this.props.contributors == null ||
      this.props.contributors.length === 0
    ) {
      contributorsTooltip = "There are currently no contributors.";
    } else {
      contributorsTooltip = contributorsTooltip + creator;
      contributorsTooltip =
        contributorsTooltip +
        (this.props.contributors[0] &&
        this.props.contributors[0].nickname &&
        this.props.contributors[0].nickname.length > 0
          ? this.props.contributors[0].nickname
          : this.props.contributors[0].name);

      if (this.props.contributors.length > 1) {
        contributorsTooltip = contributorsTooltip + contributors;
        for (var i = 1; i < this.props.contributors.length; i++) {
          var contributor = this.props.contributors[i].name;
          if (i < this.props.contributors.length - 1) {
            contributorsTooltip = contributorsTooltip + contributor + "\n";
          } else {
            contributorsTooltip = contributorsTooltip + contributor;
          }
        }
      }
    }

    const popoverContributors = (
      <Popover id="popover-positioned-bottom" title="Contributors">
        {contributorsTooltip}
      </Popover>
    );

    var iconContributors = (
      <FontAwesome
        id="contributorsIcon"
        fixedWidth={true}
        title={contributorsTooltip}
        style={{ color: "#888888", fontSize: "14px", marginRight: "5px" }}
        name="user"
      />
    );

    var overlayContributors = (
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="bottom"
        overlay={popoverContributors}
      >
        {iconContributors}
      </OverlayTrigger>
    );

    var shared;
    if (this.props.shared) {
      shared = (
        <div
          style={{
            position: "absolute",
            top: "35px",
            left: "30px",
            width: "128px",
            height: "128px",
          }}
        >
          <FontAwesome
            title="This checklist is shared and can't be edited."
            fixedWidth={true}
            style={{
              opacity: 0.85,
              color: "darkgreen",
              fontSize: "100px",
              width: "128px",
              height: "128px",
            }}
            name="shield"
          />
        </div>
      );
    }

    var visible;
    if (!this.props.visible) {
      visible = (
        <div
          style={{
            position: "absolute",
            top: "35px",
            left: "35px",
            width: "128px",
            height: "128px",
          }}
        >
          <FontAwesome
            title="This checklist will not display within the mobile app."
            fixedWidth={true}
            style={{
              opacity: 0.85,
              color: "darkred",
              fontSize: "100px",
              width: "128px",
              height: "128px",
            }}
            name="eye-slash"
          />
        </div>
      );
    }

    var addButton = this.props.addButton;

    var topLeft;
    if (addButton) {
      topLeft = addButton;
    } else {
      topLeft = this.props.privacy === "public" ? iconPublic : iconPrivate;
    }

    var card;

    if (this.props.compact) {
      
      card = (
        <div
          className="cardCompact"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: "6px",
            paddingRight: "6px",
            backgroundColor: this.props.disabled ? "#eeeeee" : "#374850",
            color: this.props.disabled ? "#888888" : "#bcbcbc",
          }}
          onClick={this.props.onClick}
        >
          
          {this.props.image != "" ? (
            <img
              src={this.props.image}
              alt="<img />"
              style={{
                width: "74px",
                height: "74px",
                border: "1px solid #a0a0a0",
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
                borderBottomRightRadius: "4px",
                borderBottomLeftRadius: "4px",
                objectFit: 'cover',
                objectPosition: "center",
              }}
            />
          ) : (
            <div
              style={{
                width: "74px",
                height: "74px",
                border: "1px solid #a0a0a0",
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
                borderBottomRightRadius: "4px",
                borderBottomLeftRadius: "4px",
                justifyContent: "center", // Center horizontally
                alignItems: "center", // Center vertically
              }}
            >
              <div
                style={{
                  width: "74px",
                  paddingLeft: " 16px",
                  paddingTop: "20px",
                }}
              >
               
                <FontAwesome
                  fixedWidth={true}
                  style={{ fontSize: "32px" }}
                  name={icon}
                />
              </div>
            </div>
          )}

          <div
            style={{
              width: "100%",
              height: "74px",
              paddingTop: "4px",
              paddingBottom: "4px",
              overflowY: "auto",
              paddingLeft: "8px",
              paddingRight: "4px",
            }}
          >
            {this.props.title}
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "0px",
              left: "0px",
              margin: "2px",
              backgroundColor: this.props.disabled ? "#eeeeee" : "#374850",
              borderTopRightRadius: "8px",
            }}
          >
            {overlayContributors}
          </div>
          <div
            style={{
              height: "74px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <div style={{ marginBottom: "4px" }}>
              {this.props.menuComponent}
            </div>
            <div style={{ marginTop: "4px" }}>{topLeft}</div>
          </div>

          {/* 
                    <div style={{ paddingLeft: "4px", display: "flex", alignItems: "center" }}>
                        <div style={{ border: "1px solid #a0a0a0", height: "44px" }}>
                        <FontAwesome fixedWidth={true} style={{ fontSize: "32px", marginLeft: "5px" }} name={icon} />
                        </div>
                        <div title={this.props.description} style={{ marginLeft: "5px", fontSize: "12px", fontWeight: "normal", maxHeight: "60px", overflowY: "auto" }}>
                            {this.props.title}
                        </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ marginLeft: "10px" }}>
                            {topLeft}
                        </div>
                        <div style={{ position: 'absolute', bottom: "0px", left: "0px", margin: "2px" }}>
                            {overlayContributors}
                        </div>
                        <div style={{ marginLeft: "5px", marginRight: "10px" }}>
                            {this.props.menuComponent}
                        </div>
                    </div> */}
        </div>
      );
    } else {
      card = (
        <div
          className="card"
          style={{
            padding: "4px",
            backgroundColor: this.props.disabled ? "#eeeeee" : "#222d32",
            color: this.props.disabled ? "#888888" : "#bcbcbc",
          }}
          onClick={this.props.onClick}
        >
          <div style={{ display: "flex", width: "100%", height: "100%" }}>
            <div
              style={{
                flex: "1 1 50%",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
                borderBottomLeftRadius: "8px",
                border: "1px solid #a0a0a0",
                justifyContent: "center",
              }}
            >
              {this.props.image != "" ? (
                <img
                  src={this.props.image}
                  alt="Uploaded"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    borderBottomRightRadius: "8px",
                    borderBottomLeftRadius: "8px",
                    border: "1px solid #a0a0a0",
                    objectFit: 'cover',
                    objectPosition: "center",
                  }}
                />
              ) : (
                <div style={{ paddingTop: "24px", height: "41px" }}>
                  <FontAwesome
                    fixedWidth={true}
                    style={{
                      fontSize: "100px",
                      width: "175px",
                      height: "100px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    name={icon}
                  />
                  <div style={{ fontSize: "10px", textAlign: "center" }}>
                    No Image Uploaded Yet
                  </div>
                </div>
              )}
            </div>
            <div style={{ flex: "1 1 50%" }}>
              <div style={{ paddingLeft: "8px", height: "56px" }}></div>
              <div
                style={{
                  paddingLeft: "8px",
                  fontSize: "16px",
                  fontWeight: "bold",
                  height: "48px",
                  overflowY: "auto",
                }}
              >
                {this.props.title}
              </div>
              <div
                style={{
                  paddingLeft: "8px",
                  paddingBottom: "4px",
                  height: "64px",
                  overflowX: "auto",
                  overflowY: "auto",
                }}
              >
                {this.props.description}
              </div>
            </div>
          </div>
          {/* <div style={{ width: '100%', overflowX: 'auto', display: 'flex' }}>
                        <div className="btn-pill" style={{ minWidth: '200px' }}>Tag</div>
                        <div> 
                        {/* TAG SECTION */}
          {/* </div> */}

          <div
            style={{
              position: "absolute",
              top: "0px",
              right: "0px",
              margin: "10px",
            }}
          >
            {this.props.menuComponent}
          </div>
          <div
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              margin:"4px",
              paddingLeft: "6px",
              paddingTop: "6px",
              paddingBottom: "4px",
              borderBottomRightRadius: "8px",
              borderTopLeftRadius: "4px",
              backgroundColor: "#222D32",
            }}
          >
            {topLeft} {overlayContributors}
          </div>
          <div
            style={{
              position: "absolute",
              top: "125px",
              left: "0px",
              margin: "10px",
            }}
          ></div>
          {shared}
          {visible}
        </div>
      );
    }

    return <div>{card}</div>;
  }
}

export default Card;
