import React from 'react';
import ReactDOM from 'react-dom';

import classNames from 'classnames';

import Leaf from './app-leaf.js';
import Glyphicon from '../components/app-icon';

import PropTypes from 'baobab-react/prop-types';
import * as actions from '../actions/actions';

import EditorCategory from '../components/app-editor-category.js';
import EditorList from '../components/app-editor-list.js';
import EditorSection from '../components/app-editor-section.js';

import CardLeaf from '../components/app-card-leaf.js';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'; 

import { pathToArray } from "../utils/utils";

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  WebkitUserSelect: "none",
  MozUserSelect: "none",
  msUserSelect: "none",
//   padding: grid * 2,
//   margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
//   background: isDragging ? "lightgreen" : "#88888844",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyleSection = isDraggingOver => ({
  background: isDraggingOver ? "#00000011" : "transparent",
//   padding: grid
});

const getListStyleList = isDraggingOver => ({
	background: isDraggingOver ? "#00000011" : "transparent",
  //   padding: grid
  });

  class CardCategory extends React.PureComponent {
	// shouldComponentUpdate(nextProps, nextState) {
	// 	//console.log("Category shouldComponentUpdate");
	// 	return this.props.node !== nextProps.node;
	// }

	handleSelectNodeAtPath(e) {
		actions.handleSelectNodeAtPath(this.context.tree,pathToArray(this.props.path));
	}

	handleToggleNode(e) {
		actions.handleToggleNode(this.context.tree.select(pathToArray(this.props.path)));
	}

	handleChangeLabel(e) {
		actions.handleChangeProperty(this.context.tree,"label",e.target.value);
	}

	render() {
		var self = this;
		var nodes;
		if (!this.props.node) {
			return <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>Loading...</div>
		} else {

		console.log(self.props.children);
		if( Object.prototype.toString.call( self.props.node.children ) === '[object Array]' && this.props.node.expanded ) {
			nodes = self.props.node.children.map(function (node, i) {
				let arrPath = self.props.path;
				arrPath = arrPath + ",children";
				arrPath = arrPath + "," + i;
				if (node.entity.type == "checklist") {
					return (
						<CardCategory 
							editorState={self.props.editorState} 
							key={node.entity.entityId} 
							node={node} path={arrPath} 
						/>
					);
				} else if (node.entity.type == "list") {
					return (
						<Draggable key={node.entity.entityId} draggableId={node.entity.entityId} index={i}>
							{(providedDrag, snapshot) => (
								<div
									ref={providedDrag.innerRef}
									{...providedDrag.draggableProps}
									// {...providedDrag.dragHandleProps}
									style={getItemStyle(
										snapshot.isDragging,
										providedDrag.draggableProps.style
									)}
								>
									<DragDropContext onDragEnd={(result)=>{actions.handleMoveNode(self.context.tree,
										result.destination.droppableId,
										result.source.index,
										result.destination.index
									)}}>
										<Droppable droppableId={node.entity.entityId}>
											{(provided, snapshot) => (
												<div
													{...provided.droppableProps}
													ref={provided.innerRef}
													style={getListStyleList(snapshot.isDraggingOver)}
												>
													<CardCategory
														editorState={self.props.editorState} 
														key={node.entity.entityId} node={node} 
														path={arrPath}
														provided={providedDrag}
													/>
													{provided.placeholder}
												</div>
												
											)}	
										</Droppable>
									</DragDropContext>	
								</div>
							)}
					  	</Draggable>  
					);
				} else if (node.entity.type == "section") {
					return (
						<Draggable key={node.entity.entityId} draggableId={node.entity.entityId} index={i}>
							{(providedDrag, snapshot) => (
								<div
									ref={providedDrag.innerRef}
									{...providedDrag.draggableProps}
									// {...providedDrag.dragHandleProps}
									style={getItemStyle(
										snapshot.isDragging,
										providedDrag.draggableProps.style
									)}
								>
									<DragDropContext onDragEnd={(result)=>{actions.handleMoveNode(self.context.tree,
										result.destination.droppableId,
										result.source.index,
										result.destination.index
									)}}>
										<Droppable droppableId={node.entity.entityId}>
											{(provided, snapshot) => (
												<div
													{...provided.droppableProps}
													ref={provided.innerRef}
													style={getListStyleSection(snapshot.isDraggingOver)}
												>
													<CardCategory
														editorState={self.props.editorState} 
														key={node.entity.entityId} node={node} 
														path={arrPath}
														provided={providedDrag}
													/>
													{provided.placeholder}
												</div>
												
											)}	
										</Droppable>
									</DragDropContext>	
								</div>
							)}
					  	</Draggable>  
					);
				} else {
					return (
						<Draggable key={node.entity.entityId} draggableId={node.entity.entityId} index={i}>
							{(provided, snapshot) => (
								<div
									ref={provided.innerRef}
									{...provided.draggableProps}
									// {...provided.dragHandleProps}
									style={getItemStyle(
										snapshot.isDragging,
										provided.draggableProps.style
									)}
							>
								<CardLeaf
									editorState={self.props.editorState} 
									node={node} 
									path={arrPath}
									provided={provided}
								/>
							</div>
							)}
					  	</Draggable>  
					);
				}
			});
		} else {
			nodes =
				<div>
					<span style={{fontWeight: "bold"}}>
						{this.props.node.entity.label}
					</span>
				</div>
		}

		var style;
		if (!this.props.node.expanded) {
			style = {display: "none"};
		} else {
			style = {marginLeft: "10px"};
		}
		
		var category, icon, color;

		if (this.props.node.entity.type == "category") {
			icon = this.props.node.expanded ? "folder-open" : "folder";
			color = "#ffc153";
		} else if (this.props.node.entity.type == "checklist") {
			icon = "question";
			for (var i=0; i<this.props.genres.length; i++) {
				var genre = this.props.genres[i];
				if (genre.value == this.props.node.entity.genre) {
					icon = genre.icon;
				}
			} 
			color = "#000000";
		} else if (this.props.node.entity.type == "list") {
			icon = "list-alt";
			color = "#0084c1";
		} else if (this.props.node.entity.type == "section") {
			icon = "table";
			color = "#ffc153";
		} else {
			icon = this.props.node.expanded ? "folder-open" : "folder";
			color = "#ffc153";
		}

		 
		if (!this.props.node.expanded) {
			style = { display: "none" };
		} else {
			style = { marginLeft: "5px" };
		}

		var editor;
			if (this.props.node.entity.type === "checklist") {
				editor = <EditorCategory node={this.props.node} provided={this.props.provided} path={this.props.path} />;
			} else if (this.props.node.entity.type === "list") {
				editor = <EditorList node={this.props.node} provided={this.props.provided} path={this.props.path}/>;
			} else if (this.props.node.entity.type === "section") {
				editor = <EditorSection node={this.props.node} provided={this.props.provided} path={this.props.path} />;
			}
		
		if (this.props.node.selected) {
			category =
			<div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center", padding: "2px", fontWeight: "bold", backgroundColor: "#357EC7", cursor: "pointer"}}>
				{/* <div onClick={this.handleToggleNode.bind(this)}>
					<Glyphicon style={{color: "white", marginRight: "10px"}} glyph={this.props.node.expanded?"minus":"plus"} />
				</div>	 */}
				{editor}
			</div>
		} else {
			category =
			<div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center", padding: "2px", fontWeight: "bold", color: "#bcbcbc", cursor: "pointer"}} onClick={this.handleSelectNodeAtPath.bind(this)}>
				{/* <div onClick={this.handleToggleNode.bind(this)}>
					<Glyphicon style={{color: "#D78770", marginRight: "10px"}} glyph={this.props.node.expanded?"minus":"plus"} />
				</div>	 */}
				{editor}
			</div>
		}

		return (
			<div>
				<div>
					{category}
				</div>
				<div style={style}>
					{nodes}
				</div>
			</div>
		);
		}
	}
}


CardCategory.contextTypes = {
	tree: PropTypes.baobab
};

export default CardCategory;
