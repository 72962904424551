import React from 'react';
import Alerts from '../components/app-alerts.js';

import Flexbox from 'flexbox-react';

import MediaQuery from 'react-responsive';

//import shallowCompare from 'react-addons-shallow-compare';

import _ from 'lodash';

import FontAwesome from 'react-fontawesome';

import {Button} from 'react-bootstrap';

import {Timeline, TimelineEvent} from 'react-event-timeline';
import marked from 'marked';

import moment from 'moment';

import GoogleMapsContainer from './app-google-maps-container';
import GoogleMapReactContainer from "./app-google-map-react-container";

import Modal from 'react-responsive-modal';

import ProfileView from './app-profile-view';

class ChecklistHistoryDetail extends React.PureComponent{
	constructor(props, context) {
		super(props, context);

		this.state = {
			mapsUrl: "",
			googleMapModalVisible: false,
			profileViewModalVisible: false,
			gpsRoute: [],
			loadCoordsFinished: false
		}
	}

	createMarkup(label) {
		var myRenderer = new marked.Renderer();
		myRenderer.link = function(href, title, text) {
			var external, newWindow, out;
			external = /^https?:\/\/.+$/.test(href);
			newWindow = external || title === 'newWindow';
			out = "<a href=\"" + href + "\"";
			if (newWindow) {
				out += ' target="_blank"';
			}
			if (title && title !== 'newWindow') {
				out += " title=\"" + title + "\"";
			}
			return out += ">" + text + "</a>";
		};

		myRenderer.image = function(href, title, text) {
			return "<img style=\"max-width: 100%\" src=\"" + href + "\"  /><br/><div>" + text + "</div>";
		};

		// myRenderer.paragraph = function(text) {
		// 	return text;
		// };

		marked.setOptions({
			renderer: myRenderer,
			gfm: true,
			tables: true,
			breaks: true,
			pedantic: false,
			sanitize: false,
			smartLists: true,
			smartypants: false
		});
	
 		return {__html: marked(label)};
	}
/*
	shouldComponentUpdate(nextProps, nextState) {
		return shallowCompare(this, nextProps, nextState);
	}
*/

	buildMapsUrl(checklist) {
		if (!checklist.hasOwnProperty("historyGeolocation") || checklist.historyGeolocation.length === 0) {
			return "";
		} 

		var coordsStr = "";

		var modNumber = checklist.historyGeolocation.length/300;
		modNumber = modNumber.toFixed(0);
		if (checklist.historyGeolocation) {
			for (var i=0; i<checklist.historyGeolocation.length; i++) {
				// Filter first 5 out
				if (i > 5) {
					if (checklist.historyGeolocation.length <= 350) {
						var coords = checklist.historyGeolocation[i].coords;
						coordsStr = coordsStr + coords.latitude.toFixed(6) + "," + coords.longitude.toFixed(6) + "|";
					} else {
						if (i%modNumber===0) {
							var coords = checklist.historyGeolocation[i].coords;
							coordsStr = coordsStr + coords.latitude.toFixed(6) + "," + coords.longitude.toFixed(6) + "|";
						}
					}
				}
			}

			coordsStr = coordsStr.slice(0,-1);

			return "http://maps.googleapis.com/maps/api/staticmap?key=AIzaSyB8F8WiJBwuSPTdj0BDZT-mHbhupgP-IHk&size=800x800&path=" + coordsStr + "&sensor=false";
		} else {
			return "";
		}
	}

	getMapCoords(checklist) {
		if (!checklist.hasOwnProperty("historyGeolocation") || checklist.historyGeolocation.length === 0) {
			return [];
		} 

		var arrCoords = [];

		if (checklist.historyGeolocation) {
			for (var i=0; i<checklist.historyGeolocation.length; i++) {
				// Filter first 5 out
				if (i > 5) {
					var coords = checklist.historyGeolocation[i].coords;
					arrCoords.push({lat: Number(coords.latitude.toFixed(6)), lng: Number(coords.longitude.toFixed(6))});
				}
			}

			return arrCoords;
		} else {
			return [];
		}
	}

	componentDidMount() {
		//this.setState({mapsUrl: this.buildMapsUrl(this.props.checklist)});
		this.setState({gpsRoute: this.getMapCoords(this.props.checklist), loadCoordsFinished: true});
	}

	render() {	
		var self = this;

		var checklist = this.props.checklist;

		var items = checklist.history.map(function (item, i) {
				var timestamp = item.timestamp;

				var newTimestamp = moment.utc(timestamp);

				var className = "fa fa-plus-circle";
				var title = item.type + " - " + item.action;
				var content;

				if (item.type === "checklist") {
					if (item.action === "open") {
						className = "fa fa-folder-open";
						title = "Opened Checklist " + checklist.name;
					}
				} else if (item.type === "item") {
					if (item.action === "check") {
						className = "fa fa-check-square-o";
						var itemParts = item.path.split(",");
						var newList = checklist.children[itemParts[0]];
						var newSection = newList.children[itemParts[1]];
						var newItem = newSection.children[itemParts[2]];
						title = "Checked Item in " + newList.name + " / " +  newSection.name;
						content = 
							<div>
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.label1)} />
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.label2)} />
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.hasOwnProperty("label3") ? newItem.label3 : "")} />
							</div>;
					} else if (item.action === "uncheck") {
						className = "fa fa-square-o";
						var itemParts = item.path.split(",");
						var newList = checklist.children[itemParts[0]];
						var newSection = newList.children[itemParts[1]];
						var newItem = newSection.children[itemParts[2]];
						title = "Unchecked Item in " + newList.name + " / " +  newSection.name;
						content = 
							<div>
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.label1)} />
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.label2)} />
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.hasOwnProperty("label3") ? newItem.label3 : "")} />
							</div>;
					} else if (item.action === "skip") {
						className = "fa fa-share-square-o";
						var itemParts = item.path.split(",");
						var newList = checklist.children[itemParts[0]];
						var newSection = newList.children[itemParts[1]];
						var newItem = newSection.children[itemParts[2]];
						title = "Skipped Item in " + newList.name + " / " +  newSection.name;
						content = 
							<div>
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.label1)} />
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.label2)} />
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.hasOwnProperty("label3") ? newItem.label3 : "")} />
							</div>;
					} else if (item.action === "setNotes") {
						className = "fa fa-share-square-o";
						var itemParts = item.path.split(",");
						var newList = checklist.children[itemParts[0]];
						var newSection = newList.children[itemParts[1]];
						var newItem = newSection.children[itemParts[2]];
						title = "Set notes for Item in " + newList.name + " / " +  newSection.name;
						content = 
							<div>
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.label1)} />
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.label2)} />
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.hasOwnProperty("label3") ? newItem.label3 : "")} />
								<br/>
								<div style={{padding: "10px", backgroundColor: "#a0a0a0", borderRadius: 5}}>
									<b>Notes:</b><br/>
									{item.note}
								</div>	
							</div>;
					} else if (item.action === "setDate") {
						className = "fa fa-share-square-o";
						var itemParts = item.path.split(",");
						var newList = checklist.children[itemParts[0]];
						var newSection = newList.children[itemParts[1]];
						var newItem = newSection.children[itemParts[2]];
						title = "Set date for Item in " + newList.name + " / " +  newSection.name;

						var options = { year: 'numeric', month: 'long', day: 'numeric' };
						var date;
						var dateString;
						//date = new Date(item.value.split("T")[0] + "T" + item.value.split(" ")[1]);
						//dateString = date.toLocaleDateString('en-US', options) + "; " + date.toLocaleTimeString('en-US');
						dateString = item.value;
						content = 
							<div>
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.label1)} />
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.label2)} />
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.hasOwnProperty("label3") ? newItem.label3 : "")} />
								<br/>
								<div style={{padding: "10px", backgroundColor: "#a0a0a0", borderRadius: 5}}>
									<b>Date:</b><br/>
									{dateString}
								</div>	
							</div>;
					} else if (item.action === "setWeather") {
						className = "fa fa-share-square-o";
						var itemParts = item.path.split(",");
						var newList = checklist.children[itemParts[0]];
						var newSection = newList.children[itemParts[1]];
						var newItem = newSection.children[itemParts[2]];
						title = "Set weather for Item in " + newList.name + " / " +  newSection.name;

						var value = item.value;

						if (item.value !== null && item.value.currently && item.value.kpIndex) {
							var kpIndexArray = value.kpIndex;
							var kpIndexLastArray = kpIndexArray[kpIndexArray.length-1];
							var kpIndex = kpIndexLastArray[1];
	
							content = 
								<div>
									<div dangerouslySetInnerHTML={self.createMarkup(newItem.label1)} />
									<div dangerouslySetInnerHTML={self.createMarkup(newItem.label2)} />
									<div dangerouslySetInnerHTML={self.createMarkup(newItem.hasOwnProperty("label3") ? newItem.label3 : "")} />
									<br/>
									<div style={{padding: "10px", backgroundColor: "#a0a0a0", borderRadius: 5}}>
										<b>Weather:</b> <br/>
										Summary: {value.currently.summary}<br/>
										Forecast: {value.minutely.summary} {value.hourly.summary}<br/>
										Temperature: {(Math.round(10 * value.currently.temperature)/10) + ' °F'}<br/>
										Dew Point: {(Math.round(10 * value.currently.dewPoint)/10) + ' °F'}<br/>
										Humidity: {Math.round(value.currently.humidity * 1000)/10}%<br/>
										Precipitation Type: {(!value.currently.hasOwnProperty("precipType"))?"None":value.currently.precipType}<br/>
										Precipitation Probability: {Math.round(value.currently.precipProbability * 1000)/10}<br/>
										Cloud Cover: {Math.round(value.currently.cloudCover * 1000)/10}%<br/>
										Wind Direction: {value.currently.windBearing}<br/>
										Wind Speed: {value.currently.windSpeed}<br/>
										Wind Gust: {value.currently.windGust}<br/>
										Visibility: {value.currently.visibility}<br/>
										KP-Index: {kpIndex}
									</div>	
								</div>;
	
						} else {
							content = 
								<div>
									<div dangerouslySetInnerHTML={self.createMarkup(newItem.label1)} />
									<div dangerouslySetInnerHTML={self.createMarkup(newItem.label2)} />
									<div dangerouslySetInnerHTML={self.createMarkup(newItem.hasOwnProperty("label3") ? newItem.label3 : "")} />
									<br/>
									<div style={{padding: "10px", backgroundColor: "#a0a0a0", borderRadius: 5}}>
										<b>Weather:</b> <br/>
										No Weather Data
									</div>	
								</div>;

						}

					} else if (item.action === "setTextInput") {

						className = "fa fa-share-square-o";
						var itemParts = item.path.split(",");
						var newList = checklist.children[itemParts[0]];
						var newSection = newList.children[itemParts[1]];
						var newItem = newSection.children[itemParts[2]];
						title = "Set text input for Item in " + newList.name + " / " +  newSection.name;

						content = 
							<div>
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.label1)} />
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.label2)} />
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.hasOwnProperty("label3") ? newItem.label3 : "")} />
								<br/>
								<div style={{padding: "10px", backgroundColor: "#a0a0a0", borderRadius: 5}}>
									<b>Text Input:</b><br/>
									{item.value}
								</div>	
							</div>;
					} else if (item.action === "setLocation") {
						className = "fa fa-share-square-o";
						var itemParts = item.path.split(",");
						var newList = checklist.children[itemParts[0]];
						var newSection = newList.children[itemParts[1]];
						var newItem = newSection.children[itemParts[2]];
						title = "Set location for Item in " + newList.name + " / " +  newSection.name;

						var value;
						if (item.value.hasOwnProperty("address")) {
							value = item.value.address;
						} else if (item.value.split(";").length === 2) {
							value = "Latitude: " + item.value.split(";")[0] + "\nLongitude: " + item.value.split(";")[1].substring(1);
						} else {
							value = item.value;
							//value = "Latitude: " + item.value.latitude + "\nLongitude: " + item.value.longitude;
						}

						content = 
							<div>
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.label1)} />
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.label2)} />
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.hasOwnProperty("label3") ? newItem.label3 : "")} />
								<br/>
								<div style={{padding: "10px", backgroundColor: "#a0a0a0", borderRadius: 5}}>
									<b>Location:</b><br/>
									{value}
								</div>	
							</div>;
					} else if (item.action === "setPicker") {

						className = "fa fa-share-square-o";
						var itemParts = item.path.split(",");
						var newList = checklist.children[itemParts[0]];
						var newSection = newList.children[itemParts[1]];
						var newItem = newSection.children[itemParts[2]];
						title = "Set picker for Item in " + newList.name + " / " +  newSection.name;

						content = 
							<div>
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.label1)} />
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.label2)} />
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.hasOwnProperty("label3") ? newItem.label3 : "")} />
								<br/>
								<div style={{padding: "10px", backgroundColor: "#a0a0a0", borderRadius: 5}}>
									<b>Picker:</b><br/>
									{item.value}
								</div>	
							</div>;
					} else if (item.action === "setYesNo") {

						className = "fa fa-share-square-o";
						var itemParts = item.path.split(",");
						var newList = checklist.children[itemParts[0]];
						var newSection = newList.children[itemParts[1]];
						var newItem = newSection.children[itemParts[2]];
						title = "Set yes/no for Item in " + newList.name + " / " +  newSection.name;

						content = 
							<div>
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.label1)} />
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.label2)} />
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.hasOwnProperty("label3") ? newItem.label3 : "")} />
								<br/>
								<div style={{padding: "10px", backgroundColor: '#a0a0a0', borderRadius: 5}}>
									<b>Yes / No:</b><br/>
									{item.value}
								</div>	
							</div>;
					} else if (item.action === "setBarcode") {
						className = "fa fa-share-square-o";
						var itemParts = item.path.split(",");
						var newList = checklist.children[itemParts[0]];
						var newSection = newList.children[itemParts[1]];
						var newItem = newSection.children[itemParts[2]];
						title = "Set barcode for Item in " + newList.name + " / " +  newSection.name;

						content = 
							<div>
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.label1)} />
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.label2)} />
								<div dangerouslySetInnerHTML={self.createMarkup(newItem.hasOwnProperty("label3") ? newItem.label3 : "")} />
								<br/>
								<div style={{padding: "10px", backgroundColor: "#a0a0a0", borderRadius: 5}}>
									<b>Barcode:</b><br/>
									{(item.hasOwnProperty("value") && item.value.hasOwnProperty("data") && item.value.data != "")?item.value.data:"(No Data)"}
								</div>	
							</div>;
					} else if (item.action === "clearBarcode") {
						className = "fa fa-trash";
						var itemParts = item.path.split(",");
						var newList = checklist.children[itemParts[0]];
						var newSection = newList.children[itemParts[1]];
						var newItem = newSection.children[itemParts[2]];
						title = "Removed barcode for Item in " + newList.name + " / " +  newSection.name;
					} else if (item.action === "setImagePicker") {
						className = "fa fa-share-square-o";
						var itemParts = item.path.split(",");
						var newList = checklist.children[itemParts[0]];
						var newSection = newList.children[itemParts[1]];
						var newItem = newSection.children[itemParts[2]];
						title = "Set image picker for Item in " + newList.name + " / " +  newSection.name;

						var imgUrlParts = item.value.split(".");
						var ext = imgUrlParts[imgUrlParts.length-1];
						var imgUrl;
						if (ext === "mp4") {
							imgUrl = "<p><b>Video Thumbnail</b><br/><img style='width: 160px; height: 160px' src='" + item.value.replace(".mp4","-00001.png") + "' /></p>";
						} else {
							imgUrl = "<p><b>Photo</b><br/><img style='width: 50%' src='" + item.value + "' /></p>";
						}

						content =
						<div style={{display: "flex", alignItems: "center", width: "100%"}}>
							<div style={{width: "100%", overflowX: "auto"}}>
								<div dangerouslySetInnerHTML={self.createMarkup("<b>" + newItem.label1 + "</b>")} />
								<div dangerouslySetInnerHTML={self.createMarkup("<b>" + newItem.label2 + "</b>")} />
								<div dangerouslySetInnerHTML={self.createMarkup("<b>" + (newItem.hasOwnProperty("label3") ? newItem.label3 : "") + "</b>")} />
								<div dangerouslySetInnerHTML={self.createMarkup(imgUrl)} />
							</div>	
						</div>;
					} else if (item.action === "setSketchPad") {
						className = "fa fa-share-square-o";
						var itemParts = item.path.split(",");
						var newList = checklist.children[itemParts[0]];
						var newSection = newList.children[itemParts[1]];
						var newItem = newSection.children[itemParts[2]];
						title = "Set sketch pad for Item in " + newList.name + " / " +  newSection.name;

						var imgUrl = "<p><b>Sketch</b><br/><img style='width: 30%; border: 1px solid #eeeeee' src='" + item.value + "' /></p>";

						content =
						<div style={{display: "flex", alignItems: "center", width: "100%"}}>
							<div style={{width: "100%", overflowX: "auto"}}>
								<div dangerouslySetInnerHTML={self.createMarkup("<b>" + newItem.label1 + "</b>")} />
								<div dangerouslySetInnerHTML={self.createMarkup("<b>" + newItem.label2 + "</b>")} />
								<div dangerouslySetInnerHTML={self.createMarkup("<b>" + (newItem.hasOwnProperty("label3") ? newItem.label3 : "") + "</b>")} />
								<div dangerouslySetInnerHTML={self.createMarkup(imgUrl)} />
							</div>	
						</div>;
					}
				} else if (item.type === "section") {
					if (item.action === "check") {
						className = "fa fa-check-square-o";
						var itemParts = item.path.split(",");
						var newList = checklist.children[itemParts[0]];
						var newSection = newList.children[itemParts[1]];
						title = "Checked Section " + newList.name + " / " +  newSection.name;
					} else if (item.action === "reset") {
						className = "fa fa-eraser";
						var itemParts = item.path.split(",");
						var newList = checklist.children[itemParts[0]];
						var newSection = newList.children[itemParts[1]];
						title = "Reset Section " + newList.name + " / " +  newSection.name;
					}
				} else if (item.type === "list") {
					if (item.action === "reset") {
						className = "fa fa-eraser";
						var newList = checklist.children[item.path];
						title = "Reset List " + newList.name;
					}
				}

				return (
					<TimelineEvent style={{color: "black", fontSize: 12}} title={title} titleStyle={{color: "black", fontSize: 12, fontWeight: "bold"}} createdAt={newTimestamp.format()} icon={<i style={{color: "black", fontSize: 16}} className={className} />}>
						{content}
					</TimelineEvent>
				);
		});

		let showGoogleMapModal = 
		<Modal classNames={{ modal: "custom-modal" }} open={this.state.googleMapModalVisible} onClose={() => this.setState({googleMapModalVisible: false})} little>
			<h3 style={{ textAlign: "center" }}>Route</h3>
			<div style={{ height: "70vh", overflow: "auto", flex: "display", flex: 1, alignItems: "center", justifyContent: "center" }}>
				No Map Data
			</div>
		</Modal>;

		var gpsIndex = 0;
		if (this.state.gpsRoute.length > 1) {
			gpsIndex = Math.round(this.state.gpsRoute.length/2);
		}

		if (this.state.loadCoordsFinished && this.state.gpsRoute.length > 0) {
			showGoogleMapModal =
			<Modal classNames={{ modal: "custom-modal" }} open={this.state.googleMapModalVisible} onClose={() => this.setState({googleMapModalVisible: false})} little>
				<h3 style={{ textAlign: "center" }}>Route</h3>
				<div style={{ height: "70vh", overflow: "auto" }}>
					<GoogleMapsContainer initialCenter={{lat: this.state.gpsRoute[gpsIndex].lat, lng: this.state.gpsRoute[gpsIndex].lng}} route={this.state.gpsRoute} />
				</div>
			</Modal>;
		}

		let showProfileViewModal = 
		<Modal classNames={{ modal: "custom-modal-profile-view" }} open={this.state.profileViewModalVisible} onClose={() => this.setState({profileViewModalVisible: false})} little>
			<h3 style={{ textAlign: "center" }}>Profile View</h3>
			<div style={{ height: "70vh", overflow: "auto", flex: "display", flex: 1, alignItems: "center", justifyContent: "center" }}>
				No Altimeter Data
			</div>
		</Modal>;

		if (this.state.loadCoordsFinished && this.state.gpsRoute.length > 0) {
			showProfileViewModal =
			<Modal classNames={{ modal: "custom-modal-profile-view" }} open={this.state.profileViewModalVisible} onClose={() => this.setState({profileViewModalVisible: false})} little>
				<h3 style={{ textAlign: "center" }}>Profile View</h3>
				<div style={{ backgroundColor: "white", height: "70vh", overflow: "auto" }}>
					<ProfileView historyGeolocation={this.props.checklist.historyGeolocation} />
				</div>
			</Modal>;
		}

		if (!this.state.loadCoordsFinished) {
			return (
				<div>Loading...</div>
			);
		} else {
			return (
				<div>
					<div style={{display: "flex", flexDirection: "row"}}>
						{showGoogleMapModal}
						{showProfileViewModal}
					</div>
					{/* <GoogleMapsContainer/> */}
					{/* <GoogleMapReactContainer/> */}
				{/* {this.state.mapsUrl !== "" && <div> <a href={this.state.mapsUrl} target="_blank"><FontAwesome fixedWidth={true} name="location-arrow"/> Show GPS Track</a><hr/></div>} */}
				
				<Button style={{marginBottom: 10, marginRight: 10}} onClick={() => {
	
					this.setState({googleMapModalVisible: true});
				}
				}>Show GPS Track</Button>
				<Button style={{marginBottom: 10}} onClick={() => {
					
					this.setState({profileViewModalVisible: true});
				}
				}>Show Profile View</Button>
				<Timeline>
					{items}
				</Timeline>
				</div>
			);
	
		}

	}
}

export default ChecklistHistoryDetail;