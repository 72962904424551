import React from 'react';

//import shallowCompare from 'react-addons-shallow-compare';

class Icon extends React.PureComponent{
	/*
	shouldComponentUpdate(nextProps, nextState) {
		return shallowCompare(this, nextProps, nextState);
	}
*/
	render() {
		return (
			<span style={this.props.style} className={"fa fa-" + this.props.glyph} />
		);
	}
}

export default Icon;