import React from 'react';
import {branch} from 'baobab-react/higher-order';
import PropTypes from 'baobab-react/prop-types';

import {Checkbox, Button, Panel, FormControl} from 'react-bootstrap';

import AfPanel from '../components/shared/AfPanel';
import AfHelp from '../components/shared/AfHelp';
import { uploadAiFile } from '../actions/portalActions';
import { assistantsFilesCreate, assistantsFilesDelete, filesDelete, getContent, getContentStreamed } from '../ai/aiAssistant';
import { createMarkupForLabel } from '../utils/utils';

import * as smd from "../../node_modules/streaming-markdown/smd.js";

let parser;

class AiAssistant extends React.PureComponent{
	constructor(props, context) {
		super(props, context);

		this.state = {
			inputAiMessage: "",
			threadId: ""
		};
	}

	componentDidUpdate() {
		var messageBody = document.getElementById("openai-markdown2");
		messageBody.scrollTop = 10000;		

		console.log("SCROLL", messageBody.scrollTop);
	}

	async componentDidMount() {
        const element  = document.getElementById("openai-markdown");
        const renderer = smd.default_renderer(element);
        parser   = smd.parser(renderer);

		// let headers = new Headers();

		// headers.append('Authorization', 'Basic amVmZi5ib25hc3NvQG1pcmFsb3VhZXJvLmNvbTpTaXhGb3VyNzJHb2xm');
		
		const response = await fetch('https://api.ambifi.com/services/openai/files', {
			method: 'GET',
			// headers: headers
		});
		const jsonResponse = await response.json();

		const files = jsonResponse.data.filter((file) => {
			return file.filename.startsWith(this.props.user.identityId + "/");
		});

		this.context.tree.set(["tree", "root", "entity", "aiAssistantFiles"], files);

		if (this.props.aiAssistantId && this.props.assistantId !== "") {
			const responseChecklistFiles = await fetch(`https://api.ambifi.com/services/openai/assistants/${this.props.aiAssistantId}/files`, {
				method: 'GET',
				// headers: headers
			});
			const jsonResponseChecklistFiles = await responseChecklistFiles.json();
			const checklistFiles = jsonResponseChecklistFiles.data.filter((file) => {
				return file;
			});
	
			this.context.tree.set(["tree", "root", "entity", "aiAssistantChecklistFiles"], checklistFiles);	

			// Create thread - probably need a way to clear everything too
			const responseCurrentThread = await fetch(`https://api.ambifi.com/services/openai/threads`, {
				method: 'POST',
				// headers: headers
			});
			const jsonResponseCurrentThread = await responseCurrentThread.json();
	
			this.setState({
				threadId: jsonResponseCurrentThread.id
			})
		}
	}

	componentDidUpdate(prevProps, prevState) {
	}

	renderFiles() {
		let fileRows = this.props.files.map((item, idx) => {
			// Need to determine if checked based on a parallel data structure looking at files enabled in this assistant
			const checklistFiles = this.props.checklistFiles;

			let fileChecked = false;
			if (checklistFiles.find((newItem) => {
				return newItem.id === item.id
			})) {
				fileChecked = true;
			}

			return  <tr style={{borderStyle: "none"}} key={item.id}>
				<td style={{borderStyle: "none"}}>
					<Checkbox checked={fileChecked} onChange={(e) => {
						if (e.target.checked) {
							// Add to Assistant Files
							assistantsFilesCreate(this.props.aiAssistantId, item.id);
						} else {
							// Remove from Assistant Files
							assistantsFilesDelete(this.props.aiAssistantId, item.id);
						}
					}}>
						{item.filename.replace(this.props.user.identityId + "/","")}
					</Checkbox>
				</td>
				<td style={{borderStyle: "none"}}>
					{new Date(item.created_at*1000).toLocaleString()}
				</td>
				<td style={{borderStyle: "none"}}>
						<div style={{display: "flex"}}>
						{/* <Button style={{color: "white", backgroundColor: "#0E7AFE"}} onClick={() => {
							// getContent(this.props.aiAssistantId, item.id);

							getContentStreamed(smd, parser, item.id, this.props.aiAssistantId, );
						}}>
							Get Content
						</Button>	 */}
						<Button style={{color: "white", backgroundColor: "red"}} onClick={() => {
							filesDelete(item.id);
						}}>
							Delete
						</Button>	
					</div>
				</td>
			</tr>;
		});

		if (fileRows.length === 0) {
			return <div><i>You currently do not have any files uploaded.</i></div>
		} else {
			const files = <table style={{borderStyle: "none"}}>
				<thead>
					<tr style={{borderStyle: "none"}}>
						<th style={{borderStyle: "none"}} scope="col">Filename</th>
						<th style={{borderStyle: "none"}} scope="col">Created At</th>
						<th style={{borderStyle: "none"}} scope="col">Actions</th>
					</tr>
				</thead>
				<tbody>
					{fileRows}
				</tbody>
			</table>;

			return <div>
				<div><i>Enable any files below that you want to share with your AI Assistant</i></div>
				{files}
			</div>
		}
	}

	render() {
		var self=this;

		var files = this.props.files;

		this.renderFiles();

		return (
			<div style={{width: "100%", height: "100%"}}>
				<div style={{ display: "flex", flexDirection: "column" }}>
					{this.renderFiles()}

					<hr />

					<div id="openai-markdown"></div>

					<div id="openai-markdown2" style={{maxHeight: "50vh", overflow: "auto"}} dangerouslySetInnerHTML={createMarkupForLabel(this.props.aiAssistantResponse)} />

					<div style={{display: "flex", flexDirection: "column"}}>
						<input ref="inputAiMessage" style={{marginTop: "15px", marginBottom: "15px"}} className="form-control" type="text" placeholder="Enter your message..." value={this.state.inputAiMessage} onChange={(e) => {
							this.setState({
								inputAiMessage: e.target.value
							})
						}} />
						<Button disabled={this.state.inputAiMessage === ""} style={{color: "white", backgroundColor: "#0E7AFE", maxWidth: "100px"}} onClick={() => {
							// getContent(this.props.aiAssistantId, item.id);

							let newData = this.context.tree.get(["appState", "aiAssistantResponse"]);
							newData = newData + `\n\n---\n\n**You:** ${this.state.inputAiMessage}\n\n---\n\n**Assistant:**\n\n`;

							this.context.tree.set(["appState", "aiAssistantResponse"], newData);    
				
							getContentStreamed(smd, parser, null, this.props.aiAssistantId, this.state.threadId, this.state.inputAiMessage);
						}}>
							Run
						</Button>	

					</div>

					<div>
						<div style={{marginTop: "20px"}}>
							<label>Upload File</label>
						</div>
						<div>
							<input type="file" /*accept=".*" */onChange={async (event)=> {
								console.log("Selected file:", event.target.files[0]);
								try {
									await uploadAiFile("ai.miralouaero.com", "openai/files/" + this.props.user.identityId,  event.target.files[0]);
								} catch (e) {
									console.log(e);
								}
							}}/>
						</div>
					</div>					
				</div>
			</div>
		);
	}
}

AiAssistant.contextTypes = {
	tree: PropTypes.baobab
};

export default branch({
	user: ['user'],
	content: ['content','activity','properties','properties'],
	aiAssistantId: ['tree', 'root', 'entity', 'aiAssistantId'],
	aiAssistantResponse: ["appState", "aiAssistantResponse"]
	// newFiles: ['tree', 'root', 'entity', 'aiAssistantFiles']
}, AiAssistant);