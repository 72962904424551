import React from 'react';
import { branch } from 'baobab-react/higher-order';
import * as actions from '../../actions/actions';
import * as actionsPortal from '../../actions/portalActions';
import * as utils from '../../utils/utils';
import { Nav, Navbar, NavItem, Button, DropdownButton, MenuItem, Glyphicon } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';

import browserHistory from '../../history';

import Flexbox from 'flexbox-react';

import MediaQuery from 'react-responsive';
import { Right } from 'react-bootstrap/lib/Media';

//import shallowCompare from 'react-addons-shallow-compare';

class NavLinks extends React.PureComponent {
    /*
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }
*/
    home() {
        this.props.dispatch(
            actions.setNavPanelSelected,
            "home"
        );
    }

    search() {
        this.props.dispatch(
            actions.setNavPanelSelected,
            "search"
        );
    }

    myChecklists() {
        this.props.dispatch(
            actions.setNavPanelSelected,
            "myChecklists"
        );
    }

    timers() {
        this.props.dispatch(
            actions.setNavPanelSelected,
            "timers"
        );
    }

    history() {
        this.props.dispatch(
            actions.setNavPanelSelected,
            "history"
        );
    }

    checkMateImport() {
        this.props.dispatch(
            actions.setNavPanelSelected,
            "checkMateImport"
        );
    }
    // afmPoh() {
    //     this.props.dispatch(
    //         actions.setNavPanelSelected,
    //         "afmpoh"
    //     );
    // }
    // flightSchools() {
    //     this.props.dispatch(
    //         actions.setNavPanelSelected,
    //         "flightSchools"
    //     );
    // }

    pop() {
        alert("POP");
        actions.showSuccess("Some Title", "This is for success!");
        actions.showWarning("Warning Title", "Something is dangerous");
        actions.showError("Darn", "Something did go wrong");
    }

    logout() {
        actionsPortal.logout(this.props.user.username);
    }

    render() {
        var self = this;

        var reorder;
        if (this.props.showReorder) {
            reorder = <NavItem onClick={this.props.onReorder}>Organize</NavItem>;
        }

        var title = <span><i className="fa fa-user"></i> {this.props.user.username}</span>;


        var profileButton;
        if (utils.isMobile()) {
            profileButton =
                <DropdownButton style={{ marginTop: "8px", marginLeft: "10px" }} title={title} key="dropdown-user" id="dropdown-user">
                    <MenuItem eventKey="1" onClick={this.logout.bind(this)}>Logout</MenuItem>
                </DropdownButton>;
        } else if (!this.props.igapp) {
            profileButton =
                <DropdownButton style={{ marginTop: "8px", marginLeft: "10px" }} title={title} key="dropdown-user" id="dropdown-user">
                    {/* /TODO: <MenuItem eventKey="1" onClick={}>My Profile</MenuItem>
                <MenuItem divider /> */}
                    <MenuItem eventKey="1" onClick={this.logout.bind(this)}>Logout</MenuItem>
                    <MenuItem divider />
                    <MenuItem eventKey="2" onClick={(e) => { self.props.dispatch(actions.setLastLocation, window.location); browserHistory.push("/subscribe"); }}>Subscription</MenuItem>
                    <MenuItem divider />
                    <MenuItem eventKey="3" onClick={(e) => { self.props.dispatch(actions.setLastLocation, window.location); browserHistory.push("/updateCustomerCard"); }}>Update Card Info</MenuItem>
                </DropdownButton>;
        } else if (this.props.igapp) {
            profileButton =
                <DropdownButton style={{ marginTop: "8px", marginLeft: "10px" }} title={title} key="dropdown-user" id="dropdown-user">
                    <MenuItem eventKey="1" onClick={this.logout.bind(this)}>Logout</MenuItem>
                </DropdownButton>;
        }

        return (
            <div style={{ marginBottom: "-20px" }}>
                <Navbar fluid>
                    <Navbar.Header>
                        <Navbar.Brand>
                            <Flexbox alignItems="center">
                                <img width="32" height="32" src="/assets/goose-g-logo-300x300.png" />
                                <div style={{ marginLeft: "10px", fontWeight: "normal", fontSize: "24px", color: "#fdfdfd" }}>Goose Cloud</div>
                                {/* <a style={{marginLeft: "10px", fontWeight: "normal", fontSize: "24px"}} href="#">Goose Cloud (formerly MiraCheck)</a> */}
                            </Flexbox>
                        </Navbar.Brand>
                        <Navbar.Toggle />
                    </Navbar.Header>
                    
                    <Navbar.Collapse>
                        <MediaQuery query='(max-width: 767px)'>
                        
                            <Nav pullRight>
                                <NavItem onClick={this.home.bind(this)}>Home</NavItem>
                                <NavItem onClick={this.search.bind(this)}>Search</NavItem>
                                <NavItem onClick={this.myChecklists.bind(this)}>My Hangar</NavItem>
                                <NavItem onClick={this.timers.bind(this)}>Timers</NavItem>
                                <NavItem onClick={this.history.bind(this)}>History</NavItem>
                                {/* <NavItem onClick={this.afmPoh.bind(this)}>AFM & POH</NavItem>
                                <NavItem onClick={this.flightSchools.bind(this)}>Flight Schools</NavItem> */}
                                {/* {this.props.appCapabilitiesTimers && this.props.capabilitiesTimers && this.props.user.hasOwnProperty("subscriptionPlan") && this.props.user.subscriptionPlan.startsWith("pro-plan") && <NavItem onClick={this.timers.bind(this)}>Timers</NavItem>} */}
                                {/* <NavItem onClick={this.checkMateImport.bind(this)}>Import</NavItem> */}
                                {/* {reorder} */}

                                {!this.props.igapp && <div style={{ display: "flex", alignItems: "center", padding: "10px" }}>
                                    <FontAwesome fixedWidth={true} style={{ color: "#0E7AFE", fontWeight: "bold", fontSize: "24px" }} name="question-circle" />&nbsp;&nbsp;<a onClick={(e) => utils.openUrl(e, "https://forum.miralouaero.com")} href="javascript:void(0)">Support</a>
                                </div>}
                                {/* <NavItem href="https://forum.miralouaero.com" target="_blank"><Button><FontAwesome fixedWidth={true} style={{fontWeight: "bold", color: "#0E7AFE", fontSize: "16px"}} name="question-circle" />Support</Button></NavItem> */}

                                <NavItem>
                                    {profileButton}
                                </NavItem>
                            </Nav>
                        </MediaQuery>
                        <MediaQuery query='(min-width: 768px)'>
                            <Nav pullRight>
                                {reorder}
                                <li>
                                    {profileButton}
                                </li>
                                {/* <NavItem href="https://forum.miralouaero.com" target="_blank"><Button><FontAwesome fixedWidth={true} style={{fontWeight: "bold", color: "green", fontSize: "16px"}} name="question-circle" /></Button></NavItem> */}
                            </Nav>
                        </MediaQuery>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    }
}

export default branch({
    user: ['user'],
    capabilitiesTimers: ['appCapabilities', 'timers'],
    igapp: ['appCapabilities', 'igapp']
}, NavLinks);