import React from 'react';
import {branch} from 'baobab-react/higher-order';
import PropTypes from 'baobab-react/prop-types';

import EditorCategory from '../components/app-editor-category.js';
import EditorList from '../components/app-editor-list.js';
import EditorSection from '../components/app-editor-section.js';
import EditorItem from '../components/app-editor-item.js';

import * as utils from '../utils/utils.js';

//import shallowCompare from 'react-addons-shallow-compare';

import {HotKeys} from 'react-hotkeys';

const keyMap = {
	'moveUp': ['alt+up'],
	'moveDown': ['alt+down'],
	'cut': ['ctrl+x','command+x'],
	'copy': ['ctrl+c','command+c'],
	'paste': ['ctrl+v','command+v'],
	'undo': ['ctrl+z','command+z'],
	'redo': ['ctrl+y','command+shift+z','command+y'],
	'removeNode': ['fn+del']
}

class Editor extends React.PureComponent{
	/*
	shouldComponentUpdate(nextProps, nextState) {
		var should = shallowCompare(this, nextProps, nextState);
		//console.log("Editor should: " + should);
		return should;
	}
*/
	createEditor(node) {
		var editor;
		if (node.hasOwnProperty("entity")) {
			var entity = node.entity;
			if (entity.type.startsWith("item")) {
				editor = <EditorItem node={node} />;
			} else if (entity.type == "list") {
				editor = <EditorList node={node} />;
			} else if (entity.type == "section") {
				editor = <EditorSection node={node} />;
			} else {
				editor = <EditorCategory node={node} />;
			}
		} else {
			editor = <h4>Please select a node in the tree.</h4>;
		}

		return editor;
	}

	recurseEditors(arr, arrResult) {
		for (var i=0; i<arr.length; i++) {
			arrResult.push(this.createEditor(arr[i]));
			//if( arr[i].hasOwnProperty("children")) {
			//	this.recurseEditors(arr[i].children, arrResult);
			//}
		}
	}
	createEditors(node) {
		var arrResult = [];
		//var t1 = Date.now();
		if (node.hasOwnProperty("children")) {
			this.recurseEditors(node.children,arrResult);
		}
		//var t2 = Date.now();
		//console.log("Number of editors: " + arrResult.length + " (" + Number(t2-t1) + " ms)");
		return arrResult;
	}

	render() {
		const handlers = {
			'moveUp': (event) => {},
			'moveDown': (event) => {},
			'cut': (event) => {},
			'copy': (event) => {},
			'paste': (event) => {},
			'undo': (event) => {},
			'redo': (event) => {},
			'removeNode': (event) => {}
		};

		var editor = this.createEditor(this.props.node);
		return (
			<HotKeys style={{padding: "10px", overflowY: "auto", overflowX: "hidden", width: "100%"}} keyMap={keyMap} handlers={handlers}>
				{editor}
			</HotKeys>	
		);
	}
}

Editor.contextTypes = {
	tree: PropTypes.baobab
};

// Using a function so that your cursors' path can use the component's props etc.
export default branch((props, context) => {
	var arrPath = context.tree.select('selectedNodePath').get();
	return {
		editorState: ['appState','editor'],
		node: arrPath,
		typeahead: ["typeahead"]
	};
}, Editor);
