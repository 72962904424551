import React, { PureComponent } from "react";
import { createPortal } from "react-dom";
import Modal from "react-responsive-modal";

import CKEditor from "react-ckeditor-component";

import {Button} from 'react-bootstrap';

class ModalRichTextEditor extends PureComponent {
    constructor(props, context) {
		super(props, context);
        this.state = {
            oldComments: props.comments
        };
	}

    render() {
		return createPortal(
            <Modal open={this.props.showComments} showCloseIcon={false} closeOnOverlayClick={false} closeOnEsc={false} onClose={()=>{this.props.onCloseCommentsModal()}} little>
                <CKEditor
                    content={this.props.comments}
                    //scriptUrl="https://cdn.ckeditor.com/4.8.0/full/ckeditor.js"
                    scriptUrl="https://checklist.miracheck.com/ckeditor/ckeditor.js?v=2"
                    config={{
                        dialog_noConfirmCancel: true
                    }}
                    events={{
                        "change": (e) => this.props.onChangeComments(e.editor.getData())
                    }}
                />
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Button style={{margin: "10px"}} onClick={()=>this.props.onCloseCommentsModal()}>OK</Button>
                    <Button style={{margin: "10px"}} onClick={()=>{
                        this.props.onChangeComments(this.state.oldComments);
                        this.props.onCloseCommentsModal()
                    }}>Cancel</Button>
                </div>
            </Modal>		
            , document.body);
	}
}

export default ModalRichTextEditor;