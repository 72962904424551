import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class GoogleMapReactContainer extends Component {
  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 8
  };

  render() {
    var coords = this.props.route.map(function (coord) {
      return (
        <AnyReactComponent
            lat={coord.lat}
            lng={coord.lng}
            text={'*'}
          />
      );
    });

    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyB8F8WiJBwuSPTdj0BDZT-mHbhupgP-IHk" }}
          defaultCenter={this.props.initialCenter}
          defaultZoom={this.props.zoom}
        >
          {coords}
        </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleMapReactContainer;
