import AWS from 'aws-sdk';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import {cognito} from '../constants/globals.js';
import { showError } from '../actions/actions.js';

import state from '../state/state';

let aiSocket;

export function assistantsCreate() {
	// Can I cache all this user stuff?
	var poolData = {
		UserPoolId : cognito.userPoolId, // Your user pool id here
		ClientId : cognito.clientId // Your client id here
	};
	var userPool = new CognitoUserPool(poolData);
	var cognitoUser = userPool.getCurrentUser();

	if (cognitoUser != null) {
		cognitoUser.getSession(function(err, session) {
			if (err) {
				alert(err);
				return;
			}
			console.log('session validity: ' + session.isValid());

			var logins = {};
			logins[cognito.logins] = session.getIdToken().getJwtToken();

			AWS.config.credentials = new AWS.CognitoIdentityCredentials({
				IdentityPoolId :  cognito.identityPoolId, // your identity pool id here
				Logins : logins,
				region: "us-east-1"
			});

			AWS.config.credentials.refresh(function(){
				AWS.config.credentials.get(function(err) {
					if (!err) {
                        // Let's append the checklist id to the name
                        var arrPath = state.select('selectedNodePath').get();
                        const checklistId = state.select(arrPath).get(["id"]);

						var lambda = new AWS.Lambda({region: "us-east-1", apiVersion: '2015-03-31'});
						
						var params = {
							FunctionName : "aiAssistant",
							InvocationType : 'RequestResponse',
							LogType : 'None',
							Payload : JSON.stringify({
                                eventName: "assistantsCreate",
                                params: {
                                    body: {
                                        instructions:
                                          "You are a helpful assistant.",
                                        name: "Checklist Create Assistant - " + checklistId,
                                        tools: [{ type: "code_interpreter" }, { type: "retrieval"}],
                                        model: "gpt-4-turbo",
                                      }    
                                }
                            })
						};

						lambda.invoke(params, function(error, data) {
							if (error) {
								alert(error);
							} else {
								console.log(data.Payload);
                                // We will want to set the aiAssistantId in the checklist
                                state.select(arrPath).set(["entity","aiAssistantId"],JSON.parse(data.Payload).id);
                                state.commit();
							}
						});
					} else {
						showError("Convert failed",err);
					}
				});
			});
		});
	}
}

export function assistantsDelete() {
	// Can I cache all this user stuff?
	var poolData = {
		UserPoolId : cognito.userPoolId, // Your user pool id here
		ClientId : cognito.clientId // Your client id here
	};
	var userPool = new CognitoUserPool(poolData);
	var cognitoUser = userPool.getCurrentUser();

	if (cognitoUser != null) {
		cognitoUser.getSession(function(err, session) {
			if (err) {
				alert(err);
				return;
			}
			console.log('session validity: ' + session.isValid());

			var logins = {};
			logins[cognito.logins] = session.getIdToken().getJwtToken();

			AWS.config.credentials = new AWS.CognitoIdentityCredentials({
				IdentityPoolId :  cognito.identityPoolId, // your identity pool id here
				Logins : logins,
				region: "us-east-1"
			});

			AWS.config.credentials.refresh(function(){
				AWS.config.credentials.get(function(err) {
					if (!err) {
                        var arrPath = state.select('selectedNodePath').get();
                        const asstId = state.select(arrPath).get(["entity", "aiAssistantId"]);

						var lambda = new AWS.Lambda({region: "us-east-1", apiVersion: '2015-03-31'});
						
						var params = {
							FunctionName : "aiAssistant",
							InvocationType : 'RequestResponse',
							LogType : 'None',
							Payload : JSON.stringify({
                                eventName: "assistantsDelete",
                                params: {
                                    asst_id: asstId
                                }
                            })
						};

						lambda.invoke(params, function(error, data) {
							if (error) {
								alert(error);
							} else {
								console.log(data.Payload);

                                // We will want to delete the aiAssistantId in the checklist
                                state.select(arrPath).set(["entity","aiAssistantId"],"");
                                state.commit();
							}
						});
					} else {
						showError("Convert failed",err);
					}
				});
			});
		});
	}
}

export function uploadAiFileToS3(bucket, keyPrefix, file) {
	var poolData = {
		UserPoolId : cognito.userPoolId, // Your user pool id here
		ClientId : cognito.clientId // Your client id here
	};
	var userPool = new CognitoUserPool(poolData);
	var cognitoUser = userPool.getCurrentUser();

	if (cognitoUser != null) {
		cognitoUser.getSession(function(err, session) {
			if (err) {
				showError("Failed getting session",err);
				return;
			}
			console.log('session validity: ' + session.isValid());

			var logins = {};
			logins[cognito.logins] = session.getIdToken().getJwtToken();

			AWS.config.credentials = new AWS.CognitoIdentityCredentials({
				IdentityPoolId :  cognito.identityPoolId, // your identity pool id here
				Logins : logins,
				region: "us-east-1"
			});

			AWS.config.credentials.refresh(function(){
				// Instantiate aws sdk service objects now that the credentials have been updated.
				var myBucket = new AWS.S3({useDualStack: true, params: {Bucket: bucket}});

				var params = {Key: keyPrefix + "/" + file.name, ContentType: file.type, Body: file};
				myBucket.upload(params, function (err, data) {
					var result = err ? 'ERROR!' : 'SAVED.';
					console.log(result);

					if (!err) {
						var lambda = new AWS.Lambda({region: "us-east-1", apiVersion: '2015-03-31'});
						
						var params = {
							FunctionName : "aiAssistant",
							InvocationType : 'RequestResponse',
							LogType : 'None',
							Payload : JSON.stringify({
                                eventName: "filesCreate",
                                params: {
                                    bucket: bucket,
									key: keyPrefix + "/" + file.name
                                }
                            })
						};

						lambda.invoke(params, function(error, data) {
							if (error) {
								alert(error);
							} else {
								console.log(data.Payload);

								var arrPath = state.select('selectedNodePath').get();
                                const newFiles = [...state.select(arrPath).get(["entity","aiAssistantFiles"])];
								newFiles.push(JSON.parse(data.Payload));

                                // sort new files by filename
                                newFiles.sort((a, b) => (a.filename > b.filename) ? 1 : -1)

								// Still need to add to assistants files as well
								state.select(arrPath).set(["entity","aiAssistantFiles"],newFiles);
                                state.commit();
							}
						});
					}
				});
			});
		});
	}
}

export function filesDelete(fileId) {
	// Can I cache all this user stuff?
	var poolData = {
		UserPoolId : cognito.userPoolId, // Your user pool id here
		ClientId : cognito.clientId // Your client id here
	};
	var userPool = new CognitoUserPool(poolData);
	var cognitoUser = userPool.getCurrentUser();

	if (cognitoUser != null) {
		cognitoUser.getSession(function(err, session) {
			if (err) {
				alert(err);
				return;
			}
			console.log('session validity: ' + session.isValid());

			var logins = {};
			logins[cognito.logins] = session.getIdToken().getJwtToken();

			AWS.config.credentials = new AWS.CognitoIdentityCredentials({
				IdentityPoolId :  cognito.identityPoolId, // your identity pool id here
				Logins : logins,
				region: "us-east-1"
			});

			AWS.config.credentials.refresh(function(){
				AWS.config.credentials.get(function(err) {
					if (!err) {
						var lambda = new AWS.Lambda({region: "us-east-1", apiVersion: '2015-03-31'});
						
						var params = {
							FunctionName : "aiAssistant",
							InvocationType : 'RequestResponse',
							LogType : 'None',
							Payload : JSON.stringify({
                                eventName: "filesDelete",
                                params: {
                                    file_id: fileId
                                }
                            })
						};

						lambda.invoke(params, function(error, data) {
							if (error) {
								alert(error);
							} else {
								console.log(data.Payload);

                                // Let's find this file_id in array and splice it out
                                var arrPath = state.select('selectedNodePath').get();

                                const files = state.select(arrPath).get(["entity","aiAssistantFiles"]);
                                const newFiles = [...files];
                                const newFilesFiltered = newFiles.filter((file) => {
                                    return file.id !== fileId;
                                });

                                // Sort new files by filename
                                newFilesFiltered.sort((a, b) => (a.filename > b.filename) ? 1 : -1)

                                state.select(arrPath).set(["entity","aiAssistantFiles"],newFilesFiltered);
                                state.commit();
							}
						});
					} else {
						showError("Convert failed",err);
					}
				});
			});
		});
	}
}

export function assistantsFilesCreate(asstId, fileId) {
	// Can I cache all this user stuff?
	var poolData = {
		UserPoolId : cognito.userPoolId, // Your user pool id here
		ClientId : cognito.clientId // Your client id here
	};
	var userPool = new CognitoUserPool(poolData);
	var cognitoUser = userPool.getCurrentUser();

	if (cognitoUser != null) {
		cognitoUser.getSession(function(err, session) {
			if (err) {
				alert(err);
				return;
			}
			console.log('session validity: ' + session.isValid());

			var logins = {};
			logins[cognito.logins] = session.getIdToken().getJwtToken();

			AWS.config.credentials = new AWS.CognitoIdentityCredentials({
				IdentityPoolId :  cognito.identityPoolId, // your identity pool id here
				Logins : logins,
				region: "us-east-1"
			});

			AWS.config.credentials.refresh(function(){
				AWS.config.credentials.get(function(err) {
					if (!err) {
						var lambda = new AWS.Lambda({region: "us-east-1", apiVersion: '2015-03-31'});
						
						var params = {
							FunctionName : "aiAssistant",
							InvocationType : 'RequestResponse',
							LogType : 'None',
							Payload : JSON.stringify({
                                eventName: "assistantsFilesCreate",
                                params: {
                                    asst_id : asstId,
                                    body: {
                                        file_id: fileId,
                                      }    
                                }
                            })
						};

						lambda.invoke(params, function(error, data) {
							if (error) {
								alert(error);
							} else {
								console.log(data.Payload);

								var arrPath = state.select('selectedNodePath').get();
                                const newFiles = [...state.select(arrPath).get(["entity","aiAssistantChecklistFiles"])];
								newFiles.push(JSON.parse(data.Payload));

								// Still need to add to assistants files as well
								state.select(arrPath).set(["entity","aiAssistantChecklistFiles"],newFiles);
                                state.commit();
                            }
						});
					} else {
						showError("Convert failed",err);
					}
				});
			});
		});
	}
}

export function assistantsFilesDelete(asstId, fileId) {
	// Can I cache all this user stuff?
	var poolData = {
		UserPoolId : cognito.userPoolId, // Your user pool id here
		ClientId : cognito.clientId // Your client id here
	};
	var userPool = new CognitoUserPool(poolData);
	var cognitoUser = userPool.getCurrentUser();

	if (cognitoUser != null) {
		cognitoUser.getSession(function(err, session) {
			if (err) {
				alert(err);
				return;
			}
			console.log('session validity: ' + session.isValid());

			var logins = {};
			logins[cognito.logins] = session.getIdToken().getJwtToken();

			AWS.config.credentials = new AWS.CognitoIdentityCredentials({
				IdentityPoolId :  cognito.identityPoolId, // your identity pool id here
				Logins : logins,
				region: "us-east-1"
			});

			AWS.config.credentials.refresh(function(){
				AWS.config.credentials.get(function(err) {
					if (!err) {
						var lambda = new AWS.Lambda({region: "us-east-1", apiVersion: '2015-03-31'});
						
						var params = {
							FunctionName : "aiAssistant",
							InvocationType : 'RequestResponse',
							LogType : 'None',
							Payload : JSON.stringify({
                                eventName: "assistantsFilesDelete",
                                params: {
                                    asst_id: asstId,
                                    file_id: fileId
                                }
                            })
						};

						lambda.invoke(params, function(error, data) {
							if (error) {
								alert(error);
							} else {
								console.log(data.Payload);

                                // Let's find this file_id in array and splice it out
                                var arrPath = state.select('selectedNodePath').get();

                                const files = state.select(arrPath).get(["entity","aiAssistantChecklistFiles"]);
                                const newFiles = [...files];
                                const newFilesFiltered = newFiles.filter((file) => {
                                    return file.id !== fileId;
                                });

                                state.select(arrPath).set(["entity","aiAssistantChecklistFiles"],newFilesFiltered);
                                state.commit();
							}
						});
					} else {
						showError("Convert failed",err);
					}
				});
			});
		});
	}
}

export async function getContent(asstId, fileId) {
    // Create a thread in the assistant
    const createThreadResponse = await fetch('https://api.ambifi.com/services/openai/threads', {
        method: 'POST'
        // headers: headers
    });
    const jsonCreateThreadResponse = await createThreadResponse.json();
    const threadId = jsonCreateThreadResponse.id;

    // Add a message to the thread
    const createMessageResponse = await fetch(`https://api.ambifi.com/services/openai/threads/${threadId}/messages`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        // body: JSON.stringify({role: "user", content: "Can you return all of the checklist content in a hierarchy of JSON data? I would like all of the content. Not just a summary.", file_ids: [fileId] }) 
        body: JSON.stringify({role: "user", content: "Can you return all of the checklist content?", file_ids: [fileId] }) 
        // headers: headers
    });
    const jsonCreateMessageResponse = await createMessageResponse.json();
    

    // Add a run
    const createRunResponse = await fetch(`https://api.ambifi.com/services/openai/threads/${threadId}/runs`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ assistant_id: asstId })
    });
    const jsonCreateRunResponse = await createRunResponse.json();

    // Poll the run until complete-- for now just use timeout

    // This needs fixed before can get good messages back!
    let runInterval;
    let runCount = 0;
    runInterval = setInterval(async () => {
        runCount++;
        const createRunIntervalResponse = await fetch(`https://api.ambifi.com/services/openai/threads/${threadId}/runs/${jsonCreateRunResponse.id}`, {
            method: 'GET'
        });
        const jsonCreateRunIntervalResponse = await createRunIntervalResponse.json();

        // console.log("RUN STEP", jsonCreateRunIntervalResponse);

        if (jsonCreateRunIntervalResponse.status === "completed") {
            clearInterval(runInterval);

            // Retrieve messages
            const createRunMessagesResponse = await fetch(`https://api.ambifi.com/services/openai/threads/${threadId}/messages`, {
                method: 'GET'
                // headers: headers
            });
            const jsonCreateRunMessagesResponse = await createRunMessagesResponse.json();

            let robotResponse = "";
            for (let i=jsonCreateRunMessagesResponse.data.length-1; i>=0; i--) {
                const item = jsonCreateRunMessagesResponse.data[i];

                robotResponse = robotResponse + item.content[0].text.value + "<br/><br/>";
            }

            robotResponse = robotResponse + "<b style='color: green'>I am done :)</b><br/><br/>"

            state.set(["appState", "aiAssistantResponse"], robotResponse);
        } else {
            // let dot = ".";
            // state.set(["appState", "aiAssistantResponse"], "I am hard at work trying to figure this out" + dot.repeat(runCount));

            try {
                // Retrieve messages
                const createRunMessagesResponse = await fetch(`https://api.ambifi.com/services/openai/threads/${threadId}/messages`, {
                    method: 'GET'
                    // headers: headers
                });
                const jsonCreateRunMessagesResponse = await createRunMessagesResponse.json();

                let robotResponse = "";
                for (let i=jsonCreateRunMessagesResponse.data.length-1; i>=0; i--) {
                    const item = jsonCreateRunMessagesResponse.data[i];

                    if (item.content.length > 0 && item.content[0].hasOwnProperty("text")) {
                        robotResponse = robotResponse + item.content[0].text.value + "<br/><br/>";
                    }
                }

                robotResponse = robotResponse + `<b style='color: #0E7AFE'>STILL WORKING${".".repeat(runCount)}</b><br/><br/>`;

                state.set(["appState", "aiAssistantResponse"], robotResponse);

            } catch (err) {
                state.set(["appState", "aiAssistantResponse"], `${err.message}`);
            }
        }    
    },1000);

    // setTimeout(async () => {
    //     // Retrieve messages
    //     const createRunMessagesResponse = await fetch(`https://api.ambifi.com/services/openai/threads/${threadId}/messages`, {
    //         method: 'GET'
    //         // headers: headers
    //     });
    //     const jsonCreateRunMessagesResponse = await createRunMessagesResponse.json();

    //     debugger;
    //     alert(jsonCreateRunMessagesResponse.data[0].content[0].text.value);

    // },10000);
}

export async function getContentStreamed(smd, parser, fileId, assistantId, threadId, message) {
    if (!aiSocket) {
        aiSocket = new WebSocket(
            `wss://kkx5nxjeha.execute-api.us-east-1.amazonaws.com/prod/?identityId=${state.get(["user", "identityId"])}`
        );    
    }

    if (!aiSocket.onmessage) {
        aiSocket.onmessage = (event) => {
            console.log(event.data);
    
			if (event.data.startsWith(`{"message": "Endpoint request timed out"`)) {
				// Timeout messages show up here!
			} else {
				// smd.parser_write(parser, event.data)
    
				let newData = state.get(["appState", "aiAssistantResponse"]);
				newData = newData + event.data;
		
				state.set(["appState", "aiAssistantResponse"], newData);    
			}            
          };
    }

    if (!aiSocket.onopen) {
        aiSocket.onopen = (event) => {
            aiSocket.send(`{ "action": "sendAssistantMessage", "assistantId": "${assistantId}", "threadId": "${threadId}", "message": "${message}" }`);
            // aiSocket.send(`{ "action": "sendAssistantMessage", "message": "Hello AI Assistant!" }`);
        };    
    } else {
        aiSocket.send(`{ "action": "sendAssistantMessage", "assistantId": "${assistantId}", "threadId": "${threadId}", "message": "${message}" }`);
    }

    if (!aiSocket.onclose) {
        aiSocket.onclose = (event) => {
            aiSocket = null;
        };    
    }

    if (!aiSocket.onerror) {
        aiSocket.onerror = (event) => {
            let newData = state.get(["appState", "aiAssistantResponse"]);
            newData = newData + "\n\nWebsocket error!\n\n";
    
            state.set(["appState", "aiAssistantResponse"], newData);    
        };    
    }

}