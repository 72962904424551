import React from "react";
import { branch } from "baobab-react/higher-order";
import PropTypes from "baobab-react/prop-types";

import Flexbox from "flexbox-react";

import moment from "moment";

import FontAwesome from "react-fontawesome";

class TimerCardReadOnly extends React.PureComponent {
	render() {
		let timerTemplate = this.props.timerTemplate;
		if (timerTemplate.type === "custom") {
			try {
				timerTemplate = JSON.parse(this.props.timerTemplate.custom);
			} catch (e) {
				console.log(e);
			}
		}

		const time = moment().utcOffset(0);
		time.set({ hour: timerTemplate.hours, minute: timerTemplate.minutes, second: timerTemplate.seconds, millisecond: 0 });

		let type = <FontAwesome fixedWidth={true} style={{ color: "lightgreen", fontSize: "24px" }} name="arrow-circle-down" />;
		if (this.props.timerTemplate.type === "countup") {
			type = <FontAwesome fixedWidth={true} style={{ color: "skyblue", fontSize: "24px" }} name="arrow-circle-up" />;
		} else if (this.props.timerTemplate.type === "custom") {
			type = <FontAwesome fixedWidth={true} style={{ color: "goldenrod", fontSize: "24px" }} name="gears" />;
		}

		let repeat = <div style={{ width: "24px", height: "24px" }}></div>;

		if (timerTemplate.repeat) {
			repeat =
				<Flexbox alignItems="center" justifyContent="center">
					<FontAwesome fixedWidth={true} style={{ fontSize: "24px" }} name="repeat" />
					<span style={{ fontSize: "14px" }}>({timerTemplate.repeatCount})</span>
				</Flexbox>;
		}

		return (
			<Flexbox style={{ width: "100%" }} alignItems="center" justifyContent="space-between">
				<Flexbox style={{ padding: "5px" }} alignItems="center" justifyContent="center">
					{type}
				</Flexbox>
				<Flexbox flexDirection="column" justifyContent="center" alignItems="center">
					{timerTemplate.label1 && timerTemplate.label1 !== "" &&
						<div style={{ color: "skyblue", fontSize: "16px", fontWeight: "bold" }}>
							{timerTemplate.label1}
						</div>
					}
					{timerTemplate.label2 && timerTemplate.label2 !== "" &&
						<div style={{ color: "#888888", fontSize: "14px" }}>
							{timerTemplate.label2}
						</div>
					}
					<div style={{ color: "#bcbcbc", fontSize: "24px", fontWeight: "bold" }}>
						{time.format("HH:mm:ss")}
					</div>
					{timerTemplate.action && timerTemplate.action.type === "message" && timerTemplate.action.message !== "" &&
						<div style={{ color: "lightgreen", fontSize: "12px", fontWeight: "bold" }}>
							{timerTemplate.action.message}
						</div>
					}
				</Flexbox>
				<Flexbox style={{ padding: "5px" }} alignItems="center" justifyContent="center">
					{repeat}
				</Flexbox>
			</Flexbox>
		);
	}
}

TimerCardReadOnly.contextTypes = {
	tree: PropTypes.baobab
};

export default branch({
	user: ["user"],
	timerTemplates: ["timerTemplates"],
	documents: ["documents"],
	timerContent: ["content", "timer"]
}, TimerCardReadOnly);