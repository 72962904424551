import React from 'react';
import { branch } from 'baobab-react/higher-order';
//import { AlertList } from "react-bs-notifier";
import SweetAlert from 'react-bootstrap-sweetalert'
import * as actions from '../actions/actions';

import { generateUUID } from "../utils/utils";

class Alerts extends React.PureComponent {

    handleDismiss() {
        actions.dismissAlert();
    }//this.handleDismiss.bind(this)

    render() {
        let alerts = [];
        const self = this;
        this.props.alerts.forEach((alert) => {

            let opts = {
                confirmBtnTest: 'OK',
                confirmBtnBsStyle: 'default',
                onConfirm: self.handleDismiss.bind(self)
            };
            opts[alert.type] = true;
            if (alert.title && alert.title !== '') {
                opts.title = alert.title;
            }

            if (alert.onConfirm) {
                opts.confirmBtnBsStyle = 'warning';
                opts.onConfirm = function () { self.handleDismiss(); alert.onConfirm(); };
                opts.showCancel = true;
                opts.onCancel = self.handleDismiss.bind(self);
                opts.warning = true;
            }

            if (alert.callback) {
                opts.onConfirm = function () { self.handleDismiss(); alert.callback(); };
            }

            alerts.push(<SweetAlert customClass="as-alert" key={generateUUID()} style={{ border: "5px solid #eba20e", backgroundColor: "#fdfdfd", borderRadius: "20px" }}
                {...opts}>{alert.message}</SweetAlert>);
        });
        return (
            <div>
                {alerts}
            </div>
            // <AlertList alerts={this.props.alerts} position={this.props.type==='errors'?'top-right':'bottom-right'} onDismiss={this.handleDismiss.bind(this)} />
        )
    }

}

export default branch((props, context) => {
    return {
        alerts: ["appState", "alerts"]
    };
}, Alerts);