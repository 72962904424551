import React from 'react';
import ReactDOM from 'react-dom';

import classNames from 'classnames';

import Leaf from './app-leaf.js';
import Glyphicon from '../components/app-icon';

import PropTypes from 'baobab-react/prop-types';
import * as actions from '../actions/actions';

import { getAdditionalInfoComponent } from "../utils/utils";

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { pathToArray } from "../utils/utils";

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: "none",
	WebkitUserSelect: "none",
	MozUserSelect: "none",
	msUserSelect: "none",
	//   padding: grid * 2,
	//   margin: `0 0 ${grid}px 0`,

	// change background colour if dragging
	//   background: isDragging ? "lightgreen" : "#88888844",

	// styles we need to apply on draggables
	...draggableStyle
});

const getListStyleSection = isDraggingOver => ({
	background: isDraggingOver ? "#00000011" : "transparent",
	//   padding: grid
});

const getListStyleList = isDraggingOver => ({
	background: isDraggingOver ? "#00000011" : "transparent",
	//   padding: grid
});

class Category extends React.PureComponent {
	// shouldComponentUpdate(nextProps, nextState) {
	// 	//console.log("Category shouldComponentUpdate");
	// 	return this.props.node !== nextProps.node;
	// }

	handleSelectNodeAtPath(e) {
		actions.handleSelectNodeAtPath(this.context.tree, pathToArray(this.props.path));
	}

	handleToggleNode(e) {
		actions.handleToggleNode(this.context.tree.select(pathToArray(this.props.path)));
	}

	handleChangeLabel(e) {
		actions.handleChangeProperty(this.context.tree, "label", e.target.value);
	}

	render() {
		var self = this;
		var nodes;

		if (!this.props.node) {
			return <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Loading...</div>
		} else {

			// If there is a subcategory
			if (Object.prototype.toString.call(self.props.node.children) === '[object Array]' && this.props.node.expanded) {
				nodes = self.props.node.children.map(function (node, i) {
					let arrPath = self.props.path;
					arrPath = arrPath + ",children";
					arrPath = arrPath + "," + i;
					if (node.entity.type == "category") {
						return (
							<Category
								editorState={self.props.editorState}
								key={node.entity.entityId}
								node={node} path={arrPath}
							/>
						);
					} else if (node.entity.type == "list") {
						return (
							<Draggable key={node.entity.entityId} draggableId={node.entity.entityId} index={i}>
								{(providedDrag, snapshot) => (
									<div
										ref={providedDrag.innerRef}
										{...providedDrag.draggableProps}
										// {...providedDrag.dragHandleProps}
										style={getItemStyle(
											snapshot.isDragging,
											providedDrag.draggableProps.style
										)}
									>
										<DragDropContext onDragEnd={(result) => {
											actions.handleMoveNode(self.context.tree,
												result.destination.droppableId,
												result.source.index,
												result.destination.index
											)
										}}>
											<Droppable droppableId={node.entity.entityId}>
												{(provided, snapshot) => (
													<div
														{...provided.droppableProps}
														ref={provided.innerRef}
														style={getListStyleList(snapshot.isDraggingOver)}
													>
														<Category
															editorState={self.props.editorState}
															key={node.entity.entityId} node={node}
															path={arrPath}
															provided={providedDrag}
														/>
														{provided.placeholder}
													</div>

												)}
											</Droppable>
										</DragDropContext>
									</div>
								)}
							</Draggable>
						);
					} else if (node.entity.type == "section") {
						return (
							<Draggable key={node.entity.entityId} draggableId={node.entity.entityId} index={i}>
								{(providedDrag, snapshot) => (
									<div
										ref={providedDrag.innerRef}
										{...providedDrag.draggableProps}
										// {...providedDrag.dragHandleProps}
										style={getItemStyle(
											snapshot.isDragging,
											providedDrag.draggableProps.style
										)}
									>
										<DragDropContext onDragEnd={(result) => {
											actions.handleMoveNode(self.context.tree,
												result.destination.droppableId,
												result.source.index,
												result.destination.index
											)
										}}>
											<Droppable droppableId={node.entity.entityId}>
												{(provided, snapshot) => (
													<div
														{...provided.droppableProps}
														ref={provided.innerRef}
														style={getListStyleSection(snapshot.isDraggingOver)}
													>
														<Category
															editorState={self.props.editorState}
															key={node.entity.entityId} node={node}
															path={arrPath}
															provided={providedDrag}
														/>
														{provided.placeholder}
													</div>

												)}
											</Droppable>
										</DragDropContext>
									</div>
								)}
							</Draggable>
						);
					} else {
						return (
							<Draggable key={node.entity.entityId} draggableId={node.entity.entityId} index={i}>
								{(provided, snapshot) => (
									<div
										ref={provided.innerRef}
										{...provided.draggableProps}
										// {...provided.dragHandleProps}
										style={getItemStyle(
											snapshot.isDragging,
											provided.draggableProps.style
										)}
									>
										<Leaf
											editorState={self.props.editorState}
											node={node}
											path={arrPath}
											provided={provided}
										/>
									</div>
								)}
							</Draggable>
						);
					}
				});
			} else {
				nodes =
					<div>
						<span style={{ fontWeight: "bold" }}>
							{this.props.node.entity.label}
						</span>
					</div>
			}

			var style;
			if (!this.props.node.expanded) {
				style = { display: "none" };
			} else {
				style = { marginLeft: "10px" };
			}

			var category, icon, color, bgcolor, fgcolor;

			var itemSizeLabel;

			if (this.props.node.entity.type == "category") {
				icon = this.props.node.expanded ? "folder-open" : "folder";
				color = "#ffc153";
				bgcolor = "#cccccc33";
				fgcolor = "#ffffff";
			} else if (this.props.node.entity.type == "checklist") {
				icon = "question";
				for (var i = 0; i < this.props.genres.length; i++) {
					var genre = this.props.genres[i];
					if (genre.value == this.props.node.entity.genre) {
						icon = genre.icon;
					}
				}
				color = "#ffffff";

				const itemSize = (JSON.stringify(self.props.node).length);
				const itemSizeKB = (itemSize / 1024).toFixed(2);
				const itemSizeMB = (itemSize / (1024 * 1024)).toFixed(2);
				const itemSizeGB = (itemSize / (1024 * 1024 * 1024)).toFixed(2);
				
				if (itemSizeMB >= 750) {
					itemSizeLabel = <span style={{ color: "red" }}><b>{" (" + itemSizeGB.toLocaleString() + " GB)"}</b></span>
				} else if (itemSizeKB >= 1024) {
					itemSizeLabel = <span style={{ color: "pink" }}><b>{" (" + itemSizeMB.toLocaleString() + " MB)"}</b></span>
				} else if (itemSize >= 100000) {
					itemSizeLabel = <span style={{ color: "goldenrod" }}><b>{" (" + itemSizeKB.toLocaleString() + " KB)"}</b></span>
				} else if (itemSize >= 0) {
					itemSizeLabel = <span style={{ fontWeight: 'Light', color: "lightgreen" }}><b>{" (" + itemSizeKB.toLocaleString() + " KB)"}</b></span>
				}

				bgcolor = "#cccccc33";
				fgcolor = "#ffffff";
			} else if (this.props.node.entity.type == "list") {
				icon = "list-alt";
				color = "#0084c1";
				bgcolor = "#cccccc33";
				fgcolor = "#ffffff";
			} else if (this.props.node.entity.type == "section") {
				icon = "table";
				color = "#ffc153";
				bgcolor = "#cccccc33";
				fgcolor = "#ffffff";
			} else {
				icon = this.props.node.expanded ? "folder-open" : "folder";
				color = "#ffc153";
			}
			var label = self.props.node.entity.label;
			if (self.props.node.entity.type === "checklist" && self.props.node.entity.label === "") {
				label = "(Procedure Name)";
			} else if (self.props.node.entity.type === "list" && self.props.node.entity.label === "") {
				label = "(List Name)";
			} else if (self.props.node.entity.type === "section" && self.props.node.entity.label === "") {
				label = "(Section Name)";
			}

			const entity = self.props.node.entity;

			let labelComponent = <div style={{ width: "100%" }}>
				{label}{itemSizeLabel}
				{getAdditionalInfoComponent(entity)}
			</div>;

			if (this.props.node.selected) {
				category =
					<div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px", fontWeight: "bold", backgroundColor: bgcolor, color: fgcolor, cursor: "pointer" }}>
						<div onClick={this.handleToggleNode.bind(this)} style={{
							display: "flex", alignItems: "center", width: "100%", userSelect: "none",
							WebkitUserSelect: "none",
							MozUserSelect: "none",
							msUserSelect: "none"
						}}>
							<Glyphicon style={{ color: "#D78770", margin: "5px" }} glyph={this.props.node.expanded ? "minus" : "plus"} />
							<Glyphicon style={{ fontSize: "32px", color: color }} glyph={icon} />
							<div style={{ width: "100%", marginLeft: "5px" }}>
								{labelComponent}
							</div>
						</div>
						{this.props.provided && <div {...this.props.provided.dragHandleProps}>
							<Glyphicon style={{ fontSize: "32px", color: color + "88" }} glyph="sort" />
						</div>}
					</div>
			} else {
				category =
					<div style={{ width: "100%", display: "flex", alignItems: "center", padding: "10px", fontWeight: "bold", color: "#bcbcbc", cursor: "pointer" }} onClick={this.handleSelectNodeAtPath.bind(this)}>
						<div onClick={this.handleToggleNode.bind(this)}>
							<Glyphicon style={{ color: "#D78770", margin: "5px" }} glyph={this.props.node.expanded ? "minus" : "plus"} />
						</div>
						<Glyphicon style={{ fontSize: "32px", color: color }} glyph={icon} />
						<div style={{ width: "100%", marginLeft: "5px" }}>
							{labelComponent}
						</div>
						{this.props.provided && <div {...this.props.provided.dragHandleProps}>
							<Glyphicon style={{ fontSize: "32px", color: color + "88" }} glyph="sort" />
						</div>}
					</div>
			}

			return (
				<div>
					<div>
						{category}
					</div>
					<div style={style}>
						{nodes}
					</div>
				</div>
			);
		}
	}
}


Category.contextTypes = {
	tree: PropTypes.baobab
};

export default Category;
