import React from 'react';

import * as actions from '../../actions/actions';
import browserHistory from '../../history';
import Return from "./app-subscribe-perf-trial-return";

import state from "../../state/state";

import HomeLink from './app-home-link.js';

import { Button } from 'react-bootstrap';

import Flexbox from 'flexbox-react';

import Alerts from '../app-alerts.js';

class PreReturn extends React.PureComponent {
	constructor(props, context) {
		super(props, context);

		if (!state.get("user", "loggedIn")) {
			actions.forceLogin(browserHistory, this.props.location.pathname);
		}
	}

	render() {
    if (!state.get("user", "loggedIn")) {
      return null
    } else {
		let headerPanel = null;

		headerPanel =
		<Flexbox flexDirection="row" alignItems="center" justifyContent="space-between">
		  <HomeLink />
		  <Button style={{width: "200px", marginRight: "20px"}} onClick={(e)=>{
			actions.goHome();
		  }}>Return Home</Button>
		</Flexbox>

      return (
		<div>
			{headerPanel}
			<Alerts />
			  <Return plan="pro-plan-as-yearly-v4" title="Pro Plan (Yearly)" content="We appreciate your business! You are now subscribed to the Pro Plan (Yearly)." />
		</div>
	  )
	}
	}
}

export default PreReturn;