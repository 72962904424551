import React from 'react';
import {branch} from 'baobab-react/higher-order';
import * as actions from '../../actions/actions';
import {Checkbox, Panel} from 'react-bootstrap';

import AfPanel from '../../components/shared/AfPanel';
import AfHelp from '../../components/shared/AfHelp';

import _ from "lodash";

import { Controlled as CodeMirror } from "react-codemirror2";

require("codemirror/lib/codemirror.css"); // eslint-disable-line
require("codemirror/theme/material.css"); // eslint-disable-line
require("codemirror/mode/javascript/javascript"); // eslint-disable-line

let lastEdit = "";

class PrintProperties extends React.PureComponent {
    constructor(props, context) {
		super(props, context);

        this.instance = null;
    }
        
    render() {
        return (
            <AfPanel defaultExpanded={false} onToggle={(e)=>{setTimeout(()=> {this.instance.refresh()}, 200)}} title="Print Properties">
                <div>
                    <AfHelp alignItems="center" description={this.props.content.keepTogether.description}>
                        <Checkbox checked={this.props.entity.printProperties.keepTogether ? "checked" : ""} onChange={(e)=>{
                            this.props.dispatch(
                                actions.handleChangePrintProperty,
                                "keepTogether",
                                e.target.checked
                            );                            
                        }}>
                            {this.props.content.keepTogether.name}
                        </Checkbox>
                    </AfHelp>
                </div>
                <div>
                    <AfHelp alignItems="center" description={this.props.content.breakBefore.description}>
                        <Checkbox checked={this.props.entity.printProperties.breakBefore ? "checked" : ""} onChange={(e)=>{
                            this.props.dispatch(
                                actions.handleChangePrintProperty,
                                "breakBefore",
                                e.target.checked
                            );                            
                        }}>
                            {this.props.content.breakBefore.name}
                        </Checkbox>
                    </AfHelp>    
                </div>
                <div>
                    <AfHelp description={this.props.content.breakBeforeHeight.description}>
                        <label>{this.props.content.breakBeforeHeight.name}</label>
                    </AfHelp>
                </div>
                <div>
                    <input style={{marginBottom: "15px", maxWidth: "100px"}} className="form-control" type="text" value={this.props.entity.printProperties.breakBeforeHeight} onChange={(e)=>{
                        this.props.dispatch(
                            actions.handleChangePrintProperty,
                            "breakBeforeHeight",
                            e.target.value
                        );                            
                    }} />
                </div>
                <div>
                    <AfHelp alignItems="center" description={this.props.content.breakAfter.description}>
                        <Checkbox checked={this.props.entity.printProperties.breakAfter ? "checked" : ""} onChange={(e)=>{
                            this.props.dispatch(
                                actions.handleChangePrintProperty,
                                "breakAfter",
                                e.target.checked
                            );                            
                        }}>
                            {this.props.content.breakAfter.name}
                        </Checkbox>
                    </AfHelp>    
                </div>
                <div>
                    <AfHelp description={this.props.content.breakAfterHeight.description}>
                        <label>{this.props.content.breakAfterHeight.name}</label>
                    </AfHelp>
                </div>
                <div>
                    <input style={{marginBottom: "15px", maxWidth: "100px"}} className="form-control" type="text" value={this.props.entity.printProperties.breakAfterHeight} onChange={(e)=>{
                        this.props.dispatch(
                            actions.handleChangePrintProperty,
                            "breakAfterHeight",
                            e.target.value
                        );                            
                    }} />
                </div>
                <div style={{marginTop: "15px"}}>
                    <AfHelp description={this.props.content.customProperties.description}>
                        <label>{this.props.content.customProperties.name}</label>
                    </AfHelp>
                </div>	
                <div>
                    <CodeMirror
                        value={this.props.entity.printCustomProperties}
                        options={{
                            mode: "javascript",
                            lineNumbers: true
                        }}
                        editorDidMount={editor => { this.instance = editor }}
                        onBeforeChange={(editor, data, value) => {
                            lastEdit = value;
                            this.props.dispatch(actions.handleChangeProperty, "printCustomProperties", value);
                        }}
                        onBlur={() => {
                            try {
                                if (!_.isEmpty(lastEdit)) {
                                    JSON.parse(lastEdit);
                                }
                            } catch (e) {
                                alert(e.message + "\n\nYou need to fix the Custom Properties JSON or you won't be able to save the checklist.");
                            }
                        }}														
                    />
                </div>
            </AfPanel>
        )    
    }
}

export default branch((props) => {
    // Get the proper content
    let type = "item";
    if (props.entity.type === "list" || props.entity.type === "section") {
        type = props.entity.type;
    }

	return {
		content: ['content',type,'properties','properties']
	};
}, PrintProperties);