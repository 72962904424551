import React from "react";
import globals from "../constants/globals.js";
import { branch } from "baobab-react/higher-order";
import PropTypes from "baobab-react/prop-types";
import * as actions from "../actions/actions";
import * as actionsPortal from "../actions/portalActions";
import * as awsS3 from "../persistence/s3";

import {
  Button,
  Dropdown,
  Glyphicon,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";

import {
  PoweredBy,
  InstantSearch,
  Configure,
  Hits,
  SearchBox,
  Highlight,
  RefinementList,
  Pagination,
  CurrentRefinements,
  ClearAll,
} from "react-instantsearch/dom";

import FontAwesome from "react-fontawesome";

import Flexbox from "flexbox-react";
import browserHistory from "../history";

import NavLinks from "./navPanel/app-nav-links.js";
import NavPanel from "./navPanel/app-nav-panel.js";

import { WithContext as ReactTags } from "react-tag-input";

import MediaQuery from "react-responsive";

import StripeCheckout from "react-stripe-checkout";

import Modal from "react-responsive-modal";
import ChecklistReadOnly from "./app-checklist-read-only.js";

import Alerts from "./app-alerts.js";

import * as utils from "../utils/utils";

import Loader from "react-loader-advanced";

function Product({ hit }) {
  var icon = "question";
  for (var i = 0; i < this.props.genres.length; i++) {
    var genre = this.props.genres[i];
    if (hit.genre == genre.value) {
      icon = genre.icon;
    }
  }
  var tags = hit.tags.map(function (tag, i) {
    return {
      id: i + 1,
      text: tag,
    };
  });


  var tagsLabel;
  if (hit.tags.length > 0) {
    tagsLabel = "Tags: ";
  }

  var addButton;
  if (hit.publisher === "checkmate") {
    var addButtonCloud = (
      <div>
        {/*
        <a href="javascript:alert('This is a test sandbox so you will not actually be charged. For the credit card enter 4242-4242-4242-4242, for expiration enter any future date and for CVC enter any three numbers.')">Help!</a>
        */}
        <StripeCheckout
          name="MiraCheck"
          description={hit.name}
          image={"assets/checkmate_logo_only_no_border_space_128x128.png"}
          token={(token) => {
            this._onToken(token, hit);
          }}
          stripeKey={
            globals.stage === "prod"
              ? globals.stripeKeyProd
              : globals.stripeKeyDev
          }
          amount={2000}
          email={this.props.user.email}
          ComponentClass="div"
        >
          <Button style={{ color: "#0E7AFE", fontWeight: "bold" }}>Buy</Button>
        </StripeCheckout>
      </div>
    );

    var addButtonMobile = (
      <div style={{ display: "flex" }}>
        <Button
          onClick={(e) => {
            alert(
              "To purchase premium content please login to the MiraCheck website through a web browser."
            );
          }}
          style={{ color: "#0E7AFE", fontWeight: "bold" }}
        >
          Info
        </Button>
      </div>
    );

    var addButtonIgapp = (
      <div style={{ display: "flex" }}>
        <Button
          onClick={(e) => {
            alert(
              "Sorry, CheckMate content is not available to purchase on the IGAPP store."
            );
          }}
          style={{ color: "#0E7AFE", fontWeight: "bold" }}
        >
          N/A
        </Button>
      </div>
    );

    // Probably better way to know if launched from mobile app
    // var mobile = false;

    // try {
    //   window.webkit.messageHandlers.reactNative.postMessage(JSON.stringify({method: "dummy"}));
    //   mobile = true;
    // } catch(e) {
    // }
    // // UIWebView
    // try {
    //   window.postMessage(JSON.stringify({method: "dummy"}));
    //   mobile = true;
    // } catch(e) {
    // }

    // if (this.props.igapp) {
    // addButton = addButtonIgapp;
    // } else if (utils.isMobile()) {
    // addButton = addButtonMobile;
    // } else {
    addButton = addButtonCloud;
    // }
  } else {
    addButton = (
      <Button
        onClick={() => {
          this._add(hit);
        }}
      >
        Add
      </Button>
    );
  }

  var contributorsTooltip = "";
  var creator = "Creator:\n";
  var contributors = "\n\nContributors:\n";
  if (hit.contributors.length === 0) {
    contributorsTooltip = "There are currently no contributors.";
  } else {
    contributorsTooltip = contributorsTooltip + creator;
    contributorsTooltip =
      contributorsTooltip +
      (hit.contributors[0] &&
      hit.contributors[0].nickname &&
      hit.contributors[0].nickname.length > 0
        ? hit.contributors[0].nickname
        : hit.contributors[0].name);

    if (hit.contributors.length > 1) {
      contributorsTooltip = contributorsTooltip + contributors;
      for (var i = 1; i < hit.contributors.length; i++) {
        var contributor = hit.contributors[i].name;
        if (i < hit.contributors.length - 1) {
          contributorsTooltip = contributorsTooltip + contributor + "\n";
        } else {
          contributorsTooltip = contributorsTooltip + contributor;
        }
      }
    }
  }

  const popoverContributors = (
    <Popover id="popover-positioned-bottom" title="Contributors">
      {contributorsTooltip}
    </Popover>
  );

  var iconContributors = (
    <FontAwesome
      id="contributorsIcon"
      fixedWidth={true}
      title={contributorsTooltip}
      style={{ color: "#888888", fontSize: "14px", marginRight: "5px" }}
      name="user"
    />
  );

  var overlayContributors = (
    <OverlayTrigger
      trigger="click"
      rootClose
      placement="bottom"
      overlay={popoverContributors}
    >
      {iconContributors}
    </OverlayTrigger>
  );

  var CustomRefinementList = (props) => {
    return (
      <RefinementList
        {...props}
        transformItems={(items) =>
          items.filter((item) => !item.label.startsWith('.'))
        }
      />
    );
  };

  var TagComponent = ({ tags }) => {
    // Filter tags to exclude those that start with a dot
    const filteredTags = tags.filter(tag => tag && tag.name && !tag.name.startsWith('.'));
  
    return (
      <div>
        <Dropdown>
          <Dropdown.Toggle noCaret className='filter-dropdown' onClick={(e) => { e.stopPropagation(); }}>
            Tags
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ className: 'filter-menu', opacity: 0.95 }}>
            <CustomRefinementList attributeName="tags" />
          </Dropdown.Menu>
        </Dropdown>
  
        <ReactTags tags={filteredTags} autofocus={false} readOnly={true} />
      </div>
    );
  };

  return (
    <div style={{ width: "100%" }}>
      <span>
        <Flexbox
          className="hit-name"
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="space-between"
          style={{
            border: "2px solid #1F272B",
            borderRadius: "15px",
            padding: "10px",
            margin: "10px",
          }}
        >
          <Flexbox flexDirection="row" alignItems="flex-start">
            <Flexbox
              flexDirection="column"
              alignItems="flex-start"
              justify-content="space-between"
            >
              <FontAwesome
                fixedWidth={true}
                style={{ minWidth: "40px", marginRight: "10px" }}
                size="2x"
                name={icon}
              />
            </Flexbox>
            <Flexbox
              flexDirection="column"
              alignItems="flex-start"
              style={{ marginRight: "10px" }}
            >
              <Flexbox flexDirection="row" alignItems="flex-start">
                <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                  <Highlight attributeName="name" hit={hit} />
                </div>
                <div style={{ marginLeft: 10 }}>{overlayContributors}</div>
              </Flexbox>
              <div style={{ fontSize: "14px", fontWeight: "normal" }}>
                <Highlight attributeName="description" hit={hit} />
              </div>
              <Button
                style={{ margin: "5px" }}
                onClick={() => {
                  this.openPreviewModal(hit);
                }}
              >
                Preview
              </Button>
              <Flexbox style={{ marginTop: "10px" }}>
                <ReactTags tags={tags} autofocus={false} readOnly={true} />
               
              </Flexbox>
            </Flexbox>
          </Flexbox>
          {addButton}
        </Flexbox>
      </span>
    </div>
  );
}

class MiraCheckSearch extends React.Component {
  constructor(props, context) {
    super(props, context);

    if (!props.user.loggedIn) {
      actions.forceLogin(browserHistory, this.props.location.pathname);
    } else {
      this.openPreviewModal = this.openPreviewModal.bind(this);
      this.closePreviewModal = this.closePreviewModal.bind(this);
    }

    this.state = {
      showFilters: false,
      createdByOpen: false,
      genresOpen: false,
      tagsOpen: false,
      visibilityOpen: false,
    };

    Product = Product.bind(this);
  }

  _add(hit) {
    awsS3.clonePublicDocument(hit);
  }

  _onToken = (token, hit) => {
    awsS3.saveStripeToken(token, hit);
    /*
        debugger;
        fetch('/save-stripe-token', {
          method: 'POST',
          body: JSON.stringify(token),
        }).then(token => {
          debugger;
          alert(`We are in business, ${token.email}`);
        });
    */
  };

  closePreviewModal() {
    this.props.dispatch(actions.closePreviewModal);
  }

  openPreviewModal(hit) {
    this.props.dispatch(actions.openPreviewModal, hit);
  }

  render() {
    if (!this.props.user.loggedIn) {
      return <div />;
    } else {
      var previewModal;
      previewModal = (
        <Modal
          classNames={{ modal: "custom-modal" }}
          open={this.props.previewChecklist.showModal}
          onClose={this.closePreviewModal}
          little
        >
          <h3 style={{ textAlign: "center", color: "#222222" }}>Preview</h3>
          <div style={{ height: "70vh", overflow: "auto" }}>
            <ChecklistReadOnly
              checklist={this.props.previewChecklist.checklist}
              preview={true}
            />
          </div>
        </Modal>
      );

      var iconFiltersVisible = (
        <FontAwesome
          fixedWidth={true}
          style={{ color: "#ffffff", fontSize: "20px" }}
          name="chevron-up"
        />
      );
      var iconFiltersHidden = (
        <FontAwesome
          fixedWidth={true}
          style={{ color: "#ffffff", fontSize: "20px" }}
          name="chevron-right"
        />
      );

      var filtersVisible = (
        <Flexbox
          style={{ margin: "5px" }}
          flexDirection="row"
          alignItems="center"
        >
          Hide Filters {iconFiltersVisible}
        </Flexbox>
      );
      var filtersHidden = (
        <Flexbox
          style={{ margin: "5px" }}
          flexDirection="row"
          alignItem="center"
        >
          Show Filters {iconFiltersHidden}
        </Flexbox>
      );

      var chevronDown = (
        <span>
          <Glyphicon glyph="chevron-down" />
        </span>
      );
      var chevronUp = (
        <span>
          <Glyphicon glyph="chevron-up" />
        </span>
      );

      var genresMenuComponent = (
        <Dropdown>
          <Dropdown.Toggle
            noCaret
            className="filter-dropdown"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            Genres
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ opacity: 0.95 }}>
            <RefinementList attributeName="genre" />
          </Dropdown.Menu>
        </Dropdown>
      );

      const CustomRefinementList = (props) => {
        return (
          <RefinementList
            {...props}
            transformItems={(items) =>
              items.filter((item) => !item.label.startsWith("."))
            }
          />
        );
      };
      // var tagsMenuComponent = (
      //   <Dropdown>
      //     <Dropdown.Toggle
      //       noCaret
      //       className="filter-dropdown"
      //       onClick={(e) => {
      //         e.stopPropagation();
      //       }}
      //     >
      //       Tags huhu
      //     </Dropdown.Toggle>
      //     <Dropdown.Menu style={{ className: "filter-menu", opacity: 0.95 }}>
      //     <RefinementList attributeName="tags" />

      //       {/* <PillButton
      //         content={{ RefinementList: attributeName = "tags" }}
      //       /> */}
      //     </Dropdown.Menu>
      //   </Dropdown>
      // );

      var tagsMenuComponent = (
        <Dropdown>
          <Dropdown.Toggle
            noCaret
            className="filter-dropdown"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            Tags
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ className: "filter-menu", opacity: 0.95 }}>
            <CustomRefinementList attributeName="tags" />
          </Dropdown.Menu>
        </Dropdown>
      );

      const spinner = <FontAwesome name="spinner" size="4x" spin />;

      return (
        <Loader show={this.props.loadingSpinner} message={spinner}>
          <InstantSearch
            appId="D6RLXM2EO4"
            apiKey="5f99830fd8e61878cedbf78e336c19a7"
            indexName="dev_MIRACHECK"
          >
            {this.props.igapp && (
              <Configure facetFilters={[["tags:-CheckMate"]]} />
            )}
            <Alerts />

            <Flexbox
              flexDirection="column"
              height="100vh"
              style={{ minHeight: 0, width: "100%" }}
            >
              <NavLinks />

              <Flexbox
                flex="1"
                flexDirection="row"
                width="100vw"
                style={{ minHeight: 0, width: "100%" }}
              >
                <MediaQuery query="(min-width: 768px)">
                  <NavPanel />
                </MediaQuery>

                <Flexbox
                  flex="1"
                  flexDirection="column"
                  alignItems="flex-start"
                  style={{ minHeight: 0, width: "100%" }}
                >
                  <Flexbox
                    flex="1"
                    flexDirection="column"
                    style={{ minHeight: 0, width: "100%" }}
                  >
                    <MediaQuery query="(max-width: 767px)">
                      <Flexbox flexDirection="row" width="100%" padding="20px">
                        <Flexbox
                          flexGrow={1}
                          style={{ borderBottom: "1px solid #ccc" }}
                          justifyContent="space-between"
                        >
                          <h1>SEARCH</h1>
                        </Flexbox>
                      </Flexbox>

                      <Flexbox
                        style={{ paddingBottom: "10px", paddingLeft: "20px" }}
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <SearchBox autoFocus={true} />
                      </Flexbox>

                      <div
                        style={{ width: "100%", backgroundColor: "gray" }}
                        onClick={() => {
                          this.setState({
                            showFilters: !this.state.showFilters,
                          });
                        }}
                      >
                        <Flexbox
                          style={{ color: "white" }}
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                        >
                          {this.state.showFilters
                            ? filtersVisible
                            : filtersHidden}
                        </Flexbox>
                      </div>
                      <div
                        style={{
                          display: this.state.showFilters ? "flex" : "none",
                          overflow: "auto",
                          flexDirection: "column",
                          flex: 2,
                          margin: "10px",
                        }}
                      >
                        <div
                          style={{
                            height: "100%",
                            overflow: "auto",
                            borderStyle: "solid",
                            borderRadius: "5px",
                            borderWidth: "1px",
                            backgroundColor: "transparent",
                            borderColor: "#1F272B",
                            padding: "10px",
                          }}
                        >
                          <h4>Filters</h4>
                          <ClearAll />
                          <div>
                            <CurrentRefinements />
                          </div>
                          <h4>Genre</h4>
                          <RefinementList attributeName="genre" />
                          <h4>Tags</h4>
                          <RefinementList attributeName="tags" />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          minHeight: 0,
                          flexDirection: "column",
                          flex: 2,
                          margin: "10px",
                        }}
                      >
                        <Flexbox
                          flexDirection="column"
                          flexGrow={1}
                          style={{
                            minHeight: 0,
                            overflowY: "auto",
                            width: "100%",
                            borderStyle: "solid",
                            borderRadius: "5px",
                            borderWidth: "1px",
                            backgroundColor: "transparent",
                            borderColor: "transparent",
                          }}
                        >
                          <Hits
                            style={{ width: "100%" }}
                            hitComponent={Product}
                          />
                        </Flexbox>
                        <Flexbox
                          style={{ width: "100%", marginTop: "10px" }}
                          minHeight="50px"
                          padding="10px"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Pagination showLast={true} />
                        </Flexbox>
                      </div>
                    </MediaQuery>

                    <MediaQuery query="(min-width: 768px)">
                      <Flexbox flexDirection="row" width="100%" padding="20px">
                        <Flexbox
                          flexGrow={1}
                          style={{ borderBottom: "1px solid #ccc" }}
                          justifyContent="space-between"
                        >
                          <h1>SEARCH</h1>
                        </Flexbox>
                      </Flexbox>
                      <Flexbox
                        flexDirection="row"
                        width="100%"
                        paddingLeft="20px"
                        paddingTop="10px"
                      >
                        <Flexbox>
                          <Flexbox
                            width="300px"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <SearchBox autoFocus={true} />
                          </Flexbox>

                          <Flexbox flexDirection="row" alignItems="left">
                            <div style={{ paddingLeft: "16px" }}>
                              {genresMenuComponent}
                            </div>
                            <div style={{ paddingLeft: "16px" }}>
                              {tagsMenuComponent}
                            </div>

                            <div style={{ paddingLeft: "16px" }}>
                              <ClearAll></ClearAll>
                            </div>
                          </Flexbox>
                        </Flexbox>
                      </Flexbox>
                      <div
                        style={{
                          paddingLeft: "20px",
                          paddingTop: "8px",
                          paddingBottom: "4px",
                          gap: "8px",
                        }}
                      >
                        <CurrentRefinements />
                      </div>

                      <Flexbox flexDirection="row" style={{ height: "100%" }}>
                        <div
                          style={{
                            display: "flex",
                            minHeight: 0,
                            flexDirection: "column",
                            flex: 2,
                            margin: "10px",
                          }}
                        >
                          <Flexbox
                            flexDirection="column"
                            flexGrow={1}
                            style={{
                              minHeight: 0,
                              overflowY: "auto",
                              width: "100%",
                              borderStyle: "solid",
                              borderRadius: "5px",
                              borderWidth: "1px",
                              backgroundColor: "transparent",
                              borderColor: "transparent",
                            }}
                          >
                            <Hits
                              style={{ width: "100%" }}
                              hitComponent={Product}
                            />
                          </Flexbox>
                          <Flexbox
                            style={{ width: "100%", marginTop: "10px" }}
                            minHeight="50px"
                            padding="10px"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Pagination showLast={true} />
                          </Flexbox>
                        </div>
                      </Flexbox>
                    </MediaQuery>
                  </Flexbox>
                </Flexbox>
              </Flexbox>

              {previewModal}
            </Flexbox>
          </InstantSearch>
        </Loader>
      );
    }
  }

  componentDidMount() {
    this.context.tree
      .select(["appState", "navPanel"])
      .set("selected", "search");
  }
}

MiraCheckSearch.contextTypes = {
  tree: PropTypes.baobab,
};

export default branch(
  {
    user: ["user"],
    version: ["version"],
    genres: ["genres"],
    previewChecklist: ["appState", "previewChecklist"],
    loadingSpinner: ["appState", "loadingSpinner"],
    igapp: ["appCapabilities", "igapp"],
  },
  MiraCheckSearch
);
