import React from "react";
import PropTypes from "baobab-react/prop-types";
import { Button } from "react-bootstrap";
import Flexbox from "flexbox-react";
import autoBind from "react-autobind";

import Glyphicon from "../../components/app-icon";
import * as actions from "../../actions/actions";

class NameValuePairEditor extends React.PureComponent {
	constructor(props, context) {
		super(props, context);

		this.state = {
			name: "",
			value: "",
			properties: "",
			editedItems: -1
		};
		autoBind(this);
	}

	handleEditItem(idx, label, value, properties) {
		this.setState({
			editedItem: idx,
			[`label-${idx}`]: label,
			[`value-${idx}`]: value,
			[`properties-${idx}`]: properties
		});
	}

	handleCancelEdit(idx) {
		this.setState({
			editedItem: -1
		});
		
		delete this.state[`label-${idx}`];
		delete this.state[`value-${idx}`];
		delete this.state[`properties-${idx}`];
	}

	handleSaveItem(idx) {
		actions.updatePickerItem(idx, this.props.prop, this.state[`label-${idx}`], this.state[`value-${idx}`], this.state[`properties-${idx}`]);
		this.setState({
			editedItem: -1
		});
	}

	handleChangeName(idx, key, e) {
		let computedValue = e.target.value.toLowerCase();
		computedValue = computedValue.split(" ").join("-");

		this.setState({
			[`${key}-${idx}`]: e.target.value,
			[`value-${idx}`]: computedValue,
		});
	}

	handleChangeValue(idx, key, e) {
		this.setState({
			[`${key}-${idx}`]: e.target.value.replace(" ","")
		});
	}

	handleChangeProperties(idx, key, e) {
		this.setState({
			[`${key}-${idx}`]: e.target.value
		});
	}

	render() {
		let self = this;

		let items = this.props.items.map((item, i) => {
			const itemEdited = i === self.state.editedItem;
			let nameComponent = itemEdited ? <input autoFocus onChange={(e) => { self.handleChangeName(i, "label", e); }} id={`nameField-${i}`} type="text" value={this.state[`label-${i}`]}/> : item[self.props.nameField];
			let valueComponent = itemEdited ? <input onChange={(e) => { self.handleChangeValue(i, "value", e); }} id={`valueField-${i}`} type="text" value={this.state[`value-${i}`]} /> : item[self.props.valueField];
			let propertiesComponent = itemEdited ? <input onChange={(e) => { self.handleChangeProperties(i, "properties", e); }} id={`propertiesField-${i}`} type="text" value={this.state[`properties-${i}`]} /> : item[self.props.propertiesField];

			const buttonComponents = [];

			if (itemEdited) {
				buttonComponents.push(<Button style={{ marginRight: 2 }}  key={`save-button-${i}`} bsStyle="success" onClick={() => {
					self.handleSaveItem(i);
				}}>
					<Glyphicon glyph="check" />
				</Button>, <Button key={`cancel-button-${i}`} bsStyle="danger" onClick={() => {
					self.handleCancelEdit(i);
				}}>
					<Glyphicon glyph="times" />
				</Button>);
			} else {
				buttonComponents.push(
					<Button key={`edit-button-${i}`} style={{ marginRight: 10 }} onClick={() => {
						self.handleEditItem(i, item[self.props.nameField], item[self.props.valueField], item[self.props.propertiesField]);
					}}>
						<Glyphicon glyph="pencil" />
					</Button>,
					<Button key={`move-up-button-${i}`} disabled={i === 0} onClick={() => {
						actions.handleMovePickerItemUp(self.context.tree, self.props.prop, i);
					}}>
						<Glyphicon glyph="arrow-up" />
					</Button>,
					<Button key={`move-down-button-${i}`} disabled={i === self.props.items.length - 1} onClick={() => {
						actions.handleMovePickerItemDown(self.context.tree, self.props.prop, i);
					}}>
						<Glyphicon glyph="arrow-down" />
					</Button>,
					<Button key={`delete-button-${i}`} style={{ marginLeft: 10 }} onClick={() => {
						actions.handleDeletePickerItem(self.context.tree, self.props.prop, i);
					}}>
						<Glyphicon glyph="trash" />
					</Button>
				);
			}
			
			return (
				<Flexbox key={"row-" + i} flexDirection={self.props.layout === "vertical" ? "column" : "row"} alignItems="center" justifyContent="space-between" style={{ margin: 10, borderTop: "1px solid gray", padding: 10 }}>
					<Flexbox flexBasis="22%" style={{ margin: 5 }}>{nameComponent}</Flexbox>
					<Flexbox flexBasis="22%" style={{ margin: 5 }}>{valueComponent}</Flexbox>
					<Flexbox flexBasis="22%" style={{ margin: 5 }}>{propertiesComponent}</Flexbox>
					<Flexbox flexBasic="34%" style={{ margin: 5}}>
						{buttonComponents}
					</Flexbox>
				</Flexbox>
			);
		});

		let component =
            <Flexbox flexDirection="column">
            	<Flexbox flexDirection={self.props.layout === "vertical" ? "column" : "row"} alignItems="center" justifyContent="flex-start">
            		<Flexbox flexDirection="column" style={{ margin: 5 }}>
            			<div>Label</div>
            			<input type="text" value={this.state.name} onChange={(e) => {
            				self.setState({ name: e.target.value });
            				let computedValue = e.target.value.toLowerCase();
            				computedValue = computedValue.split(" ").join("-");							
            				self.setState({ value: computedValue });							
            			}} />
            		</Flexbox>
            		<Flexbox flexDirection="column" style={{ margin: 5 }}>
            			<div>Value</div>
            			<input type="text" value={this.state.value} onChange={(e) => {
            				self.setState({ value: e.target.value.replace(" ", "") });
            			}} />
            		</Flexbox>
            		<Flexbox flexDirection="column" style={{ margin: 5 }}>
            			<div>Properties (optional)</div>
            			<input type="text" value={this.state.properties} onChange={(e) => {
            				self.setState({ properties: e.target.value });
            			}} />
            		</Flexbox>
            		<Button style={{ margin: 5 }} onClick={() => {
            			if (self.state.name !== "" && self.state.value !== "") {
            				actions.handleAddPickerItem(this.context.tree, self.props.prop, self.state.name, self.state.value, self.state.properties);
            				self.setState({ name: "", value: "", properties: ""});
            			} else {
            				alert("The Label and Value fields must not be empty.");
            			}
            		}}>
                        Add <Glyphicon glyph="plus" />
            		</Button>
            	</Flexbox>
            	{items}
            </Flexbox>;

		return component;
	}
}


NameValuePairEditor.contextTypes = {
	tree: PropTypes.baobab
};

export default NameValuePairEditor;