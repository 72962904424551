import _ from "lodash";
import React from "react";
import marked from 'marked';

import isWebView from "is-webview";

import moment from "moment";

import FontAwesome from 'react-fontawesome';

import state from "../state/state";

export var scr = "";
export var saveScr = false;

export function pathToArray(path) {
	const arrPath = [];

	const pathParts = path.split(",");

	for (let i = 0; i < pathParts.length; i++) {
		const pathPart = pathParts[i];

		if (isNumeric(pathPart)) {
			arrPath.push(Number(pathPart));
		} else {
			arrPath.push(pathPart);
		}
	}

	return arrPath;
}

function isNumeric(str) {
	if (typeof str != "string") return false // we only process strings!  
	return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
		!isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export function zeroPrefix(value) {
	if (value < 10) {
		return "0" + value
	} else {
		return String(value);
	}
}

export function getUtcTimestamp() {
	// ISO 8601
	return moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSS');
}

export function getUtcTimestampForFilename() {
	// ISO 8601
	return moment.utc().format('YYYY-MM-DDTHH-mm-ss');
}

export function getUtcTimestampForChecklistName() {
	// ISO 8601
	return moment.utc().format('YYYY-MM-DDTHH:mm:ss');
}


export function isNull(value) {
	return value == null || value == "undefined";
}

export function setScr(scr) {
	scr = scr;
}

export function setSaveScr(saveScr) {
	saveScr = saveScr;
}

function hasElement(arr, type, key) {
	for (var i=0; i<arr.length; i++) {
		var item = arr[i];
		if (item[type] === key) {
			return item;
		}
	}

	return null;
}

export function documentsHasHidden(tree) {
	var documents = tree.get(["documents"]);
	for (var i=0; i<documents.length; i++) {
		var document = documents[i];
		if (!document.visible) {
			return true;
		}
	}

	return false;
}

export function setDocuments(state, documents) {
	var oldGenres = state.get(["appState","filters","all","genres"]);
	var oldTags = state.get(["appState","filters","all","tags"]);

	// Build data for genres and tags for filtering
	var allGenres = [];
	var allTags = [];
	for (var i=0; i<documents.length; i++) {
		var document = documents[i];

		var showVisible = state.get(["appState","filters","showVisible"]);

		var inc = 1;
		if (!showVisible && !document.visible) {
			inc = 0;
		}

		var genre = document.genre;
		if (genre != "") {
			var genreItem = hasElement(allGenres,"genre",genre);
			if (genreItem != null) {
				// Find index
				for (var j=0; j<allGenres.length; j++) {
					if (allGenres[j].genre === genre) {
						var oldElement = hasElement(oldGenres,"genre",genre);
						var newGenreItem = {
							genre: genre,
							count: genreItem.count + inc,
							checked: oldElement==null?false:oldElement.checked
						}

						allGenres.splice(j,1,newGenreItem);
					}
				}
			} else {
				var oldElement = hasElement(oldGenres,"genre",genre);

				allGenres.push({genre: genre, count: inc, checked: oldElement==null?false:oldElement.checked});
			}

			for (var j=0; j<document.tags.length; j++) {
				var tag = document.tags[j];

				var tagItem = hasElement(allTags,"tag",tag);
				if (tagItem != null) {
					// Find index
					for (var k=0; k<allTags.length; k++) {
						if (allTags[k].tag === tag) {	
							var oldTag = hasElement(oldTags,"tag",tag);
							var newTagItem = {
								tag: tag,
								count: tagItem.count + inc,
								checked: oldTag==null?false:oldTag.checked
							}
							allTags.splice(k,1,newTagItem);
						}
					}
				} else {
					var oldTag = hasElement(oldTags,"tag",tag);

					allTags.push({tag: tag, count: inc, checked: oldTag==null?false:oldTag.checked});
				}
			}
		}
	}

	state.set(["appState","filters","all","genres"],allGenres);
	state.set(["appState","filters","all","tags"],allTags);

	state.set(["documents"],documents);
}

export function cleanTimerTemplates(timerTemplates) {
	let newTimerTemplates = [];
	for (let timerTemplate of timerTemplates) {
		if (!timerTemplate.hasOwnProperty("deleted") && !timerTemplate.deleted) {
			newTimerTemplates.push(timerTemplate);
		}
	}

	return newTimerTemplates;
}

export function syncTimerTemplates(clientTimerTemplates, serverTimerTemplates) {
	var timerTemplates = [];

	clientTimerTemplates = cleanTimerTemplates(clientTimerTemplates);
	serverTimerTemplates = cleanTimerTemplates(serverTimerTemplates);

	for (var i=0; i<clientTimerTemplates.length; i++) {
		var timerTemplate = clientTimerTemplates[i];

		var foundTemplate = _.find(serverTimerTemplates, function(o) { return o.guid === timerTemplate.guid; });

		// Not found, so we want to add it	
		if (!foundTemplate) {
			timerTemplates.push(timerTemplate);
		// Found, so we want to pick the correct one
		} else {
			var timerTemplateTimestamp = moment(timerTemplate.timestamp);
			var foundTemplateTimestamp = moment(foundTemplate.timestamp);
			if (timerTemplateTimestamp < foundTemplateTimestamp) {
				timerTemplates.push(foundTemplate);
			} else {
				timerTemplates.push(timerTemplate);
			}
		}
	}

	for (var i=0; i<serverTimerTemplates.length; i++) {
		var timerTemplate = serverTimerTemplates[i];

		var foundTemplate = _.find(clientTimerTemplates, function(o) { return o.guid === timerTemplate.guid; });

		// Not found, so we want to add it	
		if (!foundTemplate) {
			timerTemplates.push(timerTemplate);
		}
	}

	return timerTemplates;
}

function hasFilter(state) {
	var genres = state.get(["appState","filters","all","genres"]);
	var tags = state.get(["appState","filters","all","tags"]);

	for (var i=0; i<genres.length; i++) {
		var genre = genres[i];
		if (genre.checked) {
			return true;
		}
	}

	for (var i=0; i<tags.length; i++) {
		var tag = tags[i];
		if (tag.checked) {
			return true;
		}
	}
}

export function clearFilters(state,documents) {
	var genres = state.get(["appState","filters","all","genres"]);
	var tags = state.get(["appState","filters","all","tags"]);

	for (var i=0; i<documents.length; i++) {
		var document = documents[i];
		if (document.hasOwnProperty("filterVisible")) {
			delete document.filterVisible;
		}
	}
}

export function refreshDocuments(state, documents) {
	clearFilters(state, documents);

	if (!hasFilter(state)) {
		return;
	}


	var newDocuments = [];

	var genres = state.get(["appState","filters","all","genres"]);
	var tags = state.get(["appState","filters","all","tags"]);

	// Filter out only ones selected
	for (var i=0; i<documents.length; i++) {
		var document = documents[i];

		for (var j=0; j<genres.length; j++) {
			var genre = genres[j];
			if (document.genre === genre.genre && genre.checked) {
				document.filterVisible = true;
			} else {
				if (document.hasOwnProperty("filterVisible") && document.filterVisible) {
					document.filterVisible = true;
				} else {
					if (!document.newDoc) {
						document.filterVisible = false;
					}
				}
			}
		}

		for (var j=0; j<document.tags.length; j++) {
			var documentTag = document.tags[j];

			for (var k=0; k<tags.length; k++) {
				var tag = tags[k];
				if (documentTag === tag.tag && tag.checked) {
					document.filterVisible = true;
				} else {
					if (document.hasOwnProperty("filterVisible") && document.filterVisible) {
						document.filterVisible = true;
					} else {
						if (!document.newDoc) {
							document.filterVisible = false;
						}
					}
				}
			}
		}
	}
	
	state.set(["documents"],documents);
}

export function generateUUID() {
	var d = new Date().getTime();
	var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		var r = (d + Math.random()*16)%16 | 0;
		d = Math.floor(d/16);
		return (c=='x' ? r : (r&0x3|0x8)).toString(16);
	});
	return uuid;
};

export function getKeyPrefix(identityId) {
	var keyPrefix;

	const igapp = state.get(["appCapabilities","igapp"]);

	if (igapp) {
		keyPrefix = state.get(["user","identityId"]);
	} else {
		if (identityId.indexOf(":") >= 0) {
			keyPrefix = identityId.split(":")[1];
		} else {
			keyPrefix = identityId;
		}
	
		// keyPrefix = "c8507060-52a1-47da-b8f2-6165ee0f207b";	
	}
	

    return keyPrefix;
}

export function postMessage(message, embed = false) {
	let success = false;

	if (window.ReactNativeWebView) {
		try {
			// console.log("About to post message", message, embed ? "*" : window.location.href);
			// const target = embed ? parent : window; //eslint-disable-line
			// target.ReactNativeWebView.postMessage(JSON.stringify(message), embed ? "*" : window.location.href);
			window.ReactNativeWebView.postMessage(JSON.stringify(message));
			success = true;
		} catch (e) {
			console.error(e);
		}
	} else if (window.postMessage) {
		try {
			console.log("About to post message", message, embed ? "*" : window.location.href);
			const target = embed ? parent : window; //eslint-disable-line
			target.postMessage(JSON.stringify(message), embed ? "*" : window.location.href);
			success = true;
		} catch (e) {
			console.error(e);
		}
	} else if (window.webView && window.webView.postMessage) {
		try {
			window.webView.postMessage(JSON.stringify(message));
			success = true;
		} catch (e) {
			console.error(e);
		}
	}

	return success;
}

export function openUrl(e, url) {
	if (isMobile()) {
		e.preventDefault();
		postMessage({
			external_url_open: url
		});
	} else {
		handleOpenInNewTab(url);
	}
}

export function handleOpenInNewTab(url) {
	let win = window.open(url, "_blank");
	win.focus();
}

export function isMobile() {
	if (window.ReactNativeWebView) {
		return true;
	} else {
		return isWebView(navigator.userAgent);
	}
/*
	var mobile = false;
	
	try {
		window.webkit.messageHandlers.reactNative.postMessage(JSON.stringify({method: "dummy"}));
		mobile = true;
	} catch(e) {
	}
	// UIWebView
	try {
		window.postMessage(JSON.stringify({method: "dummy"}));
		mobile = true;
	} catch(e) {
	}

	return mobile;
*/	
}

export function showLoader(tree,key="loading") {
	tree.set(["appState",key],true);
}

export function hideLoader(tree,key="loading") {
	tree.set(["appState",key],false);
}

function isChecklistOfType(checklist,type) {
	if (checklist.children.length  > 0 && checklist.children[0].type.startsWith(type)) {
		return true;
	} else {
		return false;
	}
}

export function isChecklistLists(checklist) {
	return isChecklistOfType(checklist,"list");
}

export function isChecklistSections(checklist) {
	return isChecklistOfType(checklist,"section");
}

export function isChecklistItems(checklist) {
	return isChecklistOfType(checklist,"item");
}

function normalizeChecklistFromSections(checklist) {
	var newChecklist = _.cloneDeep(checklist);
	
	var list = {
		"type": "list",
		"completionState": "n",
		"name": "Sections",
		"defaultView": "checklistView",
		"startInBirdseyeView": false,
		"ignoreCompletion": false,
		"hideStatus": false,
		"color": "black",
		"horzScrollPos": 0,
		"vertScrollPos": 0,
		"children": _.cloneDeep(checklist.children)		
	};

	newChecklist.children = [];
	newChecklist.children.push(list);

	return newChecklist;
}

function normalizeChecklistFromItems(checklist) {
	var newChecklist = _.cloneDeep(checklist);
	
	// Don't allow tracking start and stop
	for (var i=0; i<checklist.children.length; i++) {
		var item = checklist.children[i];

		if (item.hasOwnProperty("startTracking") && item.hasOwnProperty("stopTracking")) {
			if (item.startTracking === item.stopTracking) {
				item.startTracking = false;
				item.stopTracking = false;	
			}
		} else {
			item.startTracking = false;
			item.stopTracking = false;
		}
	}
	
	var list = {
		"type": "list",
		"completionState": "n",
		"name": "Sections",
		"defaultView": "checklistView",
		"startInBirdseyeView": false,
		"ignoreCompletion": false,
		"hideStatus": false,
		"color": "black",
		"horzScrollPos": 0,
		"vertScrollPos": 0,
		"children": [
			{
				"type": "section",
				"completionState": "n",
				"name": "Items",
				"vertScrollPos": 0,
				"children": _.cloneDeep(checklist.children)
			}
		]
	};

	newChecklist.children = [];
	newChecklist.children.push(list);

	return newChecklist;
}

export function normalizeChecklist(checklist) {
	if (isChecklistLists(checklist)) {
		checklist.firstLevel = "lists";
		return checklist;
	} else if (isChecklistSections(checklist)) {
		checklist.firstLevel = "sections";
		return normalizeChecklistFromSections(checklist);
	} else if (isChecklistItems(checklist)) {
		checklist.firstLevel = "items";
		return normalizeChecklistFromItems(checklist);
	} else {
		checklist.firstLevel = "null";
		return checklist;
	}
}

function  speed(x,type) {
	var stripStartSquare = x.substring(7);
	var stripEndSquare = stripStartSquare.substring(0,stripStartSquare.length-1);
	var speeds = stripEndSquare.split(";");

	if (type == "MPH") {
		return speeds[1] + " MPH";
	} else {
		return speeds[0] + " KIAS";
	}
}

export function recurseReplaceSpeeds(arr) {
  for (var i=0; i<arr.length; i++) {
    if (arr[i].type == "list") {
    } else if (arr[i].type == "section") {
    } else if (arr[i].type == "item") {
	    arr[i].label1 = arr[i].label1.replace(/\[speed [^]+;[^]+\]/g, function myFunction(x){return speed(x,"KIAS");});
		arr[i].label2 = arr[i].label2.replace(/\[speed [^]+;[^]+\]/g, function myFunction(x){return speed(x,"KIAS");});
		if (arr[i].hasOwnProperty("label3")) {
			arr[i].label3 = arr[i].label3.replace(/\[speed [^]+;[^]+\]/g, function myFunction(x){return speed(x,"KIAS");});
		} else {
			arr[i].label3 = "";
		}
    }

	if(arr[i].hasOwnProperty("children")) {
		recurseReplaceSpeeds(arr[i].children);
	}
  }
}

export function recurseToFlatArray(arr,lastList,lastSection,resArr) {
  for (var i=0; i<arr.length; i++) {
    if (arr[i].type == "list") {
		lastList = {
			list: arr[i].name
		}
    } else if (arr[i].type == "section") {
		lastSection = {
			section: arr[i].name
		}
    } else if (arr[i].type == "item") {
	    var node = {
			list: lastList==""?"":lastList.list,
			section: lastSection==""?"":lastSection.section,
			label1: arr[i].label1,
			label2: arr[i].label2,
			label3: arr[i].hasOwnProperty("label3") ? arr[i].label3 : "",
			labelOnly: arr[i].labelOnly,
			labelOnlyBackgroundColor: arr[i].labelOnlyBackgroundColor,
			mandatory: arr[i].mandatory
		};

		var arrNode = [];
		if (lastList !== "") {
			arrNode.push("\"" + lastList.list.replace(/\"/g,"\"\"") + "\"");
		} else {
			arrNode.push("\"" + "Sections" + "\"");
		}
		if (lastSection !== "") {
			arrNode.push("\"" + lastSection.section.replace(/\"/g,"\"\"") + "\"");
		} else {
			arrNode.push("\"" + "Items" + "\"");
		}
		arrNode.push("\"" + arr[i].label1.replace(/\"/g,"\"\"") + "\"");
		arrNode.push("\"" + arr[i].label2.replace(/\"/g,"\"\"") + "\"");
		arrNode.push("\"" + arr[i].hasOwnProperty("label3") ? arr[i].label2.replace(/\"/g,"\"\"") : "" + "\"");
		arrNode.push(arr[i].labelOnly);
		arrNode.push(arr[i].labelOnlyBackgroundColor);
		arrNode.push(arr[i].mandatory);

		resArr.push(arrNode);
    }

	if(arr[i].hasOwnProperty("children")) {
		recurseToFlatArray(arr[i].children,lastList,lastSection,resArr);
	}
  }
}

export function recurseToFlatArrayAdvanced(checklist,arr,lastList,lastSection,resArr) {
  for (var i=0; i<arr.length; i++) {
    if (arr[i].type == "list") {
		lastList = {
			id: arr[i].hasOwnProperty("id")?arr[i].id:"",
			list: arr[i].name,
			listAudio: arr[i].hasOwnProperty("nameAudio")?arr[i].nameAudio:"",
			listPrint: arr[i].hasOwnProperty("namePrint")?arr[i].namePrint:"",
			defaultView: arr[i].hasOwnProperty("defaultView")?arr[i].defaultView:"checklistView",
			color: arr[i].hasOwnProperty("color")?arr[i].color:"",
			ignoreCompletion: arr[i].ignoreCompletion,
			dontPrint: arr[i].suppressPrint
		}
    } else if (arr[i].type == "section") {
		lastSection = {
			id: arr[i].hasOwnProperty("id")?arr[i].id:"",
			section: arr[i].name,
			sectionAudio: arr[i].hasOwnProperty("nameAudio")?arr[i].nameAudio:"",
			sectionPrint: arr[i].hasOwnProperty("namePrint")?arr[i].namePrint:"",
			color: arr[i].hasOwnProperty("color")?arr[i].color:"",
			iconColor: arr[i].hasOwnProperty("iconColor")?arr[i].iconColor:"",
			backgroundColor: arr[i].hasOwnProperty("backgroundColor")?arr[i].backgroundColor:"",
			borderColor: arr[i].hasOwnProperty("borderColor")?arr[i].borderColor:"",
			ignoreCompletion: arr[i].ignoreCompletion,
			dontPrint: arr[i].suppressPrint
		}
    } else if (arr[i].type.startsWith("item")) {
	    var node = {
			list: lastList==""?"":lastList.list,
			section: lastSection==""?"":lastSection.section,
			id: arr[i].hasOwnProperty("id")?arr[i].id:"",
			type: arr[i].hasOwnProperty("type")?arr[i].type:"",
			label1: arr[i].label1,
			label2: arr[i].label2,
			label3: arr[i].hasOwnProperty("label3") ? arr[i].label3 : "",
			comments: arr[i].comments,
			commentsInline: arr[i].hasOwnProperty("commentsInline")?arr[i].commentsInline:"",
			commentsDontPrint: arr[i].suppressPrintComments?arr[i].suppressPrintComments:"FALSE",
			mandatory: arr[i].mandatory,
			ignoreCompletion: arr[i].ignoreCompletion,
			labelOnly: arr[i].labelOnly,
			labelOnlyBackgroundColor: arr[i].labelOnlyBackgroundColor,
			label1Color: arr[i].hasOwnProperty("color")?arr[i].color:"",
			label1Audio: arr[i].hasOwnProperty("label1Audio")?arr[i].label1Audio:"",
			label1Print: arr[i].hasOwnProperty("label1Print")?arr[i].label1Print:"",
			label2Color: arr[i].hasOwnProperty("label2Color")?arr[i].label2Color:"",
			label2Audio: arr[i].hasOwnProperty("label2Audio")?arr[i].label2Audio:"",
			label2Print: arr[i].hasOwnProperty("label2Print")?arr[i].label2Print:"",
			label3Color: arr[i].hasOwnProperty("label3Color")?arr[i].label3Color:"",
			label3Audio: arr[i].hasOwnProperty("label3Audio")?arr[i].label3Audio:"",
			label3Print: arr[i].hasOwnProperty("label3Print")?arr[i].label3Print:"",

			textInputPlaceholder: arr[i].hasOwnProperty("textInputPlaceholder")?arr[i].textInputPlaceholder:"",
			textInputDefaultValue: arr[i].hasOwnProperty("textInputDefaultValue")?arr[i].textInputDefaultValue:"",
			textInputMaxLength: arr[i].hasOwnProperty("textInputMaxLength")?arr[i].textInputMaxLength:"",
			textInputKeyboardType: arr[i].hasOwnProperty("textInputKeyboardType")?arr[i].textInputKeyboardType:"",
			textInputKeyboardAutoCapitalize: arr[i].hasOwnProperty("textInputKeyboardAutoCapitalize")?arr[i].textInputKeyboardAutoCapitalize:"",
			textInputKeyboardAutoCorrect: arr[i].textInputKeyboardAutoCorrect,
			textInputKeyboardReturnKeyType: arr[i].hasOwnProperty("textInputKeyboardReturnKeyType")?arr[i].textInputKeyboardReturnKeyType:"",
			textInputMaskType: arr[i].hasOwnProperty("textInputMaskType")?arr[i].textInputMaskType:"",
			textInputCurrencySymbol: arr[i].hasOwnProperty("textInputCurrencySymbol")?arr[i].textInputCurrencySymbol:"",
			textInputCurrencySeparator: arr[i].hasOwnProperty("textInputCurrencySeparator")?arr[i].textInputCurrencySeparator:"",
			textInputNumberOfLines: arr[i].hasOwnProperty("textInputNumberOfLines")?arr[i].textInputNumberOfLines:"",
			
			pickerItemViewType: arr[i].hasOwnProperty("pickerItemViewType")?arr[i].pickerItemViewType:"",
			pickerItemPlaceholder: arr[i].hasOwnProperty("pickerItemPlaceholder")?arr[i].pickerItemPlaceholder:"",
			pickerItemDefaultValue: arr[i].hasOwnProperty("pickerItemDefaultValue")?arr[i].pickerItemDefaultValue:"",
			pickerItems: arr[i].hasOwnProperty("pickerItems")?arr[i].pickerItems:"",
			pickerAdvanceOnSelect: arr[i].pickerAdvanceOnSelect,
			pickerLinkOnSelect: arr[i].pickerLinkOnSelect,
			pickerLinkActionType: arr[i].hasOwnProperty("pickerLinkActionType")?arr[i].pickerLinkActionType:"",
			
			yesNoLinkOnSelect: arr[i].yesNoLinkOnSelect,
			yesNoLinkActionType: arr[i].hasOwnProperty("yesNoLinkActionType")?arr[i].yesNoLinkActionType:"",
			yesNoYesLinkId: arr[i].hasOwnProperty("yesNoYesLinkId")?arr[i].yesNoYesLinkId:"",
			yesNoNoLinkId: arr[i].hasOwnProperty("yesNoNoLinkId")?arr[i].yesNoNoLinkId:"",

			dateTimeType: arr[i].hasOwnProperty("dateTimeType")?arr[i].dateTimeType:"",
			dateTimeInitialDate: arr[i].hasOwnProperty("dateTimeInitialDate")?arr[i].dateTimeInitialDate:"",
			dateTimeMinuteInterval: arr[i].hasOwnProperty("dateTimeMinuteInterval")?arr[i].dateTimeMinuteInterval:"",

			imagePickerAddMediaButton: arr[i].hasOwnProperty("imagePickerAddMediaButton")?arr[i].imagePickerAddMediaButton:"",
			imagePickerUploadTitle: arr[i].hasOwnProperty("imagePickerUploadTitle")?arr[i].imagePickerUploadTitle:"",
			imagePickerCaptureMediaTitle: arr[i].hasOwnProperty("imagePickerCaptureMediaTitle")?arr[i].imagePickerCaptureMediaTitle:"",

			sketchPadBackgroundColor: arr[i].hasOwnProperty("sketchPadBackgroundColor")?arr[i].sketchPadBackgroundColor:"",
			sketchPadPenColor: arr[i].hasOwnProperty("sketchPadPenColor")?arr[i].sketchPadPenColor:"",
			sketchPadPenWidth: arr[i].hasOwnProperty("sketchPadPenWidth")?arr[i].sketchPadPenWidth:"",

			dontPrint: arr[i].suppressPrint,
			startTimer: arr[i].startTimer,
			stopTimer: arr[i].stopTimer,

			linkOnCheck: arr[i].linkOnCheck,
			linkId: arr[i].hasOwnProperty("linkId")?arr[i].linkId:"",
			linkActionType: arr[i].hasOwnProperty("linkActionType")?arr[i].linkActionType:""
		};

		var arrNode = [];

		var tags = JSON.stringify(checklist.tags);

		arrNode.push(formatQuotes((checklist.hasOwnProperty("checklistId")?checklist.checklistId:"")));
		arrNode.push(formatQuotes(checklist.name));
		arrNode.push(formatQuotes(checklist.nameAudio));
		arrNode.push(formatQuotes(checklist.namePrint));
		arrNode.push(formatQuotes(checklist.description));
		arrNode.push(checklist.itemsOnSingleLine);
		arrNode.push(checklist.suppressAudio);
		arrNode.push(checklist.trackLocation);
		arrNode.push(formatQuotes(checklist.genre));
		arrNode.push(tags);

		if (lastList !== "") {
			arrNode.push(formatQuotes(lastList.id));
			arrNode.push(formatQuotes(lastList.list));
			arrNode.push(formatQuotes(lastList.listAudio));
			arrNode.push(formatQuotes(lastList.listPrint));
			arrNode.push(lastList.defaultView);
			arrNode.push(lastList.color);
			arrNode.push(lastList.ignoreCompletion);
			arrNode.push(lastList.dontPrint);
		} else {
			arrNode.push("");
			arrNode.push(formatQuotes("Sections"));
			arrNode.push("");
			arrNode.push("");
			arrNode.push("");
			arrNode.push("");
			arrNode.push("");
			arrNode.push("");
		}
		

		if (lastSection !== "") {
			arrNode.push(formatQuotes(lastSection.id));
			arrNode.push(formatQuotes(lastSection.section));
			arrNode.push(formatQuotes(lastSection.sectionAudio));
			arrNode.push(formatQuotes(lastSection.sectionPrint));
			arrNode.push(lastSection.color);
			arrNode.push(lastSection.iconColor);
			arrNode.push(lastSection.backgroundColor);
			arrNode.push(lastSection.borderColor);
			arrNode.push(lastSection.ignoreCompletion);
			arrNode.push(lastSection.dontPrint);
		} else {
			arrNode.push("");
			arrNode.push(formatQuotes("Items"));
			arrNode.push("");
			arrNode.push("");
			arrNode.push("");
			arrNode.push("");
			arrNode.push("");
			arrNode.push("");
			arrNode.push("");
			arrNode.push("");
		}

		arrNode.push(formatQuotes(node.id));
		arrNode.push(formatQuotes(node.type));
		arrNode.push(formatQuotes(node.label1));
		arrNode.push(formatQuotes(node.label2));
		arrNode.push(formatQuotes(node.label3));
		arrNode.push(formatQuotes(node.comments));
		arrNode.push(node.commentsInline);
		arrNode.push(node.commentsDontPrint);
		arrNode.push(node.mandatory);
		arrNode.push(node.ignoreCompletion);
		arrNode.push(node.labelOnly);
		arrNode.push(node.labelOnlyBackgroundColor);
		arrNode.push(node.label1Color);
		arrNode.push(formatQuotes(node.label1Audio));
		arrNode.push(formatQuotes(node.label1Print));
		arrNode.push(node.label2Color);
		arrNode.push(formatQuotes(node.label2Audio));
		arrNode.push(formatQuotes(node.label2Print));
		arrNode.push(node.label3Color);
		arrNode.push(formatQuotes(node.label3Audio));
		arrNode.push(formatQuotes(node.label3Print));

		arrNode.push(formatQuotes(node.textInputPlaceholder));
		arrNode.push(formatQuotes(node.textInputDefaultValue));
		arrNode.push(node.textInputMaxLength);
		arrNode.push(formatQuotes(node.textInputKeyboardType));
		arrNode.push(formatQuotes(node.textInputKeyboardAutoCapitalize));
		arrNode.push(node.textInputKeyboardAutoCorrect);
		arrNode.push(formatQuotes(node.textInputKeyboardReturnKeyType));
		arrNode.push(formatQuotes(node.textInputMaskType));
		arrNode.push(formatQuotes(node.textInputCurrencySymbol));
		arrNode.push(formatQuotes(node.textInputCurrencySeparator));
		arrNode.push(node.textInputNumberOfLines);

		arrNode.push(formatQuotes(node.pickerItemViewType));
		arrNode.push(formatQuotes(node.pickerItemPlaceholder));
		arrNode.push(formatQuotes(node.pickerItemDefaultValue));
		arrNode.push(formatQuotesForObject(node.pickerItems));
		arrNode.push(node.pickerAdvanceOnSelect);
		arrNode.push(node.pickerLinkOnSelect);
		arrNode.push(formatQuotes(node.pickerLinkActionType));

		arrNode.push(node.yesNoLinkOnSelect);
		arrNode.push(formatQuotes(node.yesNoLinkActionType));
		arrNode.push(formatQuotes(node.yesNoYesLinkId));
		arrNode.push(formatQuotes(node.yesNoNoLinkId));

		arrNode.push(formatQuotes(node.dateTimeType));
		arrNode.push(formatQuotes(node.dateTimeInitialDate));
		arrNode.push(node.dateTimeMinuteInterval);

		arrNode.push(formatQuotes(node.imagePickerAddMediaButton));
		arrNode.push(formatQuotes(node.imagePickerUploadTitle));
		arrNode.push(formatQuotes(node.imagePickerCaptureMediaTitle));

		arrNode.push(node.sketchPadBackgroundColor);
		arrNode.push(node.sketchPadPenColor);
		arrNode.push(node.sketchPadPenWidth);

		arrNode.push(node.dontPrint);
		arrNode.push(node.startTimer);
		arrNode.push(node.stopTimer);
		
		arrNode.push(node.linkOnCheck);
		arrNode.push(formatQuotes(node.linkId));
		arrNode.push(formatQuotes(node.linkActionType));

		resArr.push(arrNode);
    }

	if(arr[i].hasOwnProperty("children")) {
		recurseToFlatArrayAdvanced(checklist,arr[i].children,lastList,lastSection,resArr);
	}
  }
}

function formatQuotes(str) {
	return "\"" + str.replace(/\"/g,"\"\"") + "\""
}

function formatQuotesForObject(obj) {
	if (_.isEmpty(obj)) {
		return "";
	} else {
		return "\"" + JSON.stringify(obj).replace(/\"/g,"\"\"") + "\""
	}
}

export function createBlobCsv(str, exportFilename) {
	var csvData = new Blob([str], {type: 'text/csv;charset=utf-8;'});
	var csvURL =  null;
	if (navigator.msSaveBlob) {
		csvURL = navigator.msSaveBlob(csvData, exportFilename);
	} else {
		csvURL = window.URL.createObjectURL(csvData);
	}
	var tempLink = document.createElement('a');
	tempLink.href = csvURL;
	tempLink.setAttribute('download', exportFilename);
	tempLink.click();	
}

export function createBlobCsv2(csvData, exportFilename) {
	var csvData = new Blob([csvData]);
	//IE11 & Edge
	if (navigator.msSaveBlob) {
		navigator.msSaveBlob(csvData, exportFilename);
	} else {
		//In FF link must be added to DOM to be clicked
		var link = document.createElement('a');
		link.href = window.URL.createObjectURL(csvData, {type: 'text/csv;charset=utf-8;'});
		link.setAttribute('download', exportFilename);
		document.body.appendChild(link);    
		link.click();
		document.body.removeChild(link);    
	}
}

export function createMarkupForLabel(label) {
	let myRenderer = new marked.Renderer();
	myRenderer.link = function (href, title, text) {
		let external, newWindow, out;
		external = /^https?:\/\/.+$/.test(href);
		newWindow = external || title === "newWindow";
		out = "<a href=\"javascript:void(0)\"";	
		if (newWindow) {
			out += " target=\"_blank\"";
		}
		if (title && title !== "newWindow") {
			out += " title=\"" + title + "\"";
		}
		return out += ">" + text + "</a>";
	};

	myRenderer.image = function (href, title, text) {
		return "<img style=\"max-width: 100%\" src=\"" + href + "\" /><br/><div>" + text + "</div>";
	};

	marked.setOptions({
		renderer: myRenderer,
		gfm: true,
		tables: true,
		breaks: true,
		pedantic: false,
		sanitize: false,
		smartLists: true,
		smartypants: false
	});

	const markedText = "<span><span>" + marked(label) + "</span></span>";

	return { __html: markedText };
}

export function createMarkup(label,columns,removeP) {
	var myRenderer = new marked.Renderer();

	var width = "240";
	var height = "180";

	if (columns) {
		width = "120";
		height= "90";
	}

	myRenderer.link = function(href, title, text) {
		var external, newWindow, out;
		external = /^https?:\/\/.+$/.test(href);
		newWindow = external || title === 'newWindow';
		out = "<a href=\"" + href + "\"";
		if (newWindow) {
			out += ' target="_blank"';
		}
		if (title && title !== 'newWindow') {
			out += " title=\"" + title + "\"";
		}
		return out += ">" + text + "</a>";
	};

	myRenderer.image = function(href, title, text) {
		return "<img src=\"" + href + "\" width=\"" + width + "\" height=\"" + height + "\" /><br/><div>" + text + "</div>";
	};

	if (removeP) {
		myRenderer.paragraph = function(text) {
			return text;
	   };   
	}

	marked.setOptions({
		renderer: myRenderer,
		gfm: true,
		tables: true,
		breaks: true,
		pedantic: false,
		sanitize: false,
		smartLists: true,
		smartypants: false
	});

	return marked(label);
}


export function createMarkupForComments(comments) {
	var myRenderer = new marked.Renderer();
	myRenderer.link = function(href, title, text) {
		var external, newWindow, out;
		external = /^https?:\/\/.+$/.test(href);
		newWindow = external || title === 'newWindow';
		out = "<a href=\"" + href + "\"";
		if (newWindow) {
			out += ' target="_blank"';
		}
		if (title && title !== 'newWindow') {
			out += " title=\"" + title + "\"";
		}
		return out += ">" + text + "</a>";
	};

	myRenderer.image = function(href, title, text) {
		return "<img style=\"max-width: 100%\" src=\"" + href + "\" /><br/><div>" + text + "</div>";
	};

	// myRenderer.paragraph = function(text) {
	// 	return text;
	// };

	marked.setOptions({
		renderer: myRenderer,
		gfm: true,
		tables: true,
		breaks: true,
		pedantic: false,
		sanitize: false,
		smartLists: true,
		smartypants: false
	});

	return marked(comments);
}

export function recurseConvertChecklistToMarkdown(arr,columns) {
  for (var i=0; i<arr.length; i++) {
    if (arr[i].type == "list") {
    } else if (arr[i].type == "section") {
    } else if (arr[i].type == "item") {
	    arr[i].label1 = createMarkup(arr[i].label1,columns,true);
    	arr[i].label2 = createMarkup(arr[i].label2,columns,true);
    	arr[i].label3 = arr[i].hasOwnProperty("label3") ? createMarkup(arr[i].label3,columns,true) : "";
    }

	if(arr[i].hasOwnProperty("children")) {
		recurseConvertChecklistToMarkdown(arr[i].children,columns);
	}
  }
}

export function recurseExtractNotes(arr,notes,lastListIndex,lastSectionIndex) {
	for (var i=0; i<arr.length; i++) {
	  if (arr[i].type == "list") {
		  lastListIndex = i;
	  } else if (arr[i].type == "section") {
		  lastSectionIndex = i;
	  } else if (arr[i].type == "item") {
		  if (arr[i].hasOwnProperty("notes")) {
			if (arr[i].notes != "") {
				notes.push(
					{
						listIndex: lastListIndex,
						sectionIndex: lastSectionIndex,
						itemIndex: i,
						label1: arr[i].label1,
						label2: arr[i].label2,
						label3: arr[i].hasOwnProperty("label3") ? arr[i].label3 : "",
						notes: arr[i].notes
					}
				);
			}
		  }
	  }
  
	  if(arr[i].hasOwnProperty("children")) {
		  recurseExtractNotes(arr[i].children,notes,lastListIndex,lastSectionIndex);
	  }
	}
  }
  
  export function getIconForType(type) {
	  var icon = "";
	if (type === "item") {
		icon = "check";
	} else if (type === "itemTextInput" || type === "itemTextInputMultiline") {
		icon = "font";
	} else if (type === "itemDate" || type === "itemTime" || type === "itemDateTime") {
		icon = "calendar";
	} else if (type === "itemWeather") {
		icon = "cloud";
	} else if (type === "itemAddress" || type === "itemLocation") {
		icon = "location-arrow";
	} else if (type === "itemYesNo") {
		icon = "thumbs-up";
	} else if (type === "itemPicker") {
		icon = "list";
	} else if (type === "itemBarcodeScanner") {
		icon = "barcode";
	} else if (type === "itemImagePicker") {
		icon = "file-image-o";
	} else if (type === "itemSketchPad") {
		icon = "pencil-square-o";
	} 

	return icon;
  }

  export function getGenreIconForType(tree, type) {
	var genres = tree.get(["genres"]);

	for (var i=0; i<genres.length; i++) {
		var genre = genres[i];
		if (type == genre.value) {
			return genre.icon;
		}
	} 
  }

  export function getAdditionalInfoComponent(entity) {
	let additionalInfo = null;
	let additionalInfoComponents = [];

	if ((entity.label1Audio && !_.isEmpty(entity.label1Audio)) || (entity.label2Audio && !_.isEmpty(entity.label2Audio)) || (entity.label3Audio && !_.isEmpty(entity.label3Audio)) || (entity.commentsAudio && !_.isEmpty(entity.commentsAudio)) || (entity.labelAudio && !_.isEmpty(entity.labelAudio))) {
		additionalInfoComponents.push(<div className="audio-override-info" key={`audio-override-view-${entity.id}`} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}><b>Narration Override</b></div>);
	}

	if (entity.id && !_.isEmpty(entity.id)) {
		additionalInfoComponents.push(<div className="id-info" key={`id-view-${entity.id}`} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }} title={`ID: ${entity.id} (Can be used for linking)`}><b>ID:</b>&nbsp;{entity.id}</div>);
	}

	if (entity.valueTrigger && !_.isEmpty(entity.valueTrigger)) {
		additionalInfoComponents.push(<div className="value-trigger-info" key={`value-trigger-view-${entity.id}`} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }} title={`Value Trigger`}><b>Value Trigger</b></div>);
	}

	if (entity.groupNames && !_.isEmpty(entity.groupNames)) {
		const groupsNames = _.join(entity.groupNames, ", ");
		additionalInfoComponents.push(<div className="group-info" key={`group-view-${entity.id}`} title={`Member of groups: ${groupsNames}`}><b>Groups:</b>&nbsp;{groupsNames}</div>);
	}

	if (entity.filterTags && !_.isEmpty(entity.filterTags)) {
		const filterTags = _.join(entity.filterTags, ", ");
		additionalInfoComponents.push(<div className="filter-tags-info" key={`filter-tags-view-${entity.id}`} title={`Filter Tags: ${filterTags}`}><b>Filter Tags:</b>&nbsp;{filterTags}</div>);
	}

	if (!_.isEmpty(additionalInfoComponents)) {
		additionalInfo = (
			<div className="additional-info">
				{additionalInfoComponents}
			</div>
		);
	}
	return additionalInfo;
}

export function getSectionIdsForChecklist(checklist) {
	var itemIds = [];

	if (checklist.hasOwnProperty("children")) {
		getSectionIdsForChecklistRecurse(checklist.children, itemIds);

		// itemIds.sort(function (a, b) {
		// 	// Compare the 2 labels
		// 	if (a < b) return -1;
		// 	if (a > b) return 1;
		// 	return 0;
		// });
	}

	return itemIds;
}

function getSectionIdsForChecklistRecurse(arr, itemIds) {
	for (var i = 0; i < arr.length; i++) {
		if (arr[i].entity.type == "section") {
			if (arr[i].entity.id != null && arr[i].entity.id != "") {
				itemIds.push(arr[i].entity.id);
			}
		}

		if (arr[i].hasOwnProperty("children")) {
			getSectionIdsForChecklistRecurse(arr[i].children, itemIds);
		}
	}
}

export function getIdsForChecklist(checklist) {
	var itemIds = [];

	if (checklist.hasOwnProperty("children")) {
		getIdsForChecklistRecurse(checklist.children, itemIds);

		itemIds.sort(function (a, b) {
			// Compare the 2 labels
			if (a < b) return -1;
			if (a > b) return 1;
			return 0;
		});
	}

	return itemIds;
}

function getIdsForChecklistRecurse(arr, itemIds) {
	for (var i = 0; i < arr.length; i++) {
		if (arr[i].entity.type == "list") {
			if (arr[i].entity.id != null && arr[i].entity.id != "") {
				itemIds.push(arr[i].entity.id);
			}
		} else if (arr[i].entity.type == "section") {
			if (arr[i].entity.id != null && arr[i].entity.id != "") {
				itemIds.push(arr[i].entity.id);
			}
		} else if (arr[i].entity.type.startsWith("item")) {
			if (arr[i].entity.id != null && arr[i].entity.id != "") {
				itemIds.push(arr[i].entity.id);
			}
		}

		if (arr[i].hasOwnProperty("children")) {
			getIdsForChecklistRecurse(arr[i].children, itemIds);
		}
	}
}
