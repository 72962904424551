import React from "react";
import { branch } from "baobab-react/higher-order";
import PropTypes from "baobab-react/prop-types";
import { editTimerTemplate, deleteTimerTemplate } from "../../actions/timerActions";

import { Panel, Button } from "react-bootstrap";

import Flexbox from "flexbox-react";

import TimerCardNew from "./app-timer-card-new";
import TimerCardReadOnly from "./app-timer-card-read-only";

import FontAwesome from "react-fontawesome";

class TimerCardContainer extends React.PureComponent {
	render() {
		let component;
		let actions;
		if (this.props.new) {
			component = <TimerCardNew />;

			return (
				null				// <Flexbox>
				// 	<Panel style={{ /*backgroundColor: "#eeeeee", */borderStyle: this.props.new ? "dashed" : "solid", borderWidth: 2, width: "270px", height: "240px" }} bsStyle="default">
				// 		<Panel.Body style={{ margin: 0, padding: 0 }}>
				// 			<Flexbox flexDirection="column">
				// 				{component}
				// 			</Flexbox>
				// 		</Panel.Body>
				// 	</Panel>
				// </Flexbox>
			);
		} else {
			component = <TimerCardReadOnly timerTemplate={this.props.timerTemplate} />;

			actions =
				<Flexbox flexDirection="row" alignItems="center" justifyContent="space-between">
					<Button onClick={() => {
						this.props.dispatch(editTimerTemplate, this.props.timerTemplate);
					}}>
						<FontAwesome fixedWidth={true} style={{ fontSize: "16px" }} name="pencil" />
					</Button>
					<Button onClick={() => {
						this.props.dispatch(deleteTimerTemplate, this.props.timerTemplate);
					}}>
						<FontAwesome fixedWidth={true} style={{ fontSize: "16px" }} name="trash" />
					</Button>
				</Flexbox>;
		}

		return (
			<Flexbox>
				<Panel style={{ borderStyle: this.props.new ? "dashed" : "solid", borderWidth: 2, width: "270px", height: "240px" }} bsStyle="info">
					<Panel.Heading style={{ height: "55px" }}>
						<Panel.Title componentClass="h3">{this.props.timerTemplate.name}</Panel.Title>
					</Panel.Heading>
					<Panel.Body style={{ margin: 0, padding: 0, height: "126px" }}>
						<Flexbox style={{ height: "100%" }} flexDirection="column" alignItems="center" justifyContent="center">
				
							{component}
						</Flexbox>
					</Panel.Body>
					<Panel.Footer>
						{actions}
					</Panel.Footer>
				</Panel>
			</Flexbox>
		);
	}
}

TimerCardContainer.contextTypes = {
	tree: PropTypes.baobab
};

export default branch({
}, TimerCardContainer);