import React from 'react';
import {branch} from 'baobab-react/higher-order';
import PropTypes from 'baobab-react/prop-types';
import * as actions from '../actions/actions';
import { getSectionIdsForChecklist } from '../utils/utils';

import _ from "lodash";

import {Checkbox, Panel, FormControl} from 'react-bootstrap';

import LinkActionType from '../components/itemComponents/LinkActionType';

import AfPanel from '../components/shared/AfPanel';
import AfHelp from '../components/shared/AfHelp';

class CoPilotPhases extends React.PureComponent{
	constructor(props, context) {
		super(props, context);
	}

	componentDidMount() {
	}
	componentDidUpdate(prevProps, prevState) {
	}

	render() {
		var self=this;

		var entity = this.props.node.entity;

		const sectionIds = getSectionIdsForChecklist(this.context.tree.get(["tree","root"]));

		console.log("$#$#$# SECTION IDS", sectionIds);

		const copilotPhasesComponent = entity.copilotOptions.phases.map((phase, idx) => {
			var sectionIdsSubComponent = sectionIds.map(function (sectionId, i) {
				return (
					<option key={"sectionId"+i} value={"#" + sectionId}>{sectionId}</option>
				);			
			});

			sectionIdsSubComponent.unshift(<option key={"sectionIdNotSelected"} value="">(No ID Selected)</option>);

			const sectionIdsComponent =
				<div>
					<div>
						<AfHelp description={this.props.content.linkId.description}>
							<label>{this.props.content.linkId.name}</label>
						</AfHelp>
					</div>
					<div>
						<FormControl componentClass="select" value={phase.linkId} onChange={(e)=>{
							this.props.dispatch(actions.handleChangePropertyForPhase, phase.id, "linkId", e.target.value);
						}}>
							{sectionIdsSubComponent}
						</FormControl>
					</div>
				</div>

			const advanceOptionsComponent = phase.options.map((advancedOption) => {
				return (
					<div>
						<div>
							<AfHelp description={this.props.content[advancedOption.helpId].description}>
								<label>{this.props.content[advancedOption.helpId].name}</label>
							</AfHelp>
						</div>
						<div>
							<input style={{ marginBottom: "15px" }} className="form-control" type="text" placeholder="(Enter value)" value={advancedOption.value} onChange={(e) => this.props.dispatch(actions.handleChangePropertyForAdvancedPhaseOption, phase.id, advancedOption.id, "value", e.target.value)} />
						</div>
					</div>
				);
			})	
		
			let optionsComponent =
				<div>
					<AfHelp alignItems="center" description={this.props.content[phase.helpId].description}>
						<Checkbox checked={phase.enabled==null || phase.enabled ? "checked" : ""} onChange={(e)=>{
							this.props.dispatch(
								actions.handleChangePropertyForPhase,
								phase.id,
								"enabled",
								e.target.checked
							);							
						}}>
							{this.props.content[phase.helpId].name}
						</Checkbox>
					</AfHelp>

					{phase.enabled &&
						<Panel>
							<Panel.Heading>
								<Panel.Title>
									{phase.name} Options
								</Panel.Title>
							</Panel.Heading>
							<Panel.Body>
								{sectionIdsComponent}
								
								<Checkbox checked={phase.showConfirmationPrompt==null || phase.showConfirmationPrompt ? "checked" : ""} onChange={(e)=>{
										this.props.dispatch(
											actions.handleChangePropertyForPhase,
											phase.id,
											"showConfirmationPrompt",
											e.target.checked
										);							
								}}>
									Show Confirmation Prompt
								</Checkbox>
								<Checkbox checked={phase.readSection==null || phase.readSection ? "checked" : ""} onChange={(e)=>{
										this.props.dispatch(
											actions.handleChangePropertyForPhase,
											phase.id,
											"readSection",
											e.target.checked
										);							
								}}>
									Auto-Read Section
								</Checkbox>

								<AfPanel style={{ marginTop: "25px", marginBottom: "0px" }} bsStyle="success" defaultExpanded={false} title="Advanced">
									{advanceOptionsComponent}
									{/* <div>
										<div>
											<AfHelp description={this.props.content.linkAction.description}>
												<label>{this.props.content.linkAction.name}</label>
											</AfHelp>
										</div>
										<div>
											<LinkActionType forPhases={true} user={this.props.user} value={phase.linkActionType} onChange={(e) => this.props.dispatch(actions.handleChangePropertyForPhase, phase.id, "linkActionType", e.target.value)} />
										</div>
									</div> */}
								</AfPanel>
							</Panel.Body>	
						</Panel>
					}

					<hr style={{padding: 0, margin: 0}}/>
				</div>;		

			return optionsComponent
		});

		return (
			<div style={{width: "100%", height: "100%"}}>
				<div style={{ display: "flex", flexDirection: "column" }}>
					{copilotPhasesComponent}
				</div>
			</div>
		);
	}
}

CoPilotPhases.contextTypes = {
	tree: PropTypes.baobab
};

export default branch({
	user: ['user'],
	content: ['content','activity','properties','properties']
}, CoPilotPhases);