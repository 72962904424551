import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Polyline } from 'google-maps-react';

const mapStyles = {
  width: '95%',
  height: '70vh'
};

export class GoogleMapsContainer extends Component {
  render() {
    //   console.log("ROUTE: " + JSON.stringify(this.props.route));
    var bounds = new this.props.google.maps.LatLngBounds();
    for (var i = 0; i < this.props.route.length; i++) {
    bounds.extend(this.props.route[i]);
    }  
    return (
      <Map
        google={this.props.google}
        style={mapStyles}
        initialCenter={this.props.initialCenter}
        bounds={bounds}
      >
        <Polyline   geodesic={true}
                    options={{
                        path: this.props.route,
                        strokeColor: '#ff0000',
                        strokeOpacity: 1,
                        strokeWeight: 3,
                        icons: [{
                            offset: '0',
                            repeat: '10px'
                        }],
                    }}
        />      
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyB8F8WiJBwuSPTdj0BDZT-mHbhupgP-IHk"
})(GoogleMapsContainer);