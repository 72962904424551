import React from 'react';
import {FormControl} from 'react-bootstrap';

class ItemType extends React.PureComponent {
    render() {
        var optionBarcode;
        if (this.props.barcode) {
            optionBarcode = <option value="itemBarcodeScanner">Barcode Scanner</option>;
        }

        return (
            <FormControl componentClass="select" value={this.props.value} onChange={this.props.onChange}>
                <option value="item">Check Item</option>
                <option value="itemTextInput">Text Input</option>
                <option value="itemTextInputMultiline">Text Input (Multiline)</option>
                <option value="itemYesNo">Yes / No</option>
                <option value="itemPicker">Picker</option>
                <option value="itemDate">Date Picker</option>
                <option value="itemTime">Time Picker</option>
                <option value="itemDateTime">Date Time Picker</option>
                <option value="itemWeather">Weather</option>
                <option value="itemAddress">Address Search</option>
                <option value="itemLocation">Location Search</option>
                <option value="itemImagePicker">Media Upload</option>
                {optionBarcode}
                <option value="itemSketchPad">Sketch Pad</option>
            </FormControl>
        );       
    }
}

export default ItemType;