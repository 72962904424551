import React from 'react';
import globals from '../../constants/globals.js';
import { branch } from 'baobab-react/higher-order';

import * as actions from '../../actions/actions';
import * as awsS3 from '../../persistence/s3';
import { Button } from 'react-bootstrap';
import { Panel } from 'react-bootstrap';

import FontAwesome from 'react-fontawesome';

import Flexbox from 'flexbox-react';
import browserHistory from '../../history';

import Alerts from '../app-alerts.js';

import HomeLink from './app-home-link.js';

class RedeemVoucher extends React.PureComponent {
	constructor(props, context) {
		super(props, context);

		if (!props.user.loggedIn) {
			actions.forceLogin(browserHistory, this.props.location.pathname, true);
		}

		this.state = {
			voucherCode: this.props.urlParams.voucher ? this.props.urlParams.voucher : ""
		}
	}

	render() {
		var self = this;

		if (!this.props.user.loggedIn) {
			return (
				<div />
			);
		} else {
			var self = this;

			return (
				<Flexbox className="myBackground" flexDirection="column">
					<HomeLink />
					<Flexbox flexDirection="column" alignItems="center" justifyContent="center" flexGrow={1}>
						<Alerts />
						<h3 style={{ color: "#eeeeee", padding: "10px" }}>Redeem Voucher</h3>
						<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
							<div style={{ margin: "10px", maxWidth: "800px", textAlign: "left", backgroundColor: "#666666", border: "1px solid #666666", borderRadius: "10px", padding: "10px" }}>
								<label>Voucher Code</label>
								<div>
									<input ref="inputVoucherCode" className="form-control" type="text" value={this.state.voucherCode} onChange={(e)=>{
										this.setState({voucherCode: e.target.value});
									}} />
								</div>
								<br/>
								<Flexbox flexDirection="row" alignItems="center" justifyContent="center" flexWrap="wrap">
									<Button onClick={()=>{
										awsS3.redeemVoucher(this.state.voucherCode);
									}}>Apply</Button>
								</Flexbox>
							</div>
						</div>
					</Flexbox>
				</Flexbox>
			);
		}
	}
}

export default branch({
	user: ["user"],
	version: ["version"],
	urlParams: ["appState", "urlParams"]
}, RedeemVoucher);