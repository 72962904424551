import React from "react";

//import shallowCompare from 'react-addons-shallow-compare';

import { Panel } from "react-bootstrap";

import FontAwesome from "react-fontawesome";

import marked from "marked";

class ChecklistReadOnly extends React.PureComponent {
  /*
	shouldComponentUpdate(nextProps, nextState) {
		return shallowCompare(this, nextProps, nextState);
	}
*/
  createMarkup(label) {
    var myRenderer = new marked.Renderer();
    myRenderer.link = function (href, title, text) {
      var external, newWindow, out;
      external = /^https?:\/\/.+$/.test(href);
      newWindow = external || title === "newWindow";
      out = '<a href="' + href + '"';
      if (newWindow) {
        out += ' target="_blank"';
      }
      if (title && title !== "newWindow") {
        out += ' title="' + title + '"';
      }
      return (out += ">" + text + "</a>");
    };

    myRenderer.image = function (href, title, text) {
      return (
        '<img style="max-width: 100%" src="' +
        href +
        '" /><br/><div>' +
        text +
        "</div>"
      );
    };

    // myRenderer.paragraph = function(text) {
    // 	return text;
    // };

    marked.setOptions({
      renderer: myRenderer,
      gfm: true,
      tables: true,
      breaks: true,
      pedantic: false,
      sanitize: false,
      smartLists: true,
      smartypants: false,
    });

    return { __html: marked(label) };
  }

  createComments(comments) {
    return { __html: comments };
  }

  render() {
    var self = this;
    var lists;
    try {
      lists = self.props.checklist.children.map(function (list, i) {
        var sections = list.children.map(function (section, j) {
          var items = section.children.map(function (item, k) {
            var check;
            if (item.labelOnly) {
            } else if (item.checked) {
              check = (
                <FontAwesome
                  fixedWidth={true}
                  style={{
                    fontSize: "32px",
                    width: "32px",
                    height: "32px",
                    margin: 10,
                  }}
                  name="check-square-o"
                />
              );
            } else {
              check = (
                <FontAwesome
                  fixedWidth={true}
                  style={{
                    fontSize: "32px",
                    width: "32px",
                    height: "32px",
                    margin: 10,
                  }}
                  name="square-o"
                />
              );
            }
            var comments;
            if (
              item.hasOwnProperty("comments") &&
              item.comments !== "" &&
              self.props.hasOwnProperty("preview") &&
              self.props.preview
            ) {
              comments = (
                <div
                  style={{
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "gold",
                    borderRadius: 5,
                    backgroundColor: "lightyellow",
                    padding: 10,
                    margin: 10,
                  }}
                >
                  <b>Comments</b>
                  <br />
                  <div
                    dangerouslySetInnerHTML={self.createComments(item.comments)}
                  />
                </div>
              );
            }

            var notes;
            if (item.hasOwnProperty("notes") && item.notes !== "") {
              notes = (
                <div
                  style={{
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "blue",
                    borderRadius: 5,
                    backgroundColor: "lightblue",
                    padding: 10,
                    margin: 10,
                  }}
                >
                  <b>Notes</b>
                  <br />
                  {item.notes}
                </div>
              );
            }

            var component;
            if (item.type.toLowerCase() === "item") {
              var imageComponent;
              if (
                item.hasOwnProperty("sketchPadImage") &&
                item.sketchPadImage &&
                item.sketchPadImage != ""
              ) {
                var imgUrl =
                  "<p><b>Sketch</b><br/><a href='" +
                  item.sketchPadImage +
                  "' target='blank'><img style='width: 30%; border: 1px solid #eeeeee' src='" +
                  item.sketchPadImage +
                  "' /></a></p>";
                imageComponent = (
                  <div dangerouslySetInnerHTML={self.createMarkup(imgUrl)} />
                );
              }

              component = (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {check}
                  <div style={{ width: "100%", overflowX: "auto" }}>
                    <div
                      dangerouslySetInnerHTML={self.createMarkup(item.label1)}
                    />
                    <div
                      dangerouslySetInnerHTML={self.createMarkup(item.label2)}
                    />
                    {item.hasOwnProperty("label3") && (
                      <div
                        dangerouslySetInnerHTML={self.createMarkup(item.label3)}
                      />
                    )}
                    {imageComponent}
                  </div>
                </div>
              );
            } else if (
              item.type.toLowerCase() === "itemtextinput" ||
              item.type.toLowerCase() === "itemtextinputmultiline"
            ) {
              try {
                component = (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div style={{ width: "100%", overflowX: "auto" }}>
                      <div
                        dangerouslySetInnerHTML={self.createMarkup(
                          "<b>" + item.label1 + "</b>"
                        )}
                      />
                      <div
                        dangerouslySetInnerHTML={self.createMarkup(
                          "<b>" + item.label2 + "</b>"
                        )}
                      />
                      {item.hasOwnProperty("label3") && (
                        <div
                          dangerouslySetInnerHTML={self.createMarkup(
                            "<b>" + item.label3 + "</b>"
                          )}
                        />
                      )}
                      <div>{item.value}</div>
                    </div>
                  </div>
                );
              } catch (e) {}
            } else if (item.type.toLowerCase() === "itempicker") {
              try {
                component = (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div style={{ width: "100%", overflowX: "auto" }}>
                      <div
                        dangerouslySetInnerHTML={self.createMarkup(
                          "<b>" + item.label1 + "</b>"
                        )}
                      />
                      <div
                        dangerouslySetInnerHTML={self.createMarkup(
                          "<b>" + item.label2 + "</b>"
                        )}
                      />
                      {item.hasOwnProperty("label3") && (
                        <div
                          dangerouslySetInnerHTML={self.createMarkup(
                            "<b>" + item.label3 + "</b>"
                          )}
                        />
                      )}
                      <div>{item.value}</div>
                    </div>
                  </div>
                );
              } catch (e) {}
            } else if (item.type.toLowerCase() === "itemyesno") {
              try {
                component = (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div style={{ width: "100%", overflowX: "auto" }}>
                      <div
                        dangerouslySetInnerHTML={self.createMarkup(
                          "<b>" + item.label1 + "</b>"
                        )}
                      />
                      <div
                        dangerouslySetInnerHTML={self.createMarkup(
                          "<b>" + item.label2 + "</b>"
                        )}
                      />
                      {item.hasOwnProperty("label3") && (
                        <div
                          dangerouslySetInnerHTML={self.createMarkup(
                            "<b>" + item.label3 + "</b>"
                          )}
                        />
                      )}
                      <div>{item.value}</div>
                    </div>
                  </div>
                );
              } catch (e) {}
            } else if (item.type.toLowerCase() === "itembarcodescanner") {
              try {
                component = (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div style={{ width: "100%", overflowX: "auto" }}>
                      <div
                        dangerouslySetInnerHTML={self.createMarkup(
                          "<b>" + item.label1 + "</b>"
                        )}
                      />
                      <div
                        dangerouslySetInnerHTML={self.createMarkup(
                          "<b>" + item.label2 + "</b>"
                        )}
                      />
                      {item.hasOwnProperty("label3") && (
                        <div
                          dangerouslySetInnerHTML={self.createMarkup(
                            "<b>" + item.label3 + "</b>"
                          )}
                        />
                      )}
                      <div>
                        {item.hasOwnProperty("barcode") &&
                        item.barcode.hasOwnProperty("data") &&
                        item.barcode.data != ""
                          ? item.barcode.data
                          : "(No Data)"}
                      </div>
                    </div>
                  </div>
                );
              } catch (e) {}
            } else if (item.type.toLowerCase() === "itemimagepicker") {
              try {
                var imgUrls = "";
                for (var i = 0; i < item.valueUrls.length; i++) {
                  // Need to loop through all values
                  var imgUrlParts = item.valueUrls[i].split(".");
                  var ext = imgUrlParts[imgUrlParts.length - 1];
                  var imgUrl;
                  if (ext === "mp4") {
                    imgUrl =
                      "<p><b>Video Thumbnail</b><br/><a href='" +
                      item.valueUrls[i] +
                      "' target='blank'><img style='width: 160px; height: 160px' src='" +
                      item.valueUrls[i].replace(".mp4", "-00001.png") +
                      "' /></a></p>";
                  } else {
                    imgUrl =
                      "<p><b>Photo</b><br/><a href='" +
                      item.valueUrls[i] +
                      "' target='blank'><img style='width: 50%' src='" +
                      item.valueUrls[i] +
                      "' /></a></p>";
                  }

                  imgUrls = imgUrls + imgUrl;
                }

                component = (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div style={{ width: "100%", overflowX: "auto" }}>
                      <div
                        dangerouslySetInnerHTML={self.createMarkup(
                          "<b>" + item.label1 + "</b>"
                        )}
                      />
                      <div
                        dangerouslySetInnerHTML={self.createMarkup(
                          "<b>" + item.label2 + "</b>"
                        )}
                      />
                      {item.hasOwnProperty("label3") && (
                        <div
                          dangerouslySetInnerHTML={self.createMarkup(
                            "<b>" + item.label3 + "</b>"
                          )}
                        />
                      )}
                      <div
                        dangerouslySetInnerHTML={self.createMarkup(imgUrls)}
                      />
                    </div>
                  </div>
                );
              } catch (e) {
                component = (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    (No Media)
                  </div>
                );
              }
            } else if (item.type.toLowerCase() === "itemsketchpad") {
              var imgUrl =
                "<p><b>Sketch</b><br/><a href='" +
                item.sketchPadImage +
                "' target='blank'><img style='width: 30%; border: 1px solid #eeeeee' src='" +
                item.sketchPadImage +
                "' /></a></p>";

              component = (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "100%", overflowX: "auto" }}>
                    <div
                      dangerouslySetInnerHTML={self.createMarkup(
                        "<b>" + item.label1 + "</b>"
                      )}
                    />
                    <div
                      dangerouslySetInnerHTML={self.createMarkup(
                        "<b>" + item.label2 + "</b>"
                      )}
                    />
                    {item.hasOwnProperty("label3") && (
                      <div
                        dangerouslySetInnerHTML={self.createMarkup(
                          "<b>" + item.label3 + "</b>"
                        )}
                      />
                    )}
                    <div dangerouslySetInnerHTML={self.createMarkup(imgUrl)} />
                  </div>
                </div>
              );
            } else if (item.type.toLowerCase() === "itemdate") {
              try {
                component = (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div style={{ width: "100%", overflowX: "auto" }}>
                      <div
                        dangerouslySetInnerHTML={self.createMarkup(
                          "<b>" + item.label1 + "</b>"
                        )}
                      />
                      <div
                        dangerouslySetInnerHTML={self.createMarkup(
                          "<b>" + item.label2 + "</b>"
                        )}
                      />
                      {item.hasOwnProperty("label3") && (
                        <div
                          dangerouslySetInnerHTML={self.createMarkup(
                            "<b>" + item.label3 + "</b>"
                          )}
                        />
                      )}
                      <div>{item.value}</div>
                    </div>
                  </div>
                );
              } catch (e) {}
            } else if (item.type.toLowerCase() === "itemtime") {
              try {
                component = (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div style={{ width: "100%", overflowX: "auto" }}>
                      <div
                        dangerouslySetInnerHTML={self.createMarkup(
                          "<b>" + item.label1 + "</b>"
                        )}
                      />
                      <div
                        dangerouslySetInnerHTML={self.createMarkup(
                          "<b>" + item.label2 + "</b>"
                        )}
                      />
                      {item.hasOwnProperty("label3") && (
                        <div
                          dangerouslySetInnerHTML={self.createMarkup(
                            "<b>" + item.label3 + "</b>"
                          )}
                        />
                      )}
                      <div>{item.value}</div>
                    </div>
                  </div>
                );
              } catch (e) {}
            } else if (item.type.toLowerCase() === "itemdatetime") {
              try {
                var options = {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                };
                var date;
                var dateString;
                //date = new Date(item.value.split(" ")[0] + "T" + item.value.split(" ")[1]);
                //dateString = date.toLocaleDateString('en-US', options) + "; " + date.toLocaleTimeString('en-US');
                dateString = item.value;

                component = (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div style={{ width: "100%", overflowX: "auto" }}>
                      <div
                        dangerouslySetInnerHTML={self.createMarkup(
                          "<b>" + item.label1 + "</b>"
                        )}
                      />
                      <div
                        dangerouslySetInnerHTML={self.createMarkup(
                          "<b>" + item.label2 + "</b>"
                        )}
                      />
                      {item.hasOwnProperty("label3") && (
                        <div
                          dangerouslySetInnerHTML={self.createMarkup(
                            "<b>" + item.label3 + "</b>"
                          )}
                        />
                      )}
                      <div>{dateString}</div>
                    </div>
                  </div>
                );
              } catch (e) {}
            } else if (item.type.toLowerCase() === "itemweather") {
              try {
                var value = item.value;

                if (value !== null && value.currently && value.kpIndex) {
                  var kpIndexArray = value.kpIndex;
                  var kpIndexLastArray = kpIndexArray[kpIndexArray.length - 1];
                  var kpIndex = kpIndexLastArray[1];

                  component = (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "100%", overflowX: "auto" }}>
                        <div
                          dangerouslySetInnerHTML={self.createMarkup(
                            "<b>" + item.label1 + "</b>"
                          )}
                        />
                        <div
                          dangerouslySetInnerHTML={self.createMarkup(
                            "<b>" + item.label2 + "</b>"
                          )}
                        />
                        {item.hasOwnProperty("label3") && (
                          <div
                            dangerouslySetInnerHTML={self.createMarkup(
                              "<b>" + item.label3 + "</b>"
                            )}
                          />
                        )}
                        <div>
                          <b>Summary: </b>
                          {value.currently.summary}
                          <br />
                          <b>Forecast: </b>
                          {value.minutely.summary} {value.hourly.summary}
                          <br />
                          <b>Temperature: </b>
                          {Math.round(10 * value.currently.temperature) / 10 +
                            " °F"}
                          <br />
                          <b>Dew Point: </b>
                          {Math.round(10 * value.currently.dewPoint) / 10 +
                            " °F"}
                          <br />
                          <b>Humidity: </b>
                          {Math.round(value.currently.humidity * 1000) / 10}%
                          <br />
                          <b>Precipitation Type: </b>
                          {!value.currently.hasOwnProperty("precipType")
                            ? "None"
                            : value.currently.precipType}
                          <br />
                          <b>Precipitation Probability: </b>
                          {Math.round(
                            value.currently.precipProbability * 1000
                          ) / 10}
                          <br />
                          <b>Cloud Cover: </b>
                          {Math.round(value.currently.cloudCover * 1000) / 10}%
                          <br />
                          <b>Wind Direction: </b>
                          {value.currently.windBearing}
                          <br />
                          <b>Wind Speed: </b>
                          {value.currently.windSpeed}
                          <br />
                          <b>Wind Gust: </b>
                          {value.currently.windGust}
                          <br />
                          <b>Visibility: </b>
                          {value.currently.visibility}
                          <br />
                          <b>KP-Index: </b>
                          {kpIndex}
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  component = (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "100%", overflowX: "auto" }}>
                        <div
                          dangerouslySetInnerHTML={self.createMarkup(
                            "<b>" + item.label1 + "</b>"
                          )}
                        />
                        <div
                          dangerouslySetInnerHTML={self.createMarkup(
                            "<b>" + item.label2 + "</b>"
                          )}
                        />
                        {item.hasOwnProperty("label3") && (
                          <div
                            dangerouslySetInnerHTML={self.createMarkup(
                              "<b>" + item.label3 + "</b>"
                            )}
                          />
                        )}
                        <div>No Weather Data</div>
                      </div>
                    </div>
                  );
                }
              } catch (e) {}
            } else if (
              item.type.toLowerCase() === "itemaddress" ||
              item.type.toLowerCase() === "itemlocation"
            ) {
              try {
                var value;
                if (item.value.hasOwnProperty("address")) {
                  value = item.value.address;
                } else if (item.value.hasOwnProperty("latitude")) {
                  value =
                    "Latitude: " +
                    item.value.latitude.toFixed(4) +
                    "\nLongitude: " +
                    item.value.longitude.toFixed(4);
                } else if (item.value.split(" ").length === 2) {
                  value =
                    "Latitude: " +
                    item.value.split(" ")[0] +
                    "\nLongitude: " +
                    item.value.split(" ")[1];
                } else {
                  value = item.value;
                }

                component = (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div style={{ width: "100%", overflowX: "auto" }}>
                      <div
                        dangerouslySetInnerHTML={self.createMarkup(
                          "<b>" + item.label1 + "</b>"
                        )}
                      />
                      <div
                        dangerouslySetInnerHTML={self.createMarkup(
                          "<b>" + item.label2 + "</b>"
                        )}
                      />
                      {item.hasOwnProperty("label3") && (
                        <div
                          dangerouslySetInnerHTML={self.createMarkup(
                            "<b>" + item.label3 + "</b>"
                          )}
                        />
                      )}
                      <div>{value}</div>
                    </div>
                  </div>
                );
              } catch (e) {}
            }

            return (
              <div
                key={"history-list" + i + "section" + j + "item" + k}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "lightgray",
                  borderRadius: 5,
                  backgroundColor: item.labelOnly
                    ? ""
                    : "",
                  padding: 10,
                  margin: 5,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {component}
                </div>
                {comments}
                {notes}
              </div>
            );
          });

          var sectionCompletion = section.completionState;
          var sectionCompletionStyle = "default";
          if (!self.props.hasOwnProperty("preview") || !self.props.preview) {
            if (sectionCompletion === "i") {
              sectionCompletionStyle = "warning";
            } else if (sectionCompletion === "c") {
              sectionCompletionStyle = "success";
            } else if (sectionCompletion === "n") {
              sectionCompletionStyle = "danger";
            }
          } else {
            sectionCompletionStyle = "default";
          }

          return (
            <Panel
              key={"history-list" + i + "section" + j}
              bsStyle={sectionCompletionStyle}
            >
              <Panel.Heading>
                <Panel.Title toggle>{section.name}</Panel.Title>
              </Panel.Heading>
              <Panel.Collapse>
                <Panel.Body>{items}</Panel.Body>
              </Panel.Collapse>
            </Panel>
          );
        });

        var listCompletion = list.completionState;
        var listCompletionStyle = "default";

        if (!self.props.hasOwnProperty("preview") || !self.props.preview) {
          if (listCompletion === "i") {
            listCompletionStyle = "warning";
          } else if (listCompletion === "c") {
            listCompletionStyle = "success";
          } else if (listCompletion === "n") {
            listCompletionStyle = "danger";
          }
        } else {
          listCompletionStyle = "default";
        }

        return (
          <Panel key={"history-list" + i} bsStyle={listCompletionStyle}>
            <Panel.Heading>
              <Panel.Title toggle>{list.name}</Panel.Title>
            </Panel.Heading>
            <Panel.Collapse>
              <Panel.Body>{sections}</Panel.Body>
            </Panel.Collapse>
          </Panel>
        );
      });
    } catch (e) {
      alert(e.message);
      lists = (
        <div>There was an error getting the content for the checklist!</div>
      );
    }

    return <div>{lists}</div>;
  }
}

export default ChecklistReadOnly;
