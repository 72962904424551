import React from 'react';
import { branch } from 'baobab-react/higher-order';
import * as actions from '../../actions/actions';
import * as utils from "../../utils/utils";

import NavItem from './app-nav-item.js';
import NavItemMyChecklists from './app-nav-item-my-checklists.js';

import { Button } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';


import browserHistory from '../../history';

class NavItems extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.onClickHome = this.onClickHome.bind(this);
        this.onClickSearch = this.onClickSearch.bind(this);
        this.onClickMyChecklists = this.onClickMyChecklists.bind(this);
        this.onClickTimers = this.onClickTimers.bind(this);
        this.onClickHistory = this.onClickHistory.bind(this);
        // this.onClickAfmPoh = this.onClickAfmPoh.bind(this);
        // this.onClickFlightSchools = this.onClickFlightSchools.bind(this);
        this.onClickCheckMateImport = this.onClickCheckMateImport.bind(this);
        }

    // Could refactor into one onClick
    onClickHome() {
        this.props.dispatch(
            actions.setNavPanelSelected,
            "home"
        );
    }

    onClickSearch() {
        this.props.dispatch(
            actions.setNavPanelSelected,
            "search"
        );
    }

    onClickMyChecklists() {
        this.props.dispatch(
            actions.setNavPanelSelected,
            "myChecklists"
        );
    }

    onClickTimers() {
        this.props.dispatch(
            actions.setNavPanelSelected,
            "timers"
        );
    }

    onClickHistory() {
        this.props.dispatch(
            actions.setNavPanelSelected,
            "history"
        );
    }

    // onClickAfmPoh() {
    //     this.props.dispatch(
    //         actions.setNavPanelSelected,
    //         "afmpoh"
    //     );
    
    // }
    // onClickFlightSchools() {
    //     this.props.dispatch(
    //         actions.setNavPanelSelected,
    //         "flightSchools"
    //     );
    // }

    onClickCheckMateImport() {
        this.props.dispatch(
            actions.setNavPanelSelected,
            "import"
        );
    }

    render() {
    //     var myChecklists =
    //         <NavItem id="myChecklists" label="My Hangar" icon="check-square-o" onClick={this.onClickMyChecklists} selected={this.props.navPanel.selected == "myChecklists" ? true : false} filtersAll={this.props.filtersAll} />;
    //         // <NavItem id="myChecklists" label="My Hangar" icon="check-square-o" onClick={this.onClickMyChecklists} selected={this.props.navPanel.selected == "myChecklists" ? true : false} filtersAll={this.props.filtersAll} />;
    //     if (this.props.capabilitiesMyChecklists.filterVisible) {
    //         myChecklists =
    //             <NavItemMyChecklists id="myChecklists" label="My Hangar" icon="check-square-o" onClick={this.onClickMyChecklists} selected={this.props.navPanel.selected == "myChecklists" ? true : false} filtersAll={this.props.filtersAll} />;
    //         // <NavItemMyChecklists id="myChecklists" label="My Hangar" icon="check-square-o" onClick={this.onClickMyChecklists} selected={this.props.navPanel.selected == "myChecklists" ? true : false} filtersAll={this.props.filtersAll} />;
    //     }

        return (
            <div style={{ display: "flex", flexDirection: "column", cursor: "pointer" }}>
                <NavItem id="home" label="Home" icon="home" onClick={this.onClickHome} selected={this.props.navPanel.selected == "home" ? true : false} />
                <NavItem id="search" label="Search" icon="search" onClick={this.onClickSearch} selected={this.props.navPanel.selected == "search" ? true : false} />
                {/* {myChecklists} */}
                <NavItem id="myChecklists" label="My Hangar" icon="check-square-o" onClick={this.onClickMyChecklists} selected={this.props.navPanel.selected == "myChecklists" ? true : false} />
                <NavItem id="timers" label="Timers" icon="clock-o" onClick={this.onClickTimers} selected={this.props.navPanel.selected == "timers" ? true : false} />
                <NavItem id="history" label="History" icon="history" onClick={this.onClickHistory} selected={this.props.navPanel.selected == "history" ? true : false} />
                {/* <NavItem id="afmpoh" label="AFM & POH" icon="home" onClick={this.onClickAfmPoh} selected={this.props.navPanel.selected == "afmpoh" ? true : false} />
                <NavItem id="flightSchools" label="Flight Schools" icon="home" onClick={this.onClickFlightSchools} selected={this.props.navPanel.selected == "flightSchools" ? true : false} /> */}
                {/* {this.props.capabilitiesTimers && this.props.capabilitiesTimers && this.props.user.hasOwnProperty("subscriptionPlan") && this.props.user.subscriptionPlan.startsWith("pro-plan") && <NavItem id="timers" label="Timers" icon="clock-o" onClick={this.onClickTimers} selected={this.props.navPanel.selected=="timers"?true:false}/> } */}
                {/* <NavItem id="checkMateImport" label="Import" icon="download" onClick={this.onClickCheckMateImport} selected={this.props.navPanel.selected == "checkMateImport" ? true : false} /> */}
                {!this.props.igapp && <div style={{ display: "flex", alignItems: "center", padding: "10px", borderLeftStyle: "solid", borderWidth: "5px", borderColor: "#1F272B" }}>
                    <FontAwesome fixedWidth={true} style={{ color: "#0E7AFE", fontWeight: "bold", fontSize: "24px" }} name="question-circle" />&nbsp;&nbsp;<a onClick={(e) => utils.openUrl(e, "https://forum.miralouaero.com")} href="javascript:void(0)">Support</a>
                </div>}
            </div>
        );
    }
}

export default branch({
    user: ['user'],
    navPanel: ['appState', 'navPanel'],
    filtersAll: ['appState', 'filters', 'all'],
    capabilitiesMyChecklists: ['appCapabilities', 'views', 'myChecklists'],
    capabilitiesTimers: ['appCapabilities', 'timers'],
    igapp: ['appCapabilities', 'igapp']
}, NavItems);