import React from 'react';
import ReactDOM from 'react-dom';

import { branch } from 'baobab-react/higher-order';
import * as actions from '../../actions/portalActions';

import {Link} from 'react-router-dom';

import { Button } from 'react-bootstrap';
import { Panel } from 'react-bootstrap';

import Flexbox from 'flexbox-react';

import HomeLink from './app-home-link.js';

class Devices extends React.Component {
	render() {
		var devices = [];

		if (this.props.devices.length === 0) {
			devices = <div>You do not have any registered devices.</div>
		} else {
			var deviceRows = this.props.devices.map(function (device, i) {
				var deviceName = device.deviceDescription;
				var deviceCreateDate = device.deviceCreateDate;
				var deviceKey = device.deviceId;

				return (
					<tr key={"devicerow" + i}>
						<td>{deviceName}</td>
						<td>{deviceCreateDate}</td>
						<td>
							<Button onClick={(e) => { actions.removeDevice(deviceKey) }}>Remove</Button>
						</td>
					</tr>
				);
			});

			var devices =
				<table>
					<tbody>
						<tr>
							<th>Device Name</th>
							<th>Device Creation Date</th>
							<th>Remove</th>
						</tr>
						{deviceRows}
					</tbody>
				</table>;
		}

		return (
			<Flexbox className="myBackground" flexDirection="column">
				<HomeLink />
				<Flexbox flexDirection="column" alignItems="center" justifyContent="center" flexGrow={1}>
					<h3 style={{ color: "#eeeeee", padding: "10px" }}>Remove a Device</h3>
					<Panel style={{ marginBottom: "5px", padding: "0px" }} className="col-xs-10 col-sm-10 col-md-8 col-lg-6" style={{ overflow: "auto" }}>
						<Panel.Body>
							<div>
								{devices}
							</div>
						</Panel.Body>	
					</Panel>
					<Link style={{ color: "#0E7AFE", fontSize: "16pt" }} to="/login">Login</Link>
					<div style={{ color: "white", padding: "20px", textAlign: "center", width: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
						{this.props.version.copyright}.<br />{this.props.version.prefix}&nbsp;{this.props.version.major}.{this.props.version.minor}.{this.props.version.revision}.{this.props.version.build}&nbsp;{this.props.version.suffix}
					</div>
				</Flexbox>
			</Flexbox>
		);
	}
}

export default branch({
	user: ['user'],
	version: ['version'],
	devices: ['devices']
}, Devices);

