import React from 'react';

import AfHelp from '../components/shared/AfHelp';


//import shallowCompare from 'react-addons-shallow-compare';

class Contributors extends React.PureComponent{
	/*
	shouldComponentUpdate(nextProps, nextState) {
		return shallowCompare(this, nextProps, nextState);
	}
*/
	render() {
		var lastContributor = "";
		var contributors = this.props.contributors.map(function (contributor, i) {
			if (i > 0 && lastContributor !== contributor.name) {
				lastContributor = contributor.name;
				return (
					<div key={contributor.name+" - "+i}>{contributor.name}</div>
				);			
			} else {
				return <div></div>;
			}
		});

		var panelContributors;
		if (this.props.contributors.length === 1) {
			panelContributors =
				<div>
					<br/>
					<div>
						<AfHelp description={this.props.creatorContent.description}>
							<label>{this.props.creatorContent.name}</label>						
						</AfHelp>
					</div>
					<div>
						{this.props.contributors[0].nickname !== "" ? this.props.contributors[0].nickname : this.props.contributors[0].name}
					</div>
				</div>;
		} else if (this.props.contributors.length > 1) {
			panelContributors =
				<div>
					<br/>
					<div>
						<AfHelp description={this.props.creatorContent.description}>
							<label>{this.props.creatorContent.name}</label>						
						</AfHelp>
					</div>
					<div>
						{this.props.contributors[0].nickname !== "" ? this.props.contributors[0].nickname : this.props.contributors[0].name}
					</div>
					<br/>
					<div>
						<AfHelp description={this.props.contributorsContent.description}>
							<label>{this.props.contributorsContent.name}</label>						
						</AfHelp>
					</div>
					<div>
						{contributors}
					</div>
				</div>;
		}
		
		return (
			<div>
				{panelContributors}
			</div>
		);
	}
}

export default Contributors;