import React from 'react';
import globals from '../../constants/globals.js';
import { branch } from 'baobab-react/higher-order';

import * as actions from '../../actions/actions';
import * as awsS3 from '../../persistence/s3';
import { Button } from 'react-bootstrap';

import FontAwesome from 'react-fontawesome';

import Flexbox from 'flexbox-react';
import browserHistory from '../../history';

import MediaQuery from 'react-responsive';

//import shallowCompare from 'react-addons-shallow-compare';

import StripeCheckout from 'react-stripe-checkout';

import Alerts from '../app-alerts.js';

import HomeLink from './app-home-link.js';

class UpdateCustomerCard extends React.PureComponent {
	constructor(props, context) {
		super(props, context);

		if (!props.user.loggedIn) {
			actions.forceLogin(browserHistory, this.props.location.pathname);
		}
	}

	_onToken = (token) => {
		awsS3.updateCustomerCard(token);
	}
/*
	shouldComponentUpdate(nextProps, nextState) {
		return shallowCompare(this, nextProps, nextState);
	}
*/
	render() {
		var self = this;

		if (!this.props.user.loggedIn) {
			return (
				<div />
			);
		} else {
			var stripeKey =  globals.stage==="prod"?globals.stripeKeyProd:globals.stripeKeyDev;

			var updateCustomerCardButton =
				<div style={{ width: "100%" }}>
					<StripeCheckout
						name="MiraCheck"
						description="Update Card"
						label="Update Card"
						panelLabel="Update Card"
						image={"assets/checkmate_logo_only_no_border_space_128x128.png"}
						token={(token) => { self._onToken(token) }}
						stripeKey={stripeKey}
						email={self.props.user.email}
						ComponentClass="div"
					>
						<Button style={{ color: "#0E7AFE", fontWeight: "bold" }}>Update Card</Button>
					</StripeCheckout>
				</div>;

			var headerPanel;

			if (this.props.lastLocation != null) {
				headerPanel =
				<Flexbox flexDirection="row" alignItems="center" justifyContent="space-between">
					<HomeLink />
					<Button style={{width: "150px", marginRight: "20px"}} onClick={(e)=>{
						browserHistory.push(this.props.lastLocation.pathname);
					}}>Return</Button>
				</Flexbox> 
			} else {
				headerPanel =
				<HomeLink />;
			}

			return (
				<Flexbox className="myBackground" flexDirection="column">
					{headerPanel}
					<Flexbox flexDirection="column" alignItems="center" justifyContent="center" flexGrow={1}>
						<Alerts />
						<h3 style={{ color: "#eeeeee", padding: "10px" }}>Update Card Info</h3>
						<Flexbox flexDirection="row" alignItems="center" justifyContent="center" flexWrap="wrap">
							{updateCustomerCardButton}
						</Flexbox>
					</Flexbox>
				</Flexbox>
			);
		}
	}
}

export default branch({
	lastLocation: ["appState","lastLocation"],
	user: ["user"],
	version: ["version"]
}, UpdateCustomerCard);