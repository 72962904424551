import * as portalActions from '../actions/portalActions';
import * as awsS3 from '../persistence/s3';
import * as dynamoDb from '../persistence/dynamoDb';

import state from '../state/state';
import _ from 'lodash';

import request  from 'superagent';

import browserHistory from '../history';

import { AuthenticationDetails, CognitoUserPool, CognitoUserAttribute, CognitoUser } from 'amazon-cognito-identity-js';
import AWS from 'aws-sdk';
import newDoc from '../data/newDoc.js';

import * as utils from '../utils/utils.js'; 
import * as transformUtils from '../utils/transformUtils.js'; 
import * as entityUtils from '../utils/entityUtils.js'; 

import globals from '../constants/globals';

import { contentBucket, miracheckContentBucket } from '../constants/globals.js'; 


import moment from 'moment';

import marked from 'marked';

AWS.config.update({region:'us-east-1'});

var changePropertyCount = 0;

// Output tree
var dstTree = {
	"root": {
		"entity": {
			"entityId": "root",
			"type": "category",
			"label": "Price List"
		},
		"expanded": false,
		"children": []
	}
};

var _visiblePaths = [];

export function deleteChecklistInstance(tree,identityId,checklistId,instanceId,timestamp) {
	var lastTimestamp = timestamp.replace(" ", "T");

	awsS3.deleteJsonFileFromS3WithKeyPrefix(contentBucket,identityId,checklistId + "/" + lastTimestamp + "_" + instanceId + ".json");
}

export function saveChecklist() {
	utils.showLoader(state,"loadingSpinner");

	var checklist = transformUtils.transformFromChecklistEntities(state.get(["tree","root"])); 
	
	awsS3.updateChecklistsAndPushToS3(checklist,null,"The checklist was successfully saved!");
}

export async function shareWithCode(identityId, document) {
	var shareCode = prompt("Please enter a share code (e.g. N51212)", document.shareCode);

	if (shareCode != null) {
		try {
			var data = await dynamoDb.getPrivateSharePromise(shareCode);
			if (!_.isEmpty(data) && (document.shareCode != shareCode)) {
				alert("The Share Code " + shareCode + " has already been used. Please choose another Share Code.");
			} else {
				document.shareCode = shareCode;

				await dynamoDb.putPrivateSharePromise({shareCode: shareCode, identityId: identityId, checklistId: document.id, publisher: document.publisher});
				
				awsS3.updateDocument(document);
				var newDocuments = _.cloneDeep(state.get(["documents"]));
				newDocuments.shift();
				awsS3.saveJsonToS3(newDocuments,contentBucket,"checklists.json");				

				showSuccess("Share Code", "You have successfully created a Share Code " + shareCode + ". Communicate this Share Code to other users and they can use it to replace one of their checklists with the contents of this checklist.");
				//showSuccess("Share Code", "You have successfully created a Share Code " + shareCode + ". Communicate this Share Code to other users and they can use it to replace one of their checklists with the contents of this checklist. They would utilize the Replace Share Code action to receive your content. NOTE: If the content being shared is licensed CheckMate content, then they will need to replace a checklist that has a CheckMate license. They can purchase a new one if they don't already have one.");
			}
		} catch (e) {
			console.log(e);	
			alert(e);		
		}
	}
}

export async function replaceWithCode(identityId, document) {
	if (document.shareCodeReceived == "") {
		var shareCodeReceived = prompt("Please enter a share code (e.g. N51212)", "");
		
		if (shareCodeReceived != null) {
			try {
				var data = await dynamoDb.getPrivateSharePromise(shareCodeReceived);
				if (!_.isEmpty(data)) {
					var shareCodeObject = data.Item;
					if (shareCodeObject.publisher == "checkmate" && (document.publisher != "checkmate" || (document.publisher == "checkmate" && document.cloned))) {
						alert("This checklist does not have a CheckMate license. Please purchase a checklist with a CheckMate license from the Home or Search page before replacing.");
					} else {
						document.shareCodeReceived = shareCodeReceived;

						await dynamoDb.putPrivateShareReplacedPromise({shareCode: shareCodeReceived, checklistId: document.id, identityId: identityId, publisher: document.publisher});
						
						awsS3.updateDocument(document);

						//var newDocuments = _.cloneDeep(state.get(["documents"]));
						//newDocuments.pop();

						//awsS3.saveJsonToS3(newDocuments,contentBucket,"checklists.json");

						awsS3.replaceDocument(document,shareCodeObject.identityId,shareCodeObject.checklistId);
					}
				} else {
					alert("The Share Code " + shareCodeReceived + " was not found.");
				}
			} catch (e) {	
				console.log(e);
				alert(e);		
			}
		}
	
	} else {
		try {
			var data = await dynamoDb.getPrivateSharePromise(document.shareCodeReceived);
			if (!_.isEmpty(data)) {
				var shareCodeObject = data.Item;
				// We now have the identityId and checkistId we need to replace checklist
				awsS3.replaceDocument(document,shareCodeObject.identityId,shareCodeObject.checklistId);
			} else {
				alert("The Share Code " + shareCodeReceived + " was not found.");
			}
		} catch (e) {
			console.log(e);
			alert(e);		
		}
	}
}

export function receiveDocuments(documents) {
	var newDocument = {
		"id": utils.generateUUID(),
		"newDoc": true,
		"name": "",
		"description": "",
		"genre": "",
		"tags": [],
		"publisher": "self",
		"store": "",
		"productPlaneId": "",
		"image": "",
		"shareCode": "",
		"shareCodeReceived": "",
		"version": "1.0",
		"cloned": false,
		"visible": true,
		"speedType": "KIAS"
	};
	documents.unshift(newDocument);

	utils.setDocuments(state, documents);

	// Route to editor
	//const path = "/search";
    //browserHistory.push(path);	
}

export function setFilterGenreProperty(tree,property,value) {
	var genres = tree.get(["appState","filters","all","genres"]);

	var index = -1;
	for (var i=0; i<genres.length; i++) {
		var genre = genres[i];
		if (genre.genre === property) {
			index = i;
		}
	}

	if (index >= 0) {
		tree.set(["appState","filters","all","genres",index,"checked"],value);
	}

	utils.refreshDocuments(tree,tree.get(["documents"]));
}

export function setFilterTagProperty(tree,property,value) {
	var tags = tree.get(["appState","filters","all","tags"]);

	var index = -1;
	for (var i=0; i<tags.length; i++) {
		var tag = tags[i];
		if (tag.tag === property) {
			index = i;
		}
	}

	if (index >= 0) {
		tree.set(["appState","filters","all","tags",index,"checked"],value);
	}

	utils.refreshDocuments(tree,tree.get(["documents"]));
}

export function goHome(tree) {
	if (!tree) {
		tree=state;
	}
	tree.unset(["appState","urlParams","session_id"]);
	tree.unset(["appState","urlParams","subscriptionPlan"]);
	tree.unset(["appState","urlParams","voucher"]);
	tree.set(["appState","navPanel","selected"],"home");
	const path = "/home";
	browserHistory.push(path);	
}

export function receiveData(srcTree, checklistId) {
	srcTree = transformUtils.transformToChecklistEntities(srcTree);

	// Let's have this act on dstTree, not srcTree
	srcTree.root.selected = true;
	srcTree.root.expanded = true;
	resetTree(srcTree.root.children);

	state.set("selectedChecklistId",srcTree.root.id);
	state.set("selectedNodePath",["tree","root"]);
	state.set("history",[]);
	state.set("historyIndex",-1);
	//state.set("clipboard",{});
	//state.set("clipboardMode","");
	state.set("tree",srcTree);

	saveUndoState();

	// Route to editor
	const path = "/editor/" + checklistId;
	browserHistory.push(path);
}

export function passwordlessLogin(origin) {
	origin = origin.replace("/magiclink/","");
	const originParts = origin.split(",");

	portalActions.passwordlessLoginUser(state, originParts[0], originParts[1]);
}

export function forceLogin(browserHistory, origin, forceSignUp=false) {
	
	if (origin === '/editor') {
		origin = '/myHangar';
	}
	state.set(["user","entryPoint"],origin);

	let path = "/login";
	if (forceSignUp) {
		path = "/portalHome";
	}
	browserHistory.push(path);
}

export function saveUndoState() {
	// Always splice anything to right
	if (state.get("history").length >= 0 && (state.get("historyIndex") < state.get("history").length-1)) {
		state.get("history").splice([state.get("historyIndex")+1]);
	}

	state.get("history").push(
		{
			tree: state.get("tree"),
			selectedNodePath: Object.assign([],state.get("selectedNodePath")),
			typeahead: state.get("typeahead")
		}
	);
	state.set("historyIndex",state.get("historyIndex")+1);

	changePropertyCount = 0;
}

function resetTree(arr) {
	for (var i=0; i<arr.length; i++) {
		arr[i].selected = false;
		arr[i].expanded = false;
		if( arr[i].hasOwnProperty("children")) {
			resetTree(arr[i].children);
		}
	}
}

export function idExists(tree, id) {
	var typeahead = tree.get("typeahead");
	for (var i=0; i<typeahead.length; i++) {
		if (typeahead[i].id === id) {
			return true;
		}
	}
}

export function setNavPanelSelected(tree, selectedId) {
	tree.set(["appState","navPanel","selected"],selectedId);

	var path;
	if (selectedId === "home") {
		path = "/home";
	} else if (selectedId === "search") {
		path = "/search";
	} else if (selectedId === "myChecklists") {
		path = "/myHangar";
	} else if (selectedId === "history") {
		path = "/history";
	} else if (selectedId === "timers") {
		path = "/timers";
	} else if (selectedId === "import") {
		path = "/import";
	}
	else if (selectedId === "afmpoh") {
		path = "/afmpoh";
	}
	else if (selectedId === "flightSchools") {
		path = "/flightSchools";
	}
    browserHistory.push(path);		
}

export function handleChangeItemType(tree, value) {
	var user = tree.get(["user"]);

	if (!user.subscriptionPlan.startsWith("pro-plan") && value !== "item") {
		alert("This item type is only supported for Pro plan subscribers. If you want to test the capability, you can turn on Preview Pro in the mobile app to see how these work.");
	}

	var arrPath = tree.select('selectedNodePath').get();
	var oldItem = tree.select(arrPath).get(["entity"]);

	var newItem = _.cloneDeep(oldItem);

	newItem.type = value;

	var deltaProps;
	// Set default content for each type
	if (value === "itemTextInput" || value === "itemTextInputMultiline") {
		deltaProps =
		{
			textInputPlaceholder: newItem.hasOwnProperty("textInputPlaceholder")?newItem.textInputPlaceholder:"",
			textInputDefaultValue: newItem.hasOwnProperty("textInputDefaultValue")?newItem.textInputDefaultValue:"",
			textInputMaxLength: newItem.hasOwnProperty("textInputMaxLength")?newItem.textInputMaxLength:1000,
			textInputKeyboardType: newItem.hasOwnProperty("textInputKeyboardType")?newItem.textInputKeyboardType:"default",
			textInputKeyboardAutoCapitalize: newItem.hasOwnProperty("textInputKeyboardAutoCapitalize")?newItem.textInputKeyboardAutoCapitalize:"sentences",
			textInputKeyboardAutoCorrect: newItem.hasOwnProperty("textInputKeyboardAutoCorrect")?newItem.textInputKeyboardAutoCorrect:true,
			textInputKeyboardReturnKeyType: newItem.hasOwnProperty("textInputKeyboardReturnKeyType")?newItem.textInputKeyboardReturnKeyType:"default",
			textInputMaskType: newItem.hasOwnProperty("textInputMaskType")?newItem.textInputMaskType:"",
			textInputCurrencySymbol: newItem.hasOwnProperty("textInputCurrencySymbol")?newItem.textInputCurrencySymbol:"$",
			textInputCurrencySeparator: newItem.hasOwnProperty("textInputCurrencySeparator")?newItem.textInputCurrrencySeparator:",",
			textInputNumberOfLines: newItem.hasOwnProperty("textInputNumberOfLines")?newItem.textInputNumberOfLines:4
		}
	} else if (value === "itemDate" || value === "itemTime" || value === "itemDateTime") {
		deltaProps = {
			dateTimeType: newItem.hasOwnProperty("dateTimeType")?newItem.dateTimeType:"local",
			dateTimeInitialDate: newItem.hasOwnProperty("dateTimeInitialDate")?newItem.dateTimeInitialDate:"today",
			dateTimeMinuteInterval: newItem.hasOwnProperty("dateTimeMinuteInterval")?newItem.dateTimeMinuteInterval:1
		}	
	} else if (value === "itemYesNo") {
		deltaProps = {
			yesNoLinkOnSelect: newItem.hasOwnProperty("yesNoLinkOnSelect")?newItem.yesNoLinkOnSelect:false,
			yesNoLinkActionType: newItem.hasOwnProperty("yesNoLinkActionType")?newItem.yesNoLinkActionType:"goto",
			yesNoYesLinkId: newItem.hasOwnProperty("yesNoYesLinkId")?newItem.yesNoYesLinkId:"",
			yesNoNoLinkId: newItem.hasOwnProperty("yesNoNoLinkId")?newItem.yesNoNoLinkId:"",
		}
	} else if (value === "itemPicker") {
		deltaProps = {
			pickerItemViewType: newItem.hasOwnProperty("pickerItemViewType")?newItem.pickerItemViewType:"picker",
			pickerItemPlaceholder: newItem.hasOwnProperty("pickerItemPlaceholder")?newItem.pickerItemPlaceholder:"",
			pickerItemDefaultValue: newItem.hasOwnProperty("pickerItemDefaultValue")?newItem.pickerItemDefaultValue:"",
			pickerItems: newItem.hasOwnProperty("pickerItems")?newItem.pickerItems:[],
			pickerAdvanceOnSelect: newItem.hasOwnProperty("pickerAdvanceOnSelect")?newItem.pickerAdvanceOnSelect:false,
			pickerLinkOnSelect: newItem.hasOwnProperty("pickerLinkOnSelect")?newItem.pickerLinkOnSelect:false,
			pickerLinkActionType: newItem.hasOwnProperty("pickerLinkActionType")?newItem.pickerLinkActionType:"goto"
		}
	} else if (value === "itemBarcodeScanner") {
		deltaProps = {
			barcodeTypes: newItem.hasOwnProperty("barcodeTypes")?newItem.barcodeTypes:[]
		}	
	} else if (value === "itemImagePicker") {
		deltaProps = {
			imagePickerMediaType: newItem.hasOwnProperty("imagePickerMediaType")?newItem.imagePickerMediaType:"photo",
			imagePickerAddMediaButton: newItem.hasOwnProperty("imagePickerAddMediaButton")?newItem.imagePickerAddMediaButton:"Add Photo",
			imagePickerUploadTitle: newItem.hasOwnProperty("imagePickerUploadTitle")?newItem.imagePickerUploadTitle:"Upload",
			imagePickerCaptureMediaTitle: newItem.hasOwnProperty("imagePickerCaptureMediaTitle")?newItem.imagePickerCaptureMediaTitle:"Take Photo..."
		}
	} else if (value === "itemSketchPad") {
		deltaProps = {
			sketchPadBackgroundColor: newItem.hasOwnProperty("sketchPadBackgroundColor")?newItem.sketchPadBackgroundColor:"#ffffff",
			sketchPadPenColor: newItem.hasOwnProperty("sketchPadPenColor")?newItem.sketchPadPenColor:"#000000",
			sketchPadPenWidth: newItem.hasOwnProperty("sketchPadPenWidth")?newItem.sketchPadPenWidth:5,
			sketchPadHeight: newItem.hasOwnProperty("sketchPadHeight")?newItem.sketchPadHeight:190
		}
	}

	var newItemMerged = _.merge({}, newItem, deltaProps);

	tree.select(arrPath).set(["entity"],newItemMerged);

	// Future use for blocks of undo
	changePropertyCount++;

	saveUndoState();
}

export function handleAddPickerItem(tree, prop, label, value, properties) {
	try {
		if (properties && properties !== "") {
			JSON.parse(properties);
		}

		let arrPath = tree.select("selectedNodePath").get();
		tree.select(arrPath).push(prop, { label: label, value: value, properties: properties });

		// Future use for blocks of undo
		changePropertyCount++;

		saveUndoState();
	} catch (e) {
		alert("The properties must be valid JSON.");		
	}

}

export function updatePickerItem(idx, prop, label, value, properties) {
	try {
		if (properties && properties !== "") {
			JSON.parse(properties);
		}

		let arrPath = state.select("selectedNodePath").get();
		state.select(_.concat(arrPath, prop)).splice([idx, 1, { label: label, value: value, properties: properties }]);

		// Future use for blocks of undo
		changePropertyCount++;

		saveUndoState();
	} catch (e) {
		alert("The properties must be valid JSON.");
	}

}

export function handleDeletePickerItem(tree, prop, index) {
	let arrPath = tree.select("selectedNodePath").get();
	tree.select(arrPath).splice(prop, [index, 1]);

	// Future use for blocks of undo
	changePropertyCount++;

	saveUndoState();
}

export function handleMovePickerItemUp(tree, prop, index) {
	let arrPath = tree.select("selectedNodePath").get();

	let moveItem = tree.select(arrPath).get(prop)[index];

	tree.select(arrPath).splice(prop, [index, 1]);
	tree.select(arrPath).splice(prop, [index - 1, 0, moveItem]);

	// Future use for blocks of undo
	changePropertyCount++;

	saveUndoState();
}

export function handleMovePickerItemDown(tree, prop, index) {
	let arrPath = tree.select("selectedNodePath").get();

	let moveItem = tree.select(arrPath).get(prop)[index];

	tree.select(arrPath).splice(prop, [index, 1]);
	tree.select(arrPath).splice(prop, [index + 1, 0, moveItem]);

	// Future use for blocks of undo
	changePropertyCount++;

	saveUndoState();
}

// These need to pass in node working on instead of selected node
export function handleChangeProperty(tree, property, value) {
	var arrPath = tree.select('selectedNodePath').get();
	tree.select(arrPath).set(["entity",property],value);
	tree.commit();

	// Future use for blocks of undo
	changePropertyCount++;

	saveUndoState();
}

export function handleChangePropertyForPhase(tree, phase, property, value) {
	var arrPath = tree.select('selectedNodePath').get();

	const copilotOptionsPhases = tree.select(arrPath).get(["entity","copilotOptions","phases"]);

	const index = copilotOptionsPhases.findIndex(x => x.id === phase);

	tree.select(arrPath).set(["entity","copilotOptions","phases",index,property],value);
	tree.commit();

	// Future use for blocks of undo
	changePropertyCount++;

	saveUndoState();
}

export function handleChangePropertyForAdvancedPhaseOption(tree, phase, advancedOption, property, value) {
	var arrPath = tree.select('selectedNodePath').get();

	const copilotOptionsPhases = tree.select(arrPath).get(["entity","copilotOptions","phases"]);

	const index = copilotOptionsPhases.findIndex(x => x.id === phase);

	const optionsIndex = copilotOptionsPhases[index].options.findIndex(x => x.id === advancedOption);

	tree.select(arrPath).set(["entity","copilotOptions","phases",index,"options", optionsIndex, property],value);
	tree.commit();

	// Future use for blocks of undo
	changePropertyCount++;

	saveUndoState();
}

export function handleCopyPasteProperty(tree, srcProperty, dstProperty) {
	var arrPath = tree.select('selectedNodePath').get();
	var value = tree.select(arrPath).get(["entity",srcProperty]);
	tree.select(arrPath).set(["entity",dstProperty],value);
	tree.commit();

	// Future use for blocks of undo
	changePropertyCount++;

	saveUndoState();
}

export function handleChangePrintProperty(tree, property, value) {
	var arrPath = tree.select('selectedNodePath').get();
	tree.select(arrPath).set(["entity","printProperties",property],value);

	// Future use for blocks of undo
	changePropertyCount++;

	saveUndoState();
}

export function handleChangePropertyForComments(tree, value) {
	var arrPath = tree.select('selectedNodePath').get();
	//tree.select(arrPath).set(["entity","commentsAuthor"],value);
	tree.select(arrPath).set(["entity","commentsAuthor"],"");
	tree.select(arrPath).set(["entity","comments"],utils.createMarkupForComments(value));

	// Future use for blocks of undo
	changePropertyCount++;

	saveUndoState();
}

export function handleChangeValuesProperty(tree, property, value) {
	var arrPath = tree.select('selectedNodePath').get();
	tree.select(arrPath).set(["entity","values",property],value);

	changePropertyCount++;

	saveUndoState();
}

export function handleDeleteTag(tree, i) {
	var arrPath = tree.select('selectedNodePath').get();
	var tags = tree.select(arrPath).select(["entity","tags"]);

	tags.splice([i,1]);
}

export function handleAddTag(tree, tag) {
	var arrPath = tree.select('selectedNodePath').get();
	var tags = tree.select(arrPath).select(["entity","tags"]);
	
	tags.push(tag);
}

export function handleDeleteGroupName(tree, i) {
	const arrPath = tree.select("selectedNodePath").get();
	let groupNames = tree.select(arrPath).select(["entity", "groupNames"]);

	groupNames.splice([i, 1]);

	// Also remove from global state
	// Would need to check if any other items are referencing
}

export function handleAddGroupName(tree, groupName) {
	if (groupName.indexOf(" ") > -1) {
		alert("Sorry, a group name should not contain have any spaces.");
		return;
	}

	const arrPath = tree.select("selectedNodePath").get();
	let groupNames = tree.select(arrPath).select(["entity", "groupNames"]);

	groupNames.push(groupName);

	// Also add to global state
	let globalGroupNames = tree.get("groupNames");
	if (globalGroupNames.indexOf(groupName) === -1) {
		globalGroupNames.push(groupName);
	}
	tree.set(["groupNames"],globalGroupNames);
}

export function handleCopyGroupNames(tree) {
	const arrPath = tree.select("selectedNodePath").get();
	let groupNames = tree.select(arrPath).get(["entity", "groupNames"]);

	global.groupNames = groupNames;
}

export function handlePasteGroupNames(tree) {
	const arrPath = tree.select("selectedNodePath").get();
	let groupNames = tree.select(arrPath).get(["entity", "groupNames"]);
	let groupNamesCursor = tree.select(arrPath).select(["entity", "groupNames"]);

	for (let i=0; i<global.groupNames.length; i++) {
		let groupName = global.groupNames[i];
		
		if (groupNames.indexOf(groupName) === -1) {
			groupNamesCursor.push(groupName);
		}
	}

	saveUndoState();
}


export function handleDeleteFilterTag(tree, i) {
	const arrPath = tree.select("selectedNodePath").get();
	let filterTags = tree.select(arrPath).select(["entity", "filterTags"]);

	filterTags.splice([i, 1]);

	// Also remove from global state
	// Would need to check if any other items are referencing
}

export function handleAddFilterTag(tree, filterTag) {
	const arrPath = tree.select("selectedNodePath").get();
	let filterTags = tree.select(arrPath).select(["entity", "filterTags"]);

	filterTags.push(filterTag);

	// Also add to global state
	let globalFilterTags = tree.get("filterTags");
	if (globalFilterTags.indexOf(filterTag) === -1) {
		globalFilterTags.push(filterTag);
	}
	tree.set(["filterTags"], globalFilterTags);
}

export function handleCopyFilterTags(tree) {
	const arrPath = tree.select("selectedNodePath").get();
	let filterTags = tree.select(arrPath).get(["entity", "filterTags"]);

	global.filterTags = filterTags;
}

export function handlePasteFilterTags(tree) {
	const arrPath = tree.select("selectedNodePath").get();
	let filterTags = tree.select(arrPath).get(["entity", "filterTags"]);
	let filterTagsCursor = tree.select(arrPath).select(["entity", "filterTags"]);

	for (let i=0; i<global.filterTags.length; i++) {
		let filterTag = global.filterTags[i];
		
		if (filterTags.indexOf(filterTag) === -1) {
			filterTagsCursor.push(filterTag);
		}
	}

	saveUndoState();
}

function unselectNode(tree) {
	var oldSelectedNodePath = tree.select('selectedNodePath').get();
	oldSelectedNodePath.push("selected");
	tree.set(oldSelectedNodePath,false);

	tree.set(['selectedNodePath'],[]);
}

export function handleSelectNodeAtPath(tree, arrPath) {
	var oldSelectedNodePath = tree.select('selectedNodePath').get();
	oldSelectedNodePath.push("selected");
	tree.set(oldSelectedNodePath,false);

	tree.set(['selectedNodePath'],arrPath);

	var selectedNodePath = arrPath;
	selectedNodePath.push("selected");
	tree.set(selectedNodePath,true);
}

export function handleUndo(tree) {
	var historyIndex = tree.get("historyIndex");
	if (historyIndex > 0) {
		historyIndex--;
		var history = tree.get(["history",historyIndex]);
		tree.set("tree",history.tree);
		tree.set("selectedNodePath",history.selectedNodePath);
		tree.set("historyIndex",historyIndex);
	}
}

export function handleRedo(tree) {
	var historyIndex = tree.get("historyIndex");
	if (historyIndex < tree.get("history").length-1) {
		historyIndex++;
		var history = tree.get(["history",historyIndex]);
		tree.set("tree",history.tree);
		tree.set("selectedNodePath",history.selectedNodePath);
		tree.set("historyIndex",historyIndex);
	}

}

// Clean up select code
export function handleSelectNodeParent(tree) {
	if (nothingSelected(tree)) {
		return;
	}

	var oldSelectedNodePath = tree.select('selectedNodePath').get();
	var oldSelectedNodeCursor = tree.select(oldSelectedNodePath);

	if (isNothingSelected(oldSelectedNodeCursor)) {
		return;
	}

	var selectedNodeCursor = oldSelectedNodeCursor.up().up();
	if (selectedNodeCursor != null && selectedNodeCursor.path.length > 0) {
		oldSelectedNodePath.push("selected");
		tree.set(oldSelectedNodePath,false);

		// Weirdness if use path directly
		var arrPath = Object.assign([],selectedNodeCursor.path);
		tree.set(['selectedNodePath'],arrPath);

		var selectedNodePath = arrPath;
		selectedNodePath.push("selected");
		tree.set(selectedNodePath,true);
	}
}

export function handleSelectNodeFirstChild(tree) {
	if (nothingSelected(tree)) {
		return;
	}

	var oldSelectedNodePath = tree.select('selectedNodePath').get();
	var oldSelectedNodeCursor = tree.select(oldSelectedNodePath);

	if (!oldSelectedNodeCursor.get().hasOwnProperty("children")) {
		return;
	}

	// Expand if not already
	if (!isNodeExpanded(oldSelectedNodeCursor)) {
		handleToggleNode(oldSelectedNodeCursor);
	}

	var selectedNodeCursor = oldSelectedNodeCursor.select("children").down();
	if (selectedNodeCursor != null) {
		oldSelectedNodePath.push("selected");
		tree.set(oldSelectedNodePath,false);

		// Weirdness if use path directly
		var arrPath = Object.assign([],selectedNodeCursor.path);
		tree.set(['selectedNodePath'],arrPath);

		var selectedNodePath = arrPath;
		selectedNodePath.push("selected");
		tree.set(selectedNodePath,true);
	}
}

function refreshVisible(tree) {
	_visiblePaths = [];
	var cursor = tree.select(["tree","root"]);
	recurseRefreshVisible(null,cursor);
}

export function handleSelectNodeDownNew(tree) {
	refreshVisible(tree);

	if (nothingSelected(tree)) {
		return;
	}

	var selectedIndex;

	for (var i=0; i<_visiblePaths.length; i++) {
		if (JSON.stringify(tree.select('selectedNodePath').get()) == JSON.stringify(_visiblePaths[i])) {
			selectedIndex = i;
		}
	}

	var oldSelectedNodePath = tree.select('selectedNodePath').get();
	var oldSelectedNodeCursor = tree.select(oldSelectedNodePath);

	if (isNothingSelected(oldSelectedNodeCursor)) {
		return;
	}
	/*
	 if (isTreeRoot(oldSelectedNodeCursor)) {
	 return;
	 }
	 */

//	var selectedNodeCursor = oldSelectedNodeCursor.right();
//	if (selectedNodeCursor != null) {


	if (selectedIndex < _visiblePaths.length-1) {
		oldSelectedNodePath.push("selected");
		tree.set(oldSelectedNodePath,false);

		var arrPath = Object.assign([],_visiblePaths[selectedIndex+1]);
		tree.set(['selectedNodePath'],arrPath);


		var selectedNodePath = arrPath;
		selectedNodePath.push("selected");
		tree.set(selectedNodePath,true);
	}

	/*
	 // Weirdness if use path directly
	 var arrPath = Object.assign([],selectedNodeCursor.path);
	 tree.set(['selectedNodePath'],arrPath);


	 var selectedNodePath = arrPath;
	 selectedNodePath.push("selected");
	 tree.set(selectedNodePath,true);
	 */
//	}
}

export function handleSelectNodeUpNew(tree) {
	_visiblePaths = [];
	var cursor = tree.select(["tree","root"]);
	recurseRefreshVisible(null,cursor);

	if (nothingSelected(tree)) {
		return;
	}

	var selectedIndex;

	for (var i=0; i<_visiblePaths.length; i++) {
		if (JSON.stringify(tree.select('selectedNodePath').get()) == JSON.stringify(_visiblePaths[i])) {
			selectedIndex = i;
		}
	}

	var oldSelectedNodePath = tree.select('selectedNodePath').get();
	var oldSelectedNodeCursor = tree.select(oldSelectedNodePath);

	if (isNothingSelected(oldSelectedNodeCursor)) {
		return;
	}

	if (selectedIndex > 0) {
		oldSelectedNodePath.push("selected");
		tree.set(oldSelectedNodePath,false);

		var arrPath = Object.assign([],_visiblePaths[selectedIndex-1]);
		tree.set(['selectedNodePath'],arrPath);

		var selectedNodePath = arrPath;
		selectedNodePath.push("selected");
		tree.set(selectedNodePath,true);
	}
}

export function handleSelectNodeDown(tree) {
	if (nothingSelected(tree)) {
		return;
	}

	var oldSelectedNodePath = tree.select('selectedNodePath').get();
	var oldSelectedNodeCursor = tree.select(oldSelectedNodePath);

	if (isNothingSelected(oldSelectedNodeCursor)) {
		return;
	}
	if (isTreeRoot(oldSelectedNodeCursor)) {
		return;
	}

	var selectedNodeCursor = oldSelectedNodeCursor.right();
	if (selectedNodeCursor != null) {
		oldSelectedNodePath.push("selected");
		tree.set(oldSelectedNodePath,false);

		// Weirdness if use path directly
		var arrPath = Object.assign([],selectedNodeCursor.path);
		tree.set(['selectedNodePath'],arrPath);

		var selectedNodePath = arrPath;
		selectedNodePath.push("selected");
		tree.set(selectedNodePath,true);
	}
}

export function handleSelectNodeUp(tree) {
	if (nothingSelected(tree)) {
		return;
	}

	var oldSelectedNodePath = tree.select('selectedNodePath').get();
	var oldSelectedNodeCursor = tree.select(oldSelectedNodePath);

	if (isNothingSelected(oldSelectedNodeCursor)) {
		return;
	}
	if (isTreeRoot(oldSelectedNodeCursor)) {
		return;
	}

	var selectedNodeCursor = oldSelectedNodeCursor.left();
	if (selectedNodeCursor != null) {
		oldSelectedNodePath.push("selected");
		tree.set(oldSelectedNodePath,false);

		// Weirdness if use path directly
		var arrPath = Object.assign([],selectedNodeCursor.path);
		tree.set(['selectedNodePath'],arrPath);

		var selectedNodePath = arrPath;
		selectedNodePath.push("selected");
		tree.set(selectedNodePath,true);
	}
}

function getTreeRoot(tree) {
	return tree.select(["tree","root"]);
}


function nothingSelected(tree) {
	var oldSelectedNodePath = tree.select('selectedNodePath').get();
	var oldSelectedNodeCursor = tree.select(oldSelectedNodePath);

	return isNothingSelected(oldSelectedNodeCursor);
}

function isNothingSelected(cursor) {
	return cursor.path.length == 0;
}

function isTreeRoot(cursor) {
	return cursor.path.length == 2;
}

function isNodeExpanded(cursor) {
	return cursor.get().expanded == true;
}

export function handleCollapseAll(tree) {
	var cursor = tree.select(tree.select('selectedNodePath').get());

	var root = tree.get(tree.select('selectedNodePath').get());
	if (root.children.length === 0 || !root.children[0].hasOwnProperty("entity")) {
		return;
	}

	if (cursor.path.length == 0) {
		cursor = getTreeRoot(tree);
	}

	if (cursor.select("children").isBranch()) {
		cursor.set("expanded", false);
		recurseCollapse(cursor.select("children").down());
	}
}

export function handleExpandAll(tree) {
	var cursor = tree.select(tree.select('selectedNodePath').get());

	var root = tree.get(tree.select('selectedNodePath').get());
	if (root.children.length === 0 || !root.children[0].hasOwnProperty("entity")) {
		return;
	}


	if (cursor.path.length == 0) {
		cursor = getTreeRoot(tree);
	}

	if (cursor.select("children").isBranch()) {
		cursor.set("expanded",true);
		recurseExpand(cursor.select("children").down());
	}
}

export function handleToggleNode(cursor) {
	cursor.set("expanded",!cursor.get("expanded"));
}

export function handleExpandNode(cursor) {
	cursor.set("expanded",true);
}

export function handleCollapseNode(cursor) {
	cursor.set("expanded",false);
}

export function handleRemoveNode(tree, cursor) {
	if (nothingSelected(tree)) {
		return;
	}
	if (isTreeRoot(cursor)) {
		return;
	}

	unselectNode(tree);

	var index = cursor.path[cursor.path.length-1];
	cursor.up().splice([index,1]);

	saveUndoState();
}

export function handleCut(tree) {
	if (nothingSelected(tree)) {
		return;
	}

	tree.set("clipboardMode", "cut");

	let cursor = tree.select(tree.select("selectedNodePath").get());

	tree.set("clipboard", cursor.get());
	tree.set(["clipboard", "selected"], false);

	let index = cursor.path[cursor.path.length - 1];

	unselectNode(tree);

	cursor.up().splice([index, 1]);

	saveUndoState();
}

export function handleCopy(tree) {
	if (nothingSelected(tree)) {
		return;
	}

	tree.set("clipboardMode", "copy");
	let cursor = tree.select(tree.select("selectedNodePath").get());
	//tree.set("clipboard",tree.select('selectedNodePath').get());

	tree.set("clipboard", cursor.get());
	//saveUndoState();
}

function canPaste(tree) {
	let cursor = tree.select(tree.select("selectedNodePath").get());
	let srcNode = cursor.get();

	let pasteNode = tree.get("clipboard");

	if (!pasteNode.hasOwnProperty("entity")) {
		return false;
	} else if (pasteNode.entity.type === "checklist") {
		return false;
		// If pasting on the same type
	} else if (srcNode.entity.type === pasteNode.entity.type) {
		return true;
	} else if (srcNode.entity.type.startsWith("item") && pasteNode.entity.type.startsWith("item")) {
		return true;
	} else {
		if (srcNode.entity.type === "checklist" && srcNode.children.length === 0) {
			return true;
		} else if (pasteNode.entity.type === "list" && srcNode.entity.type === "checklist") {
			if ((srcNode.children.length > 0 && srcNode.children[0].entity.type === "list") || (srcNode.length === 0)) {
				return true;
			}
		} else if (pasteNode.entity.type === "section" && srcNode.entity.type === "checklist") {
			if ((srcNode.children.length > 0 && srcNode.children[0].entity.type === "section") || (srcNode.length === 0)) {
				return true;
			}
		} else if (pasteNode.entity.type.startsWith("item") && srcNode.entity.type.startsWith("item")) {
			if ((srcNode.children.length > 0 && srcNode.children[0].entity.type === "list") || (srcNode.length === 0)) {
				return true;
			}
		} else if (pasteNode.entity.type === "section" && srcNode.entity.type === "list") {
			return true;
		} else if (pasteNode.entity.type.startsWith("item") && srcNode.entity.type === "section") {
			return true;
		}
	}

	showError("Invalid", "You can't paste this here.");

	return false;
}

// TODO: Try to be clever for default add when not a category
export function handlePaste(tree, positionType = "lastChild") {
	// Can only paste a list onto a checklist where all children are lists or another list
	// Can only paste a section onto a list where are children are sections or another section
	// Can only paste an item onto a section where all children are items or another item

	if (nothingSelected(tree)) {
		return;
	}
	if (tree.get("clipboard") === null) {
		return;
	}
	if (!canPaste(tree)) {
		return;
	}

	let newNode;

	if (tree.get("clipboardMode") === "copy") {
		//var copiedNodePath = tree.get("clipboard");

		let node = tree.get("clipboard");

		let entityId = utils.generateUUID();
		// const guid = utils.generateUUID();
		newNode = _.cloneDeep(node);
		newNode.entity.entityId = entityId;
		// newNode.entity.guid = guid;

		//newNode.entity.id = entityId;
		newNode.selected = false;

		if (newNode.hasOwnProperty("children")) {
			recurseUpdateIds(newNode.children);
		}
	} else if (tree.get("clipboardMode") === "cut") {
		newNode = tree.get("clipboard");
	}
	let index, arrPath;
	if (tree.get("clipboardMode") === "copy" || tree.get("clipboardMode") === "cut") {
		let cursor = tree.select(tree.select("selectedNodePath").get());
		// If pasting on the same type
		if (cursor.get().entity.type === newNode.entity.type || (cursor.get().entity.type.startsWith("item") && newNode.entity.type.startsWith("item"))) {
			if (positionType === "lastChild") {
				positionType = "after";
			}
		}
		// eslint-disable-next-line default-case
		switch (positionType) {
		case "firstChild":
			cursor.set("expanded", true);
			cursor.select("children").unshift(newNode);
			break;
		case "lastChild":
			cursor.set("expanded", true);
			cursor.select("children").push(newNode);
			break;
		case "before":
			unselectNode(tree);
			index = cursor.path[cursor.path.length - 1];
			cursor.up().splice([index, 0, newNode]);
			arrPath = Object.assign([], cursor.path);
			arrPath[arrPath.length - 1] = arrPath[arrPath.length - 1] + 1;
			handleSelectNodeAtPath(tree, arrPath);
			break;
		case "after":
			index = cursor.path[cursor.path.length - 1];
			cursor.up().splice([index + 1, 0, newNode]);
			break;
		}
	}

	saveUndoState();
}

// Only allow to paste as last child if checklist node is selected, nextSibling if List node is selected
export function handlePasteLists(tree, positionType = "lastChild") {
	if (nothingSelected(tree)) {
		return;
	}
	if (tree.get("clipboard") === null) {
		return;
	}
	
	if (!canPasteLists(tree)) {
		return;
	}

	let newNode;

	if (tree.get("clipboardMode") === "copy") {
		//var copiedNodePath = tree.get("clipboard");

		let node = tree.get("clipboard");

		let entityId = utils.generateUUID();
		// const guid = utils.generateUUID();
		newNode = _.cloneDeep(node);
		newNode.entity.entityId = entityId;
		// newNode.entity.guid = guid;
		//newNode.entity.id = entityId;
		newNode.selected = false;

		if (newNode.hasOwnProperty("children")) {
			recurseUpdateIds(newNode.children);
		}
	} else if (tree.get("clipboardMode") === "cut") {
		newNode = tree.get("clipboard");
	}


	let index, arrPath;
	if (tree.get("clipboardMode") === "copy" || tree.get("clipboardMode") === "cut") {
		let cursor = tree.select(tree.select("selectedNodePath").get());
		// If pasting on the same type
		if (cursor.get().entity.type === "list") {
			if (positionType === "lastChild") {
				positionType = "after";
			}
		}
		// eslint-disable-next-line default-case
		switch (positionType) {
		case "firstChild":
			cursor.set("expanded", true);
			cursor.select("children").unshift(newNode);
			break;
		case "lastChild":
			cursor.set("expanded", true);
			//cursor.select("children").push(newNode);
				
			// if selected node is a List then just add the List otherwise add the children	
			if (newNode.entity.type === "list") {
				cursor.select("children").push(newNode);
			} else {
				cursor.select("children").concat(newNode.children);
			}

			break;
		case "before":
			unselectNode(tree);
			index = cursor.path[cursor.path.length - 1];
			cursor.up().splice([index, 0, newNode]);
			arrPath = Object.assign([], cursor.path);
			arrPath[arrPath.length - 1] = arrPath[arrPath.length - 1] + 1;
			handleSelectNodeAtPath(tree, arrPath);
			break;
		case "after": 
			// if selected node is a List then just add the List otherwise add the children	
			if (newNode.entity.type === "list") {
				index = cursor.path[cursor.path.length - 1];
				cursor.up().splice([index + 1, 0, newNode]);
			} else {
				let lists = cursor.up().get();

				index = cursor.path[cursor.path.length - 1];

				lists.splice(index + 1, 0, ...newNode.children);

				console.log(lists);

				cursor.up().set(["children"], lists);
			}

			break;
		}
	}

	saveUndoState();
}

function canPasteLists(tree) {
	// Check source content
	// All children must be a List node

	// Check destination
	// If Checklist node then append to children
	// If List node then append after selected

	let cursor = tree.select(tree.select("selectedNodePath").get());
	let selectedNode = cursor.get();
	let clipboardNode = tree.get("clipboard");

	if (!clipboardNode.hasOwnProperty("entity")) {
		return false;
	}

	if (clipboardNode.entity.type !== "checklist" && clipboardNode.entity.type !== "list") {
		alert("You have not copied any Lists to the clipboard.");
		return false;
	}

	if (selectedNode.entity.type === "checklist" || selectedNode.entity.type === "list") {
		return true;

	}

	alert("You can only paste Lists on the Actvitiy node (top-level root node) or another List node.");

	return false;
}

export function handlePasteSections(tree, positionType = "lastChild") {
	if (nothingSelected(tree)) {
		return;
	}
	if (tree.get("clipboard") === null) {
		return;
	}
	if (!canPasteSections(tree)) {
		return;
	}

	let newNode;
	let newSectionsChildren = [];

	if (tree.get("clipboardMode") === "copy") {
		//var copiedNodePath = tree.get("clipboard");

		let node = tree.get("clipboard");

		let entityId = utils.generateUUID();
		// const guid = utils.generateUUID();
		newNode = _.cloneDeep(node);
		newNode.entity.entityId = entityId;
		// newNode.entity.guid = guid;
		//newNode.entity.id = entityId;
		newNode.selected = false;

		if (newNode.hasOwnProperty("children")) {
			recurseUpdateIds(newNode.children);
		}

		if (newNode.hasOwnProperty("children")) {
			// Need to recurse from top and push each section into children arr
			recurseToSectionsChildren(newNode.children, newSectionsChildren);
		}
	} else if (tree.get("clipboardMode") === "cut") {
		newNode = tree.get("clipboard");
	}

	let index;
	if (tree.get("clipboardMode") === "copy" || tree.get("clipboardMode") === "cut") {
		let cursor = tree.select(tree.select("selectedNodePath").get());
		// If pasting on the same type
		if (cursor.get().entity.type === "section") {
			if (positionType === "lastChild") {
				positionType = "after";
			}
		}
		// eslint-disable-next-line default-case
		switch (positionType) {
		case "lastChild":
			// if selected node is a Section then just add the Section otherwise add the children	
			if (newNode.entity.type === "section") {
				cursor.select("children").push(newNode);
			} else {
				cursor.select("children").concat(newSectionsChildren);
			}

			cursor.set("expanded", true);
			break;
		case "after":
			// if selected node is a List then just add the List otherwise add the children	
			if (newNode.entity.type === "section") {
				index = cursor.path[cursor.path.length - 1];
				cursor.up().splice([index + 1, 0, newNode]);
			} else {
				let sections = cursor.up().get();

				index = cursor.path[cursor.path.length - 1];

				sections.splice(index + 1, 0, ...newSectionsChildren);

				console.log(sections);

				cursor.up().set(["children"], sections);
			}
				
				
				

			break;
		}
	}

	saveUndoState();
}

function canPasteSections(tree) {
	// Check source content
	// All children must be a Section node

	// Check destination
	// If Checklist node then add list and append to children
	// If List node then append to children
	// If Section node then append after selected
	let cursor = tree.select(tree.select("selectedNodePath").get());
	let selectedNode = cursor.get();
	let clipboardNode = tree.get("clipboard");

	if (!clipboardNode.hasOwnProperty("entity")) {
		return false;
	}

	if (clipboardNode.entity.type !== "checklist" && clipboardNode.entity.type !== "list" && clipboardNode.entity.type !== "section") {
		alert("You have not copied any Sections to the clipboard.");
		return false;
	}

	if (selectedNode.entity.type === "checklist" && (selectedNode.children.length === 0 || selectedNode.children[0].type === "section")) {
		return true;
	}

	if (selectedNode.entity.type === "list" || selectedNode.entity.type === "section") {
		return true;

	}

	alert("You can only paste Sections on a List node or another Section node or an Activity node (top-level root node) that already has Sections or is empty.");

	return false;
}

export function recurseToSectionsChildren(arr, resArr) {
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].entity.type === "list") {
			console.log("List: " + arr[i].entity.label);
			//resArr = resArr.concat(Object.assign([], arr[i].children));
		} else if (arr[i].entity.type === "section") {
			console.log("Section: " + arr[i].entity.label);
			resArr.push(Object.assign({}, arr[i]));
		} else if (arr[i].entity.type.startsWith("item")) {
			console.log("Item: " + arr[i].entity.label);
		}

		if (arr[i].hasOwnProperty("children")) {
			recurseToSectionsChildren(arr[i].children, resArr);
		}
	}
}

export function handlePasteItems(tree, positionType = "lastChild") {
	if (nothingSelected(tree)) {
		return;
	}
	if (tree.get("clipboard") === null) {
		return;
	}
	if (!canPasteItems(tree)) {
		return;
	}

	let newNode;
	let newItemsChildren = [];

	if (tree.get("clipboardMode") === "copy") {
		//var copiedNodePath = tree.get("clipboard");

		let node = tree.get("clipboard");

		let entityId = utils.generateUUID();
		// const guid = utils.generateUUID();
		newNode = _.cloneDeep(node);
		newNode.entity.entityId = entityId;
		// newNode.entity.guid = guid;
		//newNode.entity.id = entityId;
		newNode.selected = false;
		if (newNode.hasOwnProperty("children")) {
			recurseUpdateIds(newNode.children);
		}

		if (newNode.hasOwnProperty("children")) {
			// Need to recurse from top and push each section into children arr
			recurseToItemsChildren(newNode.children, newItemsChildren);
		}
	} else if (tree.get("clipboardMode") === "cut") {
		newNode = tree.get("clipboard");
	}

	let index;
	if (tree.get("clipboardMode") === "copy" || tree.get("clipboardMode") === "cut") {
		let cursor = tree.select(tree.select("selectedNodePath").get());
		// If pasting on the same type
		if (cursor.get().entity.type.startsWith("item")) {
			if (positionType === "lastChild") {
				positionType = "after";
			}
		}
		// eslint-disable-next-line default-case
		switch (positionType) {
		case "lastChild":
			cursor.set("expanded", true);
			// if selected node is an Item then just add the Section otherwise add the children	
			if (newNode.entity.type.startsWith("item")) {
				cursor.select("children").push(newNode);
			} else {
				cursor.select("children").concat(newItemsChildren);
			}
			break;
		case "after":
			// if selected node is an Item then just add the Item otherwise add the children	
			if (newNode.entity.type.startsWith("item")) {
				index = cursor.path[cursor.path.length - 1];
				cursor.up().splice([index + 1, 0, newNode]);
			} else {
				let items = cursor.up().get();

				index = cursor.path[cursor.path.length - 1];

				items.splice(index + 1, 0, ...newItemsChildren);

				console.log(items);

				cursor.up().set(["children"], items);
			}

			break;
		}
	}

	saveUndoState();
}

export function recurseToItemsChildren(arr, resArr) {
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].entity.type === "list") {
			console.log("List: " + arr[i].entity.label);
		} else if (arr[i].entity.type === "section") {
			console.log("Section: " + arr[i].entity.label);
		} else if (arr[i].entity.type.startsWith("item")) {
			console.log("Item: " + arr[i].entity.label);
			resArr.push(Object.assign({}, arr[i]));
		}

		if (arr[i].hasOwnProperty("children")) {
			recurseToItemsChildren(arr[i].children, resArr);
		}
	}
}

function canPasteItems(tree) {
	// Check source content
	// All children must be an Item node

	// Check destination
	// If Checklist node then append list and section then to children
	// If List node then append section then to children
	// If Section node then append to children
	// If Item node then append after selected
	let cursor = tree.select(tree.select("selectedNodePath").get());
	let selectedNode = cursor.get();
	let clipboardNode = tree.get("clipboard");

	if (!clipboardNode.hasOwnProperty("entity")) {
		return false;
	}

	if (clipboardNode.entity.type !== "checklist" && clipboardNode.entity.type !== "list" && clipboardNode.entity.type !== "section" && !clipboardNode.entity.type.startsWith("item")) {
		alert("You have not copied any Items to the clipboard.");
		return false;
	}

	if (selectedNode.entity.type === "checklist" && (selectedNode.children.length === 0 || selectedNode.children[0].type.startsWith("item"))) {
		return true;
	}

	if (selectedNode.entity.type === "section" || selectedNode.entity.type.startsWith("item")) {
		return true;

	}

	alert("You can only paste Items on a Section node or another Item node or an Activity node (top-level root node) that already has Items or is empty.");

	return false;
}

function recurseUpdateIds(arr) {
	for (var i=0; i<arr.length; i++) {
		var entityId = utils.generateUUID();
		arr[i].entity.entityId = entityId;
		//arr[i].entity.id = entityId;
		//state.get("typeahead").push({id: entityId, label: entityId});
		if(arr[i].hasOwnProperty("children")) {
			recurseUpdateIds(arr[i].children);
		}
	}
}

export function handleMoveNode(tree, type, srcIndex, dstIndex) {
	unselectNode(tree);

	if (type === "root") {
		var arrList = tree.get(["tree","root","children"]);

		arrList.splice(dstIndex, 0, arrList.splice(srcIndex, 1)[0]);

		tree.set(["tree","root","children"],Object.assign([],arrList));

		var arrPath = Object.assign([],["tree","root","children",dstIndex]);
		
		handleSelectNodeAtPath(tree,arrPath);

		saveUndoState();
	} else {
		var entityChildren = { found: false };
		entityUtils.findEntityChildrenById(tree.get(["tree", "root", "children"]), type, entityChildren);
		if (entityChildren.found) {
			var arrNode = entityChildren.result;
			arrNode.splice(dstIndex, 0, arrNode.splice(srcIndex, 1)[0]);

			var entity = { found: false };
			entityUtils.findEntityIndicesById(tree.get(["tree", "root", "children"]), type, entity);

			if (entity.found) {
				if (entity.result.type === "list" && entity.result.listIndex > -1) {
					tree.set(["tree", "root", "children", entity.result.listIndex, "children"], Object.assign([], arrNode));
					const arrPathList = Object.assign([], ["tree", "root", "children", entity.result.listIndex, "children", dstIndex]);
					handleSelectNodeAtPath(tree, arrPathList);
				} else if (entity.result.type === "section" && entity.result.listIndex > -1) {
					tree.set(["tree", "root", "children", entity.result.listIndex, "children", entity.result.sectionIndex, "children"], Object.assign([], arrNode));
					const arrPathSection = Object.assign([], ["tree", "root", "children", entity.result.listIndex, "children", entity.result.sectionIndex, "children", dstIndex]);
					handleSelectNodeAtPath(tree, arrPathSection);
				} else if (entity.result.type === "section" && entity.result.listIndex === -1) {
					tree.set(["tree", "root", "children", entity.result.sectionIndex, "children"], Object.assign([], arrNode));
					const arrPathSection = Object.assign([], ["tree", "root", "children", entity.result.sectionIndex, "children", dstIndex]);
					handleSelectNodeAtPath(tree, arrPathSection);
				}

				saveUndoState();
			}
		}
	}
}

export function handleMoveNodeUp(tree,cursor) {
	if (nothingSelected(tree)) {
		return;
	}
	if (isTreeRoot(cursor)) {
		return;
	}

	// TODO: Quick fix...proper way to do is figure out how things get out of sync
	handleCollapseNode(cursor);

	var node = cursor.get();

	var index = cursor.path[cursor.path.length-1];
	if (index > 0) {
		unselectNode(tree);
		cursor.up().splice([index,1]);
		cursor.up().splice([index-1,0,node]);

		var arrPath = Object.assign([],cursor.path);
		arrPath[arrPath.length-1] = arrPath[arrPath.length-1]-1;
		handleSelectNodeAtPath(tree,arrPath);

		saveUndoState();
	}
}

export function handleMoveNodeDown(tree,cursor) {
	if (nothingSelected(tree)) {
		return;
	}
	if (isTreeRoot(cursor)) {
		return;
	}

	// TODO: Quick fix...proper way to do is figure out how things get out of sync
	handleCollapseNode(cursor);
	var node = cursor.get();

	var index = cursor.path[cursor.path.length-1];
	if (index < (cursor.up().get().length-1)) {
		unselectNode(tree);
		cursor.up().splice([index,1]);
		cursor.up().splice([index+1,0,node]);

		var arrPath = Object.assign([],cursor.path);
		arrPath[arrPath.length-1] = arrPath[arrPath.length-1]+1;
		handleSelectNodeAtPath(tree,arrPath);

		saveUndoState();
	}
}

export function handleAddCategory(tree, cursor, positionType = "lastChild") {
	if (nothingSelected(tree)) {
		return;
	}

	var entity = entityUtils.addCategoryEntity(utils.generateUUID(),"(New Category)",true);
	var node = entityUtils.addCategoryNode(entity,false);

	if (cursor.get().entity.type != "category") {
		if (positionType === "lastChild") {
			positionType = "after";
		}
	}
	switch (positionType) {
		case "firstChild":
			cursor.set("expanded",true);
			cursor.select("children").unshift(node);
			break;
		case "lastChild":
			cursor.set("expanded",true);
			cursor.select("children").push(node);
			break;
		case "before":
			unselectNode(tree);
			var index = cursor.path[cursor.path.length-1];
			cursor.up().splice([index,0,node]);
			var arrPath = Object.assign([],cursor.path);
			arrPath[arrPath.length-1] = arrPath[arrPath.length-1]+1;
			handleSelectNodeAtPath(tree,arrPath);
			break;
		case "after":
			var index = cursor.path[cursor.path.length-1];
			cursor.up().splice([index+1,0,node]);
			break;
	}
	saveUndoState();
}

export function handleAddList(tree, cursor, positionType = "lastChild") {
	if (nothingSelected(tree)) {
		return;
	}

	var entity = entityUtils.addListEntity(utils.generateUUID(),"",true);
	var node = entityUtils.addCategoryNode(entity,true);
	var sectionEntity = entityUtils.addSectionEntity(utils.generateUUID(),"");
	var sectionNode = entityUtils.addCategoryNode(sectionEntity,true);
	node.children.push(sectionNode);
	var itemEntity = entityUtils.addItemEntity(utils.generateUUID(),"","","",false,false,"");
	var itemNode = entityUtils.addItemNode(itemEntity);
	sectionNode.children.push(itemNode);
	if (cursor.get().entity.type == "list") {
		if (positionType === "lastChild") {
			positionType = "after";
		}
	}
	switch (positionType) {
		case "firstChild":
			if (cursor.get().entity.type == "checklist") {
				cursor.set("expanded",true);
				cursor.select("children").unshift(node);
				break;
			}
			break;
		case "lastChild":
			if (cursor.get().entity.type == "checklist") {
				if (cursor.get("children").length == 0 || cursor.get("children")[0].entity.type === "list") {
					unselectNode(tree);

					cursor.set("expanded",true);
					cursor.select("children").push(node);

					var len = cursor.get("children").length;

					var arrPath = Object.assign([],cursor.path);
					arrPath.push("children");
					arrPath.push(len-1);
					handleSelectNodeAtPath(tree,arrPath);
				// Migrate collection of items to this section
				} else if (cursor.get("children")[0].entity.type === "section") {
					node.expanded = true;
					node.children = cursor.get("children");
					cursor.select("children").splice([0,node.children.length]);

					unselectNode(tree);

					cursor.set("expanded",true);
					cursor.select("children").push(node);

					var len = cursor.get("children").length;

					var arrPath = Object.assign([],cursor.path);
					arrPath.push("children");
					arrPath.push(len-1);
					handleSelectNodeAtPath(tree,arrPath);
				} else if (cursor.get("children")[0].entity.type.startsWith("item")) {
					showError("Invalid","You can only add a List when all of the children of this node are Sections. First add a Section then you will be able to add a List.");
				} else {
					showError("Invalid","You can't add a List here. You can only add a List to the root node which is the Checklist.");
				}
				break;
			} else {
				showError("Invalid","You can't add a List here. You can only add a List to the root node which is the Checklist.");
			}
			break;
		case "before":
			if (cursor.get().entity.type == "list") {
				unselectNode(tree);
				var index = cursor.path[cursor.path.length-1];
				cursor.up().splice([index,0,node]);
				var arrPath = Object.assign([],cursor.path);
				arrPath[arrPath.length-1] = arrPath[arrPath.length-1]+1;
				handleSelectNodeAtPath(tree,arrPath);
				break;
			}	
			break;
		case "after":
			if (cursor.get().entity.type == "list") {
				unselectNode(tree);
				
				var index = cursor.path[cursor.path.length-1];
				cursor.up().splice([index+1,0,node]);

				var arrPath = Object.assign([],cursor.path);
				arrPath[arrPath.length-1] = arrPath[arrPath.length-1]+1;
				handleSelectNodeAtPath(tree,arrPath);
				break;
			} else {
				showError("Invalid","You can't add a List here. You can only add a List to the root node which is the Checklist.");
			}	
			break;
	}
	saveUndoState();
}

export function handleAddSection(tree, cursor, positionType = "lastChild") {
	if (nothingSelected(tree)) {
		return;
	}

	var entity = entityUtils.addSectionEntity(utils.generateUUID(),"",true);
	var node = entityUtils.addCategoryNode(entity,true);
	var itemEntity = entityUtils.addItemEntity(utils.generateUUID(),"","","",false,false,"");
	var itemNode = entityUtils.addItemNode(itemEntity);
	node.children.push(itemNode);

	if (cursor.get().entity.type == "section") {
		if (positionType === "lastChild") {
			positionType = "after";
		}
	}
	switch (positionType) {
		case "firstChild":
			if (cursor.get().entity.type == "checklist" || cursor.get().entity.type == "list") {
				cursor.set("expanded",true);
				cursor.select("children").unshift(node);
				break;
			}
			break;
		case "lastChild":
			if (cursor.get().entity.type == "checklist" || cursor.get().entity.type == "list") {
				// Check to see that first child is a isection
				if (cursor.get("children").length == 0 || cursor.get("children")[0].entity.type === "section") {
					unselectNode(tree);

					cursor.set("expanded",true);
					cursor.select("children").push(node);

					var len = cursor.get("children").length;

					var arrPath = Object.assign([],cursor.path);
					arrPath.push("children");
					arrPath.push(len-1);
					handleSelectNodeAtPath(tree,arrPath);
				// Migrate collection of items to this section
				} else if (cursor.get("children")[0].entity.type.startsWith("item")) {
					node.expanded = true;
					node.children = cursor.get("children");
					cursor.select("children").splice([0,node.children.length]);

					unselectNode(tree);

					cursor.set("expanded",true);
					cursor.select("children").push(node);

					var len = cursor.get("children").length;

					var arrPath = Object.assign([],cursor.path);
					arrPath.push("children");
					arrPath.push(len-1);
					handleSelectNodeAtPath(tree,arrPath);
				} else {
					showError("Invalid","You can't add a Section here. A Section should be added to a List.");
				}

				break;
			} else {
				showError("Invalid","You can't add a Section here. A Section should be added to a List.");				
			}
			break;
		case "before":
			if (cursor.get().entity.type == "section") {
				unselectNode(tree);
				var index = cursor.path[cursor.path.length-1];
				cursor.up().splice([index,0,node]);
				var arrPath = Object.assign([],cursor.path);
				arrPath[arrPath.length-1] = arrPath[arrPath.length-1]+1;
				handleSelectNodeAtPath(tree,arrPath);
				break;
			}	
			break;
		case "after":
			if (cursor.get().entity.type == "section") {
				unselectNode(tree);
				
				var index = cursor.path[cursor.path.length-1];
				cursor.up().splice([index+1,0,node]);

				var arrPath = Object.assign([],cursor.path);
				arrPath[arrPath.length-1] = arrPath[arrPath.length-1]+1;
				handleSelectNodeAtPath(tree,arrPath);
				break;
			} else {
				showError("Invalid","You can't add a Section here. A Section should be added to a List.");
			}	
			break;
	}
	saveUndoState();
}

export function handleAddItem(tree, cursor, positionType = "lastChild") {
	if (nothingSelected(tree)) {
		return;
	}

	var entityId = utils.generateUUID();
	var entity = entityUtils.addItemEntity(entityId,"","","",false,false,"");

	var node = entityUtils.addItemNode(entity,false);

	if (cursor.get().entity.type.startsWith("item")) {
		if (positionType === "lastChild") {
			positionType = "after";
		}
	}
	switch (positionType) {
		case "firstChild":
			if (cursor.get().entity.type == "section" || cursor.get().entity.type == "checklist") {
				cursor.set("expanded",true);
				cursor.select("children").unshift(node);
				break;
			} else {
				showError("Invalid","You can't add an Item here. An Item should be added to a Section.");
			}	
			break;
		case "lastChild":
			if (cursor.get().entity.type == "section" || cursor.get().entity.type == "checklist") {
				// Check to see that first child is an item
				if (cursor.get("children").length == 0 || cursor.get("children")[0].entity.type.startsWith("item")) {
					unselectNode(tree);

					cursor.set("expanded",true);
					cursor.select("children").push(node);

					var len = cursor.get("children").length;

					var arrPath = Object.assign([],cursor.path);
					arrPath.push("children");
					arrPath.push(len-1);
					handleSelectNodeAtPath(tree,arrPath);

				} else {
					showError("Invalid","You can't add an Item here. An Item should be added to a Section.");
				}
				break;
			} else {
				showError("Invalid","You can't add an Item here. An Item should be added to a Section.");
			}
			break;
		case "before":
			if (cursor.get().entity.type.startsWith("item")) {
				unselectNode(tree);
				var index = cursor.path[cursor.path.length-1];
				cursor.up().splice([index,0,node]);
				var arrPath = Object.assign([],cursor.path);
				arrPath[arrPath.length-1] = arrPath[arrPath.length-1]+1;
				handleSelectNodeAtPath(tree,arrPath);
				break;
			}	
			break;
		case "after":
			if (cursor.get().entity.type.startsWith("item")) {
				unselectNode(tree);
				
				var index = cursor.path[cursor.path.length-1];
				cursor.up().splice([index+1,0,node]);

				var arrPath = Object.assign([],cursor.path);
				arrPath[arrPath.length-1] = arrPath[arrPath.length-1]+1;
				handleSelectNodeAtPath(tree,arrPath);
				break;
			} else {
				showError("Invalid","You can't add an Item here. An Item should be added to a Section.");
			}	
			break;
	}

	saveUndoState();
}

export function showAlert(message, cb) {
	showInfo("Info", message, cb);
}

export function dismissAlert() {
	state.select(['appState','alerts']).set([]);
}

export function showInfo(title, message, cb) {
	state.select(['appState','alerts']).push({
		id: new Date().getTime() + Math.random(),
		type: "info",
		title,
		message,
		callback:cb
	});
}

export function showError(title, message, cb) {
state.select(['appState','alerts']).push({
		id: new Date().getTime() + Math.random(),
		type: "danger",
		title,
		message,
		callback:cb
	});
}

export function showWarning(title, message, cb) {
state.select(['appState','alerts']).push({
		id: new Date().getTime() + Math.random(),
		type: "warning",
		title,
		message,
		callback:cb
	});
}

export function showSuccess(title, message, cb) {
state.select(['appState','alerts']).push({
		id: new Date().getTime() + Math.random(),
		type: "success",
		title,
		message,
		callback:cb
	});
}

export function showConfirm(message, title, onConfirm) {
	state.select(['appState','alerts']).push({
		message,
		title,
		onConfirm
	});
}

function recurseCollapse(cursor) {
	while (cursor != null) {
		cursor.set("expanded",false);

		if (cursor.get().hasOwnProperty("children") && cursor.get().children.length > 0) {
			recurseCollapse(cursor.select("children").down());
		}

		cursor = cursor.right();
	}
}

function recurseExpand(cursor) {
	while (cursor != null) {
		cursor.set("expanded",true);

		if (cursor.get().hasOwnProperty("children") && cursor.get().children.length > 0) {
			recurseExpand(cursor.select("children").down());
		}

		cursor = cursor.right();
	}
}

function recurseRefreshVisible(cursorParent, cursor) {
	while (cursor != null) {
		if (cursor.get("expanded") == true || (cursorParent != null && cursorParent.get("expanded") == true)) {
			//console.log(cursor.path);
			_visiblePaths.push(cursor.path);
		}

		if (cursor.get().hasOwnProperty("children") && cursor.get().children.length > 0) {
			recurseRefreshVisible(cursor,cursor.select("children").down());
		}

		if (cursor.path.length > 2) {
			cursor = cursor.right();
		} else {
			cursor = null;
		}
	}
}

export function receiveHistory(identityId) {
	awsS3.getHistory(state,identityId);

	// When gets history will set state which will cause re-render of history view
}

export function clearHistory(tree) {
	tree.set(["checklistHistory"],null);
}

export function openPreviewModal(tree,hit) {
	// Get JSON and after retrieved set state
	awsS3.previewJsonFromS3(tree,miracheckContentBucket,hit.id + ".json");
}

export function closePreviewModal(tree) {
	tree.set(["appState","previewChecklist","showModal"],false);
}

export function showNotesModal(tree, hit) {
	// Get JSON and after retrieved set state
	awsS3.showNotes(tree, contentBucket, hit.url);
}

export function closeNotesModal(tree) {
	tree.set(["appState", "showNotes", "showModal"], false);
}

export function showDetailedStatusModal(tree, hit) {
	// Get JSON and after retrieved set state
	awsS3.showDetailedStatus(tree, contentBucket, hit.url);
}

export function closeDetailedStatusModal(tree) {
	tree.set(["appState", "showDetailedStatus", "showModal"], false);
}

export function showDetailedHistoryModal(tree, hit) {
	// Get JSON and after retrieved set state
	awsS3.showDetailedHistory(tree, contentBucket, hit.url);
}

export function closeDetailedHistoryModal(tree) {
	tree.set(["appState", "showDetailedHistory", "showModal"], false);
}

export function deleteStatusItem(tree, hit) {
	var key = hit.url.replace("https://s3.amazonaws.com/content.checklist.miralouaero.com/","");

	return awsS3.deleteObject(contentBucket, key);
}

export function toggleMultiSelect(tree) {
	let enabled = tree.select(["appState","editor", "multiSelectMode"]).get();
	tree.set(["appState","editor", "multiSelectMode"], !enabled);
}

/**
 * CheckMate Import related functions
 */
const CM_LIST_CHECKLISTS = "https://www.mykneeboard.com/Checklist-rest/jaxrs/checklistsAllJson";
const CM_GET_CHECKLIST = "https://www.mykneeboard.com/Checklist-rest/jaxrs/checklistJson/"; // +checklist id
export function fetchCheckLists() {
	state.select(['checkMateImport']).set("showSpinner",true)
	const credentials = state.select(['checkMateImport','credentials']).get()
	var lambda = new AWS.Lambda({ region: "us-east-1", apiVersion: '2015-03-31' });
// 	alert(credentials.username + ":" + credentials.password, () =>{
var params = {
		FunctionName: globals.lambdaFunctionNames.fetchCheckmateChecklists,
		InvocationType: 'RequestResponse',
		LogType: 'None',
		Payload: JSON.stringify({
			username: credentials.username,
			password: credentials.password,
			action: "fetchAll"
		})
	};

	lambda.invoke(params, function (error, data) {
		if (error) {
			showError("Failed to get checklists", "Please verify that you have connectivity and your username and password are correct.")
		} else {
			// console.log(data);
			const result = JSON.parse(data.Payload)
			console.log(result);
			if (result.success !== false) {
				console.log(result);
				if (_.isArray(result) && result.length === 0) {
					showWarning("No Checklists Found", "It appears as though your account is empty, we couldn't locate any checklists, if this is a mistake please make sure to contact support@miralouaero.com.")
				}
				state.select(['checkMateImport']).set("checklists", result);
			} else {
				showError("Failed to get checklists", "Please verify that you have connectivity and that your username and password are correct.")
			}
			// alert(JSON.stringify(data));
			state.select(['checkMateImport']).set("showSpinner",false)					
		}
	});
// 	});
	
	// request
	// 	.get(CM_LIST_CHECKLISTS)
	// 	.withCredentials()
	// 	.auth(credentials.username, credentials.password)
	// 	.end(function(err, res){
	// 		if (err) {
	// 			console.error(err);
	// 			showError("Credentials Error", JSON.stringify(err))
	// 		} else {
	// 		// Do something
	// 			alert(res)
	// 		}
	// 	});

}

export function doImportChecklists() {
	state.select(['checkMateImport']).set("showSpinner",true)
	const credentials = state.select(['checkMateImport','credentials']).get()
	const failuresCursor = state.select(['checkMateImport','failedChecklists']);
	var lambda = new AWS.Lambda({ region: "us-east-1", apiVersion: '2015-03-31' });
	const checklists = state.select(['checkMateImport',"checklists"]).get();
	const promises = [];
	checklists.forEach((checklist)=>{
		console.log(checklist.id);
		promises.push(new Promise((resolve, reject)=>{
			var params = {
				FunctionName: globals.lambdaFunctionNames.fetchCheckmateChecklists,
				InvocationType: 'RequestResponse',
				LogType: 'None',
				Payload: JSON.stringify({
					username: credentials.username,
					password: credentials.password,
					action: "fetch",
					id: checklist.id
				})
			};

			lambda.invoke(params, function (error, data) {
				if (error) {
					reject("")
					showError("Failed to get checklists", "Please verify that you have connectivity and that your username and password are correct.")
				} else {
					// console.log(data);
					// state.select(['checkMateImport']).set("checklists", JSON.parse(data.Payload));
					// alert(JSON.stringify(data));
					let result = JSON.parse(data.Payload)
					if (result.success !== false && !result.hasOwnProperty("error")) {
						console.log("DONE GETTING " + checklist.id);
						resolve(result)
					} else {
						console.log("FAILED " + checklist.id);
						failuresCursor.push(checklist.id);
						resolve({
							error:true,
							failed:checklist.id
						});
					}
				}
			});
		}));
		
	});

	Promise.all(promises).then((checklists)=>{
		return new Promise((resolve, reject)=>{
			let numChecklists=awsS3.importChecklistsFromCheckMate(checklists);
			// num
			resolve(failuresCursor.get(), numChecklists);
		});
	}).then((failures, numImported)=>{
		console.log("ABOUT TO SET PLAN");
		awsS3.updateSubscriptionBasicCheckMate().then(()=>{
			state.select(['checkMateImport']).set("showSpinner",false)
			if (!_.isEmpty(failures)) {
				if (numImported>0) {
					showWarning("Partial Import", numImported + " were successfully imported, however " + failures.length + " failed to import, below are the IDs of the ones that couldn't be imported, please send those to support@miralouaero.com so we can transfer them for you. " + failures);
				} else {
					showError("Import Failed", "Some checklists couldn't be retrieved, please make sure to contact MiraCheck Support (support@miralouaero.com) and provide the following ID's: " + failures)
				}
			} else {
				showSuccess("Import Finished", "All checklists have been successfully imported.")
			}
		}).catch((e)=>{
			state.select(['checkMateImport']).set("showSpinner",false)
			console.error(e);
			showError("Unknown Error", "Something went wrong when attempting the import, please contact support@miralouaero.com.")
		});
		
	});
}

export function setCheckMateUsername(username) {
	state.select(['checkMateImport','credentials']).set("username", username);
}

export function setCheckMatePassword(password) {
	state.select(['checkMateImport','credentials']).set("password", password);
}

export function setLastLocation(tree, location) {
	tree.set(["appState","lastLocation"],location);
}

export function setShowVisible(tree, visible) {
	tree.set(["appState","filters","showVisible"],visible);
	utils.setDocuments(tree, tree.get(["documents"]));
}
  