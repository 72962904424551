import _ from 'lodash'; 

import * as utils from '../utils/utils.js'; 
import jsonTemplateMinimal from '../data/template-minimal.js';

const defaultCopilotOptions = {
	version: 2,
	// Allow named timers as well as inline timers as well
	alerts: [
		{
			relationship: "before",
			timeInSeconds: "600",
			//distanceInNm: "10",
			waypoint: "{destinationAirport}",
			linkAction: "goto",
			linkId: "#approach"
		},
		{
			relationship: "after",
			timeInSeconds: "600",
			//distanceInNm: "10",
			waypoint: "{originAirport}",
			message: "Close Cowl Flaps"
		}
	],
	phases: [
		{
			id: "taxi",
			helpId: "copilotPhaseTaxi",
			name: "Taxi",
			enabled: false,
			linkActionType: "goto",
			linkId: "",
			showConfirmationPrompt: true,
			readSection: true,
			options: [
				{
					id: "minSpeed",
					helpId: "copilotPhaseOptionMinSpeed",
					inputType: "text",
					value: 2
				},
				{
					id: "maxSpeed",
					helpId: "copilotPhaseOptionMaxSpeed",
					inputType: "text",
					value: 10
				}

			]
		},
		{
			id: "run-up",
			helpId: "copilotPhaseRunUp",
			name: "Run-up",
			enabled: false,
			linkActionType: "goto",
			linkId: "",
			showConfirmationPrompt: true,
			readSection: false,
			options: [
				{
					id: "stopDelay",
					helpId: "copilotPhaseOptionStopDelay",
					inputType: "text",
					value: 7
				}
			]
		},
		{
			id: "takeoff",
			helpId: "copilotPhaseTakeoff",
			name: "Takeoff",
			enabled: false,
			linkActionType: "goto",
			linkId: "",
			showConfirmationPrompt: false,
			readSection: true,
			options: [
				{
					id: "minSpeed",
					helpId: "copilotPhaseOptionMinSpeed",
					inputType: "text",
					value: 15
				}/*,
				{
					id: "rotateSpeed",
					helpId: "copilotPhaseOptionRotateSpeed",
					inputType: "text",
					value: 52
				},
				{
					id: "rotateSpeedMessage",
					helpId: "copilotPhaseOptionRotateSpeedMessage",
					inputType: "text",
					value: "You are at your rotate speed."
				}*/

			]
		},
		{
			id: "climb",
			helpId: "copilotPhaseClimb",
			name: "Climb",
			enabled: false,
			linkActionType: "resetRangeAndGoto",
			linkId: "",
			showConfirmationPrompt: true,
			readSection: false,
			options: [
				{
					id: "minVertSpeed",
					helpId: "copilotPhaseOptionMinVertSpeed",
					inputType: "text",
					value: 350
				}
			]
		},
		{
			id: "cruise",
			helpId: "copilotPhaseCruise",
			name: "Cruise",
			enabled: false,
			linkActionType: "resetRangeAndGoto",
			linkId: "",
			showConfirmationPrompt: true,
			readSection: false,
			options: [
				{
					id: "maxVertSpeed",
					helpId: "copilotPhaseOptionMaxVertSpeed",
					inputType: "text",
					value: 100
				},
				{
					id: "suppressDistFromDest",
					helpId: "copilotPhaseOptionSuppressDistFromDest",
					inputType: "text",
					value: 10
				}
			]
		},
		{
			id: "descent",
			helpId: "copilotPhaseDescent",
			name: "Descent",
			enabled: false,
			linkActionType: "newSessionAndGoto",
			linkId: "",
			showConfirmationPrompt: true,
			readSection: false,
			options: [
				{
					id: "minVertSpeed",
					helpId: "copilotPhaseOptionMinVertSpeed",
					inputType: "text",
					value: -350
				},
				{
					id: "suppressDistFromDest",
					helpId: "copilotPhaseOptionSuppressDistFromDest",
					inputType: "text",
					value: 5
				}
			]
		},
		{
			id: "pre-landing",
			helpId: "copilotPhasePreLanding",
			name: "Pre-Landing",
			enabled: false,
			linkActionType: "newSessionAndGoto",
			linkId: "",
			showConfirmationPrompt: true,
			readSection: false,
			options: [
				{
					id: "distFromDest",
					helpId: "copilotPhaseOptionDistFromDest",
					inputType: "text",
					value: 5
				}
			]
		},
		{
			id: "landing",
			helpId: "copilotPhaseLanding",
			name: "Landing",
			enabled: false,
			linkActionType: "goto",
			linkId: "",
			showConfirmationPrompt: false,
			readSection: true,
			options: [
				{
					id: "distFromDest",
					helpId: "copilotPhaseOptionDistFromDest",
					inputType: "text",
					value: 1.5
				},
				{
					id: "minAltAboveGround",
					helpId: "copilotPhaseOptionMinAltAboveGround",
					inputType: "text",
					value: 250
				},
				{
					id: "maxAltAboveGround",
					helpId: "copilotPhaseOptionMaxAltAboveGround",
					inputType: "text",
					value: 700
				}
			]
		},
		{
			id: "postflight",
			helpId: "copilotPhasePostflight",
			name: "Postflight",
			enabled: false,
			linkActionType: "goto",
			linkId: "",
			showConfirmationPrompt: true,
			readSection: false,
			options: [
				{
					id: "minSpeed",
					helpId: "copilotPhaseOptionMinSpeed",
					inputType: "text",
					value: 2
				},
				{
					id: "maxSpeed",
					helpId: "copilotPhaseOptionMaxSpeed",
					inputType: "text",
					value: 10
				}
			]
		},
		{
			id: "securing",
			helpId: "copilotPhaseSecuring",
			name: "Securing",
			enabled: false,
			linkActionType: "goto",
			linkId: "",
			showConfirmationPrompt: true,
			readSection: false,
			options: [
				{
					id: "stopDelay",
					helpId: "copilotPhaseOptionStopDelay",
					inputType: "text",
					value: 5
				}
			]
		}		
	]
};

function mergeLegacyCopilotOptions(legacyCopilotOptions) {
	let copilotOptions = legacyCopilotOptions;

	copilotOptions.version = defaultCopilotOptions.version;

	copilotOptions.phases.forEach((option, idx) => {
		option.options = defaultCopilotOptions.phases[idx].options;
	});

	return copilotOptions;
}

/*
Transform from checklist json to entities in memory
*/
export function transformToChecklistEntities(checklist) {	
	var checklistEntities = {};
	var dstChildren = [];

	let copilotOptions;

	if (checklist.hasOwnProperty("copilotOptions") && checklist.copilotOptions.hasOwnProperty("version") && checklist.copilotOptions.version >= 2) {
		copilotOptions = checklist.copilotOptions;
	} else if (checklist.hasOwnProperty("copilotOptions")) {
		copilotOptions = mergeLegacyCopilotOptions(checklist.copilotOptions);
	} else {
		copilotOptions = defaultCopilotOptions;
	}

	var root = {
		revision: checklist.hasOwnProperty("revision") ? checklist.revision : 1,
		id: checklist.id,
		baseIds: checklist.hasOwnProperty("baseIds")?checklist.baseIds:[],
		cloned: checklist.cloned,
		publisher: checklist.publisher,
		entity: {
			entityId: "root",
			checklistId: checklist.hasOwnProperty("checklistId")?checklist.checklistId:"",
			type: "checklist",
			label: checklist.name,
			labelAlexaPlain: checklist.hasOwnProperty("nameAlexaPlain")?checklist.nameAlexaPlain:"",
			labelAlexaRich: checklist.hasOwnProperty("nameAlexaRich")?checklist.nameAlexaRich:"",
			labelAudio: checklist.hasOwnProperty("nameAudio")?checklist.nameAudio:"",
			labelSsml: checklist.hasOwnProperty("nameSsml")?checklist.nameSsml:"",
			labelPrint: checklist.hasOwnProperty("namePrint")?checklist.namePrint:"",
			description: checklist.description,
			backgroundImage: checklist.hasOwnProperty("backgroundImage")?checklist.backgroundImage:"",
			video: checklist.hasOwnProperty("video")?checklist.video:"",
			image: checklist.hasOwnProperty("image")?checklist.image:"",
			audio: checklist.hasOwnProperty("audio")?checklist.audio:"",
			genre: checklist.genre,
			tags: checklist.tags,
			contributors: checklist.hasOwnProperty("contributors")?checklist.contributors:[],
			startInShowMode: checklist.hasOwnProperty("startInShowMode")?checklist.startInShowMode:false,
			suppressAudio: checklist.hasOwnProperty("suppressAudio")?checklist.suppressAudio:false,
			suppressAudioTitle: checklist.hasOwnProperty("suppressAudioTitle")?checklist.suppressAudioTitle:false,
			suppressPrint: checklist.hasOwnProperty("suppressPrint")?checklist.suppressPrint:false,
			trackLocation: checklist.hasOwnProperty("trackLocation")?checklist.trackLocation:false,
			itemsOnSingleLine: checklist.hasOwnProperty("itemsOnSingleLine")?checklist.itemsOnSingleLine:false,
			ignoreCompletion: checklist.hasOwnProperty("ignoreCompletion")?checklist.ignoreCompletion:false,
			hideStatus: checklist.hasOwnProperty("hideStatus")?checklist.hideStatus:false,
			printCustomProperties: checklist.hasOwnProperty("printCustomProperties") ? JSON.stringify(checklist.printCustomProperties, null, 2) : "{}",
			printTemplate: checklist.hasOwnProperty("printTemplate") ? checklist.printTemplate : "",			
			functions: checklist.hasOwnProperty("functions") ? checklist.functions : "",
			// printEngine: checklist.hasOwnProperty("printEngine") ? checklist.printEngine : "jsrender",		
			// printRecipe: checklist.hasOwnProperty("printRecipe") ? checklist.printRecipe : "electron-pdf",		
			// printRecipeOptions: checklist.hasOwnProperty("printRecipeOptions") ? checklist.printRecipeOptions : "{}",		
			// printHelpers: checklist.hasOwnProperty("printHelpers") ? checklist.printHelpers : "",			
			// printScripts: checklist.hasOwnProperty("printScripts") ? checklist.printScripts : "",
			
			// Support generic alerts like 30 mininutes from destination present Approach checklist items
			// After starting airplane, remind me every 45 minutes to switch tanks
			// 
			copilotOptions: copilotOptions,
			aiAssistant: checklist.hasOwnProperty("aiAssistant") ? checklist.aiAssistant : false,
			aiAssistantId: checklist.hasOwnProperty("aiAssistantId") ? checklist.aiAssistantId : "",
			aiAssistantFiles: checklist.hasOwnProperty("aiAssistantFiles") ? checklist.aiAssistantFiles : [],
			aiAssistantChecklistFiles: checklist.hasOwnProperty("aiAssistantChecklistFiles") ? checklist.aiAssistantChecklistFiles : [],			
		},
		expanded: true,
		selected: false,
		children: dstChildren
	}
	
	checklistEntities.root = root;
	checklistEntities.children = dstChildren;
	recurseChecklist(checklist.children,dstChildren);

	return checklistEntities;
}

function recurseChecklist(arr,dstChildren) {
  for (var i=0; i<arr.length; i++) {
	var newChildren = [];
    if (arr[i].type == "list") {
		var guid = utils.generateUUID();
		var node = {
			expanded: false,
			selected: false,
			entity: {
				entityId: guid,
				id: arr[i].hasOwnProperty("id")?arr[i].id:"",
				groupNames: arr[i].hasOwnProperty("groupNames") ? arr[i].groupNames : [],
				filterTags: arr[i].hasOwnProperty("filterTags") ? arr[i].filterTags : [],
				type: "list",
				label: arr[i].name,
				labelAlexaPlain: arr[i].hasOwnProperty("nameAlexaPlain")?arr[i].nameAlexaPlain:"",
				labelAlexaRich: arr[i].hasOwnProperty("nameAlexaRich")?arr[i].nameAlexaRich:"",
				labelAudio: arr[i].hasOwnProperty("nameAudio")?arr[i].nameAudio:"",
				labelSsml: arr[i].hasOwnProperty("nameSsml")?arr[i].nameSsml:"",
				labelPrint: arr[i].hasOwnProperty("namePrint")?arr[i].namePrint:"",
				defaultView: arr[i].defaultView,
				startInBirdseyeView: arr[i].hasOwnProperty("startInBirdseyeView")?arr[i].startInBirdseyeView:false,
				color: arr[i].color,
				backgroundImage: arr[i].hasOwnProperty("backgroundImage")?arr[i].backgroundImage:"",
				video: arr[i].hasOwnProperty("video")?arr[i].video:"",
				image: arr[i].hasOwnProperty("image")?arr[i].image:"",
				audio: arr[i].hasOwnProperty("audio")?arr[i].audio:"",
				suppressAudio: arr[i].hasOwnProperty("suppressAudio")?arr[i].suppressAudio:false,
				suppressAudioTitle: arr[i].hasOwnProperty("suppressAudioTitle")?arr[i].suppressAudioTitle:false,
				suppressAudioEntityType: arr[i].hasOwnProperty("suppressAudioEntityType") ? arr[i].suppressAudioEntityType : true,
				suppressPrint: arr[i].hasOwnProperty("suppressPrint")?arr[i].suppressPrint:false,
				visible: arr[i].hasOwnProperty("visible") ? arr[i].visible : true,
				ignoreCompletion: arr[i].hasOwnProperty("ignoreCompletion")?arr[i].ignoreCompletion:false,
				hideStatus: arr[i].hasOwnProperty("hideStatus")?arr[i].hideStatus:false,
				printProperties: arr[i].hasOwnProperty("printProperties")?arr[i].printProperties:{
					keepTogether:false,
					breakBefore:true,
					breakBeforeHeight:0,
					breakAfter:false,
					breakAfterHeight: 0
				},
				printCustomProperties: arr[i].hasOwnProperty("printCustomProperties") ? JSON.stringify(arr[i].printCustomProperties, null, 2) : "{}"
			},
			children: newChildren
		};

		dstChildren.push(node);
    } else if (arr[i].type == "section") {
		var guid = utils.generateUUID();
		var node = {
			expanded: false,
			selected: false,
			entity: {
				entityId: guid,
				id: arr[i].hasOwnProperty("id")?arr[i].id:"",
				groupNames: arr[i].hasOwnProperty("groupNames") ? arr[i].groupNames : [],
				filterTags: arr[i].hasOwnProperty("filterTags") ? arr[i].filterTags : [],
				type: "section",
				label: arr[i].name,
				labelAlexaPlain: arr[i].hasOwnProperty("nameAlexaPlain")?arr[i].nameAlexaPlain:"",
				labelAlexaRich: arr[i].hasOwnProperty("nameAlexaRich")?arr[i].nameAlexaRich:"",
				labelAudio: arr[i].hasOwnProperty("nameAudio")?arr[i].nameAudio:"",
				labelSsml: arr[i].hasOwnProperty("nameSsml")?arr[i].nameSsml:"",
				labelPrint: arr[i].hasOwnProperty("namePrint")?arr[i].namePrint:"",
				color: arr[i].hasOwnProperty("color")?arr[i].color:"#ffffff",
				backgroundColor: arr[i].hasOwnProperty("backgroundColor")?arr[i].backgroundColor:"#888888",
				borderColor: arr[i].hasOwnProperty("borderColor")?arr[i].borderColor:"#888888",
				iconColor: arr[i].hasOwnProperty("iconColor")?arr[i].iconColor:"#ffffff",
				backgroundImage: arr[i].hasOwnProperty("backgroundImage")?arr[i].backgroundImage:"",
				video: arr[i].hasOwnProperty("video")?arr[i].video:"",
				image: arr[i].hasOwnProperty("image")?arr[i].image:"",
				audio: arr[i].hasOwnProperty("audio")?arr[i].audio:"",
				suppressAudio: arr[i].hasOwnProperty("suppressAudio")?arr[i].suppressAudio:false,
				suppressAudioTitle: arr[i].hasOwnProperty("suppressAudioTitle")?arr[i].suppressAudioTitle:false,
				suppressAudioEntityType: arr[i].hasOwnProperty("suppressAudioEntityType") ? arr[i].suppressAudioEntityType : true,
				suppressPrint: arr[i].hasOwnProperty("suppressPrint")?arr[i].suppressPrint:false,
				visible: arr[i].hasOwnProperty("visible") ? arr[i].visible : true,
				ignoreCompletion: arr[i].hasOwnProperty("ignoreCompletion")?arr[i].ignoreCompletion:false,
				hideStatus: arr[i].hasOwnProperty("hideStatus")?arr[i].hideStatus:false,
				printProperties: arr[i].hasOwnProperty("printProperties")?arr[i].printProperties:{
					keepTogether:false,
					breakBefore:false,
					breakBeforeHeight:0,
					breakAfter:false,
					breakAfterHeight: 0
				},
				printCustomProperties: arr[i].hasOwnProperty("printCustomProperties") ? JSON.stringify(arr[i].printCustomProperties, null, 2) : "{}"
			},
			children: newChildren
		};

		dstChildren.push(node);
    } else if (arr[i].type.startsWith("item")) {
		var node = {
			expanded: false,
			selected: false,
			entity: {
				entityId: utils.generateUUID(),
				id: arr[i].hasOwnProperty("id")?arr[i].id:"",
				groupNames: arr[i].hasOwnProperty("groupNames") ? arr[i].groupNames : [],
				filterTags: arr[i].hasOwnProperty("filterTags") ? arr[i].filterTags : [],
				type: arr[i].type,
				label: arr[i].hasOwnProperty("label1")?arr[i].label1:"",
				label1EditorAdvanced: arr[i].hasOwnProperty("label1EditorAdvanced") ? arr[i].label1EditorAdvanced : "",
				label1AlexaPlain: arr[i].hasOwnProperty("label1AlexaPlain")?arr[i].label1AlexaPlain:"",
				label1AlexaRich: arr[i].hasOwnProperty("label1AlexaRich")?arr[i].label1AlexaRich:"",
				label1Audio: arr[i].hasOwnProperty("label1Audio")?arr[i].label1Audio:"",
				label1Ssml: arr[i].hasOwnProperty("label1Ssml")?arr[i].label1Ssml:"",
				label1Print: arr[i].hasOwnProperty("label1Print")?arr[i].label1Print:"",
				color: arr[i].hasOwnProperty("color")?arr[i].color:"#000000",
				label2: arr[i].hasOwnProperty("label2")?arr[i].label2:"",
				label2EditorAdvanced: arr[i].hasOwnProperty("label2EditorAdvanced") ? arr[i].label2EditorAdvanced : "",
				label2AlexaPlain: arr[i].hasOwnProperty("label2AlexaPlain")?arr[i].label2AlexaPlain:"",
				label2AlexaRich: arr[i].hasOwnProperty("label2AlexaRich")?arr[i].label2AlexaRich:"",
				label2Audio: arr[i].hasOwnProperty("label2Audio")?arr[i].label2Audio:"",
				label2Ssml: arr[i].hasOwnProperty("label2Ssml")?arr[i].label2Ssml:"",
				label2Print: arr[i].hasOwnProperty("label2Print")?arr[i].label2Print:"",
				label2Color: arr[i].hasOwnProperty("label2Color")?arr[i].label2Color:"#000000",
				label3: arr[i].hasOwnProperty("label3")?arr[i].label3:"",
				label3EditorAdvanced: arr[i].hasOwnProperty("label3EditorAdvanced") ? arr[i].label3EditorAdvanced : "",
				label3AlexaPlain: arr[i].hasOwnProperty("label3AlexaPlain")?arr[i].label3AlexaPlain:"",
				label3AlexaRich: arr[i].hasOwnProperty("label3AlexaRich")?arr[i].label3AlexaRich:"",
				label3Audio: arr[i].hasOwnProperty("label3Audio")?arr[i].label3Audio:"",
				label3Ssml: arr[i].hasOwnProperty("label3Ssml")?arr[i].label3Ssml:"",
				label3Print: arr[i].hasOwnProperty("label3Print")?arr[i].label3Print:"",
				label3Color: arr[i].hasOwnProperty("label3Color")?arr[i].label3Color:"#000000",
				mandatory: arr[i].hasOwnProperty("mandatory")?arr[i].mandatory:false,
				comments: arr[i].hasOwnProperty("comments")?arr[i].comments:"",
				commentsInline: arr[i].hasOwnProperty("commentsInline")?arr[i].commentsInline:false,
				commentsAuthor: "",
				commentsAudio: arr[i].hasOwnProperty("commentsAudio")?arr[i].commentsAudio:"",
				commentsSsml: arr[i].hasOwnProperty("commentsSsml")?arr[i].commentsSsml:"",
				commentsPrint: arr[i].hasOwnProperty("commentsPrint")?arr[i].commentsPrint:"",
				linkOnCheck: arr[i].hasOwnProperty("linkOnCheck")?arr[i].linkOnCheck:false,
				linkId: arr[i].hasOwnProperty("linkId")?arr[i].linkId:"",
				linkActionType: arr[i].hasOwnProperty("linkActionType")?arr[i].linkActionType:"goto",
				backgroundImage: arr[i].hasOwnProperty("backgroundImage")?arr[i].backgroundImage:"",
				video: arr[i].hasOwnProperty("video")?arr[i].video:"",
				image: arr[i].hasOwnProperty("image")?arr[i].image:"",
				audio: arr[i].hasOwnProperty("audio")?arr[i].audio:"",
				suppressAudio: arr[i].hasOwnProperty("suppressAudio")?arr[i].suppressAudio:false,
				suppressAudioLabel1: arr[i].hasOwnProperty("suppressAudioLabel1")?arr[i].suppressAudioLabel1:false,
				suppressAudioLabel2: arr[i].hasOwnProperty("suppressAudioLabel2")?arr[i].suppressAudioLabel2:false,
				suppressAudioLabel3: arr[i].hasOwnProperty("suppressAudioLabel3")?arr[i].suppressAudioLabel3:false,
				suppressPrint: arr[i].hasOwnProperty("suppressPrint")?arr[i].suppressPrint:false,
				suppressPrintComments: arr[i].hasOwnProperty("suppressPrintComments")?arr[i].suppressPrintComments:false,
				visible: arr[i].hasOwnProperty("visible") ? arr[i].visible : true,
				ignoreCompletion: arr[i].hasOwnProperty("ignoreCompletion")?arr[i].ignoreCompletion:false,
				startTracking: arr[i].hasOwnProperty("startTracking")?arr[i].startTracking:false,
				stopTracking: arr[i].hasOwnProperty("stopTracking")?arr[i].stopTracking:false,
				startNamedTimers: arr[i].hasOwnProperty("startNamedTimers") ? arr[i].startNamedTimers : [],							
				stopNamedTimers: arr[i].hasOwnProperty("stopNamedTimers") ? arr[i].stopNamedTimers : [],
				valueTrigger: arr[i].hasOwnProperty("valueTrigger") ? arr[i].valueTrigger : "",
				printProperties: arr[i].hasOwnProperty("printProperties")?arr[i].printProperties:{
					keepTogether:true,
					breakBefore:false,
					breakBeforeHeight:0,
					breakAfter:false,
					breakAfterHeight: 0
				},
				printCustomProperties: arr[i].hasOwnProperty("printCustomProperties") ? JSON.stringify(arr[i].printCustomProperties, null, 2) : "{}"
			}
			
		};

		if (arr[i].hasOwnProperty("startTracking") && arr[i].hasOwnProperty("stopTracking") && arr[i].startTracking===true && arr[i].stopTracking===true) {
			node.entity.startTracking = false;
			node.entity.stopTracking = false;
		}	

		var deltaProps = {};
		switch (arr[i].type) {
			case "item":
				deltaProps = {
					entity: {
						labelOnly: arr[i].hasOwnProperty("labelOnly")?arr[i].labelOnly:false,
						labelOnlyBackgroundColor: arr[i].hasOwnProperty("labelOnlyBackgroundColor")?arr[i].labelOnlyBackgroundColor:"#FFF8C6",
						startTimer: arr[i].hasOwnProperty("startTimer")?arr[i].startTimer:false,
						stopTimer: arr[i].hasOwnProperty("stopTimer")?arr[i].stopTimer:false							
					}
				}
				break;
			case "itemTextInput":
			case "itemTextInputMultiline":
				deltaProps = {
					entity: {
						textInputPlaceholder: arr[i].hasOwnProperty("textInputPlaceholder")?arr[i].textInputPlaceholder:"",
						textInputDefaultValue: arr[i].hasOwnProperty("textInputDefaultValue")?arr[i].textInputDefaultValue:"",
						textInputMaxLength: arr[i].hasOwnProperty("textInputMaxLength")?arr[i].textInputMaxLength:1000,
						textInputKeyboardType: arr[i].hasOwnProperty("textInputKeyboardType")?arr[i].textInputKeyboardType:"default",
						textInputKeyboardAutoCapitalize: arr[i].hasOwnProperty("textInputKeyboardAutoCapitalize")?arr[i].textInputKeyboardAutoCapitalize:"sentences",
						textInputAutoCorrect: arr[i].hasOwnProperty("textInputKeyboardAutoCorrect")?arr[i].textInputKeyboardAutoCorrect:true,
						textInputKeyboardReturnKeyType: arr[i].hasOwnProperty("textInputKeyboardReturnKeyType")?arr[i].textInputKeyboardReturnKeyType:"default",
						textInputMaskType: arr[i].hasOwnProperty("textInputMaskType")?arr[i].textInputMaskType:"",
						textInputCurrencySymbol: arr[i].hasOwnProperty("textInputCurrencySymbol")?arr[i].textInputCurrencySymbol:"$",
						textInputCurrencySeparator: arr[i].hasOwnProperty("textInputCurrencySeparator")?arr[i].textInputCurrrencySeparator:",",
						textInputNumberOfLines: arr[i].hasOwnProperty("textInputNumberOfLines")?arr[i].textInputNumberOfLines:4
					}
				}
				break;
			case "itemPicker":
				deltaProps = {
					entity: {
						pickerItemViewType: arr[i].hasOwnProperty("pickerItemViewType")?arr[i].pickerItemViewType:"picker",
						pickerItemPlaceholder: arr[i].hasOwnProperty("pickerItemPlaceholder")?arr[i].pickerItemPlaceholder:"",
						pickerItemDefaultValue: arr[i].pickerItemDefaultValue,
						pickerItems: arr[i].pickerItems,
						pickerAdvanceOnSelect: arr[i].hasOwnProperty("pickerAdvanceOnSelect")?arr[i].pickerAdvanceOnSelect:false,
						pickerLinkOnSelect: arr[i].hasOwnProperty("pickerLinkOnSelect")?arr[i].pickerLinkOnSelect:false,
						pickerLinkActionType: arr[i].hasOwnProperty("pickerLinkActionType")?arr[i].pickerLinkActionType:"goto"
					}
				}
				break;
			case "itemYesNo":
				deltaProps = {
					entity: {
						yesNoLinkOnSelect: arr[i].hasOwnProperty("yesNoLinkOnSelect")?arr[i].yesNoLinkOnSelect:false,
						yesNoLinkActionType: arr[i].hasOwnProperty("yesNoLinkActionType")?arr[i].yesNoLinkActionType:"goto",
						yesNoYesLinkId: arr[i].hasOwnProperty("yesNoYesLinkId")?arr[i].yesNoYesLinkId:"",
						yesNoNoLinkId: arr[i].hasOwnProperty("yesNoNoLinkId")?arr[i].yesNoNoLinkId:""
					}
				}
				break;
			case "itemDate":
			case "itemTime":
			case "itemDateTime":
				deltaProps = {
					entity: {
						dateTimeType: arr[i].hasOwnProperty("dateTimeType")?arr[i].dateTimeType:"local",
						dateTimeInitialDate: arr[i].hasOwnProperty("dateTimeInitialDate")?arr[i].dateTimeInitialDate:"today",
						dateTimeMinuteInterval: arr[i].hasOwnProperty("dateTimeMinuteInterval")?arr[i].dateTimeMinuteInterval:1
					}
				}	
				break;
			case "itemWeather":
				deltaProps = {
					entity: {
						weatherInitialLocation: arr[i].hasOwnProperty("weatherInitialLocation")?arr[i].weatherInitialLocation:"current",
					}
				}	
				break;
			case "itemBarcodeScanner":
				deltaProps = {
					entity: {
						barcodeTypes: arr[i].hasOwnProperty("barcodeTypes")?arr[i].barcodeTypes:[],
					}
				}	
				break;
			case "itemImagePicker":
				deltaProps = {
					entity: {
						imagePickerMediaType: arr[i].hasOwnProperty("imagePickerMediaType")?arr[i].imagePickerMediaType:"photo",
						imagePickerAddMediaButton: arr[i].hasOwnProperty("imagePickerAddMediaButton")?arr[i].imagePickerAddMediaButton:"Add Photo",
						imagePickerUploadTitle: arr[i].hasOwnProperty("imagePickerUploadTitle")?arr[i].imagePickerUploadTitle:"Upload",
						imagePickerCaptureMediaTitle: arr[i].hasOwnProperty("imagePickerCaptureMediaTitle")?arr[i].imagePickerCaptureMediaTitle:"Take Photo..."
					}
				}	
				break;
			case "itemSketchPad":
				deltaProps = {
					entity: {
						sketchPadBackgroundColor: arr[i].hasOwnProperty("sketchPadBackgroundColor")?arr[i].sketchPadBackgroundColor:"#ffffff",
						sketchPadPenColor: arr[i].hasOwnProperty("sketchPadPenColor")?arr[i].sketchPadPenColor:"#000000",
						sketchPadPenWidth: arr[i].hasOwnProperty("sketchPadPenWidth")?arr[i].sketchPadPenWidth:5,
						sketchPadHeight: arr[i].hasOwnProperty("sketchPadHeight")?arr[i].sketchPadHeight:190
					}
				}	
				break;
		}

		// Merge props
		node = _.merge({}, node, deltaProps);		

		dstChildren.push(node);
    }

	if(arr[i].hasOwnProperty("children")) {
		recurseChecklist(arr[i].children,newChildren);
	}
  }
}

/*
Transform from entities in memory to checklist json
*/
export function transformFromChecklistEntities(checklistEntities, resolveMarkdown=false) {
	var dstChildren = [];
	var checklist = _.cloneDeep(jsonTemplateMinimal);	
	if (checklistEntities.hasOwnProperty("revision")) {
		checklistEntities.revision = checklistEntities.revision + 1;
		checklist.revision = checklistEntities.revision;
	} else {
		checklist.revision = 1;
	}
	checklist.id = checklistEntities.id;
	checklist.baseIds = checklistEntities.baseIds;
	checklist.checklistId = checklistEntities.entity.checklistId;
	checklist.name = checklistEntities.entity.label;
	checklist.nameAlexaPlain = checklistEntities.entity.labelAlexaPlain;
	checklist.nameAlexaRich = checklistEntities.entity.labelAlexaRich;
	checklist.nameAudio = checklistEntities.entity.labelAudio;
	checklist.nameSsml = checklistEntities.entity.labelSsml;
	checklist.namePrint = checklistEntities.entity.labelPrint;
	checklist.description = checklistEntities.entity.description;
	checklist.genre = checklistEntities.entity.genre;
	checklist.tags = checklistEntities.entity.tags;
	checklist.contributors = checklistEntities.entity.contributors;
	checklist.children = dstChildren;
	checklist.cloned = checklistEntities.cloned;
	checklist.publisher = checklistEntities.publisher;
	checklist.backgroundImage = checklistEntities.entity.backgroundImage;
	checklist.video = checklistEntities.entity.video;
	checklist.image = checklistEntities.entity.image;
	checklist.audio = checklistEntities.entity.audio;
	checklist.startInShowMode = checklistEntities.entity.startInShowMode;
	checklist.suppressAudio = checklistEntities.entity.suppressAudio;
	checklist.suppressAudioTitle = checklistEntities.entity.suppressAudioTitle;
	checklist.suppressPrint = checklistEntities.entity.suppressPrint;
	checklist.trackLocation = checklistEntities.entity.trackLocation;
	checklist.itemsOnSingleLine = checklistEntities.entity.itemsOnSingleLine;
	checklist.ignoreCompletion = checklistEntities.entity.ignoreCompletion;
	checklist.hideStatus = checklistEntities.entity.hideStatus;
	checklist.printCustomProperties = safeParse(checklistEntities.entity.printCustomProperties, {});
	checklist.printTemplate = checklistEntities.entity.printTemplate;
	checklist.functions = checklistEntities.entity.functions;
	checklist.copilotOptions = checklistEntities.entity.copilotOptions;
	checklist.aiAssistant = checklistEntities.entity.aiAssistant;
	checklist.aiAssistantId = checklistEntities.entity.aiAssistantId;
	checklist.aiAssistantFiles = checklistEntities.entity.aiAssistantFiles;
	checklist.aiAssistantChecklistFiles = checklistEntities.entity.aiAssistantChecklistFiles;

	// TODO: Bug where "tree" has children twice
	recurseChecklistEntities(checklistEntities.children,dstChildren);
	
	if (resolveMarkdown) {
		utils.recurseConvertChecklistToMarkdown(checklist.children, true);
	}

	return checklist;
} 

function recurseChecklistEntities(arr,dstChildren) {
	for (var i=0; i<arr.length; i++) {
		var newChildren = [];
		if (arr[i].entity.type == "list") {
			
			var node = {
				"id": arr[i].entity.id,
				"groupNames": arr[i].entity.groupNames,
				"filterTags": arr[i].entity.filterTags,
				"type": "list",
				"completionState": "n",
				"visible": arr[i].entity.visible,
				"ignoreCompletion": arr[i].entity.ignoreCompletion,
				"hideStatus": arr[i].entity.hideStatus,
				"name": arr[i].entity.label,
				"nameAlexaPlain": arr[i].entity.labelAlexaPlain,
				"nameAlexaRich": arr[i].entity.labelAlexaRich,
				"nameAudio": arr[i].entity.labelAudio,
				"nameSsml": arr[i].entity.labelSsml,
				"namePrint": arr[i].entity.labelPrint,
				"defaultView": arr[i].entity.defaultView,
				"startInBirdseyeView": arr[i].entity.startInBirdseyeView,
				"color": arr[i].entity.color,
				"backgroundImage": arr[i].entity.backgroundImage,
				"video": arr[i].entity.video,
				"image": arr[i].entity.image,
				"audio": arr[i].entity.audio,
				"suppressAudio": arr[i].entity.suppressAudio,
				"suppressAudioTitle": arr[i].entity.suppressAudioTitle,
				"suppressAudioEntityType": arr[i].entity.suppressAudioEntityType,
				"suppressPrint": arr[i].entity.suppressPrint,
				"printProperties": arr[i].entity.printProperties,
				"printCustomProperties": safeParse(arr[i].entity.printCustomProperties, {}),				
				"horzScrollPos": 0,
				"vertScrollPos": 0,
				"children": newChildren
			};

			dstChildren.push(node);
		} else if (arr[i].entity.type == "section") {
			var node = {
				"id": arr[i].entity.id,
				"groupNames": arr[i].entity.groupNames,
				"filterTags": arr[i].entity.filterTags,
				"type": "section",
				"completionState": "n",
				"visible": arr[i].entity.visible,
				"ignoreCompletion": arr[i].entity.ignoreCompletion,
				"hideStatus": arr[i].entity.hideStatus,
				"name": arr[i].entity.label,
				"nameAlexaPlain": arr[i].entity.labelAlexaPlain,
				"nameAlexaRich": arr[i].entity.labelAlexaRich,
				"nameAudio": arr[i].entity.labelAudio,
				"nameSsml": arr[i].entity.labelSsml,
				"namePrint": arr[i].entity.labelPrint,
				"color": arr[i].entity.color,
				"backgroundColor": arr[i].entity.backgroundColor,
				"borderColor": arr[i].entity.borderColor,
				"iconColor": arr[i].entity.iconColor,
				"backgroundImage": arr[i].entity.backgroundImage,
				"video": arr[i].entity.video,
				"image": arr[i].entity.image,
				"audio": arr[i].entity.audio,
				"suppressAudio": arr[i].entity.suppressAudio,
				"suppressAudioTitle": arr[i].entity.suppressAudioTitle,
				"suppressAudioEntityType": arr[i].entity.suppressAudioEntityType,
				"suppressPrint": arr[i].entity.suppressPrint,
				"printProperties": arr[i].entity.printProperties,
				"printCustomProperties": safeParse(arr[i].entity.printCustomProperties, {}),				
				"horzScrollPos": 0,
				"vertScrollPos": 0,
				"children": newChildren
			};

			dstChildren.push(node);
		} else if (arr[i].entity.type.startsWith("item")) {
			var node = {
				"id": arr[i].entity.id,
				"groupNames": arr[i].entity.groupNames,
				"filterTags": arr[i].entity.filterTags,
				"type": arr[i].entity.type,
				"checked": false,
				"visible": arr[i].entity.visible,
				"ignoreCompletion": arr[i].entity.ignoreCompletion,
				"enabled": true,
				"label1": arr[i].entity.label,
				"label1EditorAdvanced": arr[i].entity.label1EditorAdvanced,
				"label1AlexaPlain": arr[i].entity.label1AlexaPlain,
				"label1AlexaRich": arr[i].entity.label1AlexaRich,
				"label1": arr[i].entity.label,
				"label1Audio": arr[i].entity.label1Audio,
				"label1Ssml": arr[i].entity.label1Ssml,
				"label1Print": arr[i].entity.label1Print,
				"color": arr[i].entity.color,
				"label2": arr[i].entity.label2,
				"label2EditorAdvanced": arr[i].entity.label2EditorAdvanced,
				"label2AlexaPlain": arr[i].entity.label2AlexaPlain,
				"label2AlexaRich": arr[i].entity.label2AlexaRich,
				"label2Audio": arr[i].entity.label2Audio,
				"label2Ssml": arr[i].entity.label2Ssml,
				"label2Print": arr[i].entity.label2Print,
				"label2Color": arr[i].entity.label2Color,
				"label3": arr[i].entity.label3,
				"label3EditorAdvanced": arr[i].entity.label3EditorAdvanced,
				"label3AlexaPlain": arr[i].entity.label3AlexaPlain,
				"label3AlexaRich": arr[i].entity.label3AlexaRich,
				"label3Audio": arr[i].entity.label3Audio,
				"label3Ssml": arr[i].entity.label3Ssml,
				"label3Print": arr[i].entity.label3Print,
				"label3Color": arr[i].entity.label3Color,
				"mandatory": arr[i].entity.mandatory,
				"comments": arr[i].entity.comments,
				"commentsInline": arr[i].entity.commentsInline,
				"commentsAuthor": arr[i].entity.commentsAuthor,
				"commentsAudio": arr[i].entity.commentsAudio,
				"commentsSsml": arr[i].entity.commentsSsml,
				"commentsPrint": arr[i].entity.commentsPrint,
				"linkOnCheck": arr[i].entity.linkOnCheck,
				"linkId": arr[i].entity.linkId,
				"linkActionType": arr[i].entity.linkActionType,
				"backgroundImage": arr[i].entity.backgroundImage,
				"video": arr[i].entity.video,
				"image": arr[i].entity.image,
				"audio": arr[i].entity.audio,
				"suppressAudio": arr[i].entity.suppressAudio,
				"suppressAudioLabel1": arr[i].entity.suppressAudioLabel1,
				"suppressAudioLabel2": arr[i].entity.suppressAudioLabel2,
				"suppressAudioLabel3": arr[i].entity.suppressAudioLabel3,
				"suppressPrint": arr[i].entity.suppressPrint,
				"suppressPrintComments": arr[i].entity.suppressPrintComments,
				"startTracking": arr[i].entity.startTracking,
				"stopTracking": arr[i].entity.stopTracking,
				"startNamedTimers": arr[i].entity.startNamedTimers,
				"stopNamedTimers": arr[i].entity.stopNamedTimers,
				"valueTrigger": arr[i].entity.valueTrigger,
				"printProperties": arr[i].entity.printProperties,
				"printCustomProperties": safeParse(arr[i].entity.printCustomProperties, {})
			};

			// Don't allow both to be true
			if (arr[i].entity.startTracking===true && arr[i].entity.stopTracking === true) {
				node.startTracking = false;
				node.stopTracking = false;
			}
			
			
			var deltaProps = {};
			switch (arr[i].entity.type) {
				case "item":
					deltaProps = {
						labelOnly: arr[i].entity.labelOnly,
						labelOnlyBackgroundColor: arr[i].entity.labelOnlyBackgroundColor,
						startTimer: arr[i].entity.startTimer,
						stopTimer: arr[i].entity.stopTimer
					}
					break;
				case "itemTextInput":
				case "itemTextInputMultiline":
					deltaProps = {
						textInputPlaceholder: arr[i].entity.textInputPlaceholder,
						textInputDefaultValue: arr[i].entity.textInputDefaultValue,
						textInputMaxLength: arr[i].entity.textInputMaxLength,
						textInputKeyboardType: arr[i].entity.textInputKeyboardType,
						textInputKeyboardAutoCapitalize: arr[i].entity.textInputKeyboardAutoCapitalize,
						textInputKeyboardAutoCorrect: arr[i].entity.textInputKeyboardAutoCorrect,
						textInputKeyboardReturnKeyType: arr[i].entity.textInputKeyboardReturnKeyType,
						textInputMaskType: arr[i].entity.textInputMaskType,
						textInputCurrencySymbol: arr[i].entity.textInputCurrencySymbol,
						textInputCurrencySeparator: arr[i].entity.textInputCurrencySeparator,
						textInputNumberOfLines: arr[i].entity.textInputNumberOfLines
					}
					break;
				case "itemYesNo":
					deltaProps = {
						yesNoLinkOnSelect: arr[i].entity.yesNoLinkOnSelect,
						yesNoYesLinkId: arr[i].entity.yesNoYesLinkId,
						yesNoNoLinkId: arr[i].entity.yesNoNoLinkId,
						yesNoLinkActionType: arr[i].entity.yesNoLinkActionType,
					}	
					break;
				case "itemPicker":
					deltaProps = {
						pickerItemViewType: arr[i].entity.pickerItemViewType,
						pickerItemPlaceholder: arr[i].entity.pickerItemPlaceholder,
						pickerItemDefaultValue: arr[i].entity.pickerItemDefaultValue,
						pickerItems: arr[i].entity.pickerItems,
						pickerAdvanceOnSelect: arr[i].entity.pickerAdvanceOnSelect,
						pickerLinkOnSelect: arr[i].entity.pickerLinkOnSelect,
						pickerLinkActionType: arr[i].entity.pickerLinkActionType
					}
					break;
				case "itemDate":
				case "itemTime":
				case "itemDateTime":
					deltaProps = {
						dateTimeType: arr[i].entity.dateTimeType,
						dateTimeInitialDate: arr[i].entity.dateTimeInitialDate,
						dateTimeMinuteInterval: arr[i].entity.dateTimeMinuteInterval
					}	
					break;
				case "itemWeather":
					deltaProps = {
						weatherInitialLocation: arr[i].entity.weatherInitialLocation
					}	
					break;
				case "itemBarcodeScanner":
					deltaProps = {
						barcodeTypes: arr[i].entity.barcodeTypes
					}	
					break;
				case "itemImagePicker":
					deltaProps = {
						imagePickerMediaType: arr[i].entity.imagePickerMediaType,
						imagePickerAddMediaButton: arr[i].entity.imagePickerAddMediaButton,
						imagePickerUploadTitle: arr[i].entity.imagePickerUploadTitle,
						imagePickerCaptureMediaTitle: arr[i].entity.imagePickerCaptureMediaTitle
					}	
					break;
				case "itemSketchPad":
					deltaProps = {
						sketchPadBackgroundColor: arr[i].entity.sketchPadBackgroundColor,
						sketchPadPenColor: arr[i].entity.sketchPadPenColor,
						sketchPadPenWidth: Number(arr[i].entity.sketchPadPenWidth),
						sketchPadHeight: Number(arr[i].entity.sketchPadHeight)
					}	
					break;
	
			}
	
			// Merge props
			node = _.assign({}, node, deltaProps);		
				

			dstChildren.push(node);
		}

		if( arr[i].hasOwnProperty("children")) {
			recurseChecklistEntities(arr[i].children,newChildren);
		}
	}
}

function safeParse(obj, defaultValue = null) {
	let result = defaultValue;
	if (result) {
		try {
			result = JSON.parse(obj);
		} catch (e) { } // eslint-disable-line
	}
	return result;
}