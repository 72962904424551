import React from 'react';

import {createMarkupForLabel, openUrl} from '../../utils/utils';

class AfPropDescription extends React.PureComponent {
    render() {    
        var learnMoreLinks = [];
        
        if (this.props.description && this.props.description.learnMoreLinks) {
            learnMoreLinks = this.props.description.learnMoreLinks.map(function (learnMoreLink, i) {
                return (
                    <div key={"learnMoreLink-"+i}>
                        <a href="#" onClick={(e)=>{openUrl(e,learnMoreLink.url)}}>{<div dangerouslySetInnerHTML={createMarkupForLabel(learnMoreLink.title)} />}</a>
                    </div>
                );			
            });    
        }
        
        return (
			<div style={{fontWeight: "unset", display: "flex", flexDirection: "column"}}>
				{<div dangerouslySetInnerHTML={createMarkupForLabel(this.props.description.description)} />}
                {learnMoreLinks.length > 0 &&
                    <div>
                        <br/>
                        <div><b>Learn More</b></div>
                        {learnMoreLinks}
                    </div>
                }
            </div>
        );	  
    }
}

export default AfPropDescription;