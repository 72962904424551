import React from 'react';
import ReactDOM from 'react-dom';

import { branch } from 'baobab-react/higher-order';
import * as actions from '../../actions/portalActions';
import * as utils from '../../utils/utils';

import { Link } from 'react-router-dom';

import { Button } from 'react-bootstrap';
import { Panel } from 'react-bootstrap';

import Flexbox from 'flexbox-react';

import { HotKeys } from 'react-hotkeys';

import Alerts from '../app-alerts.js';

import HomeLink from './app-home-link.js';

const keyMap = {
	'verify': 'enter'
}

class VerifyUser extends React.Component {
	submit() {
		var username = ReactDOM.findDOMNode(this.refs.username).value;
		var code = ReactDOM.findDOMNode(this.refs.code).value;

		actions.verifyUser(username, code);
	};

	render() {
		const handlers = {
			'verify': this.submit.bind(this)
		}

		return (
			<HotKeys keyMap={keyMap} handlers={handlers}>
				<Flexbox className="myBackground" flexDirection="column">
					<HomeLink />
					<Flexbox flexDirection="column" alignItems="center" justifyContent="center" flexGrow={1}>
						<Alerts />
						<h1 style={{ color: "#eeeeee", margin: "10px", marginTop: "220px" }}>Verify Your Account</h1>
						<p className="col-xs-10 col-sm-6 col-md-5 col-lg-4" style={{ fontFamily: "Manrope", color: "#eeeeee", padding: "10px" }}>You should receive an email from us within the next 2 minutes. If you haven't, please check your spam folder or use the resend verification code option on the <Link style={{ color: "#0E7AFE" }} to="/portalHome">Sign up Screen</Link>. If you still encouter any troubles, please reach out to our <a style={{ color: "#0E7AFE" }} href="mailto:support@aerosys.io?Subject=No%20Verification%20Code" target="_top">Goose Support</a>.</p>
						<Panel style={{ marginBottom: "5px", padding: "0px", overflow: "auto" }} className="col-xs-10 col-sm-6 col-md-5 col-lg-4 as-panel-new">
							<Panel.Body>
								<div className="row">
									{/* <div className="col-xs-12">
										<label>Username (case-sensitive)</label>
									</div> */}
									<div className="col-xs-12">
										<input ref="username" style={{ marginBottom: "10px" }} className="form-control" type="text" autoCorrect="off" autoCapitalize="none" defaultValue={this.props.user.username} placeholder='Username (case sesitive)' />
									</div>
									{/* <div className="col-xs-12">
										<label>Verification Code</label>
									</div> */}
									<div className="col-xs-12">
										<input autoFocus={utils.isMobile()} ref="code" style={{ marginBottom: "5px" }} className="form-control" type="number" defaultValue="" placeholder='Verification Code' />
									</div>
								</div>
								<Button style={{ width: "100%", borderRadius: "20px", marginBottom: "5px", color: "black", fontWeight: "bold", fontFamily: 'Syne' }} bsStyle="primary" onClick={this.submit.bind(this)}>VERIFY</Button>
							</Panel.Body>
						</Panel>
						<span style={{ color: "white", marginTop: "100px" }}>Not yet registered?</span>
						<Link style={{ color: "#eba20e", fontSize: "12pt" }} to="/portalHome">Sign up</Link>
						<div style={{ color: "white", padding: "20px", textAlign: "center", width: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
							{this.props.version.copyright}.<br />{this.props.version.prefix}&nbsp;{this.props.version.major}.{this.props.version.minor}.{this.props.version.revision}.{this.props.version.build}&nbsp;{this.props.version.suffix}
						</div>
					</Flexbox>
				</Flexbox>
			</HotKeys>
		);
	}
}

export default branch({
	user: ['user'],
	version: ['version']
}, VerifyUser);

