import React, { useCallback } from "react";

import globals from '../../constants/globals.js';
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import state from "../../state/state";
import browserHistory from "../../history";
import { forceLogin } from "../../actions/actions";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.
var stripeKey =  globals.stage==="prod"?globals.stripeKeyProd:globals.stripeKeyDev;
const stripePromise = loadStripe(stripeKey);

const Checkout = (props) => {
  const fetchClientSecret = useCallback(async () => {
    if (!state.get(["user", "loggedIn"])) {
			forceLogin(browserHistory, props.location.pathname);
		} else {
      const params = props.params;
      // If have customerid use it otherwise use email
      const stripeCustomerId = state.get(["user", "stripeCustomerId"]);
      if (stripeCustomerId && stripeCustomerId !== "") {
        params.customer = stripeCustomerId;
      } else {
        params.customer_email = state.get(["user", "email"]);
      }

      // Create a Checkout Session
      const response = await fetch("https://6qlld3f4yl.execute-api.us-east-1.amazonaws.com/prod/stripe-create-checkout-session", {
        method: "POST",
        body: JSON.stringify(params)
      });
      const jsonResponse = await response.json();
      const newJsonResponse = JSON.parse(jsonResponse.body);
      return newJsonResponse.clientSecret;
    }
  }, []);

  const options = {fetchClientSecret};

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  )
}

export default Checkout;