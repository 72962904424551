import React from 'react';
import {branch} from 'baobab-react/higher-order';
import * as actions from '../actions/actions';

import Editor from './app-editor.js';

//import shallowCompare from 'react-addons-shallow-compare';

class EditorWrapper extends React.PureComponent {
	/*
	shouldComponentUpdate(nextProps, nextState) {
		return shallowCompare(this, nextProps, nextState);
	}
*/
	render() {
		return (
			<Editor style={{width: "100%"}}/>
		);
	}
}

export default branch({
	selectedNodePath: ['selectedNodePath']
}, EditorWrapper);