import React from 'react';
import {FormControl} from 'react-bootstrap';

class TextInputMaskType extends React.PureComponent {
    render() {
        return (
            <FormControl componentClass="select" value={this.props.value} onChange={this.props.onChange}>
                <option value="none">None</option>
                <option value="phone">Phone</option>
                <option value="money">Money</option>
            </FormControl>
        )    
    }
}

export default TextInputMaskType;