import React from 'react';
import {branch} from 'baobab-react/higher-order';
import Category from './app-category.js';
import CardCategory from './app-card-category.js';
import PropTypes from 'baobab-react/prop-types';

//import shallowCompare from 'react-addons-shallow-compare';

import Flexbox from 'flexbox-react';

import MediaQuery from 'react-responsive';

import { DragDropContext, Droppable } from 'react-beautiful-dnd'; 

import * as actions from '../actions/actions';

const getListStyleCategory = isDraggingOver => ({
	background: isDraggingOver ? "#00000011" : "transparent",
//   padding: grid
});


class ChecklistEditor extends React.PureComponent{
	// shouldComponentUpdate(nextProps, nextState) {
	// 	return shallowCompare(this, nextProps, nextState);
	// }



	render() {
		const arrPath = "tree,root";
				
		return (
			<Flexbox flex="1" flexDirection="column" style={{width: "100%"}}>
				
				<MediaQuery query='(max-width: 767px)'>
					<Flexbox flex="1" flexDirection="column" style={{overflowX: "hidden", overflowY: "auto", width: "100%"}}>
						<DragDropContext onDragEnd={(result)=>{this.props.dispatch(
							actions.handleMoveNode,
							result.destination.droppableId,
							result.source.index,
							result.destination.index
						)}}>
							<Droppable droppableId="root">
								{(provided, snapshot) => (
									<div
										{...provided.droppableProps}
										ref={provided.innerRef}
										style={getListStyleCategory(snapshot.isDraggingOver)}
									>
										<CardCategory editorState={this.props.editorState} node={this.props.tree.root} path={arrPath} genres={this.props.genres} provided={provided}/>
										{provided.placeholder}
									</div>				
								)}	
							</Droppable>
						</DragDropContext>	
					</Flexbox>	
				</MediaQuery>	
				<MediaQuery query='(min-width: 768px)'>
					<Flexbox flex="1" flexDirection="column" style={{overflowX: "hidden", overflowY: "auto", width: "100%"}}>
						<DragDropContext onDragEnd={(result)=>{this.props.dispatch(
							actions.handleMoveNode,
							result.destination.droppableId,
							result.source.index,
							result.destination.index
						)}}>
							<Droppable droppableId="root">
								{(provided, snapshot) => (
									<div
										{...provided.droppableProps}
										ref={provided.innerRef}
										style={getListStyleCategory(snapshot.isDraggingOver)}
									>
										<Category editorState={this.props.editorState} node={this.props.tree.root} path={arrPath} genres={this.props.genres}/>
										{provided.placeholder}
									</div>				
								)}	
							</Droppable>
						</DragDropContext>	
					</Flexbox>	
				</MediaQuery>	
			</Flexbox>
		);
	}
}

ChecklistEditor.contextTypes = {
	tree: PropTypes.baobab
};


export default branch({
	editorState : ['appState','editor'],
	tree: ['tree'],
	selectedNodePath: ['selectedNodePath'],
	genres: ['genres']
}, ChecklistEditor);
