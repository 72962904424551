import React from 'react';
import ReactDOM from 'react-dom';

import {branch} from 'baobab-react/higher-order';
import * as actions from '../actions/actions';
import * as actionsPortal from '../actions/portalActions';

import {Panel} from 'react-bootstrap';

//import shallowCompare from 'react-addons-shallow-compare';

import * as utils from '../utils/utils';

import ReactPlayer from 'react-player';
import ReactAudioPlayer from 'react-audio-player';

class EditorMediaPanel extends React.PureComponent{
	constructor(props, context) {
		super(props, context);

		this.handleChangeBackgroundImage = this.handleChangeBackgroundImage.bind(this);
		this.handleChangeImage = this.handleChangeImage.bind(this);
		this.handleChangeVideo = this.handleChangeVideo.bind(this);
		this.handleChangeAudio = this.handleChangeAudio.bind(this);		
	}
/*
	shouldComponentUpdate(nextProps, nextState) {
		return shallowCompare(this, nextProps, nextState);
	}
*/
	handleChangeBackgroundImage(e) {
		this.props.dispatch(
			actions.handleChangeProperty,
			"backgroundImage",
			e.target.value
		);
	}

	handleChangeImage(e) {
		this.props.dispatch(
			actions.handleChangeProperty,
			"image",
			e.target.value
		);
	}

	handleChangeVideo(e) {
		this.props.dispatch(
			actions.handleChangeProperty,
			"video",
			e.target.value
		);
	}

	handleChangeAudio(e) {
		this.props.dispatch(
			actions.handleChangeProperty,
			"audio",
			e.target.value
		);
	}
	
	importMediaFile(event, type) {
		var fileName = utils.generateUUID();

		console.log('Selected file:', event.target.files[0]);
		var file = event.target.files[0];
		var parts = file.name.split(".");
		var ext = parts[parts.length-1];

		actionsPortal.uploadMediaFile(event.target.files[0],this.props.user.identityId + "_" + fileName + "." + ext, type);

		var newExt;
		if (type === "video") {
			newExt = "mp4";	
		} else if (type === "audio") {
			newExt = "mp3";	
		} else {
			newExt = "jpg";	
		}

		return "https://s3.amazonaws.com/content.checklist.miralouaero.com/" + this.props.user.identityId + "/assets/" + type + "/" + fileName + "." + newExt;	
	}

	importBackgroundImageFile(event) {
		var s3Url = this.importMediaFile(event, "background-image");
		this.props.dispatch(
			actions.handleChangeProperty,
			"backgroundImage",
			s3Url
		);		
	}
	
	importImageFile(event) {
		var s3Url = this.importMediaFile(event, "image");
		this.props.dispatch(
			actions.handleChangeProperty,
			"image",
			s3Url
		);		
	}
	
	importVideoFile(event) {
		var s3Url = this.importMediaFile(event, "video");
		this.props.dispatch(
			actions.handleChangeProperty,
			"video",
			s3Url
		);		
	}
	
	importAudioFile(event) {
		var s3Url = this.importMediaFile(event, "audio");
		this.props.dispatch(
			actions.handleChangeProperty,
			"audio",
			s3Url
		);		
	}	

	render() {
		var entity = this.props.node.entity;

		var mediaPanel;	
		if (this.props.urlParams.mode === "smartdisplay") {
			mediaPanel =
			<Panel style={{marginTop: "25px", marginBottom: "0px"}} defaultExpanded={false}>
				<Panel.Heading>
					<Panel.Title toggle>
						Media
					</Panel.Title>
				</Panel.Heading>
				<Panel.Collapse>
					<Panel.Body>
						<div>
							<label>Background Image</label>
						</div>
						<div>
							<input style={{width: "99%"}} type="file" accept="image/*" id="captureBackgroundImage" onChange={this.importBackgroundImageFile.bind(this)}/>
							<textarea ref="inputBackgroundImage" style={{marginTop: "15px", marginBottom: "15px"}} className="form-control" placeholder="(Background Image URL)" value={entity.backgroundImage} onChange={this.handleChangeBackgroundImage} />
							<img style={{width: "100%", maxWidth: "400px"}} src={entity.backgroundImage} />
						</div>
						<div style={{marginTop: "15px"}}>
							<label>Image</label>
						</div>
						<div>
							<input style={{width: "99%"}}  type="file" accept="image/*" id="captureImage" onChange={this.importImageFile.bind(this)}/>
							<textarea ref="inputImage" style={{marginTop: "15px", marginBottom: "15px"}} className="form-control" placeholder="(Image URL)" value={entity.image} onChange={this.handleChangeImage} />
							<img style={{width: "100%", maxWidth: "400px"}} src={entity.image} />
						</div>
						<div style={{marginTop: "15px"}}>
							<label>Video</label>
						</div>
						<div>
							<input style={{width: "99%"}}  type="file" accept="video/*" id="captureVideo" onChange={this.importVideoFile.bind(this)}/>
							<textarea ref="inputVideo" style={{marginTop: "15px", marginBottom: "15px"}} className="form-control" placeholder="(Video URL)" value={entity.video} onChange={this.handleChangeVideo} />
							<ReactPlayer url={entity.video} style={{width: "100%", maxWidth: "400px"}} width="100%" height="100%" playing muted={true} loop controls />
						</div>
						<div style={{marginTop: "15px"}}>
							<label>Audio</label>
						</div>
						<div>
							<input style={{width: "99%"}}  type="file" accept="audio/*" id="captureAudio" onChange={this.importAudioFile.bind(this)}/>
							<textarea ref="inputAudio" style={{marginTop: "15px", marginBottom: "15px"}} className="form-control" placeholder="(Audio URL)" value={entity.audio} onChange={this.handleChangeAudio} />
							<ReactAudioPlayer style={{width: "100%", maxWidth: "400px"}} src={entity.audio} controls />
						</div>
					</Panel.Body>	
				</Panel.Collapse>	
			</Panel>
		} else {
			return null;
		}

		return (
			<div style={{marginBottom: "25px"}}>
				{mediaPanel}
			</div>
		);
	}
}

export default branch({
	user: ['user'],
	urlParams: ['appState','urlParams']
}, EditorMediaPanel);