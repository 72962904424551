import React from "react";
import ReactDOM from "react-dom";
import browserHistory from "../../history";
import { branch } from "baobab-react/higher-order";
import PropTypes from "baobab-react/prop-types";
import * as actions from "../../actions/portalActions";
import * as utils from "../../utils/utils";

import globals from "../../constants/globals.js";

import { Button } from "react-bootstrap";
import { Panel } from "react-bootstrap";

import { Link } from "react-router-dom";

import Flexbox from "flexbox-react";

import Loader from "react-loader-advanced";
import FontAwesome from "react-fontawesome";

import { HotKeys } from "react-hotkeys";

import Alerts from "../app-alerts.js";

import HomeLink from "./app-home-link.js";

var request = require("superagent");

const keyMap = {
  login: "enter",
};

class Login extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  loginLoader() {
    this.props.dispatch(utils.showLoader);
    setTimeout(this.login.bind(this), 300);
  }

  login() {
    if (this.props.igapp) {
      this.emailMagicLink();
    } else {
      var username = ReactDOM.findDOMNode(this.refs.username).value;
      var password = ReactDOM.findDOMNode(this.refs.password).value;
      if (this.refs.hasOwnProperty("savePassword")) {
        var savePassword = this.savePasswordCheckbox.checked;

        this.props.dispatch(actions.login, username, password, savePassword);
      } else {
        this.props.dispatch(actions.login, username, password, false);
      }
    }
  }

  forgot() {
    var username = ReactDOM.findDOMNode(this.refs.username).value;

    if (username != "") {
      actions.forgotPasswordForUser(username);
    } else {
      alert("Please enter a username.");
    }
  }

  onBlur() {
    this.props.dispatch(
      actions.setUsername,
      ReactDOM.findDOMNode(this.refs.username).value
    );
  }

  componentDidMount() {
    if (this.props.user && this.props.user.loggedIn) {
      browserHistory.push("/home");
    }
  }

  emailMagicLink() {
    // var username = ReactDOM.findDOMNode(this.refs.username).value;
    var email = ReactDOM.findDOMNode(this.refs.email).value;

    const body = {
      // "username": "",
      email: email,
    };

    request
      .post(globals.apiGatewayUrl + "/igappPasswordlessLogin")
      .send(body)
      .end(function (err, res) {
        if (err || !res.ok) {
          alert(err.message);
        } else {
          alert("A MagicLink has been sent to your email address.");
        }
      });
  }

  /*
		componentDidMount() {
			var self=this;
			setTimeout(function() {
				var usernameInput = ReactDOM.findDOMNode(self.refs.username);
				var loginButton = ReactDOM.findDOMNode(self.refs.login);
				if (usernameInput.value === "") {
					usernameInput.focus();	
				} else {
					loginButton.focus();
				}
			},1000);
		}
	*/
  render() {
    var self = this;

    const handlers = {
      login: this.loginLoader.bind(this),
    };

    const spinner = <FontAwesome name="spinner" size="4x" spin />;
    var savePassword;
    // See if we are being hosted in a webview
    // WebView
    try {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ method: "dummy" })
      );
      savePassword = (
        <label style={{ marginTop: 10 }}>
          <input
            type="checkbox"
            ref={(input) => {
              this.savePasswordCheckbox = input;
            }}
            defaultChecked={
              this.props.user.password != "" &&
              typeof this.props.user.password != "undefined"
                ? true
                : true
            }
          />{" "}
          Remember Password
        </label>
      );
    } catch (e) {}
    // WKWebView
    try {
      window.webkit.messageHandlers.reactNative.postMessage(
        JSON.stringify({ method: "dummy" })
      );
      savePassword = (
        <label style={{ marginTop: 10 }}>
          <input
            type="checkbox"
            ref={(input) => {
              this.savePasswordCheckbox = input;
            }}
            defaultChecked={
              this.props.user.password != "" &&
              typeof this.props.user.password != "undefined"
                ? true
                : true
            }
          />{" "}
          Remember Password
        </label>
      );
    } catch (e) {}
    // UIWebView
    try {
      window.postMessage(JSON.stringify({ method: "dummy" }));
      savePassword = (
        <label style={{ marginTop: 10, fontWeight: "normal" }}>
          <input
            type="checkbox"
            ref={(input) => {
              this.savePasswordCheckbox = input;
            }}
            defaultChecked={
              this.props.user.password != "" &&
              typeof this.props.user.password != "undefined"
                ? true
                : true
            }
            style={{
              marginRight: "5px",
              position: "relative",
              top: "2px",
            }}
          />{" "}
          Remember Password
        </label>
      );
    } catch (e) {}

    if (this.props.igapp) {
      return (
        <HotKeys keyMap={keyMap} handlers={handlers}>
          <Loader show={this.props.appState.loading} message={spinner}>
            <Flexbox className="myBackground" flexDirection="column">
              <HomeLink />

              {utils.isMobile() && !this.props.appState.loading && (
                <Flexbox
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  flexGrow={1}
                >
                  <Alerts />
                  <Button
                    bsSize="large"
                    ref="login"
                    bsStyle="primary"
                    onClick={() => {
                      this.props.dispatch(utils.showLoader);
                      setTimeout(function () {
                        actions.loginUserIgapp(
                          self.props.user.username,
                          self.props.user.password
                        );
                      }, 300);
                    }}
                  >
                    Open Goose Cloud
                  </Button>
                </Flexbox>
              )}
              {!utils.isMobile() && !this.props.appState.loading && (
                <Flexbox
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  flexGrow={1}
                >
                  <Alerts />
                  <h3 style={{ marginTop: "10px", color: "#eeeeee" }}>
                    Email MagicLink
                  </h3>
                  <Panel
                    style={{
                      margin: "0px",
                      padding: "15px",
                      width: "400px",
                      maxWidth: "90%",
                    }}
                  >
                    <Panel.Body>
                      <div className="row">
                        <div>
                          <label>Your Email</label>
                        </div>
                        <div>
                          <input
                            ref="email"
                            style={{ marginBottom: "10px" }}
                            className="form-control"
                            type="text"
                            autoCapitalize="none"
                            defaultValue=""
                          />
                        </div>
                      </div>
                      <Button
                        ref="login"
                        bsStyle="primary"
                        onClick={this.login.bind(this)}
                      >
                        Email MagicLink
                      </Button>
                    </Panel.Body>
                  </Panel>
                </Flexbox>
              )}
            </Flexbox>
          </Loader>
        </HotKeys>
      );
    } else {
      return (
        <HotKeys keyMap={keyMap} handlers={handlers}>
          <Flexbox className="myBackground" flexDirection="column">
            <HomeLink />
            <Flexbox
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              flexGrow={1}
            >
              <Alerts />
              <h1
                style={{ margin: "10px", marginTop: "140px", color: "#eeeeee" }}
              >
                Login to Goose Cloud
              </h1>
              {/* <h3 style={{ color: "#fdfdfd", marginTop: "10px" }}>Not registered?</h3> */}
              {/* <Link style={{ color: "#0E7AFE", fontSize: "16pt" }} to="/portalHome">Sign up</Link> */}
              <Link
                style={{
                  color: "#eba20e",
                  fontSize: "12pt",
                  marginBottom: "20px",
                }}
                to="/portalHome"
              >
                <span
                  style={{
                    fontFamily: "Manrope",
                    color: "white",
                  }}
                >
                  No Account yet?{" "}
                </span>{" "}
                Sign Up!
              </Link>
              <Loader
                show={this.props.appState.loading}
                message={spinner}
                style={{ margin: "0px", padding: "0px" }}
                className="col-xs-10 col-sm-6 col-md-5 col-lg-4"
                backgroundStyle={{
                  overflow: "auto",
                  height: "calc(100% - 20px)",
                  borderRadius: "4px",
                }}
              >
                <Panel
                  style={{ overflow: "auto" }}
                  className="col-xs-12 as-panel-new"
                >
                  <Panel.Body>
                    <div className="row">
                      {/* <div className="col-xs-12">
												<label>Username (case-sensitive)</label>
											</div> */}
                      <div className="col-xs-12">
                        <input
                          autoFocus={!utils.isMobile()}
                          ref="username"
                          style={{ marginBottom: "10px" }}
                          className="form-control"
                          type="text"
                          autoCorrect="off"
                          autoCapitalize="none"
                          defaultValue={this.props.user.username}
                          onBlur={this.onBlur.bind(this)}
                          placeholder="Username (case sesitive)"
                        />
                      </div>
                      {/* <div className="col-xs-12">
												<label>Password</label>
											</div> */}
                      <div className="col-xs-12">
                        <input
                          ref="password"
                          style={{ marginBottom: "5px" }}
                          className="form-control"
                          type="password"
                          defaultValue={this.props.user.password}
                          placeholder="Password"
                        />
                      </div>
                    </div>

                    <Button
                      style={{
                        marginBottom: "5px",
                        color: "black",
                        fontWeight: "bold",
                        fontFamily: "Syne",
                      }}
                      ref="login"
                      bsStyle="primary"
                      onClick={this.loginLoader.bind(this)}
                      className="asSignUpBtn"
                    >
                      LOGIN
                    </Button>
                    <br></br>
                    <p
                      style={{
                        textAlign: "center",
                        color: "#a0a0a0",
                      }}
                    >
                      By logging in you agree to our <a style={{color: '#a0a0a0'}} href="https://miracheck.com/privacy.html">Privacy Policy</a>.
                    </p>
                    <Button
                      style={{
                        color: "#eeeeee",
                        padding: 0,
                        marginBlock: 15,
                        fontFamily: "Manrope",
                      }}
                      bsStyle="link"
                      onClick={this.forgot.bind(this)}
                    >
                      Forgot Password?
                    </Button>
                    <br />
                    {/* {savePassword} */}
                  </Panel.Body>
                </Panel>
              </Loader>

              <div
                style={{
                  color: "white",
                  padding: "20px",
                  textAlign: "center",
                  width: "100%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {this.props.version.copyright}.<br />
                {this.props.version.prefix}&nbsp;{this.props.version.major}.
                {this.props.version.minor}.{this.props.version.revision}.
                {this.props.version.build}&nbsp;{this.props.version.suffix}
              </div>
            </Flexbox>
          </Flexbox>
        </HotKeys>
      );
    }
  }
}

export default branch(
  {
    user: ["user"],
    appState: ["appState"],
    version: ["version"],
    igapp: ["appCapabilities", "igapp"],
  },
  Login
);
