import React from "react";
import { branch, root } from "baobab-react/higher-order";
import PropTypes from "baobab-react/prop-types";
import * as actions from "../../actions/actions";
import { Button } from 'react-bootstrap';
import Alerts from '../../components/app-alerts.js';

import Flexbox from "flexbox-react";
import browserHistory from "../../history";

import NavLinks from '../navPanel/app-nav-links.js';
import NavPanel from '../navPanel/app-nav-panel.js';

import MediaQuery from 'react-responsive';

import TimerCard from "./app-timer-card-container";

import state from "../../state/state";

import { saveTimerTemplate, cancelTimerModal, addTimerTemplate } from "../../actions/timerActions";

import TimerCardEdit from "./app-timer-card-edit";

import Modal from "react-responsive-modal";

import moment from "moment";

class TimerTemplates extends React.PureComponent {
	constructor(props, context) {
		super(props, context);

		if (!props.user.loggedIn) {
			actions.forceLogin(browserHistory, this.props.location.pathname);
		}

		this.state = {
			selectedIdentityId: this.props.user.identityId
		};
	}

	getTimerTemplateFromState(state) {
		let action;

		if (state.action !== "") {
			action = {
				"type": "link",
				"message": state.message,
				"action": state.action,
				"linkItemId": state.linkItemId,
				"checklistId": state.checklistId
			};
		} else {
			action = {
				"type": "message",
				"message": state.message
			};
		}

		const timerTemplate = {
			guid: state.guid,
			timestamp: moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSS"),
			name: state.name,
			type: state.type,
			custom: state.custom,
			mode: state.mode,
			viewType: state.viewType,
			hours: Number(state.hours),
			minutes: Number(state.minutes),
			seconds: Number(state.seconds),
			repeat: state.repeat,
			repeatCount: Number(state.repeatCount),
			label1: state.label1,
			label2: state.label2,
			hideDismiss: state.hideDismiss,
			suppressAudio: state.suppressAudio,
			action: action,
			description: ""
		};

		return timerTemplate;
	}


	componentDidMount() {
		this.context.tree.select(["appState", "navPanel"]).set("selected", "timers");
	}

	render() {
		if (!this.props.user.loggedIn) {
			return (
				<div />
			);
		} else {
			let timerCards = this.props.timerTemplates.map(function (timerTemplate) {
				if (!timerTemplate.deleted) {
					return (
						<div style={{ margin: 5 }}>
							<TimerCard
								timerTemplate={timerTemplate}
							/>
						</div>
					);
				} else {
					return null;
				}
			});

			let pageComponent = (
				<div className=".editTimer" style={{ overflow: "auto", width: "100%" }}>
					<Flexbox flexDirection="row" flexWrap="wrap" alignItems="flex-start" style={{ margin: 0 }}>
						<div className=".editTimer" style={{ margin: 5 }}>
							<TimerCard
								new={true}
							/>
						</div>
						{timerCards}
					</Flexbox>
					<Modal classNames={{ modal: "custom-modal-2" }} closeOnOverlayClick={false} open={this.props.timerModal.show} onClose={() => {
						this.props.dispatch(cancelTimerModal);
					}} little>
						<h3 className="editTimerHeader" style={{ textAlign: "center" }}>Edit Timer</h3>
						<TimerCardEdit
							timerTemplate={this.props.timerModal.timerTemplate}
							onSave={(state) => {
								console.log("SAVE: " + JSON.stringify(state));

								const timerTemplate = this.getTimerTemplateFromState(state);

								this.props.dispatch(saveTimerTemplate, timerTemplate);
							}}
							onCancel={() => {
								this.props.dispatch(cancelTimerModal);
							}}
						/>
					</Modal>
				</div>
			);

			return (
				<Flexbox flexDirection="column" height="100vh" style={{ minHeight: 0 }}>
					<Alerts />
					<NavLinks />
					<MediaQuery query='(max-width: 599px)'>
						<Flexbox flexDirection='row' paddingLeft="20px" width="100%">
							<Flexbox flexGrow={1} style={{ borderBottom: "1px solid #ccc" }} justifyContent="space-between">
								<h1>TIMERS</h1>
							</Flexbox>
						</Flexbox>
						<Flexbox flex="1" flexDirection="row" width="100vw" style={{ minHeight: 0 }}>
							{!this.props.user.subscriptionPlan.startsWith("pro-plan") &&
								<span style={{ fontSize: 24, marginLeft: 10, marginRight: 10, color: "red" }}>
									You must be a Pro Plan subscriber to use timers.
								</span>
							}
							{this.props.user.subscriptionPlan.startsWith("pro-plan") &&
								<Flexbox style={{ padding: "20px", overflowY: "auto", display: "flex", width: "100%", flexDirection: "column", flexGrow: 1, justifyContent: "flex-start" }}>
									<Flexbox flexDirection='row' width="100%" paddingBottom='10px' paddingLeft="5px" justifyContent="space-between">
										<div></div> {/* Empty div to push the button to the right */}
										<Button bsStyle='primary' style={{ marginTop: 0 }} onClick={() => { this.props.dispatch(addTimerTemplate) }}>
											Create a New Timer
										</Button>
									</Flexbox>
									{pageComponent}
								</Flexbox>
							}
						</Flexbox>
					</MediaQuery>
					<MediaQuery query='(min-width: 600px)'>
						<Flexbox flex="1" flexDirection="row" width="100vw" style={{ minHeight: 0 }}>
							<MediaQuery query='(min-width: 768px)'>
								<NavPanel />
							</MediaQuery>
							{!this.props.user.subscriptionPlan.startsWith("pro-plan") &&
								<span style={{ fontSize: 24, marginLeft: 10, marginRight: 10, color: "red" }}>You must be a Pro Plan subscriber to use timers.</span>
							}
							{this.props.user.subscriptionPlan.startsWith("pro-plan") &&
								<Flexbox style={{ padding: "20px", overflowY: "auto", display: "flex", width: "100%", flexDirection: "column", flexGrow: 1, justifyContent: "flex-start" }}>
									<Flexbox flexDirection="column" alignItems="center" paddingBottom="10px">
										<Flexbox flexDirection='row' width="100%" >
											<Flexbox flexGrow={1} style={{ borderBottom: "1px solid #ccc" }} justifyContent="space-between">
												<h1>CONFIGURE YOUR TIMER</h1>
											</Flexbox>
										</Flexbox>
										<Flexbox flexDirection='row' width="100%" paddingTop='0px'>
										</Flexbox>
									</Flexbox>
									<Flexbox flexDirection='row' width="100%" paddingBottom='10px' paddingLeft="5px" >
										<Flexbox >
											<Button bsStyle='primary' onClick={() => { this.props.dispatch(addTimerTemplate) }}>Create a New Timer</Button>


										</Flexbox>
									</Flexbox>
									{pageComponent}

								</Flexbox>
							}
						</Flexbox>
					</MediaQuery>
				</Flexbox>
			);
		}
	}
}

TimerTemplates.contextTypes = {
	tree: PropTypes.baobab
};

export default root(state, branch({
	user: ["user"],
	timerTemplates: ["timerTemplates"],
	timerModal: ["appState", "timerModal"]
}, TimerTemplates));