import React from "react";

import { Button } from "react-bootstrap";

import Glyphicon from "../../components/app-icon";

import { createMarkupForLabel } from "../../utils/utils";

import AfPropDescription from "./AfPropDescription";

class AfHelp extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showDescription: false,
    };
  }

  render() {
    let description;
    if (typeof this.props.description === "string") {
      description = (
        <div
          dangerouslySetInnerHTML={createMarkupForLabel(this.props.description)}
        />
      );
    } else {
      description = (
        <AfPropDescription
          description={this.props.description}
          learnMoreLinks={this.props.learnMoreLinks}
        />
      );
    }

    const question = (
      <div
        onClick={(e) => {
          this.setState({ showDescription: !this.state.showDescription });
        }}
      >
        <Glyphicon
          style={{ marginLeft: "8px", fontSize: "16px", color: 'rgba(147,155,162,0.9)' }}
          glyph="question-circle"
        />
      </div>
    );

    return (
      <div>
        <div
          style={{
            display: "flex",
            alignItems: this.props.alignItems
              ? this.props.alignItems
              : "flex-start",
          }}
        >
          {this.props.children}
          {question}
        </div>
        {this.state.showDescription && (
          <div
            className="disable-select"
            style={{
              marginBottom: "10px",
              brackground: "#333",
              border: "dashed 1px #999999",
              padding: "10px",
              borderRadius: "10px",
              overflow: "auto",
            }}
          >
            {description}
            <Button
              style={{
                marginTop: "15px",
                backgroundColor: "#1f272b",
                borderRadius: "50px",
                paddingLeft: "2em",
                paddingRight: "2em",
                color: "white",
              }}
              onClick={() => {
                this.setState({ showDescription: false });
              }}
            >
              Close
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default AfHelp;
