import React from 'react';
import ReactDOM from 'react-dom';

import { branch } from 'baobab-react/higher-order';
import PropTypes from 'baobab-react/prop-types';
import * as actions from '../../actions/portalActions';
import * as utils from '../../utils/utils';

import { Button, Checkbox } from 'react-bootstrap';
import { Panel } from 'react-bootstrap';

import Login from './app-login.js';
import Flexbox from 'flexbox-react'

import { Link } from 'react-router-dom';

import { HotKeys } from 'react-hotkeys';

import Alerts from '../app-alerts.js';

import HomeLink from './app-home-link.js';

import browserHistory from '../../history';

const keyMap = {
	'signUp': 'enter'
}

class PortalHome extends React.Component {
	constructor(props, context) {
		super(props, context);

		// Redirect to login if IGAPP
		if (props.igapp) {
			const path = "/login";
			browserHistory.push(path);
		}
	}

	submit() {
		var name = ReactDOM.findDOMNode(this.refs.name).value;
		var username = ReactDOM.findDOMNode(this.refs.username).value;
		var email = ReactDOM.findDOMNode(this.refs.email).value;
		var password = ReactDOM.findDOMNode(this.refs.password).value;

		var user = {
			name: name,
			username: username,
			email: email,
			password: password
		};

		if (name === "" || username === "" || email === "" || password === "") {
			alert("Sorry, you must fill in all fields, then click the Sign up button.");
		} else {
			actions.signUp(user);
		}
	}

	resendVerifyCode() {
		var username = ReactDOM.findDOMNode(this.refs.username).value;

		if (username === "") {
			alert("Please provide the username.");
		} else {
			actions.resendVerifyCode(username);
		}
	}

	render() {
		const handlers = {
			'signUp': this.submit.bind(this)
		}
		// var useEmail = true;

		return (
			<HotKeys keyMap={keyMap} handlers={handlers}>

				<Flexbox className="myBackground" flexDirection="column">
					<HomeLink />
					<Flexbox flexDirection="column" alignItems="center" justifyContent="center" flexGrow={1}>
						<Alerts />
						<h1 style={{ color: "#eeeeee", fontWeight: "bold", margin: "10px", marginBottom: "10px", marginTop: "220px" }}>Be a Maverick. Fly with Goose!</h1>
						<Link style={{ color: "#eba20e", fontSize: "12pt" }} to="/login">
							<span style={{
								fontFamily: "Manrope", color: "white"
							}}>Already have an account? </span> Log In!
						</Link>						{/* <h3 style={{ color: "#eeeeee" }}>Sign up!</h3> */}
						<Panel style={{ marginBottom: "5px", padding: "0px", overflow: "auto" }} className="col-xs-10 col-sm-6 col-md-5 col-lg-4 as-panel-new">
							<Panel.Body>
								<div className="row">
									<div className="col-xs-12">
										{/* <label>Your Name</label> */}
									</div>
									<div className="col-xs-12">
										<input autoFocus={utils.isMobile()} ref="name" style={{ marginBottom: "10px" }} className="form-control" type="text" autoCapitalize="none" defaultValue="" placeholder='Your Name' />
									</div>
									<div className="col-xs-12">
										{/* <label>Your Username (case-sensitive)</label> */}
									</div>
									<div className="col-xs-12">
										{/* <Checkbox checked={useEmail ? "checked" : ""} onChange={(e) => { useEmail = !useEmail; }}>
											Use Email as Username
										</Checkbox> */}
										{/* <input ref="username" style={{ marginBottom: "10px" }} className="form-control" type="checkbox" autoCorrect="off" autoCapitalize="none" defaultValue="" placeholder='Username (case sesitive)' /> */}
										<input ref="username" style={{ marginBottom: "10px" }} className="form-control" type="text" autoCorrect="off" autoCapitalize="none" defaultValue="" placeholder='Username (case sesitive)' />
									</div>
									<div className="col-xs-12">
										{/* <label>Your Email</label> */}
									</div>
									<div className="col-xs-12">
										<input ref="email" style={{ marginBottom: "10px" }} className="form-control" type="text" autoCapitalize="none" defaultValue="" placeholder='Your Email' />
									</div>
									<div className="col-xs-12">
										{/* <label>Create a password</label> */}
									</div>
									<div className="col-xs-12">
										<input ref="password" style={{ marginBottom: "5px" }} className="form-control" type="password" defaultValue="" placeholder='Password' />
									</div>
								
								</div>
								<p
                      style={{
                        textAlign: "center",
                        color: "#a0a0a0",
                      }}
                    >
                      By signin in you agree to our <a style={{color: '#a0a0a0'}} href="https://miracheck.com/privacy.html">Privacy Policy</a>.
                    </p>
								<Button style={{ marginBottom: "5px", color: "black", fontWeight: "bold", fontFamily: 'Syne' }} bsStyle="primary" className="asSignUpBtn" onClick={this.submit.bind(this)}>SIGN UP</Button><br />
								<br></br>
								<Link class="asSignUpLink" style={{ color: "white" }} to="/verify">Enter a Verification Code</Link><br />
								<br></br>
								<Button class="asSignUpLink" bsStyle="link" style={{ color: "white", margin: 0, padding: 0 }} onClick={this.resendVerifyCode.bind(this)}>Resend the Verifcation Code</Button>
							</Panel.Body>
						</Panel>
						<div style={{ color: "white", padding: "20px", textAlign: "center", width: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
							{this.props.version.copyright}.<br />{this.props.version.prefix}&nbsp;{this.props.version.major}.{this.props.version.minor}.{this.props.version.revision}.{this.props.version.build}&nbsp;{this.props.version.suffix}
						</div>
					</Flexbox>
				</Flexbox>
			</HotKeys >
		);
	}
}

PortalHome.contextTypes = {
	tree: PropTypes.baobab
};

export default branch({
	user: ['user'],
	version: ['version'],
	igapp: ['appCapabilities', 'igapp']
}, PortalHome);



