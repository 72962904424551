import React from 'react';
import {FormControl} from 'react-bootstrap';

class TextInputKeyboardType extends React.PureComponent {
    render() {
        return (
            <FormControl componentClass="select" value={this.props.value} onChange={this.props.onChange}>
                <option value="default">Default</option>
                <option value="number-pad">Number Pad</option>
                <option value="decimal-pad">Decimal Pad</option>
                <option value="numeric">Numeric</option>
                <option value="email-address">Email Address</option>
                <option value="phone-pad">Phone Pad</option>
                <option value="ascii-capable">ASCII Capable (iOS Only)</option>
                <option value="numbers-and-punctuation">Numbers and Punctuation (iOS Only)</option>
                <option value="url">URL (iOS Only)</option>
                <option value="name-phone-pad">Name and Phone Pad (iOS Only)</option>
                <option value="twitter">Twitter (iOS Only)</option>
                <option value="web-search">Web Search (iOS Only)</option>
                <option value="visible-password">Visible Password (Android Only)</option>
            </FormControl>
        );  
    }
}

export default TextInputKeyboardType;