import React from "react";
import ReactDOM from "react-dom";

import { branch } from "baobab-react/higher-order";
import PropTypes from "baobab-react/prop-types";
import * as actions from "../actions/actions";
import * as actionsPortal from "../actions/portalActions";
import * as awsS3 from "../persistence/s3";
import axios from "axios";

import _ from "lodash";

import {
  Button,
  Dropdown,
  MenuItem,
  Checkbox,
  Panel,
  FormControl,
} from "react-bootstrap";

import AfPanel from "../components/shared/AfPanel";
import AfHelp from "../components/shared/AfHelp";

import Glyphicon from "../components/app-icon";

import Contributors from "../components/app-contributors";

//import shallowCompare from 'react-addons-shallow-compare';

import { WithContext as ReactTags } from "react-tag-input";

import MediaPanel from "../components/app-editor-media-panel";

import * as utils from "../utils/utils";
import * as transformUtils from "../utils/transformUtils.js";

import { Controlled as CodeMirror } from "react-codemirror2";
import Flexbox from "flexbox-react";
import MediaQuery from "react-responsive";

import { pathToArray } from "../utils/utils";

import CoPilotPhases from "./app-copilot-phases";

import AiAssistant from "./app-ai-assistant";
import { assistantsCreate, assistantsDelete } from "../ai/aiAssistant.js";
import FontAwesome from "react-fontawesome";
import { useRef } from "react";
import { useState } from "react";
import { ClipLoader } from "react-spinners";

require("codemirror/lib/codemirror.css"); // eslint-disable-line
require("codemirror/theme/material.css"); // eslint-disable-line
require("codemirror/mode/javascript/javascript"); // eslint-disable-line
require("codemirror/addon/fold/foldcode"); // eslint-disable-line
require("codemirror/addon/fold/foldgutter"); // eslint-disable-line
require("codemirror/addon/fold/brace-fold.js"); // eslint-disable-line
require("codemirror/addon/fold/foldgutter.css"); // eslint-disable-line

let lastEdit = "";

class EditorCategory extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    this.fileInputRef = React.createRef();

    this.instance1 = null;
    this.instance2 = null;
    this.instance3 = null;

    this.handleChangeId = this.handleChangeId.bind(this);
    this.handleChangeLabel = this.handleChangeLabel.bind(this);
    this.handleChangeLabelAlexaPlain =
      this.handleChangeLabelAlexaPlain.bind(this);
    this.handleChangeLabelAlexaRich =
      this.handleChangeLabelAlexaRich.bind(this);
    this.handleChangeLabelAudio = this.handleChangeLabelAudio.bind(this);
    this.handleChangeLabelSsml = this.handleChangeLabelSsml.bind(this);
    this.handleChangeLabelPrint = this.handleChangeLabelPrint.bind(this);
    this.handleChangeDescription = this.handleChangeDescription.bind(this);
    this.handleChangeGenre = this.handleChangeGenre.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleDeleteTag = this.handleDeleteTag.bind(this);
    this.handleAddTag = this.handleAddTag.bind(this);
    this.handleChangeStartInShowMode =
      this.handleChangeStartInShowMode.bind(this);
    this.handleChangeSuppressAudio = this.handleChangeSuppressAudio.bind(this);
    this.handleChangeIgnoreCompletion =
      this.handleChangeIgnoreCompletion.bind(this);
    this.handleChangeHideStatus = this.handleChangeHideStatus.bind(this);
    this.handleChangeTrackLocation = this.handleChangeTrackLocation.bind(this);
    this.handleChangeItemsOnSingleLine =
      this.handleChangeItemsOnSingleLine.bind(this);
    this.handleChangeAiAssistant = this.handleChangeAiAssistant.bind(this);

    if (window.innerWidth < 768) {
      this.state = {
        open: false,
        advancedOpen: false,
        contentModel: JSON.stringify(this.getContentModel(), null, 3),
        inlineEdit: false,
        selectedFile: null,
        open: window.innerWidth >= 768,
        advancedOpen: false,
        contentModel: JSON.stringify(this.getContentModel(), null, 3),
        inlineEdit: false,
      };
    } else {
      this.state = {
        open: true,
        advancedOpen: false,
        contentModel: JSON.stringify(this.getContentModel(), null, 3),
        inlineEdit: false,
        selectedFile: null,
        open: window.innerWidth >= 768,
        advancedOpen: false,
        contentModel: JSON.stringify(this.getContentModel(), null, 3),
        inlineEdit: false,
        isLoading: false,
        uploadedImageUrl: "",
      };
    }

    this.immediateInlineClick = false;
    this.handleFileChange = this.handleFileChange.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }
  handleFileChange(event) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const base64Image = e.target.result.split(",")[1];
        const mimeType = file.type;
        this.setState({ selectedFile: { base64Image, mimeType } });
        await this.uploadFile({ base64Image, mimeType });
      };
      reader.readAsDataURL(file);
    }
  }

  async uploadFile({ base64Image, mimeType }) {
    this.setState({ isLoading: true });
    try {
      const response = await axios.post(
        "https://2wq7qpbdq5.execute-api.us-east-1.amazonaws.com/dev/upload",
        {
          base64Image,
          mimeType,
        }
      );
      const responseBody = JSON.parse(response.data.body);
      console.log("File uploaded successfully:", responseBody.url);
      this.setState({ uploadedImageUrl: responseBody.url });
      this.props.dispatch(
        actions.handleChangeProperty,
        "image",
        responseBody.url
      );
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  /*
    shouldComponentUpdate(nextProps, nextState) {
      return shallowCompare(this, nextProps, nextState);
    }
  */
  handleChangeId(e) {
    this.props.dispatch(
      actions.handleChangeProperty,
      "checklistId",
      e.target.value
    );
  }

  handleChangeLabel(e) {
    this.props.dispatch(actions.handleChangeProperty, "label", e.target.value);
  }

  handleChangeLabelAlexaPlain(e) {
    this.props.dispatch(
      actions.handleChangeProperty,
      "labelAlexaPlain",
      e.target.value
    );
  }

  handleChangeLabelAlexaRich(e) {
    this.props.dispatch(
      actions.handleChangeProperty,
      "labelAlexaRich",
      e.target.value
    );
  }

  handleChangeLabelAudio(e) {
    this.props.dispatch(
      actions.handleChangeProperty,
      "labelAudio",
      e.target.value
    );
  }

  handleChangeLabelSsml(e) {
    this.props.dispatch(
      actions.handleChangeProperty,
      "labelSsml",
      e.target.value
    );
  }

  handleChangeLabelPrint(e) {
    this.props.dispatch(
      actions.handleChangeProperty,
      "labelPrint",
      e.target.value
    );
  }

  handleChangeDescription(e) {
    this.props.dispatch(
      actions.handleChangeProperty,
      "description",
      e.target.value
    );
  }

  handleChangeGenre(e) {
    this.props.dispatch(actions.handleChangeProperty, "genre", e.target.value);
  }

  handleChangeStartInShowMode(e) {
    this.props.dispatch(
      actions.handleChangeProperty,
      "startInShowMode",
      e.target.checked
    );
  }

  handleChangeSuppressAudio(e) {
    this.props.dispatch(
      actions.handleChangeProperty,
      "suppressAudio",
      e.target.checked
    );
  }

  handleChangeIgnoreCompletion(e) {
    this.props.dispatch(
      actions.handleChangeProperty,
      "ignoreCompletion",
      e.target.checked
    );
  }

  handleChangeHideStatus(e) {
    this.props.dispatch(
      actions.handleChangeProperty,
      "hideStatus",
      e.target.checked
    );
  }

  handleChangeTrackLocation(e) {
    if (
      !this.props.user.subscriptionPlan.startsWith("pro-plan") &&
      e.target.checked
    ) {
      alert("This feature is only available to Pro Plan subscribers.");
    } else {
      this.props.dispatch(
        actions.handleChangeProperty,
        "trackLocation",
        e.target.checked
      );
    }
  }

  handleChangeItemsOnSingleLine(e) {
    if (
      !this.props.user.subscriptionPlan.startsWith("pro-plan") &&
      e.target.checked
    ) {
      alert("This feature is only available to Pro Plan subscribers.");
    } else {
      this.props.dispatch(
        actions.handleChangeProperty,
        "itemsOnSingleLine",
        e.target.checked
      );
    }
  }

  handleChangeAiAssistant(e) {
    if (
      !this.props.user.subscriptionPlan.startsWith("pro-plan") &&
      e.target.checked
    ) {
      alert("This feature is only available to Pro Plan subscribers.");
    } else {
      this.props.dispatch(
        actions.handleChangeProperty,
        "aiAssistant",
        e.target.checked
      );

      if (e.target.checked) {
        // Need to create an assistant
        assistantsCreate();
      } else {
        // Need to delete an assistant
        assistantsDelete();
      }
    }
  }

  handleBlur(e) {
    actions.saveUndoState();
  }

  handleDeleteTag(i) {
    this.props.dispatch(actions.handleDeleteTag, i);
  }

  handleAddTag(tag) {
    this.props.dispatch(actions.handleAddTag, tag);
  }

  handlePrint(
    columns,
    numColumns = 5,
    orientation = "portrait",
    custom = false
  ) {
    var checklist = transformUtils.transformFromChecklistEntities(
      this.context.tree.get(["tree", "root"])
    );

    if (
      this.props.subscriptionPlan.startsWith("no-plan") ||
      this.props.subscriptionPlan.startsWith("basic-plan") ||
      this.props.subscriptionPlan.startsWith("standard-plan")
    ) {
      alert("Sorry. You need to be a Pro Plan subscriber to print.");
    } else {
      awsS3.printFromMemory(
        checklist,
        columns,
        numColumns,
        orientation,
        custom
      );
    }
  }

  getContentModel() {
    return transformUtils.transformFromChecklistEntities(
      this.context.tree.get(["tree", "root"]),
      true
    );
  }

  /*
    componentDidMount() {
      var inputLabel = ReactDOM.findDOMNode(this.refs.inputLabel);
      if (inputLabel.value === "(Checklist Name)") {
        inputLabel.focus();
        inputLabel.setSelectionRange(0, inputLabel.value.length);
      }
    }
  
    handleOnFocus(e) {
      var inputLabel = ReactDOM.findDOMNode(this.refs.inputLabel);
      if (inputLabel.value === "(Checklist Name)") {
        inputLabel.setSelectionRange(0, inputLabel.value.length);
      }
    }	
  */
  componentDidMount() {
    // if (window.innerWidth < 768) {
    // } else {
    var inputLabel = ReactDOM.findDOMNode(this.refs.inputLabel);
    if (
      inputLabel &&
      (inputLabel.value === "" || inputLabel.value === "(Procedure Name)")
    ) {
      inputLabel.focus();
      //inputLabel.setSelectionRange(0, inputLabel.value.length);
    }
    // }
  }
  componentDidUpdate(prevProps, prevState) {
    // if (window.innerWidth < 768) {
    // } else {
    // if (prevProps.selectedNodePath !== this.props.selectedNodePath) {
    var inputLabel = ReactDOM.findDOMNode(this.refs.inputLabel);
    if (
      inputLabel &&
      (inputLabel.value === "" || inputLabel.value === "(Procedure Name)")
    ) {
      inputLabel.focus();
      //inputLabel.setSelectionRange(0, inputLabel.value.length);
    }
    // }
    // }
  }

  async pasteTemplate(templateId) {
    if (this.props.node.entity.printTemplate.trim() !== "") {
      window.confirm(
        "This will overwrite the current template in the Custom Template editor. Are you sure you want to do this?",
        async () => {
          this.props.dispatch(
            actions.handleChangeProperty,
            "printTemplate",
            await awsS3.getResolvedTemplate(templateId)
          );
        }
      );
    } else {
      this.props.dispatch(
        actions.handleChangeProperty,
        "printTemplate",
        await awsS3.getResolvedTemplate(templateId)
      );
    }
  }

  handleOnFocus(e) {
    var inputLabel = ReactDOM.findDOMNode(this.refs.inputLabel);
    if (inputLabel.value === "(Procedure Name)") {
      inputLabel.setSelectionRange(0, inputLabel.value.length);
    }
  }

  render() {
    var self = this;

    var entity = this.props.node.entity;
    const { isLoading, uploadedImageUrl } = this.state;

    var inlineEditor;
    inlineEditor = (
      <div
        style={{
          width: "100%",
          marginLeft: "5px",
          marginRight: "5px",
          backgroundColor: "white",
          color: "black",
          padding: "3px",
        }}
      >
        <input
          ref="inputLabel"
          style={{ marginBottom: "15px" }}
          className="form-control"
          type="text"
          placeholder="(Procedure Name)"
          value={entity.label}
          onChange={this.handleChangeLabel}
          onClick={this.handleOnFocus.bind(this)}
        />
        <Button
          onClick={(e) => {
            this.immediateInlineClick = false;
            this.setState({ inlineEdit: false });
          }}
        >
          <Glyphicon
            style={{ fontSize: "16px", color: "green" }}
            glyph="check"
          />{" "}
          OK
        </Button>
      </div>
    );

    var icon = "question";
    for (var i = 0; i < this.props.genres.length; i++) {
      var genre = this.props.genres[i];
      if (genre.value == this.props.node.entity.genre) {
        icon = genre.icon;
      }
    }

    const close = (
      <span>
        <Glyphicon style={{ color: "green", fontSize: "20px" }} glyph="check" />{" "}
        OK
      </span>
    );
    const edit = (
      <span>
        <Glyphicon style={{ fontSize: "20px" }} glyph="pencil" />
      </span>
    );

    var header = (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          onClick={() => {
            if (
              !this.immediateInlineClick &&
              this.props.node.selected &&
              this.props.provided &&
              !this.state.inlineEdit
            )
              actions.handleToggleNode(
                this.context.tree.select(this.props.selectedNodePath)
              );
          }}
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            marginTop: "5px",
            userSelect: "none",
            WebkitUserSelect: "none",
            MozUserSelect: "none",
            msUserSelect: "none",
          }}
        >
          <Glyphicon style={{ fontSize: "32px" }} glyph={icon} />
          {!this.state.inlineEdit && (
            <div
              style={{ marginLeft: "5px", marginRight: "5px" }}
              onClick={(e) => {
                  /*e.stopPropagation();*/ this.immediateInlineClick = true;
                this.setState({ inlineEdit: true });
              }}
            >
              {entity.label === "" ? "(Procedure Name)" : entity.label}
            </div>
          )}
          {this.state.inlineEdit && inlineEditor}
        </div>
        {this.props.provided && !this.state.inlineEdit && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: 10 }}>
              <Button
                style={{
                    /*backgroundColor: "rgba(255,255,255,0.3)", */ color:
                    "#fdfdfd",
                }}
                onClick={() => this.setState({ open: !this.state.open })}
              >
                {this.state.open ? close : edit}
              </Button>
            </div>
          </div>
        )}
      </div>
    );

    var genres = this.props.genres.map(function (genre, i) {
      const validGenreValues = [
        "aviation",
        "drone",
        "learning",
        "maintenance",
        "people",
        "school",
        "travel",
        "work",
        "helicopter",
        "warbird",
        "vintage",
      ];
      if (!validGenreValues.includes(genre.value)) {
        return null;
      }
      return (
        <option key={"genre" + i} value={genre.value}>
          {genre.name}
        </option>
      );
    });
    var tags = entity.tags.map(function (tag, i) {
      return {
        id: i + 1,
        text: tag,
      };
    });

    var labelAlexaPlain, labelAlexaRich, labelSsml;
    if (
      this.props.urlParams.hasOwnProperty("mode") &&
      this.props.urlParams.mode === "smartdisplay"
    ) {
      labelAlexaPlain = (
        <div>
          <div>
            <label>Label Alexa Override (Plain Text)</label>
          </div>
          <div>
            <textarea
              style={{ marginBottom: "15px" }}
              className="form-control"
              placeholder="(Optional: plain content to display on Alexa)"
              value={entity.labelAlexaPlain}
              onChange={this.handleChangeLabelAlexaPlain}
            />
          </div>
        </div>
      );

      labelAlexaRich = (
        <div>
          <div>
            <label>Label Alexa Override (Rich Text)</label>
          </div>
          <div>
            <textarea
              style={{ marginBottom: "15px" }}
              className="form-control"
              placeholder="(Optional: rich content to display on Alexa)"
              value={entity.labelAlexaRich}
              onChange={this.handleChangeLabelAlexaRich}
            />
          </div>
        </div>
      );

      labelSsml = (
        <div>
          <div>
            <label>Label SSML Override</label>
          </div>
          <div>
            <textarea
              style={{ marginBottom: "15px" }}
              className="form-control"
              placeholder="(Optional: content to have Alexa speak)"
              value={entity.labelSsml}
              onChange={this.handleChangeLabelSsml}
            />
          </div>
        </div>
      );
    }

    var advancedHeader = (
      <div style={{ display: "flex", alignItems: "" }}>
        <div>Advanced</div>
        {/* <div style={{float: "right"}}>
            <Button bsSize="small" onClick={ ()=> this.setState({ advancedOpen: !this.state.advancedOpen })}>{this.state.advancedOpen?close:edit}</Button>
          </div>
          <div style={{float: "none", clear: "both"}} /> */}
      </div>
    );

    var showMode;
    if (this.props.appCapabilities.showMode) {
      showMode = (
        <div style={{ marginTop: "25px", marginBottom: "0px" }}>
          <Checkbox
            checked={
              entity.startInShowMode == null || entity.startInShowMode
                ? "checked"
                : ""
            }
            onChange={this.handleChangeStartInShowMode}
          >
            Start In Show Mode
          </Checkbox>
        </div>
      );
    }

    var templates = this.props.printTemplates;

    var pasteTemplateMenuItems = [];
    for (var i = 0; i < templates.length; i++) {
      var template = Object.assign({}, templates[i]);
      var templateName = template.name;
      var templateId = template["_id"];
      pasteTemplateMenuItems.push(
        <MenuItem
          id={template["_id"]}
          key={"menuitem-" + template["_id"]}
          onClick={(e) => {
            e.stopPropagation();
            self.pasteTemplate(e.currentTarget.id);
          }}
        >
          {templateName}
        </MenuItem>
      );
    }

    var pasteTemplateMenuComponent = (
      <Dropdown
        style={{ marginBotttom: "5px" }}
        pullLeft
        id="dropdown-card-paste-template"
      >
        <Dropdown.Toggle
          noCaret
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          Paste From Template
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ opacity: 0.95 }}>
          {pasteTemplateMenuItems}
        </Dropdown.Menu>
      </Dropdown>
    );

    return (
      <div style={{ width: "100%", height: "100%" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <MediaQuery query="(max-width: 767px)">
            {!this.state.inlineEdit && !this.state.open && (
              <div
                style={{ flex: 0, marginLeft: "5px", marginRight: "10px" }}
                onClick={() =>
                  actions.handleToggleNode(
                    this.context.tree.select(pathToArray(this.props.path))
                  )
                }
              >
                <Glyphicon
                  style={{
                    color: this.props.node.selected ? "white" : "#D78770",
                  }}
                  glyph={this.props.node.expanded ? "minus" : "plus"}
                />
              </div>
            )}
          </MediaQuery>
          <Panel
            style={{ width: "100%", flex: 1, marginBottom: "0px" }}
            defaultExpanded={this.state.open}
            expanded={this.state.open}
            onToggle={(e) => {
              this.setState({ open: !this.state.open });
            }}
          >
            <Panel.Heading
              style={{
                borderBottomLeftRadius: "15px",
                borderBottomRightRadius: "15px",
                padding: "5px 10px",
              }}
            >
              <Panel.Title>{header}</Panel.Title>
            </Panel.Heading>
            <MediaQuery query="(min-width: 767px)">

              <div
                style={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  paddingTop: "20px",
                  flexDirection: "row",
                }}
              >
                <Panel defaultExpanded={false} bsStyle="primary">
                  <Panel.Heading
                    style={{
                      borderRadius: "10px",
                      border: "1px solid #fcb31f",
                      backgroundColor: "#1F272B",
                      color: "white",
                    }}
                  >
                    <Panel.Title toggle>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {entity.image != "" ? (
                          <img
                            src={entity.image}
                            alt="Uploaded"
                            style={{
                              width: "56px",
                              height: "56px",
                              border: "1px solid #a0a0a0",
                              borderTopLeftRadius: "4px",
                              borderTopRightRadius: "4px",
                              borderBottomRightRadius: "4px",
                              borderBottomLeftRadius: "4px",
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              width: "56px",
                              height: "56px",
                              border: "1px solid #a0a0a0",
                              borderTopLeftRadius: "4px",
                              borderTopRightRadius: "4px",
                              borderBottomRightRadius: "4px",
                              borderBottomLeftRadius: "4px",
                              justifyContent: "center", // Center horizontally
                              alignItems: "center", // Center vertically
                            }}
                          >
                            <div
                              style={{ paddingLeft: "11px", paddingTop: "16px" }}
                            >
                              <FontAwesome
                                fixedWidth={true}
                                style={{ fontSize: "24px" }}
                                name={icon}
                              />
                            </div>
                          </div>
                        )}

                        <div style={{ paddingLeft: "20px", fontFamily: "syne" }}>
                          IMAGE UPLOAD
                        </div>
                        <div style={{ paddingLeft: "20px" }}>

                          Checklist Title Picture - early beta

                        </div>
                      </div>
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Collapse>
                    <Panel.Body>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ fontFamily: "syne", fontSize: "16px" }}>
                          UPLOAD A TITLE PICTURE FOR YOUR CHECKLIST
                        </div>
                      </div>
                      <div style={{ paddingTop: "8px" }}>
                        <p style={{ fontWeight: "bold", color: "lightblue" }}>
                          Please Note:
                        </p>
                        <p style={{ color: "lightblue" }}>
                          Title images are not yet shown on the mobile app but
                          coming soon. We expect to support title images within
                          the app at iOS version V6.3+.
                        </p>
                      </div>
                      <div>
                        <p style={{ paddingTop: "8px", fontWeight: "bold" }}>
                          About the title images:
                        </p>
                        <p>
                          We reccomend an image with a 1:1 aspect ratio in a jpg format.
                          Other aspect ratios are going to be centered
                          and cropped. Higher resolutions are currently accepted
                          but scaled down to 800x800 for performance
                          optimization.
                        </p>
                        <p>
                          This is a brand new feature. In case you do run into
                          troubles, please contact our support at
                          support@aerosys.io.
                        </p>
                      </div>
                      <div
                        style={{
                          paddingTop: "16px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {this.state.isLoading && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100vh",
                            }}
                          >
                            <ClipLoader
                              size={50}
                              color={"#123abc"}
                              loading={this.state.isLoading}
                            />
                          </div>
                        )}
                        <Button onClick={() => this.fileInputRef.current.click()}>
                          <Glyphicon
                            style={{
                              fontSize: "16px",
                              paddingRight: "8px",
                              color: "#fcb31f",
                            }}
                            glyph="image"
                          />
                          Upload New Picture
                        </Button>
                        <Button
                          onClick={() => {
                            this.props.dispatch(
                              actions.handleChangeProperty,
                              "image",
                              ""
                            );
                          }}
                        >
                          <Glyphicon
                            style={{
                              fontSize: "16px",
                              paddingRight: "8px",
                              color: "#a0a0a0",
                            }}
                            glyph="trash"
                          />
                          Remove Current Picture
                        </Button>
                      </div>
                      <input
                        type="file"
                        ref={this.fileInputRef}
                        style={{ display: "none" }}
                        onChange={this.handleFileChange}
                      />
                    </Panel.Body>
                  </Panel.Collapse>
                </Panel>
              </div>
            </MediaQuery>
            <Panel.Collapse>
              <Panel.Body>
                <div>
                  <AfHelp description={this.props.content.title.description}>
                    <label>{this.props.content.title.name}</label>
                  </AfHelp>
                </div>
                <div>
                  <input
                    ref="inputLabel"
                    className="form-control"
                    type="text"
                    placeholder="(Procedure Name)"
                    value={entity.label}
                    onChange={this.handleChangeLabel}
                  />
                </div>
                <div style={{ marginBottom: "15px" }}>
                  <AfHelp
                    alignItems="center"
                    description={
                      this.props.content.suppressAudioTitle.description
                    }
                  >
                    <Checkbox
                      checked={
                        entity.suppressAudioTitle === null ||
                          entity.suppressAudioTitle
                          ? "checked"
                          : ""
                      }
                      onChange={(e) => {
                        this.props.dispatch(
                          actions.handleChangeProperty,
                          "suppressAudioTitle",
                          e.target.checked
                        );
                      }}
                    >
                      {this.props.content.suppressAudioTitle.name}
                    </Checkbox>
                  </AfHelp>
                </div>
                <AfPanel
                  defaultExpanded={
                    entity.labelAudio && entity.labelAudio !== ""
                  }
                  title="Title Advanced"
                >
                  {labelAlexaPlain}
                  {labelAlexaRich}
                  <AfHelp
                    description={
                      this.props.content.titleNarrationOverride.description
                    }
                  >
                    <label>
                      {this.props.content.titleNarrationOverride.name}
                    </label>
                  </AfHelp>
                  <div style={{ marginBottom: "15px" }}>
                    <textarea
                      style={{ marginBottom: "5px" }}
                      className="form-control"
                      placeholder="(Optional: content to be spoken)"
                      value={entity.labelAudio}
                      onChange={this.handleChangeLabelAudio}
                    />
                    <Button
                      bsSize="small"
                      onClick={() => {
                        this.props.dispatch(
                          actions.handleCopyPasteProperty,
                          "label",
                          "labelAudio"
                        );
                      }}
                    >
                      <Glyphicon glyph="paste" /> Paste Title
                    </Button>
                  </div>
                  {labelSsml}
                  <AfHelp
                    description={
                      this.props.content.titlePrintOverride.description
                    }
                  >
                    <label>{this.props.content.titlePrintOverride.name}</label>
                  </AfHelp>
                  <div>
                    <textarea
                      style={{ marginBottom: "5px" }}
                      className="form-control"
                      placeholder="(Optional: content to be printed)"
                      value={entity.labelPrint}
                      onChange={this.handleChangeLabelPrint}
                    />
                    <Button
                      bsSize="small"
                      onClick={() => {
                        this.props.dispatch(
                          actions.handleCopyPasteProperty,
                          "label",
                          "labelPrint"
                        );
                      }}
                    >
                      <Glyphicon glyph="paste" /> Paste Title
                    </Button>
                  </div>
                </AfPanel>
                <div>
                  <AfHelp description={this.props.content.genre.description}>
                    <label>{this.props.content.genre.name}</label>
                  </AfHelp>
                </div>
                <div>
                  <FormControl
                    componentClass="select"
                    value={entity.genre === "" ? "aviation" : entity.genre}
                    onChange={this.handleChangeGenre}
                  >
                    {genres}
                  </FormControl>
                </div>
                <div>
                  <AfHelp description={this.props.content.id.description}>
                    <label>{this.props.content.id.name}</label>
                  </AfHelp>
                </div>
                <div>
                  <input
                    ref="inputId"
                    style={{ marginBottom: "15px" }}
                    className="form-control"
                    type="text"
                    placeholder="(Optional: ID)"
                    value={entity.checklistId}
                    onChange={this.handleChangeId}
                  />
                </div>

                <div>
                  <AfHelp
                    description={this.props.content.description.description}
                  >
                    <label>{this.props.content.description.name}</label>
                  </AfHelp>
                </div>
                <div>
                  <input
                    style={{ marginBottom: "15px" }}
                    className="form-control"
                    type="text"
                    placeholder="(Optional: checklist description)"
                    value={entity.description}
                    onChange={this.handleChangeDescription}
                  />
                </div>
                <MediaPanel node={this.props.node} />

                <div>
                  <AfHelp
                    alignItems="center"
                    description={
                      this.props.content.ignoreCompletion.description
                    }
                  >
                    <Checkbox
                      checked={
                        entity.ignoreCompletion == null ||
                          entity.ignoreCompletion
                          ? "checked"
                          : ""
                      }
                      onChange={this.handleChangeIgnoreCompletion}
                    >
                      {this.props.content.ignoreCompletion.name}
                    </Checkbox>
                  </AfHelp>
                </div>
                <div>
                  <AfHelp
                    alignItems="center"
                    description={this.props.content.hideStatus.description}
                  >
                    <Checkbox
                      checked={
                        entity.hideStatus == null || entity.hideStatus
                          ? "checked"
                          : ""
                      }
                      onChange={this.handleChangeHideStatus}
                    >
                      {this.props.content.hideStatus.name}
                    </Checkbox>
                  </AfHelp>
                </div>
                <div>
                  <AfHelp description={this.props.content.tags.description}>
                    <label>{this.props.content.tags.name}</label>
                  </AfHelp>
                </div>
                <div>
                  <ReactTags
                    tags={tags}
                    suggestions={this.props.suggestions}
                    handleDelete={this.handleDeleteTag}
                    handleAddition={this.handleAddTag}
                    autofocus={false}
                  />
                </div>
                {showMode}

                <Contributors
                  contributors={entity.contributors}
                  creatorContent={this.props.content.creator}
                  contributorsContent={this.props.content.contributors}
                />

                <AfPanel
                  style={{ marginTop: "15px", marginBottom: "0px" }}
                  bsStyle="info"
                  title="CoPilot Phases"
                >
                  <Panel className="info-panel">
                    <div>
                      <p>
                        You can configure Goose to monitor all the phases of
                        flight and have her be proactive as you transition into
                        a new phase. For example, when you start to descend,
                        enter cruise or are about to land. Just make sure the
                        phase of flight is checked that you want her to monitor.
                      </p>
                      <p>
                        You select the ID of the section (e.g. taxi, run-up,
                        takeoff) you want to link to. If you already have IDs
                        setup on your sections you want to link to, you can
                        select it from the dropdown. If you don't have IDs
                        setup, make sure to add IDs on the appropriate section
                        in your outline in the Goose Cloud Editor. Sections are
                        the yellow icons in the outline view. You can get more
                        info on linking below.
                      </p>
                      <p>
                        When you link to a section, you have the option of going
                        through the items one-by-one or having Mira reading all
                        items in the section back to you with no interaction
                        needed. Just check Read Section if you want her to read
                        the entire section.
                      </p>
                      <p>
                        You also have the option for Mira to prompt you for
                        confirmation before jumping to the new checklist items.
                      </p>
                    </div>
                  </Panel>

                  <CoPilotPhases node={this.props.node} />
                </AfPanel>

                <AfPanel
                  style={{ marginTop: "15px", marginBottom: "0px" }}
                  bsStyle="info"
                  title="AI Assistant (ALPHA FEATURE)"
                >
                  <Panel className="info-panel">
                    <div>
                      <p>
                        You can enable an AI assistant to help you create your
                        checklist.
                      </p>
                    </div>
                  </Panel>
                  <div>
                    <AfHelp
                      alignItems="center"
                      description={this.props.content.aiAssistant.description}
                    >
                      <Checkbox
                        checked={
                          entity.aiAssistant == null || entity.aiAssistant
                            ? "checked"
                            : ""
                        }
                        onChange={this.handleChangeAiAssistant}
                      >
                        {this.props.content.aiAssistant.name}
                      </Checkbox>
                    </AfHelp>
                  </div>

                  {entity.aiAssistant && (
                    <AiAssistant
                      files={this.props.node.entity.aiAssistantFiles}
                      checklistFiles={
                        this.props.node.entity.aiAssistantChecklistFiles
                      }
                    />
                  )}
                </AfPanel>

                <AfPanel
                  style={{ marginTop: "15px", marginBottom: "0px" }}
                  bsStyle="success"
                  title="Advanced"
                >
                  <div>
                    <AfHelp description={this.props.content.id.description}>
                      <label>{this.props.content.id.name}</label>
                    </AfHelp>
                  </div>
                  <div>
                    <input
                      ref="inputId"
                      style={{ marginBottom: "15px" }}
                      className="form-control"
                      type="text"
                      placeholder="(Optional: ID)"
                      value={entity.checklistId}
                      onChange={this.handleChangeId}
                    />
                  </div>
                  <AfPanel
                    defaultExpanded={false}
                    onToggle={(e) => {
                      setTimeout(() => {
                        this.instance1.refresh();
                        // this.instance2.refresh();
                        // this.instance3.refresh();
                      }, 0);
                    }}
                    title="Print Properties"
                  >
                    <Flexbox
                      style={{ marginBottom: "15px" }}
                      flexDirection="row"
                      flexWrap="wrap"
                    >
                      <Button
                        style={{ marginBotttom: "5px" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          self.handlePrint(false, 0, "portrait", true);
                        }}
                      >
                        Preview Portrait
                      </Button>
                      <div style={{ width: "10px" }} />
                      <Button
                        style={{ marginBotttom: "5px" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          self.handlePrint(false, 0, "landscape", true);
                        }}
                      >
                        Preview Landscape
                      </Button>
                      <div style={{ width: "10px" }} />
                      {pasteTemplateMenuComponent}
                    </Flexbox>
                    <div>
                      <AfHelp
                        description={
                          this.props.content.customTemplate.description
                        }
                      >
                        <label>{this.props.content.customTemplate.name}</label>
                      </AfHelp>
                    </div>
                    <div>
                      <CodeMirror
                        value={entity.printTemplate}
                        options={{
                          mode: "",
                          lineNumbers: true,
                        }}
                        editorDidMount={(editor) => {
                          this.instance1 = editor;
                        }}
                        onBeforeChange={(editor, data, value) => {
                          this.props.dispatch(
                            actions.handleChangeProperty,
                            "printTemplate",
                            value
                          );
                        }}
                      />
                    </div>
                    {/* FROM HERE */}
                    {/* <div style={{ marginTop: "15px" }}>
                        <AfHelp
                          description={
                            this.props.content.contentModel.description
                          }
                        >
                          <label>{this.props.content.contentModel.name}</label>
                        </AfHelp>
                      </div>
                      <div>
                        <CodeMirror
                          value={this.state.contentModel}
                          options={{
                            mode: "javascript",
                            lineNumbers: true,
                            foldGutter: true,
                            gutters: [
                              "CodeMirror-linenumbers",
                              "CodeMirror-foldgutter",
                            ],
                          }}
                          editorDidMount={(editor) => {
                            this.instance3 = editor;
                          }}
                        />
                      </div>
                      <div style={{ marginTop: "15px" }}>
                        <AfHelp
                          description={
                            this.props.content.customProperties.description
                          }
                        >
                          <label>
                            {this.props.content.customProperties.name}
                          </label>
                        </AfHelp>
                      </div>
                      <div>
                        <CodeMirror
                          value={entity.printCustomProperties}
                          options={{
                            mode: "javascript",
                            lineNumbers: true,
                          }}
                          editorDidMount={(editor) => {
                            this.instance2 = editor;
                          }}
                          onBeforeChange={(editor, data, value) => {
                            lastEdit = value;
                            this.props.dispatch(
                              actions.handleChangeProperty,
                              "printCustomProperties",
                              value
                            );
                          }}
                          onBlur={() => {
                            try {
                              if (!_.isEmpty(lastEdit)) {
                                JSON.parse(lastEdit);
                              }
                            } catch (e) {
                              alert(
                                e.message +
                                  "\n\nYou need to fix the Custom Properties JSON or you won't be able to save the checklist."
                              );
                            }
                          }}
                        />
                      </div> */}
                    {/* TO HERE */}
                  </AfPanel>
                  <AfPanel
                    defaultExpanded={false}
                    onToggle={(e) => {
                      setTimeout(() => {
                        this.instance4.refresh();
                      }, 0);
                    }}
                    title="Functions"
                  >
                    <div>
                      <AfHelp
                        description={this.props.content.functions.description}
                      >
                        <label>{this.props.content.functions.name}</label>
                      </AfHelp>
                    </div>
                    <div style={{ height: "300px", overflow: "auto" }}>
                      <CodeMirror
                        value={entity.functions}
                        options={{
                          mode: "javascript",
                          lineNumbers: true,
                        }}
                        onBeforeChange={(editor, data, value) => {
                          this.props.dispatch(
                            actions.handleChangeProperty,
                            "functions",
                            value
                          );
                        }}
                        editorDidMount={(editor) => {
                          this.instance4 = editor;
                        }}
                      />
                    </div>
                  </AfPanel>

                  <div>
                    <AfHelp
                      alignItems="center"
                      description={
                        this.props.content.singleLineCheckItems.description
                      }
                    >
                      <Checkbox
                        checked={
                          entity.itemsOnSingleLine == null ||
                            entity.itemsOnSingleLine
                            ? "checked"
                            : ""
                        }
                        onChange={this.handleChangeItemsOnSingleLine}
                      >
                        {this.props.content.singleLineCheckItems.name}
                      </Checkbox>
                    </AfHelp>
                  </div>
                  <div>
                    <div>
                      <AfHelp
                        alignItems="center"
                        description={
                          this.props.content.suppressAudio.description
                        }
                      >
                        <Checkbox
                          checked={
                            entity.suppressAudio == null || entity.suppressAudio
                              ? "checked"
                              : ""
                          }
                          onChange={this.handleChangeSuppressAudio}
                        >
                          {this.props.content.suppressAudio.name}
                        </Checkbox>
                      </AfHelp>
                    </div>
                    <AfHelp
                      alignItems="center"
                      description={this.props.content.trackLocation.description}
                    >
                      <Checkbox
                        checked={
                          entity.trackLocation == null || entity.trackLocation
                            ? "checked"
                            : ""
                        }
                        onChange={this.handleChangeTrackLocation}
                      >
                        {this.props.content.trackLocation.name}
                      </Checkbox>
                    </AfHelp>
                  </div>
                </AfPanel>
              </Panel.Body>
            </Panel.Collapse>
          </Panel>
        </div>
      </div>
    );
  }
}

EditorCategory.contextTypes = {
  tree: PropTypes.baobab,
};

export default branch(
  {
    user: ["user"],
    selectedNodePath: ["selectedNodePath"],
    genres: ["genres"],
    suggestions: ["tagSuggestions"],
    urlParams: ["appState", "urlParams"],
    appCapabilities: ["appCapabilities"],
    subscriptionPlan: ["user", "subscriptionPlan"],
    printTemplates: ["printTemplates"],
    content: ["content", "activity", "properties", "properties"],
  },
  EditorCategory
);
