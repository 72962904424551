import React, { PureComponent } from "react";
import ReactDOM from 'react-dom';

import { branch } from "baobab-react/higher-order";

import marked from "marked";

import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";

import { EditorState, /*ContentState, */convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

// Prior to markdown change
// import { mdToDraftjs, draftjsToMd } from "draftjs-md-converter";

import { stateToMarkdown } from "draft-js-export-markdown";
import { stateFromMarkdown } from 'draft-js-import-markdown';

// AmbiFi uses these imports, may be better if see other issues
// Reference AmbiFi implemntation
// import { draftToMarkdown } from "markdown-draft-js";
// import { markdownToDraft } from "markdown-draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class RichTextEditor extends PureComponent {
	constructor(props) {
		super(props);

		const defaultValue = this.props.value;
		const contentState = stateFromMarkdown(defaultValue);
		const newEditorState = EditorState.createWithContent(contentState);

		this.state = {
			editorState: newEditorState
		};

		this.lastMode = props.advanced;
	}
	// Prior to markdown change
	// constructor(props) {
	// 	super(props);

	// 	const defaultValue = this.props.value;
	// 	const rawData = mdToDraftjs(defaultValue);
	// 	const contentState = convertFromRaw(rawData);
	// 	const newEditorState = EditorState.createWithContent(contentState);

	// 	this.state = {
	// 		editorState: newEditorState
	// 	};

	// 	this.lastMode = props.advanced;
	// }

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.advanced !== this.lastMode) {
			this.lastMode = nextProps.advanced;

			const defaultValue = nextProps.value;
			const contentState = stateFromMarkdown(defaultValue);
			const newEditorState = EditorState.createWithContent(contentState);

			this.setState({
				editorState: newEditorState
			});
		}
	}

	// Prior to markdown change
	// UNSAFE_componentWillReceiveProps(nextProps) {
	// 	if (nextProps.advanced !== this.lastMode) {
	// 		this.lastMode = nextProps.advanced;

	// 		const defaultValue = nextProps.value;
	// 		const rawData = mdToDraftjs(defaultValue);
	// 		const contentState = convertFromRaw(rawData);
	// 		const newEditorState = EditorState.createWithContent(contentState);

	// 		this.setState({
	// 			editorState: newEditorState
	// 		});
	// 	}
	// }

	createMarkup(label) {
		return { __html: this.getMarked(label) };
	}

	getMarked(label) {
		let myRenderer = new marked.Renderer();
		myRenderer.link = function (href, title, text) {
			let external, newWindow, out;
			external = /^https?:\/\/.+$/.test(href);
			newWindow = external || title === "newWindow";
			out = "<a href=\"" + href + "\"";
			if (newWindow) {
				out += " target=\"_blank\"";
			}
			if (title && title !== "newWindow") {
				out += " title=\"" + title + "\"";
			}
			return out += ">" + text + "</a>";
		};

		myRenderer.image = function (href, title, text) {
			return "<img style=\"max-width: 100%\" src=\"" + href + "\" /><br/><div>" + text + "</div>";
		};

		marked.setOptions({
			renderer: myRenderer,
			gfm: true,
			tables: true,
			breaks: true,
			pedantic: false,
			sanitize: false,
			smartLists: true,
			smartypants: false
		});

		return marked(label);
	}

	getMarkdown(newState) {
		const content = newState.getCurrentContent();

		const markdown = stateToMarkdown(
			content
		)
	
		return markdown;
    }

	// Prior to markdown change
	// getMarkdown(newState) {
	// 	const content = newState.getCurrentContent();

	// 	const myMdDict = {
	// 		BOLD: "**",
	// 		STRIKETHROUGH: "~~"
	// 	};

	// 	return draftjsToMd(convertToRaw(content), myMdDict);
    // }
    
    componentDidMount() {
        if (this.props.hasOwnProperty("autoFocus") && this.props.autoFocus) {
            if (!this.props.advanced && this.editorReference.editor.textContent === "") {
                this.editorReference.focus();
            // } else if (this.props.advanced && this.editorReference.value === "") {
			// 	this.editorReference.focus();			
			} else if (this.props.advanced) {
				if (this.mdEditor.nodeMdText.current !== null && this.mdEditor.nodeMdText.current.textContent === "") {
					this.mdEditor.nodeMdText.current.focus();
				}            }
        }
    }

	renderComponent() {
		const { editorState } = this.state;
		if (!this.props.advanced) {
			return (
				<div>
					<Editor
                        editorRef={(ref) => { this.editorReference = ref }}
						toolbar={{
							options: ["inline", "blockType", "list", "link", "emoji", "image", "remove", "history"], inline: {
								options: ["bold", "italic"]
							},
							blockType: {
								inDropdown: true,
								options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6", "Blockquote"]
							}
						}}
						editorState={editorState}
						onEditorStateChange={(editorState) => {
							this.props.onChange({ html: "", text: this.getMarkdown(editorState) });
							this.setState({
								editorState
							});
						}}
					/>
				</div>
			);

		// } else if (this.props.advanced) {
		// 	let markdownPreviewLabel;
		// 	let markupLabel = this.createMarkup(this.props.value);
		// 	if (this.props.value !== markupLabel.__html) {
		// 		markdownPreviewLabel =
		// 			<div style={{ marginBottom: 10 }}>
		// 				<div>
		// 					<label>Preview</label>
		// 				</div>
		// 				<div dangerouslySetInnerHTML={markupLabel} />
		// 			</div>;
		// 	}

		// 	return (
		// 		<div>
		// 			{/* <div>
		// 				<label>Advanced Markdown Editor</label>
		// 			</div> */}
		// 			<textarea 
		// 				ref={(ref) => { this.editorReference = ref }}
		// 				style={{ marginBottom: "15px" }} 
		// 				className="form-control" 
		// 				placeholder={this.props.placeholder} 
		// 				value={this.props.value} 
		// 				onChange={(e)=>{this.props.onChange({ html: "", text: e.target.value})}}
		// 				/>
		// 			{markdownPreviewLabel}						
		// 		</div>		
		// 	);

		} else {
			return (
				<div>
					<div>
						<label>Advanced Markdown Editor</label>
					</div>

					<MdEditor
                        ref={node => (this.mdEditor = node || undefined)}
                        // ref={(ref) => { this.editorReference = ref }}
						value={this.props.value}
						renderHTML={(text) => this.getMarked(text)}
                        onChange={this.props.onChange}
						plugins={[
							'header',
							'font-bold',
							'font-italic',
							// 'font-underline',
							'font-strikethrough',
							'list-unordered',
							'list-ordered',
							'block-quote',
							'block-wrap',
							'block-code-inline',
							'block-code-block',
							'table',
							'image',
							'link',
							'clear',
							'logger',
							'mode-toggle',
							'full-screen',
							'auto-resize',
							'tab-insert'
						]}
					/>
				</div >);
		}
	}

	render() {
		let component = this.renderComponent();

		return component;
	}
}

export default branch({
}, RichTextEditor);