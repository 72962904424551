import React from 'react';

import {Panel, Button} from 'react-bootstrap';

import Glyphicon from '../../components/app-icon';

class AfPanel extends React.PureComponent {

    constructor(props, context) {
		super(props, context);

		this.state = {
            open: props.defaultExpanded
		}
    }

    render() {
		const close = <span><Glyphicon style={{ fontSize: "16px" }} glyph='chevron-up' /></span>;
		const edit = <span><Glyphicon style={{ fontSize: "16px" }} glyph='chevron-down' /></span>;

        return (
            <Panel style={this.props.style} bsStyle={this.props.bsStyle} defaultExpanded={this.props.defaultExpanded} onToggle={this.props.onToggle ? this.props.onToggle : (e)=>{this.setState({open: !this.state.open})}}>
                <Panel.Heading>
                    <Panel.Title toggle>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                            <div>{this.props.title}</div>
                            <div>
                                <Button bsSize="small">
                                    {this.state.open?close:edit}
                                </Button>
                            </div>
                        </div>
                    </Panel.Title>
                </Panel.Heading>

                <Panel.Collapse>
                    <Panel.Body>
                        {this.props.children}
                    </Panel.Body>	
                </Panel.Collapse>	
            </Panel>
        );  
    }
}

export default AfPanel;