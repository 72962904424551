import Baobab from 'baobab';

import contentEN_US from "../content/en-US/content.js";

const state = new Baobab({
	lang: "en-US",
	version: {
		copyright: "© 2024 AeroSys Technologies Inc. - All Rights Reserved",
		prefix: "Version",
		major: "7",
		minor: "1",
		revision: "2",
		build: "663",
		suffix: ""
	},
	appCapabilities: {
		igapp: false,
		views: {
			myChecklists: {
				filterVisible: true,
				filterGenresVisible: true
			}
		},
		showMode: false,
		mediaUploadPhotosOnly: true,
		barcode: false,
		timers: true
	},
	device: null,
	devices: [],
	documents: [],
	checklistHistory: null,
	tree: {},
	selectedNodePath: [],
	typeahead: [],
	clipboard: {},
	clipboardMode: "",
	history: [],
	historyIndex: -1,
	genres: [ // We got rid of most of these. Yet I let them in to no risk causing issues with existing checklists having an "old" genre
		{
			value: "auto", // old
			name: "Automobile",
			icon: "car",
			iconPng: "https://i.imgur.com/qyUEp4f.png"
		},
		{
			value: "aviation",
			name: "Aircraft",
			icon: "plane",
			iconPng: "https://i.imgur.com/WHjyNob.png"
		},
		{
			value: "drone",
			name: "Military Aircraft",
			icon: "fighter-jet",
			iconPng: "https://i.imgur.com/WzXw8Po.png"
		},
		{
			value: "bike", // old
			name: "Bike",
			icon: "bicycle",
			iconPng: "https://i.imgur.com/0AMfTYN.png"
		},
		{
			value: "boat", // old
			name: "Boat",
			icon: "ship",
			iconPng: "https://i.imgur.com/KtcTbZj.png"
		},
		{
			value: "fun", // old
			name: "Fun",
			icon: "puzzle-piece",
			iconPng: "https://i.imgur.com/RIp6GG1.png"
		},
		{
			value: "food", // old
			name: "Food",
			icon: "spoon",
			iconPng: "https://i.imgur.com/FQd4TWZ.png"
		},
		{
			value: "games", // old
			name: "Games",
			icon: "gamepad",
			iconPng: "https://i.imgur.com/quGKE9k.png"
		},
		{
			value: "groceries", // old
			name: "Groceries",
			icon: "shopping-cart",
			iconPng: "https://i.imgur.com/nN6A66S.png"
		},
		{
			value: "health", // old
			name: "Health",
			icon: "ambulance",
			iconPng: "https://i.imgur.com/mMIK26a.png"
		},
		{
			value: "holidays", // old
			name: "Holidays",
			icon: "tree",
			iconPng: "https://i.imgur.com/3lp6LCd.png"
		},
		{
			value: "home", // old
			name: "Home",
			icon: "home",
			iconPng: "https://i.imgur.com/HPiip9M.png"
		},
		{
			value: "kids", // old
			name: "Kids",
			icon: "smile-o",
			iconPng: "https://i.imgur.com/VPstdR1.png"
		},
		{
			value: "legal", // old
			name: "Legal",
			icon: "gavel",
			iconPng: "https://i.imgur.com/DHyXMgi.png"
		},
		{
			value: "learning",
			name: "Handbook / Manual",
			icon: "book",
			iconPng: "https://i.imgur.com/itjRDLW.png"
		},
		{
			value: "maintenance",
			name: "Maintenance",
			icon: "wrench",
			iconPng: "https://i.imgur.com/FxgGsnk.png"
		},
		{
			value: "money", // old
			name: "Money",
			icon: "money",
			iconPng: "https://i.imgur.com/Tyz2Fpu.png"
		},
		{
			value: "movies", // old
			name: "Movies",
			icon: "ticket",
			iconPng: "https://i.imgur.com/7Kk82Wj.png"
		},
		{
			value: "music", // old
			name: "Music",
			icon: "music",
			iconPng: "https://i.imgur.com/KiDWWwM.png"
		},
		{
			value: "motorcycle", // old
			name: "Motorcycle",
			icon: "motorcycle",
			iconPng: "https://i.imgur.com/1bfmjEY.png"
		},
		{
			value: "people",
			name: "Formation & Group Procedures",
			icon: "users",
			iconPng: "https://i.imgur.com/VU2dTse.png"
		},
		{
			value: "pets", // old
			name: "Pets",
			icon: "paw",
			iconPng: "https://i.imgur.com/niAuSzv.png"
		},
		{
			value: "photo", // old
			name: "Photography",
			icon: "camera-retro",
			iconPng: "https://i.imgur.com/a4VTwWx.png"
		},
		{
			value: "restaurants", // old
			name: "Restaurants",
			icon: "cutlery",
			iconPng: "https://i.imgur.com/KWJl1lu.png"
		},
		{
			value: "school",
			name: "Instructing",
			icon: "graduation-cap",
			iconPng: "https://i.imgur.com/FE7UPUo.png"
		},
		{
			value: "science", // old
			name: "Science",
			icon: "flask",
			iconPng: "https://i.imgur.com/i4nnHbD.png"
		},
		{
			value: "shopping", // old
			name: "Shopping",
			icon: "shopping-bag",
			iconPng: "https://i.imgur.com/McFzyKa.png"
		},
		{
			value: "social", // old
			name: "Social",
			icon: "beer",
			iconPng: "https://i.imgur.com/ZO5Js7h.png"
		},
		{
			value: "sports", // old
			name: "Sports",
			icon: "soccer-ball-o",
			iconPng: "https://i.imgur.com/IQAAL43.png"
		},
		{
			value: "tv", // old
			name: "Television",
			icon: "tv",
			iconPng: "https://i.imgur.com/RVEgTL6.png"
		},
		{
			value: "travel",
			name: "Campaign & Fly-Ins",
			icon: "hotel",
			iconPng: "https://i.imgur.com/zaxDHNy.png"
		},
		{
			value: "video", // old
			name: "Video",
			icon: "video-camera",
			iconPng: "https://i.imgur.com/m3mRYo9.png"
		},
		{
			value: "work",
			name: "Monitoring & Performance",
			icon: "bar-chart",
			iconPng: "https://i.imgur.com/vBP2oLl.png"
		},
		{
			value: "misc", // old
			name: "Miscellaneous",
			icon: "question",
			iconPng: "https://i.imgur.com/lVSsmXj.png"
		},
		{
			value: "helicopter",
			name: "Helicopter",
			icon: "plane",
			iconPng: "https://i.imgur.com/WHjyNob.png"
		},
		{
			value: "warbird",
			name: "Warbird",
			icon: "fighter-jet",
			iconPng: "https://i.imgur.com/WzXw8Po.png"
		},
		{
			value: "vintage",
			name: "Vintage",
			icon: "plane",
			iconPng: "https://i.imgur.com/WHjyNob.png"
		},
	],
	tagSuggestions: [
		"aviation", "general aviation", "airplane", "aircraft"
	],
	groupNames: [
	],
	filterTags: [
	],
	user: {
		sub: "",
		name: "",
		username: "",
		email: "",
		password: "",
		code: "",
		identityId: "",
		loggedIn: false,
		historyUsers: [],
		entryPoint: ""
	},
	timerTemplates: [],
	appState: {
		aiAssistantResponse: "",
		timerModal: {
			show: false,
			timerTemplate: {}
		},
		checklistReceived: false,
		checklistReceivedMessage: "Congratulations. Goose learnt your new checklist inside & out - its ready for you to use.",
		
		lastIdentityId: "",
		lastChecklistId: "",
		showDialogPriceTiers: false,
		registrationStep: 1,
		loading: false,
		loadingSpinner: false,
		hideFreeBanner: false,
		alerts: [],
		confirm: {
			message: '',
			title: '',
			onConfirm: null
		},
		editor: {
			multiSelectMode: false
		},
		navPanel: {
			selected: "home"
		},
		previewChecklist: {
			checklist: {},
			showModal: false
		},
		showNotes: {
			checklist: {},
			showModal: false
		},
		showDetailedStatus: {
			checklist: {},
			showModal: false
		},
		showDetailedHistory: {
			checklist: {},
			showModal: false
		},
		lastLocation: null,
		filters: {
			showVisible: true,
			all: {
				genres: [],
				tags: []
			}
		}
	},
	search: {
		featured: [],
		checkmate: [],
		trending: [],
		recommended: []
	},
	checkMateImport: {
		credentials: {
			username: null,
			password: null
		},
		checklists: [],
		failedChecklists: []
	},
	recent: [],
	functions: "",
	printTemplates: [],
	content: contentEN_US,
	contentAll: {
		"en-US": contentEN_US
	}
},
	{
		immutable: false
	});

export default state;