import React from 'react';
import {FormControl} from 'react-bootstrap';

class TextInputAutoCapitalize extends React.PureComponent {
    render() {
        return (
            <FormControl componentClass="select" value={this.props.value} onChange={this.props.onChange}>
                <option value="none">None</option>
                <option value="sentences">Sentences</option>
                <option value="words">Words</option>
                <option value="characters">Characters</option>
            </FormControl>
        )    
    }
}

export default TextInputAutoCapitalize;