import React from 'react';
import {FormControl} from 'react-bootstrap';

class LinkActionType extends React.PureComponent {
    render() {
        var show = !this.props.user.subscriptionPlan.startsWith("basic-plan");

        return (
            <FormControl componentClass="select" value={this.props.value} onChange={this.props.onChange}>
                <option value="goto">Goto</option>
                {show && <option value="gotoUnchecked">Goto Unchecked</option>}
                {show && <option value="newSessionAndGoto">Goto (New Session)</option>}
                {show && <option value="resetRangeAndGoto">Goto (Reset Items In Range)</option>}
                {show && <option value="checkRangeAndGoto">Goto (Check Items In Range)</option>}
                <option value="gotoAndReturn">Goto and Return</option>
                {show && <option value="gotoUncheckedAndReturn">Goto Unchecked and Return</option>}
                {show && <option value="newSessionAndGotoAndReturn">Goto and Return (New Session)</option>}
                {show && <option value="gotoAndOpenBirdseyeView">Goto and Open Birdseye View</option>}
                {show && <option value="resetCurrentChecklist">Reset Current Checklist</option>}
                {show && <option value="resetCurrentList">Reset Current List</option>}
                {show && <option value="resetCurrentSection">Reset Current Section</option>}
                {show && <option value="openComments">Open Comments</option>}
                {show && <option value="openNotes">Open Notes</option>}
                {show && <option value="openSketchpad">Open Sketchpad</option>}
                {show && <option value="external">External</option>}
            </FormControl>
        )    
    }
}

export default LinkActionType;

/*

| Name                          | Inline Command              | Description                                                                                                                                                                                                                                                               |
|-------------------------------|-----------------------------|---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------|
| Goto                          | goto:                       | Goto an item with an ID.                                                                                                                                                                                                                                                  |
| Goto Unchecked                | gotoUnchecked:              | Goto the first unchecked item with an ID.                                                                                                                                                                                                                                 |
| Goto (New Session)            | newSessionAndGoto:          | This creates a new session for the checklist and then does a goto to the item.                                                                                                                                                                                            |
| Goto (Reset Items In Range)   | resetRangeAndGoto:          | Goto an item and reset all the items in between the one you are currently on. The item you are jumping to should typically be before the item you are jumping from. An example of its usage would be to reset all the items from takeoff to landing after a touch-and-go. |
| Goto (Check Items In Range)   | checkRangeAndGoto:          | Goto an item and check all the items in between the one you are currently on. The item you are jumping to should typically be after the item you are jumping from.                                                                                                        |
| Goto and Return               | gotoAndReturn:              | Goto an item with an ID. This also presents a Go Back button after navigation so that you can return to where you jumped from.                                                                                                                                            |
| Goto Unchecked and Return     | gotoUncheckedAndReturn:     | Goto the first unchecked item with an ID. This also presents a Go Back button after navigation so that you can return to where you jumped from.                                                                                                                           |
| Goto and Return (New Session) | newSessionAndGotoAndReturn: | This creates a new session for the checklist and then does a goto to the item. This also presents a Go Back button after navigation so that you can return to where you jumped from.                                                                                      |
| Goto and Open Birdseye View   | gotoAndOpenBirdseyeView:    | Goto an item with an ID and switch to Birdseye View.                                                                                                                                                                                                                      |
| Reset Current Checklist       | resetCurrentChecklist       | Resets the current Checklist. This gives you a new session of the Checklist which effectively clears all items to their default state.                                                                                                                                    |
| Reset Current List            | resetCurrentList            | Resets the current List. This clears all items to their default state in the current List.                                                                                                                                                                                |
| Reset Current Section         | resetCurrentSection         | Resets the current Section. This clears all items to their default state in the current Section.                                                                                                                                                                          |
| Open Comments                 | openComments                | Opens the Comments view. The Comments view allows detailed rich text content to be added for each item.                                                                                                                                                                   |
| Open Notes                    | openNotes                   | Opens the Notes view. Each item allows user notes to be added.                                                                                                                                                                                                            |
| Open Sketchpad                | openSketchpad               | Opens the Sketchpad view. Each item allows a sketchpad.                                                                                                                                                                                                                   |
| External                      | external:                   | Opens and external URL. This allows you to launch content from any URL on the web. You can also utilize URL's the mobile device supports.                                                                                                                                 |

*/