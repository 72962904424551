import React from 'react';

import {branch} from 'baobab-react/higher-order';
import PropTypes from 'baobab-react/prop-types';
import * as actions from '../actions/actions';
import * as portalActions from '../actions/portalActions';
import * as awsS3 from '../persistence/s3';

import {ButtonToolbar} from 'react-bootstrap';
import {ButtonGroup} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {Dropdown} from 'react-bootstrap';
import {DropdownButton} from 'react-bootstrap';
import {MenuItem} from 'react-bootstrap';
import {SplitButton} from 'react-bootstrap';

import Glyphicon from '../components/app-icon';
import FontAwesome from "react-fontawesome";

import * as transformUtils from '../utils/transformUtils.js'; 

//import shallowCompare from 'react-addons-shallow-compare';

import {Link} from 'react-router-dom';

class Toolbar extends React.PureComponent{

	constructor(props, context) {
		super(props, context);
	}
/*
	shouldComponentUpdate(nextProps, nextState) {
		return shallowCompare(this, nextProps, nextState);
	}
*/
	save() {
		// Need to transform back to checklist specific format
		actions.saveChecklist();
	}

	cut() {
		actions.handleCut(this.context.tree);
	}

	copy() {
		actions.handleCopy(this.context.tree);
	}

	pasteAsLastChild() {
		actions.handlePaste(this.context.tree,"lastChild");
	}

	pasteAsFirstChild() {
		actions.handlePaste(this.context.tree,"firstChild");
	}

	pasteBefore() {
		actions.handlePaste(this.context.tree,"before");
	}

	pasteAfter() {
		actions.handlePaste(this.context.tree,"after");
	}

	pasteLists() {
		actions.handlePasteLists(this.context.tree, "lastChild");
	}

	pasteSections() {
		actions.handlePasteSections(this.context.tree, "lastChild");
	}

	pasteItems() {
		actions.handlePasteItems(this.context.tree, "lastChild");
	}

	expandAll(e) {
		actions.handleExpandAll(this.context.tree);
	}

	collapseAll(e) {
		actions.handleCollapseAll(this.context.tree);
	}

	addListAsFirstChild() {
		actions.handleAddList(this.context.tree,this.context.tree.select(this.context.tree.select('selectedNodePath').get()),"firstChild");
	}

	addListAsLastChild() {
		actions.handleAddList(this.context.tree,this.context.tree.select(this.context.tree.select('selectedNodePath').get()),"lastChild");
	}

	addListBefore() {
		actions.handleAddList(this.context.tree,this.context.tree.select(this.context.tree.select('selectedNodePath').get()),"before");
	}

	addListAfter() {
		actions.handleAddList(this.context.tree,this.context.tree.select(this.context.tree.select('selectedNodePath').get()),"after");
	}

	addSectionAsFirstChild() {
		actions.handleAddSection(this.context.tree,this.context.tree.select(this.context.tree.select('selectedNodePath').get()),"firstChild");
	}

	addSectionAsLastChild() {
		actions.handleAddSection(this.context.tree,this.context.tree.select(this.context.tree.select('selectedNodePath').get()),"lastChild");
	}

	addSectionBefore() {
		actions.handleAddSection(this.context.tree,this.context.tree.select(this.context.tree.select('selectedNodePath').get()),"before");
	}

	addSectionAfter() {
		actions.handleAddSection(this.context.tree,this.context.tree.select(this.context.tree.select('selectedNodePath').get()),"after");
	}

	addItemAsFirstChild() {
		actions.handleAddItem(this.context.tree,this.context.tree.select(this.context.tree.select('selectedNodePath').get()),"firstChild");
	}

	addItemAsLastChild() {
		actions.handleAddItem(this.context.tree,this.context.tree.select(this.context.tree.select('selectedNodePath').get()),"lastChild");
	}

	addItemBefore() {
		actions.handleAddItem(this.context.tree,this.context.tree.select(this.context.tree.select('selectedNodePath').get()),"before");
	}

	addItemAfter() {
		actions.handleAddItem(this.context.tree,this.context.tree.select(this.context.tree.select('selectedNodePath').get()),"after");
	}

	undo() {
		actions.handleUndo(this.context.tree);
	}

	redo() {
		actions.handleRedo(this.context.tree);
	}

	removeNode() {
		actions.handleRemoveNode(this.context.tree,this.context.tree.select(this.context.tree.select('selectedNodePath').get()));
		//actions.handleUndo(this.context.tree);
	}

	moveNodeUp() {
		actions.handleMoveNodeUp(this.context.tree,this.context.tree.select(this.context.tree.select('selectedNodePath').get()));
	}

	moveNodeDown() {
		actions.handleMoveNodeDown(this.context.tree,this.context.tree.select(this.context.tree.select('selectedNodePath').get()));
	}

	prefs() {
	}

	handlePrint(columns,numColumns=5,orientation="portrait",custom=false) {
		var checklist = transformUtils.transformFromChecklistEntities(this.context.tree.get(["tree","root"])); 

		if (this.props.subscriptionPlan.startsWith("no-plan") || this.props.subscriptionPlan.startsWith("basic-plan") || this.props.subscriptionPlan.startsWith("standard-plan")) {
			alert("Sorry. You need to be a Pro Plan subscriber to print.");
		} else {		
			awsS3.printFromMemory(checklist,columns,numColumns,orientation,custom);
		}
	}

	render() {
		var self=this;

		const pasteList = [
			<MenuItem eventKey={0} title="Paste Lists" key="paste-lists" onClick={() => this.pasteLists()}><FontAwesome style={{ color: "#0084c1", paddingRight: "5px" }} name="list-alt" />Paste Lists</MenuItem>,
			<MenuItem eventKey={1} title="Paste Sections" key="paste-sections" onClick={() => this.pasteSections()}><FontAwesome style={{ color: "#ffc153", paddingRight: "5px" }} name="table" />Paste Sections</MenuItem>,
			<MenuItem eventKey={2} title="Paste Items" key="paste-items" onClick={() => this.pasteItems()}><FontAwesome style={{ color: "#118871", paddingRight: "5px" }} name="check" />Paste Items</MenuItem>
		];

		const pasteButton =
			<SplitButton
				id="paste-split-button"
				// bsSize={(this.props.asMainBar) ? "large" : "large"}
				title={<span><FontAwesome name="paste" /></span>}
				onClick={() => this.pasteAsLastChild()}
			>
				{pasteList}
			</SplitButton>;

		var menuItems;
		menuItems = [	
			<MenuItem key={"menuitem1"} onClick={(e)=>{e.stopPropagation();self.handlePrint(false)}}>Print (Portrait - Standard)</MenuItem>,
			<MenuItem key={"menuitem2"} onClick={(e)=>{e.stopPropagation();self.handlePrint(true)}}>Print (Portrait - 5 Columns)</MenuItem>,
			<MenuItem key={"menuitem3"} onClick={(e)=>{e.stopPropagation();self.handlePrint(true,4,"landscape")}}>Print (Landscape - 4 Columns)</MenuItem>,
			<MenuItem key={"menuitem4"} onClick={(e)=>{e.stopPropagation();self.handlePrint(true,2)}}>Print (Portrait - 2 Columns)</MenuItem>,
			<MenuItem key={"menuitem5"} onClick={(e)=>{e.stopPropagation();self.handlePrint(true,2,"landscape")}}>Print (Landscape - 2 Columns)</MenuItem>,
			<MenuItem key={"menuitem6"} onClick={(e)=>{e.stopPropagation();self.handlePrint(false,0,"portrait",true)}}>Print Custom (Portrait)</MenuItem>,
			<MenuItem key={"menuitem7"} onClick={(e)=>{e.stopPropagation();self.handlePrint(false,0,"landscape",true)}}>Print Custom (Landscape)</MenuItem>
		];

		// if (document.publisher === "checkmate") {
		// 	menuItems.splice(1,6);
		// }

		var printMenuComponent = 
			<Dropdown pullLeft id="dropdown-card-menu">
				<Dropdown.Toggle noCaret onClick={(e)=>{e.stopPropagation()}}>
					<Glyphicon style={{color:"#fdfdfd"}} glyph="print" />
				</Dropdown.Toggle>
				<Dropdown.Menu style={{opacity: 0.95}}>
					{menuItems}
				</Dropdown.Menu>
			</Dropdown>;

		return (
			<div style={{width: "100%"}}>
				<ButtonToolbar style={{padding: "5px"}}>
					<ButtonGroup>	
						<Button onClick={this.copy.bind(this)} title="Copy"><Glyphicon glyph="copy" /></Button>
						<Button onClick={this.cut.bind(this)} title="Cut"><Glyphicon glyph="cut" /></Button>
						{/* <Button onClick={this.pasteAsLastChild.bind(this)} title="Paste"><FontAwesome name="paste" /></Button>
						<Button onClick={this.pasteLists.bind(this)} title="Paste as Lists"><FontAwesome name="list-alt" /></Button>
						<Button onClick={this.pasteSections.bind(this)} title="Paste as Sections"><FontAwesome name="table" /></Button>
						<Button onClick={this.pasteItems.bind(this)} title="Paste as Items"><FontAwesome name="check" /></Button> */}
						{pasteButton}
						{/* <Button onClick={this.pasteAsLastChild.bind(this)} title="Paste"><Glyphicon glyph="paste" /></Button> */}
					</ButtonGroup>
					<ButtonGroup>	
						<Button style={{backgroundColor: "rgb(205,61,62)"}} onClick={this.removeNode.bind(this)} title="Delete"><Glyphicon glyph="trash" style={{color:"white"}} /></Button>
					</ButtonGroup>
					<ButtonGroup>
						{/* <Button onClick={this.save.bind(this)} title="Save"><Glyphicon glyph="save" /></Button> */}
						{printMenuComponent}
					</ButtonGroup>
					<ButtonGroup>	
						<Button onClick={this.undo.bind(this)} title="Undo"><Glyphicon glyph="rotate-left" /></Button>
						<Button onClick={this.redo.bind(this)} title="Redo"><Glyphicon glyph="rotate-right" /></Button>
					</ButtonGroup>
{/*}
						<div style={{height: "3px"}}/>
						<Button onClick={this.addListAsLastChild.bind(this)} title="Add List (ALT+1)"><Glyphicon style={{color: "#0084c1"}} glyph="list-alt" /></Button>
						<Button onClick={this.addSectionAsLastChild.bind(this)} title="Add Section (ALT+2)"><Glyphicon style={{color: "#ffc153"}} glyph="table" /></Button>
						<Button onClick={this.addItemAsLastChild.bind(this)} title="Add Item (ALT+3)"><Glyphicon style={{color: "#118871"}} glyph="check" /></Button>
						<div style={{height: "3px"}}/>
						<Button onClick={this.moveNodeUp.bind(this)} title="Move Up (ALT+UP)"><Glyphicon glyph="arrow-up" /></Button>
						<Button onClick={this.moveNodeDown.bind(this)} title="Move Down (ALT+DOWN)"><Glyphicon glyph="arrow-down" /></Button>
						<div style={{height: "3px"}}/>
						<Button onClick={this.expandAll.bind(this)} title="Expand (CTRL+.)"><Glyphicon glyph="plus-square"/></Button>
						<Button onClick={this.collapseAll.bind(this)} title="Collapse (CTRL+,)"><Glyphicon glyph="minus-square"/></Button>
*/}						

						{/*
						<DropdownButton id="split-button-dropdown-paste">
							<MenuItem eventKey="1" onClick={this.pasteAsFirstChild.bind(this)}>First Child</MenuItem>
							<MenuItem eventKey="2" onClick={this.pasteBefore.bind(this)}>Before</MenuItem>
							<MenuItem eventKey="3" onClick={this.pasteAfter.bind(this)}>After</MenuItem>
						</DropdownButton>
						*/}
						{/*
						<Button onClick={this.addListAsLastChild.bind(this)} title="Add List (ALT+1)"><Glyphicon style={{color: "#0084c1"}} glyph="list-alt" /></Button>
						<DropdownButton id="split-button-dropdown-list">
							<MenuItem eventKey="4" onClick={this.addListAsFirstChild.bind(this)}>First Child</MenuItem>
							<MenuItem eventKey="5" onClick={this.addListBefore.bind(this)}>Before</MenuItem>
							<MenuItem eventKey="6" onClick={this.addListAfter.bind(this)}>After</MenuItem>
						</DropdownButton>
						*/}
						{/*
						<Button onClick={this.addSectionAsLastChild.bind(this)} title="Add Section (ALT+2)"><Glyphicon style={{color: "#ffc153"}} glyph="table" /></Button>
						<DropdownButton id="split-button-dropdown-list">
							<MenuItem eventKey="7" onClick={this.addSectionAsFirstChild.bind(this)}>First Child</MenuItem>
							<MenuItem eventKey="8" onClick={this.addSectionBefore.bind(this)}>Before</MenuItem>
							<MenuItem eventKey="9" onClick={this.addSectionAfter.bind(this)}>After</MenuItem>
						</DropdownButton>
						*/}
						{/*
						<Button onClick={this.addItemAsLastChild.bind(this)} title="Add Item (ALT+3)"><Glyphicon style={{color: "#118871"}} glyph="check" /></Button>
						<DropdownButton id="split-button-dropdown-item-value">
							<MenuItem eventKey="10" onClick={this.addItemAsFirstChild.bind(this)}>First Child</MenuItem>
							<MenuItem eventKey="11" onClick={this.addItemBefore.bind(this)}>Before</MenuItem>
							<MenuItem eventKey="12" onClick={this.addItemAfter.bind(this)}>After</MenuItem>
						</DropdownButton>
						*/}
					{/*
					<ButtonGroup>
						<Button onClick={this.prefs.bind(this)} title="Preferences"><Glyphicon glyph="cog" /></Button>
					</ButtonGroup>
					<ButtonGroup>
						<Link to="/login">Home</Link>
					</ButtonGroup>
					*/}
				</ButtonToolbar>
			</div>
		);
	}
}

Toolbar.contextTypes = {
	tree: PropTypes.baobab
};

export default branch({
	subscriptionPlan: ["user","subscriptionPlan"]
}, Toolbar);