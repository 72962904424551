import React from 'react';
import globals from '../constants/globals.js';
import { branch } from 'baobab-react/higher-order';
import PropTypes from 'baobab-react/prop-types';
import * as actions from '../actions/actions';
import * as actionsPortal from '../actions/portalActions';
import * as awsS3 from '../persistence/s3';
import { contentBucket } from '../constants/globals.js';


import { Panel, Button, OverlayTrigger, Popover } from 'react-bootstrap';

import {
	PoweredBy, InstantSearch, Hits, SearchBox, Highlight, RefinementList,
	Pagination, CurrentRefinements, MenuSelect, ClearAll
} from 'react-instantsearch/dom';

import FontAwesome from 'react-fontawesome';

import Flexbox from 'flexbox-react';

import browserHistory from '../history';

import NavLinks from './navPanel/app-nav-links.js';
import NavPanel from './navPanel/app-nav-panel.js';

import { WithContext as ReactTags } from 'react-tag-input';

import MediaQuery from 'react-responsive';

import StripeCheckout from 'react-stripe-checkout';

import Modal from 'react-responsive-modal';
import ChecklistNotes from "./app-checklist-notes.js";
import ChecklistReadOnly from './app-checklist-read-only.js';
import ChecklistHistoryDetail from './app-checklist-history-detail.js';

import Alerts from './app-alerts.js';

import Loader from 'react-loader-advanced';

import moment from "moment";
require("moment-duration-format");

function Product({ hit }) {
	var icon = "question";
	for (var i = 0; i < this.props.genres.length; i++) {
		var genre = this.props.genres[i];
		if (hit.genre == genre.value) {
			icon = genre.icon;
		}
	}
	var tags = hit.tags.map(function (tag, i) {
		return (
			{
				id: i + 1,
				text: tag
			}
		);
	});

	let iconCompletion;

	if (hit.completionState === "n") {
		iconCompletion = <FontAwesome id="iconCompletion" fixedWidth={true} title="Not Completed" style={{ color: "#ff0000cc", fontSize: "36px" }} name="circle-o" />;

	} else if (hit.completionState === "i") {
		iconCompletion = <FontAwesome id="iconCompletion" fixedWidth={true} title="In Progress" style={{ color: "#ffff00cc", fontSize: "36px" }} name="dot-circle-o" />;

	} else if (hit.completionState === "c") {
		iconCompletion = <FontAwesome id="iconCompletion" fixedWidth={true} title="Completed" style={{ color: "#00ff00cc", fontSize: "36px" }} name="check-circle-o" />;
	}

	var tagsLabel;
	if (hit.tags.length > 0) {
		tagsLabel = "Tags: "
	}

	// var elapsedTime = moment.duration(((moment(hit.logbookTimeIn) - moment(hit.logbookTimeOut)) / 1000) / 60, "minutes").format("h [Hours]", 1)

	let elapsedTime = 0.0;

	if (hit.logbookTimeIn !== "------------" && hit.logbookTimeOut !== "------------") {
		const newTimeIn = moment(hit.logbookTimeIn.split("(")[1].split(")")[0], 'YYYY-MM-DD HH:mm [UTC]');
		const newTimeOut = moment(hit.logbookTimeOut.split("(")[1].split(")")[0], 'YYYY-MM-DD HH:mm [UTC]');

		elapsedTime = moment.duration(((moment(newTimeIn) - moment(newTimeOut)) / 1000) / 60, "minutes").format("h [Hours]", 1)
	}

	/*
	  var contributorsTooltip = "";
	  var creator = "Creator:\n";
	  var contributors = "\n\nContributors:\n";
	  if (hit.contributors.length === 0) {
		  contributorsTooltip = "There are currently no contributors.";
	  } else {
		  contributorsTooltip = contributorsTooltip + creator;
		  contributorsTooltip = contributorsTooltip + (hit.contributors[0] && hit.contributors[0].nickname && hit.contributors[0].nickname.length>0 ? hit.contributors[0].nickname : hit.contributors[0].name);
	
		  if (hit.contributors.length > 1) {
			  contributorsTooltip = contributorsTooltip + contributors;
			  for (var i=1; i<hit.contributors.length; i++) {
				  var contributor = hit.contributors[i].name;
				  if (i<hit.contributors.length-1) {
					  contributorsTooltip = contributorsTooltip + contributor + "\n";                
				  } else {
					  contributorsTooltip = contributorsTooltip + contributor;                
				  }
			  }
		  }
	  }
	
	  const popoverContributors = (
	  <Popover id="popover-positioned-bottom" title="Contributors">
		  {contributorsTooltip}            
	  </Popover>
	  );
	
	  var iconContributors = <FontAwesome id="contributorsIcon" fixedWidth={true} title={contributorsTooltip} style={{color: "#888888", fontSize: "14px", marginRight: "5px"}} name="user"/>;
	
	  var overlayContributors =
	  <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={popoverContributors}>
		  {iconContributors}
	  </OverlayTrigger>;
	*/

	return (
		<div style={{ width: "100%" }}>

			<span>
				<Flexbox className="hit-name" flexDirection="row" alignItems="flex-start" justifyContent="space-between" style={{ border: "2px solid #1F272B", borderRadius: "15px", padding: "10px", margin: "10px" }}>
					<Flexbox flexDirection="row" alignItems="center" style={{ width: "100%" }}>
						<Flexbox flexDirection="column" alignItems="flex-start" justify-content="space-between">
							<FontAwesome fixedWidth={true} style={{ minWidth: "40px", marginRight: "10px" }} size="2x" name={icon} />
						</Flexbox>
						<Flexbox flexDirection="column" alignItems="flex-start" style={{ width: "100%", marginRight: "10px" }}>
							<Flexbox flexDirection="row" alignItems="center" justifyContent="space-between" style={{ width: "100%" }}>
								<div style={{ fontSize: "16px", fontWeight: "bold" }}><Highlight attributeName="name" hit={hit} /></div>
								<Flexbox flexDirection="row" alignItems="center">
									<Flexbox flex="0" alignSelf="flex-start">
										{iconCompletion}
									</Flexbox>
									<Button style={{ marginLeft: 10 }} onClick={(e) => {
										this.props.dispatch(
											awsS3.printInstance,
											contentBucket,
											hit.url
										);
									}
									}>
										<i style={{ fontSize: 18 }} className="fa fa-print" />
									</Button>
									<Button style={{ marginLeft: 10 }} onClick={(e) => {
										this.props.dispatch(
											actions.deleteStatusItem,
											hit
										);
									}
									}>
										<i style={{ fontSize: 18 }} className="fa fa-trash-o" />
									</Button>
								</Flexbox>
							</Flexbox>
							<div style={{ marginTop: "10px", fontSize: "14px", fontWeight: "normal" }}><Highlight attributeName="description" hit={hit} /></div>
							<div style={{ color: "gray", fontSize: "10px", fontWeight: "normal", marginLeft: 0 }}>Created: {moment.unix(hit.createdDateTime).format("LLLL")}</div>
							<div style={{ color: "gray", fontSize: "10px", fontWeight: "normal", marginLeft: 0 }}>Updated: {moment.unix(hit.lastUpdatedDateTime).format("LLLL")}</div>
							<Flexbox style={{ marginTop: "10px" }}>
								<ReactTags tags={tags}
									autofocus={false}
									readOnly={true} />
							</Flexbox>
							{(hit.logbookTimeOut !== "------------" || hit.logbookTimeIn != "------------") && <Flexbox flexDirection="column" style={{ marginTop: "10px", backgroundColor: "lightblue", borderRadius: 5, padding: 5 }}>
								<div style={{ color: "gray" }}>Time Out: <i>{hit.logbookTimeOut}</i></div>
								<div style={{ color: "gray" }}>Time In: <i>{hit.logbookTimeIn}</i></div>
								<div style={{ color: "gray" }}>Elapsed Time: <i>{elapsedTime}</i></div>
							</Flexbox>
							}
							<Flexbox className="history-card-buttons" flexDirection="row" alignItems="flex-start" flexWrap="wrap" justifyContent="flex-start" style={{ width: "100%" }}>
								<Button disabled={hit.notes.length === 0} style={{ marginTop: 10, marginRight: 10, color: "#0E7AFE", fontWeight: "bold" }} onClick={() => {
									this.props.dispatch(
										actions.showNotesModal,
										hit
									);
								}}>Notes</Button>
								<Button style={{ marginTop: 10, marginRight: 10, color: "#0E7AFE", fontWeight: "bold" }} onClick={() => {
									this.props.dispatch(
										actions.showDetailedStatusModal,
										hit
									);
								}}>Status</Button>
								<Button style={{ marginTop: 10, marginRight: 10, color: "#0E7AFE", fontWeight: "bold" }} onClick={() => {
									this.props.dispatch(
										actions.showDetailedHistoryModal,
										hit
									);
								}}>History</Button>
							</Flexbox>

						</Flexbox>
					</Flexbox>
				</Flexbox>
			</span>
		</div>
	);
};

class FlightSchool extends React.Component {
	constructor(props, context) {
		super(props, context);

		if (!props.user.loggedIn) {
			actions.forceLogin(browserHistory, this.props.location.pathname);
		}

		this.state = {
			session: 1,
			searchState: {},
			showFilters: false
		}

		Product = Product.bind(this);
	}
	onSearchStateChange(searchState) {
		this.setState({ searchState: searchState });
	}
	render() {
		if (!this.props.user.loggedIn) {
			return (
				<div />
			);
		} else {

			let showNotesModal;
			showNotesModal =
				<Modal classNames={{ modal: "custom-modal" }} open={this.props.showNotes.showModal} onClose={() => this.props.dispatch(actions.closeNotesModal)} little>
					<h3 style={{ textAlign: "center" }}>Notes</h3>
					<div style={{ height: "70vh", overflow: "auto" }}>
						<ChecklistNotes checklist={this.props.showNotes.checklist} />
					</div>
				</Modal>;

			let showDetailedStatusModal;
			showDetailedStatusModal =
				<Modal classNames={{ modal: "custom-modal" }} open={this.props.showDetailedStatus.showModal} onClose={() => this.props.dispatch(actions.closeDetailedStatusModal)} little>
					<h3 style={{ textAlign: "center" }}>Status Detail</h3>
					<div style={{ height: "70vh", overflow: "auto" }}>
						<ChecklistReadOnly checklist={this.props.showDetailedStatus.checklist} />
					</div>
				</Modal>;

			let showDetailedHistoryModal;
			showDetailedHistoryModal =
				<Modal classNames={{ modal: "custom-modal" }} open={this.props.showDetailedHistory.showModal} onClose={() => this.props.dispatch(actions.closeDetailedHistoryModal)} little>
					<h3 style={{ textAlign: "center" }}>History Detail</h3>
					<div style={{ height: "70vh", overflow: "auto" }}>
						<ChecklistHistoryDetail checklist={this.props.showDetailedHistory.checklist} />
					</div>
				</Modal>;

			var iconFiltersVisible = <FontAwesome fixedWidth={true} style={{ color: "#ffffff", fontSize: "20px" }} name="chevron-up" />;
			var iconFiltersHidden = <FontAwesome fixedWidth={true} style={{ color: "#ffffff", fontSize: "20px" }} name="chevron-right" />;

			var filtersVisible = <Flexbox style={{ margin: "5px" }} flexDirection="row" alignItems="center">Hide Filters {iconFiltersVisible}</Flexbox>
			var filtersHidden = <Flexbox style={{ margin: "5px" }} flexDirection="row" alignItem="center">Show Filters {iconFiltersHidden}</Flexbox>

			var historyIndex = "dev_history_" + this.props.user.identityId;

			const spinner =
				<FontAwesome
					name='spinner'
					size='4x'
					spin />;


			return (
				<Loader show={this.props.loadingSpinner} message={spinner}>

					<div key={"session" + this.state.session}>

						<InstantSearch
							appId="D6RLXM2EO4"
							apiKey="5f99830fd8e61878cedbf78e336c19a7"
							indexName={historyIndex}
							searchState={this.state.searchState}
							onSearchStateChange={this.onSearchStateChange.bind(this)}
						>
							<Alerts />
							<Flexbox flexDirection="column" height="100vh" style={{ minHeight: 0, width: "100%" }}>
								<NavLinks />

								<Flexbox flex="1" flexDirection="row" width="100vw" style={{ minHeight: 0, width: "100%" }}>
									<MediaQuery query='(min-width: 768px)'>
										<NavPanel />
									</MediaQuery>

									<Flexbox flex="1" flexDirection="column" alignItems="flex-start" style={{ minHeight: 0, width: "100%" }}>
										{!this.props.user.subscriptionPlan.startsWith("pro-plan") && <span style={{ fontSize: 24, marginLeft: 10, marginRight: 10, color: "red" }}>You must be a Pro Plan subscriber to view history.</span>}
										<Flexbox flex="1" flexDirection="column" style={{ minHeight: 0, width: "100%" }}>



											<MediaQuery query='(max-width: 767px)'>
											<Flexbox flexDirection='row' width="100%" padding='20px'>
													<Flexbox flexGrow={1} style={{ borderBottom: "1px solid #ccc" }} justifyContent="space-between">
														<h1>HISTORY</h1>

													</Flexbox>
												</Flexbox>
												<Flexbox style={{ margin: "10px" }} paddingLeft='20px' flexDirection="row" alignItems="center" justifyContent="space-between">
												
													<SearchBox autoFocus={true} />
													<Button style={{ marginLeft: "10px" }}
														onClick={() => {
															this.setState({ session: this.state.session + 1 });
														}}>
														<i style={{ fontSize: 18 }} className="fa fa-refresh" />
													</Button>

												</Flexbox>
												<div style={{ width: "100%", backgroundColor: "gray" }} onClick={() => {
													this.setState({ showFilters: !this.state.showFilters });
												}}>
													<Flexbox style={{ color: "white" }} flexDirection="column" alignItems="center" justifyContent="center">
														{this.state.showFilters ? filtersVisible : filtersHidden}
													</Flexbox>
												</div>
												<div style={{ display: this.state.showFilters ? "flex" : "none", overflow: "auto", flexDirection: "column", flex: 2, margin: "10px" }}>
													<div style={{ height: "100%", overflow: "auto", borderStyle: "solid", borderRadius: "5px", borderWidth: "1px", backgroundColor: "transparent", borderColor: "#1F272B", padding: "10px" }}>
														<h4>Filters</h4>
														<ClearAll />
														<div>
															<CurrentRefinements />
														</div>
														<h4>Checklist Name</h4>
														<MenuSelect attributeName="name" />
														<h4>Completion</h4>
														<RefinementList attributeName="completionStateString" />
														<h4>Genre</h4>
														<RefinementList attributeName="genre" />
														<h4>Tags</h4>
														<RefinementList attributeName="tags" />
													</div>
												</div>
												<div style={{ display: "flex", minHeight: 0, flexDirection: "column", flex: 2, margin: "10px" }}>
													<Flexbox flexDirection="column" flexGrow={1} style={{ minHeight: 0, overflowY: "auto", width: "100%", borderStyle: "solid", borderRadius: "5px", borderWidth: "1px", backgroundColor: "transparent", borderColor: "transparent" }}>
														<Hits style={{ width: "100%" }} hitComponent={Product} />
													</Flexbox>
													<Flexbox style={{ width: "100%", marginTop: "10px" }} minHeight="50px" padding="10px" alignItems="center" justifyContent="center">
														<Pagination showLast={true} />
													</Flexbox>
												</div>
											</MediaQuery>

											<MediaQuery query='(min-width: 768px)'>

												<Flexbox flexDirection='row' width="100%" padding='20px'>
													<Flexbox flexGrow={1} style={{ borderBottom: "1px solid #ccc" }} justifyContent="space-between">
														<h1>FLIGHT SCHOOLS</h1>

													</Flexbox>
												</Flexbox>
												<Flexbox flexDirection='row' width="100%" paddingLeft='20px' paddingTop='10px'>
													<Flexbox >
														<Flexbox width='300px' flexDirection="row" alignItems="center" justifyContent="space-between">
														<Flexbox style={{ margin: "10px" }} flexDirection="row" alignItems="center" justifyContent="space-between">
															<SearchBox autoFocus={true} />
															<Button style={{ marginLeft: "10px" }}
																onClick={() => {
																	this.setState({ session: this.state.session + 1 });
																}}>
																<i style={{ fontSize: 18 }} className="fa fa-refresh" />
															</Button>

														</Flexbox>

														</Flexbox>

														{/* <Flexbox flexDirection="row" lignItems="left" padding="10px" paddingLeft='25px'>
													<div className='pill-button-container'>
														<Button className='btn-pill'>FILTER</Button>
														<Button className='btn-pill'>FILTER</Button>
														<Button className='btn-pill'>FILTER</Button>
														<Button className='btn-pill'>FILTER</Button>
													</div>												</Flexbox> */}
													</Flexbox>
												</Flexbox>
												<Flexbox flexDirection="row" style={{ height: "100%" }}>
													<div style={{ display: "flex", overflow: "auto", flexDirection: "column", flex: 2, margin: "10px", maxWidth: "280px" }}>
														
														<div style={{ height: "100%", overflow: "auto", borderStyle: "solid", borderRadius: "5px", borderWidth: "1px", backgroundColor: "transparent", borderColor: "#1F272B", padding: "10px" }}>
															<h4>Filters</h4>
															<ClearAll />
															<div>
																<CurrentRefinements />
															</div>
															<h4>Checklist Name</h4>
															<MenuSelect attributeName="name" />
															<h4>Completion</h4>
															<RefinementList attributeName="completionStateString" />
															<h4>Genre</h4>
															<RefinementList attributeName="genre" />
															<h4>Tags</h4>
															<RefinementList attributeName="tags" />
														</div>
													</div>
													<div style={{ display: "flex", minHeight: 0, flexDirection: "column", flex: 2, margin: "10px" }}>
														<Flexbox flexDirection="column" flexGrow={1} style={{ minHeight: 0, overflowY: "auto", width: "100%", borderStyle: "solid", borderRadius: "5px", borderWidth: "1px", backgroundColor: "transparent", borderColor: "transparent" }}>
															<Hits style={{ width: "100%" }} hitComponent={Product} />
														</Flexbox>
														<Flexbox style={{ width: "100%", marginTop: "10px" }} minHeight="50px" padding="10px" alignItems="center" justifyContent="center">
															<Pagination showLast={true} />
														</Flexbox>
													</div>
												</Flexbox>
											</MediaQuery>


										</Flexbox>
									</Flexbox>
								</Flexbox>
								{showNotesModal}
								{showDetailedStatusModal}
								{showDetailedHistoryModal}
							</Flexbox>
						</InstantSearch>
					</div>
				</Loader>
			);
		}
	}

	componentDidMount() {
		this.context.tree.select(['appState', 'navPanel']).set('selected', 'history');
	}
}

FlightSchool.contextTypes = {
	tree: PropTypes.baobab
};

export default branch({
	user: ['user'],
	version: ['version'],
	genres: ['genres'],
	showNotes: ["appState", "showNotes"],
	showDetailedStatus: ["appState", "showDetailedStatus"],
	showDetailedHistory: ["appState", "showDetailedHistory"],
	loadingSpinner: ['appState', 'loadingSpinner']
}, FlightSchool);