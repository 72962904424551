import React from 'react';
import globals from '../../constants/globals.js';
import { branch } from 'baobab-react/higher-order';

import * as actions from '../../actions/actions';
import * as awsS3 from '../../persistence/s3';
import { Button } from 'react-bootstrap';

//import Card from '../../components/card/app-card.js';

import FontAwesome from 'react-fontawesome';

import Flexbox from 'flexbox-react';
import browserHistory from '../../history';

import MediaQuery from 'react-responsive';

//import shallowCompare from 'react-addons-shallow-compare';

import StripeCheckout from 'react-stripe-checkout';

import Alerts from '../app-alerts.js';

import HomeLink from './app-home-link.js';
import PreCheckout from './app-subscribe-perf-trial-pre-checkout.js';

class Subscribe extends React.PureComponent {
	constructor(props, context) {
		super(props, context);

		if (!props.user.loggedIn) {
			actions.forceLogin(browserHistory, this.props.location.pathname);
		}

		this.state = {
			showCheckout: false,
			checkoutParams: {

			}
		}
	}

	_onTokenStandard = (token) => {
		awsS3.updateSubscription(token, "standard-plan-unlimited");
	}

	_onTokenStandardYearly = (token) => {
		awsS3.updateSubscription(token, "standard-plan-per-year");
	}

	_onTokenMonthly = (token) => {
		awsS3.updateSubscription(token, "pro-plan-monthly");
	}

	_onTokenProYearlyAs = (token) => {
		awsS3.updateSubscription(token, "pro-plan-as-yearly-v4");
	}

	_onTokenProMonthlyAs = (token) => {
		awsS3.updateSubscription(token, "pro-plan-as-monthly-v4");
	}

	_onTokenPerformanceYearlyAs = (token) => {
		awsS3.updateSubscription(token, "pro-plan-performance-as-yearly-v4");
	}

	_onTokenPerformanceMonthlyAs = (token) => {
		awsS3.updateSubscription(token, "pro-plan-performance-as-monthly-v4");
	}

	_onTokenProYearly20DollarsOffPerYear = (token) => {
		awsS3.updateSubscription(token, "pro-plan-per-year", "20-dollars-off-per-year");
	}

	_onTokenProYearly50DollarsOffFirstYear = (token) => {
		awsS3.updateSubscription(token, "pro-plan-per-year", "50-dollars-off-first-year");
	}

	_onTokenYearly = (token) => {
		awsS3.updateSubscription(token, "pro-plan-yearly");
	}

	_onTokenUnlimited = (token) => {
		awsS3.updateSubscription(token, "pro-plan-unlimited");
	}

	_onTokenUnlimitedV4 = (token) => {
		awsS3.updateSubscription(token, "pro-plan-unlimited-v4");
	}

	_onTokenUnlimitedDiscount = (token) => {
		awsS3.updateSubscription(token, "pro-plan-unlimited-discount");
	}
	/*
		shouldComponentUpdate(nextProps, nextState) {
			return shallowCompare(this, nextProps, nextState);
		}
	*/
	handleClickBasicButton(e) {
		awsS3.updateSubscriptionBasic();
	}

	handleClickCancelButton(e) {
		awsS3.updateSubscriptionCancel();
	}

	render() {
		var self = this;

		var subDesc = "";
		var subExp;
		var current = "Current Plan ";

		switch (this.props.user.subscriptionPlan) {
			case "no-plan":
				subDesc = current + "Free Plan";
				subExp = "Upgrade to a Pro or Performance Plan below";
				break;
			case "basic-plan":
				subDesc = current + "Basic Plan";
				subExp = "Upgrade to a Pro or Performance Plan below";
				break;
			case "basic-plan-checkmate":
				subDesc = current + "Basic Plan (CheckMate)";
				subExp = "Upgrade to a Pro or Performance Plan below";
				break;
			case "standard-plan-unlimited":
				subDesc = current + "Standard Plan (Unlimited)";
				subExp = "Never Expires - Upgrade to a Pro or Performance Plan below";
				break;
			case "standard-plan-per-year":
				subDesc = current + "Standard Plan (Yearly)";
				subExp = "Upgrade to a Pro or Performance Plan below"
				break;
			case "standard-plan-monthly-itunes":
				subDesc = current + "Standard Plan iTunes (Monthly)";
				subExp = "Expires: " + new Date(this.props.user.subscriptionExpiration);
				break;
			case "standard-plan-yearly-itunes":
				subDesc = current + "Standard Plan iTunes (Yearly)";
				subExp = "Expires: " + new Date(this.props.user.subscriptionExpiration);
				break;
			case "pro-plan-per-year":
				subDesc = current + "Pro Plan (Yearly)";
				subExp = "Upgrade to a Performance Plan below";
				break;
			case "pro-plan-monthly":
				subDesc = current + "Pro Plan (Monthly)";
				subExp = "Upgrade to a Performance Plan below";
				break;
			case "pro-plan-as-monthly":
				subDesc = current + "Pro Plan (Monthly)";
				subExp = "Upgrade to a Performance Plan below";
				break;
			case "pro-plan-as-monthly-v4":
				subDesc = current + "Pro Plan (Monthly)";
				subExp = "Upgrade to a Performance Plan below";
				break;
			// case "pro-plan-performance-as-monthly":
			case "pro-plan-performance-as-monthly-v2":
				subDesc = current + "Performance Plan (Monthly)";
				break;
			case "pro-plan-performance-as-monthly-v4":
				subDesc = current + "Performance Plan (Monthly)";
				break;
			case "pro-plan-yearly":
				subDesc = current + "Pro Plan (Yearly)";
				subExp = "Upgrade to a Performance Plan below";
				break;
			case "pro-plan-as-yearly":
				subDesc = current + "Pro Plan (Yearly)";
				subExp = "Upgrade to a Performance Plan below";
				break;
			case "pro-plan-as-yearly-v4":
				subDesc = current + "Pro Plan (Yearly)";
				subExp = "Upgrade to a Performance Plan below";
				break;
			// case "pro-plan-performance-as-yearly":
			case "pro-plan-performance-as-yearly-v2":
				subDesc = current + "Performance Plan (Yearly)";
				break;
			case "pro-plan-performance-as-yearly-v4":
				subDesc = current + "Performance Plan (Yearly)";
				break;
			case "pro-plan-v5-itunes":
				subDesc = current + "Pro Plan iTunes (Version 5)"
				subExp = "Never Expires for Version 5 - Upgrade to a Performance Plan below"
				break;
			case "pro-plan-as-yearly-itunes":
				subDesc = current + "Pro Plan iTunes (Version 6)"
				subExp = "Expires: " + new Date(this.props.user.subscriptionExpiration);
				break;
			case "pro-plan-performance-as-yearly-itunes":
				subDesc = current + "Performance Plan iTunes (Version 6)"
				subExp = "Expires: " + new Date(this.props.user.subscriptionExpiration);
				break;
			case "pro-plan-monthly-itunes":
				subDesc = current + "Pro Plan iTunes (Monthly)";
				subExp = "Expires: " + new Date(this.props.user.subscriptionExpiration);
				break;
			case "pro-plan-yearly-itunes":
				subDesc = current + "Pro Plan iTunes (Yearly)";
				subExp = "Expires: " + new Date(this.props.user.subscriptionExpiration);
				break;
			case "pro-plan-unlimited":
			case "pro-plan-unlimited-v4":
				subDesc = current + "Pro Plan (Unlimited)";
				subExp = "Never Expires - Upgrade to a Performance Plan below";
				break;
		}

		if (!this.props.user.loggedIn) {
			return (
				<div />
			);
		} else {
			var self = this;

			var cancelButton =
				<Button bsSize="small" onClick={() =>
					window.confirm("Are you sure you want to cancel? Safe skies ahead!", () => {
						this.handleClickCancelButton()
					})
				} style={{ borderRadius: "32px", marginTop: 20, backgroundColor: "transparent", color: "white", fontWeight: "bold", width: "220px" }}>
					Cancel Subscription
				</Button>;

			var basicButton =
				<Button bsSize="large" onClick={this.handleClickBasicButton.bind(this)} style={{ borderRadius: "32px", backgroundColor: "rgb(255,181,63)", color: "white", fontWeight: "bold", width: "145px" }}>
					Confirm Free
				</Button>;

			var basicButtonDisabled =
				<Button bsSize="large" disabled onClick={this.handleClickBasicButton.bind(this)} style={{ borderRadius: "32px", backgroundColor: "rgb(255,181,63,0)", color: "white", fontWeight: "bold", width: "145px" }}>
					Free
				</Button>;


			var freeButton;
			var freeTitle;
			if (this.props.user.subscriptionPlan === "pro-plan-monthly" || this.props.user.subscriptionPlan === "pro-plan-yearly" || this.props.user.subscriptionPlan === "standard-plan-per-year" || this.props.user.subscriptionPlan === "pro-plan-per-year" || this.props.user.subscriptionPlan.startsWith("pro-plan-as") || this.props.user.subscriptionPlan.startsWith("pro-plan-performance-as")) {
				freeTitle = "Free Plan"
				freeButton = basicButtonDisabled;
			} else if (this.props.user.subscriptionPlan === "pro-plan-unlimited" || this.props.user.subscriptionPlan === "standard-plan-unlimited" || this.props.user.subscriptionPlan === "pro-plan-v5-itunes") {
				freeTitle = "Free Plan"
				freeButton = basicButtonDisabled;
			} else {
				freeTitle = "Free Plan"
				freeButton = basicButtonDisabled;
			}
			var stripeKey = globals.stage === "prod" ? globals.stripeKeyProd : globals.stripeKeyDev;

			// var subscribeStandardButton =
			// <div style={{ width: "100%" }}>
			// 	<StripeCheckout
			// 		name="MiraCheck"
			// 		description="Standard Plan - Unlimited"
			// 		image={"assets/checkmate_logo_only_no_border_space_128x128.png"}
			// 		token={(token) => { self._onTokenStandard(token) }}
			// 		stripeKey={stripeKey}
			// 		amount={2999}
			// 		email={self.props.user.email}
			// 		ComponentClass="div"
			// 	>	
			// 		<Button bsSize="large" disabled={(this.props.user.subscriptionPlan === "pro-plan-unlimited" || this.props.user.subscriptionPlan === "standard-plan-unlimited") ? "disabled" : false} style={{ color: "#0E7AFE", fontWeight: "bold" }}>$49/yr</Button>
			// 	</StripeCheckout>
			// 	{/* 
			// 	<a style={{marginLeft: "5px"}} href="javascript:alert('This is a test sandbox so you will not actually be charged. For the credit card enter 4242-4242-4242-4242, for expiration enter any future date and for CVC enter any three numbers.')">Help</a>
			// 	*/}
			// </div>;

			// var subscribe5DollarPerformancePlanButton = 
			// 	<form action="https://6qlld3f4yl.execute-api.us-east-1.amazonaws.com/prod/stripeCreateCheckoutSession" method="post">
			// 		<Button style={{ color: "#0E7AFE", fontWeight: "bold" }} type="submit" bsSize="large">BUY IT!</Button>
			// 	</form>;

			var subscribeStandardYearlyButton =
				<div style={{ width: "100%" }}>
					<StripeCheckout
						name="MiraCheck"
						description="Standard Plan - Yearly"
						image={"assets/checkmate_logo_only_no_border_space_128x128.png"}
						token={(token) => { self._onTokenStandardYearly(token) }}
						stripeKey={stripeKey}
						amount={4900}
						email={self.props.user.email}
						ComponentClass="div"
					>
						<Button bsSize="large" disabled={(this.props.user.subscriptionPlan === "pro-plan-unlimited" || this.props.user.subscriptionPlan === "standard-plan-unlimited" || this.props.user.subscriptionPlan === "standard-plan-per-year" || this.props.user.subscriptionPlan === "pro-plan-v5-itunes") ? "disabled" : false} style={{ color: "#0E7AFE", fontWeight: "bold" }}>$49/yr</Button>
					</StripeCheckout>
					{/* 
				<a style={{marginLeft: "5px"}} href="javascript:alert('This is a test sandbox so you will not actually be charged. For the credit card enter 4242-4242-4242-4242, for expiration enter any future date and for CVC enter any three numbers.')">Help</a>
				*/}
				</div>;

			var subscribePerformanceMonthlyButton =
				<Button bsSize="large" disabled={(this.props.user.subscriptionPlan === "pro-plan-unlimited" || this.props.user.subscriptionPlan === "pro-plan-per-year" || this.props.user.subscriptionPlan === "pro-plan-v5-itunes") ? "disabled" : false} style={{ borderRadius: "32px", backgroundColor: "rgb(255,181,63,0)", color: "white", fontWeight: "bold" }} onClick={(e) => {
					e.preventDefault();

					this.setState({
						showCheckout: true,
						checkoutParams: {
							ui_mode: "embedded",
							line_items: [
								{
									price: "pro-plan-performance-as-monthly-v4",
									quantity: 1
								}
							],
							mode: "subscription",
							return_url: `${globals.hostUrl}/perfMonthlyReturn?session_id={CHECKOUT_SESSION_ID}`,
							// automatic_tax: { "enabled": true }
						}
					})
				}}>$8/Month</Button>
				;

			var subscribePerformanceYearlyButton =
				<Button bsSize="large" disabled={(this.props.user.subscriptionPlan === "pro-plan-unlimited" || this.props.user.subscriptionPlan === "pro-plan-per-year" || this.props.user.subscriptionPlan === "pro-plan-v5-itunes") ? "disabled" : false} style={{ borderRadius: "32px", backgroundColor: "rgb(255,181,63)", color: "black", fontWeight: "bold" }} onClick={(e) => {
					e.preventDefault();

					this.setState({
						showCheckout: true,
						checkoutParams: {
							ui_mode: "embedded",
							line_items: [
								{
									price: "pro-plan-performance-as-yearly-v4",
									quantity: 1
								}
							],
							mode: "subscription",
							return_url: `${globals.hostUrl}/perfYearlyReturn?session_id={CHECKOUT_SESSION_ID}`,
							// automatic_tax: { "enabled": true }
						}
					})
				}}>$49/Year</Button>


			var subscribeMonthlyButton =
				<Button bsSize="large" disabled={(this.props.user.subscriptionPlan === "pro-plan-unlimited" || this.props.user.subscriptionPlan === "pro-plan-per-year" || this.props.user.subscriptionPlan === "pro-plan-v5-itunes") ? "disabled" : false} style={{ borderRadius: "32px", backgroundColor: "rgb(255,181,63,0)", color: "white", fontWeight: "bold" }} onClick={(e) => {
					e.preventDefault();

					this.setState({
						showCheckout: true,
						checkoutParams: {
							ui_mode: "embedded",
							line_items: [
								{
									price: "pro-plan-as-monthly-v4",
									quantity: 1
								}
							],
							mode: "subscription",
							return_url: `${globals.hostUrl}/proMonthlyReturn?session_id={CHECKOUT_SESSION_ID}`,
							// automatic_tax: { "enabled": true }
						}
					})
				}}>$5/Month</Button>;

			var subscribeProYearlyButton =
				<Button bsSize="large" disabled={(this.props.user.subscriptionPlan === "pro-plan-unlimited" || this.props.user.subscriptionPlan === "pro-plan-per-year" || this.props.user.subscriptionPlan === "pro-plan-v5-itunes") ? "disabled" : false} style={{ borderRadius: "32px", backgroundColor: "rgb(255,181,63)", color: "black", fontWeight: "bold" }} onClick={(e) => {
					e.preventDefault();

					this.setState({
						showCheckout: true,
						checkoutParams: {
							ui_mode: "embedded",
							line_items: [
								{
									price: "pro-plan-as-yearly-v4",
									quantity: 1
								}
							],
							mode: "subscription",
							return_url: `${globals.hostUrl}/proYearlyReturn?session_id={CHECKOUT_SESSION_ID}`,
							// automatic_tax: { "enabled": true }
						}
					})
				}}>$36/Year</Button>;

			var subscribeProYearlyButton20DollarsOffPerYear =
				<div style={{ width: "100%" }}>
					<StripeCheckout
						name="MiraCheck"
						description="Pro Plan - Yearly"
						image={"assets/checkmate_logo_only_no_border_space_128x128.png"}
						token={(token) => { self._onTokenProYearly20DollarsOffPerYear(token) }}
						stripeKey={stripeKey}
						amount={7900}
						email={self.props.user.email}
						ComponentClass="div"
					>
						<Button bsSize="large" disabled={(this.props.user.subscriptionPlan === "pro-plan-unlimited" || this.props.user.subscriptionPlan === "pro-plan-per-year" || this.props.user.subscriptionPlan === "pro-plan-v5-itunes") ? "disabled" : false} style={{ color: "#0E7AFE", fontWeight: "bold" }}>$79/yr</Button>
					</StripeCheckout>
					{/* 
					<a style={{marginLeft: "5px"}} href="javascript:alert('This is a test sandbox so you will not actually be charged. For the credit card enter 4242-4242-4242-4242, for expiration enter any future date and for CVC enter any three numbers.')">Help</a>
					*/}
				</div>;

			var subscribeProYearlyButton50DollarsOffFirstYear =
				<div style={{ width: "100%" }}>
					<StripeCheckout
						name="MiraCheck"
						description="Pro Plan - Yearly"
						image={"assets/checkmate_logo_only_no_border_space_128x128.png"}
						token={(token) => { self._onTokenProYearly50DollarsOffFirstYear(token) }}
						stripeKey={stripeKey}
						amount={4900}
						email={self.props.user.email}
						ComponentClass="div"
					>
						<Button bsSize="large" disabled={(this.props.user.subscriptionPlan === "pro-plan-unlimited" || this.props.user.subscriptionPlan === "pro-plan-per-year" || this.props.user.subscriptionPlan === "pro-plan-v5-itunes") ? "disabled" : false} style={{ color: "#0E7AFE", fontWeight: "bold" }}>$49 First Year</Button>
					</StripeCheckout>
					{/* 
					<a style={{marginLeft: "5px"}} href="javascript:alert('This is a test sandbox so you will not actually be charged. For the credit card enter 4242-4242-4242-4242, for expiration enter any future date and for CVC enter any three numbers.')">Help</a>
					*/}
				</div>;

			// var subscribeYearlyButton =
			// 	<div style={{ width: "100%" }}>
			// 		<StripeCheckout
			// 			name="MiraCheck"
			// 			description="Pro Plan - Yearly"
			// 			image={"assets/checkmate_logo_only_no_border_space_128x128.png"}
			// 			token={(token) => { self._onTokenYearly(token) }}
			// 			stripeKey={stripeKey}
			// 			amount={4999}
			// 			email={self.props.user.email}
			// 			ComponentClass="div"
			// 		>
			// 			<Button bsSize="large" disabled={this.props.user.subscriptionPlan === "pro-plan-unlimited" ? "disabled" : false} style={{ color: "#0E7AFE", fontWeight: "bold" }}>$49.99/yr</Button>
			// 		</StripeCheckout>
			// 		{/* 
			// 		<a style={{marginLeft: "5px"}} href="javascript:alert('This is a test sandbox so you will not actually be charged. For the credit card enter 4242-4242-4242-4242, for expiration enter any future date and for CVC enter any three numbers.')">Help</a>
			// 		*/}
			// 	</div>;

			var subscribeUnlimitedButton =
				<div style={{ width: "100%" }}>
					<StripeCheckout
						name="MiraCheck"
						description="Pro Plan - Unlimited"
						image={"assets/checkmate_logo_only_no_border_space_128x128.png"}
						token={(token) => { self._onTokenUnlimited(token) }}
						stripeKey={stripeKey}
						amount={9999}
						email={self.props.user.email}
						ComponentClass="div"
					>
						<Button bsSize="large" disabled={(this.props.user.subscriptionPlan === "pro-plan-unlimited" || this.props.user.subscriptionPlan === "pro-plan-v5-itunes") ? "disabled" : false} style={{ color: "#0E7AFE", fontWeight: "bold" }}>$99.99 once</Button>
					</StripeCheckout>
					{/* 
					<a style={{marginLeft: "5px"}} href="javascript:alert('This is a test sandbox so you will not actually be charged. For the credit card enter 4242-4242-4242-4242, for expiration enter any future date and for CVC enter any three numbers.')">Help</a>
					*/}
				</div>;

			var subscribeUnlimitedButtonV4 =
				<div style={{ width: "100%" }}>
					<StripeCheckout
						name="MiraCheck"
						description="Pro Plan - Unlimited"
						image={"assets/checkmate_logo_only_no_border_space_128x128.png"}
						token={(token) => { self._onTokenUnlimitedV4(token) }}
						stripeKey={stripeKey}
						amount={19999}
						email={self.props.user.email}
						ComponentClass="div"
					>
						<Button bsSize="large" disabled={(this.props.user.subscriptionPlan === "pro-plan-unlimited" || this.props.user.subscriptionPlan === "pro-plan-v5-itunes") ? "disabled" : false} style={{ color: "#0E7AFE", fontWeight: "bold" }}>$199.99 Once</Button>
					</StripeCheckout>
					{/* 
					<a style={{marginLeft: "5px"}} href="javascript:alert('This is a test sandbox so you will not actually be charged. For the credit card enter 4242-4242-4242-4242, for expiration enter any future date and for CVC enter any three numbers.')">Help</a>
					*/}
				</div>;

			var subscribeUnlimitedButtonDiscount =
				<div style={{ width: "100%" }}>
					<StripeCheckout
						name="MiraCheck"
						description="Pro Plan - Unlimited"
						image={"assets/checkmate_logo_only_no_border_space_128x128.png"}
						token={(token) => { self._onTokenUnlimitedDiscount(token) }}
						stripeKey={stripeKey}
						amount={6999}
						email={self.props.user.email}
						ComponentClass="div"
					>
						<Button bsSize="large" style={{ color: "#0E7AFE", fontWeight: "bold" }}>$69.99 once</Button>
					</StripeCheckout>
					{/* 
					<a style={{marginLeft: "5px"}} href="javascript:alert('This is a test sandbox so you will not actually be charged. For the credit card enter 4242-4242-4242-4242, for expiration enter any future date and for CVC enter any three numbers.')">Help</a>
					*/}
				</div>;

			var hasDiscount = false;

			var subscribeProYearlyButtonComponent;
			if (this.props.urlParams && this.props.urlParams.planType != null && this.props.urlParams.planType.toLowerCase() === "pppy-20-dollars-off-per-year") {
				subscribeProYearlyButtonComponent = subscribeProYearlyButton20DollarsOffPerYear;
				hasDiscount = true;
			} else if (this.props.urlParams && this.props.urlParams.planType != null && this.props.urlParams.planType.toLowerCase() === "pppy-50-dollars-off-first-year") {
				subscribeProYearlyButtonComponent = subscribeProYearlyButton50DollarsOffFirstYear;
				hasDiscount = true;
			} else {
				subscribeProYearlyButtonComponent = subscribeProYearlyButton;
			}

			var subscribeProPlanUnlimitedButton;
			if (this.props.urlParams && this.props.urlParams.planType != null && this.props.urlParams.planType.toLowerCase() === "ppu4") {
				subscribeProPlanUnlimitedButton = subscribeUnlimitedButtonV4;
				hasDiscount = true;
			} else if (this.props.urlParams && this.props.urlParams.planType != null && this.props.urlParams.planType.toLowerCase() === "ppu1") {
				subscribeProPlanUnlimitedButton = subscribeUnlimitedButton;
				hasDiscount = true;
			}

			subscribeProPlanUnlimitedButton = subscribeUnlimitedButton;
			hasDiscount = true;

			var proPlan =
				<div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Community Search to discover <b>free</b> checklist content</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Ability to purchase premium CheckMate® content</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Advanced Cloud Editor for customizing checklist content</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Ability to share checklist creations publicly</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Mobile app for interacting with checklist procedures</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Multi-tasking support to overlay checklist on any app</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Checkbar - Check, Skip, Locate, Emergency </span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Advanced layouts (Vertical, Simple, Big Check Overlay)</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Advanced CSV file import/export</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px", color: "lightgray" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Night Mode</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}><b>Voice-enabled CoPilot:</b> Cutting edge voice recognition that "just works" as well as voice narration of checklist items, emergency procedures and detailed comments.</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}><b>Bluetooth Media Controls:</b> Control the procedure with any smartwatch like the Garmin D2 line or Apple Watch.</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}><b>Quick Toobar:</b> Quick access to key functions such as voice recognition and audio narration.</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}><b>Advanced Item Types:</b> Checkbox, Photo/Video Upload, Sketchpad, Picker, Yes/No, Date/Time, GPS Address/Location, Weather, more to come!</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Filter Tags - Filter Items, Sections and Lists based on tags (e.g. IFR, Night)</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Print checklists from a variety of templates</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px", color: "lightgray" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Multiple, Persistent Timers</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px", color: "lightgray" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Geolocation tracking</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px", color: "lightgray" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>History of all checklist sessions</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px", color: "lightgray" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Connect Goose to EFB</span>
						</div>
					</div>
				</div>;

			var performancePlan =
				<div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Community Search to discover <b>free</b> checklist content</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Ability to purchase premium CheckMate® content</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Advanced Cloud Editor for customizing checklist content</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Ability to share checklist creations publicly</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Mobile app for interacting with checklist procedures</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Multi-tasking support to overlay checklist on any app</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Checkbar - Check, Skip, Locate, Emergency </span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Advanced layouts (Vertical, Simple, Big Check Overlay)</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Advanced CSV file import/export</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Night Mode</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}><b>Voice-enabled CoPilot:</b> Cutting edge voice recognition that "just works" as well as voice narration of checklist items, emergency procedures and detailed comments.</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}><b>Bluetooth Media Controls:</b> Control the procedure with any smartwatch like the Garmin D2 line or Apple Watch.</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}><b>Quick Toobar:</b> Quick access to key functions such as voice recognition and audio narration.</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}><b>Advanced Item Types:</b> Checkbox, Photo/Video Upload, Sketchpad, Picker, Yes/No, Date/Time, GPS Address/Location, Weather, more to come!</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Filter Tags - Filter Items, Sections and Lists based on tags (e.g. IFR, Night)</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Print checklists from a variety of templates</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Multiple Persistent Timers</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Geolocation tracking</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>History of all checklist sessions</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Connect Goose to EFB</span>
						</div>
					</div>
				</div>;

			// var standardPlan =
			// 	<div>
			// 		<div style={{ textAlign: "left", marginBottom: "8px" }}>
			// 			<div style={{display: "flex", alignItems: "center"}}>
			// 				<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Community Search to discover <b>free</b> checklist content</span>
			// 			</div>
			// 		</div>
			// 		<div style={{ textAlign: "left", marginBottom: "8px" }}>
			// 			<div style={{display: "flex", alignItems: "center"}}>
			// 				<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Ability to purchase premium CheckMate® content</span>
			// 			</div>
			// 		</div>
			// 		<div style={{ textAlign: "left", marginBottom: "8px" }}>
			// 			<div style={{display: "flex", alignItems: "center"}}>
			// 				<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Advanced Cloud Editor for customizing checklist content</span>
			// 			</div>
			// 		</div>
			// 		<div style={{ textAlign: "left", marginBottom: "8px" }}>
			// 			<div style={{display: "flex", alignItems: "center"}}>
			// 				<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Ability to share checklist creations publicly</span>
			// 			</div>
			// 		</div>
			// 		<div style={{ textAlign: "left", marginBottom: "8px" }}>
			// 			<div style={{display: "flex", alignItems: "center"}}>
			// 				<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Mobile app for interacting with checklist procedures</span>
			// 			</div>
			// 		</div>
			// 		<div style={{ textAlign: "left", marginBottom: "8px" }}>
			// 			<div style={{display: "flex", alignItems: "center"}}>
			// 				<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Multi-tasking support to overlay checklist on any app</span>
			// 			</div>
			// 		</div>
			// 		<div style={{ textAlign: "left", marginBottom: "8px" }}>
			// 			<div style={{display: "flex", alignItems: "center"}}>
			// 				<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Checkbar - Check, Skip, Locate, Emergency </span>
			// 			</div>
			// 		</div>
			// 		<div style={{ textAlign: "left", marginBottom: "8px" }}>
			// 			<div style={{display: "flex", alignItems: "center"}}>
			// 				<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Advanced layouts (Vertical, Simple, Big Check Overlay)</span>
			// 			</div>
			// 		</div>
			// 		<div style={{ textAlign: "left", marginBottom: "8px" }}>
			// 			<div style={{display: "flex", alignItems: "center"}}>
			// 				<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Advanced CSV file import/export</span>
			// 			</div>
			// 		</div>
			// 		<div style={{ textAlign: "left", marginBottom: "8px" }}>
			// 			<div style={{display: "flex", alignItems: "center"}}>
			// 				<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Night Mode</span>
			// 			</div>
			// 		</div>
			// 		<div style={{ textAlign: "left", marginBottom: "8px", color: "lightgray" }}>
			// 			<div style={{display: "flex", alignItems: "center"}}>
			// 				<i className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Voice-enabled CoPilot: Cutting edge voice recognition that "just works" as well as voice narration of checklist items, emergency procedures and detailed comments</span>
			// 			</div>
			// 		</div>
			// 		<div style={{ textAlign: "left", marginBottom: "8px", color: "lightgray" }}>
			// 			<div style={{display: "flex", alignItems: "center"}}>
			// 				<i className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}><b>Bluetooth Media Controls:</b> Control the procedure with any smartwatch like the Garmin D2 line or Apple Watch.</span>
			// 			</div>
			// 		</div>
			// 		<div style={{ textAlign: "left", marginBottom: "8px", color: "lightgray" }}>
			// 			<div style={{display: "flex", alignItems: "center"}}>
			// 				<i className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}><b>Quick Toobar:</b> Quick access to key functions such as voice recognition and audio narration.</span>
			// 			</div>
			// 		</div>
			// 		<div style={{ textAlign: "left", marginBottom: "8px", color: "lightgray" }}>
			// 			<div style={{display: "flex", alignItems: "center"}}>
			// 				<i className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}><b>Advanced Item Types:</b> Checkbox, Photo/Video Upload, Sketchpad, Picker, Yes/No, Date/Time, GPS Address/Location, Weather, more to come!</span>
			// 			</div>
			// 		</div>
			// 		<div style={{ textAlign: "left", marginBottom: "8px", color: "lightgray" }}>
			// 			<div style={{display: "flex", alignItems: "center"}}>
			// 				<i className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Filter Tags - Filter Items, Sections and Lists based on tags (e.g. IFR, Night)</span>
			// 			</div>
			// 		</div>
			// 		<div style={{ textAlign: "left", marginBottom: "8px", color: "lightgray" }}>
			// 			<div style={{display: "flex", alignItems: "center"}}>
			// 			<i className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Print checklists from a variety of templates</span>
			// 			</div>
			// 		</div>
			// 		<div style={{ textAlign: "left", marginBottom: "8px", color: "lightgray" }}>
			// 			<div style={{display: "flex", alignItems: "center"}}>
			// 				<i className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Multiple, Persistent Timers</span>
			// 			</div>
			// 		</div>
			// 		<div style={{ textAlign: "left", marginBottom: "8px", color: "lightgray" }}>
			// 			<div style={{display: "flex", alignItems: "center"}}>
			// 				<i className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Geolocation tracking</span>
			// 			</div>
			// 		</div>
			// 		<div style={{ textAlign: "left", marginBottom: "8px", color: "lightgray" }}>
			// 			<div style={{display: "flex", alignItems: "center"}}>
			// 				<i className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>History of all checklist sessions</span>
			// 			</div>
			// 		</div>
			// 	</div>;

			var basicPlan =
				<div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Community Search to discover <b>free</b> checklist content</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Ability to purchase premium CheckMate® content</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Advanced Cloud Editor for customizing checklist content</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Ability to share checklist creations publicly</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i style={{ color: "rgb(255,181,63)" }} className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Mobile app for interacting with checklist procedures</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px", color: "lightgray" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Multi-tasking support to overlay checklist on any app</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px", color: "lightgray" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Checkbar - Check, Skip, Locate, Emergency </span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px", color: "lightgray" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Advanced layouts (Vertical, Simple, Big Check Overlay)</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px", color: "lightgray" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Advanced CSV file import/export</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px", color: "lightgray" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Night Mode</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px", color: "lightgray" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Voice-enabled CoPilot: Cutting edge voice recognition that "just works" as well as voice narration of checklist items, emergency procedures and detailed comments</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px", color: "lightgray" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}><b>Bluetooth Media Controls:</b> Control the procedure with any smartwatch like the Garmin D2 line or Apple Watch.</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px", color: "lightgray" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}><b>Quick Toobar:</b> Quick access to key functions such as voice recognition and audio narration.</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px", color: "lightgray" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}><b>Advanced Item Types:</b> Checkbox, Photo/Video Upload, Sketchpad, Picker, Yes/No, Date/Time, GPS Address/Location, Weather, more to come!</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px", color: "lightgray" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Filter Tags - Filter Items, Sections and Lists based on tags (e.g. IFR, Night)</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px", color: "lightgray" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Print checklists from a variety of templates</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px", color: "lightgray" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Multiple, Persistent Timers</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px", color: "lightgray" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Geolocation tracking</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px", color: "lightgray" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>History of all checklist sessions</span>
						</div>
					</div>
					<div style={{ textAlign: "left", marginBottom: "8px", color: "lightgray" }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i className="fa fa-check-circle fa-lg"></i><span style={{ marginLeft: "15px" }}>Connect Goose to EFB</span>
						</div>
					</div>
				</div>;

			var headerPanel;

			if (this.props.lastLocation != null) {
				headerPanel =
					<Flexbox flexDirection="row" alignItems="center" justifyContent="space-between">
						<HomeLink />
						<Button style={{ width: "150px", marginRight: "20px" }} onClick={(e) => {
							browserHistory.push(this.props.lastLocation.pathname);
						}}>Return</Button>
					</Flexbox>
			} else {
				headerPanel =
					<HomeLink />;
			}

			if (this.state.showCheckout) {
				return (
					<PreCheckout params={this.state.checkoutParams} onReturn={() => {
						this.setState({
							showCheckout: false
						});
					}} />
				)
			} else {
				return (
					<Flexbox className="myBackground" flexDirection="column">
						{headerPanel}
						<Flexbox flexDirection="column" alignItems="center" justifyContent="center" flexGrow={1}>
							<Alerts />
							{/* <h3 style={{ color: "#eeeeee", padding: "10px" }}>Your Current Plan</h3>
								<div>{subDesc}</div>
								<div>{subExp}</div>
							</div> */}


							{/* <div style={{ marginTop: "25px", marginBottom: "25px", marginLeft: "10px", marginRight: "10px", padding: "20px", borderColor: "rgb(256,176,56)", borderStyle: "solid", borderWidth: "3px", borderRadius: "20px", backgroundColor: "rgb(30,39,43)", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
								<div style={{ fontSize: "36px", marginBottom: "15px", textAlign: "center" }}>3 Months Performance Trial</div>
								<p style={{ textAlign: "center" }}>for only $4.99 you can access EVERY FEATURE in Goose - Your Digital Co-Pilot<br />Introductory offer - for new customers only!</p>
								<Button bsSize="large" style={{ borderRadius: "10px", margin: "20px", backgroundColor: "rgb(255,181,63)", color: "black", fontWeight: "bold" }} onClick={(e) => {
									e.preventDefault();

									this.setState({
										showCheckout: true,
										checkoutParams: {
											ui_mode: "embedded",
											// redirect_on_completion: "if_required",
											line_items: [
												{
													price: "pro-plan-performance-as-intro-499",
													quantity: 1
												},
												{
													price: "pro-plan-performance-as-yearly-v4",
													quantity: 1
												}
											],
											mode: "subscription",
											return_url: `${globals.hostUrl}/perfTrialReturn?session_id={CHECKOUT_SESSION_ID}`,
											// automatic_tax: { "enabled": true },
											subscription_data: {
												trial_period_days: 90,
												trial_settings: {
													end_behavior: {
														missing_payment_method: "cancel"
													}
												}
											}
										}
									})
								}}>$4.99&nbsp;&nbsp;&nbsp;GO PERFORMANCE - RISK FREE</Button>
								<p style={{ textAlign: "center" }}>3 months risk free trial for $4.99 - you can cancel anytime!<br />The plan auto-renews to the yearly plan for $36/year giving you all the features and the best value.</p>
							</div> */}

							{/* <h3 style={{ color: "lightgreen", padding: "10px" }}>Cyber Monday Special! $199.99 for Pro Plan (Lifetime Unlimited)</h3>
							<Flexbox style={{marginBottom: 20}} flexDirection="row" alignItems="center" justifyContent="center">
								{subscribeProPlanUnlimitedButton}
							</Flexbox> */}

							<div style={{ textAlign: "center", fontSize: "36px", marginBottom: "15px", marginLeft: "10px", marginRight: "10px" }}>
								{/* <h2 style={{ color: "#eeeeee", padding: "0px" }}>Your Current Plan</h2> */}
								<div style={{ display: "flex", flexDirection: "column", margin: "0px", alignItems: "center", justifyContent: "center", color: "white", fontFamily: "Syne" }}>
									<h2>{subDesc}</h2>
									<h3>{subExp}</h3>

									{/* {(this.props.user.subscriptionPlan === "pro-plan-monthly" || this.props.user.subscriptionPlan === "pro-plan-yearly" || this.props.user.subscriptionPlan === "standard-plan-per-year" || this.props.user.subscriptionPlan === "pro-plan-per-year" || this.props.user.subscriptionPlan.startsWith("pro-plan-as") || this.props.user.subscriptionPlan.startsWith("pro-plan-performance-as")) &&
									<div style={{marginTop: "20px"}}>{freeButton}</div>
								} */}
								</div>
							</div>
							<Flexbox flexDirection="row" alignItems="center" justifyContent="center" flexWrap="wrap">
								<Flexbox style={{ marginTop: "10px" }} flexDirection="column" alignItems="center" justifyContent="center">
									<Flexbox flexDirection="column" style={{ alignItems: "center", justifyContent: "center", maxWidth: "360px", width: "100%", borderRadius: "20px 20px 0px 0px", padding: "5px", backgroundColor: "#1F272B", color: "white" }}>
										{!hasDiscount && <h3 style={{ color: "#FDFDFD" }}>Pro Plan - <span style={{ textDecoration: "line-through" }}>$119/yr</span> <span style={{ color: "lightgreen" }}>$99/yr</span></h3>}
										{hasDiscount && <h3 style={{ color: "#FDFDFD" }}>Performance</h3>}
										<div className="text-center" style={{ marginBottom: "5px" }}>Manage unlimited checklists</div>
										<div className="text-center" style={{ marginBottom: "20px" }}>Sync across 3 devices</div>
									</Flexbox>
									<div style={{ maxWidth: "360px", width: "100%", margin: "0px", padding: "10px", borderRadius: "0px 0px 20px 20px", backgroundColor: "#1F272B", overflow: "auto", textAlign: "center" }}>
										<Flexbox flexDirection="row" alignItems="center" justifyContent="space-around">
											{/* {subscribeMonthlyButton}
											{subscribeProYearlyButtonComponent} */}
											{/* {subscribeProPlanUnlimitedButton} */}
											{/* {subscribe5DollarPerformancePlanButton} */}
											{subscribePerformanceYearlyButton}
											{subscribePerformanceMonthlyButton}


										</Flexbox>
										<br />
										{performancePlan}
									</div>
								</Flexbox>
								<div style={{ width: "10px" }} />
								<Flexbox style={{ marginTop: "10px" }} flexDirection="column" alignItems="center" justifyContent="center">
									<Flexbox flexDirection="column" style={{ alignItems: "center", justifyContent: "center", maxWidth: "360px", width: "100%", borderRadius: "20px 20px 0px 0px", padding: "5px", backgroundColor: "#1F272B", color: "white" }}>
										{!hasDiscount && <h3 style={{ color: "#FDFDFD" }}>Pro Plan - <span style={{ textDecoration: "line-through" }}>$119/yr</span> <span style={{ color: "lightgreen" }}>$99/yr</span></h3>}
										{hasDiscount && <h3 style={{ color: "#FDFDFD" }}>Pro</h3>}
										<div className="text-center" style={{ marginBottom: "5px" }}>Manage 5 checklists</div>
										<div className="text-center" style={{ marginBottom: "20px" }}>Sync across 2 devices</div>
									</Flexbox>
									<div style={{ maxWidth: "360px", width: "100%", margin: "0px", padding: "10px", borderRadius: "0px 0px 20px 20px", backgroundColor: "#1F272B", overflow: "auto", textAlign: "center" }}>
										<Flexbox flexDirection="row" alignItems="center" justifyContent="space-around">
											{subscribeProYearlyButton}
											{subscribeMonthlyButton}

											{/* {subscribeProPlanUnlimitedButton} */}

										</Flexbox>
										<br />
										{proPlan}
									</div>
								</Flexbox>
								<div style={{ width: "10px" }} />
								{/* <Flexbox style={{marginTop: "10px"}} flexDirection="column" alignItems="center" justifyContent="center">
									<Flexbox flexDirection="column" style={{ alignItems: "center", justifyContent: "center", maxWidth: "360px", width: "100%", borderRadius: "10px 10px 0px 0px", padding: "5px", backgroundColor: "rgb(67,78,90)", color: "white"}}>
										<h3>Standard Plan - $49/yr</h3>
										<div className="text-center" style={{ marginBottom: "5px" }}>Manage unlimited checklists</div>
										<div className="text-center" style={{ marginBottom: "20px" }}>Sync across 3 devices</div>
									</Flexbox>
									<div style={{ maxWidth: "360px", width: "100%", margin: "0px", padding: "10px", borderRadius: "0px 0px 10px 10px", backgroundColor: "white", overflow: "auto", textAlign: "center" }}>
										{standardPlan}
										<br />
										<Flexbox flexDirection="row" alignItems="center" justifyContent="center">
											{subscribeStandardYearlyButton}
										</Flexbox>
									</div>
								</Flexbox>	
								<div style={{width: "10px"}}/> */}
								<Flexbox style={{ marginTop: "10px" }} flexDirection="column" alignItems="center" justifyContent="center">
									<Flexbox flexDirection="column" style={{ alignItems: "center", justifyContent: "center", maxWidth: "360px", width: "100%", borderRadius: "20px 20px 0px 0px", padding: "5px", backgroundColor: "#1F272B", color: "white" }}>
										<h3 style={{ color: "#FDFDFD" }}>Free</h3>
										<div className="text-center" style={{ marginBottom: "5px" }}>Manage 2 checklists</div>
										<div className="text-center" style={{ marginBottom: "20px" }}>Sync across 1 device</div>
									</Flexbox>
									<div style={{ maxWidth: "360px", width: "100%", margin: "0px", padding: "10px", borderRadius: "0px 0px 20px 20px", backgroundColor: "#1F272B", overflow: "auto", textAlign: "center" }}>
										<Flexbox flexDirection="row" alignItems="center" justifyContent="space-around">
											{freeButton}
										</Flexbox>
										<br />
										{basicPlan}
									</div>
								</Flexbox>
							</Flexbox>
							{cancelButton}
							<div style={{ color: "white", padding: "20px", textAlign: "center", width: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
								{this.props.version.copyright}.<br />{this.props.version.prefix}&nbsp;{this.props.version.major}.{this.props.version.minor}.{this.props.version.revision}.{this.props.version.build}&nbsp;{this.props.version.suffix}
							</div>
						</Flexbox>

					</Flexbox>
				);

			}
		}
	}
}

export default branch({
	lastLocation: ["appState", "lastLocation"],
	user: ["user"],
	version: ["version"],
	urlParams: ["appState", "urlParams"]
}, Subscribe);