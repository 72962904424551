import React from "react";
import marked from "marked";
import * as utils from "../utils/utils.js";

class ChecklistNotes extends React.PureComponent {
	createMarkup(label) {
		let myRenderer = new marked.Renderer();
		myRenderer.link = function (href, title, text) {
			let external, newWindow, out;
			external = /^https?:\/\/.+$/.test(href);
			newWindow = external || title === "newWindow";
			out = "<a href=\"" + href + "\"";
			if (newWindow) {
				out += " target=\"_blank\"";
			}
			if (title && title !== "newWindow") {
				out += " title=\"" + title + "\"";
			}
			return out += ">" + text + "</a>";
		};

		myRenderer.image = function (href, title, text) {
			return "<img style=\"max-width: 100%\" src=\"" + href + "\" /><br/><div>" + text + "</div>";
		};

		// myRenderer.paragraph = function (text) {
		// 	return text;
		// };

		marked.setOptions({
			renderer: myRenderer,
			gfm: true,
			tables: true,
			breaks: true,
			pedantic: false,
			sanitize: false,
			smartLists: true,
			smartypants: false
		});

		return { __html: marked(label) };
	}

	render() {
		let self = this;

		let notes = [];
		utils.recurseExtractNotes(self.props.checklist.children, notes, 0, 0);

		let noteItems = <div>There are no notes for this activity.</div>;

		if (notes.length > 0) {
			noteItems = notes.map(function (noteItem) {
				let newList = self.props.checklist.children[noteItem.listIndex];
				let newSection = newList.children[noteItem.sectionIndex];

				return (
					<div style={{ padding: "10px", marginBottom: "10px", backgroundColor: '#333333', borderRadius: 5 }}>
						<b>{noteItem.notes}</b>
						<hr />
						Appears in {newList.name} / {newSection.name}<br />
						<div dangerouslySetInnerHTML={self.createMarkup(noteItem.label1)} />
						<div dangerouslySetInnerHTML={self.createMarkup(noteItem.label2)} />
						{noteItem.hasOwnProperty("label3") && <div dangerouslySetInnerHTML={self.createMarkup(noteItem.label3)} />}
					</div>
				);
			});
		}

		let title;
		if (this.props.showTitle) {
			title = <h4>Notes</h4>;
		}

		return (
			<div style={{margin: 5}}>
				{title}
				{noteItems}
			</div>	
		);
	}
}

export default ChecklistNotes;