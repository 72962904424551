import React from 'react';
import ReactDOM from 'react-dom';

import {branch} from 'baobab-react/higher-order';
import PropTypes from 'baobab-react/prop-types';
import * as actions from '../actions/actions';
import * as actionsPortal from '../actions/portalActions';

import globals from '../constants/globals.js';

import { WithContext as ReactTags } from 'react-tag-input';

import {Button, Checkbox, Panel, Popover, OverlayTrigger} from 'react-bootstrap';

import AfPanel from '../components/shared/AfPanel';
import AfHelp from '../components/shared/AfHelp';

import Glyphicon from '../components/app-icon';
import { SketchPicker } from 'react-color';

//import shallowCompare from 'react-addons-shallow-compare';

import MediaPanel from '../components/app-editor-media-panel';
import PrintProperties from '../components/itemComponents/PrintProperties';

import Flexbox from 'flexbox-react';
import MediaQuery from "react-responsive";

import * as utils from '../utils/utils';

import { pathToArray } from "../utils/utils";

class EditorSection extends React.PureComponent{
	constructor(props, context) {
		super(props, context);
		this.handleChangeId = this.handleChangeId.bind(this);
		this.handleChangeLabel = this.handleChangeLabel.bind(this);
		this.handleChangeVisible = this.handleChangeVisible.bind(this);
		this.handleChangeLabelAlexaPlain = this.handleChangeLabelAlexaPlain.bind(this);
		this.handleChangeLabelAlexaRich = this.handleChangeLabelAlexaRich.bind(this);
		this.handleChangeLabelAudio = this.handleChangeLabelAudio.bind(this);
		this.handleChangeLabelSsml = this.handleChangeLabelSsml.bind(this);
		this.handleChangeLabelPrint = this.handleChangeLabelPrint.bind(this);
		this.handleChangeColor = this.handleChangeColor.bind(this);
		this.handleChangeBackgroundColor = this.handleChangeBackgroundColor.bind(this);
		this.handleChangeBorderColor = this.handleChangeBorderColor.bind(this);
		this.handleChangeIconColor = this.handleChangeIconColor.bind(this);
		this.handleIgnoreCompletion = this.handleIgnoreCompletion.bind(this);
		this.handleHideStatus = this.handleHideStatus.bind(this);
		this.handleChangeSuppressAudio = this.handleChangeSuppressAudio.bind(this);
		this.handleChangeSuppressPrint = this.handleChangeSuppressPrint.bind(this);
		this.handleBlur = this.handleBlur.bind(this);

		if (window.innerWidth < 768) {
			this.state = {
				open: false,
				advancedOpen: false,
				inlineEdit: false
			};
		} else {
			this.state = {
				open: true,
				advancedOpen: false,
				inlineEdit: false
			};
		}

		this.immediateInlineClick = false;
	}
/*
	shouldComponentUpdate(nextProps, nextState) {
		return shallowCompare(this, nextProps, nextState);
	}
*/
	handleChangeId(e) {
		this.props.dispatch(
			actions.handleChangeProperty,
			"id",
			e.target.value
		);
	}

	handleChangeLabel(e) {
		this.props.dispatch(
			actions.handleChangeProperty,
			"label",
			e.target.value
		);
	}

	handleChangeVisible(e) {
		this.props.dispatch(
			actions.handleChangeProperty,
			"visible",
			e.target.checked
		);
	}

	handleChangeLabelAlexaPlain(e) {
		this.props.dispatch(
			actions.handleChangeProperty,
			"labelAlexaPlain",
			e.target.value
		);
	}

	handleChangeLabelAlexaRich(e) {
		this.props.dispatch(
			actions.handleChangeProperty,
			"labelAlexaRich",
			e.target.value
		);
	}

	handleChangeLabelAudio(e) {
		this.props.dispatch(
			actions.handleChangeProperty,
			"labelAudio",
			e.target.value
		);
	}

	handleChangeLabelSsml(e) {
		this.props.dispatch(
			actions.handleChangeProperty,
			"labelSsml",
			e.target.value
		);
	}

	handleChangeLabelPrint(e) {
		this.props.dispatch(
			actions.handleChangeProperty,
			"labelPrint",
			e.target.value
		);
	}

	handleChangeSuppressAudio(e) {
		this.props.dispatch(
			actions.handleChangeProperty,
			"suppressAudio",
			e.target.checked
		);
	}

	handleChangeColor(color, e) {
		this.props.dispatch(
			actions.handleChangeProperty,
			"color",
			color.hex
		);
		this.props.dispatch(
			actions.handleChangeProperty,
			"iconColor",
			color.hex
		);
	}

	handleChangeBackgroundColor(color, e) {
		this.props.dispatch(
			actions.handleChangeProperty,
			"backgroundColor",
			color.hex
		);
		this.props.dispatch(
			actions.handleChangeProperty,
			"borderColor",
			color.hex
		);
	}

	handleChangeBorderColor(color, e) {
		this.props.dispatch(
			actions.handleChangeProperty,
			"borderColor",
			color.hex
		);
	}

	handleChangeIconColor(color, e) {
		this.props.dispatch(
			actions.handleChangeProperty,
			"iconColor",
			color.hex
		);
	}

	handleIgnoreCompletion(e) {
		this.props.dispatch(
			actions.handleChangeProperty,
			"ignoreCompletion",
			e.target.checked
		);
	}

	handleHideStatus(e) {
		this.props.dispatch(
			actions.handleChangeProperty,
			"hideStatus",
			e.target.checked
		);
	}

	handleChangeSuppressPrint(e) {
		this.props.dispatch(
			actions.handleChangeProperty,
			"suppressPrint",
			e.target.checked
		);
	}

	handleBlur(e) {
		actions.saveUndoState();
	}

	handleOnFocus(e) {
		var inputLabel = ReactDOM.findDOMNode(this.refs.inputLabel);
		if (inputLabel.value === "(Section Name)") {
			inputLabel.setSelectionRange(0, inputLabel.value.length);
		}
	}	

/*
	componentDidMount() {
		var inputLabel = ReactDOM.findDOMNode(this.refs.inputLabel);
		inputLabel.focus();	
		if (inputLabel.value === "(Section Name)") {
			inputLabel.setSelectionRange(0, inputLabel.value.length);
		}	
	}
*/  

	componentDidMount() {
		// if (window.innerWidth < 768) {
		// } else {
			var inputLabel = ReactDOM.findDOMNode(this.refs.inputLabel);
			if (inputLabel && (inputLabel.value === "" || inputLabel.value === "(Section Name)")) {
				inputLabel.focus();	
				//inputLabel.setSelectionRange(0, inputLabel.value.length);
			}
		// }
	}

	componentDidUpdate(prevProps, prevState) {
		// if (window.innerWidth < 768) {
		// } else {
		// 	if (prevProps.selectedNodePath !== this.props.selectedNodePath) {
				var inputLabel = ReactDOM.findDOMNode(this.refs.inputLabel);
				if (inputLabel && (inputLabel.value === "" || inputLabel.value === "(Section Name)")) {
					inputLabel.focus();	
					//inputLabel.setSelectionRange(0, inputLabel.value.length);
				}
		// 	}
		// }
	}

	render() {
		var entity = this.props.node.entity;

		var inlineEditor;
		inlineEditor =
			<div style={{width: "100%", marginLeft: "5px", marginRight: "5px", backgroundColor: "white", color: "black", padding: "3px"}}>
				<input ref="inputLabel" style={{marginBottom: "15px"}} className="form-control" type="text" placeholder="(Section Name)" value={entity.label} onChange={this.handleChangeLabel} onClick={this.handleOnFocus.bind(this)} />
				<Button onClick={(e)=>{this.immediateInlineClick=false; this.setState({inlineEdit: false})}}><Glyphicon style={{fontSize: "16px", color: "green"}} glyph='check' /> OK</Button>	
			</div>;

			const close = <span><Glyphicon style={{ color: "green", fontSize: "20px" }} glyph='check' /> OK</span>;
			const edit = <span><Glyphicon style={{ fontSize: "20px" }} glyph='pencil' /></span>;
			var header = (
			<div style={{display: "flex", justifyContent: "space-between"}}>
				<div onClick={()=>{if (!this.immediateInlineClick && this.props.node.selected && this.props.provided && !this.state.inlineEdit) actions.handleToggleNode(this.context.tree.select(this.props.selectedNodePath))}} style={{display: "flex", alignItems: "center", width: "100%", marginTop: "5px", userSelect: "none",
					WebkitUserSelect: "none",
					MozUserSelect: "none",
					msUserSelect: "none"
					}}>
					<Glyphicon style={{fontSize: "32px", color: "#ffc153"}} glyph="table" />
					{!this.state.inlineEdit &&
						<div style={{marginLeft: "5px", marginRight: "5px"}} onClick={(e)=>{/*e.stopPropagation();*/this.immediateInlineClick=true; this.setState({inlineEdit: true})}}>{entity.label === "" ? "(Section Name)" : entity.label}</div>
					}
					{this.state.inlineEdit && inlineEditor}
				</div>
				{this.props.provided && !this.state.inlineEdit && <div style={{display: "flex", alignItems: "center"}}>
					<div style={{marginRight: 10}}>
						<Button style={{/*backgroundColor: "rgba(255,255,255,0.3)", */color: "#fdfdfd"}} onClick={ ()=> this.setState({ open: !this.state.open })}>{this.state.open?close:edit}</Button>
					</div>
					<div {...this.props.provided.dragHandleProps}>
						<Glyphicon style={{fontSize: "32px", color: "#ffc15388"}} glyph="sort" />
					</div>
				</div>}
			</div>
		)

		var popoverColor = 
		<Popover id="popover-section-name-color" title="Section Label Color">
			<SketchPicker
				disableAlpha={true}			
				color={ entity.color }
				onChangeComplete={ this.handleChangeColor }
				presetColors={globals.defaultColors}
			/>
		</Popover>;

		var popoverBackgroundColor = 
		<Popover id="popover-section-name-background-color" title="Section Label Background Color">
			<SketchPicker
				disableAlpha={true}			
				color={ entity.backgroundColor }
				onChangeComplete={ this.handleChangeBackgroundColor }
				presetColors={globals.defaultColors}
			/>
		</Popover>;

		var popoverBorderColor = 
		<Popover id="popover-section-name-border-color" title="Section Label Border Color">
			<SketchPicker
				disableAlpha={true}			
				color={ entity.borderColor }
				onChangeComplete={ this.handleChangeBorderColor }
				presetColors={globals.defaultColors}
			/>
		</Popover>;

		var popoverIconColor = 
		<Popover id="popover-section-name-icon-color" title="Section Label Icon Color">
			<SketchPicker
				disableAlpha={true}			
				color={ entity.iconColor }
				onChangeComplete={ this.handleChangeIconColor }
				presetColors={globals.defaultColors}
			/>
		</Popover>;

		var labelAlexaPlain, labelAlexaRich, labelSsml;
		if (this.props.urlParams.hasOwnProperty("mode") && this.props.urlParams.mode === "smartdisplay") {
			labelAlexaPlain =
			<div>
				<div>
					<label>Label Alexa Override (Plain Text)</label>
				</div>
				<div>
					<textarea style={{marginBottom: "15px"}} className="form-control" placeholder="(Optional: plain content to display on Alexa)" value={entity.labelAlexaPlain} onChange={this.handleChangeLabelAlexaPlain} />
				</div>
			</div>;
			
			labelAlexaRich =
			<div>
				<div>
					<label>Label Alexa Override (Rich Text)</label>
				</div>
				<div>
					<textarea style={{marginBottom: "15px"}} className="form-control" placeholder="(Optional: rich content to display on Alexa)" value={entity.labelAlexaRich} onChange={this.handleChangeLabelAlexaRich} />
				</div>
			</div>;

			labelSsml =
			<div>
				<div>
					<label>Label SSML Override</label>
				</div>
				<div>
					<textarea style={{marginBottom: "15px"}} className="form-control" placeholder="(Optional: content to have Alexa speak)" value={entity.labelSsml} onChange={this.handleChangeLabelSsml} />
				</div>
			</div>;
		}

		var advancedHeader = (
			<div style={{display: "flex"}}>
				<div>
					Advanced
				</div>
				{/* <div style={{float: "right"}}>
					<Button bsSize="small" onClick={ ()=> this.setState({ advancedOpen: !this.state.advancedOpen })}>{this.state.advancedOpen?close:edit}</Button>
				</div>
				<div style={{float: "none", clear: "both"}} /> */}
			</div>
		)

		let groupNameItems = entity.groupNames.map(function (groupName, i) {
			return (
				{
					id: i + 1,
					text: groupName
				}
			);
		});

		let groupNames =
			<div style={{marginTop: "15px"}}>
				<div>
					<AfHelp description={this.props.content.groupNames.description}>
						<label>{this.props.content.groupNames.name}</label>
					</AfHelp>
				</div>
				<div style={{ marginBottom: "2px" }}>
					<ReactTags
						placeholder="Enter a group name"
						tags={groupNameItems}
						suggestions={this.props.groupNames}
						handleDelete={(i) => {
							this.props.dispatch(
								actions.handleDeleteGroupName,
								i
							);
						}}
						handleAddition={(tag) => {
							this.props.dispatch(
								actions.handleAddGroupName,
								tag
							);
						}}
						autofocus={false} />
				</div>
				<Flexbox flexDirection="row">
					<Button disabled={groupNameItems.length===0} bsSize="small" onClick={ ()=> {
						this.props.dispatch(
							actions.handleCopyGroupNames
						)
					}}><Glyphicon glyph='copy' /></Button>
					<Button disabled={!global.hasOwnProperty("groupNames") || global.groupNames.length===0} bsSize="small" onClick={ ()=> {
						this.props.dispatch(
							actions.handlePasteGroupNames
						)
					}}><Glyphicon glyph='paste' /></Button>
				</Flexbox>
			</div>;

		let filterTagItems = entity.filterTags.map(function (filterTag, i) {
			return (
				{
					id: i + 1,
					text: filterTag
				}
			);
		});

		let filterTags = null;
		if (this.props.hasOwnProperty("user") && this.props.user.hasOwnProperty("subscriptionPlan") && this.props.user.subscriptionPlan.startsWith("pro-plan")) {
			filterTags =
			<div>
				<div>
					<AfHelp description={this.props.content.filterTags.description}>
						<label>{this.props.content.filterTags.name}</label>
					</AfHelp>
				</div>
				<div style={{ marginBottom: "2px" }}>
					<ReactTags
						placeholder="Enter a filter tag"
						tags={filterTagItems}
						suggestions={this.props.filterTags}
						handleDelete={(i) => {
							this.props.dispatch(
								actions.handleDeleteFilterTag,
								i
							);
						}}
						handleAddition={(tag) => {
							this.props.dispatch(
								actions.handleAddFilterTag,
								tag
							);
						}}
						autofocus={false} />
				</div>
				<Flexbox flexDirection="row">
					<Button disabled={filterTagItems.length===0} bsSize="small" onClick={ ()=> {
						this.props.dispatch(
							actions.handleCopyFilterTags
						)
					}}><Glyphicon glyph='copy' /></Button>
					<Button disabled={!global.hasOwnProperty("filterTags") || global.filterTags.length===0} style={{marginLeft: "5px"}} bsSize="small" onClick={ ()=> {
						this.props.dispatch(
							actions.handlePasteFilterTags
						)
					}}><Glyphicon glyph='paste' /></Button>
				</Flexbox>
			</div>;
		}

		return (
			<div style={{width: "100%", height: "100%"}}>
				<div style={{ display: "flex", alignItems: "center" }}>
					<MediaQuery query='(max-width: 767px)'>
						{(!this.state.inlineEdit && !this.state.open) &&
							<div style={{flex: 0, marginLeft: "5px", marginRight: "10px"}} onClick={() => actions.handleToggleNode(this.context.tree.select(pathToArray(this.props.path)))}>
								<Glyphicon style={{ color: this.props.node.selected ? "white" : "#D78770" }} glyph={this.props.node.expanded ? "minus" : "plus"} />
							</div>
						}
					</MediaQuery>
					<Panel style={{width: "100%", flex: 1, marginBottom: "0px"}} bsStyle="warning" defaultExpanded={this.state.open} expanded={this.state.open} onToggle={(e)=>{this.setState({open: !this.state.open})}}>
					<Panel.Heading style={{ borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px", padding: "5px 10px" }}>
						<Panel.Title>
							{header}
						</Panel.Title>
					</Panel.Heading>
					<Panel.Collapse>
						<Panel.Body>
							<div>
								<AfHelp description={this.props.content.title.description}>
									<label>{this.props.content.title.name}</label>
								</AfHelp>
							</div>
							<div>
								<input ref="inputLabel" className="form-control" type="text" placeholder="(Section Name)" value={entity.label} onChange={this.handleChangeLabel} onClick={this.handleOnFocus.bind(this)} />
							</div>
							<div>
								<AfHelp alignItems="center" description={this.props.content.suppressAudioTitle.description}>
									<Checkbox checked={entity.suppressAudioTitle === null || entity.suppressAudioTitle ? "checked" : ""} onChange={(e) => { this.props.dispatch(actions.handleChangeProperty, "suppressAudioTitle", e.target.checked); }}>
										{this.props.content.suppressAudioTitle.name}
									</Checkbox>
								</AfHelp>
							</div>
							<div style={{marginBottom: "15px"}}>
								<AfHelp alignItems="center" description={this.props.content.dontReadTitleSuffix.description}>
									<Checkbox checked={entity.suppressAudioEntityType === null || entity.suppressAudioEntityType ? "checked" : ""} onChange={(e) => { this.props.dispatch(actions.handleChangeProperty, "suppressAudioEntityType", e.target.checked); }}>
										{this.props.content.dontReadTitleSuffix.name}
									</Checkbox>
								</AfHelp>
							</div>
							<AfPanel defaultExpanded={entity.labelAudio && entity.labelAudio !== ""} title="Title Advanced">
								{labelAlexaPlain}
								{labelAlexaRich}					
								<AfHelp description={this.props.content.titleNarrationOverride.description}>
									<label>{this.props.content.titleNarrationOverride.name}</label>
								</AfHelp>
								<div style={{marginBottom: "15px"}}>
									<textarea style={{marginBottom: "5px"}} className="form-control" placeholder="(Optional: content to be spoken)" value={entity.labelAudio} onChange={this.handleChangeLabelAudio} />
									<Button bsSize="small" onClick={()=>{
										this.props.dispatch(
											actions.handleCopyPasteProperty,
											"label",
											"labelAudio"
										);												
									}}><Glyphicon glyph='paste' /> Paste Title</Button>
								</div>
								{labelSsml}
								<AfHelp description={this.props.content.titlePrintOverride.description}>
									<label>{this.props.content.titlePrintOverride.name}</label>
								</AfHelp>
								<div>
									<textarea style={{marginBottom: "5px"}} className="form-control" placeholder="(Optional: content to be printed)" value={entity.labelPrint} onChange={this.handleChangeLabelPrint} />
									<Button bsSize="small" onClick={()=>{
										this.props.dispatch(
											actions.handleCopyPasteProperty,
											"label",
											"labelPrint"
										);												
									}}><Glyphicon glyph='paste' /> Paste Title</Button>
								</div>
							</AfPanel>

							<div>
								<AfHelp description={this.props.content.id.description}>
									<label>{this.props.content.id.name}</label>
								</AfHelp>	
							</div>
							<div>
								<input ref="inputId" style={{marginBottom: "15px"}} className="form-control" type="text" placeholder="(Optional: ID)" value={entity.id} onChange={this.handleChangeId} />
							</div>

							<AfPanel defaultExpanded={false} title="Heading Style">
								<div>
									<AfHelp description={this.props.content.fontColor.description}>
										<label>{this.props.content.fontColor.name}</label>
									</AfHelp>
								</div>
								<div style={{marginBottom: "10px"}}>
									<OverlayTrigger trigger="click" rootClose placement="top" overlay={popoverColor}>
										<div style={{cursor: "pointer", border: "2px solid black", borderRadius: "5px", width: "32px", height: "32px", backgroundColor: entity.color}} />
									</OverlayTrigger>
								</div>
								<div>
									<AfHelp description={this.props.content.iconColor.description}>
										<label>{this.props.content.iconColor.name}</label>
									</AfHelp>
								</div>
								<div style={{marginBottom: "10px"}}>
									<OverlayTrigger trigger="click" rootClose placement="top" overlay={popoverIconColor}>
										<div style={{cursor: "pointer", border: "2px solid black", borderRadius: "5px", width: "32px", height: "32px", backgroundColor: entity.iconColor}} />
									</OverlayTrigger>
								</div>
								<div>
									<AfHelp description={this.props.content.backgroundColor.description}>
										<label>{this.props.content.backgroundColor.name}</label>
									</AfHelp>
								</div>
								<div style={{marginBottom: "10px"}}>
									<OverlayTrigger trigger="click" rootClose placement="top" overlay={popoverBackgroundColor}>
										<div style={{cursor: "pointer", border: "2px solid black", borderRadius: "5px", width: "32px", height: "32px", backgroundColor: entity.backgroundColor}} />
									</OverlayTrigger>
								</div>
								<div>
									<AfHelp description={this.props.content.borderColor.description}>
										<label>{this.props.content.borderColor.name}</label>
									</AfHelp>
								</div>
								<div style={{marginBottom: "15px"}}>
									<OverlayTrigger trigger="click" rootClose placement="top" overlay={popoverBorderColor}>
										<div style={{cursor: "pointer", border: "2px solid black", borderRadius: "5px", width: "32px", height: "32px", backgroundColor: entity.borderColor}} />
									</OverlayTrigger>
								</div>
							</AfPanel>

							<div>
								<AfHelp alignItems="center" description={this.props.content.visible.description}>
									<Checkbox checked={entity.visible === null || entity.visible ? "checked" : ""} onChange={this.handleChangeVisible}>
										{this.props.content.visible.name}
									</Checkbox>
								</AfHelp>
							</div>
							<div>
								<AfHelp alignItems="center" description={this.props.content.ignoreCompletion.description}>
									<Checkbox checked={entity.ignoreCompletion==null || entity.ignoreCompletion ? "checked" : ""} onChange={this.handleIgnoreCompletion}>
										{this.props.content.ignoreCompletion.name}
									</Checkbox>
								</AfHelp>
							</div>
							<div>
								<AfHelp alignItems="center" description={this.props.content.hideStatus.description}>
									<Checkbox checked={entity.hideStatus==null || entity.hideStatus ? "checked" : ""} onChange={this.handleHideStatus}>
										{this.props.content.hideStatus.name}
									</Checkbox>
								</AfHelp>
							</div>

							<MediaPanel node={this.props.node} />							
							<AfPanel style={{marginTop: "25px", marginBottom: "0px"}} bsStyle="success" title="Advanced">
								<div>
									<AfHelp description={this.props.content.id.description}>
										<label>{this.props.content.id.name}</label>
									</AfHelp>	
								</div>
								<div>
									<input ref="inputId" style={{marginBottom: "15px"}} className="form-control" type="text" placeholder="(Optional: ID)" value={entity.id} onChange={this.handleChangeId} />
								</div>
								{filterTags}
								{groupNames}
								<div style={{marginTop: "25px", marginBottom: "0px"}}>
									<PrintProperties entity={entity} />
								</div>
								
								
								
								<div>
									<AfHelp alignItems="center" description={this.props.content.suppressAudio.description}>
										<Checkbox checked={entity.suppressAudio==null || entity.suppressAudio ? "checked" : ""} onChange={this.handleChangeSuppressAudio}>
											{this.props.content.suppressAudio.name}
										</Checkbox>
									</AfHelp>	
								</div>
								<div>
									<AfHelp alignItems="center" description={this.props.content.suppressPrint.description}>
										<Checkbox checked={entity.suppressPrint==null || entity.suppressPrint ? "checked" : ""} onChange={this.handleChangeSuppressPrint}>
											{this.props.content.suppressPrint.name}
										</Checkbox>
									</AfHelp>	
								</div>
							</AfPanel>								
						</Panel.Body>					
					</Panel.Collapse>					
				</Panel>
				</div>
			</div>
		);
	}
}

EditorSection.contextTypes = {
	tree: PropTypes.baobab
};

export default branch({
	user: ['user'],
	selectedNodePath: ['selectedNodePath'],
	urlParams: ['appState','urlParams'],
	groupNames: ["groupNames"],
	filterTags: ["filterTags"],
	content: ['content','section','properties','properties']
}, EditorSection);