import React from 'react';
import ReactDOM from 'react-dom';
import browserHistory from '../../history';
import {branch} from 'baobab-react/higher-order';
import * as actions from '../../actions/portalActions';
import * as awsS3 from '../../persistence/s3';
import * as utils from '../../utils/utils';

import {Button} from 'react-bootstrap';

import Flexbox from 'flexbox-react';

import Loader from 'react-loader-advanced';
import FontAwesome from 'react-fontawesome';

class LoggedIn extends React.Component{
	logout() {
		actions.logout();
	};

	refreshChecklistsLoader() {
		this.props.dispatch(utils.showLoader);
		setTimeout(this.refreshChecklists.bind(this),300);
	}

	refreshChecklists() {
		this.props.dispatch(
			awsS3.getAllChecklists
		);
	}

	componentDidMount() {
		if (this.props.user && this.props.user.loggedIn) {
			browserHistory.push('/home');
		}
	}

	render() {
		const spinner = 
			<span>
				<FontAwesome
					name='spinner'
					size='4x'
					spin
			/>
			</span>;
		return (
			<Loader show={this.props.loading} message={spinner}>
				<Flexbox className="myBackground" flexDirection="column">
					<Flexbox className="myBackground" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
						<h4 style={{color: "#eeeeee", padding:"25px"}}>{this.props.user.username} is logged in.</h4>
						<Button bsStyle="primary" onClick={this.refreshChecklistsLoader.bind(this)}>Sync Checklists</Button>
						<br/>
						<Button bsStyle="primary" onClick={this.logout.bind(this)}>Logout</Button>
					</Flexbox>	
				</Flexbox>
			</Loader>	
		);
	}
}

export default branch({
	user: ['user'],
	loggedIn: ['user','loggedIn'],
	loading: ['appState','loading']
}, LoggedIn);
