import React from 'react';
import {FormControl} from 'react-bootstrap';

class MediaType extends React.PureComponent {
    render() {
        return (
            <FormControl componentClass="select" value={this.props.value} onChange={this.props.onChange}>
                <option value="photo">Photo</option>
                <option value="video">Video</option>
                <option value="mixed">Both Photo and Video (iOS Only)</option>
            </FormControl>
        )    
    }
}

export default MediaType;