import React from "react";
import globals from "../constants/globals.js";
import { branch } from "baobab-react/higher-order";
import PropTypes from "baobab-react/prop-types";
import * as actions from "../actions/actions";
import * as actionsPortal from "../actions/portalActions";
import * as awsS3 from "../persistence/s3";
import * as algolia from "../search/algolia";
import { Button, Dropdown, MenuItem, Glyphicon, Panel } from "react-bootstrap";

import Card from "../components/card/app-card.js";
import Alerts from "../components/app-alerts.js";

import FontAwesome from "react-fontawesome";

import Flexbox from "flexbox-react";
import browserHistory from "../history";

import NavLinks from "./navPanel/app-nav-links.js";
import NavPanel from "./navPanel/app-nav-panel.js";

import { WithContext as ReactTags } from "react-tag-input";

import MediaQuery from "react-responsive";

//import shallowCompare from 'react-addons-shallow-compare';

import StripeCheckout from "react-stripe-checkout";

import Modal from "react-responsive-modal";

import ChecklistReadOnly from "./app-checklist-read-only.js";

import * as utils from "../utils/utils";

import Loader from "react-loader-advanced";

class Home extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    if (!props.user.loggedIn) {
      actions.forceLogin(browserHistory, this.props.location.pathname);
    } else {
      this.handleBuy = this.handleBuy.bind(this);
      this.handleAdd = this.handleAdd.bind(this);
      this.openPreviewModal = this.openPreviewModal.bind(this);
      this.closePreviewModal = this.closePreviewModal.bind(this);
    }

    this.state = {
      newsOpen: false,
      featuredOpen: true,
      premiumOpen: true,
      endorsedOpen: true,
      communityOpen: true,
      checkMateOpen: false,
      simulatorOpen: false,
      featuredSnippetsOpen: false,
      miscOpen: false,
    };
  }

  _add(hit) {
    awsS3.clonePublicDocument(hit);
  }

  _onToken = (token, hit) => {
    awsS3.saveStripeToken(token, hit);
  };

  handleBuy(item) {}

  handleAdd(item) {
    awsS3.clonePublicDocument(item);
  }

  closePreviewModal() {
    this.props.dispatch(actions.closePreviewModal);
  }

  openPreviewModal(hit) {
    this.props.dispatch(actions.openPreviewModal, hit);
  }
  /*
		shouldComponentUpdate(nextProps, nextState) {
			return shallowCompare(this, nextProps, nextState);
		}
	*/
  getHits(searchHits, type) {
    var self = this;
    var hits = searchHits.map(function (hit, i) {
      var menuItems = [
        <MenuItem
          key={"menuitem1"}
          eventKey="1"
          onClick={(e) => {
            e.stopPropagation();
            self.openPreviewModal(hit);
          }}
        >
          Preview
        </MenuItem>,
      ];

      var addButton;
      if (hit.publisher === "checkmate") {
        var addButtonCloud = (
          <div style={{ display: "flex" }}>
            <StripeCheckout
              name="MiraCheck"
              description={hit.name}
              image={"assets/checkmate_logo_only_no_border_space_128x128.png"}
              token={(token) => {
                self._onToken(token, hit);
              }}
              stripeKey={
                globals.stage === "prod"
                  ? globals.stripeKeyProd
                  : globals.stripeKeyDev
              }
              amount={2000}
              email={self.props.user.email}
              ComponentClass="div"
            >
              <Button style={{ color: "#FCB31F", fontWeight: "bold" }}>
                Buy
              </Button>
            </StripeCheckout>
            {/* 
					<a style={{marginLeft: "5px"}} href="javascript:alert('This is a test sandbox so you will not actually be charged. For the credit card enter 4242-4242-4242-4242, for expiration enter any future date and for CVC enter any three numbers.')">?</a>
					*/}
          </div>
        );

        var addButtonMobile = (
          <div style={{ display: "flex" }}>
            <Button
              onClick={(e) => {
                alert(
                  "To purchase premium content please login to the MiraCheck website through a web browser."
                );
              }}
              style={{ color: "#0E7AFE", fontWeight: "bold" }}
            >
              Info
            </Button>
          </div>
        );

        // Probably better way to know if launched from mobile app
        // var mobile = false;

        // try {
        // 	window.webkit.messageHandlers.reactNative.postMessage(JSON.stringify({ method: "dummy" }));
        // 	mobile = true;
        // } catch (e) {
        // }
        // // UIWebView
        // try {
        // 	window.postMessage(JSON.stringify({ method: "dummy" }));
        // 	mobile = true;
        // } catch (e) {
        // }

        // if (utils.isMobile()) {
        // 	addButton = addButtonMobile;
        // } else {
        addButton = addButtonCloud;
        // }
      } else {
        addButton = (
          <Button
            onClick={() => {
              self._add(hit);
            }}
          >
            Add
          </Button>
        );
      }

      var menuComponent = (
        <Dropdown pullRight id="dropdown-card-menu">
          <Dropdown.Toggle
            noCaret
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Glyphicon style={{ color: "#fdfdfd" }} glyph="option-vertical" />
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ opacity: 0.95 }}>{menuItems}</Dropdown.Menu>
        </Dropdown>
      );

      var card = (
        <Card
          title={hit.name}
          // title={hit.name + "; " + hit.id}
          description={hit.description}
          contributors={
            hit.hasOwnProperty("contributors") ? hit.contributors : []
          }
          privacy="public"
          menuComponent={menuComponent}
          disabled={false}
          visible={true}
          genres={self.props.genres}
          genre={hit.genre}
          onClick={(e) => {}}
          addButton={addButton}
          compact={true}
          image={hit.hasOwnProperty("image") ? hit.image : []}
        ></Card>
      );

      return <div key={type + "Hit" + i}>{card}</div>;
    });

    return hits;
  }

  render() {
    var self = this;
    if (!this.props.user.loggedIn) {
      return <div />;
    } else {
      var self = this;
      var modalStyle = {
        modal: {
          width: "75%",
          maxWidth: 800,
          position: "relative",
          padding: "1.2rem",
          background: "#0000ff",
          backgroundClip: "padding-box",
          boxShadow: "0 12px 15px 0 rgba(0,0,0,0.25)",
        },
      };

      var previewModal;
      previewModal = (
        <Modal
          classNames={{ modal: "custom-modal" }}
          open={this.props.previewChecklist.showModal}
          onClose={this.closePreviewModal}
          little
        >
          <h3 style={{ textAlign: "center" }}>Preview</h3>
          <div style={{ height: "70vh", overflow: "auto" }}>
            <ChecklistReadOnly
              checklist={this.props.previewChecklist.checklist}
              preview={true}
            />
          </div>
        </Modal>
      );

      var featuredHits = this.getHits(
        this.props.search.currentlyInFeatured,
        "featured"
      );
      var premiumHits = this.getHits(this.props.search.premium, "premium");
      var endorsedHits = this.getHits(this.props.search.endorsed, "endorsed");
      var communityHits = this.getHits(this.props.search.featured, "featured");
      var checkMateHits = this.getHits(
        this.props.search.checkmate,
        "checkmate"
      );
      var simulatorHits = this.getHits(
        this.props.search.featuredSims,
        "featuredSims"
      );
      var featuredSnippetsHits = this.getHits(
        this.props.search.featuredSnippets,
        "featuredSnippets"
      );
      var miscHits = this.getHits(this.props.search.misc, "misc");

      var header = (
        <div>
          <div style={{ marginTop: "5px" }}>
            MiraCheck Mission&nbsp;
            <Glyphicon glyph="info-sign" />
          </div>
        </div>
      );

      var chevronDown = (
        <span>
          <Glyphicon glyph="chevron-down" />
        </span>
      );
      var chevronUp = (
        <span>
          <Glyphicon glyph="chevron-up" />
        </span>
      );

      const panelRowSection = (
        <div className="panel-row-section">
          <div className="panel-row-container">
            {/* First Panel */}
            <div
              className="panel-container panel-container-50 col-md-3 col-sm-12"
              style={{ padding: 0, paddingRight: 10 }}
            >
              <Panel defaultExpanded bsStyle="primary">
                <Panel.Heading
                  style={{ backgroundColor: "royalblue", color: "white" }}
                >
                  <Panel.Title style={{ textAlign: "center" }}>
                    Save Time - Checklist Import Service
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Collapse>
                  <Panel.Body>
                    <p>
                      Save time and let us help you import your checklists,
                      procedures, and even your entire POH to Goose!
                    </p>
                    <p style={{ textAlign: "center", fontWeight: "bold" }}>
                      <a
                        style={{ color: "#FCB31F" }}
                        href="mailto:support@aerosys.io?subject=Request%20Quote%20for%20Importing%20Procedures%20to%20Goose&body=Dear%20Goose%20Support%2C%0A%0Acan%20you%20please%20send%20me%20a%20quote%20for%20importing%20my%20attached%20procedures%20%26%20checklists%20to%20Goose%3F%0A%0A%3Cstrong%3E%3Cfont%20color%3D%22red%22%3EPlease%20add%20picture%20or%20pdf%20of%20the%20procedures%20you%20like%20the%20quote%20for.%3C%2Ffont%3E%3C%2Fstrong%3E"
                      >
                        Request a Quote
                      </a>
                    </p>
                    <p>
                      Starting for as little as $25, we build YOUR procedures.
                      Send us the checklist and documents you want imported, and
                      we will reply with a quote within 24 hours.
                    </p>
                  </Panel.Body>
                </Panel.Collapse>
              </Panel>
            </div>
            {/* Second Panel */}
            {/* <div
              className="panel-container panel-container-25 col-md-3 col-sm-12"
              style={{ padding: 0 }}
            >
              <Panel defaultExpanded bsStyle="primary">
                <Panel.Heading
                  style={{
                    backgroundColor: "#FCB31F",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <Panel.Title style={{ textAlign: "center" }}>
                    Meet Goose @ EAA AirVenture 2024
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Collapse>
                  <Panel.Body>
                    <h4
                      style={{
                        margin: 0,
                        marginBottom: 5,
                        marginTop: 5,
                        fontWeight: "bold",
                        fontFamily: "Syne",
                        color: "#FCB31F",
                        textAlign: "center",
                      }}
                    >
                      Co-Pilot Goose Hangar!
                    </h4>
                    <h4
                      style={{
                        margin: 0,
                        marginBottom: 20,
                        fontWeight: "bold",
                        fontFamily: "Syne",
                        color: "#FCB31F",
                        textAlign: "center",
                      }}
                    >
                      Booth #2162 & #258A
                    </h4>
                    <p style={{ fontWeight: "bold" }}>
                      Goose is the proud sponsor of the Hangar B during the EAA
                      AirVenture!
                    </p>
                    <p>
                      Visit us and our many events, forums and press conferences
                      for many amazing news & announcements.
                    </p>
                  </Panel.Body>
                </Panel.Collapse>
              </Panel>
            </div> */}

            {/* Third Panel */}
            <div
              className="panel-container panel-container-50 col-md-6 col-sm-12"
              style={{ padding: 0, paddingLeft: 10 }}
            >
              <Panel defaultExpanded bsStyle="primary">
                <Panel.Heading
                  style={{ backgroundColor: "royalblue", color: "white" }}
                >
                  <Panel.Title style={{ textAlign: "center" }}>
                    AeroSys Ambassadors and Partners
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Collapse>
                  <Panel.Body style={{ minHeight: "100px" }}>
                    <div style={{ textAlign: 'center', paddingTop: "35px" }}>Coming Soon!</div>
                    {/* <div className="special-highligh-box">
                      <div className="special-highlight-image"> */}
                        {/* <img
                          src="/assets/2024-AirVenture-4cSponsorGlyph-CMYK.jpg"
                          alt="EAA AirVenture Supporter Logo"
                        /> */}
                      {/* </div>
                      <div
                        className="special-highlight-content"
                        style={{
                          width: "225px",
                        }}
                      >
                        <div
                          style={{
                            borderBottom: "solid 1px #fff",
                            paddingBottom: "4px",
                            paddingTop: "4px",
                            fontFamily: "syne",
                            fontWeight: "bold",
                          }}
                        >
                          NOTAM based
                        </div>

                        <div
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            paddingBottom: "8px",
                            paddingTop: "8px",
                          }}
                        >
                          AirVenture 2024 Official Fly-In Procedures
                        </div>
                        {/* <div>
                          Get the fly-in procedures and official NOTAM by FAA
                          and briefings & informtaion by the EAA{" "}
                        </div> */}
                        {/* <button
                          onClick={(e) =>
                            utils.openUrl(
                              e,
                              "https://checklist.miracheck.com"
                            )
                          }
                          style={{
                            width: "100%",
                            borderRadius: "40px",
                            borderColor: "#fcb31f",
                            background: "#fcb31f",
                            color: "#000",
                            fontWeight: "bold",
                          }}
                        >
                          EAA AirVenture 2024
                        </button>
                      </div>
                    </div> */} 
                  </Panel.Body>
                </Panel.Collapse>
              </Panel>
            </div>
          </div>
        </div>
      );

      var newsTitle = (
        <div onClick={() => this.setState({ newsOpen: !this.state.newsOpen })}>
          <div style={{ marginTop: "5px", float: "left" }}>
            General News & Latest Information
          </div>
          <div style={{ float: "right" }}>
            <Button bsSize="small">
              {this.state.newsOpen ? chevronUp : chevronDown}
            </Button>
          </div>
          <div style={{ float: "none", clear: "both" }} />
        </div>
      );

      var featuredHeader = (
        <div
          onClick={() =>
            this.setState({ featuredOpen: !this.state.featuredOpen })
          }
        >
          <div style={{ marginTop: "5px", float: "left" }}>
            Handpicked Selection of Special Content
          </div>
          <div style={{ float: "right" }}>
            <Button bsSize="small">
              {this.state.featuredOpen ? chevronUp : chevronDown}
            </Button>
          </div>
          <div style={{ float: "none", clear: "both" }} />
        </div>
      );

      var premiumHeader = (
        <div
          onClick={() =>
            this.setState({ premiumOpen: !this.state.premiumOpen })
          }
        >
          <div style={{ marginTop: "5px", float: "left" }}>
            Goose Original's Premium Checklist & Smart Procedures
          </div>
          <div style={{ float: "right" }}>
            <Button bsSize="small">
              {this.state.premiumOpen ? chevronUp : chevronDown}
            </Button>
          </div>
          <div style={{ float: "none", clear: "both" }} />
        </div>
      );
      var endorsedHeader = (
        <div
          onClick={() =>
            this.setState({ endorsedOpen: !this.state.endorsedOpen })
          }
        >
          <div style={{ marginTop: "5px", float: "left" }}>
            Endorsed Checklist and Procedures - Endoresed by 3rd Parties or
            direct POH Versions{" "}
          </div>
          <div style={{ float: "right" }}>
            <Button bsSize="small">
              {this.state.endorsedOpen ? chevronUp : chevronDown}
            </Button>
          </div>
          <div style={{ float: "none", clear: "both" }} />
        </div>
      );

      var communityHeader = (
        <div
          onClick={() =>
            this.setState({ communityOpen: !this.state.communityOpen })
          }
        >
          <div style={{ marginTop: "5px", float: "left" }}>
            Community Checklists - Made by Fellow Aviators
          </div>
          <div style={{ float: "right" }}>
            <Button bsSize="small">
              {this.state.communityOpen ? chevronUp : chevronDown}
            </Button>
          </div>
          <div style={{ float: "none", clear: "both" }} />
        </div>
      );

      var simulatorHeader = (
        <div
          onClick={() =>
            this.setState({ simulatorOpen: !this.state.simulatorOpen })
          }
        >
          <div style={{ marginTop: "5px", float: "left" }}>
            Flight Simulator Community Procedures
          </div>
          <div style={{ float: "right" }}>
            <Button bsSize="small">
              {this.state.simulatorOpen ? chevronUp : chevronDown}
            </Button>
          </div>
          <div style={{ float: "none", clear: "both" }} />
        </div>
      );

      var checkMateHeader = (
        <div
          onClick={() =>
            this.setState({ checkMateOpen: !this.state.checkMateOpen })
          }
        >
          <div style={{ marginTop: "5px", float: "left" }}>
            CheckMate Aviation Originals - Digital Copies of CheckMate Cards
          </div>
          <div style={{ float: "right" }}>
            <Button bsSize="small">
              {this.state.checkMateOpen ? chevronUp : chevronDown}
            </Button>
          </div>
          <div style={{ float: "none", clear: "both" }} />
        </div>
      );

      var featuredSnippetsHeader = (
        <div
          onClick={() =>
            this.setState({
              featuredSnippetsOpen: !this.state.featuredSnippetsOpen,
            })
          }
        >
          <div style={{ marginTop: "5px", float: "left" }}>
            Aviation Related Snippets & Examples
          </div>
          <div style={{ float: "right" }}>
            <Button bsSize="small">
              {this.state.featuredSnippetsOpen ? chevronUp : chevronDown}
            </Button>
          </div>
          <div style={{ float: "none", clear: "both" }} />
        </div>
      );

      var miscHeader = (
        <div onClick={() => this.setState({ miscOpen: !this.state.miscOpen })}>
          <div style={{ marginTop: "5px", float: "left" }}>
            Miscellaneous Community Content & Checklist
          </div>
          <div style={{ float: "right" }}>
            <Button bsSize="small">
              {this.state.miscOpen ? chevronUp : chevronDown}
            </Button>
          </div>
          <div style={{ float: "none", clear: "both" }} />
        </div>
      );

      var featuredPanel = (
        <Panel defaultExpanded={true} bsStyle="info">
          <Panel.Heading style={{ borderRadius: "10px" }}>
            <Panel.Title toggle>{featuredHeader}</Panel.Title>
          </Panel.Heading>
          <Panel.Collapse>
            <Panel.Body>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    maxWidth: "800px",
                    textAlign: "left",
                    backgroundColor: "#666666",
                    border: "1px solid #666666",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  We manually update this section to always have the most
                  current and relevant content from all the checklist down below
                  displayed up here on the top.
                </div>
              </div>
              <MediaQuery query="(max-width: 1089px)">
                <Flexbox
                  padding="10px"
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="center"
                >
                  {featuredHits}
                </Flexbox>
              </MediaQuery>
              <MediaQuery query="(min-width: 1090px)">
                <Flexbox
                  padding="10px"
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="flex-start"
                >
                  {featuredHits}
                </Flexbox>
              </MediaQuery>
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      );

      var premiumPanel = (
        <Panel defaultExpanded={true} bsStyle="info">
          <Panel.Heading style={{ borderRadius: "10px" }}>
            <Panel.Title toggle>{premiumHeader}</Panel.Title>
          </Panel.Heading>
          <Panel.Collapse>
            <Panel.Body>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    maxWidth: "800px",
                    textAlign: "left",
                    backgroundColor: "#666666",
                    border: "1px solid #666666",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  Our team of experienced pilots and engineers is constantly
                  building complex and advanced checklists and templates for you
                  to get the full experience out of YOUR digital co-pilot. These
                  checklists are meant for you to use, update and learn from.
                  You can modify them in full or partially, copy and re-build
                  them. Please note, that these checklists are copyrighted
                  content and to be used by the customer only. You will not be
                  able to share purchased content.
                </div>
              </div>
              <MediaQuery query="(max-width: 1089px)">
                <Flexbox
                  padding="10px"
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="center"
                >
                  {premiumHits}
                </Flexbox>
              </MediaQuery>
              <MediaQuery query="(min-width: 1090px)">
                <Flexbox
                  padding="10px"
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="flex-start"
                >
                  {premiumHits}
                </Flexbox>
              </MediaQuery>
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      );

      var endorsedPanel = (
        <Panel defaultExpanded={true} bsStyle="info">
          <Panel.Heading style={{ borderRadius: "10px" }}>
            <Panel.Title toggle>{endorsedHeader}</Panel.Title>
          </Panel.Heading>
          <Panel.Collapse>
            <Panel.Body>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    maxWidth: "800px",
                    textAlign: "left",
                    backgroundColor: "#666666",
                    border: "1px solid #666666",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  We as are team are making a great effort to provide you with
                  as many certified and approved procedures (e.g. the original
                  POH checklist) as possible. Please note, since you are
                  purchasing copyrighted content, you will not be able to share
                  publicly any customisations you make, or print the checklist.
                </div>
              </div>
              <MediaQuery query="(max-width: 1089px)">
                <Flexbox
                  padding="10px"
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="center"
                >
                  {endorsedHits}
                </Flexbox>
              </MediaQuery>
              <MediaQuery query="(min-width: 1090px)">
                <Flexbox
                  padding="10px"
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="flex-start"
                >
                  {endorsedHits}
                </Flexbox>
              </MediaQuery>
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      );

      var communityPanel = (
        <Panel defaultExpanded={true} bsStyle="success">
          <Panel.Heading style={{ borderRadius: "10px" }}>
            <Panel.Title toggle>{communityHeader}</Panel.Title>
          </Panel.Heading>
          <Panel.Collapse>
            <Panel.Body>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    maxWidth: "800px",
                    textAlign: "left",
                    backgroundColor: "#666666",
                    border: "1px solid #666666",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  Goose - Your Digital Co-PIlot is an ecosystem for the
                  community to create aircraft, checklists & procedures. We
                  highly encourage the sharing of those checklists. Here are
                  some of the checklists Goose pilots have created. If you want
                  to find more, you can go to Search to browse everything that’s
                  available. Please cross-check the content, to make sure these
                  procedures reflect both your personal and aircraft’s needs. On
                  that note: Please help the community by creating your
                  checklists and share them with other pilots through making
                  them public! Thanks.
                </div>
              </div>
              <MediaQuery query="(max-width: 1089px)">
                <Flexbox
                  padding="10px"
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="center"
                >
                  {communityHits}
                </Flexbox>
              </MediaQuery>
              <MediaQuery query="(min-width: 1090px)">
                <Flexbox
                  padding="10px"
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="flex-start"
                >
                  {communityHits}
                </Flexbox>
              </MediaQuery>
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      );

      var checkMatePanel = (
        <Panel defaultExpanded={false} bsStyle="info">
          <Panel.Heading style={{ borderRadius: "10px" }}>
            <Panel.Title toggle>{checkMateHeader}</Panel.Title>
          </Panel.Heading>
          <Panel.Collapse>
            <Panel.Body>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    maxWidth: "800px",
                    textAlign: "left",
                    backgroundColor: "#666666",
                    border: "1px solid #666666",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  We have partnered with CheckMate Aviation to bring their world
                  class checklist content to Goose - Your Digital Co-Pilot. If
                  you prefer the proven track record of a company like CheckMate
                  you can now purchase a digital copy of the original CheckMate
                  plastic card. There are currently over 100 airplanes available
                  for purchase for $20. Since you are purchasing copyrighted
                  content, you will not be able to share publicly any
                  customisations you make, nor print the checklist.{" "}
                </div>
              </div>
              <MediaQuery query="(max-width: 1089px)">
                <Flexbox
                  padding="10px"
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="center"
                >
                  {checkMateHits}
                </Flexbox>
              </MediaQuery>
              <MediaQuery query="(min-width: 1090px)">
                <Flexbox
                  padding="10px"
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="flex-start"
                >
                  {checkMateHits}
                </Flexbox>
              </MediaQuery>
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      );

      var simulatorPanel = (
        <Panel defaultExpanded={false} bsStyle="success">
          <Panel.Heading style={{ borderRadius: "10px" }}>
            <Panel.Title toggle>{simulatorHeader}</Panel.Title>
          </Panel.Heading>
          <Panel.Collapse>
            <Panel.Body>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    maxWidth: "800px",
                    textAlign: "left",
                    backgroundColor: "#666666",
                    border: "1px solid #666666",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  These checklist are designed for simulation use. They may
                  serve as starting points for your own airplane, but if used in
                  aircraft operations outside the simulator, please cross-check
                  the content with your POH.{" "}
                </div>
              </div>
              <MediaQuery query="(max-width: 1089px)">
                <Flexbox
                  padding="10px"
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="center"
                >
                  {simulatorHits}
                </Flexbox>
              </MediaQuery>
              <MediaQuery query="(min-width: 1090px)">
                <Flexbox
                  padding="10px"
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="flex-start"
                >
                  {simulatorHits}
                </Flexbox>
              </MediaQuery>
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      );

      var featuredSnippetsPanel = (
        <Panel defaultExpanded={false} bsStyle="success">
          <Panel.Heading style={{ borderRadius: "10px" }}>
            <Panel.Title toggle>{featuredSnippetsHeader}</Panel.Title>
          </Panel.Heading>
          <Panel.Collapse>
            <Panel.Body>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    maxWidth: "800px",
                    textAlign: "left",
                    backgroundColor: "#666666",
                    border: "1px solid #666666",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  These are checklist just snippets and examples - mostly
                  without context. If needed, add them to My Hangar. From there
                  you can copy and paste them into your own aircraft where
                  needed. We provided a detailed description on how to copy a
                  snipped in the linked post in our forum. <br />
                  <br />
                  <a
                    onClick={(e) =>
                      utils.openUrl(
                        e,
                        "https://forum.miralouaero.com/t/adding-a-snippet-to-a-checklist/270/2?u=admin"
                      )
                    }
                    href="javascript:void(0)"
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    How to Copy a Snippet
                  </a>
                </div>
              </div>
              <MediaQuery query="(max-width: 1089px)">
                <Flexbox
                  padding="10px"
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="center"
                >
                  {featuredSnippetsHits}
                </Flexbox>
              </MediaQuery>
              <MediaQuery query="(min-width: 1090px)">
                <Flexbox
                  padding="10px"
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="flex-start"
                >
                  {featuredSnippetsHits}
                </Flexbox>
              </MediaQuery>
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      );

      var miscPanel = (
        <Panel defaultExpanded={false} bsStyle="success">
          <Panel.Heading style={{ borderRadius: "10px" }}>
            <Panel.Title toggle>{miscHeader}</Panel.Title>
          </Panel.Heading>
          <Panel.Collapse>
            <Panel.Body>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    maxWidth: "800px",
                    textAlign: "left",
                    backgroundColor: "#666666",
                    border: "1px solid #666666",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  In this section you can find anything from Camping Checklists
                  for the EAA AirVenture to safe boating. This is a collection
                  to demonstrate Goose' versatility
                </div>
              </div>
              <MediaQuery query="(max-width: 1089px)">
                <Flexbox
                  padding="10px"
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="center"
                >
                  {miscHits}
                </Flexbox>
              </MediaQuery>
              <MediaQuery query="(min-width: 1090px)">
                <Flexbox
                  padding="10px"
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="flex-start"
                >
                  {miscHits}
                </Flexbox>
              </MediaQuery>
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      );

      var newsPanel = (
        <Panel defaultExpanded={false} bsStyle="primary">
          <Panel.Heading
            style={{
              borderRadius: "10px",
              backgroundColor: "royalblue",
              color: "white",
            }}
          >
            <Panel.Title toggle>{newsTitle}</Panel.Title>
          </Panel.Heading>
          <Panel.Collapse>
            <Panel.Body>
              <p>Welcome to Goose - Your Digital Co-Pilot.</p>
              <p>
                With new releases and updates coming up almost weekly, we
                suggest to follow our{" "}
                <a href="https://twitter.com/AeroSys_Group">X/Twitter feed</a>,
                in which we give high-level updates on new capabilities,
                partnerships and topics.
              </p>
              <p>
                I you want to learn more on how to interact with Goose, make
                sure to follow our{" "}
                <a href="https://www.youtube.com/@Goose.TalkToMe">
                  YouTube channel @Goose.TalkToMe
                </a>
                .
              </p>
              <p>
                For a sneak peak behind the scenes, follow our{" "}
                <a href="Instagram page @Goose.TalkToMe">
                  Instagram page @Goose.TalkToMe
                </a>
                .
              </p>
              <p>Happy Landings!</p>
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      );

      var newsPanel,
        panel1,
        panel2,
        panel3,
        panel4,
        panel5,
        panel6,
        panel7,
        panel8;
      if (
        this.props.urlParams.partner != null &&
        this.props.urlParams.partner.toLowerCase() === "checkmate"
      ) {
        newsPanel = newsPanel;
        panel1 = featuredPanel;
        panel2 = premiumPanel;
        panel3 = endorsedPanel;
        panel4 = communityPanel;
        panel5 = checkMatePanel;
        panel6 = simulatorPanel;
        panel7 = featuredSnippetsPanel;
        panel8 = miscPanel;
      } else {
        newsPanel = newsPanel;
        panel1 = featuredPanel;
        panel2 = premiumPanel;
        panel3 = endorsedPanel;
        panel4 = communityPanel;
        panel5 = checkMatePanel;
        panel6 = simulatorPanel;
        panel7 = featuredSnippetsPanel;
        panel8 = miscPanel;
      }

      var home;
      home = (
        <div style={{ padding: "10px" }}>
          {/* <Panel bsStyle="primary">
						<Panel.Heading>
							<Panel.Title toggle>
								MiraCheck Mission&nbsp;<Glyphicon glyph="info-sign" />
							</Panel.Title>
						</Panel.Heading>
						<Panel.Collapse>
							<Panel.Body>
								<p style={{ fontSize: "16pt", fontWeight: "bold", fontStyle: "italic" }}>The future of checklists is here!</p>
								<p>Technology is at the intersection of so many ground-breaking technologies, from the cloud, to mobile, to voice assistants, to computers on our wrist. <b>MiraCheck</b> combines all of these innovations to unleash the potential of such a simple concept.</p>
								<p><b>Goose Cloud</b> is where you <i>discover</i>, <i>create</i>, <i>edit</i> and <i>share</i> checklist content.</p>
								<p>Goose Cloud aims to be a central hub for the community to share their expertise. It has robust editing tools as well as a powerful search engine to easily discover checklist procedures.</p>
								<p>The ultimate goal of a tool like MiraCheck is to reduce human-error and enable people on new procedures in the most efficient way possible.</p>
								<p>The ultimate vision is learning checklists. When you are initially learning a new procedure there is a lot of detail and it walks you through the process in a very methodical way. Basically, it can act as a performance support tool for real-time on-the-job training. As you gain more experience, then the checklist acts more as an accountability tool and as a tool to reduce mistakes.</p>
								<p>For this reason, checklist content for procedures should constantly be improved, and there are many features around this premise. Every time you interact with a checklist a session is created and historical record of all actions. This history can help you identify the flaws in procedures to improve them for the future. This history can also act as an accountability mechanism.</p>
								<p>The companion mobile app <b>MiraCheck CoPilot</b> is where you interact with all the great content created on Goose Cloud. Digital assistants are becoming commonplace, and MiraCheck CoPilot embraces this trend. It utilizes voice (Mira) to try to combat the complacency that can occur when you check the same items over and over. It was designed to take you through the content in the most efficient way possible. It also provides a framework for much richer checklist content. Items can be textual content, URL links, images and videos. More detailed rich content can also be associated with each item.</p>
							</Panel.Body>
						</Panel.Collapse>
					</Panel> */}
          {newsPanel}

          {panelRowSection}
          {!this.props.igapp && (
            <div style={{ textAlign: "center" }}>
              <h2>Browse Existing Aircraft, Checklists & Procedures</h2>
              <p>
                Purchase premium content, choose open-source checklists provided
                by the community and fellow pilots and browse special procedures
              </p>
            </div>
          )}
          {/* {panel1} */}
          {/* {panel2} */}
          {/* {panel3} */}
          {panel4}
          {panel5}
          {panel6}
          {panel7}
          {/* {panel8} */}
          {!this.props.igapp && (
            <div class="creditFooter">
              MiraLou Aero LLC - 2017 - 2024 || an{" "}
              <a
                href="https://aerosys.io"
                style={{ color: "white", textDecoration: "none" }}
              >
                AeroSys Technologies
              </a>{" "}
              Company
            </div>
          )}
        </div>
      );

      const spinner = <FontAwesome name="spinner" size="4x" spin />;

      return (
        <Loader show={this.props.loadingSpinner} message={spinner}>
          <Flexbox
            flexDirection="column"
            height="100vh"
            style={{ minHeight: 0 }}
          >
            <Alerts />
            <NavLinks />
            <MediaQuery query="(max-width: 599px)">
              {/* <div onInit={() => this.setState({ newsOpen: !this.state.newsOpen })}/>  */}
              <Flexbox
                flex="1"
                flexDirection="row"
                width="100vw"
                style={{ minHeight: 0 }}
              >
                <Flexbox
                  style={{ overflowY: "auto", width: "100%" }}
                  padding="10px"
                  flexDirection="column"
                  flexGrow={1}
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  {home}
                </Flexbox>
              </Flexbox>
            </MediaQuery>
            <MediaQuery query="(min-width: 600px)">
              <Flexbox
                flex="1"
                flexDirection="row"
                width="100vw"
                style={{ minHeight: 0 }}
              >
                <MediaQuery query="(min-width: 768px)">
                  <NavPanel />
                </MediaQuery>
                <Flexbox
                  style={{ overflowY: "auto", width: "100%" }}
                  padding="10px"
                  flexDirection="column"
                  flexGrow={1}
                  justifyContent="flex-start"
                >
                  {home}
                </Flexbox>
              </Flexbox>
            </MediaQuery>
            {previewModal}
          </Flexbox>
        </Loader>
      );
    }
  }

  componentDidMount() {
    this.context.tree.select(["appState", "navPanel"]).set("selected", "home");
  }
}

Home.contextTypes = {
  tree: PropTypes.baobab,
};

export default branch(
  {
    urlParams: ["appState", "urlParams"],
    user: ["user"],
    genres: ["genres"],
    search: ["search"],
    recent: ["recent"],
    previewChecklist: ["appState", "previewChecklist"],
    loadingSpinner: ["appState", "loadingSpinner"],
    igapp: ["appCapabilities", "igapp"],
  },
  Home
);
