import React, { Component } from 'react';

export class ProfileView extends Component {
	constructor(props, context) {
		super(props, context);

    var maxAltitude = 0;

		var mToFt = 3.28084;
    
    var relativeAltitudeFt = props.historyGeolocation[0].coords.relativeAltitude * mToFt;
    var lastRelativeAltitudeFt = relativeAltitudeFt;

    var initialAltitude = props.historyGeolocation[0].coords.altitude * mToFt;

    this.profilePointsBarometricString = "0," + Math.round(relativeAltitudeFt + initialAltitude);
		for (var i=1; i<props.historyGeolocation.length; i++) {
      relativeAltitudeFt = props.historyGeolocation[i].coords.relativeAltitude * mToFt;

      // This test if reset the relative altitude
      if (Math.abs(relativeAltitudeFt - lastRelativeAltitudeFt) > 100) {
        initialAltitude = props.historyGeolocation[i].coords.altitude * mToFt;
      }

      lastRelativeAltitudeFt = relativeAltitudeFt;

			this.profilePointsBarometricString = this.profilePointsBarometricString + " " + i + "," + Math.round(relativeAltitudeFt + initialAltitude);

      if (Math.round(relativeAltitudeFt + initialAltitude) > maxAltitude) {
        maxAltitude = Math.round(relativeAltitudeFt + initialAltitude);
      }
    }

		var gpsAltitudeFt = props.historyGeolocation[0].coords.altitude * mToFt;

    this.profilePointsGpsString = "0," + Math.round(gpsAltitudeFt);
		for (var i=1; i<props.historyGeolocation.length; i++) {
      gpsAltitudeFt = props.historyGeolocation[i].coords.altitude * mToFt;
			this.profilePointsGpsString = this.profilePointsGpsString + " " + i + "," + Math.round(gpsAltitudeFt);

      if (Math.round(gpsAltitudeFt) > maxAltitude) {
        maxAltitude = Math.round(gpsAltitudeFt);
      }
    }

    this.maxAltitude = maxAltitude + 100;
 }

  backgroundGridLines() {
    var gridLines = [];
    for (var i=0; i<this.maxAltitude; i+=100) {
      gridLines.push(
        <line x1="0" y1={"" + i} x2={"" + this.props.historyGeolocation.length} y2={"" + i} 							
          stroke="#cccccc"
          strokeWidth={i%1000===0 ? "25" : i%500===0 ? "12" : "4"} />
      );
    }

    return gridLines;
  }
    
  render() {
    return (
      <svg style={{width: "100%", height: "100%"}} xmlns="http://www.w3.org/2000/svg" viewBox={"0 0 " + this.props.historyGeolocation.length + " " + this.maxAltitude}>
        <g  id="cartesian" transform={"translate(0," + (this.maxAltitude) + ") scale(1,-1)"}>
          {this.backgroundGridLines()}
          <polyline points={this.profilePointsBarometricString}
							fill="none"
							stroke="red"
							strokeWidth="10" />
          <polyline points={this.profilePointsGpsString}
							fill="none"
							stroke="blue"
							strokeWidth="10" />
        </g>
      </svg>

    );
  }
}

export default ProfileView;