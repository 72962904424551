import React from 'react';
import {FormControl} from 'react-bootstrap';

class TextInputKeyboardReturnKeyType extends React.PureComponent {
    render() {
        return (
            <FormControl componentClass="select" value={this.props.value} onChange={this.props.onChange}>
                <option value="default">Default</option>
                <option value="done">Done</option>
                <option value="go">Go</option>
                <option value="next">Next</option>
                <option value="search">Search</option>
                <option value="send">Send</option>
                <option value="emergency-call">Emergency Call (iOS Only)</option>
                <option value="google">Google (iOS Only)</option>
                <option value="join">Join (iOS Only)</option>
                <option value="route">Route (iOS Only)</option>
                <option value="yahoo">Yahoo (iOS Only)</option>
                <option value="none">None (Android Only)</option>
                <option value="previous">Previous (Android Only)</option>
            </FormControl>
        ); 
    }
}

export default TextInputKeyboardReturnKeyType;