import React from 'react';
import {FormControl} from 'react-bootstrap';

import Select from 'react-select';  
import 'react-select/dist/react-select.css';

const options = [
	{ label: 'aztec', value: 'aztec' },
	{ label: 'code128', value: 'code128' },
	{ label: 'code39', value: 'code39' },
	{ label: 'code39mod43', value: 'code39mod43' },
	{ label: 'code93', value: 'code93' },
	{ label: 'ean13', value: 'ean13' },
	{ label: 'ean8', value: 'ean8' },
	{ label: 'pdf417', value: 'pdf417' },
	{ label: 'qr', value: 'qr' },
	{ label: 'upce', value: 'upce' },
	{ label: 'interleaved2of5', value: 'interleaved2of5' },
	{ label: 'itf14', value: 'itf14' },
	{ label: 'datamatrix', value: 'datamatrix' },
];

class BarcodeType extends React.PureComponent {

    render() {
        return (
            <Select
					closeOnSelect={true}
					disabled={false}
					multi
					onChange={this.props.onChange}
					options={options}
					placeholder="Select barcode type(s) - leave blank for all"
                    removeSelected={true}
					simpleValue
					value={this.props.value}
				/>
        );  
    }
}

export default BarcodeType;