import _ from "lodash";

export function findEntityIndicesById(arr, entityId, entity, listIndex, sectionIndex, itemIndex) {
	if (_.isUndefined(listIndex)) {
		listIndex = -1;
	}
	if (_.isUndefined(sectionIndex)) {
		sectionIndex = -1;
	}
	if (_.isUndefined(itemIndex)) {
		itemIndex = -1;
	}

	for (var i=0; i<arr.length; i++) {
		if (arr[i].entity.type === "list") {
			listIndex = i;
		} else if (arr[i].entity.type === "section") {
			sectionIndex = i;
		} else if (arr[i].entity.type.startsWith("section")) {
			itemIndex = i;
		}

		if (entityId === arr[i].entity.entityId) {
			entity.found = true;
			entity.result = {type: itemIndex > -1 ? "item" : sectionIndex > -1 ? "section" : "list", listIndex: listIndex, sectionIndex: sectionIndex, itemIndex: itemIndex};
		}

		if(arr[i].hasOwnProperty("children")) {
			findEntityIndicesById(arr[i].children, entityId, entity, listIndex, sectionIndex, itemIndex);
		}
	}
}

export function findEntityChildrenById(arr, entityId, entity) {
	for (var i=0; i<arr.length; i++) {
		if (entityId === arr[i].entity.entityId) {
			entity.found = true;
			entity.result = arr[i].children;
		}
		if(arr[i].hasOwnProperty("children")) {
			findEntityChildrenById(arr[i].children, entityId, entity);
		}
	}
}

export function addCategoryNode(entity, expanded) {
	return {
		"entity": entity,
		"expanded": expanded,
		"children": []
	};
}

export function addItemNode(entity) {
	return {
		"entity": entity
	};
}

export function addCategoryEntity(entityId, label, visible) {
	return {
		"entityId": entityId,
		"type": "category",
		"label": label,
		"labelAlexaPlain": "",
		"labelAlexaRich": "",
		"labelAudio": "",
		"labelSsml": "",
		"labelPrint": "",
		"ignoreCompletion": false,
		"hideStatus": false,
		"suppressAudio": false,
		"suppressAudioTitle": false,
		"trackLocation": false,
		"visible": visible,
		"printCustomProperties": "{}"
	};
}

export function addListEntity(entityId, label) {
	return {
		"entityId": entityId,
		"id": "",
		"type": "list",
		"label": label,
		"labelAlexaPlain": "",
		"labelAlexaRich": "",
		"labelAudio": "",
		"labelSsml": "",
		"labelPrint": "",
		"defaultView": "checklistView",
		"startInBirdseyeView": false,
		"color": "black",
		"suppressPrint": false,
		"suppressAudio": false,
		"suppressAudioTitle": false,
		"suppressAudioEntityType": true,
		"visible": true,
		"ignoreCompletion": false,
		"hideStatus": false,
		"groupNames": [],
		"filterTags": [],
		"printProperties": {
			keepTogether:true,
			breakBefore:false,
			breakBeforeHeight:0,
			breakAfter:false,
			breakAfterHeight: 0
		},
		"printCustomProperties": "{}"
	};
}

export function addSectionEntity(entityId, label) {
	return {
		"entityId": entityId,
		"id": "",
		"type": "section",
		"label": label,
		"labelAlexaPlain": "",
		"labelAlexaRich": "",
		"labelAudio": "",
		"labelSsml": "",
		"labelPrint": "",
		"color": "#ffffff",
		"backgroundColor": "#888888",
		"borderColor": "#888888",
		"iconColor": "#ffffff",
		"suppressPrint": false,
		"suppressAudio": false,
		"suppressAudioTitle": false,
		"suppressAudioEntityType": true,
		"visible": true,
		"ignoreCompletion": false,
		"hideStatus": false,
		"groupNames": [],
		"filterTags": [],
		"printProperties": {
			keepTogether:true,
			breakBefore:false,
			breakBeforeHeight:0,
			breakAfter:false,
			breakAfterHeight: 0
		},
		"printCustomProperties": "{}"
	};
}

export function addItemEntity(entityId, label, label2, label3, labelOnly, mandatory, comments) {
	// Need to support types
	return {
		"entityId": entityId,
		"id": "",
		"type": "item",
		"label": label,
		"label1EditorAdvanced": false,
		"label1AlexaPlain": "",
		"label1AlexaRich": "",
		"label1Audio": "",
		"label1Ssml": "",
		"label1Print": "",
		"color": "black",
		"label2": label2,
		"label2EditorAdvanced": false,
		"label2AlexaPlain": "",
		"label2AlexaRich": "",
		"label2Audio": "",
		"label2Ssml": "",
		"label2Print": "",
		"label2Color": "black",
		"label3": label3,
		"label3EditorAdvanced": false,
		"label3AlexaPlain": "",
		"label3AlexaRich": "",
		"label3Audio": "",
		"label3Ssml": "",
		"label3Print": "",
		"label3Color": "black",
		"labelOnly": labelOnly,
		"labelOnlyBackgroundColor": "#FFF8C6",
		"linkOnCheck": false,
		"linkId": "",
		"linkActionType": "goto",
		"mandatory": mandatory,
		"comments": comments,
		"commentsInline": false,
		"commentsAuthor": "",
		"commentsAudio": "",
		"commentsSsml": "",
		"commentsPrint": "",
		"backgroundImage": "",
		"video": "",
		"image": "",
		"audio": "",
		"suppressPrint": false,
		"suppressPrintComments": false,
		"suppressAudio": false,
		"suppressAudioLabel1": false,
		"suppressAudioLabel2": false,
		"suppressAudioLabel3": false,
		"startTimer": false,
		"stopTimer": false,
		"startTracking": false,
		"stopTracking": false,
		"visible": true,
		"ignoreCompletion": false,
		"groupNames": [],
		"filterTags": [],
		"printProperties": {
			keepTogether:true,
			breakBefore:false,
			breakBeforeHeight:0,
			breakAfter:false,
			breakAfterHeight: 0
		},
		"printCustomProperties": "{}"
	};
}

