import React from 'react';
import globals from '../../constants/globals.js';

import * as actions from '../../actions/actions';
import browserHistory from '../../history';
import Checkout from "./app-subscribe-perf-trial-checkout";

import state from "../../state/state";

import HomeLink from './app-home-link.js';

import { Button } from 'react-bootstrap';

import Flexbox from 'flexbox-react';

import Alerts from '../app-alerts.js';

class PreCheckout extends React.PureComponent {
	constructor(props, context) {
		super(props, context);

		if (!state.get("user", "loggedIn")) {
			actions.forceLogin(browserHistory, this.props.location.pathname);
		}
	}

	render() {
    if (!state.get("user", "loggedIn")) {
      return null
    } else {
      let params = {
        ui_mode: "embedded",
        // redirect_on_completion: "if_required",
        line_items: [
            {
                price: "pro-plan-performance-as-intro-499",
                quantity: 1
            },
            {
              price: "pro-plan-performance-as-yearly-v4",
                quantity: 1
            }
        ],
        mode: "subscription",
        return_url: `${globals.hostUrl}/perfTrialReturn?session_id={CHECKOUT_SESSION_ID}`,
        // automatic_tax: { "enabled": true },
        subscription_data: {
            trial_period_days: 90,
            trial_settings: {
                end_behavior: {
                    missing_payment_method: "cancel"
                }
            }
        }
      };

      if (this.props.params) {
        params = this.props.params;
      }

      if (!state.get("user", "loggedIn")) {
        return null
      } else {
      let headerPanel = null;
  
      if (this.props.params) {
        headerPanel =
        <Flexbox style={{height: "54px"}} flexDirection="row" alignItems="center" justifyContent="space-between">
          <HomeLink />
          <Button style={{width: "200px", marginRight: "20px"}} onClick={(e)=>{
            if (this.props.onReturn) {
              this.props.onReturn();
            }
          }}>Return</Button>
        </Flexbox>
  
      } else {
        headerPanel =
        <Flexbox style={{height: "54px"}} flexDirection="row" alignItems="center" justifyContent="space-between">
          <HomeLink />
          <Button style={{width: "200px", marginRight: "20px"}} onClick={(e)=>{
          actions.goHome();
          }}>Return Home</Button>
        </Flexbox>
  
      }
  
        return (
      <div style={{height: "100vh"}}>
        {headerPanel}
        <Alerts />
        <div style={{backgroundColor: "white", height: "calc(100vh - 54px)", overflow: "auto"}}> 
          <Checkout params={params}/>
        </div>
      </div>
      )
    }
    }
	}
}

export default PreCheckout;