import React from 'react';
import {branch} from 'baobab-react/higher-order';
import * as actions from '../actions/actions';
import * as awsS3 from '../persistence/s3';
import Flexbox from 'flexbox-react';
import browserHistory from '../history';

import globals from '../constants/globals.js';

class ReceiveChecklist extends React.PureComponent{
	constructor(props, context) {
		super(props, context);

		if (!props.user.loggedIn) {
			actions.forceLogin(browserHistory, this.props.location.pathname);
		}	
	}

	componentWillMount() {
		var self = this;
		if (this.props.user.loggedIn && this.props.match.params.shareId) {
			// call function to add the shared checklist to account
			console.log("In ReceiveChecklist componentWillMount");
			// JJB: Positively ugly...since this changes checklists.json needs to wait because checklists.json is loaded at login. This all needs restructured.
			setTimeout(function() {
				awsS3.receiveSharedChecklist(self.props.user.identityId, self.props.match.params.shareId);
			},3000);
		}
	}

	// JJB: This is false...The checklist may actually not be received. Lots more work to do this right. Just want to quickly get a sharing capability.
	render() {	
		let message = <div>"Fasten your seatbelt and hold on tight. <br/>
		We are importing your new checklist."</div>;
		if (this.props.checklistReceived) {
			message = this.props.checklistReceivedMessage;
		}

		let urlChecklists = globals.hostUrl + "/MyHangar";

		return (
			<Flexbox style={{height: "100vh"}} flexDirection="column" alignItems="center" justifyContent="center">
				<p>{message}</p>
				<a style={{marginTop: "25px"}} href={urlChecklists}>Go to Goose Cloud</a>
			</Flexbox>
		);
	}
}

export default branch({
	user: ["user"],
	checklistReceived: ["appState","checklistReceived"],
	checklistReceivedMessage: ["appState","checklistReceivedMessage"]
}, ReceiveChecklist);