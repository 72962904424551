import React from 'react';
import FontAwesome from 'react-fontawesome';

class CardNew extends React.Component {
    render() {
        var card;

        if (this.props.compact) {
            card =
                <div className="cardNewCompact"
                    style={{ display: "flex", alignItems: "center" }} onClick={this.props.onClick}>
                    <FontAwesome fixedWidth={true} style={{ fontSize: "32px", marginLeft: "5px" }} name="plus" />
                    <div style={{ marginLeft: "5px", fontSize: "12px", fontWeight: "normal", maxHeight: "60px", overflowY: "auto" }}>
                        {this.props.title}
                    </div>
                </div>;
        } else {
            card =
                <div className="cardNew" onClick={this.props.onClick} style={{ borderStyle: "dashed", borderColor: "#fcb31f" }} >

                    <div style={{ display: 'flex', width: '100%', height: "100%" }}>
                        <div style={{
                            flex: '1 1 50%',
                            borderTopLeftRadius: '8px',
                            borderTopRightRadius: '8px',
                            borderBottomRightRadius: '8px',
                            borderBottomLeftRadius: '8px',
                            justifyContent: 'center'
                        }}>
                            <div style={{ height: '41px' }}></div>
                            <FontAwesome fixedWidth={true} style={{ fontSize: "100px", width: "175px", height: "100px", justifyContent: 'center', alignItems: 'center' }} name="plus" />

                        </div>
                        <div style={{ paddingLeft: '8px', flex: '1 1 50%' }}>
                            <div style={{ paddingLeft: '8px', height: "56px" }}></div>
                            <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                                {this.props.title}
                            </div>
                        </div>
                    </div>





                </div>;



        }

        return (
            <div>
                {card}
            </div>
        );
    }
}

export default CardNew;