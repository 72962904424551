import React from 'react';
import { branch } from 'baobab-react/higher-order';
import PropTypes from 'baobab-react/prop-types';
import * as actions from '../actions/actions';
import * as actionsPortal from '../actions/portalActions';
import * as awsS3 from '../persistence/s3';
import { Button, Dropdown, MenuItem, Glyphicon } from 'react-bootstrap';
import Alerts from '../components/app-alerts.js';
//import 'bootstrap/dist/css/bootstrap.min.css';
//import '.../css/as-overrides.css';

import Flexbox from 'flexbox-react';
import browserHistory from '../history';

import globals from '../constants/globals.js';
import { contentBucket } from '../constants/globals.js';


import NavLinks from './navPanel/app-nav-links.js';
import NavPanel from './navPanel/app-nav-panel.js';

import MediaQuery from 'react-responsive';

import Card from '../components/card/app-card.js';
import CardNew from '../components/card/app-card-new.js';

import copy from 'copy-to-clipboard';

//import shallowCompare from 'react-addons-shallow-compare';

import SortDocuments from './app-sort-documents.js';

import Modal from 'react-responsive-modal';

import Loader from 'react-loader-advanced';
import FontAwesome from 'react-fontawesome';
import { Container } from 'react-bootstrap/lib/Tab.js';

class AfmPoh extends React.PureComponent {
	constructor(props, context) {
		super(props, context);

		if (!props.user.loggedIn) {
			actions.forceLogin(browserHistory, this.props.location.pathname);
		} else {
			this.handleOnClickCard = this.handleOnClickCard.bind(this);
			this.handleCloneCard = this.handleCloneCard.bind(this);
			this.handleDeleteCard = this.handleDeleteCard.bind(this);
			this.handleToggleVisible = this.handleToggleVisible.bind(this);
			this.handleToggleSpeedType = this.handleToggleSpeedType.bind(this);
			this.handlePrint = this.handlePrint.bind(this);
			this.handleExportJson = this.handleExportJson.bind(this);
			this.handleExportCsv = this.handleExportCsv.bind(this);
			this.handleExportCsvAdvanced = this.handleExportCsvAdvanced.bind(this);
			this.openReorderModal = this.openReorderModal.bind(this);
			this.closeReorderModal = this.closeReorderModal.bind(this);
			this.handleShareWithCode = this.handleShareWithCode.bind(this);
			this.handleReplaceWithCode = this.handleReplaceWithCode.bind(this);
			this.handleShare = this.handleShare.bind(this);
			this.onClickCheckMateImport = this.onClickCheckMateImport.bind(this);
		}

		this.state = {
			showReorderModal: false
		}
	}

	/*
		shouldComponentUpdate(nextProps, nextState) {
			return shallowCompare(this, nextProps, nextState);
		}
	*/
	handleOnClickCard(document) {
		var self = this;
		return function (e) {
			e.stopPropagation();
			if (e.target.id === "contributorsIcon") {
				return;
			}
			if (document.hasOwnProperty("identityId") && document.identityId != "") {
				alert("You can't edit a shared checklist. With a shared checklist you will receive content updates if the user that shared it witth you updates the checklist. If you want to make edits, you can Clone it which will give you your own copy. Note: The clone will no longer receive updates from the user that shared it with you.");
			} else if (document.cloned || document.publisher == "self") {
				awsS3.loadJsonFromS3(contentBucket, document.id, document);
			} else {
				alert("You can't edit a base template. You can clone it to edit it.");
			}
		}
	}

	handleCloneCard(document) {
		var self = this;
		return function (e) {
			e.stopPropagation();
			awsS3.cloneDocument(document);
		}
	}

	handleSetPrivacy(document, privacy) {
		var self = this;
		return function (e) {
			e.stopPropagation();
			awsS3.setDocumentPrivacy(document, privacy);
		}
	}

	handleShare(document) {
		var self = this;
		return function (e) {
			e.stopPropagation();

			if (document.publisher === "checkmate") {
				alert("You can't share a CheckMate checklist. Use the Share with Code action to share a CheckMate.");
				return;
			}

			copy(globals.hostUrl + "/receiveChecklist/" + self.props.user.identityId + ";" + document.id);
			alert("The share link for checklist " + document.name + " has been copied to the clipboard. You can send this link to anyone you want to share the checklist with.");
		}
	}

	handleShareWithCode(document) {
		var self = this;
		return function (e) {
			e.stopPropagation();
			actions.shareWithCode(self.props.user.identityId, document);
		}
	}

	handleReplaceWithCode(document) {
		var self = this;
		return function (e) {
			e.stopPropagation();
			actions.replaceWithCode(self.props.user.identityId, document);
		}
	}

	handleDeleteCard(document) {
		var self = this;
		return function (e) {
			e.stopPropagation();
			const self = this;
			window.confirm("Are you sure you want to delete the checklist " + document.name + "? This action can't be undone.", () => {
				awsS3.deleteDocument(document);
			});
		}
	}

	handleToggleVisible(document) {
		var self = this;
		return function (e) {
			e.stopPropagation();
			awsS3.toggleVisible(document);
		}
	}

	handleToggleSpeedType(document) {
		var self = this;
		return function (e) {
			e.stopPropagation();
			awsS3.toggleSpeedType(document);
		}
	}

	handlePrint(document, columns, numColumns = 5, orientation = "portrait", custom = false) {
		var self = this;
		return function (e) {
			e.stopPropagation();
			if (self.props.subscriptionPlan.startsWith("no-plan") || self.props.subscriptionPlan.startsWith("basic-plan") || self.props.subscriptionPlan.startsWith("standard-plan")) {
				alert("Sorry. You need to be a Pro Plan subscriber to print.");
			} else {
				awsS3.print(document, columns, numColumns, orientation, custom);
			}
		}
	}

	handleExportJson(document) {
		var self = this;
		return function (e) {
			e.stopPropagation();

			if (!self.props.subscriptionPlan.startsWith("pro-plan")) {
				alert("Sorry. You need to be a Pro Plan subscriber to export to JSON format.");
			} else {
				awsS3.exportJson(document);
			}
		}
	}

	handleExportCsv(document) {
		var self = this;
		return function (e) {
			e.stopPropagation();

			if (self.props.subscriptionPlan.startsWith("no-plan") || self.props.subscriptionPlan.startsWith("basic-plan")) {
				alert("Sorry. You need to be a Standard or Pro Plan subscriber to export to CSV format.");
			} else {
				awsS3.exportCsv(document);
			}
		}
	}

	handleExportCsvAdvanced(document) {
		var self = this;
		return function (e) {
			e.stopPropagation();

			if (self.props.subscriptionPlan.startsWith("no-plan") || self.props.subscriptionPlan.startsWith("basic-plan")) {
				alert("Sorry. You need to be a Standard or Pro Plan subscriber to export to CSV format.");
			} else {
				awsS3.exportCsvAdvanced(document);
			}
		}
	}

	closeReorderModal() {
		this.setState({ showReorderModal: false });
	}

	openReorderModal() {
		this.setState({ showReorderModal: true });


	}

	onClickCheckMateImport() {
		this.props.dispatch(
			actions.setNavPanelSelected,
			"import"
		);
	}

	render() {
		if (!this.props.user.loggedIn) {
			return (
				<div />
			);
		} else {
			var self = this;

			
			var documents = self.props.documents.map(function (document, i) {
				if (!self.props.showVisible && !document.visible) {
					return;
				}

				if ((!document.hasOwnProperty("filterVisible") || document.filterVisible)) {
					var menuItems;
					if (document.hasOwnProperty("identityId")) {
						menuItems = [
							<MenuItem key={"menuitem1"} onClick={self.handleOnClickCard(document)}>Edit Checklist</MenuItem>,
							<MenuItem key={"menudivider1"} divider />,
							<MenuItem key={"menuitem2"} onClick={self.handleToggleVisible(document)}>Toggle Visible</MenuItem>,
							<MenuItem key={"menudivider3"} divider />,
							<MenuItem key={"menuitem4"} onClick={self.handleCloneCard(document)}>Clone</MenuItem>,
							<MenuItem key={"menudivider7"} divider />,
							<MenuItem key={"menuitem10"} onClick={self.handleDeleteCard(document)}>Delete</MenuItem>
						]
					} else {
						menuItems = [
							<MenuItem key={"menuitem1"} onClick={self.handleOnClickCard(document)}>Edit Checklist</MenuItem>,
							<MenuItem key={"menudivider1"} divider />,
							<MenuItem key={"menuitem2"} onClick={self.handleToggleVisible(document)}>Toggle Visible</MenuItem>,
							<MenuItem key={"menudivider2"} divider />,
							<MenuItem key={"menuitem3"} onClick={self.handlePrint(document, false)}>Print (Portrait - Standard)</MenuItem>,
							<MenuItem key={"menuitem3b"} onClick={self.handlePrint(document, true)}>Print (Portrait - 5 Columns)</MenuItem>,
							<MenuItem key={"menuitem3b2a"} onClick={self.handlePrint(document, true, 4, "landscape")}>Print (Landscape - 4 Columns)</MenuItem>,
							<MenuItem key={"menuitem3b2"} onClick={self.handlePrint(document, true, 2)}>Print (Portrait - 2 Columns)</MenuItem>,
							<MenuItem key={"menuitem3b2b"} onClick={self.handlePrint(document, true, 2, "landscape")}>Print (Landscape - 2 Columns)</MenuItem>,
							<MenuItem key={"menuitem3b2c"} onClick={self.handlePrint(document, false, 0, "portrait", true)}>Print Custom (Portrait)</MenuItem>,
							<MenuItem key={"menuitem3b2d"} onClick={self.handlePrint(document, false, 0, "landscape", true)}>Print Custom (Landscape)</MenuItem>,
							// <MenuItem key={"menuitemExportJson"} onClick={self.handleExportJson(document)}>Export JSON</MenuItem>,
							<MenuItem key={"menuitem3c"} onClick={self.handleExportCsv(document)}>Export CSV (Basic)</MenuItem>,
							// <MenuItem key={"menuitem3d"} onClick={self.handleExportCsvAdvanced(document)}>Export CSV (Advanced)</MenuItem>,
							<MenuItem key={"menudivider3"} divider />,
							<MenuItem key={"menuitem4"} onClick={self.handleCloneCard(document)}>Clone</MenuItem>
						];

						if (document.publisher === "checkmate") {
							menuItems.splice(5, 3);
						} else {
							if ((document.hasOwnProperty("privacy") && document.privacy === "public")) {
								menuItems.push(<MenuItem key={"menudivider4"} divider />);
								menuItems.push(<MenuItem key={"menuitem5"} onClick={self.handleSetPrivacy(document, "public")}>{!self.props.igapp ? "Public Republish" : "Republish to IGAPP Community"}</MenuItem>);
								menuItems.push(<MenuItem key={"menuitem6"} onClick={self.handleSetPrivacy(document, "private")}>{!self.props.igapp ? "Make Private" : "Remove from IGAPP Community"}</MenuItem>);
							} else {
								menuItems.push(<MenuItem key={"menudivider5"} divider />);
								menuItems.push(<MenuItem key={"menuitem7"} onClick={self.handleSetPrivacy(document, "public")}>{!self.props.igapp ? "Make Public" : "Share with IGAPP Community"} </MenuItem>);
							}
						}

						menuItems.push(<MenuItem key={"menudivider6"} divider />);
						menuItems.push(<MenuItem key={"menuitem75"} onClick={self.handleShare(document)}>Create Share Link</MenuItem>);
						if (!self.props.igapp) {
							menuItems.push(<MenuItem key={"menuitem8"} onClick={self.handleShareWithCode(document)}>Share with Code</MenuItem>);
							menuItems.push(<MenuItem key={"menuitem9"} onClick={self.handleReplaceWithCode(document)}>{document.shareCodeReceived == "" ? "Replace with Code" : "Refresh with Code " + document.shareCodeReceived}</MenuItem>);
						}

						if (document.genre === "aviation") {
							menuItems.push(<MenuItem key={"menudividerSpeed"} divider />);
							menuItems.push(<MenuItem key={"menuitemSpeed"} onClick={self.handleToggleSpeedType(document)}>{document.speedType === "KIAS" ? "Toggle Speed To MPH" : "Toggle Speed To KIAS"}</MenuItem>);
						}

						if ((document.hasOwnProperty("cloned") && document.cloned) || document.publisher == "self" || !document.hasOwnProperty("id")) {
							menuItems.push(<MenuItem key={"menudivider7"} divider />);
							menuItems.push(<MenuItem key={"menuitem10"} onClick={self.handleDeleteCard(document)}>Delete</MenuItem>);
						}
					}

					var menuComponent =
						<Dropdown pullRight id="dropdown-card-menu">
							<Dropdown.Toggle noCaret onClick={(e) => { e.stopPropagation() }}>
								<Glyphicon style={{ color: "#fdfdfd" }} glyph="option-vertical" />
							</Dropdown.Toggle>
							<Dropdown.Menu style={{ opacity: 0.95 }}>
								{menuItems}
							</Dropdown.Menu>
						</Dropdown>;

					var card;
					if (!document.newDoc) {
						card =
							<Card
								title={document.name}
								description={document.description}
								privacy={document.privacy}
								contributors={document.hasOwnProperty("contributors") ? document.contributors : []}
								menuComponent={menuComponent}
								disabled={false}
								visible={document.visible}
								shared={document.hasOwnProperty("identityId") ? true : false}
								genres={self.props.genres}
								genre={document.genre}
								onClick={self.handleOnClickCard(document)} />
					} else {
						card =
							<CardNew
								title="Create a New Procedure"
								onClick={self.handleOnClickCard(document)} />
					}
					return (
						<div key={"document" + i}>{card}</div>
					);

				}
			});

			var reorderModal;
			if (this.props.documents.length > 0) {
				reorderModal =
					<Modal classNames={{ modal: 'custom-modal' }} open={this.state.showReorderModal} onClose={this.closeReorderModal} little>
						<h3 style={{ textAlign: "center" }}>Organize Checklists</h3>
						<SortDocuments documents={this.props.documents} onClose={this.closeReorderModal} />
					</Modal>
			}

			const spinner =
				<FontAwesome
					name='spinner'
					size='4x'
					spin />;

			var noPlanContent =
				<div style={{ fontSize: 14, margin: "20px", padding: "10px", backgroundColor: "rgb(40,100,168)", color: "white", borderRadius: "10px" }}>
					<div>
						<Glyphicon glyph="info-sign" /> You currently are in the Free plan. Click the button below to learn about the different plans.
					</div>
					<Button style={{ margin: 10 }} onClick={() => {
						this.props.dispatch(actions.setLastLocation, window.location);
						browserHistory.push("/subscribe");
					}}>Go to Plans</Button>
					<Button style={{ margin: 10 }} onClick={() => {
						this.context.tree.set(["appState", "hideFreeBanner"], true);
					}}>Dismiss</Button>
				</div>;

			return (
				<Loader show={this.props.loadingSpinner} message={spinner}>

					<Flexbox flexDirection="column" height="100vh" style={{ minHeight: 0 }}>
						<Alerts />
						<NavLinks showReorder={true} onReorder={this.openReorderModal} />

						<MediaQuery query='(max-width: 599px)'>
						<Flexbox flexDirection='row' width="100%" padding='20px'>
													<Flexbox flexGrow={1} style={{ borderBottom: "1px solid #ccc" }} justifyContent="space-between">
														<h1>MY HANGAR</h1>

													</Flexbox>
												</Flexbox>
							<Flexbox flex="1" flexDirection="column" width="100vw" style={{ minHeight: 0 }}>
								<Flexbox style={{ overflowY: "auto" }} padding="10px" flexGrow={1} flexDirection="column" alignItems="center" justifyContent="flex-start">

									{self.props.subscriptionPlan.startsWith("no-plan") && !this.props.hideFreeBanner &&
										<Flexbox flexDirection="column" width="100%">
											{noPlanContent}
										</Flexbox>
									}
									{this.props.documents.length === 1 &&
										<div style={{ margin: "25px", textAlign: "left", fontStyle: "italic" }}>
											<h4>You currently do not have any checklists. You can create a new one from scratch or go to the Search panel to add one from the community.</h4>
										</div>
									}

									{documents}

								</Flexbox>
							</Flexbox>

						</MediaQuery>

						<MediaQuery query='(min-width: 600px)'>
							<Flexbox flex="1" flexDirection="row" width="100vw" style={{ minHeight: 0 }}>
								<MediaQuery query='(min-width: 768px)'>
									<NavPanel />
								</MediaQuery>
								<Flexbox flexDirection="column" width="100%">
									<Flexbox flexDirection="column" alignItems="center" padding="20px">
										<Flexbox flexDirection='row' width="100%" >
											<Flexbox flexGrow={1} style={{ borderBottom: "1px solid #ccc" }} justifyContent="space-between">
												<h1>AFM POH</h1>

												<Flexbox flexDirection='column'>
													<Button bsStyle="primary" marginWidth='20px' onClick={this.onClickCheckMateImport}>Import</Button>
													{/* <Flexbox height='8px'></Flexbox>
													<Button padding="4px" onClick={() => { }}>TBD - Re-Organize</Button>
													 */}
													<Flexbox height='4px'></Flexbox>
													
												</Flexbox>
											</Flexbox>
										</Flexbox>
										<Flexbox flexDirection='row' width="100%" paddingTop='10px'>
											{/* <Flexbox >
												<Button bsStyle="primary" onClick={() => { }}>TBD - Create a New Procedure</Button>
												<Flexbox flexDirection="row" lignItems="left" padding="10px" paddingLeft='25px'>
													<div className='pill-button-container'>
														<Button className='btn-pill'>FILTER</Button>
														<Button className='btn-pill'>FILTER</Button>
														<Button className='btn-pill'>FILTER</Button>
														<Button className='btn-pill'>FILTER</Button>
													</div>
													ADD DROPDOWN FILTER
												</Flexbox>
											</Flexbox> */}
										</Flexbox>

									</Flexbox>
									{/* {self.props.subscriptionPlan.startsWith("no-plan") &&
									<Flexbox flexDirection="column" width="100%">
										{noPlanContent}
									</Flexbox>
									} */}
									{self.props.subscriptionPlan.startsWith("no-plan") && !this.props.hideFreeBanner &&
										<Flexbox flexDirection="column" width="100%">
											{noPlanContent}
										</Flexbox>
									}
									{this.props.documents.length === 1 &&
										<div style={{ margin: "25px", textAlign: "left", fontStyle: "italic" }}>
											<h4>You currently do not have any checklists. You can create a new one from scratch or go to the Search panel to add one from the community.</h4>
										</div>
									}
									<Flexbox style={{ overflowY: "auto" }} padding="10px" flexGrow={1} flexWrap="wrap" flexDirection="row" justifyContent="flex-start">

										{documents}
									</Flexbox>
								</Flexbox>
							</Flexbox>
						</MediaQuery>
						{reorderModal}
					</Flexbox >
				</Loader >
			);
		}
	}

	componentDidMount() {
		this.context.tree.select(['appState', 'navPanel']).set('selected', 'myChecklists');
	}
}

AfmPoh.contextTypes = {
	tree: PropTypes.baobab
};

export default branch({
	documents: ['documents'],
	version: ['version'],
	user: ["user"],
	genres: ["genres"],
	subscriptionPlan: ["user", "subscriptionPlan"],
	showVisible: ["appState", "filters", "showVisible"],
	loadingSpinner: ["appState", "loadingSpinner"],
	hideFreeBanner: ["appState", "hideFreeBanner"],
	igapp: ["appCapabilities", "igapp"]
}, AfmPoh);