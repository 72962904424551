import React from 'react';
import ReactDOM from 'react-dom';
import { branch } from 'baobab-react/higher-order';

import MediaQuery from 'react-responsive';

import autoBind from 'react-autobind';

import * as actions from '../actions/actions';
import * as actionsPortal from '../actions/portalActions';
import * as awsS3 from '../persistence/s3';

import globals from '../constants/globals.js';

import { Button, Checkbox, Panel, Popover, OverlayTrigger } from 'react-bootstrap';

import AfPanel from '../components/shared/AfPanel';
import AfHelp from '../components/shared/AfHelp';

import Glyphicon from '../components/app-icon';

import ItemType from '../components/itemComponents/ItemType';
import TextInputKeyboardType from '../components/itemComponents/TextInputKeyboardType';
import TextInputAutoCapitalize from '../components/itemComponents/TextInputAutoCapitalize';
import TextInputKeyboardReturnKeyType from '../components/itemComponents/TextInputKeyboardReturnKeyType';
import TextInputMaskType from '../components/itemComponents/TextInputMaskType';
import DateTimeType from '../components/itemComponents/DateTimeType';
import PickerViewType from '../components/itemComponents/PickerViewType';
import LinkActionType from '../components/itemComponents/LinkActionType';
import BarcodeType from '../components/itemComponents/BarcodeType';
import MediaType from '../components/itemComponents/MediaType';
import NameValuePairEditor from '../components/itemComponents/NameValuePairEditor';

//import shallowCompare from 'react-addons-shallow-compare';

import { WithContext as ReactTags } from 'react-tag-input';

import marked from 'marked';

import CKEditor from "react-ckeditor-component";
import { SketchPicker } from 'react-color';

import MediaPanel from '../components/app-editor-media-panel';

import PrintProperties from '../components/itemComponents/PrintProperties';

import RichTextEditorWithToggle from "../components/richTextEditor/rich-text-editor-with-toggle";

import ClampText from "react-clamp-text";

import "react-markdown-editor-lite/lib/index.css";

import Flexbox from 'flexbox-react';

import * as utils from '../utils/utils';

import Select from 'react-select';
import 'react-select/dist/react-select.css';

import ModalRichTextEditor from "../components/richTextEditor/modal-rich-text-editor";

import { Controlled as CodeMirror } from "react-codemirror2";

require("codemirror/lib/codemirror.css"); // eslint-disable-line
require("codemirror/theme/material.css"); // eslint-disable-line
require("codemirror/mode/javascript/javascript"); // eslint-disable-line
require("codemirror/addon/fold/foldcode"); // eslint-disable-line
require("codemirror/addon/fold/foldgutter"); // eslint-disable-line
require('codemirror/addon/fold/brace-fold.js'); // eslint-disable-line
require("codemirror/addon/fold/foldgutter.css"); // eslint-disable-line

class EditorItem extends React.PureComponent {
	constructor(props, context) {
		super(props, context);
		//autoBind(this);

		if (window.innerWidth < 768) {
			this.state = {
				open: false,
				advancedOpen: false,
				showComments: false,
				inlineEdit1: false,
				inlineEdit2: false
			};
		} else {
			this.state = {
				open: true,
				advancedOpen: false,
				showComments: false,
				inlineEdit1: false,
				inlineEdit2: false
			};
		}
	}

	// shouldComponentUpdate(nextProps, nextState) {
	// 	return shallowCompare(this, nextProps, nextState);
	// }

	hasRichText(label) {
		let markup = this.createMarkup(label);
		if (label !== markup.__html) {
			return true;
		} else {
			return false;
		}
	}

	createMarkup(label) {
		var myRenderer = new marked.Renderer();
		myRenderer.link = function (href, title, text) {
			var external, newWindow, out;
			external = /^https?:\/\/.+$/.test(href);
			newWindow = external || title === 'newWindow';
			out = "<a href=\"" + href + "\"";
			if (newWindow) {
				out += ' target="_blank"';
			}
			if (title && title !== 'newWindow') {
				out += " title=\"" + title + "\"";
			}
			return out += ">" + text + "</a>";
		};

		myRenderer.image = function (href, title, text) {
			return "<img style=\"max-width: 100%\" src=\"" + href + "\" /><br/><div>" + text + "</div>";
		};

		// myRenderer.paragraph = function(text) {
		// 	return text;
		// };

		marked.setOptions({
			renderer: myRenderer,
			gfm: true,
			tables: true,
			breaks: true,
			pedantic: false,
			sanitize: false,
			smartLists: true,
			smartypants: false
		});

		return { __html: marked(label) };
	}

	handleOnFocus(e) {
		// var inputLabel = ReactDOM.findDOMNode(this.refs.inputLabel);
		// if (inputLabel.value === "(Label 1)") {
		// 	inputLabel.setSelectionRange(0, inputLabel.value.length);
		// }
	}
	/*    
		componentDidMount() {
			var inputLabel = ReactDOM.findDOMNode(this.refs.inputLabel);
			inputLabel.focus();	
			if (inputLabel.value === "(Label 1)") {
				inputLabel.setSelectionRange(0, inputLabel.value.length);
			}	
		}
	*/
	//onClick={(e)=>{e.target.setSelectionRange(0, e.target.value.length)}}

	/*
		getBase64FromImageUrl() {
			var url = prompt("Enter url: ");
	
			if (url !== "") {
				awsS3.convertImageUrlToBase64(url);
			}			   
		}
	*/

	componentDidMount() {
		// if (window.innerWidth < 768) {
		// } else {
		// 	var inputLabel = ReactDOM.findDOMNode(this.refs.inputLabel);
		// 	if (inputLabel.value === "") {
		// 		inputLabel.focus();
		// 		//inputLabel.setSelectionRange(0, inputLabel.value.length);
		// 	}
		// }
	}

	componentDidUpdate(prevProps, prevState) {
		if (window.innerWidth < 768) {
		} else {
			if (prevProps.selectedNodePath !== this.props.selectedNodePath) {
				// var inputLabel = ReactDOM.findDOMNode(this.refs.inputLabel);
				// if (inputLabel.value === "") {
				// 	inputLabel.focus();
				// 	//inputLabel.setSelectionRange(0, inputLabel.value.length);
				// }

				this.setState({ showComments: false });
			}
		}
	}

	render() {
		var entity = this.props.node.entity;

		let label1EditorAdvanced = false;
		if (entity.hasOwnProperty("label1EditorAdvanced")) {
			label1EditorAdvanced = entity.label1EditorAdvanced;
		}
		// label1EditorAdvanced = true;
		const label1InlineKey = "label1Inline;" + entity.entityId + ";" + (label1EditorAdvanced ? "advanced" : "basic") + ";" + this.state.showComments;


		let label2EditorAdvanced = false;
		if (entity.hasOwnProperty("label2EditorAdvanced")) {
			label2EditorAdvanced = entity.label2EditorAdvanced;
		}
		const label2InlineKey = "label2Inline;" + entity.entityId + ";" + (label2EditorAdvanced ? "advanced" : "basic") + ";" + this.state.showComments;

		var inlineEditor1;
		inlineEditor1 =
			<div style={{flex: 1, widtth: "100%", marginLeft: "5px", marginRight: "5px", marginBottom: "10px", backgroundColor: "white", color: "black", padding: "3px"}}>
				<RichTextEditorWithToggle autoFocus={true} key={label1InlineKey} placeholder="(Label 1)" advanced={label1EditorAdvanced} value={entity.label}
						onChange={(obj) => {
							this.props.dispatch(actions.handleChangeProperty, "label", obj.text);
						}}
						onClickToggle={() => {
							this.props.dispatch(actions.handleChangeProperty, "label1EditorAdvanced", !label1EditorAdvanced);
						}}
					/>
				<Button onClick={()=>{this.setState({inlineEdit1: false})}}><Glyphicon style={{fontSize: "16px", color: "green"}} glyph='check' /> OK</Button>	
			</div>;

		var inlineEditor2;
		inlineEditor2 =
			<div style={{flex: 1, widtth: "100%", marginLeft: "5px", marginRight: "5px", backgroundColor: "white", color: "black", padding: "3px"}}>
				<RichTextEditorWithToggle autoFocus={true} key={label2InlineKey} placeholder="(Label 2)" advanced={label1EditorAdvanced} value={entity.label2}
						onChange={(obj) => {
							this.props.dispatch(actions.handleChangeProperty, "label2", obj.text);
						}}
						onClickToggle={() => {
							this.props.dispatch(actions.handleChangeProperty, "label2EditorAdvanced", !label2EditorAdvanced);
						}}
					/>
				<Button onClick={()=>{this.setState({inlineEdit2: false})}}><Glyphicon style={{fontSize: "16px", color: "green"}} glyph='check' /> OK</Button>	
			</div>;

		var icon;
		var color = "#118871";

		var icon = utils.getIconForType(this.props.node.entity.type);

		const close = <span><Glyphicon style={{ color: "green", fontSize: "20px" }} glyph='check' /> OK</span>;
		const edit = <span><Glyphicon style={{ fontSize: "20px" }} glyph='pencil' /></span>;
		var header = (
			<div style={{display: "flex", justifyContent: "space-between"}}>
				<div style={{ display:"flex", alignItems: "center", marginTop: "5px",
					userSelect: "none",
					WebkitUserSelect: "none",
					MozUserSelect: "none",
					msUserSelect: "none",
					width: "100%"
				}}>
					<Glyphicon style={{fontSize: "32px", color: color }} glyph={icon} />
					<div style={{display: "flex", flexDirection: "column", width: "100%"}}>
						{!this.state.inlineEdit1 &&
							<div style={{marginLeft: "5px", marginBottom: "10px"}} onClick={()=>{this.setState({inlineEdit1: true, inlineEdit2: false})}}>
								{entity.label === "" ? "(Label 1)" : <ClampText
									line={3}
									showMore={false}
									showMoreText="Expand"
									showLessText="Collapse"
								>
									<div dangerouslySetInnerHTML={utils.createMarkupForLabel(entity.label)} />
								</ClampText>}
							</div>
						}
						{this.state.inlineEdit1 && inlineEditor1}
						{!this.state.inlineEdit2 &&
							<div style={{marginLeft: "5px"}} onClick={()=>{this.setState({inlineEdit2: true, inlineEdit1: false})}}>
								{entity.label2 === "" ? "(Label 2)" : <ClampText
									line={3}
									showMore={false}
									showMoreText="Expand"
									showLessText="Collapse"
								>
									<div dangerouslySetInnerHTML={utils.createMarkupForLabel(entity.label2)} />
								</ClampText>}
							</div>
						}
						{this.state.inlineEdit2 && inlineEditor2}						
					</div>
				</div>
				{this.props.provided && !this.state.inlineEdit1 && !this.state.inlineEdit2 && <div style={{display: "flex", alignItems: "center"}}>
					<div style={{marginRight: 10}}>
						<Button style={{/*backgroundColor: "rgba(255,255,255,0.3)", */color: "#fdfdfd"}} onClick={() => this.setState({ open: !this.state.open })}>{this.state.open ? close : edit}</Button>
					</div>
					<div {...this.props.provided.dragHandleProps}>
						<Glyphicon style={{fontSize: "32px", color: color + "88"}} glyph="sort" />
					</div>
				</div>}
			</div>
		)

		var advancedHeader = (
			<div style={{display: "flex"}}>
				<div>
					Advanced
				</div>
				{/* <div style={{ float: "right" }}>
					<Button bsSize="small" onClick={() => this.setState({ advancedOpen: !this.state.advancedOpen })}>{this.state.advancedOpen ? close : edit}</Button>
				</div>
				<div style={{ float: "none", clear: "both" }} /> */}
			</div>
		)
		// Auto selection
		//  onClick={(e)=>{e.target.setSelectionRange(0, e.target.value.length)}}

		// var markdownPreviewLabel1;
		// var markupLabel1 = this.createMarkup(entity.label);
		// if (entity.label !== markupLabel1.__html) {
		// 	markdownPreviewLabel1 =
		// 		<div style={{ marginBottom: 10 }}>
		// 			<div>
		// 				<label>Label 1 Preview</label>
		// 			</div>
		// 			<div dangerouslySetInnerHTML={markupLabel1} />
		// 		</div>
		// }

		// var markdownPreviewLabel2;
		// var markupLabel2 = this.createMarkup(entity.label2);
		// if (entity.label2 !== markupLabel2.__html) {
		// 	markdownPreviewLabel2 =
		// 		<div style={{ marginBottom: 10 }}>
		// 			<div>
		// 				<label>Label 2 Preview</label>
		// 			</div>
		// 			<div dangerouslySetInnerHTML={markupLabel2} />
		// 		</div>
		// }

		// var markdownPreviewLabel3;
		// var markupLabel3 = this.createMarkup(entity.label3);
		// if (entity.label3 !== markupLabel3.__html) {
		// 	markdownPreviewLabel3 =
		// 		<div style={{ marginBottom: 10 }}>
		// 			<div>
		// 				<label>Label 3 Preview</label>
		// 			</div>
		// 			<div dangerouslySetInnerHTML={markupLabel3} />
		// 		</div>
		// }
		/*
				var markdownPreviewComments;
				var markupComments = this.createMarkup(entity.commentsAuthor);
				if (entity.commentsAuthor !== markupComments.__html) {
					markdownPreviewComments = 
						<div style={{marginBottom: 10}}>
							<div>
								<label>Comments Preview</label>
							</div>
							<div dangerouslySetInnerHTML={markupComments} />
						</div>
				}
		*/

		var ckEditor;
		var ckEditorModal;

		if (!this.state.showComments) {
			ckEditor =
				<div>
					<Button onClick={() => this.setState({ showComments: true })}>{(entity.comments && entity.comments !== "") ? "Edit Comments" : "Add Comments"}</Button>
					<div style={{marginTop: "5px", padding: "10px", borderRadius: "5px", backgroundColor: "rgb(240,240,240)", color: "#555555"}}>
						<div dangerouslySetInnerHTML={{__html: (entity.comments && entity.comments !== "") ? entity.comments : "There are no comments. Click <b>Add Comments</b> to add them."}} />
					</div>
				</div>
		} else {
			ckEditorModal =
				<ModalRichTextEditor
					showComments={this.state.showComments}
					comments={entity.comments} 
					onChangeComments={(data) => {
						this.props.dispatch(actions.handleChangeProperty, "comments", data)				
					}}
					onCloseCommentsModal={()=> {
						this.setState({showComments: false});
					}}
				/>;

				// <Modal classNames={{ modal: 'custom-modal' }} open={this.state.showComments} onClose={()=>{this.setState({showComments: false})}} little>
				// 	<CKEditor
				// 		key={"ck-" + entity.entityId}
				// 		content={entity.comments}
				// 		//scriptUrl="https://cdn.ckeditor.com/4.8.0/full/ckeditor.js"
				// 		scriptUrl="https://checklist.miracheck.com/ckeditor/ckeditor.js?v=2"
				// 		config={{
				// 			dialog_noConfirmCancel: true
				// 		}}
				// 		events={{
				// 			"change": (e) => this.props.dispatch(actions.handleChangeProperty, "comments", e.editor.getData())
				// 		}}
				// 	/>
				// </Modal>;		

			// this.state.showComments = true;
		}

		var popoverBackgroundColor =
			<Popover id="popover-background-color" title="Label Only Background Color">
				<SketchPicker
					disableAlpha={true}
					color={entity.labelOnlyBackgroundColor}
					onChangeComplete={(color, e) => this.props.dispatch(actions.handleChangeProperty, "labelOnlyBackgroundColor", color.hex)}
					presetColors={globals.headingColors}
				/>
			</Popover>

		var popoverLabel1Color =
			<Popover id="popover-label1-color" title="Label 1 Color">
				<SketchPicker
					disableAlpha={true}
					color={entity.color}
					onChangeComplete={(color, e) => this.props.dispatch(actions.handleChangeProperty, "color", color.hex)}
					presetColors={globals.defaultColors}
				/>
			</Popover>

		var popoverLabel2Color =
			<Popover id="popover-label2-color" title="Label 2 Color">
				<SketchPicker
					disableAlpha={true}
					color={entity.label2Color}
					onChangeComplete={(color, e) => this.props.dispatch(actions.handleChangeProperty, "label2Color", color.hex)}
					presetColors={globals.defaultColors}
				/>
			</Popover>

		var popoverLabel3Color =
			<Popover id="popover-label3-color" title="Label 3 Color">
				<SketchPicker
					disableAlpha={true}
					color={entity.label3Color}
					onChangeComplete={(color, e) => this.props.dispatch(actions.handleChangeProperty, "label3Color", color.hex)}
					presetColors={globals.defaultColors}
				/>
			</Popover>

		var type =
			<div>
				<div>
					<AfHelp description={this.props.content.type.description}>
						<label>{this.props.content.type.name}</label>
					</AfHelp>
				</div>
				<div>
					<ItemType value={entity.type} onChange={(e) => this.props.dispatch(actions.handleChangeItemType, e.target.value)} barcode={this.props.appCapabilities.barcode} />
				</div>
			</div>;

		// let label1EditorAdvanced = false;
		// if (entity.hasOwnProperty("label1EditorAdvanced")) {
		// 	label1EditorAdvanced = entity.label1EditorAdvanced;
		// }
		// label1EditorAdvanced = true;
		const label1Key = "label1;" + entity.entityId + ";" + (label1EditorAdvanced ? "advanced" : "basic") + ";" + this.state.showComments;

		var label1;
		label1 =
			<div>
				<div>
					<AfHelp description={this.props.content.label1.description}>
						<label>{this.props.content.label1.name}</label>
					</AfHelp>
				</div>
				<div>
					<RichTextEditorWithToggle autoFocus={true} key={label1Key} placeholder="(Label 1)" advanced={label1EditorAdvanced} value={entity.label}
						onChange={(obj) => {
							this.props.dispatch(actions.handleChangeProperty, "label", obj.text);
						}}
						onClickToggle={() => {
							this.props.dispatch(actions.handleChangeProperty, "label1EditorAdvanced", !label1EditorAdvanced);
						}}
					/>
				</div>
				{/* {markdownPreviewLabel1} */}
			</div>;

		// let label2EditorAdvanced = false;
		// if (entity.hasOwnProperty("label2EditorAdvanced")) {
		// 	label2EditorAdvanced = entity.label2EditorAdvanced;
		// }
		// label2EditorAdvanced = true;
		const label2Key = "label2;" + entity.entityId + ";" + (label2EditorAdvanced ? "advanced" : "basic") + ";" + this.state.showComments;

		var label2;
		label2 =
			<div>
				<AfHelp description={this.props.content.label2.description}>
					<label>{this.props.content.label2.name}</label>
				</AfHelp>
				<div>
					<RichTextEditorWithToggle key={label2Key} placeholder="(Label 2)" advanced={label2EditorAdvanced} value={entity.label2}
						onChange={(obj) => {
							this.props.dispatch(actions.handleChangeProperty, "label2", obj.text);
						}}
						onClickToggle={() => {
							this.props.dispatch(actions.handleChangeProperty, "label2EditorAdvanced", !label2EditorAdvanced);
						}}
					/>
				</div>
				{/* {markdownPreviewLabel2} */}
			</div>;

		let label3EditorAdvanced = false;
		if (entity.hasOwnProperty("label3EditorAdvanced")) {
			label3EditorAdvanced = entity.label3EditorAdvanced;
		}
		// label3EditorAdvanced = true;
		const label3Key = "label3;" + entity.entityId + ";" + (label3EditorAdvanced ? "advanced" : "basic") + ";" + this.state.showComments;

		var label3;
		label3 =
			<div>
				<AfHelp description={this.props.content.label3.description}>
					<label>{this.props.content.label3.name}</label>
				</AfHelp>
				<div>
					<RichTextEditorWithToggle key={label3Key} placeholder="(Label 3)" advanced={label3EditorAdvanced} value={entity.label3}
						onChange={(obj) => {
							this.props.dispatch(actions.handleChangeProperty, "label3", obj.text);
						}}
						onClickToggle={() => {
							this.props.dispatch(actions.handleChangeProperty, "label3EditorAdvanced", !label3EditorAdvanced);
						}}
					/>
				</div>
				{/* {markdownPreviewLabel3} */}
			</div>;

		var comments;
		comments =
			<div>
				<div>
					<AfHelp description={this.props.content.comments.description}>
						<label>{this.props.content.comments.name}</label>
					</AfHelp>
				</div>
				<div>
					{ckEditor}
				</div>
			</div>;

		var labelOnly;
		var link;
		if (entity.type === "item") {
			labelOnly =
				// <Panel style={{ marginTop: "25px", marginBottom: "0px" }} defaultExpanded={false}>
				// 	<Panel.Heading>
				// 		<Panel.Title toggle>
				// 			Label Only
				// 	</Panel.Title>
				// 	</Panel.Heading>

				// 	<Panel.Collapse>
				// 		<Panel.Body>
							<div style={{marginBottom: "15px"}}>
								<div>
									<AfHelp alignItems="center" description={this.props.content.labelOnly.description}>
										<Checkbox checked={entity.labelOnly == null || entity.labelOnly ? "checked" : ""} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "labelOnly", e.target.checked)}>
											{this.props.content.labelOnly.name}
										</Checkbox>
									</AfHelp>
								</div>
								{entity.labelOnly && 
									<div>
										<div>
										<AfHelp description={this.props.content.labelOnlyBackgroundColor.description}>
											<label>{this.props.content.labelOnlyBackgroundColor.name}</label>
										</AfHelp>
										</div>
										<div>
											<OverlayTrigger trigger="click" rootClose placement="top" overlay={popoverBackgroundColor}>
												<div style={{ cursor: "pointer", border: "2px solid black", borderRadius: "5px", width: "32px", height: "32px", backgroundColor: entity.labelOnlyBackgroundColor }} />
											</OverlayTrigger>
										</div>

									</div>
								}
							</div>
				// 		</Panel.Body>
				// 	</Panel.Collapse>
				// </Panel>
		}

		if (entity.type != "itemPicker" && entity.type != "itemYesNo") {
			link =
				<AfPanel defaultExpanded title="Link">
					<div>
						<AfHelp alignItems="center" description={this.props.content.linkOnCheck.description}>
							<Checkbox checked={entity.linkOnCheck == null || entity.linkOnCheck ? "checked" : ""} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "linkOnCheck", e.target.checked)}>
								{this.props.content.linkOnCheck.name}
							</Checkbox>
						</AfHelp>
					</div>

					<div>
						<div>
							<AfHelp description={this.props.content.linkId.description}>
								<label>{this.props.content.linkId.name}</label>
							</AfHelp>
						</div>
						<div>
							<input style={{ marginBottom: "15px" }} className="form-control" type="text" placeholder="(Enter ID to link to)" value={entity.linkId} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "linkId", e.target.value)} />
						</div>
					</div>
					<div>
						<div>
							<AfHelp description={this.props.content.linkAction.description}>
								<label>{this.props.content.linkAction.name}</label>
							</AfHelp>
						</div>
						<div>
							<LinkActionType user={this.props.user} value={entity.linkActionType} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "linkActionType", e.target.value)} />
						</div>
					</div>
				</AfPanel>

		}

		let visible =
		<div>
			<AfHelp alignItems="center" description={this.props.content.visible.description}>
				<Checkbox checked={entity.visible === null || entity.visible ? "checked" : ""} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "visible", e.target.checked)}>
					{this.props.content.visible.name}
				</Checkbox>
			</AfHelp>	
		</div>;

		var mandatory =
			<div>
				<AfHelp alignItems="center" description={this.props.content.mandatory.description}>
					<Checkbox checked={entity.mandatory == null || entity.mandatory ? "checked" : ""} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "mandatory", e.target.checked)}>
						{this.props.content.mandatory.name}
					</Checkbox>
				</AfHelp>	
			</div>;

		var ignoreCompletion =
			<div style={{marginBottom: "25px"}}>
				<AfHelp alignItems="center" description={this.props.content.ignoreCompletion.description}>
					<Checkbox checked={entity.ignoreCompletion == null || entity.ignoreCompletion ? "checked" : ""} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "ignoreCompletion", e.target.checked)}>
						{this.props.content.ignoreCompletion.name}
					</Checkbox>
				</AfHelp>	
			</div>;

		var textInputProperties;
		if (entity.type === "itemTextInput" || entity.type === "itemTextInputMultiline") {
			var textInputPlaceholder =
				<div>
					<div>
						<AfHelp description={this.props.content.textInputPlaceholder.description}>
							<label>{this.props.content.textInputPlaceholder.name}</label>
						</AfHelp>
					</div>
					<div>
						<input ref="textInputPlaceholder" style={{ marginBottom: "15px" }} className="form-control" type="text" placeholder="(Optional: Enter placeholder text)" value={entity.textInputPlaceholder} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "textInputPlaceholder", e.target.value)} />
					</div>
				</div>;

			var textInputDefaultValue =
				<div>
					<div>
						<AfHelp description={this.props.content.textInputDefaultValue.description}>
							<label>{this.props.content.textInputDefaultValue.name}</label>
						</AfHelp>
					</div>
					<div>
						<input ref="textInputDefaultValue" style={{ marginBottom: "15px" }} className="form-control" type="text" placeholder="(Optional: Enter default text)" value={entity.textInputDefaultValue} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "textInputDefaultValue", e.target.value)} />
					</div>
				</div>;

			var textInputMaxLength =
				<div>
					<div>
						<AfHelp description={this.props.content.textInputMaxLength.description}>
							<label>{this.props.content.textInputMaxLength.name}</label>
						</AfHelp>
					</div>
					<div>
						<input ref="textInputMaxLength" style={{ marginBottom: "15px" }} className="form-control" type="text" placeholder="(Optional: Enter maximum number of characters)" value={entity.textInputMaxLength} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "textInputMaxLength", e.target.value)} />
					</div>
				</div>;

			var textInputMaskType =
				<div>
					<div>
						<AfHelp description={this.props.content.textInputMaskType.description}>
							<label>{this.props.content.textInputMaskType.name}</label>
						</AfHelp>
					</div>
					<div>
						<TextInputMaskType value={entity.textInputMaskType} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "textInputMaskType", e.target.value)} />
					</div>
				</div>;

			var textInputKeyboardType =
				<div>
					<div>
						<AfHelp description={this.props.content.keyboardType.description}>
							<label>{this.props.content.keyboardType.name}</label>
						</AfHelp>
					</div>
					<div>
						<TextInputKeyboardType value={entity.textInputKeyboardType} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "textInputKeyboardType", e.target.value)} />
					</div>
				</div>;
			var textInputKeyboardAutoCapitalize =
				<div>
					<div>
						<AfHelp description={this.props.content.keyboardAutoCapitalize.description}>
							<label>{this.props.content.keyboardAutoCapitalize.name}</label>
						</AfHelp>
					</div>
					<div>
						<TextInputAutoCapitalize value={entity.textInputKeyboardAutoCapitalize} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "textInputKeyboardAutoCapitalize", e.target.value)} />
					</div>
				</div>;
			var textInputKeyboardAutoCorrect =
				<div>
					<AfHelp alignItems="center" description={this.props.content.keyboardAutoCorrect.description}>
						<Checkbox checked={entity.textInputKeyboardAutoCorrect == null || entity.textInputKeyboardAutoCorrect ? "checked" : ""} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "textInputKeyboardAutoCorrect", e.target.checked)}>
							{this.props.content.keyboardAutoCorrect.name}
						</Checkbox>
					</AfHelp>	
				</div>;
			var textInputKeyboardReturnKeyType =
				<div>
					<div>
						<AfHelp description={this.props.content.keyboardReturnKeyType.description}>
							<label>{this.props.content.keyboardReturnKeyType.name}</label>
						</AfHelp>
					</div>
					<div>
						<TextInputKeyboardReturnKeyType value={entity.textInputKeyboardReturnKeyType} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "textInputKeyboardReturnKeyType", e.target.value)} />
					</div>
				</div>;

			var keyboardPanel =
				<AfPanel defaultExpanded title="Keyboard">
					{textInputKeyboardType}
					{textInputKeyboardAutoCapitalize}
					{textInputKeyboardAutoCorrect}
					{textInputKeyboardReturnKeyType}
				</AfPanel>;

			var textInputProperties =
			<AfPanel defaultExpanded title="Text Input">
				{textInputPlaceholder}
				{textInputDefaultValue}
				{textInputMaxLength}
				{textInputMaskType}
			</AfPanel>;
		}

		var yesNoProperties;
		if (entity.type === "itemYesNo") {
			var yesNoLink =
				<AfPanel defaultExpanded title="Link">
					<div>
						<AfHelp alignItems="center" description={this.props.content.yesNoLinkOnSelect.description}>
							<Checkbox checked={entity.yesNoLinkOnSelect == null || entity.yesNoLinkOnSelect ? "checked" : ""} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "yesNoLinkOnSelect", e.target.checked)}>
								{this.props.content.yesNoLinkOnSelect.name}
							</Checkbox>
						</AfHelp>	
					</div>
					<div>
						<div>
							<AfHelp description={this.props.content.yesNoYesLinkId.description}>
								<label>{this.props.content.yesNoYesLinkId.name}</label>
							</AfHelp>
						</div>
						<div>
							<input style={{ marginBottom: "15px" }} className="form-control" type="text" placeholder="(Enter Yes ID to link to)" value={entity.yesNoYesLinkId} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "yesNoYesLinkId", e.target.value)} />
						</div>
					</div>
					<div>
						<div>
							<AfHelp description={this.props.content.yesNoNoLinkId.description}>
								<label>{this.props.content.yesNoNoLinkId.name}</label>
							</AfHelp>
						</div>
						<div>
							<input style={{ marginBottom: "15px" }} className="form-control" type="text" placeholder="(Enter No ID to link to)" value={entity.yesNoNoLinkId} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "yesNoNoLinkId", e.target.value)} />
						</div>
					</div>
					<div>
						<div>
							<AfHelp description={this.props.content.yesNoLinkAction.description}>
								<label>{this.props.content.yesNoLinkAction.name}</label>
							</AfHelp>
						</div>
						<div>
							<LinkActionType user={this.props.user} value={entity.yesNoLinkActionType} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "yesNoLinkActionType", e.target.value)} />
						</div>
					</div>
				</AfPanel>

			yesNoProperties =
				<div>
					{yesNoLink}
				</div>;
		}


		var pickerProperties;
		if (entity.type === "itemPicker") {
			var pickerLink =
				<AfPanel defaultExpanded title="Link">
					<div>
						<AfHelp alignItems="center" description={this.props.content.pickerLinkOnSelect.description}>
							<Checkbox checked={entity.pickerLinkOnSelect == null || entity.pickerLinkOnSelect ? "checked" : ""} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "pickerLinkOnSelect", e.target.checked)}>
								{this.props.content.pickerLinkOnSelect.name}
							</Checkbox>
						</AfHelp>	
					</div>
					<div>
						<div>
							<AfHelp description={this.props.content.pickerLinkAction.description}>
								<label>{this.props.content.pickerLinkAction.name}</label>
							</AfHelp>
						</div>
						<div>
							<LinkActionType user={this.props.user} value={entity.pickerLinkActionType} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "pickerLinkActionType", e.target.value)} />
						</div>
					</div>
				</AfPanel>

			var pickerItems =
				<AfPanel defaultExpanded title="Picker Items">
					<div>
						<div>
							<AfHelp description={this.props.content.pickerViewType.description}>
								<label>{this.props.content.pickerViewType.name}</label>
							</AfHelp>
						</div>
						<div>
							<PickerViewType value={entity.pickerItemViewType} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "pickerItemViewType", e.target.value)} />
						</div>
					</div>
					<div>
						<MediaQuery query='(max-width: 1023px)'>
							<NameValuePairEditor prop={["entity", "pickerItems"]} items={entity.pickerItems} nameField="label" valueField="value" propertiesField="properties" layout="vertical" />
						</MediaQuery>
						<MediaQuery query='(min-width: 1024px)'>
							<NameValuePairEditor prop={["entity", "pickerItems"]} items={entity.pickerItems} nameField="label" valueField="value" propertiesField="properties" layout="horizontal" />
						</MediaQuery>
					</div>
					<div>
						<AfHelp description={this.props.content.pickerPlaceholder.description}>
							<label>{this.props.content.pickerPlaceholder.name}</label>
						</AfHelp>
					</div>
					<div>
						<input style={{ marginBottom: "15px" }} className="form-control" type="text" placeholder="(Enter placeholder value)" value={entity.pickerItemPlaceholder} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "pickerItemPlaceholder", e.target.value)} />
					</div>
					<div>
						<AfHelp description={this.props.content.pickerDefaultValue.description}>
							<label>{this.props.content.pickerDefaultValue.name}</label>
						</AfHelp>
					</div>
					<div>
						<input style={{ marginBottom: "15px" }} className="form-control" type="text" placeholder="(Enter default value)" value={entity.pickerItemDefaultValue} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "pickerItemDefaultValue", e.target.value)} />
					</div>
				</AfPanel>

			var pickerAdvanceOnSelect =
				<div>
					<AfHelp alignItems="center" description={this.props.content.pickerAdvanceOnSelect.description}>
						<Checkbox checked={entity.pickerAdvanceOnSelect == null || entity.pickerAdvanceOnSelect ? "checked" : ""} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "pickerAdvanceOnSelect", e.target.checked)}>
							{this.props.content.pickerAdvanceOnSelect.name}
						</Checkbox>
					</AfHelp>	
				</div>


			pickerProperties =
				<div>
					{pickerItems}
					{pickerAdvanceOnSelect}
					{pickerLink}
				</div>;
		}

		var imagePickerProperties;
		var imagePickerMediaType;
		if (!this.props.appCapabilities.mediaUploadPhotosOnly) {
			imagePickerMediaType =
				<div>
					<div>
						<AfHelp description={this.props.content.mediaType.description}>
							<label>{this.props.content.mediaType.name}</label>
						</AfHelp>
					</div>
					<div>
						<MediaType value={entity.imagePickerMediaType} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "imagePickerMediaType", e.target.value)} />
					</div>
				</div>;
		}
		if (entity.type === "itemImagePicker") {
			var imagePickerAddMediaButtonTitle =
				<div>
					<div>
						<AfHelp description={this.props.content.mediaAddMediaButtonTitle.description}>
							<label>{this.props.content.mediaAddMediaButtonTitle.name}</label>
						</AfHelp>
					</div>
					<div>
						<input ref="imagePickerAddMediaButton" style={{ marginBottom: "15px" }} className="form-control" type="text" placeholder="(Enter title for add media button)" value={entity.imagePickerAddMediaButton} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "imagePickerAddMediaButton", e.target.value)} />
					</div>
				</div>;
			var imagePickerUploadTitle =
				<div>
					<div>
						<AfHelp description={this.props.content.mediaUploadTitle.description}>
							<label>{this.props.content.mediaUploadTitle.name}</label>
						</AfHelp>
					</div>
					<div>
						<input ref="imagePickerUploadTitle" style={{ marginBottom: "15px" }} className="form-control" type="text" placeholder="(Enter title for upload panel)" value={entity.imagePickerUploadTitle} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "imagePickerUploadTitle", e.target.value)} />
					</div>
				</div>;
			var imagePickerCaptureMediaButtonTitle =
				<div>
					<div>
						<AfHelp description={this.props.content.mediaCaptureMediaButtonTitle.description}>
							<label>{this.props.content.mediaCaptureMediaButtonTitle.name}</label>
						</AfHelp>
					</div>
					<div>
						<input ref="imagePickerCaptureMediaTitle" style={{ marginBottom: "15px" }} className="form-control" type="text" placeholder="(Enter title for button to upload)" value={entity.imagePickerCaptureMediaTitle} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "imagePickerCaptureMediaTitle", e.target.value)} />
					</div>
				</div>;

			imagePickerProperties =
				<AfPanel style={{ marginTop: "25px", marginBottom: "0px" }} defaultExpanded title="Media Upload">
					<div>
						{imagePickerMediaType}
						{imagePickerAddMediaButtonTitle}
						{imagePickerUploadTitle}
						{imagePickerCaptureMediaButtonTitle}
					</div>
				</AfPanel>

		}

		var popoverSketchPadBackgroundColor =
			<Popover id="popover-sketch-padbackground-color" title="Sketch Pad Background Color">
				<SketchPicker
					disableAlpha={true}
					color={entity.sketchPadBackgroundColor}
					onChangeComplete={(color, e) => this.props.dispatch(actions.handleChangeProperty, "sketchPadBackgroundColor", color.hex)}
					presetColors={globals.defaultColors}
				/>
			</Popover>

		var popoverSketchPadPenColor =
			<Popover id="popover-sketch-pad-pen-color" title="Sketch Pad Pen Color">
				<SketchPicker
					disableAlpha={true}
					color={entity.sketchPadPenColor}
					onChangeComplete={(color, e) => this.props.dispatch(actions.handleChangeProperty, "sketchPadPenColor", color.hex)}
					presetColors={globals.defaultColors}
				/>
			</Popover>

		var sketchPadProperties;
		if (entity.type === "itemSketchPad") {
			var sketchPadHeight =
				<div>
					<div style={{ marginBottom: "15px" }}>
						<AfHelp description={this.props.content.sketchPadHeight.description}>
							<label>{this.props.content.sketchPadHeight.name}</label>
						</AfHelp>
					</div>
					<div>
						<input ref="sketchPadHeight" style={{ marginBottom: "15px" }} className="form-control" type="text" placeholder="(Enter height for the sketch pad)" value={entity.sketchPadHeight} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "sketchPadHeight", e.target.value)} />
					</div>
				</div>;
			var sketchPadBackgroundColor =
				<div>
					<div>
						<AfHelp description={this.props.content.sketchPadBackgroundColor.description}>
							<label>{this.props.content.sketchPadBackgroundColor.name}</label>
						</AfHelp>
					</div>
					<div>
						<OverlayTrigger trigger="click" rootClose placement="top" overlay={popoverSketchPadBackgroundColor}>
							<div style={{ cursor: "pointer", border: "2px solid black", borderRadius: "5px", width: "32px", height: "32px", backgroundColor: entity.sketchPadBackgroundColor }} />
						</OverlayTrigger>
					</div>
				</div>;
			var sketchPadPenColor =
				<div>
					<div>
						<AfHelp description={this.props.content.sketchPadPenColor.description}>
							<label>{this.props.content.sketchPadPenColor.name}</label>
						</AfHelp>
					</div>
					<div>
						<OverlayTrigger trigger="click" rootClose placement="top" overlay={popoverSketchPadPenColor}>
							<div style={{ cursor: "pointer", border: "2px solid black", borderRadius: "5px", width: "32px", height: "32px", backgroundColor: entity.sketchPadPenColor }} />
						</OverlayTrigger>
					</div>
				</div>;
			var sketchPadPenWidth =
				<div>
					<div style={{ marginTop: "15px" }}>
						<AfHelp description={this.props.content.sketchPadPenWidth.description}>
							<label>{this.props.content.sketchPadPenWidth.name}</label>
						</AfHelp>
					</div>
					<div>
						<input ref="sketchPadPenWidth" style={{ marginBottom: "15px" }} className="form-control" type="text" placeholder="(Enter width for the pen)" value={entity.sketchPadPenWidth} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "sketchPadPenWidth", e.target.value)} />
					</div>
				</div>;

			sketchPadProperties =
				<AfPanel defaultExpanded title="Sketch Pad">
					<div>
						{sketchPadBackgroundColor}
						{sketchPadPenColor}
						{sketchPadPenWidth}
					</div>
				</AfPanel>
		}

		var datePickerProperties;
		if (entity.type === "itemDate" || entity.type === "itemTime" || entity.type === "itemDateTime") {
			var dateTimeTypes =
				<div>
					<div>
						<AfHelp description={this.props.content.dateTimeType.description}>
							<label>{this.props.content.dateTimeType.name}</label>
						</AfHelp>
					</div>
					<div>
						<DateTimeType value={entity.dateTimeType} onChange={(e) => { this.props.dispatch(actions.handleChangeProperty, "dateTimeType", e.target.value) }} />
					</div>
				</div>;

			var datePickerInitialDate =
				<div>
					<div>
						<AfHelp description={this.props.content.dateTimeInitialDate.description}>
							<label>{this.props.content.dateTimeInitialDate.name}</label>
						</AfHelp>
					</div>
					<div>
						<input ref="datePickerInitialDate" style={{ marginBottom: "15px" }} className="form-control" type="text" placeholder="(Enter in YYYY-MM-DD format)" value={entity.dateTimeInitialDate} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "dateTimeInitialDate", e.target.value)} />
					</div>
				</div>;
			var datePickerMinuteInterval =
				<div>
					<div>
						<AfHelp description={this.props.content.dateTimeMinuteInterval.description}>
							<label>{this.props.content.dateTimeMinuteInterval.name}</label>
						</AfHelp>
					</div>
					<div>
						<input ref="datePickerMinuteInterval" style={{ marginBottom: "15px" }} className="form-control" type="text" placeholder="(Enter in minutes)" value={entity.dateTimeMinuteInterval} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "dateTimeMinuteInterval", e.target.value)} />
					</div>
				</div>;

			if (entity.type === "itemTime" || entity.type === "itemDateTime") {
				datePickerProperties =
					<div>
						{dateTimeTypes}
						{datePickerInitialDate}
						{datePickerMinuteInterval}
					</div>;
			} else if (entity.type === "itemDate") {
				datePickerProperties =
					<div>
						{dateTimeTypes}
						{datePickerInitialDate}
					</div>;
			}
		}

		var barcodeScannerProperties;
		if (entity.type === "itemBarcodeScanner") {
			var barcodeTypes =
				<div>
					<div>
						<AfHelp description={this.props.content.barcodeType.description}>
							<label>{this.props.content.barcodeType.name}</label>
						</AfHelp>
					</div>
					<div>
						<BarcodeType value={entity.barcodeTypes} onChange={(value) => { this.props.dispatch(actions.handleChangeProperty, "barcodeTypes", value) }} />
					</div>
				</div>;

			barcodeScannerProperties =
				<div>
					{barcodeTypes}
				</div>;
		}

		// Could add label3 to Alexa but not for now
		var label1AlexaPlain, label1AlexaRich, label1Ssml, label2AlexaPlain, label2AlexaRich, label2Ssml, commentsSsml;
		if (this.props.urlParams.hasOwnProperty("mode") && this.props.urlParams.mode === "smartdisplay") {
			label1AlexaPlain =
				<div>
					<div>
						<label>Label 1 Alexa Override (Plain Text)</label>
					</div>
					<div>
						<textarea style={{ marginBottom: "15px" }} className="form-control" placeholder="(Optional: Label 1 plain content to display on Alexa)" value={entity.label1AlexaPlain} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "label1AlexaPlain", e.target.value)} />
					</div>
				</div>;

			label1AlexaRich =
				<div>
					<div>
						<label>Label 1 Alexa Override (Rich Text)</label>
					</div>
					<div>
						<textarea style={{ marginBottom: "15px" }} className="form-control" placeholder="(Optional: Label 1 rich content to display on Alexa)" value={entity.label1AlexaRich} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "label1AlexaRich", e.target.value)} />
					</div>
				</div>;

			label1Ssml =
				<div>
					<div>
						<label>Label 1 SSML Override</label>
					</div>
					<div>
						<textarea style={{ marginBottom: "15px" }} className="form-control" placeholder="(Optional: Label 1 content to have Alexa speak)" value={entity.label1Ssml} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "label1Ssml", e.target.value)} />
					</div>
				</div>;

			label2AlexaPlain =
				<div>
					<div>
						<label>Label 2 Alexa Override (Plain Text)</label>
					</div>
					<div>
						<textarea style={{ marginBottom: "15px" }} className="form-control" placeholder="(Optional: Label 2 plain content to display on Alexa)" value={entity.label2AlexaPlain} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "label2AlexaPlain", e.target.value)} />
					</div>
				</div>;

			label2AlexaRich =
				<div>
					<div>
						<label>Label 2 Alexa Override (Rich Text)</label>
					</div>
					<div>
						<textarea style={{ marginBottom: "15px" }} className="form-control" placeholder="(Optional: Label 2 rich content to display on Alexa)" value={entity.label2AlexaRich} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "label2AlexaRich", e.target.value)} />
					</div>
				</div>;

			label2Ssml =
				<div>
					<div>
						<label>Label 2 SSML Override</label>
					</div>
					<div>
						<textarea style={{ marginBottom: "15px" }} className="form-control" placeholder="(Optional: Label 2 content to have Alexa speak)" value={entity.label2Ssml} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "label2Ssml", e.target.value)} />
					</div>
				</div>;

			commentsSsml =
				<div>
					<div>
						<label>Comments SSML Override</label>
					</div>
					<div>
						<textarea style={{ marginBottom: "15px" }} className="form-control" placeholder="(Optional: Comments content to have Alexa speak)" value={entity.commentsSsml} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "commentsSsml", e.target.value)} />
					</div>
				</div>;
		}

		var id =
			<div>
				<div>
					<AfHelp description={this.props.content.id.description}>
						<label>{this.props.content.id.name}</label>
					</AfHelp>
				</div>
				<div>
					<input ref="inputId" style={{ marginBottom: "15px" }} className="form-control" type="text" placeholder="(Optional: ID)" value={entity.id} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "id", e.target.value)} />
				</div>
			</div>;

		let groupNameItems = entity.groupNames.map(function (groupName, i) {
			return (
				{
					id: i + 1,
					text: groupName
				}
			);
		});

		let groupNames =
			<div>
				<Flexbox style={{marginBottom: "10px"}} flexDirection="row">
					<div>
						<AfHelp description={this.props.content.groupNames.description}>
							<label>{this.props.content.groupNames.name}</label>
						</AfHelp>
					</div>
					<Button disabled={groupNameItems.length===0} bsSize="small" style={{marginLeft: "5px"}} onClick={ ()=> {
						this.props.dispatch(
							actions.handleCopyGroupNames
						)
					}}><Glyphicon glyph='copy' /></Button>
					<Button disabled={!global.hasOwnProperty("groupNames") || global.groupNames.length===0} style={{marginLeft: "5px"}} bsSize="small" onClick={ ()=> {
						this.props.dispatch(
							actions.handlePasteGroupNames
						)
					}}><Glyphicon glyph='paste' /></Button>
				</Flexbox>
				<div style={{ marginBottom: "15px" }}>
					<ReactTags
						placeholder="Enter a group name"
						tags={groupNameItems}
						suggestions={this.props.groupNames}
						handleDelete={(i) => {
							this.props.dispatch(
								actions.handleDeleteGroupName,
								i
							);
						}}
						handleAddition={(tag) => {
							this.props.dispatch(
								actions.handleAddGroupName,
								tag
							);
						}}
						autofocus={false} />
				</div>
			</div>;

		let filterTagItems = entity.filterTags.map(function (filterTag, i) {
			return (
				{
					id: i + 1,
					text: filterTag
				}
			);
		});

		let filterTags = null;
		if (this.props.hasOwnProperty("user") && this.props.user.hasOwnProperty("subscriptionPlan") && this.props.user.subscriptionPlan.startsWith("pro-plan")) {
			filterTags =
				<div>
					<Flexbox style={{ marginBottom: "10px" }} flexDirection="row">
						<div style={{ marginRight: "25px" }}>
							<AfHelp description={this.props.content.filterTags.description}>
								<label>{this.props.content.filterTags.name}</label>
							</AfHelp>
						</div>
						<Button disabled={filterTagItems.length === 0} bsSize="small" onClick={() => {
							this.props.dispatch(
								actions.handleCopyFilterTags
							)
						}}><Glyphicon glyph='copy' /></Button>
						<Button disabled={!global.hasOwnProperty("filterTags") || global.filterTags.length === 0} style={{ marginLeft: "5px" }} bsSize="small" onClick={() => {
							this.props.dispatch(
								actions.handlePasteFilterTags
							)
						}}><Glyphicon glyph='paste' /></Button>
					</Flexbox>
					<div style={{ marginBottom: "15px" }}>
						<ReactTags
							placeholder="Enter a filter tag"
							tags={filterTagItems}
							suggestions={this.props.filterTags}
							handleDelete={(i) => {
								this.props.dispatch(
									actions.handleDeleteFilterTag,
									i
								);
							}}
							handleAddition={(tag) => {
								this.props.dispatch(
									actions.handleAddFilterTag,
									tag
								);
							}}
							autofocus={false} />
					</div>
				</div>;
		}

		let startNamedTimerSuggs = this.props.timerTemplates.map(function (startNamedTimerSugg, i) {
			if (!startNamedTimerSugg.deleted) {
				return (
					{
						value: startNamedTimerSugg.guid,
						label: startNamedTimerSugg.name
					}
				);
			}
		});

		var startNamedTimerSuggsFiltered = startNamedTimerSuggs.filter(function (el) {
			return el != null;
		});

		let startNamedTimers = null;
		if (this.props.user.hasOwnProperty("subscriptionPlan") && this.props.user.subscriptionPlan.startsWith("pro-plan")) {
			startNamedTimers =
				<div>
					<div>
						<AfHelp description={this.props.content.startNamedTimers.description}>
							<label>{this.props.content.startNamedTimers.name}</label>
						</AfHelp>
					</div>
					<div style={{ marginBottom: "15px" }}>
						<Select
							placeholder="Enter a named timer"
							multi={true}
							value={entity.startNamedTimers}
							options={startNamedTimerSuggsFiltered}
							onChange={(selectedOption) => {
								this.props.dispatch(actions.handleChangeProperty, "startNamedTimers", selectedOption)
							}}
						/>
					</div>
				</div>;
		}


		let stopNamedTimerSuggs = this.props.timerTemplates.map(function (stopNamedTimerSugg, i) {
			if (!stopNamedTimerSugg.deleted) {
				return (
					{
						value: stopNamedTimerSugg.guid,
						label: stopNamedTimerSugg.name
					}
				);
			}
		});

		var stopNamedTimerSuggsFiltered = stopNamedTimerSuggs.filter(function (el) {
			return el != null;
		});

		let stopNamedTimers = null;
		if (this.props.user.hasOwnProperty("subscriptionPlan") && this.props.user.subscriptionPlan.startsWith("pro-plan")) {
			stopNamedTimers =
				<div>
					<div>
						<AfHelp description={this.props.content.stopNamedTimers.description}>
							<label>{this.props.content.stopNamedTimers.name}</label>
						</AfHelp>
					</div>
					<div style={{ marginBottom: "15px" }}>
						<Select
							placeholder="Enter a named timer"
							multi={true}
							value={entity.stopNamedTimers}
							options={stopNamedTimerSuggsFiltered}
							onChange={(selectedOption) => {
								this.props.dispatch(actions.handleChangeProperty, "stopNamedTimers", selectedOption)
							}}
						/>
					</div>
				</div>;
		}


		var label1Advanced =
			<AfPanel style={{marginTop: "15px" }} defaultExpanded={entity.label1Audio && entity.label1Audio !== ""} title="Label 1 Advanced">
				<div>
					{label1AlexaPlain}
					{label1AlexaRich}
					<div style={{marginBottom: "3px", display:"flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
						<AfHelp description={this.props.content.label1NarrationOverride.description}>
							<label>{this.props.content.label1NarrationOverride.name}</label>
						</AfHelp>
					</div>
					<div>
						<textarea style={{ marginBottom: "5px" }} className="form-control" placeholder="(Optional: Label 1 content to be spoken)" value={entity.label1Audio} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "label1Audio", e.target.value)} />
						<Button style={{marginBottom: "15px"}} bsSize="small" onClick={()=>{
							this.props.dispatch(
								actions.handleCopyPasteProperty,
								"label",
								"label1Audio"
							);												
						}}><Glyphicon glyph='paste' /> Paste Label 1</Button>
					</div>
					{label1Ssml}
					<div style={{marginBottom: "3px", display:"flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
						<AfHelp description={this.props.content.label1PrintOverride.description}>
							<label>{this.props.content.label1PrintOverride.name}</label>
						</AfHelp>
					</div>
					<div>
						<textarea style={{ marginBottom: "5px" }} className="form-control" placeholder="(Optional: Label 1 content to print)" value={entity.label1Print} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "label1Print", e.target.value)} />
						<Button style={{marginBottom: "15px"}} bsSize="small" onClick={()=>{
							this.props.dispatch(
								actions.handleCopyPasteProperty,
								"label",
								"label1Print"
							);												
						}}><Glyphicon glyph='paste' /> Paste Label 1</Button>
					</div>
					<div>
						<AfHelp description={this.props.content.label1FontColor.description}>
							<label>{this.props.content.label1FontColor.name}</label>
						</AfHelp>
					</div>
					<div>
						<OverlayTrigger trigger="click" rootClose placement="top" overlay={popoverLabel1Color}>
							<div style={{ cursor: "pointer", border: "2px solid black", borderRadius: "5px", width: "32px", height: "32px", backgroundColor: entity.color }} />
						</OverlayTrigger>
						<br />
					</div>
				</div>
			</AfPanel>;

		var label2Advanced =
			<AfPanel style={{marginTop: "15px" }} defaultExpanded={entity.label2Audio && entity.label2Audio !== ""} title="Label 2 Advanced">
				<div>
					{label2AlexaPlain}
					{label2AlexaRich}
					<div style={{marginBottom: "3px", display:"flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
						<AfHelp description={this.props.content.label2NarrationOverride.description}>
							<label>{this.props.content.label2NarrationOverride.name}</label>
						</AfHelp>
					</div>
					<div>
						<textarea style={{ marginBottom: "5px" }} className="form-control" placeholder="(Optional: Label 2 content to be spoken)" value={entity.label2Audio} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "label2Audio", e.target.value)} />
						<Button style={{marginBottom: "15px"}} bsSize="small" onClick={()=>{
							this.props.dispatch(
								actions.handleCopyPasteProperty,
								"label2",
								"label2Audio"
							);												
						}}><Glyphicon glyph='paste' /> Paste Label 2</Button>
					</div>
					{label2Ssml}
					<div style={{marginBottom: "3px", display:"flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
						<AfHelp description={this.props.content.label2PrintOverride.description}>
							<label>{this.props.content.label2PrintOverride.name}</label>
						</AfHelp>
					</div>
					<div>
						<textarea style={{ marginBottom: "5px" }} className="form-control" placeholder="(Optional: Label 2 content to print)" value={entity.label2Print} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "label2Print", e.target.value)} />
						<Button style={{marginBottom: "15px"}} bsSize="small" onClick={()=>{
							this.props.dispatch(
								actions.handleCopyPasteProperty,
								"label2",
								"label2Print"
							);												
						}}><Glyphicon glyph='paste' /> Paste Label 2</Button>
					</div>
					<div>
						<AfHelp description={this.props.content.label2FontColor.description}>
							<label>{this.props.content.label2FontColor.name}</label>
						</AfHelp>
					</div>
					<div>
						<OverlayTrigger trigger="click" rootClose placement="top" overlay={popoverLabel2Color}>
							<div style={{ cursor: "pointer", border: "2px solid black", borderRadius: "5px", width: "32px", height: "32px", backgroundColor: entity.label2Color }} />
						</OverlayTrigger>
						<br />
					</div>
				</div>
			</AfPanel>;

		var label3Advanced =
			<AfPanel style={{marginTop: "15px" }} defaultExpanded={entity.label3Audio && entity.label3Audio !== ""} title="Label 3 Advanced">
				<div>
					<div style={{marginBottom: "3px", display:"flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
						<AfHelp description={this.props.content.label3NarrationOverride.description}>
							<label>{this.props.content.label3NarrationOverride.name}</label>
						</AfHelp>
					</div>
					<div>
						<textarea style={{ marginBottom: "5px" }} className="form-control" placeholder="(Optional: Label 3 content to be spoken)" value={entity.label3Audio} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "label3Audio", e.target.value)} />
						<Button style={{marginBottom: "15px"}} bsSize="small" onClick={()=>{
							this.props.dispatch(
								actions.handleCopyPasteProperty,
								"label3",
								"label3Audio"
							);												
						}}><Glyphicon glyph='paste' /> Paste Label 3</Button>
					</div>
					<div style={{marginBottom: "3px", display:"flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
						<AfHelp description={this.props.content.label3PrintOverride.description}>
							<label>{this.props.content.label3PrintOverride.name}</label>
						</AfHelp>
					</div>
					<div>
						<textarea style={{ marginBottom: "5px" }} className="form-control" placeholder="(Optional: Label 3 content to print)" value={entity.label3Print} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "label3Print", e.target.value)} />
						<Button style={{marginBottom: "15px"}} bsSize="small" onClick={()=>{
							this.props.dispatch(
								actions.handleCopyPasteProperty,
								"label3",
								"label3Print"
							);												
						}}><Glyphicon glyph='paste' /> Paste Label 3</Button>
					</div>
					<div>
						<AfHelp description={this.props.content.label3FontColor.description}>
							<label>{this.props.content.label3FontColor.name}</label>
						</AfHelp>
					</div>
					<div>
						<OverlayTrigger trigger="click" rootClose placement="top" overlay={popoverLabel3Color}>
							<div style={{ cursor: "pointer", border: "2px solid black", borderRadius: "5px", width: "32px", height: "32px", backgroundColor: entity.label3Color }} />
						</OverlayTrigger>
						<br />
					</div>
				</div>
			</AfPanel>;

		var commentsAdvanced =
			<AfPanel style={{marginTop:5}}  defaultExpanded={entity.commentsAudio && entity.commentsAudio !== ""} title="Comments Advanced">
				<div>
					<div>
						<AfHelp description={this.props.content.commentsNarrationOverride.description}>
							<label>{this.props.content.commentsNarrationOverride.name}</label>
						</AfHelp>
					</div>
					<div>
						<textarea style={{ marginBottom: "15px" }} className="form-control" placeholder="(Optional: Comments content to be spoken)" value={entity.commentsAudio} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "commentsAudio", e.target.value)} />
					</div>
					{commentsSsml}
					<div>
						<AfHelp description={this.props.content.commentsPrintOverride.description}>
							<label>{this.props.content.commentsPrintOverride.name}</label>
						</AfHelp>
					</div>
					<div>
						<textarea style={{ marginBottom: "15px" }} className="form-control" placeholder="(Optional: Comments content to print)" value={entity.commentsPrint} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "commentsPrint", e.target.value)} />
					</div>
				</div>
			</AfPanel>;

		var suppressPrint =
			<div>
				<AfHelp alignItems="center" description={this.props.content.suppressPrint.description}>
					<Checkbox checked={entity.suppressPrint == null || entity.suppressPrint ? "checked" : ""} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "suppressPrint", e.target.checked)}>
						{this.props.content.suppressPrint.name}
					</Checkbox>
				</AfHelp>	
			</div>;

		var suppressPrintComments =
			<div>
				<AfHelp alignItems="center" description={this.props.content.suppressPrintComments.description}>
					<Checkbox checked={entity.suppressPrintComments == null || entity.suppressPrintComments ? "checked" : ""} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "suppressPrintComments", e.target.checked)}>
						{this.props.content.suppressPrintComments.name}
					</Checkbox>
				</AfHelp>	
			</div>;

		var timer;
		if (entity.type === "item") {
			timer =
				<div>
					<div>
						<AfHelp alignItems="center" description={this.props.content.startTimerWhenChecked.description}>
							<Checkbox checked={entity.hasOwnProperty("startTimer") && entity.startTimer ? "checked" : ""} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "startTimer", e.target.checked)}>
								{this.props.content.startTimerWhenChecked.name}
							</Checkbox>
						</AfHelp>	
					</div>
					<div>
						<AfHelp alignItems="center" description={this.props.content.stopTimerWhenChecked.description}>
							<Checkbox checked={entity.hasOwnProperty("stopTimer") && entity.stopTimer ? "checked" : ""} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "stopTimer", e.target.checked)}>
								{this.props.content.stopTimerWhenChecked.name}
							</Checkbox>
						</AfHelp>	
					</div>
				</div>
		}

		var trackLocation =
			<div>
				<div>
					<AfHelp alignItems="center" description={this.props.content.startTrackingWhenChecked.description}>
						<Checkbox checked={entity.hasOwnProperty("startTracking") && entity.startTracking ? "checked" : ""} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "startTracking", e.target.checked)}>
							{this.props.content.startTrackingWhenChecked.name}
						</Checkbox>
					</AfHelp>	
				</div>
				<div>
					<AfHelp alignItems="center" description={this.props.content.stopTrackingWhenChecked.description}>
						<Checkbox checked={entity.hasOwnProperty("stopTracking") && entity.stopTracking ? "checked" : ""} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "stopTracking", e.target.checked)}>
							{this.props.content.stopTrackingWhenChecked.name}
						</Checkbox>
					</AfHelp>	
				</div>
			</div>;

		var valueTrigger = 
			<AfPanel defaultExpanded={false} onToggle={(e)=>{setTimeout(()=> {this.instance.refresh()}, 0)}} title="Value Trigger">
				<div>
					<AfHelp description={this.props.content.valueTrigger.description}>
						<label>{this.props.content.valueTrigger.name}</label>
					</AfHelp>
				</div>	
				<div style={{ height: "300px", overflow: "auto" }}>
					<CodeMirror
						value={entity.valueTrigger}
						options={{
							mode: "javascript",
							lineNumbers: true
						}}
						onBeforeChange={(editor, data, value) => {
							this.props.dispatch(actions.handleChangeProperty, "valueTrigger", value);
						}}
						editorDidMount={editor => { this.instance = editor; }}
					/>
				</div>
			</AfPanel>;

		return (
			<div style={{ width: "100%", height: "100%" }}>
				<div style={{ display: "flex", alignItems: "center" }}>
					<MediaQuery query='(max-width: 767px)'>
						{(!this.state.inlineEdit1 && !this.state.inlineEdit2 && !this.state.open) &&
							<div style={{ flex: 0, marginLeft: "5px", marginRight: "10px" }}>
								<Glyphicon style={{ color: "transparent" }} glyph="plus" />
							</div>
						}
					</MediaQuery>
					<Panel style={{ width: "100%", flex: 1, marginBottom: "0px" }} bsStyle="success" defaultExpanded={this.state.open} expanded={this.state.open} onToggle={(e) => { this.setState({ open: !this.state.open }) }}>
					<Panel.Heading style={{ borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px", padding: "5px 10px" }}>
						<Panel.Title>
							{header}
						</Panel.Title>
					</Panel.Heading>
					<Panel.Collapse>
						<Panel.Body>
							{this.state.open && 
							<div>
								{type}
								{labelOnly}
								<hr/>
								{label1}
					
								{label1Advanced}
								<hr/>
								{label2}
								{label2Advanced}
								<hr/>
								{label3}
								{label3Advanced}
								<hr/>
								<div>
									<AfHelp alignItems="center" description={this.props.content.suppressAudio.description}>
										<Checkbox checked={entity.suppressAudio==null || entity.suppressAudio ? "checked" : ""} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "suppressAudio", e.target.checked)}>
											{this.props.content.suppressAudio.name}
										</Checkbox>
									</AfHelp>	
								</div>
								<hr/>
								{id}
								<hr/>
								{comments}
								{(entity.comments && entity.comments !== "") && commentsAdvanced}
								{suppressPrintComments}
								<div style={{ marginBottom: 25 }}>
									<AfHelp alignItems="center" description={this.props.content.commentsInline.description}>
										<Checkbox checked={entity.commentsInline == null || entity.commentsInline ? "checked" : ""} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "commentsInline", e.target.checked)}>
											{this.props.content.commentsInline.name}
										</Checkbox>
									</AfHelp>	
								</div>
								<hr/>
								{mandatory}
								{visible}
								{ignoreCompletion}
								<MediaPanel node={this.props.node} />
								{textInputProperties}
								{keyboardPanel}
								{yesNoProperties}
								{pickerProperties}
								{datePickerProperties}
								{imagePickerProperties}
								{barcodeScannerProperties}
								{sketchPadProperties}

								<AfPanel style={{ marginTop: "25px", marginBottom: "0px" }} bsStyle="success" defaultExpanded={this.state.advancedOpen} title="Advanced">
									{id}
									{filterTags}
									{groupNames}
									{link}
									{valueTrigger}
									<div style={{ marginTop: "25px", marginBottom: "0px" }}>
										<PrintProperties entity={entity} />
									</div>
									{/* <div>
										<input type="checkbox" label="Don't Speak Audio" checked={entity.suppressAudio==null || entity.suppressAudio ? "checked" : ""} onChange={(e) => this.props.dispatch(actions.handleChangeProperty, "suppressAudio", e.target.checked)} />
									</div> */}
									{suppressPrint}
									{trackLocation}
									{timer}
									{this.props.capabilitiesTimers && this.props.capabilitiesTimers && this.props.user.hasOwnProperty("subscriptionPlan") && this.props.user.subscriptionPlan.startsWith("pro-plan") && startNamedTimers}
									{this.props.capabilitiesTimers && this.props.capabilitiesTimers && this.props.user.hasOwnProperty("subscriptionPlan") && this.props.user.subscriptionPlan.startsWith("pro-plan") && stopNamedTimers}
								</AfPanel>
							</div>
							}
						</Panel.Body>
					</Panel.Collapse>
				</Panel>
				</div>
				{ckEditorModal}
			</div>
		);
	}
}

export default branch({
	user: ['user'],
	selectedNodePath: ['selectedNodePath'],
	urlParams: ['appState', 'urlParams'],
	appCapabilities: ['appCapabilities'],
	groupNames: ["groupNames"],
	filterTags: ["filterTags"],
	timerTemplates: ["timerTemplates"],
	capabilitiesTimers: ["appCapabilities", "timers"],
	content: ["content","item","properties","properties"]
}, EditorItem);

/*
	<div>
		<input type="checkbox" label="Don't Speak Audio" checked={entity.suppressAudio==null || entity.suppressAudio ? "checked" : ""} onChange={this.handleChangeSuppressAudio} />
	</div>
	<Button onClick={this.getBase64FromImageUrl.bind(this)}>Get Data URI</Button>
*/