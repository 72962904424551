import React from 'react';

//import shallowCompare from 'react-addons-shallow-compare';

class HomeLink extends React.PureComponent {
	/*
	shouldComponentUpdate(nextProps, nextState) {
		return shallowCompare(this, nextProps, nextState);
	}
*/
	render() {

		var miracheckLink;
		miracheckLink = <a href="https://checklist.miracheck.com/" style={{ textDecoration: 'none', color: 'inherit' }}>
			<div style={{ display: "flex", alignItems: "center", margin: "10px" }}>
				<img width="32" height="32" src="/assets/goose-g-logo-300x300.png" />
				<div style={{ marginLeft: "10px", fontWeight: "normal", fontSize: "24px", color: "#fdfdfd" }}>Goose Cloud</div>
			</div>
		</a>
		// miracheckLink = 
		// 	<a style={{ marginTop: 10, marginLeft: 10, fontSize: 30 }} className="navbar-brand" href="https://www.miracheck.com/index.html#page-top">Goose Cloud</a>

		// See if we are being hosted in a webview
		// WebView
		try {
			window.ReactNativeWebView.postMessage(JSON.stringify({ method: "dummy" }));
			miracheckLink = <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>
				<img width="32" height="32" src="/assets/goose-g-logo-300x300.png" />
				<div style={{ marginLeft: "10px", fontWeight: "normal", fontSize: "24px", color: "#fdfdfd" }}>Goose Cloud</div>
			</div>
			// miracheckLink = 
			// 	<a style={{ marginTop: 10, marginLeft: 10, fontSize: 30 }} className="navbar-brand" href="#">Goose Cloud</a>
		} catch (e) {
		}
		// WKWebView
		try {
			window.webkit.messageHandlers.reactNative.postMessage(JSON.stringify({ method: "dummy" }));
			miracheckLink =
				<a href="https://checklist.miracheck.com/" style={{ textDecoration: 'none', color: 'inherit' }}>
					<div style={{ display: "flex", alignItems: "center", margin: "10px" }}>
						<img width="32" height="32" src="/assets/goose-g-logo-300x300.png" />
						<div style={{ marginLeft: "10px", fontWeight: "normal", fontSize: "24px", color: "#fdfdfd" }}>Goose Cloud</div>
					</div>
				</a >
			// miracheckLink = 
			// 	<a style={{ marginTop: 10, marginLeft: 10, fontSize: 30 }} className="navbar-brand" href="#">Goose Cloud</a>
		} catch (e) {
		}
		// UIWebView
		try {
			window.postMessage(JSON.stringify({ method: "dummy" }));
			miracheckLink =
				<a href="https://checklist.miracheck.com/" style={{ textDecoration: 'none', color: 'inherit' }}>

					<div style={{ display: "flex", alignItems: "center", margin: "10px" }}>
						<img width="32" height="32" src="/assets/goose-g-logo-300x300.png" />
						<div style={{ marginLeft: "10px", fontWeight: "normal", fontSize: "24px", color: "#fdfdfd" }}>Goose Cloud</div>
					</div>
				</a>
			// miracheckLink = 
			// 	<a style={{ marginTop: 10, marginLeft: 10, fontSize: 30 }} className="navbar-brand" href="#">Goose Cloud</a>
		} catch (e) {
		}

		return (
			<div className="navbar-header">
				{miracheckLink}
			</div>
		);
	}
}

export default HomeLink;