import * as actions from '../actions/actions';
import * as dynamoDb from '../persistence/dynamoDb';
import * as awsS3 from '../persistence/s3';
import * as utils from '../utils/utils';
import globals from '../constants/globals.js';
import {cognito} from '../constants/globals.js';
import { contentBucket } from '../constants/globals.js'; 

import state from '../state/state';

import _ from 'lodash';

import { AuthenticationDetails, CognitoUserPool, CognitoUserAttribute, CognitoUser } from 'amazon-cognito-identity-js';
import AWS from 'aws-sdk';
import browserHistory from '../history';
import { uploadAiFileToS3 } from '../ai/aiAssistant.js';

var timerTemplates = require('../data/timerTemplates.json');

var request = require('superagent');

AWS.config.update({ region: 'us-east-1' });

export function signUp(user) {
	state.set("user", user);

	registerUser(user.username, user.password, user.email, user.name);
	//registerUser(user.username, user.password, user.email, user.name, user.nickname, user.website);
}

export function setUsername(tree, username) {
	tree.set(["user", "username"], username);
}

export function verifyUser(username, code) {
	confirmRegisteredUser(username, code);
}

export function resendVerifyCode(username) {
	setUsername(state, username);
	resendConfirmation(username);
}

export function forgotPasswordForUser(username) {
	forgotPassword(username);
}

export function changeForgottenPasswordForUser(username, code, newPassword) {
	changeForgottenPassword(username, code, newPassword);
}

export function login(tree, username, password, savePassword) {
	loginUser(tree, username, password, savePassword);
}

export function upload() {
	//uploadToS3();
}
export function uploadFile(bucket, keyPrefix, file) {
	awsS3.uploadFileToS3(bucket, keyPrefix, file);
}

export function uploadAiFile(bucket, keyPrefix, file) {
	uploadAiFileToS3(bucket, keyPrefix, file);
}

export function uploadFiles(files) {
	if (awsS3.hasMaxChecklists()) {
		return;
	}

	awsS3.uploadFilesToS3(contentBucket, files);
}

export function uploadMediaFile(file, fileName, type) {
	awsS3.uploadMediaFileToS3("converter.miralouaero.com", file, fileName, type);
}

export function uploadRandomSpoonacularRecipe() {
	if (awsS3.hasMaxChecklists()) {
		return;
	}

	awsS3.uploadRandomSpoonacularRecipeToS3(contentBucket);
}

export function logout() {
	logoutUser(state.get(["user", "username"]));
}

function startsOrEndsWithWhitespace(str) {
	if (str === "") {
		return false;
	} else {
		return /^\s|\s$/.test(str);
	}
}



function registerUser(username, password, email, name, nickname = "", website = "", profile = "", picture = "") {
	if (startsOrEndsWithWhitespace(username)) {
		alert("There is a leading or trailing space on the Username. Please correct this.");
		return;
	}
	if (startsOrEndsWithWhitespace(password)) {
		alert("There is a leading or trailing space on the Password. Please correct this.");
		return;
	}
	if (startsOrEndsWithWhitespace(email)) {
		alert("There is a leading or trailing space on the Email. Please correct this.");
		return;
	}
	if (startsOrEndsWithWhitespace(name)) {
		alert("There is a leading or trailing space on the Name. Please correct this.");
		return;
	}

	var poolData = {
		UserPoolId: cognito.userPoolId, // Your user pool id here
		ClientId: cognito.clientId // Your client id here
	};
	var userPool = new CognitoUserPool(poolData);

	var attributeList = [];

	var dataEmail = {
		Name: 'email',
		Value: email
	};

	var dataName = {
		Name: 'name',
		Value: name
	};

	var attributeEmail = new CognitoUserAttribute(dataEmail);
	var attributeName = new CognitoUserAttribute(dataName);

	attributeList.push(attributeEmail);
	attributeList.push(attributeName);

	var dataNickname = {
		Name: 'nickname',
		Value: nickname
	};

	var attributeNickname = new CognitoUserAttribute(dataNickname);
	attributeList.push(attributeNickname);

	var dataWebsite = {
		Name: 'website',
		Value: website
	};

	var attributeWebsite = new CognitoUserAttribute(dataWebsite);
	attributeList.push(attributeWebsite);

	var dataProfile = {
		Name: 'profile',
		Value: profile
	};

	var attributeProfile = new CognitoUserAttribute(dataProfile);
	attributeList.push(attributeProfile);

	var dataPicture = {
		Name: 'picture',
		Value: picture
	};

	var attributePicture = new CognitoUserAttribute(dataPicture);
	attributeList.push(attributePicture);

	userPool.signUp(username, password, attributeList, null, function (err, result) {
		if (err) {
			if (err.code === "InvalidPasswordException" || (err.code === "InvalidParameterException" && err.message.toLowerCase().includes("password"))) {
				alert("Make sure your password has a minimum of 8 characters, and at least one uppecase character, one lowercase character and one numeral.");
			} else {
				alert(err.message);
			}
			return;
		}
		var cognitoUser = result.user;
		console.log('user name is ' + cognitoUser.getUsername());

		const path = "/verify";
		browserHistory.push(path);
	});
}

export function loginUserIgapp(username, password) {	
	utils.setScr(password);
	utils.setSaveScr(true);

	var poolData = {
		UserPoolId: cognito.userPoolId, // Your user pool id here
		ClientId: cognito.clientId // Your client id here
	};
	var userPool = new CognitoUserPool(poolData);
	var userData = {
		Username: username,
		Pool: userPool
	};
	var cognitoUser = new CognitoUser(userData);

	var authenticationData = {
		Username: username,
		Password: password,
	};
	var authenticationDetails = new AuthenticationDetails(authenticationData);

	try {
		cognitoUser.authenticateUser(authenticationDetails, {
			onSuccess: function (result) {
				handlePostAuthenticate(result, cognitoUser, state, username);
			},
	
			onFailure: function (err) {
				if (err.code === "UserNotFoundException" || err.code === "NotAuthorizedException") {
					// Need to register user
					var poolData = {
						UserPoolId: cognito.userPoolId, // Your user pool id here
						ClientId: cognito.clientId // Your client id here
					};
					var userPool = new CognitoUserPool(poolData);
				
					var attributeList = [];
				
					var dataEmail = {
						Name: 'email',
						Value: 'support+igapp.' + username + '@miralouaero.com'
					};

					var dataName = {
						Name: 'name',
						Value: username
					};

					var attributeEmail = new CognitoUserAttribute(dataEmail);
					var attributeName = new CognitoUserAttribute(dataName);

					attributeList.push(attributeEmail);
					attributeList.push(attributeName);

					userPool.signUp(username, password, attributeList, null, function (err, result) {
						if (err) {
							if (err.code === "InvalidPasswordException" || (err.code === "InvalidParameterException" && err.message.toLowerCase().includes("password"))) {
								alert("Make sure your password has a minimum of 8 characters, and at least one uppecase character, one lowercase character and one numeral.");
							} else {
								alert(err.message);
							}
							return;
						}
						var cognitoUser = result.user;
						console.log('user name is ' + cognitoUser.getUsername());
		
						// Login user
						loginUser(state, username, password, true);
					});

				} else if (err.code === "NotAuthorizedException") {
					utils.hideLoader(state);
					alert(err.message);		
				} else {
					utils.hideLoader(state);
					alert(err.message);		
				}
			}
		});	
	} catch (err) {
		utils.hideLoader(state);
		alert("WHY???");		
		// alert(err.message);		
	}
}

function resendConfirmation(username) {
	var poolData = {
		UserPoolId: cognito.userPoolId, // Your user pool id here
		ClientId: cognito.clientId // Your client id here
	};

	var userPool = new CognitoUserPool(poolData);
	var userData = {
		Username: username,
		Pool: userPool
	};

	var cognitoUser = new CognitoUser(userData);

	cognitoUser.resendConfirmationCode(function (err, result) {
		if (err) {
			actions.showError("Resend confirmation", `There was a problem resending the confirmation code: ${err.message}`);
			return;
		}

		const path = "/verify";
		browserHistory.push(path);
	});
}

function confirmRegisteredUser(username, code) {
	var poolData = {
		UserPoolId: cognito.userPoolId, // Your user pool id here
		ClientId: cognito.clientId // Your client id here
	};

	var userPool = new CognitoUserPool(poolData);
	var userData = {
		Username: username,
		Pool: userPool
	};

	var cognitoUser = new CognitoUser(userData);
	cognitoUser.confirmRegistration(code, true, function (err, result) {
		if (err) {
			actions.showError("Account confirmation", `There was a problem confirming your account: ${err.message}`);
			return;
		}

		console.log('call result: ' + result);

		const path = "/login";
		browserHistory.push(path);
	});
}

function forgotPassword(username) {
	var poolData = {
		UserPoolId: cognito.userPoolId, // Your user pool id here
		ClientId: cognito.clientId // Your client id here
	};

	var userPool = new CognitoUserPool(poolData);
	var userData = {
		Username: username,
		Pool: userPool
	};

	var cognitoUser = new CognitoUser(userData);
	cognitoUser.forgotPassword({
		onSuccess: function (result) {
			console.log('call result: ' + result);
		},
		onFailure: function (err) {
			actions.showError("Password reset", `There was a problem requesting the password reset: ${err.message}`);
		},
		//Optional automatic callback
		inputVerificationCode: function (data) {
			console.log('Code sent to: ' + data);
			const path = "/forgotPassword";
			browserHistory.push(path);
		}
	});
}

function changeForgottenPassword(username, code, newPassword) {
	var poolData = {
		UserPoolId: cognito.userPoolId, // Your user pool id here
		ClientId: cognito.clientId // Your client id here
	};

	var userPool = new CognitoUserPool(poolData);
	var userData = {
		Username: username,
		Pool: userPool
	};

	var cognitoUser = new CognitoUser(userData);
	cognitoUser.confirmPassword(code, newPassword, {
		onSuccess: function onSuccess(result) {
			actions.showSuccess("Password Reset", "Your password was successfully reset!");
			const path = "/login";
			browserHistory.push(path);
		},
		onFailure: function onFailure(err) {
			actions.showError("Password reset", `There was a problem resetting your password. ${err.message}`);
		}
	});
}

function updateAttribute(cognitoUser, name, value) {
	return new Promise(
		function (resolve, reject) {
			try {
				if (cognitoUser && cognitoUser.updateAttributes) {
					const attributeList = [];
					const params = {
						Name: name,
						Value: _.isString(value) ? value : JSON.stringify(value),
					};
					const attribute = new CognitoUserAttribute(params);
					attributeList.push(attribute);
		
					cognitoUser.updateAttributes(attributeList, (err, result) => {
						if (err) {
							alert("Failed to update user attributes\n\n" + err + " for " + name + "; " + value);
							reject(err);
							return;
						}
						console.log(`call result: ${result}`);

						resolve();
					});
				}
			} catch (e) {
				alert("Failed to set user attribute " + e + " for " + name + "; " + value);
				reject(e);
			}
		}
	);	
}

function deviceExists(device, devices) {
	for (var i = 0; i < devices.length; i++) {
		if (device.deviceId === devices[i].deviceId) {
			return true;
		}
	}

	return false;
}

export function updateItunesSubscription(itunesSubscription) {
	return new Promise(
		function (resolve, reject) {
			var poolData = {
				UserPoolId: cognito.userPoolId, // Your user pool id here
				ClientId: cognito.clientId // Your client id here
			};
			var userPool = new CognitoUserPool(poolData);
			var cognitoUser = userPool.getCurrentUser();
		
			if (cognitoUser != null) {
				cognitoUser.getSession(function (err, session) {
					if (err) {
						// actions.showError("Failed getting session", err);
						reject(err);
						return;
					}
					console.log('session validity: ' + session.isValid());
		
					var logins = {};
					logins[cognito.logins] = session.getIdToken().getJwtToken();
		
					AWS.config.credentials = new AWS.CognitoIdentityCredentials({
						IdentityPoolId: cognito.identityPoolId, // your identity pool id here
						Logins: logins,
						region: cognito.region
					});
					AWS.config.credentials.refresh(function () {
						AWS.config.credentials.get(async function (err) {
							if (!err) {
								await updateAttribute(cognitoUser, "custom:itunesSubscription", JSON.stringify(itunesSubscription));

								resolve();
							} else {
								// alert(err.message);
								reject(err);
							}
						});
					});
				});
			}
		}
	);	

}

function getUserMetadata(result) {
	var user = {};
	for (var i = 0; i < result.length; i++) {
		user[result[i].getName()] = result[i].getValue();
	}

	return user;
}

function refreshTokenForMobile(cognitoUser) {
	cognitoUser.getSession(function (err, session) {
		if (err) {
			return;
		} else {
			const jwtToken = session.getIdToken().getJwtToken();

			// WebView
			try {
				window.ReactNativeWebView.postMessage(JSON.stringify({ jwt: jwtToken }));
			} catch (e) {
			}
			// WKWebView
			try {
				window.webkit.messageHandlers.reactNative.postMessage(JSON.stringify({ jwt: jwtToken }));
			} catch (e) {
			}
			// UIWebView
			try {
				window.postMessage(JSON.stringify({ jwt: jwtToken }));
			} catch (e) {
			}
		}
	});
}

function refreshTokenForMobileOld(cognitoUser) {
	cognitoUser.getSession(function (err, session) {
		// var idTokenExpire = session.getIdToken().getExpiration();
		var refreshToken = session.getRefreshToken();
		// var currentTimeSeconds = Math.round(+new Date() / 1000);

		// alert((idTokenExpire < currentTimeSeconds ? "Expired" : "Not expired") + " " + session.getIdToken().getJwtToken());
		// if (idTokenExpire < currentTimeSeconds) {
			cognitoUser.refreshSession(refreshToken, (err, session) => {
				if (err) {
					return;
				} else {
					const jwtToken = session.getIdToken().getJwtToken();

					// WebView
					try {
						window.ReactNativeWebView.postMessage(JSON.stringify({ jwt: jwtToken }));
					} catch (e) {
					}
					// WKWebView
					try {
						window.webkit.messageHandlers.reactNative.postMessage(JSON.stringify({ jwt: jwtToken }));
					} catch (e) {
					}
					// UIWebView
					try {
						window.postMessage(JSON.stringify({ jwt: jwtToken }));
					} catch (e) {
					}
				}
			});
		// } else {
		// 	const jwtToken = session.getIdToken().getJwtToken();

		// 	// WebView
		// 	try {
		// 		window.ReactNativeWebView.postMessage(JSON.stringify({ jwt: jwtToken }));
		// 	} catch (e) {
		// 	}
		// 	// WKWebView
		// 	try {
		// 		window.webkit.messageHandlers.reactNative.postMessage(JSON.stringify({ jwt: jwtToken }));
		// 	} catch (e) {
		// 	}
		// 	// UIWebView
		// 	try {
		// 		window.postMessage(JSON.stringify({ jwt: jwtToken }));
		// 	} catch (e) {
		// 	}
		// }
	});
}

function handlePostAuthenticate(result, cognitoUser, tree, username) {
	refreshTokenForMobile(cognitoUser);

	// Every five minutes refresh token for mobile side
	setInterval(function() {
		refreshTokenForMobile(cognitoUser);
	},60000*10)

	console.log('access token + ' + result.getAccessToken().getJwtToken());

	const igapp = state.get(["appCapabilities","igapp"]);

	AWS.config.region = 'us-east-1';
	var logins = {};
	logins[cognito.logins] = result.getIdToken().getJwtToken();

	AWS.config.credentials = new AWS.CognitoIdentityCredentials({
		IdentityPoolId: cognito.identityPoolId, // your identity pool id here
		Logins: logins,
		region: cognito.region
	});

	//AWS.config.credentials.refresh(function(){
	// Make the call to obtain credentials
	AWS.config.credentials.get(function (err) {
		if (!err) {
			// Credentials will be available when this function is called.
			// Only need for API Gateway call
			var accessKeyId = AWS.config.credentials.accessKeyId;
			var secretAccessKey = AWS.config.credentials.secretAccessKey;
			var sessionToken = AWS.config.credentials.sessionToken;

			if (igapp) {
				state.set(["user", "identityId"], _.get(result, "idToken.payload.sub", null));
				console.log("IDENTITY ID: " + _.get(result, "idToken.payload.sub", null));
			} else {
				state.set(["user", "identityId"], AWS.config.credentials.identityId.split(":")[1]);
				console.log("IDENTITY ID: " + AWS.config.credentials.identityId);
			}
			// state.set(["user","jwt"],result.getIdToken().getJwtToken());

			// state.set(["user", "loggedIn"], true);

			var lambda = new AWS.Lambda({ region: "us-east-1", apiVersion: '2015-03-31' });
			var params = {
				FunctionName: globals.lambdaFunctionNames.getCognitoUser,
				InvocationType: 'RequestResponse',
				LogType: 'None',
				Payload: JSON.stringify({ username: username })
			};

			lambda.invoke(params, function (error, data) {
				// Get Cognito User
				var myCognitoUser;

				if (error) {
					alert(error.message);
				} else {
					myCognitoUser = JSON.parse(data.Payload)
					console.log(myCognitoUser);
					state.set(["user", "createDate"], myCognitoUser.UserCreateDate);
					// Set Algolia settings
					var lambda = new AWS.Lambda({ region: "us-east-1", apiVersion: '2015-03-31' });
					var params = {
						FunctionName: globals.lambdaFunctionNames.algoliaSetSettings,
						InvocationType: 'RequestResponse',
						LogType: 'None',
						Payload: JSON.stringify({ identityId: state.get(["user", "identityId"]) })
					};

					lambda.invoke(params, function (error, data) {
						if (error) {
							alert(error.message);
						} else {
						}
					});

					// Set all user attributes
					cognitoUser.getUserAttributes(async function (err, result) {
						if (err) {
							alert(err.message);
							return;
						}

						var keyPrefix = utils.getKeyPrefix(AWS.config.credentials.identityId);

						// if sso login we should call endpoint, otherwise do everything below
						if (!igapp && state.get(["appState", "urlParams", "sso"]) && state.get(["appState", "urlParams", "sig"])) {
							var userMetadata = getUserMetadata(result);

							var body = {
								sso: state.get(["appState", "urlParams", "sso"]),
								sig: state.get(["appState", "urlParams", "sig"]),
								externalId: keyPrefix,
								email: userMetadata.email,
								name: userMetadata.name,
								username: username
							};
							/*			
							fetch('https://6qlld3f4yl.execute-api.us-east-1.amazonaws.com/prod/discourse-sso', {
								method: 'POST',
								body: JSON.stringify(body),
							  }).then(function(response) {
								  debugger;
								  window.location.replace("https://forum.miralouaero.com/session/sso_login?" + JSON.parse(response.body));
							  }).catch(function(error) {
								  debugger;
								console.log("error");
								});
							  */


							request
								.post('https://6qlld3f4yl.execute-api.us-east-1.amazonaws.com/prod/discourse-sso')
								.send(body)
								.end(function (err, res) {
									if (err || !res.ok) {
										alert(err.message);
									} else {
										window.location.replace("https://forum.miralouaero.com/session/sso_login?" + res.body.q);
									}
								});
						} else {
							state.set(["user", "nickname"], "");
							state.set(["user", "website"], "");
							state.set(["user", "profile"], "");
							state.set(["user", "picture"], "");

							var webSubscriptionPlan;
							var itunesSubscriptionPlan;
							var itunesExpiration;

							for (var i = 0; i < result.length; i++) {
								if (result[i].getName() === "email_verified") {
									state.set(["user", "emailVerified"], result[i].getValue());
								} else if (result[i].getName() === "custom:subscriptionId") {
									state.set(["user", "subscriptionId"], result[i].getValue());
								} else if (result[i].getName() === "custom:subscriptionPlan") {
									webSubscriptionPlan = result[i].getValue();
								} else if (result[i].getName() === "custom:stripeCustomerId") {
									state.set(["user", "stripeCustomerId"], result[i].getValue());
								} else if (result[i].getName() === "custom:devices") {
									if (result[i].getValue().length > 0) {
										state.set(["devices"], JSON.parse(result[i].getValue()));
									}
								} else if (result[i].getName() === "custom:itunesSubscription") {
									var sub = JSON.parse(result[i].getValue());
									itunesSubscriptionPlan = sub.plan;
									itunesExpiration = sub.expiration;
								} else if (result[i].getName() === "custom:devicesBlacklist") {
									if (result[i].getValue().length > 0) {
										//state.set(["devicesBlacklist"], JSON.parse(result[i].getValue()));
										// WebView
										try {
											window.ReactNativeWebView.postMessage(JSON.parse(result[i].getValue()));
										} catch (e) {
										}
										// WKWebView
										try {
											window.webkit.messageHandlers.reactNative.postMessage(JSON.parse(result[i].getValue()));
										} catch (e) {
										}
										// UIWebView
										try {
											window.postMessage(result[i].getValue());
										} catch (e) {
										}
									}
								} else if (result[i].getName() === "custom:historyUpdated2") {
									state.set(["user", "historyUpdated"], result[i].getValue() === "true" ? true : false);
								} else {
									state.set(["user", result[i].getName()], result[i].getValue());
								}
								console.log(result[i].getName(), result[i].getValue());
							}

							// If have a pro web subscription, trumps all
							if (webSubscriptionPlan == null) {
								//state.set(["user","subscriptionPlan"],"basic-plan");
							} else if (webSubscriptionPlan.startsWith("pro-plan")) {
								state.set(["user", "subscriptionPlan"], webSubscriptionPlan);
							} else {
								// If have a iTunes subscription
								if (itunesSubscriptionPlan != null) {
									// If itunes subscription expired
									var current = new Date().getTime();
									if ((itunesSubscriptionPlan === "pro-plan-yearly-itunes" || itunesSubscriptionPlan === "pro-plan-monthly-itunes") && current > itunesExpiration) {
										state.set(["user", "subscriptionPlan"], webSubscriptionPlan);
									} else {
										state.set(["user", "subscriptionPlan"], itunesSubscriptionPlan);
										state.set(["user", "subscriptionExpiration"], itunesExpiration);
									}
								} else {
									state.set(["user", "subscriptionPlan"], webSubscriptionPlan);
								}
							}

							state.set(["user", "username"], username);

							var user = state.get(["user"]);

							if (!igapp && (!user.hasOwnProperty("historyUpdated") || !user.historyUpdated)) {
								awsS3.touchHistoryFiles(user.identityId);
							}

							var redirectRemoveDevices = false;

							if (!igapp) {
								var userCreateDate = new Date(user.createDate);
								var userCreateDateEpoch = userCreateDate.getTime();
	
								var currentDevice = state.get(["device"]);
								var devices = state.get(["devices"]);
								var deviceFound = false;
								// If device array is empty then add and good to go
								// Check if the current device is in the devices array
								// If it is we are good to go
								// If it is not then redirect to remove a device
								if (currentDevice !== null) {
									if (!user.hasOwnProperty("subscriptionPlan")) {
										if (!deviceExists(currentDevice, devices)) {
											devices.push(currentDevice);
											updateAttribute(cognitoUser, "custom:devices", JSON.stringify(devices));
										}
									} else if (user.subscriptionPlan === "basic-plan" && devices.length < 1) {
										if (!deviceExists(currentDevice, devices)) {
											devices.push(currentDevice);
											updateAttribute(cognitoUser, "custom:devices", JSON.stringify(devices));
										}
									} else if (user.subscriptionPlan === "basic-plan-checkmate" && devices.length < 3) {
										if (!deviceExists(currentDevice, devices)) {
											devices.push(currentDevice);
											updateAttribute(cognitoUser, "custom:devices", JSON.stringify(devices));
										}
										//} else if (user.subscriptionPlan.substring( 0, "standard-plan".length ) === "standard-plan" && devices.length < 3) {
									} else if (user.subscriptionPlan==="no-plan" && devices.length < 1) {
										if (!deviceExists(currentDevice, devices)) {
											devices.push(currentDevice);
											updateAttribute(cognitoUser, "custom:devices", JSON.stringify(devices));
										}
									} else if (user.subscriptionPlan.startsWith("standard-plan") && devices.length < 3) {
										if (!deviceExists(currentDevice, devices)) {
											devices.push(currentDevice);
											updateAttribute(cognitoUser, "custom:devices", JSON.stringify(devices));
										}
									} else if (user.subscriptionPlan.startsWith("pro-plan") && userCreateDateEpoch < 1543554000000 && devices.length < 5) {
										//} else if (user.subscriptionPlan.substring( 0, "pro-plan".length ) === "pro-plan" && devices.length < 5) {
										if (!deviceExists(currentDevice, devices)) {
											devices.push(currentDevice);
											updateAttribute(cognitoUser, "custom:devices", JSON.stringify(devices));
										}
									} else if (user.subscriptionPlan.startsWith("pro-plan") && userCreateDateEpoch >= 1543554000000 && devices.length < 3) {
										//} else if (user.subscriptionPlan.substring( 0, "pro-plan".length ) === "pro-plan" && devices.length < 5) {
										if (!deviceExists(currentDevice, devices)) {
											devices.push(currentDevice);
											updateAttribute(cognitoUser, "custom:devices", JSON.stringify(devices));
										}
									} else {
										for (var i = 0; i < devices.length; i++) {
											var device = devices[i];
											if (currentDevice.deviceId === device.deviceId) {
												deviceFound = true;
											}
										}
	
										redirectRemoveDevices = !deviceFound;
									}
								}	
							}


							if (!redirectRemoveDevices && !igapp) {
								// Subscribe them to basic plan if never have been subscribed
								// If there is a subscription plan parameter then could create basic or pro plan immediately

								if (!user.hasOwnProperty("subscriptionPlan")) {
									var lambda = new AWS.Lambda({ region: "us-east-1", apiVersion: '2015-03-31' });
									var params = {
										FunctionName: globals.lambdaFunctionNames.subscribe,
										InvocationType: 'RequestResponse',
										LogType: 'None',
										Payload: JSON.stringify({ email: user.email, identityId: user.identityId, stage: globals.stage })
									};

									lambda.invoke(params, async function (error, data) {
										if (error) {
											alert(error.message);
										} else {
											if (data.hasOwnProperty("FunctionError") && data.FunctionError === "Handled") {
												var objError = JSON.parse(data.Payload);
												alert(objError.errorMessage);
											} else {
												var results = JSON.parse(data.Payload);
												if (results.hasOwnProperty("errorMessage")) {
													alert(results.errorMessage);
												} else {
													updateAttribute(cognitoUser, "custom:subscriptionId", results.id);
													state.set(["user", "subscriptionId"], results.id);
													updateAttribute(cognitoUser, "custom:subscriptionPlan", "no-plan");
													state.set(["user", "subscriptionPlan"], "no-plan");
													// This should have given us a unique Stripe customer_id. We need to associate this with user and use for future requests.
													updateAttribute(cognitoUser, "custom:stripeCustomerId", results.customer);
													state.set(["user", "stripeCustomerId"], results.customer);

													/*
													// Comment out to get history dropdown
													try {
														// First check to see if exists in Dynamo DB
														var data = await dynamoDb.getUserPromise(keyPrefix);
														if (!_.isEmpty(data)) {
															var user = data.Item;
															console.log(user);
															if (user.hasOwnProperty("historyUsers")) {
																if (!_.isEmpty(user.historyUsers)) {
																	var newHistoryUsers = [];
																	for (var i=0; i<user.historyUsers.length; i++) {
																		data = await dynamoDb.getUserPromise(user.historyUsers[i]);
																		newHistoryUsers.push(data.Item);
																	}
							
																	state.set(["user","historyUsers"],newHistoryUsers);
																}
															}
														} else {
															var ts = new Date().toISOString();
	
															console.log("User not found! Create user in DynamoDB.");
															var userPruned = _.cloneDeep(state.get(["user"]));
															delete userPruned.loggedIn;
															delete userPruned.password;
															delete userPruned.code;
															//delete userPruned.email_verified;
															userPruned.signedUp = true;
															userPruned.basicPlan = true;
															userPruned.checkMatePlan = false;
															userPruned.proPlan = false;
															userPruned.createDate = ts;
															userPruned.lastModifiedDate = ts;
															userPruned.enabled = true;
															userPruned.status = "CONFIRMED";
															userPruned.devices = state.get(["devices"]);
							
															try {
																await dynamoDb.putUserPromise(userPruned);
															} catch (e) {
																console.log(e);		
															}
	
															var bucket = new AWS.S3({useDualStack: true, params: { Bucket: contentBucket, ResponseContentType: "application/json", ResponseCacheControl: "max-age=0" } });
															createUserDoc(tree,username,bucket,keyPrefix);
														}
													} catch (e) {
														console.log(e);
													}
													*/
													var bucket = new AWS.S3({ useDualStack: true, params: { Bucket: contentBucket, ResponseContentType: "application/json", ResponseCacheControl: "max-age=0" } });
													createUserDoc(tree, username, bucket, keyPrefix);
												}
											}
										}
									});
								} else {
									// try {
									// 	var data = await dynamoDb.getUserHistoryPromise(keyPrefix);
									// 	if (!_.isEmpty(data)) {
									// 		var users = data.Items;
									// 		console.log(users);

									// 		var newHistoryUsers = [];
									// 		for (var i = 0; i < users.length; i++) {
									// 			var user = users[i];
									// 			data = await dynamoDb.getUserPromise(user.memberIdentityId);
									// 			if (data.Item != null) {
									// 				newHistoryUsers.push(data.Item);
									// 			}
									// 		}

									// 		state.set(["user", "historyUsers"], newHistoryUsers);
									// 	}
									// } catch (e) {
									// 	console.log(e);
									// }

									var bucket = new AWS.S3({ useDualStack: true, params: { Bucket: contentBucket, ResponseContentType: "application/json", ResponseCacheControl: "max-age=0" } });
									createUserDoc(tree, username, bucket, keyPrefix);
								}
							} else if (redirectRemoveDevices && !igapp) {
								utils.hideLoader(tree);
								alert("You have too many devices registered.", () => {
									const path = "/devices";
									browserHistory.push(path);
								});
							// If IGAPP set to pro and go	
							} else {
								if (!user.hasOwnProperty("subscriptionPlan")) {
									updateAttribute(cognitoUser, "custom:subscriptionPlan", "pro-plan-per-year");
									state.set(["user", "subscriptionPlan"], "pro-plan-per-year");
									var bucket = new AWS.S3({ useDualStack: true, params: { Bucket: contentBucket, ResponseContentType: "application/json", ResponseCacheControl: "max-age=0" } });
									createUserDoc(tree, username, bucket, keyPrefix);
								} else {
									var bucket = new AWS.S3({ useDualStack: true, params: { Bucket: contentBucket, ResponseContentType: "application/json", ResponseCacheControl: "max-age=0" } });
									createUserDoc(tree, username, bucket, keyPrefix);
								}
							}
						}
					});
				}
			})

			//});
		} else {
			utils.hideLoader(tree);
			alert(err.message);
			console.log(err);
		}
	});
	//});

}

function createUserDoc(tree, username, bucket, keyPrefix) {
	// Shouldn't need to add to S3 now 
	var s3 = new AWS.S3({ useDualStack: true });
	var bucket = new AWS.S3({ useDualStack: true, params: { Bucket: contentBucket, ResponseContentType: "application/json", ResponseCacheControl: "max-age=0" } });

	// First check if bucket exists for user...if not create
	bucket.getObject({ Key: keyPrefix + "/user.json" }, async function (err, data) {
		if (err) {
			var putBatch = function putBatch(files) {
				return Promise.all(files.map(function (file) {
					var body;
					if (file == "user.json") {
						var user = _.cloneDeep(state.get(["user"]));
						delete user.loggedIn;
						delete user.password;
						delete user.code;
						delete user.email_verified;
						body = JSON.stringify(user);
					}
					return bucket.putObject({ Key: keyPrefix + "/" + file, ContentType: "application/json", Body: body }).promise()
				}));
			};

			var files = ["user.json"];

			putBatch(files)
				.then(async function () {
					// await awsS3.putJsonFileToS3(contentBucket,"userAssets/timerTemplates.json",timerTemplates);
					var newTimerTemplates = await receiveTimerTemplates(tree, keyPrefix);
					tree.set(["timerTemplates"], newTimerTemplates);
					tree.set(["user", "loggedIn"], true);
					awsS3.getChecklistsCollection(tree, username, bucket, keyPrefix, []);
				})
				.catch(function () {
					console.log("Error putiing user!")
				});

		} else {
			try {
				var newTimerTemplates = await receiveTimerTemplates(tree, keyPrefix);
				tree.set(["timerTemplates"], newTimerTemplates);
								
			} catch(e) {
				alert("Error: " + e.message);
			}
			// TODO: Could compare user attributes and update if necessary
			tree.set(["user", "loggedIn"], true);
			awsS3.getChecklistsCollection(tree, username, bucket, keyPrefix, []);
		}
	});
}

function receiveTimerTemplates(tree, keyPrefix) {
	return new Promise(async (resolve, reject) => {
		try {
			var s3 = new AWS.S3({useDualStack: true});
			var timerTemplatesData = await s3.getObject({Bucket: contentBucket, Key: keyPrefix + "/userAssets/timerTemplates.json"}).promise();
			var newTimerTemplates = JSON.parse(timerTemplatesData.Body.toString());
	
			resolve(newTimerTemplates);
			// If exists then set otherwise set a template of timers
		} catch (err) {
			if (err.statusCode === 404) {
				await awsS3.putJsonFileToS3(contentBucket,"userAssets/timerTemplates.json",timerTemplates);
				resolve(timerTemplates);
			} else {
				reject(err);
			}
		}
	})
}

function loginUser(tree, username, password, savePassword) {
	/*
	// WKWebView
	try {
		window.webkit.messageHandlers.reactNative.postMessage(JSON.stringify({ method: "login" }));
	} catch (e) {
	}
	// UIWebView
	try {
		window.postMessage(JSON.stringify({ method: "login" }));
	} catch (e) {
	}
	*/

	utils.setScr(password);
	utils.setSaveScr(savePassword);

	var poolData = {
		UserPoolId: cognito.userPoolId, // Your user pool id here
		ClientId: cognito.clientId // Your client id here
	};
	var userPool = new CognitoUserPool(poolData);
	var userData = {
		Username: username,
		Pool: userPool
	};
	var cognitoUser = new CognitoUser(userData);

	var authenticationData = {
		Username: username,
		Password: password,
	};
	var authenticationDetails = new AuthenticationDetails(authenticationData);

	cognitoUser.authenticateUser(authenticationDetails, {
		onSuccess: function (result) {

			handlePostAuthenticate(result, cognitoUser, tree, username);

		},

		onFailure: function (err) {
			/*
			AWS.config.update({
			region: 'us-east-1',
			credentials: new AWS.CognitoIdentityCredentials({
				AccountId: '186175052854', // your AWS account ID
				RoleArn: 'arn:aws:iam::186175052854:role/Cognito_CheckMateUnauth_Role',
				IdentityPoolId: 'us-east-1:86585d99-1999-447c-b19c-a98bdb8a60be'
			})
		});
		*/
			utils.hideLoader(tree);
			alert(err.message);

			// if (err.message.startsWith("PostAuthentication failed with error Too many devices registered")) {
			// 	// Set the region where your identity pool exists (us-east-1, eu-west-1)
			// 	AWS.config.region = 'us-east-1';

			// 	// Configure the credentials provider to use your identity pool
			// 	AWS.config.credentials = new AWS.CognitoIdentityCredentials({
			// 		IdentityPoolId: 'us-east-1:86585d99-1999-447c-b19c-a98bdb8a60be',
			// 	});

			// 	// Make the call to obtain credentials
			// 	AWS.config.credentials.get(function(){

			// 		// Credentials will be available when this function is called.
			// 		var accessKeyId = AWS.config.credentials.accessKeyId;
			// 		var secretAccessKey = AWS.config.credentials.secretAccessKey;
			// 		var sessionToken = AWS.config.credentials.sessionToken;

			// 		var user = state.get(["user"]);
			// 		var event = {
			// 			username: user.username
			// 		}
			// 		// Redirect to screen to remove devices
			// 		var lambda = new AWS.Lambda({ region: "us-east-1", apiVersion: '2015-03-31' });
			// 		var params = {
			// 			FunctionName: 'listDevices',
			// 			InvocationType: 'RequestResponse',
			// 			LogType: 'None',
			// 			Payload: JSON.stringify(event)
			// 		};

			// 		lambda.invoke(params, function (error, data) {
			// 			if (error) {
			// 				alert(error);
			// 			} else {
			// 				alert("You have too many devices registered.");
			// 				var payload = JSON.parse(data.Payload);
			// 				state.set(["devices"],payload.Devices);
			// 				//console.log(JSON.stringify(state.get(["devices"])));
			// 				const path = "/devices";
			// 				browserHistory.push(path);							
			// 			}
			// 		});
			// 	});						
			// } else {
			// 	alert(err);
			// }
		},
	});

}

export function passwordlessLoginUser(tree, username, challengeResponse) {
	let count = 0;
	var poolData = {
		UserPoolId: cognito.userPoolId, // Your user pool id here
		ClientId: cognito.clientId // Your client id here
	};
	var userPool = new CognitoUserPool(poolData);
	var userData = {
		Username: username,
		Pool: userPool
	};
	var cognitoUser = new CognitoUser(userData);

	var authenticationData = {
		Username: username,
	};
	var authenticationDetails = new AuthenticationDetails(authenticationData);

	cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');

	try {
		cognitoUser.initiateAuth(authenticationDetails, {
			onSuccess: function(result) {
				// User authentication was successful
				handlePostAuthenticate(result, cognitoUser, tree, username);
			},
			onFailure: function(err) {
				// User authentication was not successful
				alert(err);
			},
			customChallenge: function(challengeParameters) {
				if (count >= 1) {
					state.set(["appState","magicLinkFailed"],true);
				} else {
					// User authentication depends on challenge response
					var challengeResponses = challengeResponse;
					cognitoUser.sendCustomChallengeAnswer(challengeResponses, this);
					count++;
				}
			},
		});		
	} catch(err) {
		alert(err);
	}
}

export function restoreSession() {
	if (window.location.href.includes("/magiclink/")) {
		return;
	}

	const igapp = state.get(["appCapabilities","igapp"]);

	console.log("RESTORE SESSION");
	// alert("TRY LOGGING IN");
	utils.showLoader(state);
	return new Promise((resolve, reject) => {
		var t0, t1, t2, t3, t4, t5, t6, t7, t8;
		var times = "";
		var poolData = {
			UserPoolId: cognito.userPoolId, // Your user pool id here
			ClientId: cognito.clientId // Your client id here
		};
		var userPool = new CognitoUserPool(poolData);
		var cognitoUser = userPool.getCurrentUser();
		// alert(JSON.stringify(cognitoUser));
		if (/*(igapp && !utils.isMobile() && cognitoUser !== null) || */(/*!igapp && */cognitoUser !== null)) {
			console.log("HAVE A SESSION");
			cognitoUser.getSession(function (err, session) {
				if (err) {
					console.error(err);
					utils.hideLoader(state);
					return;
				}
				console.log('session validity: ' + session.isValid());

				// NOTE: getSession must be called to authenticate user before calling getUserAttributes
				cognitoUser.getUserAttributes(function (err, attributes) {
					if (err) {
						// Handle error
						console.error(err);
						utils.hideLoader(state);
					} else {
						// Do something with attributes
						console.log("GOT ATTRIBUTES", attributes);
					}
				});
				const username = cognitoUser.username;
				// console.log(cognitoUser);
				handlePostAuthenticate(session, cognitoUser, state, username);
			});
		} else {
			console.log("NO SESSION");
			// Try to login...if user doesn't exist, then create user. Once user is created then login.

			// if (igapp) {
			// 	if (utils.isMobile()) {
			// 		alert(state.get(["user","username"]));
			// 		loginUserIgapp("testuser7","testuser7Password1!");
			// 	}
			// } else {
				utils.hideLoader(state);
			// }
		}
	})

}

function logoutUser(username) {
	var poolData = {
		UserPoolId: cognito.userPoolId, // Your user pool id here
		ClientId: cognito.clientId // Your client id here
	};
	var userPool = new CognitoUserPool(poolData);
	var userData = {
		Username: username,
		Pool: userPool
	};
	var cognitoUser = new CognitoUser(userData);

	cognitoUser.signOut();

	// AWS.config.credentials = new AWS.CognitoIdentityCredentials({
	// 	IdentityPoolId: cognito.identityPoolId, // your identity pool id here
	// 	region: cognito.region
	// });

	if (AWS.config.credentials !== null) {
		AWS.config.credentials.clearCachedId();
		// AWS.config.credentials = null;
		AWS.config.credentials = new AWS.CognitoIdentityCredentials({
			IdentityPoolId: cognito.identityPoolId
		});	
	}

	state.set(["user", "loggedIn"], false);
	state.set(["user", "subscriptionPlan"], null);
	state.set(["user", "entryPoint"], null);

	// try {
	// 	if (AWS.config.credentials && AWS.config.credentials.clearCachedId) {
	// 		AWS.config.credentials.clearCachedId();
	// 		AWS.config.credentials = null;
	// 		AWS.config.credentials = new AWS.CognitoIdentityCredentials({
	// 			IdentityPoolId: cognito.identityPoolId
	// 		});	
	// 	}
	// } catch (e) {
	// 	console.error("Failed to clear cached identity id.", e);
	// }

	// WebView
	try {
		window.ReactNativeWebView.postMessage(JSON.stringify({ method: "logout" }));
	} catch (e) {
	}
	// WKWebView
	try {
		window.webkit.messageHandlers.reactNative.postMessage(JSON.stringify({ method: "logout" }));
	} catch (e) {
	}
	// UIWebView
	try {
		window.postMessage(JSON.stringify({ method: "logout" }));
	} catch (e) {
	}
	utils.hideLoader(state);
	setTimeout(function () {
		const path = "/login";
		browserHistory.push(path);
	}, 2000);
}

export function removeDevice(deviceKey) {
	var poolData = {
		UserPoolId: 'us-east-1_bPRWn35rR', // Your user pool id here
		ClientId: '6n5vpcb900nqohd0q5bl4bue1j' // Your client id here
	};
	var userPool = new CognitoUserPool(poolData);
	var cognitoUser = userPool.getCurrentUser();

	if (cognitoUser != null) {
		cognitoUser.getSession(function (err, session) {
			if (err) {
				alert(err.message);
				return;
			}
			console.log('session validity: ' + session.isValid());

			AWS.config.credentials = new AWS.CognitoIdentityCredentials({
				IdentityPoolId: "us-east-1:5249265b-becb-4665-b1db-136e81fb4f45", // your identity pool id here
				Logins: {
					// Change the key below according to the specific region your user pool is in.
					'cognito-idp.us-east-1.amazonaws.com/us-east-1_bPRWn35rR': session.getIdToken().getJwtToken()
				},
				region: "us-east-1"
			});

			AWS.config.credentials.refresh(function () {
				AWS.config.credentials.get(function (err) {
					if (!err) {
						var devices = [];
						var devicesBlacklist = [];
						cognitoUser.getUserAttributes(async function (err, result) {
							if (err) {
								alert(err.message);
								return;
							}
							for (var i = 0; i < result.length; i++) {
								if (result[i].getName() === "custom:devices") {
									devices = JSON.parse(result[i].getValue());
								} else if (result[i].getName() === "custom:devicesBlacklist") {
									devicesBlacklist = JSON.parse(result[i].getValue());
								}
							}

							if (devices.length > 0) {
								var index = -1;
								for (var i = 0; i < devices.length; i++) {
									var device = devices[i];
									if (device.deviceId === deviceKey) {
										index = i;
									}
								}

								devices.splice(index, 1);
								updateAttribute(cognitoUser, "custom:devices", JSON.stringify(devices));

								devicesBlacklist.push(deviceKey);
								updateAttribute(cognitoUser, "custom:devicesBlacklist", JSON.stringify(devicesBlacklist));

								state.set(["devices"], devices);
								//state.set(["devicesBlacklist"], devicesBlacklist);
								// Need to post this to mobile app
							}
						});
					} else {
						alert(err.message);
					}
				})
			});
		});
	}
}


// This shouuld work now
export function removeDeviceOld(deviceKey) {
	var poolData = {
		UserPoolId: cognito.userPoolId, // Your user pool id here
		ClientId: cognito.clientId // Your client id here
	};
	var userPool = new CognitoUserPool(poolData);
	var cognitoUser = userPool.getCurrentUser();

	if (cognitoUser != null) {
		cognitoUser.getSession(function (err, session) {
			if (err) {
				alert(err.message);
				return;
			}
			console.log('session validity: ' + session.isValid());

			var logins = {};
			logins[cognito.logins] = session.getIdToken().getJwtToken();

			AWS.config.credentials = new AWS.CognitoIdentityCredentials({
				IdentityPoolId: cognito.identityPoolId, // your identity pool id here
				Logins: logins,
				region: cognito.region
			});

			AWS.config.credentials.refresh(function () {
				AWS.config.credentials.get(function (err) {
					if (!err) {
						var devices = [];
						var devicesBlacklist = [];
						cognitoUser.getUserAttributes(async function (err, result) {
							if (err) {
								alert(err.message);
								return;
							}
							for (var i = 0; i < result.length; i++) {
								if (result[i].getName() === "custom:devices") {
									devices = JSON.parse(result[i].getValue());
								} else if (result[i].getName() === "custom:devicesBlacklist") {
									devicesBlacklist = JSON.parse(result[i].getValue());
								}
							}

							if (devices.length > 0) {
								var index = -1;
								for (var i = 0; i < devices.length; i++) {
									var device = devices[i];
									if (device.deviceId === deviceKey) {
										index = i;
									}
								}

								devices.splice(index, 1);
								updateAttribute(cognitoUser, "custom:devices", JSON.stringify(devices));

								devicesBlacklist.push(deviceKey);
								updateAttribute(cognitoUser, "custom:devicesBlacklist", JSON.stringify(devicesBlacklist));

								state.set(["devices"], devices);
								//state.set(["devicesBlacklist"], devicesBlacklist);
								// Need to post this to mobile app
							}
						});
					} else {
						alert(err.message);
					}
				})
			});
		});
	}
}

export function removeDevice2(deviceKey) {
	// Set the region where your identity pool exists (us-east-1, eu-west-1)
	AWS.config.region = 'us-east-1';

	// Configure the credentials provider to use your identity pool
	AWS.config.credentials = new AWS.CognitoIdentityCredentials({
		IdentityPoolId: 'us-east-1:86585d99-1999-447c-b19c-a98bdb8a60be',
	});

	// Make the call to obtain credentials
	AWS.config.credentials.get(function () {
		// Credentials will be available when this function is called.
		var accessKeyId = AWS.config.credentials.accessKeyId;
		var secretAccessKey = AWS.config.credentials.secretAccessKey;
		var sessionToken = AWS.config.credentials.sessionToken;

		var user = state.get(["user"]);
		var event = {
			username: user.username,
			deviceKey: deviceKey
		}

		var lambda = new AWS.Lambda({ region: "us-east-1", apiVersion: '2015-03-31' });
		var params = {
			FunctionName: globals.lambdaFunctionNames.forgetDevice,
			InvocationType: 'RequestResponse',
			LogType: 'None',
			Payload: JSON.stringify(event)
		};

		lambda.invoke(params, function (error, data) {
			var index = -1;
			if (error) {
				alert(error.message);
			} else {
				var devices = state.get(["devices"]);
				for (var i = 0; i < devices.length; i++) {
					var device = devices[i];
					if (device.DeviceKey === deviceKey) {
						index = i;
					}
				}

				state.splice(["devices"], [index, 1]);
			}
		});
	});
}