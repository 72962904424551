import React from 'react';

import { branch } from 'baobab-react/higher-order';
import * as actions from '../../actions/actions';

import Flexbox from 'flexbox-react';

import Alerts from '../app-alerts.js';

import HomeLink from './app-home-link.js';

import Loader from 'react-loader-advanced';
import FontAwesome from 'react-fontawesome';

import {Link} from 'react-router-dom';

class Passwordless extends React.Component {
	constructor(props, context) {
		super(props, context);

		// Looks like location and match can be used
		actions.passwordlessLogin(this.props.location.pathname);
	}

	render() {
		if (!this.props.magicLinkFailed) {
			const spinner =
			<FontAwesome
				name='spinner'
				size='2x'
				spin />;

			return (
				<Loader show={true} message={spinner}>
					<Flexbox className="myBackground" flexDirection="column">
						<HomeLink />
						<Flexbox flexDirection="column" alignItems="center" justifyContent="center" flexGrow={1}>
							<Alerts />
							<h3 style={{color: "white"}}>MagicLink...Please Wait.</h3>
						</Flexbox>
					</Flexbox>
				</Loader>
			);
		} else {
			return (
			<Flexbox className="myBackground" flexDirection="column">
				<HomeLink />
				<Flexbox flexDirection="column" alignItems="center" justifyContent="center" flexGrow={1}>
					<Alerts />
					<h3 style={{margin: "50px", color: "white", textAlign: "center"}}>The MagicLink was invalid or expired.<br/>You will need to regenerate a new one.</h3>
					<Link style={{ color: "#0E7AFE", fontSize: "16pt" }} to="/login">Go Back</Link>
				</Flexbox>
			</Flexbox>
			);
		}
	}
}

export default branch({
	user: ['user'],
	version: ['version'],
	magicLinkFailed: ['appState','magicLinkFailed']
}, Passwordless);

