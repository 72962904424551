import state from '../state/state';
var algoliasearch = require('algoliasearch');

var client = algoliasearch('D6RLXM2EO4', '5f99830fd8e61878cedbf78e336c19a7');

// Do I want to directly set state here, or control all through actions?
export function searchFeatured(text) {
    var index = client.initIndex('dev_MIRACHECK');
    index.search(text, { hitsPerPage: 10 }, function (err, content) {
        state.set(["search", "featuredSim"], content.hits);
    });
}

export function searchFeatured2() {
    var index = client.initIndex('dev_MIRACHECK');
    var hits1 = [];

    var hitsFeatured = [];
    var hitsFeaturedSims = [];
    var hitsFeaturedSnippets = [];

    index.search("self", { hitsPerPage: 200, restrictSearchableAttributes: "publisher" }, function (err, content) {
        if (err) {
            alert("Error connecting to Algolia.");
            state.set(["search", "featured"], []);
            state.set(["search", "featuredSnippets"], []);
            state.set(["search", "featuredSims"], []);
            return;
        }

        if (content == null) {
            state.set(["search", "featured"], []);
            state.set(["search", "featuredSnippets"], []);
            state.set(["search", "featuredSims"], []);

            return;
        }
        var hits = content.hits;

        hits.sort(compare);

        for (var i = 0; i < hits.length; i++) {
            var foundSim = false;
            var foundSnippet = false;

            var hit = hits[i];

            if (hit.tags.length > 0) {
                for (var j = 0; j < hit.tags.length; j++) {
                    var tag = hit.tags[j];
                    if (tag.toLowerCase() === "simulation") {
                        foundSim = true;
                    } else if (tag.toLowerCase() === "snippet") {
                        foundSnippet = true;
                    } else if (tag.toLowerCase() === "snippet") {
                        foundSnippet = true;
                    }
                }
            }

            if (foundSnippet) {
                hitsFeaturedSnippets.push(hit);
            } else if (foundSim) {
                hitsFeaturedSims.push(hit);
            } else {
                hitsFeatured.push(hit);
            }
        }
        state.set(["search", "featured"], hitsFeatured);
        state.set(["search", "featuredSnippets"], hitsFeaturedSnippets);
        state.set(["search", "featuredSims"], hitsFeaturedSims);
    });
}

function compare(a, b) {
    if (a.name.trim() < b.name.trim())
        return -1;
    if (a.name.trim() > b.name.trim())
        return 1;
    return 0;
}

// export function searchFeatured3() {
//     var index = client.initIndex('dev_MIRACHECK');
//     var hits1 = [];
//     index.search("self", {
//         "getRankingInfo": 1,
//         "facets": "*",
//         "attributesToRetrieve": "*",
//         "highlightPreTag": "<em>",
//         "highlightPostTag": "</em>",
//         "hitsPerPage": 10,
//         "restrictSearchableAttributes": "publisher",
//         "facetFilters": [],
//         "maxValuesPerFacet": 100
//     }, function(err, content) {
//             state.set(["search","featured"],content.hits); 
//         });    
// }


export function searchCheckMate() {
    var index = client.initIndex('dev_MIRACHECK');
    index.search("CheckMate", { hitsPerPage: 100 }, function (err, content) {
        if (err) {
            alert("Error connecting to Algolia.");
            state.set(["search", "checkmate"], []);
            return;
        }
        if (content == null) {
            state.set(["search", "checkmate"], []);
            return;
        }

        var hits = content.hits;
        hits.sort(compare);
        state.set(["search", "checkmate"], hits);
    });
}

export function searchByTag(tagName) {
    
    var index = client.initIndex('dev_MIRACHECK');
    index.search("", { facetFilters: "tags:" + tagName, hitsPerPage: 100 }, function (err, content) {
   
        if (err) {
            alert("Error connecting to Algolia.");
            state.set(["search", tagName], []);
            return;
        }
        if (content == null) {
            state.set(["search", tagName], []);
            return;
        }

        var hits = content.hits;
        hits.sort(compare);
        state.set(["search", tagName], hits);
        
    });
}

// export function searchByGenre(genreName) {
    
//     var index = client.initIndex('dev_MIRACHECK');
//     index.search("", { facetFilters: "genres:" + genreName, hitsPerPage: 100 }, function (err, content) {
   
//         if (err) {
//             alert("Error connecting to Algolia.");
//             state.set(["search", genreName], []);
//             return;
//         }
//         if (content == null) {
//             state.set(["search", genreName], []);
//             return;
//         }

//         var hits = content.hits;
//         hits.sort(compare);
//         state.set(["search", genreName], hits);
        
//     });
// }

