import React from 'react';
import {FormControl} from 'react-bootstrap';

class DateTimeType extends React.PureComponent {
    render() {
        return (
            <FormControl componentClass="select" value={this.props.value} onChange={this.props.onChange}>
                <option value="local">Local Time</option>
                <option value="utc">UTC Time</option>
            </FormControl>
        )    
    }
}

export default DateTimeType;