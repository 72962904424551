import moment from "moment";
import { generateUUID } from "../utils/utils";
import { contentBucket } from '../constants/globals.js'; 

import { putJsonFileToS3 } from "../persistence/s3";

import _ from "lodash";

export function addTimerTemplate(tree) {
	const customTimerTemplate = JSON.stringify(tree.get(["templates", "customTimer"]), null, 3);

	const timerTemplate = {
		guid: generateUUID(),
		timestamp: moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSS"),
		type: "countdown",
		custom: customTimerTemplate,
		name: "",
		mode: "normal",
		viewType: "",
		hours: 0,
		minutes: 0,
		seconds: 0,
		repeat: false,
		repeatCount: 0,
		label1: "",
		label2: "",
		hideDismiss: false,
		suppressAudio: false,
		action: {
			type: "message",
			message: "",
			action: "",
			checklistId: "",
			linkItemId: ""
		}
	};

	tree.set(["appState", "timerModal", "timerTemplate"], timerTemplate);
	tree.set(["appState", "timerModal", "show"], true);
}

export function editTimerTemplate(tree, timerTemplate) {
	tree.set(["appState", "timerModal", "timerTemplate"], timerTemplate);
	tree.set(["appState", "timerModal", "show"], true);
}

export function cancelTimerModal(tree) {
	tree.set(["appState", "timerModal", "timerTemplate"], {});
	tree.set(["appState", "timerModal", "show"], false);
}

export async function deleteTimerTemplate(tree, timerTemplate) {
	const timerTemplates = tree.get(["timerTemplates"]).slice(0);
	const timerTemplateIndex = _.findIndex(timerTemplates, function (o) { return o.guid === timerTemplate.guid; });
	timerTemplates[timerTemplateIndex] = {
		deleted: true,
		timestamp: moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSS"),
		guid: timerTemplate.guid
	};

	tree.set(["timerTemplates"], timerTemplates);

	try {
		await putJsonFileToS3(contentBucket, "userAssets/timerTemplates.json", tree.get(["timerTemplates"]));
	} catch (err) {
		alert("Error: " + err.message);
	}
}

export async function saveTimerTemplate(tree, timerTemplate) {
	if (timerTemplate.name === "") {
		alert("A name is required to save the template.");
		return;
	}

	if (timerTemplate.type !== "custom") {
		if (timerTemplate.hours === 0 && timerTemplate.minutes === 0 && timerTemplate.seconds === 0) {
			alert("A time of at least one second is required.");
			return;
		}
	} else {
		try {
			JSON.parse(timerTemplate.custom);
		} catch (e) {
			alert("Please provide valid JSON for the timer.");
			return;
		}
	}
	const timerTemplates = tree.get(["timerTemplates"]).slice(0);
	const timerTemplateIndex = _.findIndex(timerTemplates, function (o) { return o.guid === timerTemplate.guid; });

	if (timerTemplateIndex === -1) {
		tree.unshift(["timerTemplates"], timerTemplate);
	} else {
		timerTemplates[timerTemplateIndex] = timerTemplate;

		tree.set(["timerTemplates"], timerTemplates);
	}

	try {
		await putJsonFileToS3(contentBucket, "userAssets/timerTemplates.json", tree.get(["timerTemplates"]));
	} catch (err) {
		alert("Error: " + err.message);
	}

	tree.set(["appState", "timerModal", "timerTemplate"], {});
	tree.set(["appState", "timerModal", "show"], false);
}
