import React from 'react';
import globals from '../../constants/globals.js';
import { branch } from 'baobab-react/higher-order';

import * as actions from '../../actions/actions';
import * as awsS3 from '../../persistence/s3';
import { Button } from 'react-bootstrap';
import { Panel } from 'react-bootstrap';

import FontAwesome from 'react-fontawesome';

import Flexbox from 'flexbox-react';
import browserHistory from '../../history';

import MediaQuery from 'react-responsive';

//import shallowCompare from 'react-addons-shallow-compare';

import StripeCheckout from 'react-stripe-checkout';

import Alerts from '../app-alerts.js';

import HomeLink from './app-home-link.js';

class PurchaseCustom extends React.PureComponent {
	constructor(props, context) {
		super(props, context);

		if (!props.user.loggedIn) {
			actions.forceLogin(browserHistory, this.props.location.pathname);
		}
	}

	_onToken = (token) => {
		awsS3.purchaseCustom(token);
	}
	/*
		shouldComponentUpdate(nextProps, nextState) {
			return shallowCompare(this, nextProps, nextState);
		}
	*/
	render() {
		var self = this;

		if (!this.props.user.loggedIn) {
			return (
				<div />
			);
		} else {
			var stripeKey = globals.stage === "prod" ? globals.stripeKeyProd : globals.stripeKeyDev;

			var purchaseCustom =
				<div style={{ width: "100%" }}>
					<StripeCheckout
						name="MiraCheck"
						description="Purchase Custom Aircraft Model"
						image={"assets/checkmate_logo_only_no_border_space_128x128.png"}
						token={(token) => { self._onToken(token) }}
						stripeKey={stripeKey}
						amount={4999}
						email={self.props.user.email}
						ComponentClass="div"
					>
						<Button style={{ color: "#0E7AFE", fontWeight: "bold" }}>$49.99</Button>
					</StripeCheckout>
				</div>;


			return (
				<Flexbox className="myBackground" flexDirection="column">
					<HomeLink />
					<Flexbox flexDirection="column" alignItems="center" justifyContent="center" flexGrow={1}>
						<Alerts />
						<h3 style={{ color: "#eeeeee", padding: "10px" }}>Purchase Custom Aircraft Model</h3>
						<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
							<div style={{ margin: "10px", maxWidth: "800px", textAlign: "left", backgroundColor: "#666666", border: "1px solid #666666", borderRadius: "10px", padding: "10px" }}>
								<p>We will create the content for your aircraft model. Email your request to <a href="mailto:aircraft@miralouaero.com" target="_top">aircraft@miralouaero.com</a> along with photos or scans of the content. Your checklist will show up in your <em>My Hangar</em> within 2-4 weeks.</p>
								<p style={{ fontSize: "smaller" }}><span style={{ color: "red" }}><strong>NOTE:</strong></span> Any content you provide to us that is not copyrighted will be shared with the MiraCheck community. Please indicate if the content you provide is copyrighted.</p>
							</div>
						</div>
						<br />
						<Flexbox flexDirection="row" alignItems="center" justifyContent="center" flexWrap="wrap">
							{purchaseCustom}
						</Flexbox>
					</Flexbox>
				</Flexbox>
			);
		}
	}
}

export default branch({
	user: ["user"],
	version: ["version"]
}, PurchaseCustom);