import AWS from 'aws-sdk';
import _ from 'lodash';

AWS.config.update({region:'us-east-1'});

export var getUserPromise = function(identityId) {
	return new Promise(
		function (resolve, reject) {
			AWS.config.update({
				region: "us-east-1"
			});

			var docClient = new AWS.DynamoDB.DocumentClient();

			var params = {
				TableName: 'Users',
				Key: { // a map of attribute name to AttributeValue for all primary key attributes
					identityId: identityId
				}
			};
			docClient.get(params, function(err, data) {
				if (err) {
					reject(err);
				} else {
					resolve(data);
				}
			});	
		}
	);	
} 


export var putUserPromise = function(user) {
	return new Promise(
		function (resolve, reject) {
			AWS.config.update({
				region: "us-east-1"
			});

			var docClient = new AWS.DynamoDB.DocumentClient();

			var params = {
				TableName: 'Users',
				Item: cleanObj(user)
			};
			docClient.put(params, function(err, data) {
				if (err) {
					reject(err);
				} else {
					resolve(data);
				}
			});	
		}
	);	
} 


export var getUserHistoryPromise = function(identityId) {
	return new Promise(
		function (resolve, reject) {
			AWS.config.update({
				region: "us-east-1"
			});

			var docClient = new AWS.DynamoDB.DocumentClient();

			var params = {
				TableName: 'UserHistory',
				ExpressionAttributeValues: {
					':s': identityId
				   },
				   KeyConditionExpression: 'identityId = :s'
			};
			docClient.query(params, function(err, data) {
				if (err) {
					reject(err);
				} else {
					resolve(data);
				}
			});	
		}
	);	
} 

export var getPrivateSharePromise = function(shareCode) {
	return new Promise(
		function (resolve, reject) {
			AWS.config.update({
				region: "us-east-1"
			});

			var docClient = new AWS.DynamoDB.DocumentClient();

			var params = {
				TableName: 'PrivateShares',
				Key: { // a map of attribute name to AttributeValue for all primary key attributes
					shareCode: shareCode
				}
			};
			docClient.get(params, function(err, data) {
				if (err) {
					reject(err);
				} else {
					resolve(data);
				}
			});	
		}
	);	
} 

export var putPrivateSharePromise = function(privateShare) {
	return new Promise(
		function (resolve, reject) {
			AWS.config.update({
				region: "us-east-1"
			});

			var docClient = new AWS.DynamoDB.DocumentClient();

			var params = {
				TableName: 'PrivateShares',
				Item: cleanObj(privateShare)
			};
			docClient.put(params, function(err, data) {
				if (err) {
					reject(err);
				} else {
					resolve(data);
				}
			});	
		}
	);	
} 

export var putPrivateShareReplacedPromise = function(privateShareReplaced) {
	return new Promise(
		function (resolve, reject) {
			AWS.config.update({
				region: "us-east-1"
			});

			var docClient = new AWS.DynamoDB.DocumentClient();

			var params = {
				TableName: 'PrivateSharesReplaced',
				Item: cleanObj(privateShareReplaced)
			};
			docClient.put(params, function(err, data) {
				if (err) {
					reject(err);
				} else {
					resolve(data);
				}
			});	
		}
	);	
} 

function cleanObj( o ) {
    if( o && o === o )
        if( typeof o === 'boolean' ) return o;
        else if( typeof o === 'number' ) return o;
        else if( typeof o === 'string' ) return o;
        else if( Array.isArray( o ) ) {
            let x = [], i = -1, l = o.length, r = 0;
            while( ++i < l ) if( o[ i ] ) x[ r++ ] = cleanObj( o[ i ] );
            return x;
        } else if( typeof o === 'object' ) {
            for( const k in o ) o[ k ] ? o[ k ] = cleanObj( o[ k ] ) : delete o[ k ];
            return o;
        } else
            return 'Argument Error - Unknown Item';
}

/*
export function getChecklists() {	
	AWS.config.update({
		region: "us-east-1"
	});
debugger;
	var docClient = new AWS.DynamoDB.DocumentClient();

	var params = {
		TableName: "Checklists",
		FilterExpression: 'NOT attribute_type(description, :n)',
		ExpressionAttributeValues: {
			":n": "NULL" 
		}
	};

	console.log("Scanning Checklists table.");
	docClient.scan(params, onScan);
} 

function onScan(err, data) {
	if (err) {
		console.error("Unable to scan the table. Error JSON:", JSON.stringify(err, null, 2));
	} else {
		// print all the movies
		console.log("Scan succeeded.");
		data.Items.forEach(function(checklist) {
			debugger;
			console.log(JSON.stringify(checklist));
		});
	}
}


export var putChecklistPromise = function(checklist) {
	return new Promise(
		function (resolve, reject) {
			AWS.config.update({
				region: "us-east-1"
			});

			var docClient = new AWS.DynamoDB.DocumentClient({convertEmptyValues: true});

			var params = {
				TableName: "Checklists",
				Item: checklist
			};
			docClient.put(params, function(err, data) {
				if (err) {
					reject(err);
				} else {
					resolve(data);
				}
			});	
		}
	);	
} 
*/