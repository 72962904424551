import React from "react";
import { branch } from "baobab-react/higher-order";
import PropTypes from "baobab-react/prop-types";
import { addTimerTemplate } from "../../actions/timerActions";

import Flexbox from "flexbox-react";

import FontAwesome from "react-fontawesome";

class TimerCardNew extends React.PureComponent {
	render() {
		let component = (
			<Flexbox flexDirection="column" alignItems="center" justifyContent="space-between" onClick={() => {
				this.props.dispatch(addTimerTemplate);
			}}>
				<FontAwesome fixedWidth={true} style={{ fontSize: "96px", width: "96px", height: "96px" }} name="plus" />
				<h3 style={{ marginTop: 20, marginBottom: 10 }}>Create new timer</h3>
			</Flexbox>
		);

		return (
			// <Flexbox style={{ cursor: "pointer", padding: "32px" }} alignItems="center" justifyContent="center">
			// 	{component}
			// </Flexbox>
			<div />
		);
	}
}

TimerCardNew.contextTypes = {
	tree: PropTypes.baobab
};

export default branch({
}, TimerCardNew);