import React from 'react';
import FontAwesome from 'react-fontawesome';

class NavItem extends React.Component {
    render() {
        return (
            <div style={{display: "flex", alignItems:"center", borderLeftStyle: "solid", borderWidth: "5px", borderColor: this.props.selected?"#fcb31f":"#1F272B", padding: "10px" }} onClick={this.props.onClick}>
                <FontAwesome fixedWidth={true} style={{fontSize: "24px"}} name={this.props.icon} />&nbsp;&nbsp;{this.props.label}
            </div>
        );
    }
}

export default NavItem;