import React from 'react';
import {branch} from 'baobab-react/higher-order';

import FontAwesome from 'react-fontawesome';
import {Checkbox} from 'react-bootstrap';
import * as actions from '../../actions/actions';
import PropTypes from 'baobab-react/prop-types';
import * as utils from '../../utils/utils.js';


class NavItemMyChecklists extends React.Component {
    render() {
        var self=this;
        var genresTitle;
        var genres = this.props.filtersAll.genres.map(function (genre, i) {
            var genreIcon = utils.getGenreIconForType(self.context.tree,genre.genre);
            return (
                <div key={"genre"+i}>
                    <Checkbox checked={genre.checked} onChange={(e)=>{actions.setFilterGenreProperty(self.context.tree,genre.genre,e.target.checked)}}>
                        <FontAwesome fixedWidth={true} style={{fontSize: "16px"}} name={genreIcon} /> {genre.genre} ({genre.count})
                    </Checkbox>    
                </div>
            );
        });

        if (genres.length !== 0) {
            genresTitle = <div><b>Genres</b><br/></div>;
        }
    
        var tagsTitle;
        var tags = this.props.filtersAll.tags.map(function (tag, i) {
            return (
                <div key={"tag"+i}>
                    <Checkbox checked={tag.checked} onChange={(e)=>{actions.setFilterTagProperty(self.context.tree,tag.tag,e.target.checked)}}>
                        {tag.tag} ({tag.count})
                    </Checkbox>    
                </div>
            );
        });

        if (tags.length !== 0) {
            tagsTitle = <div><b>Tags</b><br/></div>;
        }

        var showVisible;
        if (utils.documentsHasHidden(this.context.tree)) {
            showVisible =
            <Checkbox checked={!this.props.showVisible} onChange={(e)=>{actions.setShowVisible(self.context.tree,!e.target.checked)}}>
                Show Visible Only
            </Checkbox>;
        }

        var genresPanel;
        if (this.props.capabilitiesMyChecklists.filterGenresVisible) {
            genresPanel =
            <div>
                {genresTitle}
                {genres}
            </div>;

        }

        var filters;
        if (this.props.selected && (this.props.filtersAll.genres.length > 0 || this.props.filtersAll.tags.length > 0)) {
            filters =
            <div style={{marginLeft: "10px", marginTop: "10px"}}>
                {showVisible}
                {genresPanel}       
                <div>
                    {tagsTitle}
                    {tags}
                </div>   
            </div>;  
        }

        return (
            <div style={{display: "flex", alignItems:"center", borderLeftStyle: "solid", borderWidth: "5px", borderColor: this.props.selected?"#fcb31f":"#1F272B", padding: "10px" }} onClick={this.props.onClick}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <FontAwesome fixedWidth={true} style={{fontSize: "24px"}} name={this.props.icon} />&nbsp;&nbsp;{this.props.label}
                    </div> 
                    {filters}
                </div>
            </div>
        );
    }
}

NavItemMyChecklists.contextTypes = {
	tree: PropTypes.baobab
};


export default branch({
    showVisible: ['appState','filters','showVisible'],
    capabilitiesMyChecklists: ['appCapabilities','views','myChecklists']
}, NavItemMyChecklists);