// import 'core-js';
// import 'whatwg-fetch';
// import 'core-js/fn/array/from';
// import 'core-js/fn/array/find-index';
// import 'core-js/fn/symbol';
// import 'core-js/fn/symbol/iterator';
// import 'core-js/fn/number/is-integer';
// import 'core-js/fn/string/includes';

import "core-js/features/array/includes";
import "core-js/features/array/from";
import "core-js/features/array/find";
import "core-js/features/array/find-index";
import "core-js/features/array/fill";
import "core-js/features/object/assign";
import "core-js/features/object/entries";
import "core-js/features/object/from-entries";
import "core-js/features/symbol";
import "core-js/features/symbol/iterator";
import "core-js/features/number/is-integer";
import "core-js/features/string/includes";
import "core-js/features/string/starts-with";
import "core-js/features/string/ends-with";
import "core-js/features/promise";
import "whatwg-fetch";

import React from 'react';
import { render } from 'react-dom';
import { root } from 'baobab-react/higher-order';

import mainState from './state/state';

import RegistrationFlow from './components/portal/app-registration-flow';

import Confirm from 'react-confirm-bootstrap';

import PortalHome from './components/portal/app-home.js';
import Passwordless from './components/portal/app-passwordless.js';
import Verify from './components/portal/app-verify-user.js';
import Devices from './components/portal/app-devices.js';
import Login from './components/portal/app-login.js';
import LoggedIn from './components/portal/app-logged-in.js';
import ForgotPassword from './components/portal/app-forgot-password.js';
import Subscribe from './components/portal/app-subscribe.js';
import PerfTrialCheckout from './components/portal/app-subscribe-perf-trial-pre-checkout.js';
import PerfTrialReturn from './components/portal/app-subscribe-perf-trial-pre-return.js';
import PerfYearlyReturn from './components/portal/app-subscribe-perf-yearly-pre-return.js';
import PerfMonthlyReturn from './components/portal/app-subscribe-perf-monthly-pre-return.js';
import ProYearlyReturn from './components/portal/app-subscribe-pro-yearly-pre-return.js';
import ProMonthlyReturn from './components/portal/app-subscribe-pro-monthly-pre-return.js';
import PurchaseCustom from './components/portal/app-purchase-custom.js';
import UpdateCustomerCard from './components/portal/app-update-customer-card.js';
import RedeemVoucher from './components/portal/app-redeem-voucher.js';

import Home from './components/app-home.js';
import MiraCheckSearch from './components/app-miracheck-search.js';
import Documents from './components/app-documents.js';
import ReceiveChecklist from './components/app-receive-checklist.js';
import History from './components/app-history.js';
import TimerTemplates from './components/timer/app-timer-templates.js';
import CheckMateImport from './components/app-import-checkmate.js';
import Main from './components/app-main.js';
import FlightSchool from './components/app-flight-schools.js';
import AfmPoh from './components/app-afm-poh.js';

import { Router, Route } from 'react-router-dom';
import browserHistory from './history';

import * as actions from './actions/actions.js';
import * as portalActions from './actions/portalActions.js';
import * as awsS3 from './persistence/s3.js';
import * as algolia from './search/algolia.js';

import * as utils from './utils/utils';

import globals from './constants/globals.js';
import { contentBucket } from './constants/globals.js'; 

var ReactGA;
if (!mainState.get(["appCapabilities","igapp"])) {
	ReactGA = require('react-ga');
	ReactGA.initialize('UA-100193329-2');	
}

// require('string.prototype.startswith');

const queryString = require('query-string');

// Creating our top-level component
class App extends React.Component {
	render() {
		return (
			<RegistrationFlow />
		);
	}
}

/*
Number.prototype.pad = function(size) {
      var s = String(this);
      while (s.length < (size || 2)) {s = "0" + s;}
      return s;
}
*/

// Let's bind the component to the tree through the `root` higher-order component
const RootedHome = root(mainState, Home);
const RootedPortal = root(mainState, App);
const RootedPasswordless = root(mainState, Passwordless);
const RootedSearch = root(mainState, MiraCheckSearch);
const RootedPortalHome = root(mainState, PortalHome);
const RootedVerify = root(mainState, Verify);
const RootedDevices = root(mainState, Devices);
const RootedLogin = root(mainState, Login);
const RootedLoggedIn = root(mainState, LoggedIn);
const RootedForgotPassword = root(mainState, ForgotPassword);
const RootedSubscribe = root(mainState, Subscribe);
const RootedPerfTrialCheckout = root(mainState, PerfTrialCheckout);
const RootedPerfTrialReturn = root(mainState, PerfTrialReturn);
const RootedPerfYearlyReturn = root(mainState, PerfYearlyReturn);
const RootedPerfMonthlyReturn = root(mainState, PerfMonthlyReturn);
const RootedProYearlyReturn = root(mainState, ProYearlyReturn);
const RootedProMonthlyReturn = root(mainState, ProMonthlyReturn);
const RootedPurchaseCustom = root(mainState, PurchaseCustom);
const RootedUpdateCustomerCard = root(mainState, UpdateCustomerCard);
const RootedRedeemVoucher = root(mainState, RedeemVoucher);
const RootedMain = root(mainState, Main);
const RootedDocuments = root(mainState, Documents);
const RootedReceiveChecklist = root(mainState, ReceiveChecklist);
const RootedHistory = root(mainState, History);
const RootedTimerTemplates = root(mainState, TimerTemplates);
const RootedImport = root(mainState, CheckMateImport);
const RootedCheckMateImport = root(mainState, CheckMateImport);
// const RootedFlightSchools = root(mainState, FlightSchool);
// const RootedAfmPoh = root(mainState, AfmPoh);

/**
 * HS: This will override the default alerts, we can always remove this and use the
 * fancy growls more selectively.
 */


(function(proxied) {
  window.alert = function(message, cb) {
    actions.showAlert(message, cb);
    // return proxied.apply(this, arguments);
	return false;
  };
  window.confirm = function (message, cb) {
    actions.showConfirm(message, 'Confirm', cb);
  };
})(window.alert);

// WKWebView
window.receivedMessageFromRN = async function (e) {
	// awsS3.logToCloudWatch2("***** We have life");
	if (e === "refreshChecklists") {
		awsS3.getAllChecklists(mainState);
	} else {
		var obj = e;
		if (obj.hasOwnProperty("type") && obj.type === "instances") {		
			//awsS3.logToCloudWatch("instances");
			
			// Array of instances to save to s3
			var instances = obj.instances;
			var files = [];
			var errLog = [];
			
			for (var i = 0; i < instances.length; i++) {
				//alert("Error " + i);
				try {
					var instance = instances[i];

					var lastTimestamp = (instance.history[0].timestamp).replace(" ", "T");
				
					var fileName = instance.id + "/" + lastTimestamp + "_" + instance.instanceId + ".json";

					files.push({fileName: fileName, fileContent: instance});
					//awsS3.saveJsonToS3ForHistory(instance, contentBucket, fileName);

					//awsS3.logToCloudWatch("instance length in webview " + JSON.stringify(instance).length);
				
					//awsS3.logToCloudWatch("lastTimestamp: " + instance.lastTimestamp);
				} catch (e) {
					//awsS3.logToCloudWatch("instance error " + e.message);

					errLog.push(e);
				}
			}	
			
			if (errLog.length > 0) {
				//alert(JSON.stringify(errLog,null,2));
			}

			try {
				await awsS3.saveHistory(contentBucket,files,true);
				//awsS3.getAllChecklists(mainState);
			} catch (e) {
				console.log(e);
			}

		} else if (Array.isArray(obj)) {
			//awsS3.logToCloudWatch("instances shouldn't be here");

			// Compile checklists to send back to mobile app 
			awsS3.getAllChecklists(mainState);
	
			// Array of instances to save to s3
			var instances = obj;
			var files = [];
			var errLog = [];
			
			for (var i = 0; i < instances.length; i++) {
				//alert("Error " + i);
				try {
					var instance = instances[i];
				
					var lastTimestamp = (instance.history[0].timestamp).replace(" ", "T");
				
					var fileName = instance.id + "/" + lastTimestamp + "_" + instance.instanceId + ".json";

					files.push({fileName: fileName, fileContent: instance});
					//awsS3.saveJsonToS3ForHistory(instance, contentBucket, fileName);
				} catch (e) {
					errLog.push(e);
				}
			}	
			
			if (errLog.length > 0) {
				//alert(JSON.stringify(errLog,null,2));
			}

			awsS3.saveHistory(contentBucket,files);
		// Edit Checklistt
		} else if (obj.hasOwnProperty("action") && obj.hasOwnProperty("params")) {
			if (obj.action === "blurActiveElement") {
				document.activeElement.blur();
			}
			if (obj.action === "selectPreset") {
				browserHistory.push("/home");
				browserHistory.push("/search");
				if (document.activeElement) {
					document.activeElement.blur();
				}
				// Set the display attribute of the element with class "navbar" to none
				const navbar = document.querySelector('.navbar');
				if (navbar) {
					navbar.style.display = 'none';
				}
			}
			if (obj.action === "createNew") {
				browserHistory.push("/home");
				browserHistory.push("/myHangar");

				setTimeout(() => {
					document.querySelector('.cardNew').click();
				}, 100);
				// setTimeout(() => {
				// 	const navbar = document.querySelector('.navbar-header');
				// 	if (navbar) {
				// 		navbar.style.display = 'none';
				// 	}
				// }, 100);

				// Set the display attribute of the element with class "navbar" to none

			}
			if (obj.action === "logoutUserFromApp") {
				portalActions.logout();
			}
		} else if (obj.hasOwnProperty("checklistId") && obj.hasOwnProperty("listIndex") && obj.hasOwnProperty("sectionIndex") && obj.hasOwnProperty("itemIndex")) {
			const path = "/editor/" + obj.checklistId;
			browserHistory.push("/home");		
			browserHistory.push(path);		
	 	} else if (obj.hasOwnProperty("itunesSubscription")) {
			var sub = e;
			var itunesSubscription = {
				plan: sub.itunesSubscription,
				expiration: sub.subscriptionExpiration,
				transactionId: sub.subscriptionTransactionId 
			}

			try {
				await portalActions.updateItunesSubscription(itunesSubscription);

				// window.ReactNativeWebView.postMessage(JSON.stringify({method:"itunesComplete"}));
			} catch (e) {
				window.ReactNativeWebView.postMessage(JSON.stringify({method: "showError", errorTitle:"Purchase Failed", errorText: "The purchase was successful with Apple, but there was a problem syncing with our server. Please contact support@miralouaero.com."}));
			}
/*
			// Post back to mobile app that all is good
			try {
				window.webkit.messageHandlers.reactNative.postMessage(JSON.stringify({method:"itunesComplete"}));
			} catch (e) {
			}
*/
		} else if (obj.hasOwnProperty("clientVersion")) {
			mainState.set(["clientVersion"],obj.clientVersion);
		} else if (obj.hasOwnProperty("deviceId")) {
			var device = e;
			mainState.set(["device"],device);
		} else if (obj.hasOwnProperty("timerTemplates")) {
			var clientTimerTemplates = obj.timerTemplates;
			var serverTimerTemplates = await awsS3.getJsonFileFromS3(contentBucket,"userAssets/timerTemplates.json");
			var timerTemplates = utils.syncTimerTemplates(clientTimerTemplates, serverTimerTemplates);
			//awsS3.logToCloudWatch("timerTemplates");

			// Save to s3
			try {
				await awsS3.putJsonFileToS3(contentBucket,"userAssets/timerTemplates.json",timerTemplates);
			} catch (err) {
				alert("Error: " + err.message);
			}

			if (obj.refreshChecklists) {
				awsS3.getAllChecklists(mainState);
			}
		} else if (obj.hasOwnProperty("type") && obj.type === "checklist") {
			// This is where could save the checklist back to S3
			var files = [];
			var errLog = [];

			try {
				var instance = obj;
			
				var	lastTimestamp = (instance.history[0].timestamp).replace(" ", "T");

				var fileName = instance.id + "/" + lastTimestamp + "_" + instance.instanceId + ".json";

				files.push({fileName: fileName, fileContent: instance});
				//awsS3.saveJsonToS3ForHistory(instance, contentBucket, fileName);
			} catch (e) {
				errLog.push(e);
			}

			awsS3.saveHistory(contentBucket,files,false);
		} else {
			var user = e;
			mainState.set(["user", "username"], user.username);
			mainState.set(["user", "password"], user.password);

			const igapp = mainState.get(["appCapabilities","igapp"]);

			if (!igapp) {
				const path = "/login";
				browserHistory.push(path);
			}
		}
	}
}

// UIWebView
document.addEventListener('message', async function (e) {
	// awsS3.logToCloudWatch2("We have life");
	if (e.data === "refreshChecklists") {
		awsS3.getAllChecklists(mainState);
	} else {
		var obj = JSON.parse(e.data);
		if (obj.hasOwnProperty("type") && obj.type === "instances") {
			// Compile checklists to send back to mobile app 
			//awsS3.getAllChecklists(mainState);

			// Array of instances to save to s3
			var instances = obj;
			var files = [];
			var errLog = [];
			
			for (var i = 0; i < instances.length; i++) {
				//alert("Error " + i);
				try {
					var instance = instances[i];
				
					var	lastTimestamp = (instance.history[0].timestamp).replace(" ", "T");
				
					var fileName = instance.id + "/" + lastTimestamp + "_" + instance.instanceId + ".json";

					files.push({fileName: fileName, fileContent: instance});
					//awsS3.saveJsonToS3ForHistory(instance, contentBucket, fileName);
				} catch (e) {
					errLog.push(e);
				}
			}	
			
			if (errLog.length > 0) {
				//alert(JSON.stringify(errLog,null,2));
			}

			try {
				await awsS3.saveHistory(contentBucket,files,true);
				//awsS3.getAllChecklists(mainState);
			} catch (e) {
				console.log(e);
			}
		} else if (Array.isArray(obj)) {
			// Compile checklists to send back to mobile app 
			awsS3.getAllChecklists(mainState);

			// Array of instances to save to s3
			var instances = obj;
			var files = [];
			var errLog = [];
			
			for (var i = 0; i < instances.length; i++) {
				//alert("Error " + i);
				try {
					var instance = instances[i];
				
					var lastTimestamp = (instance.history[0].timestamp).replace(" ", "T");
				
					var fileName = instance.id + "/" + lastTimestamp + "_" + instance.instanceId + ".json";

					files.push({fileName: fileName, fileContent: instance});
					//awsS3.saveJsonToS3ForHistory(instance, contentBucket, fileName);
				} catch (e) {
					errLog.push(e);
				}
			}	
			
			if (errLog.length > 0) {
				//alert(JSON.stringify(errLog,null,2));
			}

			awsS3.saveHistory(contentBucket,files);

/*			
			// Array of instances to save to s3
			var instances = obj;
			for (var i = 0; i < instances.length; i++) {
				var instance = instances[i];

				var lastTimestamp = (instance.history[0].timestamp).replace(" ", "T");
				var fileName = instance.id + "/" + lastTimestamp + "_" + instance.instanceId + ".json";
				awsS3.saveJsonToS3(instance, contentBucket, fileName);
			}
*/			
		} else if (obj.hasOwnProperty("deviceId")) {
			var device = obj;
			mainState.set(["device"],device);
		} else if (obj.hasOwnProperty("clientVersion")) {
			mainState.set(["clientVersion"],obj.clientVersion);
		} else if (obj.hasOwnProperty("timerTemplates")) {
			var clientTimerTemplates = obj.timerTemplates;
			var serverTimerTemplates = await awsS3.getJsonFileFromS3(contentBucket,"userAssets/timerTemplates.json");
			var timerTemplates = utils.syncTimerTemplates(clientTimerTemplates, serverTimerTemplates);

			// Save to s3
			try {
				await awsS3.putJsonFileToS3(contentBucket,"userAssets/timerTemplates.json",timerTemplates);
			} catch (err) {
				alert("Error: " + err.message);
			}

			if (obj.refreshChecklists) {
				awsS3.getAllChecklists(mainState);
			}
		} else if (obj.hasOwnProperty("type") && obj.type === "checklist") {
			// This is where could save the checklist back to S3
			var files = [];
			var errLog = [];

			try {
				var instance = obj;
			
				var	lastTimestamp = (instance.history[0].timestamp).replace(" ", "T");
		
				var fileName = instance.id + "/" + lastTimestamp + "_" + instance.instanceId + ".json";

				files.push({fileName: fileName, fileContent: instance});
				//awsS3.saveJsonToS3ForHistory(instance, contentBucket, fileName);
			} catch (e) {
				errLog.push(e);
			}

			awsS3.saveHistory(contentBucket,files,false);
		} else {
			var user = obj;
			mainState.set(["user", "username"], user.username);
			mainState.set(["user", "password"], user.password);

			const igapp = mainState.get(["appCapabilities","igapp"]);

			if (!igapp) {
				const path = "/login";
				browserHistory.push(path);
			}
		}

	}
});

function logPageView(location) {
	if (!mainState.get(["appCapabilities","igapp"])) {
		ReactGA.set({ page: location.pathname + location.search });
		ReactGA.pageview(location.pathname + location.search);
	}
}

const urlParsed = queryString.parse(window.location.search);
mainState.set(["appState", "urlParams"], urlParsed);

algolia.searchFeatured2();
algolia.searchCheckMate();

algolia.searchByTag("currentlyInFeatured");
algolia.searchByTag("premium");
algolia.searchByTag("endorsed");
algolia.searchByTag("misc");
// algolia.searchByGenre("Aircraft");
//algolia.searchTrending("Markdown");
//algolia.searchRecommended("Cessna");

// Rendering the app
render((
	<Router history={browserHistory}>
		<div>
			<Route exact path="/" component={RootedPortal} />
			<Route path="/index.html" component={RootedPortal} />
			<Route path="/home" component={RootedHome} />
			<Route path="/magiclink" component={RootedPasswordless} />
			<Route path="/search" component={RootedSearch} />
			<Route path="/portalHome" component={RootedPortalHome} />
			<Route path="/verify" component={RootedVerify} />
			<Route path="/devices" component={RootedDevices} />
			<Route path="/myHangar" component={RootedDocuments} />
			<Route path="/receiveChecklist/:shareId" component={RootedReceiveChecklist} />
			<Route path="/history" component={RootedHistory} />
			<Route path="/timers" component={RootedTimerTemplates} />
			<Route path="/login" component={RootedLogin} />
			<Route path="/loggedIn" component={RootedLoggedIn} />
			<Route path="/forgotPassword" component={RootedForgotPassword} />
			<Route path="/subscribe" component={RootedSubscribe} />
			<Route path="/plans/AOPA-offer" component={RootedPerfTrialCheckout} />
			<Route path="/perfTrialCheckout" component={RootedPerfTrialCheckout} />
			<Route path="/perfTrialReturn" component={RootedPerfTrialReturn} />
			<Route path="/perfYearlyReturn" component={RootedPerfYearlyReturn} />
			<Route path="/perfMonthlyReturn" component={RootedPerfMonthlyReturn} />
			<Route path="/proYearlyReturn" component={RootedProYearlyReturn} />
			<Route path="/proMonthlyReturn" component={RootedProMonthlyReturn} />
			<Route path="/purchaseCustom" component={RootedPurchaseCustom} />
			<Route path="/updateCustomerCard" component={RootedUpdateCustomerCard} />
			<Route path="/redeemVoucher" component={RootedRedeemVoucher} />
			<Route path="/import" component={RootedImport} />
			<Route path="/editor/:checklistId" component={RootedMain} />

			{/* <Route path="/afmEditor/:checklistId" component={RootedMain} />
			<Route path="/afmpoh" component={RootedAfmPoh} />
			<Route path="/flightschools" component={RootedFlightSchools} /> */}
		</div>
	</Router>
), document.getElementById('root'), portalActions.restoreSession);

browserHistory.listen(function (location) {
	if (!mainState.get(["appCapabilities","igapp"])) {
		// Only track if prod
		if (globals.hostUrl === "https://checklist.miracheck.com") {
			logPageView(location);
		}
	}
});